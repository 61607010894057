import styled from 'styled-components';
import { Container, TextField } from '@mui/material';

export const StyledContainer = styled(Container)(
  ({ theme }) => `
  &.MuiContainer-root {
    
    ${theme.breakpoints.down('sm')} {
      padding: 2.5rem 1.25rem 0;
    }

    & .statusWrapper {
      display: flex;
      align-items: center;
      min-height: 1.875rem;
    }
  }
  @media(max-width:800px){
    .MuiTable-root{
      min-width: 1024px;
    }
  }
`
);

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const TextFieldStyled = styled(TextField)`
  &.MuiTextField-root {
    margin-top: 15px;
  }
`;

export const SelectDataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
