import styled from 'styled-components';

export const GroupHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 0 0.25rem;

  & > :last-child {
    margin-left: 0.625rem;
  }
`;
