import React, { useEffect } from 'react';
import { FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormElements } from 'web-components';
import T from 'prop-types';
import { SelectStyled } from './StaticValues.styled';
import { CUSTOM_ICON_TYPE_LIST, DECIMAL_PLACES, metricDataType, PERIODS_LIST } from '../../../../../attrs/sensorConfig';
import { COLOR_PRIMARY } from '../../../../../attrs/colors';
import { getDefaultFieldValue } from '../../../../../helpers/utils';

const StaticValuesFormFields = ({
  values,
  onBlur,
  onChange,
  getError,
  getHelperText,
  setFieldValue,
  sensor,
  setValues
}) => {
  const { t } = useTranslation();

  const handleRadioChange = e => {
    setFieldValue('value_type', e.target.value);
  };

  useEffect(() => {
    setValues({});
    if (sensor) {
      setValues(sensor);
    }
  }, [sensor, setValues]);

  return (
    <>
      <FormElements.TextField
        label={t('machines.form.sensor.static_value_name')}
        id="static_value_name"
        margin="normal"
        name="name"
        fullWidth
        required
        onChange={onChange}
        data-selector="machines-static-value-name-field"
        value={values.name}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <FormElements.TextField
          label={t('machines.form.sensor.plc_source')}
          id="plc_address"
          margin="normal"
          name="source"
          style={{ width: '45%' }}
          required
          onChange={onChange}
          onBlur={onBlur}
          data-selector="machines-static-value-name-field"
          value={values.source}
        />
        <SelectStyled
          error={getError(`custom_icon_type`)}
          helperText={getHelperText(`custom_icon_type`)}
          handleChange={onChange}
          label={t('machines.form.sensor.custom_icon_type')}
          name="custom_icon_type"
          id="custom_icon_type"
          onBlur={onBlur}
          required
          value={values.custom_icon_type}
          withEmptyField
        >
          {CUSTOM_ICON_TYPE_LIST.map(iconType => (
            <option key={iconType.c} value={iconType.c}>
              {t(`${iconType.t}`)}
            </option>
          ))}
        </SelectStyled>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <SelectStyled
          error={getError(`custom_period`)}
          helperText={getHelperText(`custom_period`)}
          fullWidth
          handleChange={onChange}
          id="custom_period"
          label={t('machines.form.sensor.custom_period')}
          name="custom_period"
          onBlur={onBlur}
          required
          value={values.custom_period}
          withEmptyField
        >
          {PERIODS_LIST.map(period => (
            <option key={period} value={period}>
              {t(`machines.form.sensor.periods.${period}`)}
            </option>
          ))}
        </SelectStyled>
        <FormElements.TextField
          style={{ width: '45%' }}
          error={getError(`custom_unit`)}
          helperText={getHelperText(`custom_unit`)}
          id="custom_unit"
          label={t('machines.form.sensor.custom_unit')}
          margin="normal"
          name="custom_unit"
          onBlur={onBlur}
          onChange={onChange}
          required
          value={values.custom_unit}
        />
      </div>
      <div>
        <FormLabel htmlFor="machines-static-sensor-information-type-text">
          <FormElements.RadioButton
            margin="normal"
            name="information-type-text"
            activeColor={COLOR_PRIMARY}
            onChange={handleRadioChange}
            value="STRING"
            checked={values.value_type === 'STRING'}
          />
          {t('machines.form.sensor.text_radio')}
        </FormLabel>
        <FormLabel htmlFor="machines-static-sensor-information-type-numeric">
          <FormElements.RadioButton
            margin="normal"
            name="information-type-numeric"
            activeColor={COLOR_PRIMARY}
            onChange={handleRadioChange}
            value="NUMBER"
            checked={values.value_type === 'NUMBER'}
          />
          {t('machines.form.sensor.numeric_radio')}
        </FormLabel>
        <FormLabel htmlFor="machines-static-sensor-information-type-boolean">
          <FormElements.RadioButton
            margin="normal"
            name="information-type-boolean"
            activeColor={COLOR_PRIMARY}
            onChange={handleRadioChange}
            value="BOOLEAN"
            checked={values.value_type === 'BOOLEAN'}
          />
          {t('machines.form.sensor.boolean_radio')}
        </FormLabel>
      </div>
      {values.value_type === 'NUMBER' && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormElements.TextField
            style={{ width: '45%' }}
            error={getError(`multiplication_factor`)}
            helperText={getHelperText(`multiplication_factor`)}
            id="multiplication_factor"
            label={t('machines.form.sensor.multiplication_factor')}
            margin="normal"
            name="multiplication_factor"
            type="number"
            min="0.000001"
            max="100000"
            onBlur={onBlur}
            onChange={onChange}
            required
            value={getDefaultFieldValue(values.multiplication_factor)}
          />
          <SelectStyled
            error={getError(`decimal_place`)}
            helperText={getHelperText(`decimal_place`)}
            handleChange={onChange}
            id="decimal_place"
            label={t('machines.form.sensor.decimal_place')}
            name="decimal_place"
            onBlur={onBlur}
            required
            value={values.decimal_place}
            withEmptyField
          >
            {DECIMAL_PLACES.map(num => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </SelectStyled>
        </div>
      )}
    </>
  );
};

StaticValuesFormFields.propTypes = {
  values: metricDataType.isRequired,
  onBlur: T.func.isRequired,
  onChange: T.func.isRequired,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  setFieldValue: T.func.isRequired,
  sensor: metricDataType,
  setValues: T.func
};

StaticValuesFormFields.defaultProps = {
  sensor: {},
  setValues: () => null
};

export { StaticValuesFormFields };
