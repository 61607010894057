import React from 'react';
import T from 'prop-types';
import { FormElements } from 'web-components';
import { useTranslation } from 'react-i18next';

import {
  CUSTOM_ICON_TYPE_LIST,
  DECIMAL_PLACES,
  metricDataType,
  PERIODS_LIST
} from '../../../../../../attrs/sensorConfig';
import { getDefaultFieldValue } from '../../../../../../helpers/utils';

const BaseConfigurationFields = ({
  values,
  namespace,
  availableSensorTypes,
  isNewSensor,
  handleChangeSensorType,
  getError,
  getHelperText,
  onBlur,
  onChange
}) => {
  const { t } = useTranslation();
  const namespacePrefix = namespace ? `${namespace}.` : '';

  if (values.is_custom) {
    return (
      <>
        <FormElements.TextField
          fullWidth
          error={getError(`${namespacePrefix}name`)}
          helperText={getHelperText(`${namespacePrefix}name`)}
          id={`${namespacePrefix}name`}
          label={t('machines.form.sensor.name')}
          margin="normal"
          name={`${namespacePrefix}name`}
          onBlur={onBlur}
          onChange={onChange}
          required
          value={values.name}
        />
        <FormElements.TextField
          fullWidth
          error={getError(`${namespacePrefix}source`)}
          helperText={getHelperText(`${namespacePrefix}source`)}
          id={`${namespacePrefix}source`}
          label={t('machines.form.sensor.plc_source')}
          margin="normal"
          name={`${namespacePrefix}source`}
          onBlur={onBlur}
          onChange={onChange}
          required
          value={values.source}
        />

        <FormElements.Select
          error={getError(`${namespacePrefix}custom_icon_type`)}
          helperText={getHelperText(`${namespacePrefix}custom_icon_type`)}
          fullWidth
          handleChange={onChange}
          id={`${namespacePrefix}custom_icon_type`}
          label={t('machines.form.sensor.custom_icon_type')}
          name={`${namespacePrefix}custom_icon_type`}
          onBlur={onBlur}
          required
          value={values.custom_icon_type}
          withEmptyField
        >
          {CUSTOM_ICON_TYPE_LIST.map(iconType => (
            <option key={iconType.c} value={iconType.c}>
              {t(`${iconType.t}`)}
            </option>
          ))}
        </FormElements.Select>

        <FormElements.Select
          error={getError(`${namespacePrefix}custom_period`)}
          helperText={getHelperText(`${namespacePrefix}custom_period`)}
          fullWidth
          handleChange={onChange}
          id={`${namespacePrefix}custom_period`}
          label={t('machines.form.sensor.custom_period')}
          name={`${namespacePrefix}custom_period`}
          onBlur={onBlur}
          required
          value={values.custom_period}
          withEmptyField
        >
          {PERIODS_LIST.map(period => (
            <option key={period} value={period}>
              {t(`machines.form.sensor.periods.${period}`)}
            </option>
          ))}
        </FormElements.Select>
        <FormElements.TextField
          fullWidth
          error={getError(`${namespacePrefix}custom_unit`)}
          helperText={getHelperText(`${namespacePrefix}custom_unit`)}
          id={`${namespacePrefix}custom_unit`}
          label={t('machines.form.sensor.custom_unit')}
          margin="normal"
          name={`${namespacePrefix}custom_unit`}
          onBlur={onBlur}
          onChange={onChange}
          required
          value={values.custom_unit}
        />
        <FormElements.TextField
          fullWidth
          error={getError(`${namespacePrefix}multiplication_factor`)}
          helperText={getHelperText(`${namespacePrefix}multiplication_factor`)}
          id={`${namespacePrefix}multiplication_factor`}
          label={t('machines.form.sensor.multiplication_factor')}
          margin="normal"
          name={`${namespacePrefix}multiplication_factor`}
          type="number"
          min="0.000001"
          max="100000"
          onBlur={onBlur}
          onChange={onChange}
          required
          value={getDefaultFieldValue(values.multiplication_factor)}
        />
        <FormElements.Select
          error={getError(`${namespacePrefix}decimal_place`)}
          helperText={getHelperText(`${namespacePrefix}decimal_place`)}
          fullWidth
          handleChange={onChange}
          id={`${namespacePrefix}decimal_place`}
          label={t('machines.form.sensor.decimal_place')}
          name={`${namespacePrefix}decimal_place`}
          onBlur={onBlur}
          required
          value={values.decimal_place}
          withEmptyField
        >
          {DECIMAL_PLACES.map(num => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </FormElements.Select>
      </>
    );
  }

  return (
    <>
      <FormElements.Select
        error={getError(`${namespacePrefix}type`)}
        helperText={getHelperText(`${namespacePrefix}type`)}
        fullWidth
        handleChange={handleChangeSensorType}
        id={`${namespacePrefix}type`}
        label={t('machines.form.sensor.type')}
        name={`${namespacePrefix}type`}
        onBlur={onBlur}
        required
        value={values.type}
        withEmptyField={isNewSensor}
      >
        {/* TODO: TEMP - Added sensorType.postfix identifier for sub sensor types */}
        {availableSensorTypes.map(sensorType => (
          <option key={sensorType.value} value={sensorType.value}>
            {t(sensorType.name)}
            {sensorType.postfix && ` [${sensorType.postfix}]`}
          </option>
        ))}
      </FormElements.Select>
      <FormElements.TextField
        fullWidth
        error={getError(`${namespacePrefix}source`)}
        helperText={getHelperText(`${namespacePrefix}source`)}
        id={`${namespacePrefix}source`}
        label={t('machines.form.sensor.source')}
        margin="normal"
        name={`${namespacePrefix}source`}
        onBlur={onBlur}
        onChange={onChange}
        required
        value={values.source}
      />
      <FormElements.TextField
        disabled
        fullWidth
        id={`${namespacePrefix}units`}
        label={t('machines.form.sensor.units')}
        margin="normal"
        name={`${namespacePrefix}units`}
        value={values.type && values.units}
      />
      <FormElements.TextField
        disabled
        fullWidth
        id={`${namespacePrefix}period`}
        label={t('machines.form.sensor.period')}
        margin="normal"
        name={`${namespacePrefix}period`}
        value={values.type && values.period}
      />
    </>
  );
};

BaseConfigurationFields.propTypes = {
  namespace: T.string,
  values: metricDataType.isRequired,
  availableSensorTypes: T.arrayOf(T.shape({})).isRequired,
  isNewSensor: T.bool,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleChangeSensorType: T.func.isRequired,
  onBlur: T.func.isRequired,
  onChange: T.func.isRequired
};

BaseConfigurationFields.defaultProps = {
  namespace: null,
  isNewSensor: false
};

export default BaseConfigurationFields;
