import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';

export const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 305,
    maxWidth: 305,
    marginBottom: 30,
    marginRight: 20
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  deleteIcon: {
    height: 20,
    width: 20
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

export const CustomCheckbox = withStyles({
  root: {
    color: '#58BAAA',
    '&$checked': {
      color: '#58BAAA'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  getContentAnchorEl: null
};

export function getStyles(item, selectedValue, theme) {
  return {
    fontWeight:
      selectedValue.indexOf(item) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}
