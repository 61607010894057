import { Box, IconButton, Stack, Typography } from '@mui/material';
import Footer from 'components/DataTable/Footer';
import EmptyState from 'components/EmptyState';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, NotifyIcon } from 'web-components';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { setConfirmationRequest } from 'redux/ui/confirmations/actions';
import { deleteUserById } from 'redux/users/actions';
import { getUsersInvitationsPaginatedV2Action } from 'redux/users_v2/actions';
import { getUsers, getUsersV2 } from 'redux/rootSelectors';
import T from 'prop-types';
import { getUserRoleByProduct, isRoleDiffNoAccess, isSuccess } from 'helpers/utils';

const UserInvitations = memo(({ organizationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listStatusEqual = (oldObj, newObj) =>
    oldObj?.usersInvitationsPaginatedStatus?.status === newObj?.usersInvitationsPaginatedStatus?.status;
  const { usersInvitationsPaginatedStatus } = useSelector(getUsersV2, listStatusEqual);
  const [isLoading, setIsLoading] = useState(false);

  const invitingUserStatusEqual = (oldObj, newObj) =>
    oldObj?.invitingUserStatus?.status === newObj?.invitingUserStatus?.status;
  const { invitingUserStatus } = useSelector(getUsersV2, invitingUserStatusEqual);
  const [successInvitingUser, setSuccessInvitingUser] = useState(false);

  const deletingUserStatusEqual = (oldObj, newObj) =>
    oldObj?.deletingUserStatus?.status === newObj?.deletingUserStatus?.status;
  const { deletingUserStatus } = useSelector(getUsers, deletingUserStatusEqual);
  const [successDeletingUser, setSuccessDeletingUser] = useState(false);

  const userListEqual = (oldObj, newObj) => {
    const sameSize =
      oldObj?.usersInvitationsPaginated?.data?.length === newObj?.usersInvitationsPaginated?.data?.length;
    let equalsObj = true;
    for (let i = 0; sameSize && i < oldObj?.usersInvitationsPaginated?.data?.length; i += 1) {
      equalsObj =
        JSON.stringify(oldObj?.usersInvitationsPaginated?.data[i] || '') ===
        JSON.stringify(newObj?.usersInvitationsPaginated?.data[i] || '');
      if (!equalsObj) break;
    }
    return (
      JSON.stringify(oldObj?.usersInvitationsPaginated?.data || '') ===
        JSON.stringify(newObj?.usersInvitationsPaginated?.data || '') &&
      oldObj?.usersInvitationsPaginated?.total_length === newObj?.usersInvitationsPaginated?.total_length &&
      sameSize &&
      equalsObj
    );
  };
  const { usersInvitationsPaginated: getUsersResponse } = useSelector(getUsersV2, userListEqual);

  const labelAmend = 'customers.user_management.list';
  const rowsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [limit, setLimit] = useState(0);
  const [invitations, setInvitations] = useState([]);

  useEffect(() => {
    dispatch(
      getUsersInvitationsPaginatedV2Action({
        organization_id: organizationId,
        limit: rowsPerPage,
        page: currentPage,
        is_active: false
      })
    );
  }, [dispatch, getUsersInvitationsPaginatedV2Action, organizationId]);

  useEffect(() => {
    if (isSuccess(usersInvitationsPaginatedStatus.status)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [usersInvitationsPaginatedStatus.status]);

  useEffect(() => {
    if (isSuccess(deletingUserStatus.status)) {
      setSuccessDeletingUser(true);
    }
  }, [deletingUserStatus.status]);

  useEffect(() => {
    if (isSuccess(invitingUserStatus.status)) {
      setSuccessInvitingUser(true);
    }
  }, [invitingUserStatus.status]);

  useEffect(() => {
    if (successInvitingUser || successDeletingUser) {
      setSuccessInvitingUser(false);
      setSuccessDeletingUser(false);
      dispatch(
        getUsersInvitationsPaginatedV2Action({
          organization_id: organizationId,
          limit: rowsPerPage,
          page: currentPage,
          is_active: false
        })
      );
    }
  }, [successInvitingUser, successDeletingUser]);

  useEffect(() => {
    if (getUsersResponse && getUsersResponse.data && getUsersResponse.data.length > 0) {
      setCurrentPage(getUsersResponse.page || 0);
      setTotalPages(getUsersResponse.total_pages || 0);
      setTotalLength(getUsersResponse.total_length || 0);
      setLimit(getUsersResponse.limit || 0);
      setInvitations(getUsersResponse.data);
    }
  }, [getUsersResponse]);

  const onClickOpenDeleteDialog = row => {
    dispatch(
      setConfirmationRequest({
        message: 'dialog.confirmation.delete_user',
        extraValue: row?.email,
        action: () => dispatch(deleteUserById(row?.id))
      })
    );
  };

  const columns = [
    {
      id: 'email',
      label: t(`${labelAmend}.email`)
    },
    {
      id: 'role_notify',
      label: t(`${labelAmend}.role_notify`),
      cellRenderer: row => {
        const roleNotify = getUserRoleByProduct(row.roles, 'notify');
        const hasAccess = isRoleDiffNoAccess(roleNotify);
        const colorIcon = hasAccess ? '#2BACA4' : '#BCBDC1';
        const colorText = hasAccess ? undefined : '#BCBDC1';
        return (
          <Stack spacing={1} direction="row" alignItems="center">
            <NotifyIcon fontSize="medium" iconName="notifyIcon" sx={{ color: colorIcon }} />
            <Typography sx={{ fontSize: 14, color: colorText }}>{t(`products.roles.${roleNotify}`)}</Typography>
          </Stack>
        );
      }
    },
    {
      id: 'role_preventive',
      label: t(`${labelAmend}.role_prev`),
      cellRenderer: row => {
        const rolePreventive = getUserRoleByProduct(row.roles, 'preventive');
        const hasAccess = isRoleDiffNoAccess(rolePreventive);
        const colorIcon = hasAccess ? '#2BACA4' : '#BCBDC1';
        const colorText = hasAccess ? undefined : '#BCBDC1';
        return (
          <Stack spacing={1} direction="row" alignItems="center">
            <NotifyIcon fontSize="medium" iconName="preventiveIcon" sx={{ color: colorIcon }} />
            <Typography sx={{ fontSize: 14, color: colorText }}>{t(`products.roles.${rolePreventive}`)}</Typography>
          </Stack>
        );
      }
    },
    {
      id: 'action',
      cellRenderer: row => (
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <IconButton aria-label="delete" color="inherit" size="medium" onClick={() => onClickOpenDeleteDialog(row)}>
            <DeleteForeverOutlinedIcon color="primary" fontSize="inherit" />
          </IconButton>
        </Stack>
      )
    }
  ];

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(
      getUsersInvitationsPaginatedV2Action({
        organization_id: organizationId,
        limit: rowsPerPage,
        page,
        is_active: false
      })
    );
  };

  const propsInvitations = {
    dataSelectorTable: 'invitations-table',
    dataSelectorRows: 'invitations-table-row',
    isLoading,
    columns,
    rows: invitations,
    hover: undefined,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={totalPages}
        totalLength={totalLength}
        totalItemsPerPage={limit}
        onChange={handleChangePage}
      />
    )
  };
  return (
    <>
      {(invitations && invitations.length > 0) || isLoading ? (
        <DataTable hover {...propsInvitations} />
      ) : (
        <Box sx={{ pb: 2, pt: 4 }}>
          <EmptyState type="user_management_pending" />
        </Box>
      )}
    </>
  );
});

UserInvitations.propTypes = {
  organizationId: T.string.isRequired
};

export default UserInvitations;
