import React from 'react';
import T from 'prop-types';
import { StyledContentWrapper } from '../../../../components/forms/Machines/MachineDetail/elements';
import ConnectedMachineDetailContent from './ConnectedMachineDetailContent';

const MachineDetails = ({ machine, plcType }) => (
  <>
    <StyledContentWrapper elevation={3}>
      <ConnectedMachineDetailContent machine={machine} plcType={plcType} />
    </StyledContentWrapper>
  </>
);

MachineDetails.propTypes = {
  machine: T.shape({
    data: T.arrayOf(T.shape({}))
  }),
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

MachineDetails.defaultProps = {
  machine: null
};

export default MachineDetails;
