import React, { useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PageHeaderSubTitle, PageHeaderTitle } from 'elements';

import CustomerEdit from 'components/forms/Customers/CustomerEditWrapper';
import CustomerDetail from 'components/forms/Customers/CustomerDetailWrapper';

import { CustomerBreadcrumb, CustomerPageHeader, StyledContentWrapper } from './CustomerDetail.styled';

const CustomerMain = ({ customer }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const breadcrumbObj = [
    { route: '/customers', name: `${t('customers.title')}` },
    { route: `/customers/${customer.id || ''}`, name: customer.name || t('customers.new_machine') }
  ];

  return (
    <StyledContentWrapper elevation={3}>
      <CustomerBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />
      <CustomerPageHeader>
        <PageHeaderTitle>{customer?.name}</PageHeaderTitle>
        <PageHeaderSubTitle>{t('customers.detail.subtitle')}</PageHeaderSubTitle>
      </CustomerPageHeader>
      <CustomerDetail customer={customer} handleEdit={() => setIsEditing(true)} />
      <CustomerEdit open={isEditing} handleClose={() => setIsEditing(false)} customer={customer} />
    </StyledContentWrapper>
  );
};

CustomerMain.propTypes = {
  customer: T.shape({ id: T.string, name: T.string }).isRequired
};

export default CustomerMain;
