import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import Tooltip from '@mui/material/Tooltip';
import { EditStatus } from './EditStatusModal';
import withLoadingState from '../../../../helpers/withLoadingState';
import { getValueToNullableCell } from '../../../../helpers/stringHandler';
import { StyledBoxValue } from './elements';
import { PlcType } from '../../../../attrs/plcConfigValues';

const labelAmend = 'machines.machine_details';

const StatusContent = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Tooltip
            title={t(`machines.detail.plc_not_filled`)}
            disableHoverListener={plcType.plc_type !== PlcType.UNKNOWN}
            arrow
          >
            <span>
              <Button
                variant="text"
                disabled={plcType.plc_type === PlcType.UNKNOWN}
                sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
                onClick={() => setEditModal(true)}
              >
                <NotifyIcon iconName="edit" fontSize="medium" />
                {t(`${labelAmend}.edit`)}
              </Button>
            </span>
          </Tooltip>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.status`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.status_info`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={8} sx={{ mt: 5, mb: 4, maxWidth: '1200px' }}>
        <Box gridColumn="span 2">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <NotifyIcon viewBox="0 0 17 17" iconName="runningMachine" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
              {t(`${labelAmend}.status_info_table.running`)}
            </NotifyTypo.Body1>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.status_info_table.address`)} *
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.running?.address)}</NotifyTypo.Body1>
            </Box>
            <StyledBoxValue>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.value`)} *</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.running?.value)}</NotifyTypo.Body1>
            </StyledBoxValue>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <NotifyIcon viewBox="0 0 17 17" iconName="pauseMachine" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
              {t(`${labelAmend}.status_info_table.idle`)}
            </NotifyTypo.Body1>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.status_info_table.address`)} *
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.idle?.address)}</NotifyTypo.Body1>
            </Box>
            <StyledBoxValue>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.value`)} *</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.idle?.value)}</NotifyTypo.Body1>
            </StyledBoxValue>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <NotifyIcon viewBox="0 0 17 17" iconName="warningMachine" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
              {t(`${labelAmend}.status_info_table.warning`)}
            </NotifyTypo.Body1>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.address`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.warning?.address)}</NotifyTypo.Body1>
            </Box>
            <StyledBoxValue>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.value`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.warning?.value)}</NotifyTypo.Body1>
            </StyledBoxValue>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <NotifyIcon viewBox="0 0 17 17" iconName="errorMachine" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
              {t(`${labelAmend}.status_info_table.error`)}
            </NotifyTypo.Body1>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.address`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.critical?.address)}</NotifyTypo.Body1>
            </Box>
            <StyledBoxValue>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.value`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.critical?.value)}</NotifyTypo.Body1>
            </StyledBoxValue>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <NotifyIcon viewBox="0 0 17 17" iconName="maintenanceMachine" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
              {t(`${labelAmend}.status_info_table.maintenance`)}
            </NotifyTypo.Body1>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.address`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.maintenance?.address)}</NotifyTypo.Body1>
            </Box>
            <StyledBoxValue>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.value`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{getValueToNullableCell(data.statuses?.maintenance?.value)}</NotifyTypo.Body1>
            </StyledBoxValue>
          </Box>
        </Box>
      </Box>

      <EditStatus open={editModal} close={setEditModal} handleClose={handleClose} data={data} machineId={machineId} />
    </Card>
  );
};

StatusContent.propTypes = {
  data: T.shape({
    statuses: T.shape({
      running: T.shape({
        address: T.string,
        value: T.string
      }),
      idle: T.shape({
        address: T.string,
        value: T.string
      }),
      warning: T.shape({
        address: T.string,
        value: T.string
      }),
      critical: T.shape({
        address: T.string,
        value: T.string
      }),
      maintenance: T.shape({
        address: T.string,
        value: T.string
      })
    })
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

StatusContent.defaultProps = {
  data: null
};

export default withLoadingState(StatusContent);
