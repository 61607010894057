import { call, put, takeLatest } from 'redux-saga/effects';
import { actionStatus, statusAction } from 'redux/utils';

import * as api from '../../api/task';
import { LOAD_TASKS } from './constants';

function* handleLoadTasks(params) {
  yield put(statusAction(LOAD_TASKS, actionStatus.START));
  try {
    const response = yield call(api.loadTasks, params);
    yield put(statusAction(LOAD_TASKS, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    yield put(
      statusAction(LOAD_TASKS, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(LOAD_TASKS, handleLoadTasks);
}
