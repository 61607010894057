import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGatewaysWithIssues } from 'redux/gateways/selectors';
import Footer from 'components/DataTable/Footer';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'web-components';
import { Box, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { loadGatewaysWithIssues } from '../../redux/gateways/actions';
import { isLoading, isSuccess } from '../../helpers/utils';

export function GatewaysWithIssues() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { loadingState: gatewaysWithIssuesState, data: gatewaysWithIssuesResponse } =
    useSelector(getGatewaysWithIssues);
  const gatewaysWithIssuesIsLoading = isLoading(gatewaysWithIssuesState.status);
  const gatewaysWithIssuesLoaded = isSuccess(gatewaysWithIssuesState.status);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [limit, setLimit] = useState(0);
  const [gatewayList, setGatewayList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    dispatch(loadGatewaysWithIssues());
  }, [dispatch]);

  useEffect(() => {
    if (gatewaysWithIssuesLoaded) {
      setCurrentPage(gatewaysWithIssuesResponse.page || 0);
      setTotalPages(gatewaysWithIssuesResponse.total_pages || 0);
      setTotalLength(gatewaysWithIssuesResponse.total_length || 0);
      setLimit(gatewaysWithIssuesResponse.limit || 0);
      setGatewayList(gatewaysWithIssuesResponse.data);
    }
  }, [gatewaysWithIssuesLoaded]);

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(loadGatewaysWithIssues(rowsPerPage, page));
  };

  const machineWithoutGatewayTableLabel = 'dashboard.tabs.gateway.gateways_with_issues.table';
  const columnsMachineWithoutGateway = [
    {
      id: 'serial_number',
      label: t(`${machineWithoutGatewayTableLabel}.gateway_id`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.serial_number)
    },
    {
      id: 'organization_name',
      label: t(`${machineWithoutGatewayTableLabel}.customer_name`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.organization_name)
    },
    {
      id: 'desired_version',
      label: t(`${machineWithoutGatewayTableLabel}.system_version`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.desired_version)
    },
    {
      id: 'type',
      label: t(`${machineWithoutGatewayTableLabel}.system_type`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.type)
    },
    {
      id: 'issue_date',
      label: t(`${machineWithoutGatewayTableLabel}.issue_date`),
      sortable: false,
      cellRenderer: row => {
        const dt = new Date(row?.last_issue_at);
        return (
          <>
            {t(`${machineWithoutGatewayTableLabel}.issue_date_value`, {
              date: dt.toLocaleDateString(),
              time: dt.toLocaleTimeString(),
              interpolation: { escapeValue: false }
            })}
          </>
        );
      }
    }
  ];

  const handleSelectRow = row => {
    history.push(`/gateways/${row.serial_number}`);
  };

  const propsMachineWithoutGateway = {
    dataSelectorTable: 'machine-without-gateway-table',
    dataSelectorRows: 'machine-without-gateway-table-row',
    isLoading: gatewaysWithIssuesIsLoading,
    columns: columnsMachineWithoutGateway,
    rows: gatewayList,
    onClick: handleSelectRow,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={totalPages}
        totalLength={totalLength}
        totalItemsPerPage={limit}
        onChange={handleChangePage}
      />
    )
  };

  const gatewayLostConnGeneralLabel = 'dashboard.tabs.gateway.gateways_with_issues';
  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 1.5
      }}
    >
      <Stack direction="column" spacing={2}>
        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {t(`${gatewayLostConnGeneralLabel}.caption`)}
        </Typography>
        <DataTable hover {...propsMachineWithoutGateway} />
      </Stack>
    </Box>
  );
}
