import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'prop-types';
import { loadIrisAutoConfig } from '../../../../redux/machines_v2/actions';
import { getSelectedIrisAutoConfig } from '../../../../redux/machines_v2/selectors';
import { PlcType } from '../../../../attrs/plcConfigValues';
import AutoConfig from './AutoConfig';

const ConnectedAutoConfig = ({ machineId, plcType }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getSelectedIrisAutoConfig);

  useEffect(() => {
    dispatch(loadIrisAutoConfig(machineId));
  }, [machineId, dispatch]);

  // If the PLC type is not IRIS_V3, return null
  if (plcType.plc_type !== PlcType.IRIS_V3) {
    return null;
  }

  return (
    <AutoConfig
      loadingState={loadingState}
      reloadAction={() => dispatch(loadIrisAutoConfig(machineId))}
      machineId={machineId}
      data={data}
    />
  );
};

ConnectedAutoConfig.propTypes = {
  machineId: T.string,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

ConnectedAutoConfig.defaultProps = {
  machineId: null
};

export default ConnectedAutoConfig;
