import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardHeader, Stack } from '@mui/material';
import {
  BeadMaterialType,
  GrindingTechnology,
  InnerLinerMaterial,
  MACHINE_TYPE_NAMES,
  MachineTypes,
  NotifyIcon,
  NotifyTypo
} from 'web-components';
import T from 'prop-types';
import { getValueToNullableCell } from 'helpers/stringHandler';
import Gateway from '../Gateway';
import CreateEditMachineDetails from './CreateEditMachineDetails';

const labelAmend = 'machines.machine_details';
const FILTERED_OUT_MACHINE_TYPES = [MACHINE_TYPE_NAMES.NETZSCH_PUMP_GENERIC, MACHINE_TYPE_NAMES.UNKNOWN];
const machineTypes = MachineTypes.filter(({ value }) => !FILTERED_OUT_MACHINE_TYPES.includes(value));
const getListWithoutDefaultValue = list => list.filter(data => data.value !== 'UNKNOWN');
const MachineDetailsContent = ({ machine }) => {
  const { t } = useTranslation();
  const [editMachineModal, setEditMachineModal] = useState(false);

  const handleCloseEditMachineModal = () => {
    setEditMachineModal(false);
  };

  const formatDate = dateValue => {
    if (dateValue) {
      return new Date(dateValue).toLocaleDateString();
    }
    return false;
  };

  useEffect(() => {
    if (machine.other_brand_technical_info)
      document.getElementById('technical-info').innerHTML = machine.other_brand_technical_info;
  }, [machine.other_brand_technical_info]);

  const machineDetails = [
    {
      label: t(`${labelAmend}.manufacturer`),
      value: getValueToNullableCell(machine.brand),
      id: `${labelAmend}.manufacturer`
    },
    {
      label: t(`${labelAmend}.type`),
      value: getValueToNullableCell(machineTypes.find(a => a.value === machine.type)?.type),
      id: `${labelAmend}.type`
    },
    {
      label: t(`${labelAmend}.commission_number`),
      value: (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
          <Box>{getValueToNullableCell(machine.commission_number)}</Box>
          {!!machine?.validations?.commission_number && (
            <Box>
              <NotifyIcon iconName="successFilled" color="#2FB259" sx={{ fontSize: '26px', mt: '5px' }} />
            </Box>
          )}
        </Stack>
      ),
      id: `commission_number`
    },
    {
      label: t(`${labelAmend}.name`),
      value: getValueToNullableCell(machine.name),
      id: `${labelAmend}.name`
    },
    {
      label: t(`${labelAmend}.production_line`),
      value: getValueToNullableCell(machine.production_line),
      id: `${labelAmend}.production_line`
    },
    {
      label: t(`${labelAmend}.tag_id`),
      value: getValueToNullableCell(machine.tag_id),
      id: `${labelAmend}.tag_id`
    },
    {
      label: t(`${labelAmend}.creation_date`),
      value: getValueToNullableCell(formatDate(machine.created_at)),
      id: `${labelAmend}.creation_date`
    }
  ];

  const machineDetailsOther = [
    {
      label: t(`${labelAmend}.manufacturer`),
      value: getValueToNullableCell(machine.brand),
      id: 'manufacturer'
    },
    {
      label: t(`${labelAmend}.manufacturer_name`),
      value: getValueToNullableCell(machine.other_brand),
      id: 'manufacturer_name'
    },
    {
      label: t(`${labelAmend}.type`),
      value: getValueToNullableCell(machine.other_type),
      id: 'type'
    },
    {
      label: t(`${labelAmend}.id_number`),
      value: getValueToNullableCell(machine.id_number),
      id: 'id_number'
    },
    {
      label: t(`${labelAmend}.name`),
      value: getValueToNullableCell(machine.name),
      id: 'machine_name'
    },
    {
      label: t(`${labelAmend}.production_line`),
      value: getValueToNullableCell(machine.production_line),
      id: 'production_line'
    },
    {
      label: t(`${labelAmend}.tag_id`),
      value: getValueToNullableCell(machine.tag_id),
      id: 'tag_id'
    },
    {
      label: t(`${labelAmend}.creation_date`),
      value: getValueToNullableCell(formatDate(machine.created_at)),
      id: 'creation_date'
    }
  ];

  const machineTec = [
    {
      label: t(`${labelAmend}.samba_system`),
      value: machine.mechanical_config?.samba_system
        ? t(`machines.form.samba_system_yes`)
        : t(`machines.form.samba_system_no`),
      id: 8
    },
    {
      label: t(`${labelAmend}.grinding`),
      value: getValueToNullableCell(
        getListWithoutDefaultValue(GrindingTechnology).find(
          a => a.value === machine.mechanical_config?.grinding_technology
        )?.name
      ),
      id: 9
    },
    {
      label: t(`${labelAmend}.inner_liner`),
      value: getValueToNullableCell(
        getListWithoutDefaultValue(InnerLinerMaterial).find(
          a => a.value === machine.mechanical_config?.inner_liner_material
        )?.name
      ),
      id: 10
    },
    {
      label: t(`${labelAmend}.sieve_size`),
      value: machine.mechanical_config?.sieve_size ? `${machine.mechanical_config.sieve_size} mm` : '---',
      id: 11
    },
    {
      label: t(`${labelAmend}.bead_material`),
      value: getValueToNullableCell(
        getListWithoutDefaultValue(BeadMaterialType).find(a => a.value === machine.mechanical_config?.bead_material)
          ?.name
      ),
      id: 12
    },
    {
      label: t(`${labelAmend}.bead_size_one`),
      value: machine.mechanical_config?.beads_size_1 ? `${machine.mechanical_config.beads_size_1} mm` : '---',
      id: 13
    },
    {
      label: t(`${labelAmend}.bead_size_two`),
      value: machine.mechanical_config?.beads_size_2 ? `${machine.mechanical_config.beads_size_2} mm` : '---',
      id: 14
    },
    {
      label: t(`${labelAmend}.bead_filling`),
      value: machine.mechanical_config?.beads_filling_level
        ? `${machine.mechanical_config.beads_filling_level}%`
        : '---',
      id: 15
    }
  ];

  return (
    <>
      <Card sx={{ p: 4, my: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '25px' }}>
          <NotifyTypo.Body1
            fontWeight="500"
            sx={{
              color: '#7A7D85',
              fontSize: 20
            }}
          >
            {t(`${labelAmend}.license_dashboard`)}
          </NotifyTypo.Body1>
          <Stack direction="row" spacing={2} sx={{ ml: 2, alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '16px',
                height: 20
              }}
            >
              <NotifyTypo.Subtitle1 sx={{ color: '#7A7D85', fontSize: 12, height: 20 }}>
                {t(`${labelAmend}.monitoring_points`)}
              </NotifyTypo.Subtitle1>
              <Box
                sx={{
                  height: '20px',
                  borderRadius: '100px',
                  background: 'rgba(51, 141, 133, 0.50)',
                  color: '#fff',
                  fontSize: 14,
                  display: 'flex',
                  minWidth: '32px',
                  padding: '0 10px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px'
                }}
              >
                {machine?.machine_metrics?.total_machine_data_points || 0}
              </Box>
            </Box>
            <Box
              visibility="hidden"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <NotifyTypo.Subtitle1 sx={{ color: '#000' }}>{t(`${labelAmend}.number_tasks`)}</NotifyTypo.Subtitle1>
              <Box
                sx={{
                  ml: 1,
                  height: '24px',
                  borderRadius: '10px',
                  padding: '0px 6px 0px 6px',
                  background: 'rgba(88, 186, 170, .2)'
                }}
              >
                {machine?.machine_metrics?.total_machine_tasks || 0}
              </Box>
            </Box>
          </Stack>
        </Box>
      </Card>
      <Card sx={{ p: 4, my: 2 }}>
        <CardHeader
          sx={{ p: 0, alignItems: 'flex-start', mb: 3 }}
          action={
            <Button
              variant="text"
              sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
              onClick={() => setEditMachineModal(true)}
            >
              <NotifyIcon iconName="edit" fontSize="medium" />
              {t(`${labelAmend}.edit`)}
            </Button>
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.details`)}</Box>
            </Box>
          }
          titleTypographyProps={{
            fontWeight: '500',
            variant: 'Body1'
          }}
        />
        <NotifyTypo.Body1 fontWeight="500">{t(`${labelAmend}.basic_information`)}</NotifyTypo.Body1>
        <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2} sx={{ mt: 3, mb: 4 }}>
          {machine.brand !== 'OTHER' ? (
            <>
              {machineDetails.map(item => (
                <Box gridColumn="span 2" key={item.id}>
                  <div>
                    <NotifyTypo.InfoText fontWeight="400">{item.label}</NotifyTypo.InfoText>
                    {item.id === 'commission_number' ? (
                      item.value
                    ) : (
                      <NotifyTypo.Body1 fontWeight="400">{item.value}</NotifyTypo.Body1>
                    )}
                  </div>
                </Box>
              ))}
            </>
          ) : (
            <>
              {machineDetailsOther.map(item => (
                <Box gridColumn="span 2" key={item.id}>
                  <div>
                    <NotifyTypo.InfoText fontWeight="400">{item.label}</NotifyTypo.InfoText>
                    <NotifyTypo.Body1 fontWeight="400">{item.value}</NotifyTypo.Body1>
                  </div>
                </Box>
              ))}
            </>
          )}
        </Box>
        <NotifyTypo.Body1 fontWeight="500">{t(`${labelAmend}.technical_information`)}</NotifyTypo.Body1>
        {machine.other_brand_technical_info ? (
          <Box id="technical-info" sx={{ mt: 3, color: '#7A7D85', fontSize: '14px' }} />
        ) : (
          <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2} sx={{ mt: 3 }}>
            {machineTec.map(item => (
              <Box gridColumn="span 2" key={item.id}>
                <div>
                  <NotifyTypo.InfoText fontWeight="400">{item.label}</NotifyTypo.InfoText>
                  <NotifyTypo.Body1 fontWeight="400">{item.value}</NotifyTypo.Body1>
                </div>
              </Box>
            ))}
          </Box>
        )}
      </Card>
      <Gateway />
      <CreateEditMachineDetails
        handleClose={handleCloseEditMachineModal}
        open={editMachineModal}
        machineDetail={machine}
      />
    </>
  );
};

MachineDetailsContent.propTypes = {
  machine: T.shape({
    brand: T.string,
    created_at: T.string,
    tag_id: T.string,
    production_line: T.string,
    name: T.string,
    type: T.string,
    id_number: T.string,
    commission_number: T.string,
    other_brand: T.string,
    other_type: T.string,
    other_brand_technical_info: T.string,
    mechanical_config: T.shape({
      beads_filling_level: T.number,
      beads_size_2: T.number,
      beads_size_1: T.number,
      bead_material: T.string,
      sieve_size: T.number,
      inner_liner_material: T.string,
      grinding_technology: T.string,
      samba_system: T.bool
    }),
    machine_metrics: T.shape({
      total_machine_data_points: T.number,
      total_machine_tasks: T.number
    }),
    validations: T.shape({
      commission_number: T.bool
    })
  }),
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

MachineDetailsContent.defaultProps = {
  machine: null
};

export default MachineDetailsContent;
