import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMachinesListResumed } from 'redux/machines/selectors';
import Machines from './Machines';
import { loadMachinesResumed } from '../../redux/machines/actions';

const ConnectedMachines = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getMachinesListResumed);

  useEffect(() => {
    dispatch(loadMachinesResumed());
  }, [dispatch]);

  return <Machines loadingState={loadingState} reloadAction={() => dispatch(loadMachinesResumed())} machines={data} />;
};

export default ConnectedMachines;
