import { call, put, takeLatest } from 'redux-saga/effects';
import * as constants from './constants';
import { actionStatus, getError, statusAction } from '../utils';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';
import * as api from '../../api/machines_v2';

/**
 *  Gateways V2 - Get gateway by ID
 * @param {
 *   {
 *     gateway_id: string|undefined,
 *     organization_id: string|undefined,
 *     organization_name: string|undefined,
 *     commission_number: string|undefined,
 *     serial_number: string|undefined,
 *     limit: number|10,
 *     page: number|1,
 *     order: "DESC"|"ASC"|undefined,
 *     sort: string|undefined
 *   }
 * } params
 * @returns {Generator<*, void, *>}
 */
function* handlerMachinesV2ListResumed({ params }) {
  yield put(statusAction(constants.MACHINES_V2_LIST_RESUMED, actionStatus.START));

  try {
    const { data } = yield api.apiMachinesV2ListMachinesResumed(params);
    yield put(statusAction(constants.MACHINES_V2_LIST_RESUMED, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.MACHINES_V2_LIST_RESUMED, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways_v2.${error}`
      })
    );
  }
}

function* handleLoadIrisTableNames({ machineId }) {
  yield put(statusAction(constants.LOAD_IRIS_TABLE_NAMES, actionStatus.START));

  try {
    const { data } = yield api.loadIrisTableNames(machineId);
    yield put(statusAction(constants.LOAD_IRIS_TABLE_NAMES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_IRIS_TABLE_NAMES, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateIrisTableNames({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_IRIS_TABLE_NAMES, actionStatus.START));
  try {
    const { data } = yield api.updateIrisTableNames(machineId, payload);
    yield put(statusAction(constants.UPDATE_IRIS_TABLE_NAMES, actionStatus.SUCCESS, { payload: data }));
    yield call(handleLoadIrisTableNames, { machineId });
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.machine_details.edit_database_table_name.notifications.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_IRIS_TABLE_NAMES, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadIrisAutoConfig({ machineId }) {
  yield put(statusAction(constants.LOAD_IRIS_AUTO_CONFIG, actionStatus.START));

  try {
    const { data } = yield api.loadIrisAutoConfig(machineId);
    yield put(statusAction(constants.LOAD_IRIS_AUTO_CONFIG, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);

    // TODO: uncomment when backend is ready
    // yield put(
    //   statusAction(constants.LOAD_IRIS_AUTO_CONFIG, actionStatus.ERROR, {
    //     message: error
    //   })
    // );

    // TODO: Remove the block below when backend is ready

    // Temp function for mimic data
    const getCurrentDate = () => {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
      const year = today.getFullYear();

      return `${day}/${month}/${year}`;
    };

    const mockData = [
      {
        type: 'PLC',
        lastUpdate: '08/08/2024'
      },
      {
        type: 'SENSOR_DATA',
        lastUpdate: getCurrentDate(),
        detected: 10,
        has_changes: true
      },
      {
        type: 'STATIC_VALUES',
        lastUpdate: '08/08/2024',
        detected: 2
      },
      {
        type: 'STATUS',
        lastUpdate: getCurrentDate(),
        detected: 1,
        has_changes: true
      },
      {
        type: 'MESSAGES',
        detected: 1,
        lastUpdate: '08/08/2024'
      },
      {
        type: 'BATCH_RECORDS',
        lastUpdate: '08/08/2024'
      }
    ];

    yield put(statusAction(constants.LOAD_IRIS_AUTO_CONFIG, actionStatus.SUCCESS, { payload: mockData }));

    // TODO: Remove the block when backend is ready till here

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateIrisAutoConfig({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_IRIS_AUTO_CONFIG, actionStatus.START));
  try {
    const { data } = yield api.updateIrisAutoConfig(machineId, payload);
    yield put(statusAction(constants.UPDATE_IRIS_AUTO_CONFIG, actionStatus.SUCCESS, { payload: data }));
    yield call(handleLoadIrisAutoConfig, { machineId });
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.detail.auto_config.notifications.success'
      })
    );
  } catch (err) {
    const error = getError(err);

    yield put(
      statusAction(constants.UPDATE_IRIS_AUTO_CONFIG, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: 'machines.detail.auto_config.notifications.error'
      })
    );
  }
}

function* handleLoadStaticValuesIrisV3({ machineId }) {
  yield put(statusAction(constants.LOAD_STATIC_VALUE_IRIS_V3, actionStatus.START));
  try {
    const { data } = yield api.loadStaticValuesIrisV3(machineId);
    yield put(statusAction(constants.LOAD_STATIC_VALUE_IRIS_V3, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_STATIC_VALUE_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleCreateStaticValueIrisV3({ machineId, payload }) {
  yield put(statusAction(constants.CREATE_STATIC_VALUE_IRIS_V3, actionStatus.START));

  try {
    const { data } = yield api.createStaticValueIrisV3(machineId, payload);
    yield put(statusAction(constants.CREATE_STATIC_VALUE_IRIS_V3, actionStatus.SUCCESS, { payload: data }));

    yield call(handleLoadStaticValuesIrisV3, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.machine_details.delete_modal.static_values_notifications.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_STATIC_VALUE_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateStaticValueIrisV3({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_STATIC_VALUE_IRIS_V3, actionStatus.START));

  try {
    const { data } = yield api.updateStaticValueIrisV3(machineId, payload);
    yield put(statusAction(constants.UPDATE_STATIC_VALUE_IRIS_V3, actionStatus.SUCCESS, { payload: data }));

    yield call(handleLoadStaticValuesIrisV3, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.machine_details.delete_modal.static_values_notifications.success_edited'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_STATIC_VALUE_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleDeleteSensorIrisV3({ machineId, selectedItemId }) {
  yield put(statusAction(constants.DELETE_SENSOR_IRIS_V3, actionStatus.START));

  try {
    const { data } = yield api.deleteSensorIrisV3(machineId, selectedItemId);
    yield put(statusAction(constants.DELETE_SENSOR_IRIS_V3, actionStatus.SUCCESS, { payload: data }));

    yield call(handleLoadStaticValuesIrisV3, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.machine_details.delete_modal.static_values_notifications.success_delete'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.DELETE_SENSOR_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateSensorStatusIrisV3({ machineId, staticSensor }) {
  yield put(statusAction(constants.UPDATE_SENSOR_STATUS_IRIS_V3, actionStatus.START));
  try {
    const { data } = yield api.updateSensorStatusIrisV3(machineId, staticSensor);
    yield put(statusAction(constants.UPDATE_SENSOR_STATUS_IRIS_V3, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.machine_details.sensor_static_value_success_update'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_SENSOR_STATUS_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadMessagesIrisV3({ machineId }) {
  yield put(statusAction(constants.LOAD_MESSAGES_IRIS_V3, actionStatus.START));

  try {
    const { data } = yield api.loadMessagesIrisV3(machineId);

    yield put(statusAction(constants.LOAD_MESSAGES_IRIS_V3, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MESSAGES_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateMessagesMachineIrisV3({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_MESSAGES_MACHINE_IRIS_V3, actionStatus.START));

  try {
    const { data } = yield api.updateMessagesMachineIrisV3(machineId, payload);
    yield put(statusAction(constants.UPDATE_MESSAGES_MACHINE_IRIS_V3, actionStatus.SUCCESS, { payload: data }));
    yield call(handleLoadMessagesIrisV3, { machineId });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.messages.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_MESSAGES_MACHINE_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

function* handleLoadStatusMachineIrisV3({ machineId }) {
  yield put(statusAction(constants.LOAD_STATUS_MACHINE_IRIS_V3, actionStatus.START));
  try {
    const { data } = yield api.loadStatusMachineIrisV3(machineId);
    yield put(statusAction(constants.LOAD_STATUS_MACHINE_IRIS_V3, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_STATUS_MACHINE_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateStatusMachineIrisV3({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_STATUS_MACHINE_IRIS_V3, actionStatus.START));

  try {
    const { data } = yield api.updateStatusMachineIrisV3(machineId, payload);
    yield put(statusAction(constants.UPDATE_STATUS_MACHINE_IRIS_V3, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.form.machine_status_irisv3.notifications.success_edited'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_STATUS_MACHINE_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

function* handleLoadStatusMachineConditionsIrisV3({ machineId }) {
  yield put(statusAction(constants.LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3, actionStatus.START));
  try {
    const { data } = yield api.loadStatusMachineConditionsIrisV3(machineId);
    yield put(statusAction(constants.LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateStatusMachineConditionsIrisV3({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3, actionStatus.START));

  try {
    const { data } = yield api.updateStatusMachineConditionsIrisV3(machineId, payload);
    yield put(
      statusAction(constants.UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3, actionStatus.SUCCESS, { payload: data })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.form.machine_status_conditions.notifications.success_edited'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

/**
 * Load Iris Batch Details
 * @param machineId
 * @return {Generator<*, void, *>}
 */
function* handleLoadIrisBatchDetails({ machineId }) {
  yield put(statusAction(constants.LOAD_IRIS_BATCH_DETAILS, actionStatus.START));

  try {
    const { data } = yield api.loadIrisBatchDetails(machineId);
    yield put(statusAction(constants.LOAD_IRIS_BATCH_DETAILS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_IRIS_BATCH_DETAILS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

/**
 * Update Iris Batch Details
 * @param {string} machineId
 * @param {{
 *   batch_id_variable: string,
 *   batch_size_variable: string,
 *   end_time_variable: string,
 *   recipe_variable: string,
 *   start_time_variable: string
 * }} payload - The payload to update the batch details
 * @return {Generator<*, void, *>}
 */
function* handleUpdateIrisBatchDetails({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_IRIS_BATCH_DETAILS, actionStatus.START));

  try {
    const { data } = yield api.updateIrisBatchDetails(machineId, payload);
    yield put(statusAction(constants.UPDATE_IRIS_BATCH_DETAILS, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.form.batch_records_iris.notifications.details_updated'
      })
    );
    yield call(handleLoadIrisBatchDetails, { machineId });
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_IRIS_BATCH_DETAILS, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

/**
 * Load Iris Batch Status
 * @param machineId
 * @return {Generator<*, void, *>}
 */
function* handleLoadIrisBatchStatus({ machineId }) {
  yield put(statusAction(constants.LOAD_IRIS_BATCH_STATUS, actionStatus.START));

  try {
    const { data } = yield api.loadIrisBatchStatus(machineId);
    yield put(statusAction(constants.LOAD_IRIS_BATCH_STATUS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_IRIS_BATCH_STATUS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

/**
 * Update Iris Batch Status
 * @param {string} machineId
 * @param {{
 *   status_cancelled_value: number,
 *   status_completed_before_target_value: number,
 *   status_completed_value: number,
 *   status_completed_with_target_updates_value: number,
 *   status_in_operation_value: number,
 *   status_loading_value: number,
 *   status_stopped_value: number,
 *   status_variable: string
 * }} payload - The payload to update the batch status
 * @return {Generator<*, void, *>}
 */
function* handleUpdateIrisBatchStatus({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_IRIS_BATCH_STATUS, actionStatus.START));

  try {
    const { data } = yield api.updateIrisBatchStatus(machineId, payload);
    yield put(statusAction(constants.UPDATE_IRIS_BATCH_STATUS, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.form.batch_records_iris.notifications.status_updated'
      })
    );
    yield call(handleLoadIrisBatchStatus, { machineId });
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_IRIS_BATCH_STATUS, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

/**
 * Load Iris Batch Target
 * @param machineId
 * @return {Generator<*, void, *>}
 */
function* handleLoadIrisBatchTarget({ machineId }) {
  yield put(statusAction(constants.LOAD_IRIS_BATCH_TARGET, actionStatus.START));

  try {
    const { data } = yield api.loadIrisBatchTarget(machineId);
    yield put(statusAction(constants.LOAD_IRIS_BATCH_TARGET, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_IRIS_BATCH_TARGET, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

/**
 * Update Iris Batch Target
 * @param {string} machineId
 * @param {{
 *   target_comment_1: string,
 *   target_comment_2: string,
 *   target_comment_3: string,
 *   target_comment_4: string,
 *   target_type_variable: string,
 *   target_value_variable: string
 * }} payload - The payload to update the batch target
 * @return {Generator<*, void, *>}
 */
function* handleUpdateIrisBatchTarget({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_IRIS_BATCH_TARGET, actionStatus.START));

  try {
    const { data } = yield api.updateIrisBatchTarget(machineId, payload);
    yield put(statusAction(constants.UPDATE_IRIS_BATCH_TARGET, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.form.batch_records_iris.notifications.target_updated'
      })
    );
    yield call(handleLoadIrisBatchTarget, { machineId });
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_IRIS_BATCH_TARGET, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

/**
 * Load Iris Batch Limits
 * @param machineId
 * @return {Generator<*, void, *>}
 */
function* handleLoadIrisBatchLimits({ machineId }) {
  yield put(statusAction(constants.LOAD_IRIS_BATCH_LIMITS, actionStatus.START));

  try {
    const { data } = yield api.loadIrisBatchLimits(machineId);
    yield put(statusAction(constants.LOAD_IRIS_BATCH_LIMITS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_IRIS_BATCH_LIMITS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

/**
 * Update Iris Batch Limits
 * @param {string} machineId
 * @param {{
 *   dry_running_flow_protection_variable: string,
 *   dry_running_pressure_protection_variable: string,
 *   high_critical_pressure_inlet_variable: string,
 *   high_critical_product_outlet_temperature_variable: string,
 *   high_warning_pressure_inlet_variable: string,
 *   high_warning_product_outlet_temperature_variable: string,
 *   sieve_cleaning_pressure_variable: string
 * }} payload - The payload to update the batch limits
 * @return {Generator<*, void, *>}
 */
function* handleUpdateIrisBatchLimits({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_IRIS_BATCH_LIMITS, actionStatus.START));

  try {
    const { data } = yield api.updateIrisBatchLimits(machineId, payload);
    yield put(statusAction(constants.UPDATE_IRIS_BATCH_LIMITS, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.form.batch_records_iris.notifications.limits_updated'
      })
    );
    yield call(handleLoadIrisBatchLimits, { machineId });
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_IRIS_BATCH_LIMITS, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

/**
 * Load Iris Batch Measurements
 * @param machineId
 * @return {Generator<*, void, *>}
 */
function* handleLoadIrisBatchMeasurements({ machineId }) {
  yield put(statusAction(constants.LOAD_IRIS_BATCH_MEASUREMENTS, actionStatus.START));

  try {
    const { data } = yield api.loadIrisBatchMeasurements(machineId);
    yield put(statusAction(constants.LOAD_IRIS_BATCH_MEASUREMENTS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_IRIS_BATCH_MEASUREMENTS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

/**
 * Update Iris Batch Measurements
 * @param {string} machineId
 * @param {{
 *   current_cycles_variable: string,
 *   estimated_finish_time_variable: string,
 *   grinding_energy_variable: string,
 *   number_of_passes_variable: string,
 *   progress_variable: string,
 *   specific_energy_variable: string,
 *   total_grinding_energy_variable: string,
 *   total_grinding_time_variable: string
 * }} payload - The payload to update the batch measurements
 * @return {Generator<*, void, *>}
 */
function* handleUpdateIrisBatchMeasurements({ machineId, payload }) {
  yield put(statusAction(constants.UPDATE_IRIS_BATCH_MEASUREMENTS, actionStatus.START));

  try {
    const { data } = yield api.updateIrisBatchMeasurements(machineId, payload);
    yield put(statusAction(constants.UPDATE_IRIS_BATCH_MEASUREMENTS, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.form.batch_records_iris.notifications.measurements_updated'
      })
    );
    yield call(handleLoadIrisBatchMeasurements, { machineId });
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_IRIS_BATCH_MEASUREMENTS, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${(error || '').toLowerCase()}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.MACHINES_V2_LIST_RESUMED, handlerMachinesV2ListResumed);
  yield takeLatest(constants.LOAD_IRIS_TABLE_NAMES, handleLoadIrisTableNames);
  yield takeLatest(constants.UPDATE_IRIS_TABLE_NAMES, handleUpdateIrisTableNames);
  yield takeLatest(constants.LOAD_IRIS_AUTO_CONFIG, handleLoadIrisAutoConfig);
  yield takeLatest(constants.UPDATE_IRIS_AUTO_CONFIG, handleUpdateIrisAutoConfig);
  yield takeLatest(constants.CREATE_STATIC_VALUE_IRIS_V3, handleCreateStaticValueIrisV3);
  yield takeLatest(constants.LOAD_STATIC_VALUE_IRIS_V3, handleLoadStaticValuesIrisV3);
  yield takeLatest(constants.UPDATE_STATIC_VALUE_IRIS_V3, handleUpdateStaticValueIrisV3);
  yield takeLatest(constants.DELETE_SENSOR_IRIS_V3, handleDeleteSensorIrisV3);
  yield takeLatest(constants.UPDATE_SENSOR_STATUS_IRIS_V3, handleUpdateSensorStatusIrisV3);
  yield takeLatest(constants.LOAD_MESSAGES_IRIS_V3, handleLoadMessagesIrisV3);
  yield takeLatest(constants.UPDATE_MESSAGES_MACHINE_IRIS_V3, handleUpdateMessagesMachineIrisV3);
  yield takeLatest(constants.LOAD_STATUS_MACHINE_IRIS_V3, handleLoadStatusMachineIrisV3);
  yield takeLatest(constants.UPDATE_STATUS_MACHINE_IRIS_V3, handleUpdateStatusMachineIrisV3);
  yield takeLatest(constants.LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3, handleLoadStatusMachineConditionsIrisV3);
  yield takeLatest(constants.UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3, handleUpdateStatusMachineConditionsIrisV3);
  yield takeLatest(constants.LOAD_IRIS_BATCH_DETAILS, handleLoadIrisBatchDetails);
  yield takeLatest(constants.UPDATE_IRIS_BATCH_DETAILS, handleUpdateIrisBatchDetails);
  yield takeLatest(constants.LOAD_IRIS_BATCH_STATUS, handleLoadIrisBatchStatus);
  yield takeLatest(constants.UPDATE_IRIS_BATCH_STATUS, handleUpdateIrisBatchStatus);
  yield takeLatest(constants.LOAD_IRIS_BATCH_TARGET, handleLoadIrisBatchTarget);
  yield takeLatest(constants.UPDATE_IRIS_BATCH_TARGET, handleUpdateIrisBatchTarget);
  yield takeLatest(constants.LOAD_IRIS_BATCH_LIMITS, handleLoadIrisBatchLimits);
  yield takeLatest(constants.UPDATE_IRIS_BATCH_LIMITS, handleUpdateIrisBatchLimits);
  yield takeLatest(constants.LOAD_IRIS_BATCH_MEASUREMENTS, handleLoadIrisBatchMeasurements);
  yield takeLatest(constants.UPDATE_IRIS_BATCH_MEASUREMENTS, handleUpdateIrisBatchMeasurements);
}
