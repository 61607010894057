import React from 'react';
import T from 'prop-types';
import { StyledContentWrapper } from '../../../../components/forms/Machines/MachineDetail/elements';
import ConnectedErrorCodeContent from './ConnectedErrorCodeContent';

const ErrorCode = ({ plcType }) => (
  <>
    <StyledContentWrapper elevation={3}>
      <ConnectedErrorCodeContent plcType={plcType} />
    </StyledContentWrapper>
  </>
);

ErrorCode.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default ErrorCode;
