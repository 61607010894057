import { MACHINE_DEFAULT_LIST_SORT } from 'attrs/machines';
import { CUSTOMER_DEFAULT_LIST_ORDER, CUSTOMER_DEFAULT_LIST_SORT } from '../../attrs/customer';
import {
  PAGINATION_ASC,
  PAGINATION_DEFAULT_ROWS_PER_PAGE,
  PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
  PAGINATION_DEFAULT_START_PAGE
} from '../../attrs/pagination';
import * as constants from './constants';
import { CHANGE_ORGANIZATIONS_FILTER_RESUME, CHANGE_ORGANIZATIONS_ORDER_RESUME } from './constants';

const loadOrganizations = () => ({
  type: constants.LOAD_ORGANIZATIONS
});

const loadOrganization = id => ({
  type: constants.LOAD_ORGANIZATION,
  id
});

const loadOrganizationsByResume = (
  sapId = null,
  customerName = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = CUSTOMER_DEFAULT_LIST_ORDER,
  sort = CUSTOMER_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_ORGANIZATIONS_RESUME,
  sapId,
  customerName,
  limit,
  page,
  order,
  sort
});

const setOrganizationSortOrder = (sort, order) => ({
  type: CHANGE_ORGANIZATIONS_ORDER_RESUME,
  sort,
  order
});

const setOrganizationFilter = (page, limit, order, sort, sapId, customerName) => ({
  type: CHANGE_ORGANIZATIONS_FILTER_RESUME,
  sapId,
  customerName,
  limit,
  page,
  order,
  sort
});

const createOrganization = ({ values, action }) => ({
  type: constants.CREATE_ORGANIZATION,
  values,
  action
});

const updateOrganization = (id, values) => ({
  type: constants.UPDATE_ORGANIZATION,
  id,
  values
});

const verifySapId = sapAccount => ({
  type: constants.VERIFY_SAP_ID,
  sapAccount
});

const resetSapStatus = status => ({
  type: constants.RESET_SAP_STATUS,
  status
});

/**
 * Action to sagas - Get organization products access
 * @param {string} organizationId
 * @returns {{organizationId, type: string}}
 */
const getOrganizationProductsAccessAction = organizationId => ({
  type: constants.GET_ORGANIZATION_PRODUCTS_ACCESS,
  organizationId
});

/** Version 02 */

const getOrganizationMachinesList = (
  organizationId = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  sort = MACHINE_DEFAULT_LIST_SORT,
  order = PAGINATION_ASC
) => ({
  type: constants.ORGANIZATION_MACHINES_LIST,
  organizationId,
  limit,
  page,
  sort,
  order
});

const getMachinesFiltered = (organizationId, serialNumber, commissionNumber, limit, page, sort, order) => ({
  type: constants.LOAD_MACHINES_RESUME_LIST_WITH_FILTER,
  organizationId,
  serialNumber,
  commissionNumber,
  limit,
  page,
  sort,
  order
});

export {
  createOrganization,
  getMachinesFiltered,
  getOrganizationMachinesList,
  getOrganizationProductsAccessAction,
  loadOrganization,
  loadOrganizations,
  loadOrganizationsByResume,
  resetSapStatus,
  setOrganizationFilter,
  setOrganizationSortOrder,
  updateOrganization,
  verifySapId
};
