import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import T from 'prop-types';
import PlcContent from './PlcContent';
import { loadPlcMachine, loadPlcMachineIris } from '../../../../redux/machines/actions';
import { getSelectedPlcMachine } from '../../../../redux/machines/selectors';
import { PlcType } from '../../../../attrs/plcConfigValues';

const ConnectedPlcContent = ({ plcType }) => {
  const dispatch = useDispatch();
  const { loadingState, data, loadingIrisState, irisData } = useSelector(getSelectedPlcMachine);
  const { machineId } = useParams();

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(loadPlcMachineIris(machineId));
    } else {
      dispatch(loadPlcMachine(machineId));
    }
  }, [dispatch, machineId, plcType.plc_type]);

  return (
    <PlcContent
      loadingState={plcType.plc_type === PlcType.IRIS_V3 ? loadingIrisState : loadingState}
      reloadAction={() => {
        if (plcType.plc_type === PlcType.IRIS_V3) {
          dispatch(loadPlcMachineIris(machineId));
        } else {
          dispatch(loadPlcMachine(machineId));
        }
      }}
      data={plcType.plc_type === PlcType.IRIS_V3 ? irisData : data}
      machineId={machineId}
      plcType={plcType}
    />
  );
};

ConnectedPlcContent.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default ConnectedPlcContent;
