/* eslint-disable no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { API_URL } from '../attrs/settings';

function getAccessToken() {
  return localStorage.getItem('accessToken');
}

function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

const instance = axios.create({
  baseURL: API_URL
});

const refreshInstance = axios.create({
  baseURL: API_URL
});

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest =>
  refreshInstance
    .get('/v1/tokens', {
      headers: {
        Authorization: `Bearer ${getRefreshToken()}`
      }
    })
    .then(tokenRefreshResponse => {
      localStorage.setItem('accessToken', tokenRefreshResponse.data.access);
      localStorage.setItem('refreshToken', tokenRefreshResponse.data.refresh);
      failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access}`;

      return Promise.resolve();
    })
    .catch(() => {
      // If a second time access error: remove token and force redirection to login page
      // TODO: Handle it via saga, add session expired route(?) information
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      if (window.location.pathname !== '/login') {
        window.location = '/login';
      }
      window.location.reload();
    });

instance.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;

  if (!!request && !!request.params) {
    Object.keys(request.params).map(filter => {
      if (request.params[filter] === '') {
        delete request.params[filter];
      }
      return request;
    });
  }
  return request;
});

createAuthRefreshInterceptor(instance, refreshAuthLogic, {
  statusCodes: [401, 403]
});

export default instance;
