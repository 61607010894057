import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import EditPlc from './EditPlcModal/EditPlcModal';
import withLoadingState from '../../../../helpers/withLoadingState';
import { getPlcProtocolName, PlcType } from '../../../../attrs/plcConfigValues';
import { defaultEmptyCellValue, getValueToNullableCell } from '../../../../helpers/stringHandler';

const labelAmend = 'machines.machine_details';

const PlcContent = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  function preparePlcData() {
    if (!data || !data.type || data.type === PlcType.UNKNOWN || plcType.plc_type === PlcType.UNKNOWN) {
      return {
        name: defaultEmptyCellValue,
        address: defaultEmptyCellValue,
        port: defaultEmptyCellValue,
        rack: defaultEmptyCellValue,
        slot: defaultEmptyCellValue,
        type: defaultEmptyCellValue,
        database_name: defaultEmptyCellValue,
        variables_table_name: defaultEmptyCellValue
      };
    }

    return {
      name: getPlcProtocolName(data.type),
      address: getValueToNullableCell(data.address),
      port: getValueToNullableCell(data.port),
      rack: getValueToNullableCell(data.rack),
      slot: getValueToNullableCell(data.slot),
      type: getValueToNullableCell(data.type),
      database_name: getValueToNullableCell(data.database_name),
      variables_table_name: getValueToNullableCell(data.variables_table_name)
    };
  }

  const plcData = preparePlcData();
  const isSiemensField = field =>
    (data || {}).type === PlcType.SIEMENS_S7 && ['address', 'port', 'rack', 'slot'].includes(field);
  const isRockwellField = field => (data || {}).type === PlcType.ROCKWELL && ['address', 'slot'].includes(field);
  const isIrisField = field =>
    (data || {}).type === PlcType.IRIS_V3 && ['database_name', 'variables_table_name'].includes(field);

  const needToShowField = field =>
    !(data || {}).type ||
    (data || {}).type === PlcType.UNKNOWN ||
    plcType.plc_type === PlcType.UNKNOWN ||
    isSiemensField(field) ||
    isRockwellField(field) ||
    isIrisField(field);

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Button
            variant="text"
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
            onClick={() => setEditModal(true)}
          >
            <NotifyIcon iconName="edit" fontSize="medium" />
            {t(`${labelAmend}.edit`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.plc`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.plc_info`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gap={2} sx={{ mt: 3, mb: 4 }}>
        <Box gridColumn="span 2">
          <div>
            <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.plc_protocol`)}</NotifyTypo.InfoText>
            <NotifyTypo.Body1>{plcData.name}</NotifyTypo.Body1>
          </div>
        </Box>
        {needToShowField('address') && (
          <Box gridColumn="span 2">
            <div>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.plc_ip`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{plcData.address}</NotifyTypo.Body1>
            </div>
          </Box>
        )}
        {needToShowField('port') && (
          <Box gridColumn="span 2">
            <div>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.plc_port`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{plcData.port}</NotifyTypo.Body1>
            </div>
          </Box>
        )}
        {needToShowField('rack') && (
          <Box gridColumn="span 2">
            <div>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.plc_rack`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{plcData.rack}</NotifyTypo.Body1>
            </div>
          </Box>
        )}
        {needToShowField('slot') && (
          <Box gridColumn="span 2">
            <div>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.plc_slot`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{plcData.slot}</NotifyTypo.Body1>
            </div>
          </Box>
        )}
        {needToShowField('database_name') && (
          <Box gridColumn="span 2">
            <div>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.plc_database_name`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{plcData.database_name}</NotifyTypo.Body1>
            </div>
          </Box>
        )}
        {needToShowField('variables_table_name') && (
          <Box gridColumn="span 2">
            <div>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.plc_variables_table_name`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1>{plcData.variables_table_name}</NotifyTypo.Body1>
            </div>
          </Box>
        )}
      </Box>

      <EditPlc open={editModal} handleClose={handleClose} machineId={machineId} data={data} />
    </Card>
  );
};

PlcContent.propTypes = {
  data: T.shape({
    address: T.string,
    port: T.number,
    rack: T.number,
    slot: T.number,
    type: T.string,
    database_name: T.string,
    variables_table_name: T.string
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

PlcContent.defaultProps = {
  data: null
};

export default withLoadingState(PlcContent);
