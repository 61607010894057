import React from 'react';
import styled from 'styled-components';
import { Box, Stack } from '@mui/material';

import { NewDialog } from 'web-components';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const StyledNewDialog = styled(NewDialog)`
  .MuiDialog-paper {
    border-radius: 0.625rem;
    width: 100%;
    form {
      width: 100%;
    }
  }
  h2:first-of-type {
    padding-bottom: unset;
    font-size: 1.75rem;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
`;

export const RadioLabel = styled.label`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  display: flex;
  span {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
`;

export const StyledHandlers = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledHandlersCustom = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledStack = styled(Stack)`
  .MuiFormControl-fullWidth {
    max-width: 284px;
  }
  .Mui-disabled {
    background-color: #d6d6d6 !important;
  }
`;

export const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

export const AccordionSummary = styled(props => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  padding: '0 !important',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: 20
  }
}));

export const AccordionSummarySimple = styled(props => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(() => ({
  flexDirection: 'row',
  padding: '0 !important',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: 5
  }
}));

export const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const StyledContentGrid = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 27px;
`;

export const StyledGridItem = styled(Box)`
  width: 50%;
`;

export const StyledGridItemSmall = styled(Box)`
  width: 25%;
`;

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({}));
