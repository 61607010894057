import { makeStyles } from '@mui/styles';

export const MenuUseStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#2B2E42',
    color: '#FFF',
    marginTop: 48,
    marginLeft: 48,
    borderRadius: 16,
    padding: 8,
    alignSelf: 'flex-start',
    boxShadow: '0px 4px 16px 0px rgba(17, 17, 26, 0.05), 0px 8px 32px 0px rgba(17, 17, 26, 0.03)'
  },
  drawerOpen: {
    width: 280,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    width: 96,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    '& $collapseContainer, & $listItem': {
      justifyContent: 'space-around'
    }
  },
  activeItem: {
    backgroundColor: '#2BACA4',
    '&:hover': {
      backgroundColor: 'rgba(50,204,195,1) !important'
    }
  },
  listItem: {
    height: 64,
    borderRadius: 16,
    margin: '2px 0',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      backgroundColor: 'rgba(43,172,164,0.5)'
    },
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  listItemClosed: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  listItemTextClosed: {
    display: 'none',
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  listItemTextOpen: {
    display: 'block',
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  categoryText: {
    color: '#BCBDC1',
    fontSize: 14,
    textTransform: 'uppercase',
    '& span': {
      color: '#BCBDC1',
      fontSize: 14,
      textTransform: 'uppercase'
    }
  },
  listItemTextClosedCategory: {
    '& span': {
      fontSize: 12
    }
  },
  collapseContainer: {
    height: 48,
    display: 'flex',
    padding: '0px 16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
    flex: '1 0 0',
    alignSelf: 'stretch'
  },
  collapseIcon: {
    width: 28,
    height: 28,
    border: '1px solid #FFF',
    borderRadius: '8px',
    margin: '20px 0'
  },
  badge: {
    display: 'inline',
    '& span': {
      backgroundColor: '#FFF',
      color: '#2B2E42',
      fontSize: 14,
      fontWeight: 500,
      position: 'relative',
      display: 'inline',
      padding: '0 10px'
    }
  },
  badgeClosed: {
    marginTop: 0
  },
  badgeOpen: {
    marginLeft: theme.spacing(2)
  },
  badgeNoBackground: {
    '& span': {
      color: '#BCBDC1',
      backgroundColor: 'transparent',
      fontWeight: 'normal'
    }
  },
  badgeActive: {
    '& span': {
      color: '#2BACA4',
      fontWeight: 500
    }
  },
  badgeWarning: {
    '& span': {
      color: '#F13309 !important',
      fontWeight: 500
    }
  },
  categoryTextActive: {
    color: '#FFF',
    backgroundColor: 'transparent',
    '& span': {
      color: '#FFF',
      backgroundColor: 'transparent',
      fontWeight: 'normal'
    }
  },
  iconColor: {
    color: '#FFF',
    minWidth: 40
  },
  iconClosed: {
    padding: '0 0 0 8px'
  },
  divider: {
    borderColor: '#BCBDC1'
  }
}));
