import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { ResponsiveIconButton } from 'web-components';
import T from 'prop-types';
import { getLicenses } from 'redux/rootSelectors';
import UsersInviteModal from 'containers/Customers/CustomerDetail/UserManagement/UsersInviteModal';
import { setConfirmationRequest } from '../../../../redux/ui/confirmations/actions';
import { UserInviteSchema } from '../../../../attrs/formValidation';

import { PRODUCT_NOTIFY, PRODUCT_PREVENTIVE } from '../../../../attrs/products';
import { inviteUserV2Action } from '../../../../redux/users_v2/actions';

/**
 * Modal for invite or edit users
 * @param hasUsersInitial
 * @returns {Element}
 * @constructor
 */
const UserInvite = ({ organizationId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data } = useSelector(getLicenses);
  const [open, setOpen] = useState(false);

  const handleFormSubmit = values => {
    dispatch(
      inviteUserV2Action({
        organization_id: organizationId,
        user: {
          email: values.email,
          roles: [
            {
              product: PRODUCT_NOTIFY,
              role: values.role_notify
            },
            {
              product: PRODUCT_PREVENTIVE,
              role: values.role_preventive
            }
          ]
        }
      })
    );
  };

  const handleCloseDialog = dirty => {
    if (dirty) {
      dispatch(
        setConfirmationRequest({
          message: 'dialog.confirmation.form_unsaved_changes',
          action: () => setOpen(false)
        })
      );
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <ResponsiveIconButton
        buttonText={t('customers.user_management.invite_user')}
        color="primary"
        iconName="addUser"
        variant="outlined"
        disabled={data.length === 0}
        sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '156px' }}
        onClick={() => setOpen(true)}
      />

      <Formik
        initialValues={{
          email: '',
          role_notify: '',
          role_preventive: ''
        }}
        onSubmit={values => handleFormSubmit(values)}
        validationSchema={UserInviteSchema(data)}
        enableReinitialize
      >
        <UsersInviteModal organizationId={organizationId} open={open} handleClose={handleCloseDialog} />
      </Formik>
    </>
  );
};

UserInvite.propTypes = {
  organizationId: T.string.isRequired
};

export default UserInvite;
