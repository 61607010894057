// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import axios from './axios';

const loadMachines = (organizationId, gatewayId) =>
  axios({
    method: 'get',
    url: '/v1/machines',
    params: {
      organization_id: organizationId,
      gateway_id: gatewayId
    }
  });

const loadMachinesByResume = (
  organizationId,
  organizationName,
  commissionNumber,
  gatewayId,
  limit,
  page,
  order,
  sort
) =>
  axios({
    method: 'get',
    url: '/v1/machines/resume',
    params: {
      organization_id: organizationId,
      organization_name: organizationName,
      commission_number: commissionNumber,
      gateway_id: gatewayId,
      limit,
      page,
      order,
      sort
    }
  });

const createMachine = values =>
  axios({
    method: 'post',
    url: '/v1/machines',
    data: values
  });

const deleteMachine = machineId =>
  axios({
    method: 'delete',
    url: `/v1/machines/${machineId}`
  });

const verifyCommissionNumber = values =>
  axios({
    method: 'post',
    url: '/v1/validations/commission-number',
    data: values
  });

const updateMachineDetails = (machineId, values) =>
  axios({
    method: 'put',
    url: `/v1/machines/${machineId}`,
    params: {
      machineId
    },
    data: values
  });

const loadCloneableMachines = organizationId =>
  axios({
    method: 'get',
    url: '/v1/machines/cloneable',
    params: {
      organization_id: organizationId
    }
  });

const loadCloneableMachinesPaginated = (
  organizationId,
  commissionNumber,
  machineType,
  plcType,
  customerName,
  limit,
  page,
  order,
  sort
) =>
  axios({
    method: 'get',
    url: '/v1/machines/cloneable/paginated',
    params: {
      organization_id: organizationId,
      commission_number: commissionNumber,
      machine_type: machineType,
      plc_type: plcType,
      customer_name: customerName,
      limit,
      page,
      order,
      sort
    }
  });

const loadCloneableMachinesDetails = ({ machineId, organizationId }) =>
  axios({
    method: 'get',
    url: `/v1/machines/${machineId}/cloneable`,
    params: {
      organization_id: organizationId
    }
  });

const createCloneableMachine = values =>
  axios({
    method: 'post',
    url: `/v1/machines/clone`,
    data: values
  });

/**
 * @param {*} id
 * @param {*} values
 * @returns
 */
const updateMachine = (id, values) =>
  axios({
    method: 'put',
    url: `/v1/machines/${id}`,
    data: values
  });

const updateConfigurationsMachinesGateway = values =>
  axios({
    method: 'put',
    url: `/v1/configurations/machines/gateway-id`,
    data: values
  });

/** Endpoints V2 */

const loadMachinesResumed = (organizationName, commissionNumber, serialNumber, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v2/machines/list/resumed',
    params: {
      organization_name: organizationName,
      commission_number: commissionNumber,
      serial_number: serialNumber,
      limit,
      page,
      order,
      sort
    }
  });

const loadMachinesResumedSearch = params =>
  axios({
    method: 'get',
    url: '/v2/machines/list/resumed',
    params
  });

const createNewMachine = payload =>
  axios({
    method: 'post',
    url: '/v2/machines/details',
    data: payload
  });

const editMachineDetails = payload =>
  axios({
    method: 'put',
    url: '/v2/machines/details',
    data: payload
  });

const loadMachine = id =>
  axios({
    method: 'get',
    url: `/v2/machines/${id}/details`
  });

const loadMachinePlcType = id =>
  axios({
    method: 'get',
    url: `/v2/machines/${id}/current-plc-type`
  });

const loadMetricsMachineDetails = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/metrics`
  });

const loadPlcMachine = id =>
  axios({
    method: 'get',
    url: `/v2/machines/${id}/plc`
  });

const loadPlcMachineIris = id =>
  axios({
    method: 'get',
    url: `/v2/machines/${id}/iris-plc`
  });

const updatePlcMachine = (id, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${id}/plc`,
    data: payload
  });

const updatePlcMachineIris = (id, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${id}/iris-plc`,
    data: payload
  });

const loadSensorMachine = id => {
  const params = {
    selection: ['custom', 'pre_def', 'static']
  };

  // eslint-disable-next-line no-shadow
  const paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });

  return axios({
    method: 'get',
    url: `/v2/machines/${id}/sensors`,
    params,
    paramsSerializer
  });
};

const createSensor = (machineId, payload) =>
  axios({
    method: 'post',
    url: `/v2/machines/${machineId}/sensor`,
    data: payload
  });

const updateStaticValue = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/sensor`,
    data: payload
  });

const deleteSensor = (id, sensorType) =>
  axios({
    method: 'delete',
    url: `/v2/machines/${id}/sensor`,
    params: {
      sensor_type: sensorType
    }
  });

const updateSensor = (id, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${id}/sensor`,
    params: {
      sensor: payload
    }
  });

const loadErrorCode = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/errors`
  });

const loadErrorCodeById = (machineId, errorId) =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/error`,
    params: {
      error_id: errorId
    }
  });

const updateErrorCode = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/error`,
    data: payload
  });

const createErrorCode = (machineId, payload) =>
  axios({
    method: 'post',
    url: `/v2/machines/${machineId}/error`,
    data: payload
  });

const deleteErrorCode = (machineId, errorId) =>
  axios({
    method: 'delete',
    url: `/v2/machines/${machineId}/error`,
    params: {
      error_id: errorId
    }
  });

const loadStatusMachine = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/statuses`
  });

const createStatusMachine = (machineId, payload) =>
  axios({
    method: 'post',
    url: `/v2/machines/${machineId}/statuses`,
    params: {
      machine_id: machineId,
      statuses: payload
    }
  });

const updateStatusMachine = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/statuses`,
    data: {
      machine_id: machineId,
      statuses: payload
    }
  });

const loadBatchRecordsMachine = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/batch`
  });

const createBatchRecords = (machineId, payload) =>
  axios({
    method: 'post',
    url: `/v2/machines/${machineId}/batch`,
    data: payload
  });

const updateBatchRecordsMachine = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/batch`,
    data: payload
  });

const deleteBatchRecordsMachine = machineId =>
  axios({
    method: 'delete',
    url: `/v2/machines/${machineId}/batch`
  });

const updateFlagRequiredGateway = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/details/gateway-required-flag`,
    data: payload
  });

const updateSensorStatus = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/sensor/flip-state`,
    data: payload
  });

export {
  createBatchRecords,
  createCloneableMachine,
  createErrorCode,
  createMachine,
  createNewMachine,
  createSensor,
  createStatusMachine,
  deleteBatchRecordsMachine,
  updateFlagRequiredGateway,
  updateSensorStatus,
  deleteErrorCode,
  deleteMachine,
  deleteSensor,
  editMachineDetails,
  loadBatchRecordsMachine,
  loadCloneableMachines,
  loadCloneableMachinesDetails,
  loadCloneableMachinesPaginated,
  loadErrorCode,
  loadErrorCodeById,
  loadMachine,
  loadMachinePlcType,
  loadMachines,
  loadMachinesByResume,
  loadMachinesResumed,
  loadMachinesResumedSearch,
  loadMetricsMachineDetails,
  loadPlcMachine,
  loadPlcMachineIris,
  loadSensorMachine,
  loadStatusMachine,
  updateBatchRecordsMachine,
  updateConfigurationsMachinesGateway,
  updateErrorCode,
  updateMachine,
  updateMachineDetails,
  updatePlcMachine,
  updatePlcMachineIris,
  updateSensor,
  updateStaticValue,
  updateStatusMachine,
  verifyCommissionNumber
};
