import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  column-gap: 15px;
  padding: 0 15px 0 0;
  margin: 0 20px 0 0px;
  font-size: 16px;
`;

const FilterInputElement = styled.input`
  font: inherit;
  letter-spacing: inherit;
  color: '#7A7D85';
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  outline: none;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
`;

export { Container, FilterInputElement };
