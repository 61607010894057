import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SignIn from './containers/Auth/SignIn';
// import RequestPasswordReset from './containers/Auth/ForgotPassword/RequestPasswordReset';
// import SetPassword from './containers/Auth/ForgotPassword/SetPassword';
// import RegisterAccount from './containers/Auth/RegisterAccount';

const LoggedOut = () => (
  <Switch>
    <Route component={SignIn} path="/login" />
    {/* Auth endpoints are not enabled by BE yet */}
    {/* <Route component={RequestPasswordReset} path="/forgot-password" /> */}
    {/* <Route component={SetPassword} path="/set-password/:token" /> */}
    {/* <Route component={RegisterAccount} path="/register/:token" /> */}
    <Redirect to="/login" />
  </Switch>
);

export default LoggedOut;
