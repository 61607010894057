import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSensorReducerState } from 'redux/rootSelectors';
import T from 'prop-types';
import StaticValueContent from './StaticValueContent';
import { loadSensor } from '../../../../redux/sensor/actions';
import { loadStaticValuesIrisV3 } from '../../../../redux/machines_v2/actions';
import { getSelectedStaticValuesIrisV3 } from '../../../../redux/machines_v2/selectors';

import { PlcType } from '../../../../attrs/plcConfigValues';

const ConnectedStaticValueContent = ({ plcType }) => {
  const dispatch = useDispatch();
  const { loadingSensorsStatus, paginatedSensorList } = useSelector(getSensorReducerState);
  const { machineId } = useParams();

  const { data: irisStaticValuesV3List, loadingState: loadingStateIrisV3 } = useSelector(getSelectedStaticValuesIrisV3);

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(loadStaticValuesIrisV3(machineId));
    } else {
      dispatch(loadSensor(machineId));
    }
  }, [dispatch, machineId, plcType.plc_type]);

  const sensorList = paginatedSensorList.sensors || {};
  sensorList.iris = irisStaticValuesV3List || [];

  return (
    <StaticValueContent
      loadingState={plcType.plc_type === PlcType.IRIS_V3 ? loadingStateIrisV3 : loadingSensorsStatus}
      reloadAction={() => {
        dispatch(loadSensor(machineId));
        dispatch(loadStaticValuesIrisV3(machineId));
      }}
      data={{ ...paginatedSensorList, sensors: sensorList }}
      machineId={machineId}
      plcType={plcType}
    />
  );
};

ConnectedStaticValueContent.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default ConnectedStaticValueContent;
