import styled from 'styled-components';
import { Box } from '@mui/material';

const StyledHandlers = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledContentGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  width: 100%;
  margin: 12px 0;
`;

const StyledContentGridTrigger = styled(Box)`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 0;
  width: 100%;
`;

export { StyledHandlers, StyledContentGrid, StyledContentGridTrigger };
