/* eslint-disable react/prop-types */
import React from 'react';
import { LinearProgress } from '@mui/material';
import moment from 'moment';

const ProgressBar = ({ license }) => {
  const startDate = moment(license?.activation_date);
  const endDate = moment(license?.expiration_date);
  const now = moment();
  const totalDuration = moment.duration(endDate.diff(startDate));
  const elapsedDuration = moment.duration(now.diff(startDate));
  const progress = Math.floor((elapsedDuration.asMilliseconds() / totalDuration.asMilliseconds()) * 100);

  return (
    <LinearProgress
      sx={{
        height: '12px',
        my: 1,
        borderRadius: '3px',
        background: '#EEEEF5'
      }}
      color="secondary"
      variant="determinate"
      value={progress}
    />
  );
};

export default ProgressBar;
