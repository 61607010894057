import { Box, Button, FormControl, Stack } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import T, { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormElements, NotifyIcon, NotifyTypo } from 'web-components';
import { updateStatusMachine } from '../../../../../redux/machines/actions';
import { StyledContent, StyledContentGrid, StyledHandlers, StyledNewDialog } from '../elements';
import {
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  validateBlankSpace
} from '../../../../../helpers/utils';
import { MachineStatusSchema } from '../../../../../attrs/formValidation';
import { getStatusMachine } from '../../../../../redux/machines/selectors';

const labelAmend = 'machines.machine_details';

const StatusDataTypes = ['INTEGER', 'BOOL'];

const StatusTypes = [
  { type: 'running', required: true },
  {
    type: 'idle',
    required: true
  },
  { type: 'warning' },
  { type: 'critical' },
  { type: 'maintenance' }
];

const StateValueTypes = ['true', 'false'];

const statusTypeToIconName = statusType =>
  ({
    running: 'runningMachine',
    warning: 'warningMachine',
    critical: 'errorMachine',
    maintenance: 'maintenanceMachine',
    idle: 'pauseMachine'
  }[statusType]);

const StatusCard = ({ getError, getHelperText, handleBlur, handleChange, statusType, values }) => {
  const { t } = useTranslation();
  const addressFieldName = `${statusType.type}.address`;
  const valueFieldName = `${statusType.type}.value`;
  const isStatusDataTypeBoolean = values.datatype === StatusDataTypes[1];

  const handleChangeAddress = event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <NotifyIcon viewBox="0 0 17 17" iconName={statusTypeToIconName(statusType.type)} />
        <NotifyTypo.Body1 fontWeight="bold" sx={{ ml: 1 }}>
          {t(`machines.status.${statusType.type}`)}
        </NotifyTypo.Body1>
      </Box>
      <StyledContentGrid>
        <FormElements.TextField
          fullWidth
          error={getError(addressFieldName)}
          helperText={getHelperText(addressFieldName)}
          id={addressFieldName}
          label={t('machines.form.machine_status.address')}
          margin="normal"
          name={addressFieldName}
          onBlur={handleBlur}
          onChange={handleChangeAddress}
          required={statusType.required}
          value={values[statusType.type].address}
        />
        {isStatusDataTypeBoolean ? (
          <FormElements.Select
            error={getError(valueFieldName)}
            helperText={getHelperText(valueFieldName)}
            fullWidth
            id={valueFieldName}
            label={t('machines.form.machine_status.value')}
            name={valueFieldName}
            handleChange={handleChange}
            onBlur={handleBlur}
            required={statusType.required}
            value={values[statusType.type].value}
            withEmptyField
          >
            {StateValueTypes.map(val => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
          </FormElements.Select>
        ) : (
          <FormElements.TextField
            error={getError(valueFieldName)}
            helperText={getHelperText(valueFieldName)}
            fullWidth
            id={valueFieldName}
            label={t('machines.form.machine_status.value')}
            margin="normal"
            name={valueFieldName}
            onBlur={handleBlur}
            onChange={handleChange}
            required={statusType.required}
            type="number"
            value={values[statusType.type].value}
          />
        )}
      </StyledContentGrid>
    </Box>
  );
};

StatusCard.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  values: T.shape({
    datatype: T.oneOf([...StatusDataTypes, '']),
    running: T.shape({}),
    warning: T.shape({}),
    critical: T.shape({}),
    maintenance: T.shape({}),
    idle: T.shape({})
  }).isRequired,
  statusType: T.shape({
    type: T.string,
    required: T.bool
  }).isRequired
};

export const EditStatus = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loadingState } = useSelector(getStatusMachine);

  const isSubmitting = isLoading(loadingState.status);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const submitForm = (values, formikHelpers) => {
    const payload = {
      datatype: values.datatype,
      critical: {
        address: values?.critical?.address,
        value: values?.critical?.value.toString()
      },
      warning: {
        address: values?.warning?.address,
        value: values?.warning?.value.toString()
      },
      idle: {
        address: values?.idle?.address,
        value: values?.idle?.value.toString()
      },
      running: {
        address: values?.running?.address,
        value: values?.running?.value.toString()
      },
      maintenance: {
        address: values?.maintenance?.address,
        value: values?.maintenance?.value.toString()
      }
    };
    try {
      dispatch(updateStatusMachine(machineId, payload));
      handleClose(true);
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="status-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.handle_button`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <Formik
      initialValues={{
        datatype: data.statuses?.datatype || 'INTEGER',
        critical: {
          address: getDefaultFieldValue(data.statuses?.critical?.address),
          value: getDefaultFieldValue(data.statuses?.critical?.value)
        },
        warning: {
          address: getDefaultFieldValue(data.statuses?.warning?.address),
          value: getDefaultFieldValue(data.statuses?.warning?.value)
        },
        idle: {
          address: getDefaultFieldValue(data.statuses?.idle?.address),
          value: getDefaultFieldValue(data.statuses?.idle?.value)
        },
        running: {
          address: getDefaultFieldValue(data.statuses?.running?.address),
          value: getDefaultFieldValue(data.statuses?.running?.value)
        },
        maintenance: {
          address: getDefaultFieldValue(data.statuses?.maintenance?.address),
          value: getDefaultFieldValue(data.statuses?.maintenance?.value)
        }
      }}
      validationSchema={MachineStatusSchema}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              handleClose();
            }}
            title={t(`${labelAmend}.status_modal.title`)}
            subTitle={t(`${labelAmend}.status_modal.subtitle`)}
            maxWidth="1062px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            shouldCloseOnOverlayClick={false}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <Stack direction="column" gap={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <NotifyTypo.Body1 sx={{ mr: 2 }}>
                        {t(`${labelAmend}.status_modal.label_type_status`)}
                      </NotifyTypo.Body1>
                      <label htmlFor="datatype.integer" style={{ fontSize: '16px' }}>
                        <FormElements.RadioButton
                          margin="normal"
                          name="datatype"
                          id="datatype.integer"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={StatusDataTypes[0]}
                          checked={values.datatype === StatusDataTypes[0]}
                        />
                        {t(`${labelAmend}.status_modal.options.integer`)}
                      </label>
                      <label htmlFor="datatype.bool" style={{ fontSize: '16px' }}>
                        <FormElements.RadioButton
                          margin="normal"
                          name="datatype"
                          id="datatype.bool"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={StatusDataTypes[1]}
                          checked={values.datatype === StatusDataTypes[1]}
                        />
                        {t(`${labelAmend}.status_modal.options.bool`)}
                      </label>
                    </Box>
                    {StatusTypes.map(statusType => (
                      <Box>
                        <StatusCard
                          getError={getError}
                          getHelperText={getHelperText}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          key={`machine-status-card-${statusType.type}`}
                          statusType={statusType}
                          values={values}
                        />
                      </Box>
                    ))}
                  </Stack>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

EditStatus.propTypes = {
  data: T.shape({
    statuses: T.shape({
      datatype: T.string,
      running: T.shape({
        address: T.string,
        value: T.string
      }),
      idle: T.shape({
        address: T.string,
        value: T.string
      }),
      warning: T.shape({
        address: T.string,
        value: T.string
      }),
      critical: T.shape({
        address: T.string,
        value: T.string
      }),
      maintenance: T.shape({
        address: T.string,
        value: T.string
      })
    })
  }),
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired
};

EditStatus.defaultProps = {
  data: null,
  machineId: null
};
