import { getValueToNullableCell } from 'helpers/stringHandler';

const PlcType = {
  SIEMENS_S7: 'SIEMENS_S7',
  ROCKWELL: 'ROCKWELL',
  IRIS_V3: 'IRIS_V3',
  UNKNOWN: 'UNKNOWN'
};

const PlcProtocols = [
  {
    value: PlcType.SIEMENS_S7,
    type: 'S7 Connection'
  },
  {
    value: PlcType.ROCKWELL,
    type: 'Rockwell'
  },
  {
    value: PlcType.IRIS_V3,
    type: 'IRIS V3'
  }
];

const getPlcProtocolName = value => {
  const protocol = PlcProtocols.find(item => item.value === value);
  return getValueToNullableCell(protocol?.type);
};

const PlcRacks = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 }
];

const PlcSlots = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 }
];

export { PlcType, PlcProtocols, PlcRacks, PlcSlots, getPlcProtocolName };
