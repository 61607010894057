import { Box, Button, Container, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import styled, { css } from 'styled-components';
import { NewDialog, NotifyIcon } from 'web-components';

const StyledButton = styled(Button).attrs(() => ({
  variant: 'outlined',
  sx: { p: 1.2 }
}))`
  box-shadow: 0 0.25rem 0.25rem rgba(54, 125, 120, 0.25);

  &.MuiButton-root {
    justify-content: flex-start;
    text-transform: none;
    border-width: 0.125rem;
    text-align: start;

    &:hover {
      border-width: 0.125rem;
    }
  }
`;

const StyledIcon = styled(NotifyIcon).attrs(() => ({
  sx: { m: 2, fontSize: '1.75rem' }
}))``;

const StyledNewDialog = styled(NewDialog)`
  .MuiDialog-paper {
    border-radius: 0.625rem;
    width: 80%;
    max-height: 90%;

    form {
      width: 100%;
    }
  }
  h2:first-of-type {
    padding-bottom: unset;
  }
`;

const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

const StyledContentGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;
`;

const StyledGridItem = styled(Box)`
  width: 100%;
`;

const StyledHandlers = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledMobileTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: 0;
    padding: 0.5rem;
  }
`;

const StyledTableContainer = styled(TableContainer)`
  &.MuiTableContainer-root {
    margin: 1rem 0;
    padding: 0.5rem;
    ${props =>
      props.selected &&
      css`
        background: linear-gradient(0deg, rgba(45, 179, 165, 0.05), rgba(45, 179, 165, 0.05)), #ffffff;
        border: 1px solid #2db3a5;
      `}
  }
`;

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root.Mui-selected {
    background: linear-gradient(0deg, rgba(45, 179, 165, 0.1), rgba(45, 179, 165, 0.1)), #ffffff;
  }
`;

const StyledContainer = styled(Container)(
  ({ theme }) => `
  &.MuiContainer-root {
    
    ${theme.breakpoints.down('sm')} {
      padding: 2.5rem 1.25rem 0;
    }

    & .statusWrapper {
      display: flex;
      align-items: center;
      min-height: 1.875rem;
    }
  }
  @media(max-width:800px){
    .MuiTable-root{
      min-width: 1024px;
    }
  }
`
);

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const TextFieldStyled = styled(TextField)`
  &.MuiTextField-root {
    margin-top: 15px;
  }
`;

const SelectDataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export {
  FilterContainer,
  SelectDataItem,
  StyledButton,
  StyledContainer,
  StyledContent,
  StyledContentGrid,
  StyledGridItem,
  StyledHandlers,
  StyledIcon,
  StyledMobileTableCell,
  StyledNewDialog,
  StyledTableContainer,
  StyledTableRow,
  TextFieldStyled
};
