import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { enhanceWithResetActions, loadingStateReducer } from '../utils';
import {
  CREATE_SENSOR,
  DELETE_SENSOR,
  DELETE_SENSOR_IRIS,
  LOAD_SENSORS,
  LOAD_SENSORS_CONFIG_FOR_IRIS,
  LOAD_SENSORS_IRIS,
  LOAD_SENSORS_UNITS,
  UPDATE_SENSOR
} from './constants';

export default combineReducers({
  loadingSensorsStatus: loadingStateReducer(LOAD_SENSORS),
  creatingSensorStatus: loadingStateReducer(CREATE_SENSOR),
  updatingSensorStatus: loadingStateReducer(UPDATE_SENSOR),
  deletingSensorStatus: loadingStateReducer(DELETE_SENSOR),
  paginatedSensorList: typeToReducer(
    {
      [LOAD_SENSORS]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  ),
  irisSensors: enhanceWithResetActions([LOAD_SENSORS_IRIS])(
    combineReducers({
      loadingIrisSensorsStatus: loadingStateReducer(LOAD_SENSORS_IRIS),
      deletingIrisSensorStatus: loadingStateReducer(DELETE_SENSOR_IRIS),
      irisSensorList: typeToReducer(
        {
          [LOAD_SENSORS_IRIS]: {
            SUCCESS: (_data, { payload }) => payload
          }
        },
        []
      ),
      irisSensorConfigList: typeToReducer(
        {
          [LOAD_SENSORS_IRIS]: {
            SUCCESS: (_data, { payload }) => payload
          }
        },
        []
      )
    })
  ),
  sensorsConfig: enhanceWithResetActions([LOAD_SENSORS_CONFIG_FOR_IRIS])(
    combineReducers({
      loadingConfigsStatus: loadingStateReducer(LOAD_SENSORS_CONFIG_FOR_IRIS),
      sensorConfigList: typeToReducer(
        {
          [LOAD_SENSORS_CONFIG_FOR_IRIS]: {
            SUCCESS: (_data, { payload }) => payload
          }
        },
        []
      )
    })
  ),
  sensorUnits: enhanceWithResetActions([LOAD_SENSORS_UNITS])(
    combineReducers({
      loadingSensorsUnitsStatus: loadingStateReducer(LOAD_SENSORS_UNITS),
      sensorUnitsData: typeToReducer(
        {
          [LOAD_SENSORS_UNITS]: {
            SUCCESS: (_data, { payload }) => payload
          }
        },
        []
      )
    })
  )
});
