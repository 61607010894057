/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  background: #fff;
`;

const EmptyStatePageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  background: #fff;
`;

const TextEmptyState = styled.div`
  text-align: start;
  margin-left: 15px;
`;

const TextEmptyStatePage = styled.div`
  text-align: center;
`;

export { EmptyStateWrapper, EmptyStatePageWrapper, TextEmptyState, TextEmptyStatePage };
