import React from 'react';
import T from 'prop-types';
import { useResolutionCheck } from 'web-components';

import GatewayTable from './GatewayTable';
import GatewayMachineTable from './GatewayMachineTable';

const GatewayTableSelector = ({ rows, columns, organizationId }) => {
  const { isTablet } = useResolutionCheck();

  return isTablet ? (
    <GatewayMachineTable rows={rows} />
  ) : (
    <GatewayTable columns={columns} rows={rows} organizationId={organizationId} />
  );
};

GatewayTableSelector.propTypes = {
  organizationId: T.string,
  columns: T.arrayOf(T.shape({})).isRequired,
  rows: T.instanceOf(Object).isRequired
};

GatewayTableSelector.defaultProps = {
  organizationId: null
};

export default GatewayTableSelector;
