import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth/reducer';
import user from './user/reducer';
import ui from './ui/reducer';
import organizations from './organizations/reducer';
import users from './users/reducer';
import usersV2 from './users_v2/reducer';
import machines from './machines/reducer';
import gateways from './gateways/reducer';
import supportContact from './supportContact/reducer';
import licenses from './licenses/reducer';
import cloneableMachines from './cloneableMachines/reducer';
import task from './task/reducer';
import sensor from './sensor/reducer';
import batch from './batch/reducer';
import gatewaysV2 from './gateways_v2/reducer';
import machinesV2 from './machines_v2/reducer';
import productUsage from './productUsage/reducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
    ui,
    organizations,
    users,
    machines,
    gateways,
    supportContact,
    licenses,
    cloneableMachines,
    task,
    sensor,
    batch,
    usersV2,
    gatewaysV2,
    machinesV2,
    productUsage
  });

export default createRootReducer;
