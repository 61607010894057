import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, CardContent, Box } from '@mui/material';

import { NotifyTypo, FormElements, NotifyIcon } from 'web-components';

import { StyledCard, StyledCardHeader } from '../elements';
import { GroupHeadingWrapper } from './BatchRecords.styled';
import { batchConfiguration } from '../../../../../helpers/propTypes';

const Status = ({ handleBlur, handleChange, getError, getHelperText, values }) => {
  const { t } = useTranslation();

  return (
    <StyledCard className="withMargins">
      <CardContent>
        <StyledCardHeader>
          <NotifyTypo.Heading2>{t('machines.form.batch_records.batch_status')}</NotifyTypo.Heading2>
        </StyledCardHeader>
        <Container maxWidth="sm">
          <Box m={6} />
          <GroupHeadingWrapper>
            <NotifyIcon fontSize="large" iconName="successSemiFilled" viewBox="0 0 32 32" />
            <NotifyTypo.Heading2>{t('machines.form.batch_records.completed')}</NotifyTypo.Heading2>
          </GroupHeadingWrapper>
          <FormElements.TextField
            error={getError('configuration.plc.batch.status.complete.address')}
            helperText={getHelperText('configuration.plc.batch.status.complete.address')}
            fullWidth
            id="completed_status"
            label={t('machines.form.batch_records.completed_status')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.status.complete.address"
            value={values.configuration.plc.batch.status.complete.address}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.status.complete.value')}
            helperText={getHelperText('configuration.plc.batch.status.complete.value')}
            fullWidth
            id="completed_status_value"
            label={t('machines.form.batch_records.completed_status_value')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.status.complete.value"
            value={values.configuration.plc.batch.status.complete.value}
          />

          <GroupHeadingWrapper>
            <NotifyIcon fontSize="large" iconName="errorSemiFilled" viewBox="0 0 32 32" />
            <NotifyTypo.Heading2>{t('machines.form.batch_records.cancelled')}</NotifyTypo.Heading2>
          </GroupHeadingWrapper>
          <FormElements.TextField
            error={getError('configuration.plc.batch.status.cancelled.address')}
            helperText={getHelperText('configuration.plc.batch.status.cancelled.address')}
            fullWidth
            id="cancelled_status"
            label={t('machines.form.batch_records.cancelled_status')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.status.cancelled.address"
            value={values.configuration.plc.batch.status.cancelled.address}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.status.cancelled.value')}
            helperText={getHelperText('configuration.plc.batch.status.cancelled.value')}
            fullWidth
            id="cancelled_status_value"
            label={t('machines.form.batch_records.cancelled_status_value')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.status.cancelled.value"
            value={values.configuration.plc.batch.status.cancelled.value}
          />

          <GroupHeadingWrapper>
            <NotifyIcon fontSize="large" iconName="progressSemiFilled" viewBox="0 0 32 32" />
            <NotifyTypo.Heading2>{t('machines.form.batch_records.in_operation')}</NotifyTypo.Heading2>
          </GroupHeadingWrapper>
          <FormElements.TextField
            error={getError('configuration.plc.batch.status.in_operation.address')}
            helperText={getHelperText('configuration.plc.batch.status.in_operation.address')}
            fullWidth
            id="in_operation_status"
            label={t('machines.form.batch_records.in_operation_status')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.status.in_operation.address"
            value={values.configuration.plc.batch.status.in_operation.address}
            required
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.status.in_operation.value')}
            helperText={getHelperText('configuration.plc.batch.status.in_operation.value')}
            fullWidth
            id="in_operation_status_value"
            label={t('machines.form.batch_records.in_operation_status_value')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.status.in_operation.value"
            value={values.configuration.plc.batch.status.in_operation.value}
            required
          />
        </Container>
      </CardContent>
    </StyledCard>
  );
};

Status.propTypes = {
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  values: T.shape({
    configuration: T.shape({
      plc: T.shape({
        batch: batchConfiguration
      })
    })
  }).isRequired
};

export default Status;
