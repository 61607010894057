import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'web-components';
import { Box } from '@mui/material';
import { getMachinesFiltered } from '../../redux/machines/actions';

import { formatMachineType, getMachineType } from '../../helpers/utils';
import Footer from '../DataTable/Footer';
import { MACHINE_DEFAULT_LIST_ORDER, MACHINE_DEFAULT_LIST_SORT, ROUTE_MACHINES } from '../../attrs/machines';
import { getMachineSortOrder } from '../../redux/machines/selectors';
import { MachineFilter } from './MachineFilter';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';

const MachineTable = ({ columns, rows, organizationId }) => {
  const dispatch = useDispatch();
  const machineSortOrder = useSelector(getMachineSortOrder);
  const { t } = useTranslation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState({
    colId: machineSortOrder.sort || MACHINE_DEFAULT_LIST_SORT,
    order: machineSortOrder.name || MACHINE_DEFAULT_LIST_ORDER
  });
  const [filterOrganizationName, setFilterOrganizationName] = useState('');
  const [filterCommissionNumber, setFilterCommissionNumber] = useState('');
  const [filterSerialNumber, setFilterSerialNumber] = useState('');
  const [clear] = useState(false);
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN;

  useEffect(() => {
    setIsLoading(false);
    if (rows && rows.data && rows.data.length) {
      setCurrentView(
        DataTable.sortFn(
          rows.data.map(row => {
            const machineType = getMachineType(row.type);
            const machineTypeFormat = formatMachineType(row.type);

            return {
              ...row,
              commission_number: row.commission_number ? row.commission_number : '---',
              name: row.name ? row.name : t('machines.overview.machine_name_not_assigned'),
              brand: row.brand ? row.brand : '---',
              manufacturer: machineTypeFormat,
              type: row.type ? machineType.type : '---',
              tag_id: row.tag_id ? row.tag_id : t('machines.overview.not_assigned'),
              production_line: row.production_line
                ? row.production_line
                : t('machines.overview.production_line_name_not_assigned'),
              organization_name: row.organization_name ? row.organization_name : '---',
              gateway_id: row.gateway_id ? row.gateway_id : t('machines.overview.not_assigned')
            };
          })
        )
      );
    } else {
      setCurrentView([]);
    }
  }, [organizationId, rows, t, currentSort.colId, currentSort.order]);

  const handleChangePage = page => {
    setIsLoading(true);
    setCurrentPage(page);
    dispatch(
      getMachinesFiltered(
        page,
        rowsPerPage,
        currentSort.order,
        currentSort.colId,
        filterOrganizationName,
        filterCommissionNumber,
        filterSerialNumber
      )
    );
  };

  const handleChangeFilter = (page, commissionNumber, organizationName, serialNumber) => {
    setCurrentPage(page);
    dispatch(
      getMachinesFiltered(
        page,
        rowsPerPage,
        currentSort.order,
        currentSort.colId,
        organizationName,
        commissionNumber,
        serialNumber
      )
    );
  };

  const handleSelectRow = row => {
    localStorage.setItem('organizationName', row.organization_name);
    const routePath =
      row.organization_id !== null
        ? `/customers/${row.organization_id}/${ROUTE_MACHINES}/${row.id}`
        : `/${ROUTE_MACHINES}/${row.id}`;

    history.push(routePath);
  };

  const handleSort = (colId, order) => {
    setIsLoading(true);
    dispatch(
      getMachinesFiltered(
        currentPage,
        rowsPerPage,
        order.toUpperCase(),
        colId,
        organizationId,
        filterOrganizationName,
        filterCommissionNumber
      )
    );
    setCurrentSort({ colId, order });
  };

  const setInitStates = () => {
    setCurrentPage(PAGINATION_DEFAULT_START_PAGE);
    setCurrentSort({
      colId: machineSortOrder.sort || MACHINE_DEFAULT_LIST_SORT,
      order: machineSortOrder.name || MACHINE_DEFAULT_LIST_ORDER
    });
  };
  const props = {
    dataSelectorTable: 'machines-table',
    dataSelectorRows: 'machines-table-rows',
    isLoading,
    columns,
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={rows.total_pages || 1}
        totalLength={rows.total_length || 0}
        totalItemsPerPage={rows.limit || 0}
        onChange={handleChangePage}
      />
    ),
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  const handleFilterOrganizationName = organizationName => {
    if (organizationName !== filterOrganizationName) {
      setIsLoading(true);
      setFilterOrganizationName(organizationName);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, filterCommissionNumber, organizationName, filterSerialNumber);
    }
  };

  const handleFilterCommissionNumber = commissionNumber => {
    if (commissionNumber !== filterCommissionNumber) {
      setIsLoading(true);
      setFilterCommissionNumber(commissionNumber);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, commissionNumber, filterOrganizationName, filterSerialNumber);
    }
  };

  const handleFilterSerialNumber = serialNumber => {
    if (serialNumber !== filterSerialNumber) {
      setIsLoading(true);
      setFilterSerialNumber(serialNumber);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, filterCommissionNumber, filterOrganizationName, serialNumber);
    }
  };

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ flexWrap: 'wrap', py: 4 }}>
        <MachineFilter
          isCheckbox={false}
          clear={clear}
          optionText={filterCommissionNumber}
          setInitStates={setInitStates}
          handleFilter={handleFilterCommissionNumber}
          labelFilter={t('machines.filter.search_commission_number')}
        />
        {!organizationId && (
          <MachineFilter
            isCheckbox={false}
            clear={clear}
            optionText={filterOrganizationName}
            setInitStates={setInitStates}
            handleFilter={handleFilterOrganizationName}
            labelFilter={t('machines.filter.search_customer')}
          />
        )}
        <MachineFilter
          isCheckbox={false}
          clear={clear}
          optionText={filterSerialNumber}
          setInitStates={setInitStates}
          handleFilter={handleFilterSerialNumber}
          labelFilter={t('machines.filter.search_gateway')}
        />
      </Box>
      <DataTable hover {...props} />
    </Box>
  );
};

MachineTable.propTypes = {
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired,
  rows: T.shape({
    data: T.instanceOf(Array),
    limit: T.number,
    order: T.string,
    page: T.number,
    search: T.string,
    sort: T.instanceOf(Array),
    total_length: T.number,
    total_pages: T.number
  }).isRequired,
  organizationId: T.string
};

MachineTable.defaultProps = {
  organizationId: null
};

export default MachineTable;
