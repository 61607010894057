import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResolutionCheck, PageHeader, ContentPageHeader, ResponsiveIconButton } from 'web-components';

import { Link } from 'react-router-dom';
import { ContentWrapper, ContainerHeader } from '../../elements';
import ConnectedCustomersContent from './ConnectedCustomersContent';

const Customers = () => {
  const { t } = useTranslation();
  const { isMobile } = useResolutionCheck();

  return (
    <>
      <ContainerHeader>
        <PageHeader.Title>
          <PageHeader.Title.Main>{t('customers.title')}</PageHeader.Title.Main>
          <PageHeader.Title.Sub>{t('customers.subtitle_version_two')}</PageHeader.Title.Sub>
        </PageHeader.Title>
        <PageHeader.Action>
          <ResponsiveIconButton
            buttonText={t('customers.add_customer_button_text')}
            sx={{ display: 'inline-flex', padding: '16px', justifyContent: 'center', alignItems: 'center', gap: '8px' }}
            color="primary"
            iconName="plus"
            component={Link}
            variant="contained"
            to="customers/new-customer"
          />
        </PageHeader.Action>
      </ContainerHeader>
      <ContentWrapper maxWidth={false}>
        {isMobile && <ContentPageHeader header={t('customers.title')} subHeader={t('customers.subtitle')} />}
        <ConnectedCustomersContent />
      </ContentWrapper>
    </>
  );
};

export default Customers;
