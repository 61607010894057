import React from 'react';
import { Avatar } from '@mui/material';
import { NotifyTypo } from 'web-components';

import { ProfileWrapper } from './elements';
import OrgLogo from '../../../attrs/images/netzsch.png';

// This information is based on the installation. No endpoint available yet.

const OrganizationProfile = () => (
  <ProfileWrapper>
    <NotifyTypo.Heading2>Netzsch</NotifyTypo.Heading2>
    <Avatar src={OrgLogo} style={{ width: 40, height: 40, marginLeft: '1rem' }} />
  </ProfileWrapper>
);

export default OrganizationProfile;
