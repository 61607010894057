import styled from 'styled-components';
import { FormElements } from 'web-components';

export const ACTIVE_COLOR = '#000000';

export const SelectStyled = styled(FormElements.Select)`
  &.MuiFormControl-marginNormal {
    width: 45%;
  }
`;
