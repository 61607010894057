import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { NewDialog } from 'web-components';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSensorReducerState } from 'redux/rootSelectors';
import { generateId, isError, isLoading, isSuccess, validateBlankSpace } from 'helpers/utils';
import { addNotification } from 'redux/ui/notifications/actions';
import { ERROR, SUCCESS } from 'attrs/status';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CUSTOM_ICON_TYPE_LIST, DECIMAL_PLACES, PERIODS_LIST } from '../../../../../attrs/sensorConfig';
import { createSensor, loadSensor, updateSensor } from '../../../../../redux/sensor/actions';
import { loadMetricsMachineDetails } from '../../../../../redux/machines/actions';

const CustomSensor = ({ open, handleClose, machineId, customItem }) => {
  const { creatingSensorStatus, updatingSensorStatus } = useSelector(getSensorReducerState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [isNameTouched, setNameTouched] = useState(false);
  const onChangeName = event => {
    const nameParam = event.target.value;
    setName(nameParam);
    setNameTouched(true);
  };

  const [source, setSource] = useState('');
  const [isSourceTouched, setSourceTouched] = useState(false);
  const onChangeSource = event => {
    let sourceParam = event.target.value;
    sourceParam = validateBlankSpace(sourceParam);
    setSource(sourceParam);
    setSourceTouched(true);
  };

  const [sensorType, setSensorType] = useState('');
  const [isSensorTypeTouched, setSensorTypeTouched] = useState(false);
  const handleSensorTypeChange = event => {
    const sensorTypeParam = event.target.value;
    setSensorType(sensorTypeParam);
    setSensorTypeTouched(true);
  };

  const [sensorPeriod, setSensorPeriod] = useState('');
  const [isSensorPeriodTouched, setSensorPeriodTouched] = useState(false);
  const handleSensorPeriodChange = event => {
    const sensorPeriodParam = event.target.value;
    setSensorPeriod(sensorPeriodParam);
    setSensorPeriodTouched(true);
  };

  const [unit, setUnit] = useState('');
  const [isUnitTouched, setUnitTouched] = useState(false);
  const onChangeUnit = event => {
    const unitParam = event.target.value;
    setUnit(unitParam);
    setUnitTouched(true);
  };

  const [multiplicationFactor, setMultiplicationFactor] = useState(0);
  const [isMultiplicationFactorTouched, setMultiplicationFactorTouched] = useState(false);
  const onChangeMultiplicationFactor = event => {
    const multiplicationFactorParam = event.target.value;
    setMultiplicationFactor(multiplicationFactorParam);
    setMultiplicationFactorTouched(true);
  };

  const [decimalPlace, setDecimalPlace] = useState('0');
  const [isDecimalPlaceTouched, setDecimalPlaceTouched] = useState(false);
  const onChangeDecimalPlace = event => {
    const decimalPlaceParam = event.target.value;
    setDecimalPlace(decimalPlaceParam);
    setDecimalPlaceTouched(true);
  };

  const [maxEngUnitAddress, setMaxEngUnitAddress] = useState('');
  const onChangeMaxEngUnitAddress = event => {
    const maxEngUnitAddressParam = event.target.value;
    setMaxEngUnitAddress(maxEngUnitAddressParam);
  };

  const [minEngUnitAddress, setMinEngUnitAddress] = useState('');
  const onChangeMinEngUnitAddress = event => {
    const minEngUnitAddressParam = event.target.value;
    setMinEngUnitAddress(minEngUnitAddressParam);
  };

  const [maxEngUnitValue, setMaxEngUnitValue] = useState(null);
  const [isMaxEngUnitValueTouched, setMaxEngUnitValueTouched] = useState(false);
  const onChangeMaxEngUnitValue = event => {
    const maxEngUnitValueParam = event.target.value;
    setMaxEngUnitValue(maxEngUnitValueParam);
    setMaxEngUnitValueTouched(true);
  };

  const [minEngUnitValue, setMinEngUnitValue] = useState(null);
  const onChangeMinEngUnitValue = event => {
    const minEngUnitValueParam = event.target.value;
    setMinEngUnitValue(minEngUnitValueParam);
  };

  const [engineeringUnit, setEngineeringUnit] = useState('address');
  const handleRadioEngineeringUnitChange = event => {
    const engineeringUnitParam = event.target.value;
    setEngineeringUnit(engineeringUnitParam);
    if (engineeringUnitParam === 'address') {
      setMaxEngUnitValue(null);
      setMinEngUnitValue(null);
    } else {
      setMaxEngUnitAddress('');
      setMinEngUnitAddress('');
    }
  };

  const [highTriggerAlert, setHighTriggerAlert] = useState(true);
  const handleRadioHighTriggerAlertChange = event => {
    const highTriggerAlertParam = event.target.value;
    setHighTriggerAlert(highTriggerAlertParam === 'true');
  };

  const [lowTriggerAlert, setLowTriggerAlert] = useState(true);
  const handleRadioLowTriggerAlertChange = event => {
    const lowTriggerAlertParam = event.target.value;
    setLowTriggerAlert(lowTriggerAlertParam === 'true');
  };

  const [yAxisMaxVal, setYAxisMaxVal] = useState(0);
  const [isYAxisMaxValTouched, setYAxisMaxValTouched] = useState(false);
  const onChangeYAxisMaxVal = event => {
    const yAxisMaxValParam = event.target.value;
    setYAxisMaxVal(yAxisMaxValParam);
    setYAxisMaxValTouched(true);
  };

  const [yAxisMinVal, setYAxisMinVal] = useState(0);
  const [isYAxisMinValTouched, setYAxisMinValTouched] = useState(false);
  const onChangeYAxisMinVal = event => {
    const yAxisMinValParam = event.target.value;
    setYAxisMinVal(yAxisMinValParam);
    setYAxisMinValTouched(true);
  };

  const [customSensorId, setCustomSensorId] = useState('');
  useEffect(() => {
    if (customItem && customItem.custom_sensor_id) {
      setCustomSensorId(customItem.custom_sensor_id);
      setName(customItem.name);
      setSensorPeriod(customItem.custom_period);
      setSensorType(customItem.custom_icon_type);
      setUnit(customItem.custom_unit);
      setDecimalPlace(customItem.decimal_place);
      setMultiplicationFactor(customItem.multiplication_factor);
      setLowTriggerAlert(customItem.low_trigger);
      setHighTriggerAlert(customItem.high_trigger);
      setYAxisMaxVal(customItem.y_axis?.max?.value);
      setYAxisMinVal(customItem.y_axis?.min?.value);
      setEngineeringUnit(customItem.range?.max?.value ? 'fixed_value' : 'address');
      setMaxEngUnitAddress(customItem.range?.max?.source || '');
      setMaxEngUnitValue(customItem.range?.max?.value);
      setMinEngUnitAddress(customItem.range?.min?.source || '');
      setMinEngUnitValue(customItem.range?.min?.value);
      setSource(customItem.source);
    } else {
      setCustomSensorId(undefined);
      setName('');
      setNameTouched(false);
      setSensorPeriod('');
      setSensorPeriodTouched(false);
      setSensorType('');
      setSensorTypeTouched(false);
      setUnit('');
      setUnitTouched(false);
      setDecimalPlace('0');
      setDecimalPlaceTouched(false);
      setMultiplicationFactor(0);
      setMultiplicationFactorTouched(false);
      setLowTriggerAlert(true);
      setHighTriggerAlert(true);
      setYAxisMaxVal(0);
      setYAxisMaxValTouched(false);
      setYAxisMinVal(0);
      setEngineeringUnit('address');
      setMaxEngUnitAddress('');
      setMaxEngUnitValue(null);
      setMaxEngUnitValueTouched(false);
      setMinEngUnitAddress('');
      setMinEngUnitValue(null);
      setSource('');
      setSourceTouched(false);
    }
  }, [customItem]);

  const [successCreatingSensor, setSuccessCreatingSensor] = useState(false);
  useEffect(() => {
    if (successCreatingSensor && open) {
      dispatch(loadSensor(machineId));
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines.form.sensor.message.creating_success'
        })
      );
      setSuccessCreatingSensor(false);
      dispatch(loadMetricsMachineDetails(machineId));
      handleClose();
    }
  }, [successCreatingSensor]);

  const [errorCreatingSensor, setErrorCreatingSensor] = useState(false);
  useEffect(() => {
    if (errorCreatingSensor) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines.form.sensor.message.creating_error'
        })
      );
      setErrorCreatingSensor(false);
    }
  }, [errorCreatingSensor]);

  useEffect(() => {
    if (open) {
      if (isSuccess(creatingSensorStatus.status)) {
        setSuccessCreatingSensor(true);
      }
      if (isError(creatingSensorStatus.status)) {
        setErrorCreatingSensor(true);
      }
    }
  }, [creatingSensorStatus.status]);

  const [successUpdatingSensor, setSuccessUpdatingSensor] = useState(false);
  useEffect(() => {
    if (successUpdatingSensor && open) {
      dispatch(loadSensor(machineId));
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines.form.sensor.message.updating_success'
        })
      );
      setSuccessUpdatingSensor(false);
      handleClose();
    }
  }, [successUpdatingSensor]);

  const [errorUpdatingSensor, setErrorUpdatingSensor] = useState(false);
  useEffect(() => {
    if (errorUpdatingSensor) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines.form.sensor.message.updating_error'
        })
      );
      setErrorUpdatingSensor(false);
    }
  }, [errorUpdatingSensor]);

  useEffect(() => {
    if (open) {
      if (isSuccess(updatingSensorStatus.status)) {
        setSuccessUpdatingSensor(true);
      }
      if (isError(updatingSensorStatus.status)) {
        setErrorUpdatingSensor(true);
      }
    }
  }, [updatingSensorStatus.status]);

  const handleCancel = () => {
    handleClose();
  };

  function canSave() {
    return (
      !!name &&
      !!sensorPeriod &&
      !!sensorType &&
      !!unit &&
      !!decimalPlace &&
      !!multiplicationFactor &&
      (minEngUnitValue ? maxEngUnitValue > minEngUnitValue : true) &&
      !Number.isNaN(yAxisMaxVal) &&
      !Number.isNaN(yAxisMinVal) &&
      yAxisMaxVal > yAxisMinVal &&
      !!source
    );
  }

  function isSaving() {
    return isLoading(creatingSensorStatus.status) || isLoading(updatingSensorStatus.status);
  }

  const handleOk = () => {
    const isEditing = !!customSensorId || false;
    const payload = {
      is_custom: true,
      is_static: false,
      name,
      period: '',
      custom_period: sensorPeriod,
      custom_icon_type: sensorType,
      custom_unit: unit,
      decimal_place: decimalPlace,
      multiplication_factor: +multiplicationFactor,
      low_trigger: lowTriggerAlert,
      high_trigger: highTriggerAlert,
      y_axis: {
        max: {
          specified: !!yAxisMaxVal,
          value: +yAxisMaxVal
        },
        min: {
          specified: !!yAxisMinVal,
          value: +yAxisMinVal
        }
      },
      range: {
        max: {
          value: engineeringUnit === 'fixed_value' ? +maxEngUnitValue : null,
          source: engineeringUnit === 'address' ? maxEngUnitAddress || null : null
        },
        min: {
          value: engineeringUnit === 'fixed_value' ? +minEngUnitValue : null,
          source: engineeringUnit === 'address' ? minEngUnitAddress || null : null
        }
      },
      source,
      type: '',
      units: ''
    };

    if (isEditing) {
      payload.custom_sensor_id = customSensorId;
      dispatch(updateSensor(machineId, payload));
    } else {
      dispatch(createSensor(machineId, payload));
    }
  };

  const content = (
    <Stack spacing={5} justifyContent="center" sx={{ width: '1062px' }}>
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
          {t('machines.form.sensor.custom_sensor_data.title')}
        </Typography>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <TextField
              required
              fullWidth
              id="name"
              label={t('machines.form.sensor.name')}
              variant="filled"
              value={name}
              onChange={onChangeName}
              error={!name && isNameTouched}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="source"
              label={t('machines.form.sensor.plc_source')}
              variant="filled"
              value={source}
              onChange={onChangeSource}
              error={!source && isSourceTouched}
            />
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <FormControl variant="filled" required fullWidth error={!sensorType && isSensorTypeTouched}>
              <InputLabel id="sensor-type">{t('machines.form.sensor.custom_icon_type')}</InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="sensor-type-label"
                id="sensor-type-select"
                onChange={handleSensorTypeChange}
                value={sensorType}
              >
                {CUSTOM_ICON_TYPE_LIST.map(iconType => (
                  <MenuItem key={iconType.c} value={iconType.c}>
                    {t(`${iconType.t}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="filled" required fullWidth error={!sensorPeriod && isSensorPeriodTouched}>
              <InputLabel id="sensor-period">{t('machines.form.sensor.custom_period')}</InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="sensor-period-label"
                id="sensor-period-select"
                onChange={handleSensorPeriodChange}
                value={sensorPeriod}
              >
                {PERIODS_LIST.map(period => (
                  <MenuItem key={period} value={period}>
                    {t(`machines.form.sensor.periods.${period}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <TextField
              required
              fullWidth
              id="unit"
              label={t('machines.form.sensor.custom_unit')}
              variant="filled"
              value={unit}
              onChange={onChangeUnit}
              error={!unit && isUnitTouched}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="number"
              id="multiplication-factor"
              label={t('machines.form.sensor.multiplication_factor')}
              variant="filled"
              value={multiplicationFactor}
              onChange={onChangeMultiplicationFactor}
              error={!multiplicationFactor && isMultiplicationFactorTouched}
              InputProps={{
                inputProps: {
                  min: '0',
                  max: '100000',
                  step: '1'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <FormControl variant="filled" required fullWidth error={!decimalPlace && isDecimalPlaceTouched}>
              <InputLabel id="decimal-place">{t('machines.form.sensor.decimal_place')}</InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="decimal-place-label"
                id="decimal-place-select"
                onChange={onChangeDecimalPlace}
                value={decimalPlace}
              >
                {DECIMAL_PLACES.map(num => (
                  <MenuItem key={`${num.toString()}`} value={`${num.toString()}`}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
          {t('machines.form.sensor.custom_sensor_data.engineering_unit')}
        </Typography>
        <RadioGroup
          row
          aria-labelledby="radio-engineering-unit-label"
          name="radio-engineering-unit"
          sx={{ paddingLeft: 2 }}
          value={engineeringUnit}
          onChange={handleRadioEngineeringUnitChange}
        >
          <Grid container spacing={2} columns={12}>
            <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
              <FormControlLabel value="address" control={<Radio />} label={t('machines.form.sensor.source')} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel value="fixed_value" control={<Radio />} label={t('machines.form.sensor.value')} />
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}>
              <TextField
                fullWidth
                id="max-eng-unit-address"
                label={t('machines.form.sensor.custom_sensor_data.max_eng_unit_address')}
                variant="filled"
                value={maxEngUnitAddress}
                onChange={onChangeMaxEngUnitAddress}
                disabled={engineeringUnit === 'fixed_value'}
              />
            </Grid>
            <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
              <TextField
                fullWidth
                type="number"
                id="max-eng-unit-value"
                label={t('machines.form.sensor.custom_sensor_data.max_eng_unit_value')}
                variant="filled"
                value={maxEngUnitValue}
                error={maxEngUnitValue <= minEngUnitValue && isMaxEngUnitValueTouched}
                helperText={
                  maxEngUnitValue <= minEngUnitValue && isMaxEngUnitValueTouched
                    ? t('machines.form.sensor.custom_sensor_data.eng_unit_value_validation')
                    : undefined
                }
                onChange={onChangeMaxEngUnitValue}
                disabled={engineeringUnit === 'address'}
              />
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
              <TextField
                fullWidth
                id="min-eng-unit-address"
                label={t('machines.form.sensor.custom_sensor_data.min_eng_unit_address')}
                variant="filled"
                value={minEngUnitAddress}
                onChange={onChangeMinEngUnitAddress}
                disabled={engineeringUnit === 'fixed_value'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                id="min-eng-unit-value"
                label={t('machines.form.sensor.custom_sensor_data.min_eng_unit_value')}
                variant="filled"
                value={minEngUnitValue}
                onChange={onChangeMinEngUnitValue}
                disabled={engineeringUnit === 'address'}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Stack>
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
          {t('machines.form.sensor.custom_sensor_data.trigger_alert')}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {t('machines.form.sensor.custom_sensor_data.trigger_alert_caption')}
        </Typography>
        <Grid container spacing={0.5} columns={12} sx={{ pt: 3 }}>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <RadioGroup
              row
              aria-labelledby="high-trigger-alert-label"
              name="high-trigger-alert"
              value={highTriggerAlert}
              onChange={handleRadioHighTriggerAlertChange}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography sx={{ fontSize: 16, fontWeight: 'bold', pr: 6, width: '210px' }}>
                  {t('machines.form.sensor.custom_sensor_data.high_trigger_alert')}
                </Typography>
                <FormControlLabel value control={<Radio />} label={t('default_actions.enabled')} />
                <FormControlLabel value={false} control={<Radio />} label={t('default_actions.disabled')} />
              </Stack>
            </RadioGroup>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <RadioGroup
              row
              aria-labelledby="low-trigger-alert-label"
              name="low-trigger-alert"
              value={lowTriggerAlert}
              onChange={handleRadioLowTriggerAlertChange}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography sx={{ fontSize: 16, fontWeight: 'bold', pr: 6, width: '210px' }}>
                  {t('machines.form.sensor.custom_sensor_data.low_trigger_alert')}
                </Typography>
                <FormControlLabel value control={<Radio />} label={t('default_actions.enabled')} />
                <FormControlLabel value={false} control={<Radio />} label={t('default_actions.disabled')} />
              </Stack>
            </RadioGroup>
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Stack>
      <Stack spacing={2}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('machines.form.sensor.y_axis.title')}</Typography>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <TextField
              required
              fullWidth
              type="number"
              id="y-axis-max-val"
              label={t('machines.form.sensor.y_axis.enter_max_val')}
              variant="filled"
              value={yAxisMaxVal}
              onChange={onChangeYAxisMaxVal}
              error={(Number.isNaN(yAxisMaxVal) || yAxisMaxVal <= yAxisMinVal) && isYAxisMaxValTouched}
              helperText={
                yAxisMaxVal <= yAxisMinVal && isYAxisMaxValTouched
                  ? t('machines.form.sensor.custom_sensor_data.y_axis_validation')
                  : undefined
              }
              InputProps={{
                inputProps: {
                  min: '-100000',
                  max: '100000'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <TextField
              required
              fullWidth
              type="number"
              id="y-axis-min-val"
              label={t('machines.form.sensor.y_axis.enter_min_val')}
              variant="filled"
              value={yAxisMinVal}
              onChange={onChangeYAxisMinVal}
              error={Number.isNaN(yAxisMinVal) && isYAxisMinValTouched}
              InputProps={{
                inputProps: {
                  min: '-100000',
                  max: '100000'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Stack>
    </Stack>
  );

  return (
    <NewDialog
      open={open}
      canSave={canSave()}
      isSaving={isSaving()}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      handleCancel={handleCancel}
      handleOk={handleOk}
      title={
        customItem
          ? t('machines.machine_details.sensor_data_modal.title_edit')
          : t('machines.machine_details.sensor_data_modal.title')
      }
      subTitle={t('machines.machine_details.sensor_data_modal.subtitle')}
      content={content}
      cancelCaption={t('default_actions.cancel')}
      okCaption={t('default_actions.save')}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      maxWidth="98%"
      maxHeight="98%"
    />
  );
};

CustomSensor.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  machineId: T.string.isRequired,
  customItem: T.shape({
    custom_sensor_id: T.string,
    name: T.string,
    custom_period: T.string,
    custom_icon_type: T.string,
    custom_unit: T.string,
    decimal_place: T.string,
    multiplication_factor: T.number,
    low_trigger: T.bool,
    high_trigger: T.bool,
    y_axis: T.shape({
      max: T.shape({
        specified: T.bool,
        value: T.number
      }),
      min: T.shape({
        specified: T.bool,
        value: T.number
      })
    }),
    range: T.shape({
      max: T.shape({
        value: T.number,
        source: T.string
      }),
      min: T.shape({
        value: T.number,
        source: T.string
      })
    }),
    source: T.string,
    type: T.string,
    units: T.string
  })
};

CustomSensor.defaultProps = {
  customItem: null
};

export default CustomSensor;
