import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { NewDialog } from 'web-components';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSensorReducerState } from 'redux/rootSelectors';
import {
  convertSecondsToTime,
  convertTimeToSeconds,
  generateId,
  isError,
  isLoading,
  isSuccess,
  validateBlankSpace
} from 'helpers/utils';
import { addNotification } from 'redux/ui/notifications/actions';
import { ERROR, SUCCESS } from 'attrs/status';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DECIMAL_PLACES, PERIODS_LIST, SENSOR_TYPE_IRIS_V3_LIST } from '../../../../../attrs/sensorConfig';
import { loadSensor } from '../../../../../redux/sensor/actions';
import { createStaticValueIrisV3, updateStaticValueIrisV3 } from '../../../../../redux/machines_v2/actions';

const AddStaticSensorIrisV3 = ({ open, handleClose, machineId, data }) => {
  const { creatingSensorStatus, updatingSensorStatus } = useSelector(getSensorReducerState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [isNameTouched, setNameTouched] = useState(false);
  const onChangeName = event => {
    const nameParam = event.target.value;
    setName(nameParam);
    setNameTouched(true);
  };

  const [variableName, setVariableName] = useState('');
  const [isVariableTouched, setVariableTouched] = useState(false);
  const onChangeVariableName = event => {
    let variableNameParam = event.target.value;
    variableNameParam = validateBlankSpace(variableNameParam);
    setVariableName(variableNameParam);
    setVariableTouched(true);
  };

  const [sensorType, setSensorType] = useState('');
  const [isSensorTypeTouched, setSensorTypeTouched] = useState(false);
  const handleSensorTypeChange = event => {
    const sensorTypeParam = event.target.value;
    setSensorType(sensorTypeParam);
    setSensorTypeTouched(true);
  };

  const [sensorPeriod, setSensorPeriod] = useState('');
  const [isSensorPeriodTouched, setSensorPeriodTouched] = useState(false);
  const handleSensorPeriodChange = event => {
    let sensorPeriodParam = event.target.value;
    sensorPeriodParam = validateBlankSpace(sensorPeriodParam);
    setSensorPeriod(sensorPeriodParam);
  };

  const [unit, setUnit] = useState('');
  const [isUnitTouched, setUnitTouched] = useState(false);
  const onChangeUnit = event => {
    const unitParam = event.target.value;
    setUnit(unitParam);
    setUnitTouched(true);
  };

  const [valueType, setValueType] = useState('');
  const onChangeValueType = event => {
    const valueTypeParam = event.target.value;
    setValueType(valueTypeParam);
  };

  const [multiplicationFactor, setMultiplicationFactor] = useState(1);
  const [isMultiplicationFactorTouched, setMultiplicationFactorTouched] = useState(false);
  const onChangeMultiplicationFactor = event => {
    const multiplicationFactorParam = +event.target.value;
    setMultiplicationFactor(multiplicationFactorParam);
    setMultiplicationFactorTouched(true);
  };

  const [decimalPlace, setDecimalPlace] = useState('0');
  const [isDecimalPlaceTouched, setDecimalPlaceTouched] = useState(false);
  const onChangeDecimalPlace = event => {
    const decimalPlaceParam = event.target.value;
    setDecimalPlace(decimalPlaceParam);
    setDecimalPlaceTouched(true);
  };

  const [staticSensorId, setAddStaticSensorId] = useState();
  useEffect(() => {
    if (data) {
      setAddStaticSensorId(data.id);
      setName(data.name);
      setVariableName(data.variable_name);
      setSensorPeriod(convertSecondsToTime(data.interval));
      setSensorType(data.type);
      setUnit(data.unit);
      setValueType(data.value_type);
      setMultiplicationFactor(data.multiplication_factor);
      setDecimalPlace(data.decimal_places);
    } else {
      setAddStaticSensorId(undefined);
      setName('');
      setNameTouched(false);
      setSensorPeriod('');
      setSensorPeriodTouched(false);
      setSensorType('');
      setSensorTypeTouched(false);
      setUnit('');
      setUnitTouched(false);
      setVariableName('');
      setVariableTouched(false);
      setValueType('');
      setMultiplicationFactor(1);
      setDecimalPlace('0');
    }
  }, [data, open]);

  const [successCreatingSensor, setSuccessCreatingSensor] = useState(false);
  useEffect(() => {
    if (successCreatingSensor && open) {
      dispatch(loadSensor(machineId));
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines.form.sensor.message.creating_success'
        })
      );
      setSuccessCreatingSensor(false);
      handleClose();
    }
  }, [successCreatingSensor, dispatch, handleClose, machineId, open]);

  const [errorCreatingSensor, setErrorCreatingSensor] = useState(false);
  useEffect(() => {
    if (errorCreatingSensor) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines.form.sensor.message.creating_error'
        })
      );
      setErrorCreatingSensor(false);
    }
  }, [errorCreatingSensor, dispatch]);

  useEffect(() => {
    if (open) {
      if (isSuccess(creatingSensorStatus.status)) {
        setSuccessCreatingSensor(true);
      }
      if (isError(creatingSensorStatus.status)) {
        setErrorCreatingSensor(true);
      }
    }
  }, [creatingSensorStatus.status, open]);

  const [successUpdatingSensor, setSuccessUpdatingSensor] = useState(false);
  useEffect(() => {
    if (successUpdatingSensor && open) {
      dispatch(loadSensor(machineId));
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines.form.sensor.message.updating_success'
        })
      );
      setSuccessUpdatingSensor(false);
      handleClose();
    }
  }, [successUpdatingSensor, dispatch, handleClose, machineId, open]);

  const [errorUpdatingSensor, setErrorUpdatingSensor] = useState(false);
  useEffect(() => {
    if (errorUpdatingSensor) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines.form.sensor.message.updating_error'
        })
      );
      setErrorUpdatingSensor(false);
    }
  }, [errorUpdatingSensor, dispatch]);

  useEffect(() => {
    if (open) {
      if (isSuccess(updatingSensorStatus.status)) {
        setSuccessUpdatingSensor(true);
      }
      if (isError(updatingSensorStatus.status)) {
        setErrorUpdatingSensor(true);
      }
    }
  }, [updatingSensorStatus.status, open]);

  const handleCancel = () => {
    handleClose();
  };

  function canSave() {
    return !!name && !!sensorPeriod && !!sensorType && !!variableName && !!unit && !!valueType;
  }

  function isSaving() {
    return isLoading(creatingSensorStatus.status) || isLoading(updatingSensorStatus.status);
  }

  const handleOk = () => {
    const isEditing = !!staticSensorId || false;
    const payload = {
      decimal_places: decimalPlace,
      interval: convertTimeToSeconds(sensorPeriod),
      multiplication_factor: multiplicationFactor,
      name,
      type: sensorType,
      unit,
      variable_name: variableName,
      value_type: valueType
    };

    if (isEditing) {
      payload.id = staticSensorId;
      dispatch(updateStaticValueIrisV3(machineId, payload));
      handleClose();
    } else {
      dispatch(createStaticValueIrisV3(machineId, payload));
      handleClose();
    }
  };

  const content = (
    <Grid container spacing={2} columns={12} sx={{ maxWidth: '1062px' }}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="name"
          label={t('machines.machine_details.sensor_static_modal.label_static_name')}
          variant="filled"
          value={name}
          onChange={onChangeName}
          error={!name && isNameTouched}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          id="variableName"
          label={t('machines.machine_details.sensor_static_modal.label_variable_name')}
          variant="filled"
          value={variableName}
          onChange={onChangeVariableName}
          error={!variableName && isVariableTouched}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="filled" required fullWidth error={!sensorType && isSensorTypeTouched}>
          <InputLabel id="sensor-type">
            {t('machines.machine_details.sensor_static_modal.label_static_type')}
          </InputLabel>
          <Select
            fullWidth
            IconComponent={KeyboardArrowDownIcon}
            labelId="sensor-type-label"
            id="sensor-type-select"
            onChange={handleSensorTypeChange}
            value={sensorType}
          >
            {SENSOR_TYPE_IRIS_V3_LIST.map(iconType => (
              <MenuItem key={iconType.c} value={iconType.c}>
                {t(`${iconType.t}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="filled" required fullWidth error={!sensorPeriod && isSensorPeriodTouched}>
          <InputLabel id="sensor-period">
            {t('machines.machine_details.sensor_static_modal.label_static_period')}
          </InputLabel>
          <Select
            fullWidth
            IconComponent={KeyboardArrowDownIcon}
            labelId="sensor-period-label"
            id="sensor-period-select"
            onChange={handleSensorPeriodChange}
            value={sensorPeriod}
          >
            {PERIODS_LIST.map(period => (
              <MenuItem key={period} value={period}>
                {t(`machines.form.sensor.periods.${period}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          fullWidth
          id="unit"
          label={t('machines.machine_details.sensor_static_modal.label_static_unit')}
          variant="filled"
          value={unit}
          onChange={onChangeUnit}
          error={!unit && isUnitTouched}
        />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          row
          aria-labelledby="radio-value-type-label"
          name="radio-value-type"
          value={valueType}
          onChange={onChangeValueType}
        >
          <FormControlLabel
            value="STRING"
            control={<Radio />}
            label={t('machines.machine_details.sensor_static_modal.label_static_text')}
          />
          <FormControlLabel
            value="NUMBER"
            control={<Radio />}
            label={t('machines.machine_details.sensor_static_modal.label_static_numeric')}
          />
          <FormControlLabel
            value="BOOLEAN"
            control={<Radio />}
            label={t('machines.machine_details.sensor_static_modal.label_static_boolean')}
          />
        </RadioGroup>
      </Grid>
      {valueType === 'NUMBER' && (
        <>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              type="number"
              id="multiplication-factor"
              label={t('machines.machine_details.sensor_static_modal.label_static_multiplication_factor')}
              variant="filled"
              value={multiplicationFactor}
              onChange={onChangeMultiplicationFactor}
              error={!multiplicationFactor && isMultiplicationFactorTouched}
              InputProps={{
                inputProps: {
                  min: '1',
                  max: '30',
                  step: '1'
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="filled" required fullWidth error={!decimalPlace && isDecimalPlaceTouched}>
              <InputLabel id="decimal-place">
                {t('machines.machine_details.sensor_static_modal.label_static_decimal_place')}
              </InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="decimal-place-label"
                id="decimal-place-select"
                onChange={onChangeDecimalPlace}
                value={decimalPlace}
              >
                {DECIMAL_PLACES.map(num => (
                  <MenuItem key={`${num.toString()}`} value={`${num.toString()}`}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <NewDialog
      open={open}
      canSave={canSave()}
      isSaving={isSaving()}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      handleCancel={handleCancel}
      handleOk={handleOk}
      title={
        data === null
          ? t('machines.machine_details.sensor_static_modal.title')
          : t('machines.machine_details.sensor_static_modal.title_edit')
      }
      subTitle={
        data === null
          ? t('machines.machine_details.sensor_static_modal.subtitle')
          : t('machines.machine_details.sensor_static_modal.subtitle_edit')
      }
      content={content}
      cancelCaption={t('default_actions.cancel')}
      okCaption={t('default_actions.save')}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      maxWidth="98%"
      maxHeight="98%"
    />
  );
};

AddStaticSensorIrisV3.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  machineId: T.string.isRequired,
  data: T.shape({
    id: T.string,
    name: T.string.isRequired,
    variable_name: T.string.isRequired,
    interval: T.number.isRequired,
    type: T.string.isRequired,
    unit: T.string.isRequired,
    value_type: T.oneOf(['STRING', 'NUMBER', 'BOOLEAN']).isRequired,
    multiplication_factor: T.number.isRequired,
    decimal_places: T.oneOfType([T.string, T.number]).isRequired
  })
};

AddStaticSensorIrisV3.defaultProps = {
  data: null
};

export default AddStaticSensorIrisV3;
