import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomersContent from './CustomersContent';
import { loadOrganizationsByResume } from '../../redux/organizations/actions';
import { getOrganizationByResume } from '../../redux/organizations/selectors';
import { getCommonLoadingState } from '../../helpers/utils';
import { getOrganizations } from '../../redux/rootSelectors';

const ConnectedCustomersContent = () => {
  const dispatch = useDispatch();
  const { loadingState } = useSelector(getOrganizations);
  const customers = useSelector(getOrganizationByResume);

  const loadNeededData = useCallback(() => {
    dispatch(loadOrganizationsByResume());
  }, [dispatch]);

  useEffect(() => {
    loadNeededData();
  }, [loadNeededData]);

  return (
    <CustomersContent
      loadingState={getCommonLoadingState([loadingState])}
      reloadAction={loadNeededData}
      customers={customers}
    />
  );
};

export default ConnectedCustomersContent;
