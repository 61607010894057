import React from 'react';
import T from 'prop-types';

import { useResolutionCheck } from 'web-components';
import { ContentWrapper } from '../../elements';
import { CustomerTableSelector } from '../Gateways/CustomerTableSelector';

const CustomersContent = ({ customers }) => {
  const { isDesktop } = useResolutionCheck();

  return (
    <>
      <ContentWrapper style={isDesktop ? { paddingLeft: '5rem', paddingRight: '5rem' } : {}} maxWidth={false}>
        <CustomerTableSelector rows={customers} />
      </ContentWrapper>
    </>
  );
};

CustomersContent.propTypes = {
  customers: T.instanceOf(Object)
};

CustomersContent.defaultProps = {
  customers: {}
};

export default CustomersContent;
