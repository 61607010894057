import React from 'react';
import { Box, Button, CircularProgress, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import T, { bool, func } from 'prop-types';
import { FormElements, NotifyTypo } from 'web-components';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateBatchTarget } from 'redux/batch/actions';
import { getBatchReducerState } from 'redux/rootSelectors';
import {
  getChangeNumberHandler,
  getFormikError,
  getFormikHelperText,
  isLoading,
  isNullUndefinedOrEmpty,
  validateBlankSpace
} from 'helpers/utils';
import { useSnackbar } from 'notistack';
import {
  StyledContent,
  StyledContentGridColumn,
  StyledGridItem,
  StyledHandlers,
  StyledNewDialog
} from '../../elements';
import { BatchTargetSchema } from '../../../../../attrs/formValidation';

const labelAmend = 'machines.machine_details';

const EditBatchRecordsGrinding = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { updateBatchTargetStatus } = useSelector(getBatchReducerState);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const getValue = val => {
    if (isNullUndefinedOrEmpty(val)) {
      return '';
    }
    return val;
  };

  function isSaving() {
    return isLoading(updateBatchTargetStatus.status);
  }

  const submitForm = (values, formikHelpers) => {
    try {
      dispatch(updateBatchTarget(machineId, values));
      handleClose(true);
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSaving()}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.handle_button`)}
        {isSaving() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  const handleChangeAddress = handleChange => event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Formik
      validationSchema={BatchTargetSchema}
      initialValues={{
        type: data?.batch_source?.target.type || '',
        time: {
          value: data?.batch_source?.target.time.value || '',
          hours: data?.batch_source?.target.time.hours || '',
          minutes: data?.batch_source?.target.time.minutes || ''
        },
        specific_energy: {
          value: data?.batch_source?.target.specific_energy.value || '',
          target: data?.batch_source?.target.specific_energy.target || ''
        },
        grinding_energy: {
          value: data?.batch_source?.target.grinding_energy.value || '',
          target: data?.batch_source?.target.grinding_energy.target || ''
        },
        cycles: {
          value: data?.batch_source?.target.cycles.value || '',
          target: data?.batch_source?.target.cycles.target || ''
        }
      }}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ setFieldValue, errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });
        const handleChangeNumber = getChangeNumberHandler(setFieldValue);

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.grinding_target_modal.title`)}
            subTitle={t(`${labelAmend}.grinding_target_modal.subtitle`)}
            maxWidth="1062px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGridColumn>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('type')}
                        helperText={getHelperText('type')}
                        fullWidth
                        id="grinding_target_type"
                        label={t(`${labelAmend}.grinding_target_modal.grinding_target`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        name="type"
                        value={values.type}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 4 }}>
                        <NotifyTypo.Body1 fontWeight="500">
                          {t(`${labelAmend}.grinding_target_modal.time`)}
                        </NotifyTypo.Body1>
                      </Box>
                      <FormElements.TextField
                        error={getError('time.value')}
                        helperText={getHelperText('time.value')}
                        fullWidth
                        id="time_value"
                        label={t(`${labelAmend}.grinding_target_modal.time_value`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeNumber('time.value')}
                        type="number"
                        min="0"
                        max="100000"
                        name="time.value"
                        value={getValue(values.time.value)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('time.hours')}
                        helperText={getHelperText('time.hours')}
                        fullWidth
                        id="time_target_hours"
                        label={t(`${labelAmend}.grinding_target_modal.time_address_h`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        name="time.hours"
                        value={values.time.hours}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('time.minutes')}
                        helperText={getHelperText('time.minutes')}
                        fullWidth
                        id="time_target_minutes"
                        label={t(`${labelAmend}.grinding_target_modal.time_address_m`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        name="time.minutes"
                        value={values.time.minutes}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 4 }}>
                        <NotifyTypo.Body1 fontWeight="500">
                          {t(`${labelAmend}.grinding_target_modal.grinding_energy_specific`)}
                        </NotifyTypo.Body1>
                      </Box>
                      <FormElements.TextField
                        error={getError('specific_energy.value')}
                        helperText={getHelperText('specific_energy.value')}
                        fullWidth
                        id="specific_grinding_energy_value"
                        label={t(`${labelAmend}.grinding_target_modal.grinding_energy_value`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeNumber('specific_energy.value')}
                        type="number"
                        min="0"
                        max="100000"
                        name="specific_energy.value"
                        value={getValue(values.specific_energy.value)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('specific_energy.target')}
                        helperText={getHelperText('specific_energy.target')}
                        fullWidth
                        id="specific_grinding_energy_target"
                        label={t(`${labelAmend}.grinding_target_modal.energy_address`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        name="specific_energy.target"
                        value={values.specific_energy.target}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 4 }}>
                        <NotifyTypo.Body1 fontWeight="500">
                          {t(`${labelAmend}.grinding_target_modal.grinding_energy_common`)}
                        </NotifyTypo.Body1>
                      </Box>
                      <FormElements.TextField
                        error={getError('grinding_energy.value')}
                        helperText={getHelperText('grinding_energy.value')}
                        fullWidth
                        id="grinding_energy_value"
                        label={t(`${labelAmend}.grinding_target_modal.energy_value_common`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeNumber('grinding_energy.value')}
                        type="number"
                        min="0"
                        max="100000"
                        name="grinding_energy.value"
                        value={getValue(values.grinding_energy.value)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('grinding_energy.target')}
                        helperText={getHelperText('grinding_energy.target')}
                        fullWidth
                        id="grinding_energy_target"
                        label={t(`${labelAmend}.grinding_target_modal.energy_address_common`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        name="grinding_energy.target"
                        value={values.grinding_energy.target}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 4 }}>
                        <NotifyTypo.Body1 fontWeight="500">
                          {t(`${labelAmend}.grinding_target_modal.number_cycles`)}
                        </NotifyTypo.Body1>
                      </Box>
                      <FormElements.TextField
                        error={getError('cycles.value')}
                        helperText={getHelperText('cycles.value')}
                        fullWidth
                        id="cycles_value"
                        label={t(`${labelAmend}.grinding_target_modal.number_cycles_value`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeNumber('cycles.value')}
                        type="number"
                        min="0"
                        max="100000"
                        name="cycles.value"
                        value={getValue(values.cycles.value)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('cycles.target')}
                        helperText={getHelperText('cycles.target')}
                        fullWidth
                        id="cycles_target"
                        label={t(`${labelAmend}.grinding_target_modal.cycles_address`)}
                        margin="none"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        name="cycles.target"
                        value={values.cycles.target}
                      />
                    </StyledGridItem>
                  </StyledContentGridColumn>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

EditBatchRecordsGrinding.propTypes = {
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired,
  data: T.shape({
    batch_source: T.shape({
      details: T.shape({
        batch_id: T.string,
        recipe: T.string,
        max_allowed_temperature: T.string,
        batch_size: T.string,
        progress: T.string,
        est_energy_consumption: T.string,
        cycles: T.string,
        total_grinding_time: T.string
      }),
      status: T.shape({
        complete: T.shape({
          value: T.string,
          address: T.string
        }),
        cancelled: T.shape({
          value: T.string,
          address: T.string
        }),
        in_operation: T.shape({
          value: T.string,
          address: T.string
        })
      }),
      target: T.shape({
        type: T.string,
        time: T.shape({
          hours: T.string,
          minutes: T.string,
          value: T.number
        }),
        grinding_energy: T.shape({
          target: T.string,
          value: T.number
        }),
        specific_energy: T.shape({
          target: T.string,
          value: T.number
        }),
        cycles: T.shape({
          target: T.string,
          value: T.number
        })
      })
    })
  })
};

EditBatchRecordsGrinding.defaultProps = {
  machineId: null,
  data: null
};

export default EditBatchRecordsGrinding;
