import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormElements, MACHINE_TYPE_NAMES, MachineTypes, NotifyTypo } from 'web-components';
import { Container, useTheme } from '@mui/material';
import { getDefaultFieldValue, getMachineType } from '../../../../helpers/utils';
import { StyledContentWrapper } from './elements';
import MachineDetailsBatchRecordFields from './MachineDetailsBatchRecordFields';
import FeatureAccessSwitch from './FeatureAccessSwitch';
import { StyledCheckbox, StyledSambaSystemRadioGroup } from '../elements';
import CommissionNumberFormElement from '../FormElements/CommissionNumberFormElement';
import {
  MachineBreadcrumb,
  MachinePageHeader
} from '../../../../containers/Machines/MachineDetail/MachineDetail.styled';
import { PageHeaderSubTitle, PageHeaderTitle } from '../../../../elements';

// PUMP POC RELATED
// For now,we are not supporting the creation/modification of pumps in the service app
const FILTERED_OUT_MACHINE_TYPES = [MACHINE_TYPE_NAMES.NETZSCH_PUMP_GENERIC, MACHINE_TYPE_NAMES.UNKNOWN];

const machineTypes = MachineTypes.filter(({ value }) => !FILTERED_OUT_MACHINE_TYPES.includes(value));

const MachineDetails = ({
  getError,
  getHelperText,
  handleBlur,
  handleChange,
  handleChangeNumber,
  handleChangeFloat,
  handleFeatureChange,
  setFieldValue,
  values,
  handleSubmit,
  touched,
  submitDisabled
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleTypeChange = e => {
    if (e.target.value !== MACHINE_TYPE_NAMES.NETZSCH_MASTER_REFINER) {
      setFieldValue('mechanical_config.samba_system', false);
      setFieldValue('mechanical_config.beads_size_2', '');
    }
    handleChange(e);
  };

  const handleCommissionNumberChange = e => {
    handleChange(e);
  };

  const handleCheckboxChange = e => setFieldValue('mechanical_config.samba_system', e.target.checked);

  const breadcrumbObj = [
    { route: '/machines', name: `${t('machines.title')}` },
    { route: `/machines/${values.id || ''}`, name: values.commission_number || t('machines.detail.new_machine') },
    { route: null, name: `${t('machines.detail.details.title')}` }
  ];

  return (
    <StyledContentWrapper elevation={3}>
      <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

      <MachinePageHeader>
        <PageHeaderTitle>{t('machines.detail.details.title')}</PageHeaderTitle>
        <PageHeaderSubTitle>{t('machines.detail.subtitle')}</PageHeaderSubTitle>
      </MachinePageHeader>

      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <FormElements.TextField
          disabled
          fullWidth
          id="gateway"
          label={t('machines.form.gateway')}
          margin="normal"
          name="gateway"
          value={getDefaultFieldValue(values.gateway_SN) || '--'}
        />
        <FormElements.TextField
          disabled
          fullWidth
          id="brand"
          label={t('machines.form.brand')}
          margin="normal"
          name="brand"
          value={getMachineType(values.type).brand}
        />
        <FormElements.Select
          fullWidth
          error={getError('type')}
          helperText={getHelperText('type')}
          handleChange={handleTypeChange}
          id="type"
          label={t('machines.form.type')}
          name="type"
          onBlur={handleBlur}
          required
          value={values.type}
        >
          {machineTypes.map(machineType => (
            <option key={machineType.value} value={machineType.value}>
              {machineType.type}
            </option>
          ))}
        </FormElements.Select>
        <StyledSambaSystemRadioGroup theme={theme} disabled={values.type !== MACHINE_TYPE_NAMES.NETZSCH_MASTER_REFINER}>
          <NotifyTypo.InfoText>{t('machines.form.samba_system_label')}</NotifyTypo.InfoText>
          <StyledCheckbox
            activeColor="primary"
            checked={values.mechanical_config.samba_system}
            label={t('machines.form.samba_system_title')}
            onChange={handleCheckboxChange}
            disabled={values.type !== MACHINE_TYPE_NAMES.NETZSCH_MASTER_REFINER}
          />
        </StyledSambaSystemRadioGroup>

        <CommissionNumberFormElement
          handleBlur={handleBlur}
          handleChange={handleCommissionNumberChange}
          values={{ commission_number: values.commission_number, commission_number_status: true }}
          errors={{ commission_number: getError('commission_number') }}
          handleSubmit={handleSubmit}
          touched={touched}
          submitDisabled={submitDisabled}
        />
        <MachineDetailsBatchRecordFields
          isMasterRefiner={values.type === MACHINE_TYPE_NAMES.NETZSCH_MASTER_REFINER}
          getError={getError}
          getHelperText={getHelperText}
          handleBlur={handleBlur}
          handleChangeNumber={handleChangeNumber}
          handleChangeFloat={handleChangeFloat}
          setFieldValue={setFieldValue}
          values={values}
        />
        <FeatureAccessSwitch values={values} setFieldValue={setFieldValue} handleFeatureChange={handleFeatureChange} />
      </Container>
    </StyledContentWrapper>
  );
};

MachineDetails.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  handleChangeNumber: T.func.isRequired,
  handleChangeFloat: T.func.isRequired,
  handleFeatureChange: T.func.isRequired,
  setFieldValue: T.func.isRequired,
  values: T.shape({
    id: T.string,
    type: T.string,
    commission_number: T.string,
    gateway_SN: T.string,
    mechanical_config: T.shape({
      samba_system: T.bool
    }),
    enabled_config: T.shape({
      batch: T.bool
    })
  }).isRequired,
  handleSubmit: T.func.isRequired,
  touched: T.shape({
    type: T.bool,
    commission_number: T.bool,
    gateway_SN: T.bool,
    mechanical_config: T.shape({
      samba_system: T.bool
    }),
    enabled_config: T.shape({
      batch: T.bool
    })
  }),
  submitDisabled: T.bool.isRequired
};

MachineDetails.defaultProps = {
  touched: {}
};

export default MachineDetails;
