import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'web-components';

import {
  GATEWAY_CONNECTED,
  GATEWAY_DEFAULT_LIST_ORDER,
  GATEWAY_DEFAULT_LIST_SORT,
  GATEWAY_NOT_CONNECTED
} from 'attrs/gateways';
import { getGatewayFilteredResume } from 'redux/gateways/actions';
import { Box } from '@mui/material';
import Footer from '../DataTable/Footer';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';
import { GatewayFilter } from './GatewayFilter';

const GatewayTable = ({ columns, rows }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(PAGINATION_DEFAULT_START_PAGE);
  const [currentView, setCurrentView] = useState([]);
  const [currentSort, setCurrentSort] = useState({
    colId: GATEWAY_DEFAULT_LIST_SORT,
    order: GATEWAY_DEFAULT_LIST_ORDER
  });
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN;

  const [filterOrganizationName, setFilterOrganizationName] = useState([]);
  const [filterGatewayId, setFilterGatewayId] = useState([]);
  const [filterConfigured, setFilterConfigured] = useState([]);
  const [filterConnection, setFilterConnection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    if (rows && rows.data && rows.data.length) {
      setCurrentView(rows.data);
    } else {
      setCurrentView([]);
    }
  }, [rows, currentSort.colId, currentSort.order]);

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(
      getGatewayFilteredResume(
        filterGatewayId,
        filterConfigured,
        filterOrganizationName,
        filterConnection,
        rowsPerPage,
        page,
        currentSort.order,
        currentSort.colId
      )
    );
  };

  const handleSelectRow = row => history.push(`/gateways/${row.serial_number}`);

  const handleSort = (colId, order) => {
    dispatch(
      getGatewayFilteredResume(
        filterGatewayId,
        filterConfigured,
        filterOrganizationName,
        filterConnection,
        rowsPerPage,
        PAGINATION_DEFAULT_START_PAGE,
        order.toUpperCase(),
        colId
      )
    );
    setCurrentSort({ colId, order });
  };

  const props = {
    dataSelectorTable: 'gateway-table',
    dataSelectorRows: 'gateway-table-row',
    isLoading,
    columns,
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={rows.total_pages || 1}
        totalLength={rows.total_length || 0}
        totalItemsPerPage={rows.limit || 0}
        onChange={handleChangePage}
      />
    ),
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  const configuredObject = [
    { label: 'general.all', value: '' },
    { label: 'gateways.configured', value: 'true' },
    { label: 'gateways.not_configured', value: 'false' }
  ];

  const connectionObject = [
    { label: 'general.all', value: '' },
    { label: 'gateways.connected', value: GATEWAY_CONNECTED },
    { label: 'gateways.not_connected', value: GATEWAY_NOT_CONNECTED }
  ];

  const handleChangeFilter = (page, organizationName, gatewayId, configured, connection) => {
    setCurrentPage(page);
    dispatch(
      getGatewayFilteredResume(
        gatewayId,
        configured,
        organizationName,
        connection,
        rowsPerPage,
        page,
        currentSort.order,
        currentSort.colId
      )
    );
  };

  const handleFilterOrganizationName = organizationName => {
    if (organizationName !== filterOrganizationName) {
      setIsLoading(true);
      setFilterOrganizationName(organizationName);
      handleChangeFilter(
        PAGINATION_DEFAULT_START_PAGE,
        organizationName,
        filterGatewayId,
        filterConfigured,
        filterConnection
      );
    }
  };

  const handleFilterGatewayId = gatewayId => {
    if (gatewayId !== filterGatewayId) {
      setIsLoading(true);
      setFilterGatewayId(gatewayId);
      handleChangeFilter(
        PAGINATION_DEFAULT_START_PAGE,
        filterOrganizationName,
        gatewayId,
        filterConfigured,
        filterConnection
      );
    }
  };

  const handleFilterConfigured = configured => {
    if (configured !== filterConfigured) {
      setIsLoading(true);
      setFilterConfigured(configured);
      handleChangeFilter(
        PAGINATION_DEFAULT_START_PAGE,
        filterOrganizationName,
        filterGatewayId,
        configured,
        filterConnection
      );
    }
  };

  const handleFilterConnection = connection => {
    if (connection !== filterConnection) {
      setIsLoading(true);
      setFilterConnection(connection);
      handleChangeFilter(
        PAGINATION_DEFAULT_START_PAGE,
        filterOrganizationName,
        filterGatewayId,
        filterConfigured,
        connection
      );
    }
  };

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ flexWrap: 'wrap', py: 4 }}>
        <GatewayFilter
          clear={clear}
          optionText={filterGatewayId}
          handleFilter={handleFilterGatewayId}
          labelFilter={t('gateways.filter.gateway_id')}
          setClear={setClear}
        />
        <GatewayFilter
          clear={clear}
          optionText={filterOrganizationName}
          handleFilter={handleFilterOrganizationName}
          labelFilter={t('gateways.filter.customer_id')}
          setClear={setClear}
        />
        <GatewayFilter
          isCheckbox={false}
          clear={clear}
          optionText={filterConfigured}
          handleFilter={handleFilterConfigured}
          labelFilter={t('gateways.overview.is_configured')}
          optionsObj={configuredObject}
          setClear={setClear}
        />
        <GatewayFilter
          isCheckbox={false}
          clear={clear}
          optionText={filterConnection}
          handleFilter={handleFilterConnection}
          labelFilter={t('gateways.overview.status')}
          optionsObj={connectionObject}
          setClear={setClear}
        />
      </Box>
      <DataTable hover {...props} />
    </Box>
  );
};

GatewayTable.propTypes = {
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired,
  rows: T.instanceOf(Object).isRequired
};

export default GatewayTable;
