import React from 'react';
import { Stack } from '@mui/material';
import { MachineWithoutGateway } from './MachineWithoutGateway';
import { GatewayLostConnection } from './GatewayLostConnection';
import { GatewaysWithIssues } from './GatewaysWithIssues';

export function Gateway() {
  window.history.replaceState(null, '', '/dashboard/gateway');
  return (
    <Stack direction="column" spacing={4}>
      <GatewayLostConnection />
      <MachineWithoutGateway />
      <GatewaysWithIssues />
    </Stack>
  );
}
