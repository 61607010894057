import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';
import { LOAD_ACTIVE_CUSTOMERS, LOAD_ACTIVE_USERS, LOAD_CUSTOMERS_MONTH, LOAD_FREEMIUM_MONTH } from './constants';

export default combineReducers({
  loadActiveCustomersStatus: loadingStateReducer(LOAD_ACTIVE_CUSTOMERS),
  activeCustomersResponse: typeToReducer(
    {
      [LOAD_ACTIVE_CUSTOMERS]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  ),
  loadActiveUsersStatus: loadingStateReducer(LOAD_ACTIVE_USERS),
  activeUsersResponse: typeToReducer(
    {
      [LOAD_ACTIVE_USERS]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  ),
  loadCustomersMonthStatus: loadingStateReducer(LOAD_CUSTOMERS_MONTH),
  customersMonthResponse: typeToReducer(
    {
      [LOAD_CUSTOMERS_MONTH]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  ),
  loadFreemiumMonthStatus: loadingStateReducer(LOAD_FREEMIUM_MONTH),
  freemiumMonthResponse: typeToReducer(
    {
      [LOAD_FREEMIUM_MONTH]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  )
});
