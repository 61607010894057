import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader, Tooltip } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import EditBatchRecordsDetails from '../EditBatchRecordsModal/EditBatchRecordsDetails';
import { PlcType } from '../../../../../attrs/plcConfigValues';

const labelAmend = 'machines.machine_details';

const BatchDetails = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Tooltip
            title={t(`machines.detail.plc_not_filled`)}
            disableHoverListener={plcType.plc_type !== PlcType.UNKNOWN}
            arrow
          >
            <span>
              <Button
                variant="text"
                disabled={plcType.plc_type === PlcType.UNKNOWN}
                sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
                onClick={() => setEditModal(true)}
              >
                <NotifyIcon iconName="edit" fontSize="medium" />
                {t(`${labelAmend}.edit`)}
              </Button>
            </span>
          </Tooltip>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.batch_details`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.batch_details_subtitle`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2} sx={{ mt: 3, mb: 4 }}>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.batch_details_label.id_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.details.batch_id ? '---' : data.batch_source?.details.batch_id}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.batch_details_label.recipe_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.details.recipe ? '---' : data.batch_source?.details.recipe}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.batch_details_label.maximum_temperature`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.details.max_allowed_temperature
              ? '---'
              : data.batch_source?.details.max_allowed_temperature}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.batch_details_label.size_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.details.batch_size ? '---' : data.batch_source?.details.batch_size}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.batch_details_label.progress_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.details.progress ? '---' : data.batch_source?.details.progress}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.batch_details_label.grinding_energy`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.details.est_energy_consumption
              ? '---'
              : data.batch_source?.details.est_energy_consumption}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.batch_details_label.cycles_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.details.cycles ? '---' : data.batch_source?.details.cycles}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.batch_details_label.total_time_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.details.total_grinding_time ? '---' : data.batch_source?.details.total_grinding_time}
          </NotifyTypo.Body1>
        </Box>
      </Box>

      <EditBatchRecordsDetails open={editModal} handleClose={handleClose} data={data} machineId={machineId} />
    </Card>
  );
};

BatchDetails.propTypes = {
  data: T.shape({
    batch_source: T.shape({
      details: T.shape({
        batch_id: T.string,
        recipe: T.string,
        max_allowed_temperature: T.string,
        batch_size: T.string,
        progress: T.string,
        est_energy_consumption: T.string,
        cycles: T.string,
        total_grinding_time: T.string
      })
    })
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

BatchDetails.defaultProps = {
  data: null
};

export default BatchDetails;
