import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import * as features from '../helpers/features';

import createSagaInjector from './createSagaInjector';
import rootSaga from './rootSaga';
import createRootReducer from './rootReducer';

let store = null;
let history = null;

export default preloadedState => {
  if (store && history) {
    return { store, history };
  }

  history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware();
  const enhancers = [];
  const middleware = [sagaMiddleware, routerMiddleware(history)];

  if (features.get('is_debug')) {
    const { __REDUX_DEVTOOLS_EXTENSION__: reduxDevTools } = window;

    if (typeof reduxDevTools === 'function') {
      enhancers.push(reduxDevTools());
    }
  }

  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
  store = createStore(createRootReducer(history), preloadedState, composedEnhancers);
  const { injectSaga, unplugSaga } = createSagaInjector(sagaMiddleware.run, rootSaga);
  store.injectSaga = injectSaga;
  store.unplugSaga = unplugSaga;

  return { store, history };
};
