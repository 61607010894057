import T from 'prop-types';
import React from 'react';
import { useResolutionCheck } from 'web-components';
import { getPlcProtocolName } from 'attrs/plcConfigValues';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { useTranslation } from 'react-i18next';
import MobileMachineTable from '../MobileMachineTable';
import CloneMachineTable from './CloneMachineTable';
import TableInput from '../TableInput/TableInput';

const CloneMachineTableSelector = ({ rows, organizationId, setSelectedMachineToClone, selectedMachineToClone }) => {
  const { isTablet } = useResolutionCheck();
  const { t } = useTranslation();

  const columns = [
    {
      id: 'commission_number',
      label: (
        <TableInput name="commission_number" placeholder={t('machines.overview.clone_modal.table.commission_number')} />
      ),
      sortable: true
    },
    {
      id: 'machine_type',
      label: <TableInput name="machine_type" placeholder={t('machines.overview.clone_modal.table.machine_type')} />,
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row.type)
    },
    {
      id: 'plc_type',
      label: <TableInput name="plc_type" placeholder={t('machines.overview.clone_modal.table.plc_type')} />,
      sortable: false,
      cellRenderer: row => getPlcProtocolName(row.plc.type)
    },
    {
      id: 'customer_name',
      label: <TableInput name="customer_name" placeholder={t('machines.overview.clone_modal.table.custumer')} />,
      sortable: true,
      cellRenderer: row => getValueToNullableCell(row.organization_name)
    }
  ];

  return isTablet ? (
    <MobileMachineTable columns={columns} rows={rows} />
  ) : (
    <CloneMachineTable
      columns={columns}
      rows={rows}
      organizationId={organizationId}
      selectedMachineToClone={selectedMachineToClone}
      setSelectedMachineToClone={setSelectedMachineToClone}
    />
  );
};

CloneMachineTableSelector.propTypes = {
  rows: T.instanceOf(Object).isRequired,
  organizationId: T.string,
  selectedMachineToClone: T.shape({
    id: T.string
  }).isRequired,
  setSelectedMachineToClone: T.func.isRequired
};

CloneMachineTableSelector.defaultProps = {
  organizationId: null
};

export default CloneMachineTableSelector;
