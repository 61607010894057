export const isString = value => typeof value === 'string' || value instanceof String;
export const defaultEmptyCellValue = '---';

/**
 * This function is used to handle nullable cell values. It checks if the provided value is not equal to zero, and
 * if it's not, it returns the value itself or a default value if the value is falsy (null, undefined, etc.).
 * If the value is zero, it returns false.
 *
 * @param {any} value - The value to be checked and returned if it's not zero and not falsy.
 * @param {any} [defaultValue=defaultEmptyCellValue] - The default value to be returned if the value is falsy.
 * @returns {any} - Returns the value if it's not zero and not falsy, the default value if the value is falsy,
 * or false if the value is zero.
 */
export const getValueToNullableCell = (value, defaultValue = defaultEmptyCellValue) =>
  +(value !== 0) && (value || defaultValue);

export const getBackendStringFormat = value => (isString(value) && !value ? undefined : value);

/**
 * // TODO: Waiting for the new function to replace this function
 * Convert empty string to null (for backend format) for all object properties
 * @param {Object} obj - object to convert
 * @param {Array} ignore - array of paths to ignore, for object {a: {b: {c: ''}}}, path is 'a.b.c'
 *  for array {a: [{b: '1'}, {b: ''}]}, path is 'a.n.b' (the 'n' is for array index)
 * @param {String} currentPath - current path, used only for recursion
 */
export const convertEmptyToNull = (obj, ignore = [], currentPath = '') => {
  let value = obj;
  let path = currentPath;
  if (value instanceof Array) {
    path = [path, 'n'].filter(Boolean).join('.');
    for (let i = 0; i < value.length; i += 1) {
      value[i] = convertEmptyToNull(value[i], ignore, path);
    }
  } else if (value instanceof Object) {
    Object.keys(value).forEach(key => {
      if (ignore.indexOf([path, key].filter(Boolean).join('.')) === -1) {
        value[key] = convertEmptyToNull(value[key], ignore, key);
      }
    });
  } else if (value === '') {
    value = null;
  }
  return value;
};
