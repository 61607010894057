import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormElements, NotifyTypo } from 'web-components';
import { numberOrEmpty } from 'helpers/utils';

import { StyledTab, StyledTabs } from '../../elements';
import { ENGINEERING_DATA_TYPES, ENGINEERING_UNITS, metricDataType } from '../../../../../../attrs/sensorConfig';

const EngineeringUnitsFields = ({
  values,
  engineeringDataType,
  namespace,
  getError,
  getHelperText,
  onChangeTab,
  onBlur,
  onChange
}) => {
  const { t } = useTranslation();
  const selectedEngineeringUnit = ENGINEERING_DATA_TYPES.find(type => type.id === engineeringDataType);
  const namespacePrefix = namespace ? `${namespace}.` : '';

  return (
    <>
      {ENGINEERING_UNITS.map(rangeUnit => (
        <div key={rangeUnit} style={{ margin: '2rem 0' }}>
          <NotifyTypo.Heading2>{t(`machines.form.sensor.${rangeUnit}_engineering_unit`)}</NotifyTypo.Heading2>
          <StyledTabs
            aria-label="engineering-unit-tab"
            color="primary"
            value={selectedEngineeringUnit.id}
            indicatorColor="primary"
            textColor="primary"
            onChange={onChangeTab}
          >
            {ENGINEERING_DATA_TYPES.map(type => (
              <StyledTab key={type.id} id={type.id} label={t(`machines.form.sensor.${type.label}`)} size="small" />
            ))}
          </StyledTabs>
          <div>
            {selectedEngineeringUnit.label === 'source' && (
              <FormElements.TextField
                fullWidth
                id={`${namespacePrefix}range.${rangeUnit}.source`}
                label={t('machines.form.sensor.source')}
                margin="normal"
                name={`${namespacePrefix}range.${rangeUnit}.source`}
                onBlur={onBlur}
                onChange={onChange}
                type="text"
                value={values.range[rangeUnit].source || ''}
              />
            )}
            {selectedEngineeringUnit.label === 'value' && (
              <FormElements.TextField
                fullWidth
                error={getError(`${namespacePrefix}range.${rangeUnit}.value`)}
                helperText={getHelperText(`${namespacePrefix}range.${rangeUnit}.value`)}
                id={`${namespacePrefix}range.${rangeUnit}.value`}
                label={t('machines.form.sensor.value')}
                margin="normal"
                name={`${namespacePrefix}range.${rangeUnit}.value`}
                onBlur={onBlur}
                onChange={onChange}
                type="number"
                value={numberOrEmpty(values.range[rangeUnit].value)}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

EngineeringUnitsFields.propTypes = {
  engineeringDataType: T.number.isRequired,
  namespace: T.string,
  values: metricDataType.isRequired,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  onChangeTab: T.func.isRequired,
  onBlur: T.func.isRequired,
  onChange: T.func.isRequired
};

EngineeringUnitsFields.defaultProps = {
  namespace: null
};

export default EngineeringUnitsFields;
