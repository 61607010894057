import axios from 'axios';
import { API_URL } from '../attrs/settings';

// create basic axios instance for non-jwtAuth requests
const basicInstance = axios.create({
  baseURL: API_URL
});

const checkToken = auth =>
  basicInstance({
    method: 'get',
    url: '/v1/tokens/check-code',
    params: {
      token: auth
    }
  });

const resetPasswordNonAuth = (values, auth, recaptchaValue) =>
  basicInstance({
    method: 'put',
    url: '/v1/users/set-password',
    params: {
      token: auth
    },
    data: {
      ...values,
      recaptchaValue
    }
  });

const registerUserWithDetails = (values, userId, auth) =>
  basicInstance({
    method: 'put',
    url: `/v1/users/${userId}`,
    headers: {
      Authorization: `Bearer ${auth}`
    },
    data: values
  });

export { checkToken, resetPasswordNonAuth, registerUserWithDetails };
