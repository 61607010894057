import React, { useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { NotifyIcon, NotifyTypo } from 'web-components';

import { getMachineType } from '../../helpers/utils';
import { StyledMobileTableCell } from './elements';
import { MachineCommissionNumber } from './MachineCommissionNumber';

const { Placeholder, Subtitle1, Body1 } = NotifyTypo;

const renderTableRowContent = (machine, key, t) => {
  const machineType = getMachineType(machine.type);

  return {
    brand: (
      <>
        <Subtitle1>{t('machines.overview.brand')}</Subtitle1>
        <Body1>{machineType.brand}</Body1>
      </>
    ),
    type: (
      <>
        <Subtitle1>{t('machines.overview.type')}</Subtitle1>
        <Body1>{machineType.type}</Body1>
      </>
    ),
    commission_number: (
      <>
        <Subtitle1>{t('machines.overview.commission_number')}</Subtitle1>
        <MachineCommissionNumber
          commissionNumber={machine.commission_number}
          status={machine.validations.commission_number}
        />
      </>
    ),
    organizationName: (
      <>
        <Subtitle1>{t('machines.overview.customer')}</Subtitle1>
        {machine.organizationName ? (
          <NotifyTypo.Body1>{machine.organizationName}</NotifyTypo.Body1>
        ) : (
          <NotifyTypo.Placeholder>{t('machines.overview.not_assigned')}</NotifyTypo.Placeholder>
        )}
      </>
    ),
    gatewaySN: (
      <>
        <Subtitle1>{t('machines.overview.gateway_serial_number')}</Subtitle1>
        {machine.gatewaySN ? (
          <NotifyTypo.Body1>{machine.gatewaySN}</NotifyTypo.Body1>
        ) : (
          <NotifyTypo.Placeholder>{t('machines.overview.not_assigned')}</NotifyTypo.Placeholder>
        )}
      </>
    ),
    name: (
      <>
        <Subtitle1>{t('machines.overview.name')}</Subtitle1>
        {machine.name ? (
          <NotifyTypo.Body1>{machine.name}</NotifyTypo.Body1>
        ) : (
          <NotifyTypo.Placeholder>{t('machines.overview.machine_name_not_assigned')}</NotifyTypo.Placeholder>
        )}
      </>
    ),
    production_line: (
      <>
        <Subtitle1>{t('machines.overview.production_line')}</Subtitle1>
        {machine.production_line ? (
          <Body1>{machine.production_line}</Body1>
        ) : (
          <Placeholder>{t('machines.overview.production_line_name_not_assigned')}</Placeholder>
        )}
      </>
    )
  }[key];
};

const Row = ({ row, columns }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [col0, col1, col2, ...hiddenColumns] = columns;

  return (
    <>
      <TableRow>
        <StyledMobileTableCell width={20}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            data-selector="machines-table-row-open-machine-button"
          >
            {open ? <NotifyIcon iconName="chevronUp" /> : <NotifyIcon iconName="chevronDown" />}
          </IconButton>
        </StyledMobileTableCell>

        <StyledMobileTableCell component="th" scope="row" align="left">
          {renderTableRowContent(row, col0.id, t)}
        </StyledMobileTableCell>
        <StyledMobileTableCell width={20} />
      </TableRow>
      {[col1, col2].map((col, idx) => (
        <TableRow key={col.id}>
          <StyledMobileTableCell />
          <StyledMobileTableCell>{renderTableRowContent(row, col.id, t)}</StyledMobileTableCell>
          <StyledMobileTableCell width={20}>
            {/* Pump PoC: It should not be able to modify a pump machine */}
            {idx === 0 && row.type !== 'NETZSCH_PUMP_GENERIC' && (
              <Link style={{ textDecoration: 'none' }} to={`/machines/${row.id}`}>
                <IconButton aria-label="go to machine" size="small">
                  <NotifyIcon iconName="chevronRight" />
                </IconButton>
              </Link>
            )}
          </StyledMobileTableCell>
        </TableRow>
      ))}
      <TableRow>
        <StyledMobileTableCell />
        <StyledMobileTableCell>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              {hiddenColumns.map((col, idx) => (
                <Box key={col.id} style={{ padding: idx === 0 ? '0 0 .5rem' : '.5rem 0' }}>
                  {renderTableRowContent(row, col.id, t)}
                </Box>
              ))}
            </Box>
          </Collapse>
        </StyledMobileTableCell>
        <StyledMobileTableCell />
      </TableRow>
    </>
  );
};

Row.propTypes = {
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired,
  row: T.shape({
    id: T.string,
    brand: T.string,
    type: T.string,
    name: T.string,
    commission_number: T.string,
    production_line: T.string
  }).isRequired
};

const MobileMachineTable = ({ columns, rows }) => (
  <div data-selector="machines-table">
    {rows.data.map(row => (
      <TableContainer key={row.id} component={Paper} style={{ margin: '1rem 0' }} data-selector="machines-table-row">
        <Table aria-label="machine table">
          <TableBody>
            <Row row={row} columns={columns} />
          </TableBody>
        </Table>
      </TableContainer>
    ))}
  </div>
);

MobileMachineTable.propTypes = {
  rows: T.shape({
    data: T.instanceOf(Array).isRequired
  }).isRequired,
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired
};

export default MobileMachineTable;
