import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader } from '@mui/material';
import { BuildCircleOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { blue, orange } from '@mui/material/colors';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { EditStatusConditionsIrisV3 } from './EditStatusModal';
import withLoadingState from '../../../../helpers/withLoadingState';
import { getValueToNullableCell } from '../../../../helpers/stringHandler';
import { StyledBoxValue } from './elements';

const labelAmend = 'machines.machine_details';

const StatusContentConditionsIrisV3 = ({ data, machineId }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <>
      <Card sx={{ p: 4, my: 2 }}>
        <CardHeader
          sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
          action={
            <Button
              variant="text"
              sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
              onClick={() => setEditModal(true)}
            >
              <NotifyIcon iconName="edit" fontSize="medium" />
              {t(`${labelAmend}.edit`)}
            </Button>
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.conditions`)}</Box>
            </Box>
          }
          titleTypographyProps={{
            fontWeight: '500',
            variant: 'Body1'
          }}
        />
        <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.status_conditions_info`)}</NotifyTypo.Subtitle1>
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={8} sx={{ mt: 5, mb: 4, maxWidth: '1200px' }}>
          <Box gridColumn="span 2">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <ErrorOutlineOutlined sx={{ color: orange[500], fontSize: 25 }} />
              <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
                {t(`${labelAmend}.status_conditions_info_table.warning`)}
              </NotifyTypo.Body1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.status_conditions_info_table.variable_name`)}
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.warning_variable_name)}</NotifyTypo.Body1>
              </Box>
              <StyledBoxValue>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.status_conditions_info_table.value`)}
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.warning_value)}</NotifyTypo.Body1>
              </StyledBoxValue>
            </Box>
          </Box>

          <Box gridColumn="span 2">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <BuildCircleOutlined sx={{ color: blue[500], fontSize: 25 }} />
              <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
                {t(`${labelAmend}.status_conditions_info_table.maintenance`)}
              </NotifyTypo.Body1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.status_conditions_info_table.variable_name`)}
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.maintenance_variable_name)}</NotifyTypo.Body1>
              </Box>
              <StyledBoxValue>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.status_conditions_info_table.value`)}
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.maintenance_value)}</NotifyTypo.Body1>
              </StyledBoxValue>
            </Box>
          </Box>
        </Box>

        <EditStatusConditionsIrisV3
          open={editModal}
          close={setEditModal}
          handleClose={handleClose}
          data={data}
          machineId={machineId}
        />
      </Card>
    </>
  );
};

StatusContentConditionsIrisV3.propTypes = {
  data: T.shape({
    warning_variable_name: T.string,
    warning_value: T.number,
    maintenance_variable_name: T.string,
    maintenance_value: T.number
  }),
  machineId: T.string.isRequired
};

StatusContentConditionsIrisV3.defaultProps = {
  data: null
};

export default withLoadingState(StatusContentConditionsIrisV3);
