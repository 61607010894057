import styled, { css } from 'styled-components';

import {
  COLOR_MAIN_HIGHLIGHT,
  COLOR_MAIN_HIGHLIGHT_TEXT_SECONDARY,
  COLOR_MAIN_HIGHLIGHT_TEXT
} from '../../attrs/colors';
import { NAVIGATION_BAR_HEIGHT } from '../../attrs/layout';

const Shortener = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
  overflow-x: hidden;
`;

const ButtonWrapper = styled.div`
  min-width: fit-content;
  height: ${NAVIGATION_BAR_HEIGHT}px;
  & > button {
    height: 100%;
  }
`;

const PageTitleWrapper = styled.div`
  height: ${NAVIGATION_BAR_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.8rem;
`;

const NavigationItem = styled.div`
  display: inline-flex;
  height: ${NAVIGATION_BAR_HEIGHT}px;
  cursor: pointer;
  align-items: center;
  padding: 0 1.5rem;
  border-bottom: 3px solid ${COLOR_MAIN_HIGHLIGHT};
  color: ${COLOR_MAIN_HIGHLIGHT_TEXT_SECONDARY} !important;
  text-decoration: none !important;
  ${props =>
    props.selected &&
    css`
      border-bottom: 3px solid ${COLOR_MAIN_HIGHLIGHT_TEXT};
      color: ${COLOR_MAIN_HIGHLIGHT_TEXT} !important;
    `}
`;

export { ButtonWrapper, PageTitleWrapper, NavigationItem, Shortener };
