import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';

import { LOAD_SUPPORT_CONTACTS } from './constants';

export default combineReducers({
  loadingState: loadingStateReducer(LOAD_SUPPORT_CONTACTS),
  data: typeToReducer(
    {
      [LOAD_SUPPORT_CONTACTS]: {
        SUCCESS: (state, { payload }) => payload
      }
    },
    []
  )
});
