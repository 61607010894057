import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader, Tooltip } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import EditBatchRecordsStatus from '../EditBatchRecordsModal/EditBatchRecordsStatus';
import { PlcType } from '../../../../../attrs/plcConfigValues';

const labelAmend = 'machines.machine_details';

const BatchStatus = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Tooltip
            title={t(`machines.detail.plc_not_filled`)}
            disableHoverListener={plcType.plc_type !== PlcType.UNKNOWN}
            arrow
          >
            <span>
              <Button
                variant="text"
                disabled={plcType.plc_type === PlcType.UNKNOWN}
                sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
                onClick={() => setEditModal(true)}
              >
                <NotifyIcon iconName="edit" fontSize="medium" />
                {t(`${labelAmend}.edit`)}
              </Button>
            </span>
          </Tooltip>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.batch_status`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.batch_status_subtitle`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={8} sx={{ mt: 5, mb: 4 }}>
        <Box gridColumn="span 2">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <NotifyIcon iconName="batchComplete" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500" sx={{ mb: 1 }}>
              {t(`${labelAmend}.batch_status_label.completed`)}
            </NotifyTypo.Body1>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '400px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.batch_status_label.completed_status_address`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>
                {!data.batch_source?.status.complete.address ? '---' : data.batch_source?.status.complete.address}
              </NotifyTypo.Body1>
            </Box>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.batch_status_label.completed_status_value`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>
                {!data.batch_source?.status.complete.value || !data.batch_source?.status.complete.address
                  ? '---'
                  : data.batch_source?.status.complete.value}
              </NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <NotifyIcon iconName="batchError" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500" sx={{ mb: 1 }}>
              {t(`${labelAmend}.batch_status_label.cancelled`)}
            </NotifyTypo.Body1>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '400px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.batch_status_label.cancelled_status_address`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>
                {!data.batch_source?.status.cancelled.address ? '---' : data.batch_source?.status.cancelled.address}
              </NotifyTypo.Body1>
            </Box>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.batch_status_label.cancelled_status_value`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>
                {!data.batch_source?.status.cancelled.value || !data.batch_source?.status.cancelled.address
                  ? '---'
                  : data.batch_source?.status.cancelled.value}
              </NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <NotifyIcon iconName="batchPending" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500" sx={{ mb: 1 }}>
              {t(`${labelAmend}.batch_status_label.in_operation`)}
            </NotifyTypo.Body1>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '400px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.batch_status_label.in_operation_status_address`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>
                {!data.batch_source?.status.in_operation.address
                  ? '---'
                  : data.batch_source?.status.in_operation.address}
              </NotifyTypo.Body1>
            </Box>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.batch_status_label.in_operation_status_value`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>
                {!data.batch_source?.status.in_operation.value || !data.batch_source?.status.in_operation.address
                  ? '---'
                  : data.batch_source?.status.in_operation.value}
              </NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
      </Box>

      <EditBatchRecordsStatus open={editModal} handleClose={handleClose} machineId={machineId} data={data} />
    </Card>
  );
};

BatchStatus.propTypes = {
  data: T.shape({
    batch_source: T.shape({
      status: T.shape({
        complete: T.shape({
          value: T.string,
          address: T.string
        }),
        cancelled: T.shape({
          value: T.string,
          address: T.string
        }),
        in_operation: T.shape({
          value: T.string,
          address: T.string
        })
      })
    })
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

BatchStatus.defaultProps = {
  data: null
};

export default BatchStatus;
