import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader } from '@mui/material';
import T from 'prop-types';

import { MACHINE_TYPE_NAMES, MachineTypes, NotifyIcon, NotifyTypo } from 'web-components';
import CreateEditMachineDetails from 'containers/Machines/MachineDetail/MachineDetails/CreateEditMachineDetails';
import { getValueToNullableCell } from 'helpers/stringHandler';
import withLoadingState from '../../../../helpers/withLoadingState';
import MachineTable from '../../../../components/MachineTableOrganization/MachineTable';
import AddMachine from './AddMachine';
import CloneMachine from './CloneMachineModal/CloneMachineModal';
import EmptyState from '../../../../components/EmptyState';

const labelAmend = 'machines.overview';

const MachinesContent = ({ machines, organizationId, customer }) => {
  const [addModal, setAddModal] = useState(false);
  const [cloneMachineModal, setCloneMachineModal] = useState(false);
  const [createNewMachine, setCreateNewMachine] = useState(false);
  const [hasMachinesInitial] = useState(!!machines?.data?.length);
  const [hasMachines] = useState(hasMachinesInitial);
  const { t } = useTranslation();
  const FILTERED_OUT_MACHINE_TYPES = [MACHINE_TYPE_NAMES.NETZSCH_PUMP_GENERIC, MACHINE_TYPE_NAMES.UNKNOWN];
  const machineTypes = MachineTypes.filter(({ value }) => !FILTERED_OUT_MACHINE_TYPES.includes(value));

  const columns = [
    {
      id: 'commission_number',
      label: t(`${labelAmend}.commission_id_number`),
      cellRenderer: row => getValueToNullableCell(row.id_number || row.commission_number),
      sortable: true
    },
    { id: 'name', label: t(`${labelAmend}.name`), sortable: true },
    {
      id: 'brand',
      label: t(`${labelAmend}.manufacturer`),
      cellRenderer: row => getValueToNullableCell(row?.brand || row?.other_brand || t('enum.manufacturer_type.OTHER')),
      sortable: false
    },
    {
      id: 'type',
      label: t(`${labelAmend}.type`),
      cellRenderer: row => getValueToNullableCell(row.other_type || machineTypes.find(a => a.value === row.type)?.type),
      sortable: false
    },
    {
      id: 'production_line',
      label: t(`${labelAmend}.production_line`),
      cellRenderer: row => getValueToNullableCell(row?.production_line),
      sortable: true
    },
    {
      id: 'gateway_id',
      label: t(`${labelAmend}.gateway_id`),
      cellRenderer: row => (row.gateway_id ? row.gateway_id : t('machines.overview.not_assigned')),
      sortable: false
    }
  ];

  const handleClose = () => setAddModal(false);

  const handleCloneMachine = () => {
    setAddModal(false);
    setCloneMachineModal(true);
  };

  const handleCreateMachine = () => {
    setAddModal(false);
    setCreateNewMachine(true);
  };

  const handleCloseCloneMachine = () => setCloneMachineModal(false);
  const handleCloseCreateMachine = () => setCreateNewMachine(false);

  // TODO: change here
  return (
    <Card sx={{ p: 4 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start' }}
        action={
          <Button
            variant={!hasMachines ? 'contained' : 'outlined'}
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px', width: '156px' }}
            onClick={() => setAddModal(true)}
          >
            <NotifyIcon iconName="plus" fontSize="medium" />
            {t(`${labelAmend}.add_modal.title`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mr: '10px' }}>{t('customers.detail.machines')}</Box>
            <Box
              sx={{
                height: '24px',
                borderRadius: '10px',
                padding: '0px 6px 0px 6px',
                background: 'rgba(88, 186, 170, .2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <NotifyTypo.Subtitle1 sx={{ color: '#000' }}>{t(`${labelAmend}.created_machine`)}</NotifyTypo.Subtitle1>
              <Box sx={{ ml: 1, fontSize: '14px' }}>{machines.total_length}</Box>
            </Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: 'bold',
          variant: 'Body1'
        }}
      />

      {!hasMachinesInitial && !hasMachines && <EmptyState type="machine_customer_detail" />}

      {hasMachinesInitial && hasMachines && (
        <MachineTable organizationId={organizationId} columns={columns} rows={machines} customer={customer} />
      )}

      <AddMachine
        open={addModal}
        handleClose={handleClose}
        handleCloneMachine={() => handleCloneMachine()}
        handleCreateMachine={() => handleCreateMachine()}
      />

      <CreateEditMachineDetails
        handleClose={handleCloseCreateMachine}
        open={createNewMachine}
        customerIdParam={customer.id}
      />

      <CloneMachine
        open={cloneMachineModal}
        handleClose={handleCloseCloneMachine}
        organizationById={organizationId}
        customer={customer}
      />
    </Card>
  );
};

MachinesContent.propTypes = {
  machines: T.shape({
    data: T.arrayOf(T.shape({})),
    total_length: T.number
  }),
  organizationId: T.string,
  customer: T.instanceOf(Object)
};

MachinesContent.defaultProps = {
  machines: null,
  organizationId: null,
  customer: null
};

export default withLoadingState(MachinesContent);
