import React from 'react';
import { useSelector } from 'react-redux';

import { getSelectedMachine } from 'redux/machines/selectors';
import GatewayContent from './GatewayContent';

const ConnectedGatewayContent = () => {
  const { loadingState, data } = useSelector(getSelectedMachine);

  return <GatewayContent loadingState={loadingState} gateways={data} />;
};

export default ConnectedGatewayContent;
