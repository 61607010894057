import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader, Grid } from '@mui/material';
import T from 'prop-types';

import { NotifyIcon, NotifyTypo } from 'web-components';
import {
  GATEWAY_CONNECTED,
  GATEWAY_CONNECTION_STATUS,
  GATEWAY_CONNECTION_TIME,
  GATEWAY_NOT_CONNECTED
} from 'attrs/gateways';
import { StyledSmall } from 'components/GatewayTable/elements';
import moment from 'moment';
import withLoadingState from '../../../../helpers/withLoadingState';
import GatewayTable from '../../../../components/CustomerDetails/GatewayTable';
import AddGateway from './AddGateway';
import EmptyState from '../../../../components/EmptyState';

const labelAmmend = 'gateways.overview';

const GatewayContent = ({ gateways, organizationId }) => {
  const [addModal, setAddModal] = useState(false);
  const [hasGatewayInitial] = useState(!!gateways?.data?.length);
  const [hasGateway] = useState(hasGatewayInitial);
  const { t } = useTranslation();

  const columns = [
    { id: 'gateway_id', label: t(`${labelAmmend}.gateway_id`), sortable: true },
    { id: 'linked_machines', label: t(`${labelAmmend}.linked_machines`), sortable: false },
    { id: 'system_version', label: t(`${labelAmmend}.system_version`), sortable: false },
    { id: 'operating_system', label: t(`${labelAmmend}.operating_system`), sortable: true },
    {
      id: 'last_seen',
      label: t(`${labelAmmend}.connection_status`),
      sortable: true,
      cellRenderer: row => {
        const lastSeen = moment((row || {}).last_seen || null);
        const lastSeenDiff = moment().diff(lastSeen, 'minutes');
        const status = lastSeenDiff <= GATEWAY_CONNECTION_TIME ? GATEWAY_CONNECTED : GATEWAY_NOT_CONNECTED;
        return (
          <Grid container spacing={0} style={{ display: 'block' }}>
            <Grid item xs={12}>
              {status === 'NOT_CONNECTED' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <NotifyIcon
                    iconName="gatewayDesconnected"
                    fontSize="medium"
                    htmlColor={GATEWAY_CONNECTION_STATUS[status].color}
                    style={{
                      marginRight: '.5rem'
                    }}
                  />
                  {t(GATEWAY_CONNECTION_STATUS[status].label)}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <NotifyIcon
                    iconName="gatewayConnected"
                    fontSize="medium"
                    htmlColor={GATEWAY_CONNECTION_STATUS[status].color}
                    style={{
                      marginRight: '.5rem'
                    }}
                  />
                  {t(GATEWAY_CONNECTION_STATUS[status].label)}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <StyledSmall>{`${t(`${labelAmmend}.last_seen`)}: ${lastSeen.format('LLLL')}`}</StyledSmall>
            </Grid>
          </Grid>
        );
      }
    },
    { id: 'link_gateway' }
  ];

  const handleClose = () => setAddModal(false);

  const handleAddGateway = () => {
    setAddModal(false);
  };

  return (
    <Card sx={{ p: 4 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', pb: 2 }}
        action={
          <Button
            variant={!hasGateway ? 'contained' : 'outlined'}
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px', minWidth: '156px' }}
            onClick={() => setAddModal(true)}
          >
            <NotifyIcon
              iconName={!hasGateway ? 'linkGatewayWhite' : 'linkGateway'}
              fontSize="medium"
              sx={{ color: '#FFF' }}
            />
            {t(`${labelAmmend}.link_gateway.title`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mr: '10px' }}>{t('customers.detail.gateways')}</Box>
            <Box
              sx={{
                height: '24px',
                borderRadius: '10px',
                padding: '0px 6px 0px 6px',
                background: 'rgba(88, 186, 170, .2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <NotifyTypo.Subtitle1 sx={{ color: '#000' }}>{t(`${labelAmmend}.gateways_linked`)}</NotifyTypo.Subtitle1>
              <Box sx={{ ml: 1, fontSize: '14px' }}>{gateways.total_length}</Box>
            </Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: 'bold',
          variant: 'Body1'
        }}
      />

      {!hasGatewayInitial && !hasGateway && <EmptyState type="gateway_customer_detail" />}

      {hasGatewayInitial && hasGateway && (
        <GatewayTable organizationId={organizationId} columns={columns} rows={gateways} />
      )}

      <AddGateway open={addModal} handleClose={handleClose} handleCloneMachine={() => handleAddGateway()} />
    </Card>
  );
};

GatewayContent.propTypes = {
  gateways: T.shape({
    data: T.arrayOf(T.shape({})),
    total_length: T.number
  }),
  organizationId: T.string
};

GatewayContent.defaultProps = {
  gateways: null,
  organizationId: null
};

export default withLoadingState(GatewayContent);
