import { Box, Button, Card, CardHeader, Tooltip } from '@mui/material';
import T from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotifyIcon, NotifyTypo } from 'web-components';
import EmptyStatePage from '../../../../components/EmptyState/EmptyStatePage';
import withLoadingState from '../../../../helpers/withLoadingState';
import AddSensor from './AddSensor';
import SensorMap from './SensorMap/SensorMap';
import ErrorCodeFormModal from './ErrorCodeFormModal/ErrorCodeFormModal';
import { PlcType } from '../../../../attrs/plcConfigValues';

const labelAmend = 'machines.machine_details';

const ErrorCodeTypeSelection = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();
  const [selectTypeModal, setSelectTypeModal] = useState(false);
  const [, setPreDefinedModal] = useState(false);
  const [customMode, setCustomMode] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [hasSensorMachinesInitial, setHasSensorMachinesInitial] = useState(false);

  useEffect(() => {
    if (data && data.errors?.custom && data.errors?.custom.length > 0) {
      setHasSensorMachinesInitial(true);
    }
    if (data && data.errors?.pre_def && data.errors?.pre_def.length > 0) {
      setHasSensorMachinesInitial(true);
    }
  }, [data]);

  const [hasErrorCode] = useState(hasSensorMachinesInitial);
  const handleClose = () => setSelectTypeModal(false);
  const handleCloseErrorModal = () => setErrorModal(false);

  const handlePreDefined = () => {
    setSelectTypeModal(false);
    setCustomMode(false);
    setErrorModal(true);
  };

  const handleCustomMode = () => {
    setSelectTypeModal(false);
    setCustomMode(true);
    setErrorModal(true);
  };

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Tooltip
            title={t(`machines.detail.plc_not_filled`)}
            disableHoverListener={plcType.plc_type !== PlcType.UNKNOWN}
            arrow
          >
            <span>
              <Button
                variant="outlined"
                disabled={plcType.plc_type === PlcType.UNKNOWN}
                sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px' }}
                onClick={() => setSelectTypeModal(true)}
              >
                <NotifyIcon iconName="plus" fontSize="medium" />
                {t(`${labelAmend}.error_code_action`)}
              </Button>
            </span>
          </Tooltip>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.error_code`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.error_code_info`)}</NotifyTypo.Subtitle1>

      {!hasSensorMachinesInitial && !hasErrorCode && (
        <Box sx={{ mt: 3, mb: 4, mx: 'auto', pt: 4, maxWidth: '300px' }}>
          <EmptyStatePage type="error_code" />
        </Box>
      )}

      {hasSensorMachinesInitial && (
        <Box sx={{ mt: 3, mb: 4, pt: 4 }}>
          <SensorMap
            sensors={data}
            machineId={machineId}
            customModal={setCustomMode}
            predefinedModal={setPreDefinedModal}
          />
        </Box>
      )}

      <AddSensor
        open={selectTypeModal}
        handleClose={handleClose}
        handleCustomSensor={() => handleCustomMode()}
        handlePreDefinedSensor={() => handlePreDefined()}
      />

      <ErrorCodeFormModal
        handleClose={handleCloseErrorModal}
        open={errorModal}
        machineId={machineId}
        isNewError
        isCustom={customMode}
        listedValues={data?.errors?.pre_def || []}
      />
    </Card>
  );
};

ErrorCodeTypeSelection.propTypes = {
  data: T.shape({
    data: T.arrayOf(T.shape({})),
    errors: T.shape({
      pre_def: T.arrayOf(T.shape({})),
      custom: T.arrayOf(T.shape({}))
    })
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

ErrorCodeTypeSelection.defaultProps = {
  data: null
};

export default withLoadingState(ErrorCodeTypeSelection);
