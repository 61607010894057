import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, CardContent, Box } from '@mui/material';

import { NotifyTypo, FormElements } from 'web-components';

import { StyledCard, StyledCardHeader } from '../elements';
import { batchConfiguration } from '../../../../../helpers/propTypes';
import { isNullUndefinedOrEmpty } from '../../../../../helpers/utils';

const GrindingTarget = ({ handleBlur, handleChange, handleChangeNumber, getError, getHelperText, values }) => {
  const { t } = useTranslation();

  const getValue = val => {
    if (isNullUndefinedOrEmpty(val)) {
      return '';
    }
    return val;
  };

  return (
    <StyledCard className="withMargins">
      <CardContent>
        <StyledCardHeader>
          <NotifyTypo.Heading2>{t('machines.form.batch_records.grinding_target')}</NotifyTypo.Heading2>
        </StyledCardHeader>
        <Container maxWidth="sm">
          <Box m={6} />
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.type')}
            helperText={getHelperText('configuration.plc.batch.target.type')}
            fullWidth
            id="grinding_target_type"
            label={t('machines.form.batch_records.grinding_target_type')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.target.type"
            value={values.configuration.plc.batch.target.type}
          />
          <Box m={3} />
          <StyledCardHeader>
            <NotifyTypo.Heading2>{t('machines.form.batch_records.time')}</NotifyTypo.Heading2>
          </StyledCardHeader>
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.time.value')}
            helperText={getHelperText('configuration.plc.batch.target.time.value')}
            fullWidth
            id="time_value"
            label={t('machines.form.batch_records.time_value')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChangeNumber('configuration.plc.batch.target.time.value')}
            type="number"
            min="0"
            max="100000"
            name="configuration.plc.batch.target.time.value"
            value={getValue(values.configuration.plc.batch.target.time.value)}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.time.hours')}
            helperText={getHelperText('configuration.plc.batch.target.time.hours')}
            fullWidth
            id="time_target_hours"
            label={t('machines.form.batch_records.time_target_hours')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.target.time.hours"
            value={values.configuration.plc.batch.target.time.hours}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.time.minutes')}
            helperText={getHelperText('configuration.plc.batch.target.time.minutes')}
            fullWidth
            id="time_target_minutes"
            label={t('machines.form.batch_records.time_target_minutes')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.target.time.minutes"
            value={values.configuration.plc.batch.target.time.minutes}
          />
          <Box m={3} />
          <StyledCardHeader>
            <NotifyTypo.Heading2>{t('machines.form.batch_records.specific_grinding_energy')}</NotifyTypo.Heading2>
          </StyledCardHeader>
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.specific_energy.value')}
            helperText={getHelperText('configuration.plc.batch.target.specific_energy.value')}
            fullWidth
            id="specific_grinding_energy_value"
            label={t('machines.form.batch_records.specific_grinding_energy_value')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChangeNumber('configuration.plc.batch.target.specific_energy.value')}
            type="number"
            min="0"
            max="100000"
            name="configuration.plc.batch.target.specific_energy.value"
            value={getValue(values.configuration.plc.batch.target.specific_energy.value)}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.specific_energy.target')}
            helperText={getHelperText('configuration.plc.batch.target.specific_energy.target')}
            fullWidth
            id="specific_grinding_energy_target"
            label={t('machines.form.batch_records.specific_grinding_energy_target')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.target.specific_energy.target"
            value={values.configuration.plc.batch.target.specific_energy.target}
          />
          <Box m={3} />
          <StyledCardHeader>
            <NotifyTypo.Heading2>{t('machines.form.batch_records.grinding_energy')}</NotifyTypo.Heading2>
          </StyledCardHeader>
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.grinding_energy.value')}
            helperText={getHelperText('configuration.plc.batch.target.grinding_energy.value')}
            fullWidth
            id="grinding_energy_value"
            label={t('machines.form.batch_records.grinding_energy_value')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChangeNumber('configuration.plc.batch.target.grinding_energy.value')}
            type="number"
            min="0"
            max="100000"
            name="configuration.plc.batch.target.grinding_energy.value"
            value={getValue(values.configuration.plc.batch.target.grinding_energy.value)}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.grinding_energy.target')}
            helperText={getHelperText('configuration.plc.batch.target.grinding_energy.target')}
            fullWidth
            id="grinding_energy_target"
            label={t('machines.form.batch_records.grinding_energy_target')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.target.grinding_energy.target"
            value={values.configuration.plc.batch.target.grinding_energy.target}
          />
          <Box m={3} />
          <StyledCardHeader>
            <NotifyTypo.Heading2>{t('machines.form.batch_records.cycles')}</NotifyTypo.Heading2>
          </StyledCardHeader>
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.cycles.value')}
            helperText={getHelperText('configuration.plc.batch.target.cycles.value')}
            fullWidth
            id="cycles_value"
            label={t('machines.form.batch_records.cycles_value')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChangeNumber('configuration.plc.batch.target.cycles.value')}
            type="number"
            min="0"
            max="100000"
            name="configuration.plc.batch.target.cycles.value"
            value={getValue(values.configuration.plc.batch.target.cycles.value)}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.target.cycles.target')}
            helperText={getHelperText('configuration.plc.batch.target.cycles.target')}
            fullWidth
            id="cycles_target"
            label={t('machines.form.batch_records.cycles_target')}
            margin="normal"
            onBlur={handleBlur}
            onChange={handleChange}
            name="configuration.plc.batch.target.cycles.target"
            value={values.configuration.plc.batch.target.cycles.target}
          />
        </Container>
      </CardContent>
    </StyledCard>
  );
};

GrindingTarget.propTypes = {
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  handleChangeNumber: T.func.isRequired,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  values: T.shape({
    configuration: T.shape({
      plc: T.shape({
        batch: batchConfiguration
      })
    })
  }).isRequired
};

export default GrindingTarget;
