import React from 'react';
import { bool, func } from 'prop-types';
import { NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { StyledButton, StyledContent, StyledIcon, StyledNewDialog } from './elements';

const labelAmend = 'machines.overview.add_modal';

const AddMachine = ({ open, handleClose, handleCloneMachine, handleCreateMachine }) => {
  const { t } = useTranslation();

  return (
    <StyledNewDialog
      open={open}
      handleClose={handleClose}
      title={t(`${labelAmend}.title`)}
      subTitle={t(`${labelAmend}.subtitle`)}
      displayActionBar="none"
      maxWidth="680px"
      content={
        <StyledContent>
          <StyledButton onClick={() => handleCreateMachine()}>
            <StyledIcon iconName="plus" />
            <Box>
              <NotifyTypo.Body1 color="text.primary">
                <strong>{t(`${labelAmend}.add_machine`)}</strong>
              </NotifyTypo.Body1>
              <NotifyTypo.Subtitle1 color="text.primary" fontWeight="normal">
                {t(`${labelAmend}.add_machine_description`)}
              </NotifyTypo.Subtitle1>
            </Box>
          </StyledButton>

          <StyledButton onClick={() => handleCloneMachine()}>
            <StyledIcon iconName="copy" />
            <Box>
              <NotifyTypo.Body1 color="text.primary">
                <strong>{t(`${labelAmend}.clone_machine`)}</strong>
              </NotifyTypo.Body1>
              <NotifyTypo.Subtitle1 color="text.primary" fontWeight="normal">
                {t(`${labelAmend}.clone_machine_description`)}
              </NotifyTypo.Subtitle1>
            </Box>
          </StyledButton>
        </StyledContent>
      }
    />
  );
};

AddMachine.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleCloneMachine: func.isRequired,
  handleCreateMachine: func.isRequired
};

export default AddMachine;
