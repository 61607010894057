import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotifyIcon } from 'web-components';
import { GATEWAY_CONNECTION_STATUS } from '../../../attrs/gateways';
import GatewayTableAssignable from './GatewayTableAssignable';

const GatewayTableSelectorAssignable = ({ rows, setSelectedGateway, selectedGateway }) => {
  const { t } = useTranslation();

  const columns = [
    { id: 'serial_number_radio', label: t('gateways.overview.gateway_id'), sortable: true },
    {
      id: 'actual_version',
      label: t('gateways.overview.version'),
      sortable: false,
      cellRenderer: row => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {row.actual_version ? (
            <>
              {row.actual_version !== row.desired_version && (
                <Tooltip title={t('gateways.not_current_version')} placement="bottom" arrow>
                  <span>
                    <NotifyIcon
                      iconName="checkmarkFilled"
                      style={{
                        paddingLeft: '.5rem',
                        marginTop: '1rem'
                      }}
                      fontSize="medium"
                    />
                  </span>
                </Tooltip>
              )}
              {row.actual_version}
            </>
          ) : (
            '---'
          )}
        </div>
      )
    },
    {
      id: 'type',
      label: t('gateways.overview.type'),
      sortable: false,
      cellRenderer: row => (row.type ? row.type[0].toUpperCase() + row.type.slice(1).toLowerCase() : '---')
    },
    {
      id: 'connected',
      label: t('gateways.overview.status'),
      sortable: true,
      cellRenderer: row => {
        const connected = row.connected ? 'CONNECTED' : 'NOT_CONNECTED';
        return (
          <Grid container spacing={0} style={{ display: 'block' }}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <NotifyIcon
                  iconName="antenna"
                  fontSize="small"
                  htmlColor={GATEWAY_CONNECTION_STATUS[connected].color}
                  style={{
                    marginRight: '.5rem'
                  }}
                />
                {t(GATEWAY_CONNECTION_STATUS[connected].label)}
              </div>
            </Grid>
          </Grid>
        );
      }
    }
  ];

  return (
    <GatewayTableAssignable
      columns={columns}
      rows={rows}
      selectedGateway={selectedGateway}
      setSelectedGateway={setSelectedGateway}
    />
  );
};

GatewayTableSelectorAssignable.propTypes = {
  rows: T.instanceOf(Object).isRequired,
  selectedGateway: T.instanceOf(Object).isRequired,
  setSelectedGateway: T.func.isRequired
};

export default GatewayTableSelectorAssignable;
