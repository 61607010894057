import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton, Paper, Table, TableBody, TableRow } from '@mui/material';
import { orderBy } from 'lodash';
import { FormElements, NotifyIcon, NotifyTypo } from 'web-components';

import { getMachineType } from '../../../helpers/utils';
import { StyledMobileTableCell, StyledTableContainer } from './elements';

const { Subtitle1, Body1 } = NotifyTypo;

const GatewayMachineTable = ({ rows, onDelete, onSelect, selected }) => {
  const { t } = useTranslation();
  const orderedRows = orderBy(rows, item => item.brand, 'asc');

  return orderedRows.map(row => {
    const machineType = getMachineType(row.type);

    return (
      <StyledTableContainer
        key={row.id}
        component={Paper}
        selected={selected && selected.includes(row.id)}
        style={{ margin: '1rem 0' }}
        onClick={onSelect && (() => onSelect(row.id))}
      >
        <Table aria-label="machines table">
          <TableBody>
            <TableRow>
              {onSelect && selected && (
                <StyledMobileTableCell padding="checkbox" className="paddingTop">
                  <FormElements.Checkbox name={`${row.id}_checked`} checked={selected && selected.includes(row.id)} />
                </StyledMobileTableCell>
              )}
              <StyledMobileTableCell className="paddingTop" align="left">
                <Subtitle1>{t('machines.overview.brand')}</Subtitle1>
                <Body1>{machineType.brand}</Body1>
              </StyledMobileTableCell>
              {onDelete && <StyledMobileTableCell width={20} />}
            </TableRow>
            <TableRow>
              {onSelect && <StyledMobileTableCell />}
              <StyledMobileTableCell colSpan={onDelete ? '2' : '1'}>
                <Subtitle1>{t('machines.overview.type')}</Subtitle1>
                <Body1>{machineType.type}</Body1>
              </StyledMobileTableCell>
              {onDelete && (
                <StyledMobileTableCell align="right">
                  <IconButton color="inherit" onClick={() => onDelete(row.id)}>
                    <NotifyIcon fontSize="large" iconName="delete" color="primary" />
                  </IconButton>
                </StyledMobileTableCell>
              )}
            </TableRow>
            <TableRow>
              {onSelect && <StyledMobileTableCell />}
              <StyledMobileTableCell colSpan={onDelete ? '2' : '1'}>
                <Subtitle1>{t('machines.overview.commission_number')}</Subtitle1>
                <Body1>{row.commission_number}</Body1>
              </StyledMobileTableCell>
              {onDelete && <StyledMobileTableCell width={20} />}
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  });
};

GatewayMachineTable.propTypes = {
  rows: T.arrayOf(T.shape({})).isRequired,
  onDelete: T.func,
  selected: T.arrayOf(T.string),
  onSelect: T.func
};

GatewayMachineTable.defaultProps = {
  onDelete: null,
  onSelect: null,
  selected: null
};

export default GatewayMachineTable;
