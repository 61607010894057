import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { FormElements, NotifyTypo } from 'web-components';
import T from 'prop-types';
import { RadioButtonsWrapper, RadioGroupWrapper, RadioLabelsWrapper } from '../SensorData/fields/elements';
import { COLOR_PRIMARY } from '../../../../../attrs/colors';

const SelectErrorType = ({ error, setFieldValue, namespace }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const namespacePrefix = namespace ? `${namespace}.` : '';

  if (!error.is_custom) {
    return null;
  }

  const handleRadioChange = e => {
    setFieldValue(`${namespacePrefix}custom_type`, e.target.value);
    return true;
  };

  return (
    <>
      <RadioGroupWrapper theme={theme}>
        <RadioLabelsWrapper>
          <NotifyTypo.Caption>{t('machines.form.error.select')}</NotifyTypo.Caption>
        </RadioLabelsWrapper>
        <RadioButtonsWrapper theme={theme}>
          <RadioButtonsWrapper theme={theme}>
            <label htmlFor="machines.form.error.type.bool">
              <FormElements.RadioButton
                margin="normal"
                name="custom_type_bool"
                activeColor={COLOR_PRIMARY}
                onChange={handleRadioChange}
                value="BOOL"
                checked={error.custom_type === 'BOOL'}
              />
              {t('machines.form.error.type.bool')}
            </label>
            <label htmlFor="machines.form.error.type.int">
              <FormElements.RadioButton
                margin="normal"
                name="custom_type_int"
                activeColor={COLOR_PRIMARY}
                onChange={handleRadioChange}
                value="INT"
                checked={error.custom_type === 'INT'}
              />
              {t('machines.form.error.type.int')}
            </label>
          </RadioButtonsWrapper>
        </RadioButtonsWrapper>
      </RadioGroupWrapper>
    </>
  );
};

SelectErrorType.propTypes = {
  error: T.shape({
    is_custom: T.bool,
    custom_bool_val: T.bool,
    custom_type: T.string,
    custom_int_val: T.number,
    type: T.string,
    custom_message: T.string
  }).isRequired,
  namespace: T.string,
  setFieldValue: T.func.isRequired
};

SelectErrorType.defaultProps = {
  namespace: ''
};

export default SelectErrorType;
