import React from 'react';
import T from 'prop-types';
import { Dialog } from 'web-components';
import { Button, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { StyledDialogActions, StyledDialogHeader } from '../../../../../attrs/style';
import { COLOR_PRIMARY } from '../../../../../attrs/colors';

const StaticValuesDialogDelete = ({ open, onSubmit, setOpen, sensor }) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Formik initialValues={sensor} onSubmit={() => onSubmit(sensor)}>
      {({ handleSubmit }) => (
        <Dialog open={open}>
          <>
            <StyledDialogHeader highlightColor={COLOR_PRIMARY} onClose={() => setOpen(false)}>
              {t('dialog.confirmation.title')}
            </StyledDialogHeader>
            <Dialog.Body>
              <Container style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                {t('dialog.confirmation.delete_static_sensor')}
              </Container>
            </Dialog.Body>
            <StyledDialogActions>
              <Button variant="text" color="secondary" onClick={handleClose}>
                No
              </Button>
              <Button
                color="primary"
                data-selector="machine-detail--delete-static-sensor"
                onClick={handleSubmit}
                variant="contained"
                type="button"
              >
                Yes
              </Button>
            </StyledDialogActions>
          </>
        </Dialog>
      )}
    </Formik>
  );
};

StaticValuesDialogDelete.propTypes = {
  open: T.bool.isRequired,
  onSubmit: T.func.isRequired,
  setOpen: T.func.isRequired,
  sensor: T.instanceOf(Object).isRequired
};

export { StaticValuesDialogDelete };
