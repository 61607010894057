import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { DataTable, NewDialog, NotifyIcon } from 'web-components';
import parse from 'html-react-parser';
import { getValueToNullableCell } from 'helpers/stringHandler';
import ResponsiveTable from '../../../../../components/ResponsiveTable';

export default function Materials() {
  const { t } = useTranslation();

  const [materials, setMaterials] = useState();

  const rowsPerPage = 3;
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const defaultSort = {
    colId: 'name',
    order: 'asc'
  };
  const [currentSort, setCurrentSort] = useState(defaultSort);
  const [slicedMaterials, setSlicedMaterials] = useState([]);
  const [sortedMaterials, setSortedMaterials] = useState([]);
  const [openAddEditMaterialDialog, setOpenAddEditMaterialDialog] = useState(false);
  const [openMaterialViewerDialog, setOpenMaterialViewerDialog] = useState(false);
  const [materialToEditView, setMaterialToEditView] = useState(undefined);

  const [indexMaterial, setIndexMaterial] = useState();
  const [name, setName] = useState('');
  const [setNumber, setSetNumber] = useState('');
  const [positionPartsList, setPositionPartsList] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [itemId, setItemId] = useState('');
  const [description, setDescription] = useState('');

  const [isNameTouched, setNameTouched] = useState(false);
  const [isQuantityTouched, setQuantityTouched] = useState(false);

  const [openWarningDeleteUser, setOpenWarningDeleteUser] = useState(false);

  const onChangeName = event => {
    const nameParam = event.target.value;
    setName(nameParam);
    setNameTouched(true);
  };

  const onChangeSetNumber = event => {
    const setNumberParam = event.target.value;
    setSetNumber(setNumberParam);
  };

  const onChangePositionPartsList = event => {
    const positionPartsListParam = event.target.value;
    setPositionPartsList(positionPartsListParam);
  };

  const onChangeQuantity = event => {
    const quantityParam = +event.target.value;
    setQuantity(quantityParam);
    setQuantityTouched(true);
  };

  const onChangeItemId = event => {
    const itemIdParam = event.target.value;
    setItemId(itemIdParam);
  };

  const onChangeDescription = event => {
    const descriptionParam = event.target.value;
    setDescription(descriptionParam);
  };

  const handleOpenAddMaterialDialog = () => {
    setOpenAddEditMaterialDialog(true);
  };

  const handleOpenEditMaterialDialog = (row, idx) => {
    setMaterialToEditView(row);
    setIndexMaterial(idx);
    setOpenAddEditMaterialDialog(true);
  };

  const handleCloseEditMaterialDialog = () => {
    setOpenAddEditMaterialDialog(false);
    setMaterialToEditView(undefined);
    setIndexMaterial(undefined);
  };

  const handleCancelEditMaterialDialog = () => {
    handleCloseEditMaterialDialog();
  };

  const handleOkEditMaterialDialog = () => {
    if (Number.isNaN(Number(indexMaterial))) {
      setMaterials([
        ...materials,
        {
          assembly_number: setNumber,
          description,
          id: 0,
          item_id: itemId,
          name,
          position_parts_list: positionPartsList,
          quantity
        }
      ]);
    } else {
      setMaterials(
        materials.map((m, i) => {
          if (i === indexMaterial) {
            return {
              assembly_number: setNumber,
              description,
              id: 0,
              item_id: itemId,
              name,
              position_parts_list: positionPartsList,
              quantity
            };
          }
          return m;
        })
      );
    }
    setOpenAddEditMaterialDialog(false);
  };

  const handleOpenMaterialViewerDialog = () => {
    setOpenMaterialViewerDialog(true);
  };

  const handleCloseMaterialViewerDialog = () => {
    setOpenMaterialViewerDialog(false);
  };

  const deleteMaterial = () => {
    setMaterials(materials.filter((_, i) => i !== indexMaterial));
    setOpenWarningDeleteUser(false);
    setMaterialToEditView(undefined);
    setIndexMaterial(undefined);
  };

  const handleOpenWarningDeleteUser = (material, idx) => {
    setMaterialToEditView(material);
    setIndexMaterial(idx);
    setOpenWarningDeleteUser(true);
  };

  const handleCloseWarningDeleteUser = () => {
    setOpenWarningDeleteUser(false);
    setMaterialToEditView(undefined);
    setIndexMaterial(undefined);
  };

  const handleTableSort = (order, colId) => {
    setCurrentSort({ colId, order });
    setSortedMaterials(DataTable.sortFn(slicedMaterials, colId, order));
  };

  const handleChangePage = page => {
    setCurrentPage(page);
  };

  const handleRowClick = row => {
    setMaterialToEditView(row);
    handleOpenMaterialViewerDialog();
  };

  useEffect(() => {
    setMaterials([
      {
        assembly_number: 'a1',
        description: 'desc1',
        id: 0,
        item_id: 'str0001',
        name: 'Tinta',
        position_parts_list: 'p1',
        quantity: 1
      },
      {
        assembly_number: 'a2',
        description: 'desc2',
        id: 0,
        item_id: 'str0002',
        name: 'Solvente',
        position_parts_list: 'p2',
        quantity: 2
      }
    ]);
  }, []);

  useEffect(() => {
    if (materials && materials.length > 0) {
      setSlicedMaterials(materials?.slice(rowsPerPage * currentPage - rowsPerPage, rowsPerPage * currentPage) || []);
      setTotalPages(Math.ceil((materials?.length || 1) / rowsPerPage));
    } else {
      setSlicedMaterials([]);
      setTotalPages(0);
    }
  }, [currentPage, materials]);

  useEffect(() => {
    if (!openAddEditMaterialDialog) {
      setNameTouched(false);
      setQuantityTouched(false);
    }
  }, [openAddEditMaterialDialog]);

  useEffect(() => {
    setSortedMaterials(DataTable.sortFn(slicedMaterials, currentSort.colId, currentSort.order));
  }, [slicedMaterials, currentSort.colId, currentSort.order]);

  useEffect(() => {
    if (materialToEditView) {
      setName(materialToEditView.name);
      setSetNumber(materialToEditView.assembly_number);
      setPositionPartsList(materialToEditView.position_parts_list);
      setQuantity(materialToEditView.quantity || 0);
      setItemId(materialToEditView.item_id);
      setDescription(materialToEditView.description);
    } else {
      setName('');
      setSetNumber('');
      setPositionPartsList('');
      setQuantity(0);
      setItemId('');
      setDescription('');
    }
  }, [materialToEditView]);

  const columns = [
    {
      field: 'name',
      label: t('task.materials.table.item_name'),
      sort: true,
      align: 'left',
      size: '20%'
    },
    {
      field: 'assembly_number',
      label: t('task.materials.table.set_number'),
      align: 'right',
      size: '10%'
    },
    {
      field: 'position_parts_list',
      label: t('task.materials.table.position_parts_list'),
      align: 'left',
      size: '10%'
    },
    {
      field: 'quantity',
      label: t('task.materials.table.quantity'),
      align: 'left',
      size: '10%'
    },
    {
      field: 'description',
      label: t('task.materials.table.note'),
      align: 'left',
      size: '20%',
      render: row => (
        <Typography sx={{ width: 120 }} noWrap>
          {row.description}
        </Typography>
      )
    },
    {
      field: 'item_id',
      label: t('task.materials.table.item_id'),
      align: 'left',
      size: '15%'
    },
    {
      field: 'action',
      label: '',
      align: 'center',
      size: '10%',
      hasAction: true,
      render: (row, idx) => (
        <Stack spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
          <IconButton
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={() => handleOpenEditMaterialDialog(row, idx)}
          >
            <ModeEditOutlineOutlinedIcon color="primary" fontSize="inherit" />
          </IconButton>
          <IconButton
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={() => handleOpenWarningDeleteUser(row, idx)}
          >
            <DeleteForeverOutlinedIcon color="primary" fontSize="inherit" />
          </IconButton>
        </Stack>
      )
    }
  ];

  const contentEditMaterialDialog = (
    <Stack spacing={4} justifyContent="center" sx={{ width: '66vw' }}>
      <Box>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="name"
              label={t('task.materials.dialog.item.name')}
              variant="filled"
              value={name}
              onChange={onChangeName}
              error={!name && isNameTouched}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="set_number"
              label={t('task.materials.dialog.item.set_number')}
              variant="filled"
              value={setNumber}
              onChange={onChangeSetNumber}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="position_parts_list"
              label={t('task.materials.dialog.item.position_parts_list')}
              variant="filled"
              value={positionPartsList}
              onChange={onChangePositionPartsList}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type="number"
              id="quantity"
              label={t('task.materials.dialog.item.quantity')}
              variant="filled"
              value={quantity}
              onChange={onChangeQuantity}
              error={(!quantity || quantity === 0) && isQuantityTouched}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="item_id"
              label={t('task.materials.dialog.item.item_id')}
              variant="filled"
              value={itemId}
              onChange={onChangeItemId}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              maxRows={3}
              id="description"
              label={t('task.materials.dialog.item.description')}
              variant="filled"
              value={description}
              onChange={onChangeDescription}
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );

  const contentViewMaterialDialog = (
    <Stack spacing={4} justifyContent="center" sx={{ width: '66vw' }}>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {t('task.materials.dialog.item.name')}
          </Typography>
          <Typography noWrap>{name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {t('task.materials.dialog.item.set_number')}
          </Typography>
          <Typography noWrap>{getValueToNullableCell(setNumber)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {t('task.materials.dialog.item.position_parts_list')}
          </Typography>
          <Typography noWrap>{getValueToNullableCell(positionPartsList)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {t('task.materials.dialog.item.quantity')}
          </Typography>
          <Typography noWrap>{quantity}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {t('task.materials.dialog.item.item_id')}
          </Typography>
          <Typography noWrap>{getValueToNullableCell(itemId)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {t('task.materials.dialog.item.description')}
          </Typography>
          <Typography noWrap>{getValueToNullableCell(description)}</Typography>
        </Grid>
      </Grid>
    </Stack>
  );

  const warningDeleteUserDialogContent = (
    <Stack spacing={2} justifyContent="center">
      <Typography sx={{ fontSize: { xs: 12, sm: 14 } }}>
        {parse(
          t('task.materials.delete_dialog.message_html', {
            material: materialToEditView && materialToEditView.name
          })
        )}
      </Typography>
    </Stack>
  );

  const warningDeleteUserDialogActionBar = (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
        <Button variant="outlined" onClick={handleCloseWarningDeleteUser}>
          {t('default_actions.cancel')}
        </Button>
        <Button variant="contained" color="error" onClick={deleteMaterial}>
          {t('default_actions.yes_delete')}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 'bold' }} gutterBottom>
          {t('task.materials.title')}
        </Typography>
        <Button sx={{ minHeight: 56 }} variant="outlined" startIcon={<AddIcon />} onClick={handleOpenAddMaterialDialog}>
          {t('task.materials.add_material')}
        </Button>
      </Stack>
      {sortedMaterials && sortedMaterials.length > 0 ? (
        <ResponsiveTable
          rowKey="id"
          dataSource={sortedMaterials}
          columns={columns}
          hasFooter
          itensPerPageLabel={t('general.overview.items')}
          itemLabel={t('general.overview.of')}
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleTableSort={handleTableSort}
          rowClick={handleRowClick}
        />
      ) : (
        <Stack sx={{ pt: '25px', pb: '75px' }} spacing={2} alignItems="center" justifyContent="center">
          <Box
            sx={{
              bgcolor: '#EDEDF2',
              m: 1,
              width: '7.5rem',
              height: '7.5rem',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center'
            }}
          >
            <NotifyIcon iconName="material" sx={{ color: '#C3C8D3', fontSize: '4rem', alignSelf: 'center' }} />
          </Box>
          <Typography sx={{ fontSize: { xs: 18, sm: 20 } }}>{t('task.materials.empty_warning.title')}</Typography>
          <Typography sx={{ fontSize: { xs: 12, sm: 14 }, maxWidth: 280, textAlign: 'center' }} color="text.secondary">
            {t('task.materials.empty_warning.caption')}
          </Typography>
        </Stack>
      )}
      <NewDialog
        open={openAddEditMaterialDialog}
        canSave={!!name && !!quantity}
        handleClose={handleCloseEditMaterialDialog}
        handleCancel={handleCancelEditMaterialDialog}
        handleOk={handleOkEditMaterialDialog}
        title={t('task.materials.dialog.title')}
        content={contentEditMaterialDialog}
        cancelCaption={t('default_actions.cancel')}
        okCaption={t('default_actions.save')}
        displayActionBar="flex"
        justifyContentActionBar="space-between"
        minWidth="900px"
      />
      <NewDialog
        open={openMaterialViewerDialog}
        handleClose={handleCloseMaterialViewerDialog}
        title={t('task.materials.title')}
        content={contentViewMaterialDialog}
        displayActionBar="none"
        paddingYContent="1rem"
        minWidth="857px"
      />
      <NewDialog
        open={openWarningDeleteUser}
        handleClose={handleCloseWarningDeleteUser}
        title={t('task.materials.delete_dialog.title')}
        subTitle={t('task.materials.delete_dialog.caption')}
        content={warningDeleteUserDialogContent}
        actionBar={warningDeleteUserDialogActionBar}
      />
    </Stack>
  );
}
