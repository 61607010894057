import React from 'react';
import T from 'prop-types';

import { Container, FilterInputElement } from './TableInput.styled';

const TableInput = ({ name, placeholder }) => (
  <Container>
    <FilterInputElement name={name} placeholder={placeholder} />
  </Container>
);

TableInput.propTypes = {
  name: T.string.isRequired,
  // onChange: T.func.isRequired,
  placeholder: T.string.isRequired
};

export default TableInput;
