import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionStatus, getError, statusAction } from 'redux/utils';

import * as api from '../../api/licenses';
import { CREATE_LICENSE, EDIT_LICENSE, LOAD_LICENSES, LOAD_LICENSES_RESUME, RENEW_LICENSE } from './constants';

function* handleCreateLicense(license) {
  yield put(statusAction(CREATE_LICENSE, actionStatus.START));

  try {
    const response = yield call(api.createLicense, license.values);
    yield put(statusAction(CREATE_LICENSE, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(CREATE_LICENSE, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleLoadLicenses({ organizationId }) {
  yield put(statusAction(LOAD_LICENSES, actionStatus.START));

  try {
    const { data } = yield call(api.loadLicenses, organizationId);

    yield put(statusAction(LOAD_LICENSES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_LICENSES, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadLicensesByResume({ licenseId, limit, page, order }) {
  yield put(statusAction(LOAD_LICENSES_RESUME, actionStatus.START));
  try {
    const { data } = yield api.loadLicensesByResume(licenseId, limit, page, order);

    yield put(statusAction(LOAD_LICENSES_RESUME, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(LOAD_LICENSES_RESUME, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleUpdateLicense(license) {
  yield put(statusAction(EDIT_LICENSE, actionStatus.START));

  try {
    const response = yield call(api.editLicense, license.values);
    yield put(statusAction(EDIT_LICENSE, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    yield put(
      statusAction(EDIT_LICENSE, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleRenewLicense(payload) {
  yield put(statusAction(RENEW_LICENSE, actionStatus.START));

  try {
    yield call(api.renewLicense, payload.license);
    yield put({ type: LOAD_LICENSES, organizationId: payload.organizationId });
    yield put(statusAction(RENEW_LICENSE, actionStatus.SUCCESS));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(RENEW_LICENSE, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

export function* watchCreateLicense() {
  yield takeEvery(CREATE_LICENSE, handleCreateLicense);
  yield takeLatest(LOAD_LICENSES, handleLoadLicenses);
  yield takeLatest(LOAD_LICENSES_RESUME, handleLoadLicensesByResume);
  yield takeLatest(RENEW_LICENSE, handleRenewLicense);
  yield takeLatest(EDIT_LICENSE, handleUpdateLicense);
}
