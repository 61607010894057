import { SensorTypes } from 'web-components';

const millSensors = SensorTypes.mill;
const millSensorKeys = Object.keys(millSensors);
const millSensorMap = new Map();

// I don't make the rules, I just 'workaround'
millSensorKeys.forEach(sk => {
  const keySensor = `mill_${sk}_00`;
  const sensor = millSensors[sk];
  const resumedSensor = {
    // 'uniqueKey' is needed to avoid 'react/no-array-index-key' warning
    // eslint doesn't know if an array index is unique or can cause a rederization error
    uniqueKey: keySensor,
    name: `sensors.mill.${sk}`,
    unit: sensor.unit,
    period: sensor.period,
    isSubItem: false
  };
  millSensorMap.set(keySensor, resumedSensor);
  if (sensor?.subIdent) {
    Object.keys(sensor.subIdent).forEach(ss => {
      const keySubSensor = `mill_${sk}_${ss}`;
      const resumedSubSensor = {
        // 'uniqueKey' is needed, same as resumedSensor
        uniqueKey: keySubSensor,
        name: `sensors.mill.${sk}`,
        unit: sensor.subIdent[ss].unit,
        period: sensor.period,
        isSubItem: true
      };
      millSensorMap.set(keySubSensor, resumedSubSensor);
    });
  }
});

export const getMillSensorMap = () => millSensorMap;

export const getMillSensor = code => millSensorMap.get(code);
