import React, { useEffect, useState } from 'react';
import T from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { getLicenses } from 'redux/rootSelectors';
import { getLicenseByResume } from 'redux/licenses/selectors';
import { getSelectedOrganizationId } from 'redux/organizations/selectors';
import { loadLicenses, loadLicensesByResume } from 'redux/licenses/actions';
import { StyledContentWrapper } from '../CustomerDetail.styled';
import ConnectedLicensesContent from './ConnectedLicensesContent';

const Licenses = ({ customer }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getLicenses);
  const { loadingState: loadingDataByResume, dataByResume } = useSelector(getLicenseByResume);
  const organizationId = useSelector(getSelectedOrganizationId);

  const [selectedUUID, setSelectedUUID] = useState('');

  useEffect(() => {
    dispatch(loadLicenses(organizationId));
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (selectedUUID !== '') {
      dispatch(loadLicensesByResume(selectedUUID));
    }
  }, [dispatch, selectedUUID]);

  return (
    <StyledContentWrapper elevation={3}>
      <ConnectedLicensesContent
        loadingState={loadingState}
        customer={customer}
        reloadAction={() => dispatch(loadLicenses(organizationId))}
        data={data}
        loadingDataByResume={loadingDataByResume}
        dataByResume={dataByResume}
        selectedUUID={selectedUUID}
        setSelectedUUID={setSelectedUUID}
        organizationId={organizationId}
      />
    </StyledContentWrapper>
  );
};

Licenses.propTypes = {
  customer: T.shape({ id: T.string, name: T.string }).isRequired
};

export default Licenses;
