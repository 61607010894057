import T from 'prop-types';

const statusShape = T.shape({
  address: T.string,
  value: T.string
});

const targetShape = T.shape({
  target: T.string,
  value: T.number
});

const batchConfiguration = T.shape({
  details: T.shape({
    batch_id: T.string,
    batch_size: T.string,
    cycles: T.string,
    est_energy_consumption: T.string,
    max_allowed_temperature: T.string,
    progress: T.string,
    recipe: T.string
  }),
  status: T.shape({
    cancelled: statusShape,
    complete: statusShape,
    in_operation: statusShape
  }),
  target: T.shape({
    cycles: targetShape,
    grinding_energy: targetShape,
    specific_energy: targetShape,
    time: T.shape({
      hours: T.string,
      minutes: T.string,
      value: T.number
    }),
    type: T.string
  })
});

const IrisBatchDetailsPropTypes = T.shape({
  batch_id_variable: T.string,
  batch_size_variable: T.string,
  end_time_variable: T.string,
  recipe_variable: T.string,
  start_time_variable: T.string
});

const IrisBatchStatusPropTypes = T.shape({
  status_cancelled_value: T.number,
  status_completed_before_target_value: T.number,
  status_completed_value: T.number,
  status_completed_with_target_updates_value: T.number,
  status_in_operation_value: T.number,
  status_loading_value: T.number,
  status_stopped_value: T.number,
  status_variable: T.string
});

const IrisBatchTargetPropTypes = T.shape({
  target_comment_1: T.string,
  target_comment_2: T.string,
  target_comment_3: T.string,
  target_comment_4: T.string,
  target_type_variable: T.string,
  target_value_variable: T.string
});

const IrisBatchLimitsPropTypes = T.shape({
  dry_running_flow_protection_variable: T.string,
  dry_running_pressure_protection_variable: T.string,
  high_critical_pressure_inlet_variable: T.string,
  high_critical_product_outlet_temperature_variable: T.string,
  high_warning_pressure_inlet_variable: T.string,
  high_warning_product_outlet_temperature_variable: T.string,
  sieve_cleaning_pressure_variable: T.string
});

const IrisBatchMeasurementsPropTypes = T.shape({
  current_cycles_variable: T.string,
  estimated_finish_time_variable: T.string,
  grinding_energy_variable: T.string,
  number_of_passes_variable: T.string,
  progress_variable: T.string,
  specific_energy_variable: T.string,
  total_grinding_energy_variable: T.string,
  total_grinding_time_variable: T.string
});

export {
  batchConfiguration,
  IrisBatchDetailsPropTypes,
  IrisBatchStatusPropTypes,
  IrisBatchTargetPropTypes,
  IrisBatchLimitsPropTypes,
  IrisBatchMeasurementsPropTypes
};
