import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader } from '@mui/material';
import { NotifyIcon, NotifyTypo } from 'web-components';
import T from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import withLoadingState from '../../../../helpers/withLoadingState';
import EmptyStatePage from '../../../../components/EmptyState/EmptyStatePage';
import AddSensor from './AddSensor';
import PreDefinedSensor from './PreDefinedSensor/PreDefinedSensor';
import SensorMap from './SensorMap/SensorMap';
import CustomSensor from './CustomSensor/CustomSensor';
import { PlcType } from '../../../../attrs/plcConfigValues';
import IrisSensor from './IrisSensor';
import DatabaseTableName from '../DatabaseTableName';
import ManagementUnits from './ManagementUnits';
import { IrisTableNameEnum } from '../../../../attrs/machines';

const labelAmend = 'machines.machine_details';

const SensorDataContent = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const [preDefinedModal, setPreDefinedModal] = useState(false);
  const [preDefItem, setPreDefItem] = useState();

  const [customModal, setCustomModal] = useState(false);
  const [customItem, setCustomItem] = useState();

  const [irisModal, setIrisModal] = useState(false);
  const [irisItem, setIrisItem] = useState();

  const editIrisModal = item => {
    setIrisItem(item);
    setIrisModal(true);
  };

  const editCustomModal = item => {
    setCustomItem(item);
    setCustomModal(true);
  };

  const editPreDefSensorModal = item => {
    setPreDefItem(item);
    setPreDefinedModal(true);
  };

  useEffect(() => {
    if (!customModal) {
      setCustomItem(null);
    }
    if (!preDefinedModal) {
      setPreDefItem(null);
    }
    if (!irisModal) {
      setIrisItem(null);
    }
  }, [customModal, preDefinedModal, irisModal]);

  const [hasSensorMachinesInitial, setHasSensorMachinesInitial] = useState(false);

  useEffect(() => {
    if (
      data &&
      data.sensors &&
      (data.sensors.custom?.length > 0 || data.sensors.pre_def?.length > 0 || data.sensors.iris?.length > 0)
    ) {
      setHasSensorMachinesInitial(true);
    }
  }, [data]);

  const [hasSensorMachines] = useState(hasSensorMachinesInitial);
  const handleClose = () => setEditModal(false);

  const handlePreDefinedModal = () => {
    setEditModal(false);
    setPreDefinedModal(true);
  };

  const handleCustomModal = () => {
    setEditModal(false);
    setCustomModal(true);
  };

  const handleIrisModal = () => {
    setIrisModal(true);
  };

  const handleClosePreDefinedModal = () => setPreDefinedModal(false);
  const handleCloseCustomModal = () => setCustomModal(false);
  const handleCloseIrisModal = () => setIrisModal(false);

  return (
    <>
      <DatabaseTableName
        plcType={plcType}
        machineId={machineId}
        selectedTableName={IrisTableNameEnum.METRICS_TABLE_NAME}
      />
      <ManagementUnits plcType={plcType} machineId={machineId} />
      <Card sx={{ p: 4, my: 2, minHeight: '490px' }}>
        <CardHeader
          sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
          action={
            <Tooltip
              title={t(`machines.detail.plc_not_filled`)}
              disableHoverListener={plcType.plc_type !== PlcType.UNKNOWN}
              arrow
            >
              <span>
                <Button
                  variant="outlined"
                  disabled={plcType.plc_type === PlcType.UNKNOWN}
                  sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px' }}
                  onClick={() => {
                    if (plcType.plc_type === PlcType.IRIS_V3) {
                      handleIrisModal();
                    } else {
                      setEditModal(true);
                    }
                  }}
                >
                  <NotifyIcon iconName="plus" fontSize="medium" />
                  {t(`${labelAmend}.sensor_data_action`)}
                </Button>
              </span>
            </Tooltip>
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.sensor_data`)}</Box>
            </Box>
          }
          titleTypographyProps={{
            fontWeight: '500',
            variant: 'Body1'
          }}
        />
        <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.sensor_data_info`)}</NotifyTypo.Subtitle1>
        {!hasSensorMachinesInitial && !hasSensorMachines && (
          <Box sx={{ mt: 3, mb: 4, pt: 4 }}>
            <EmptyStatePage type="sensor_data" />
          </Box>
        )}

        {hasSensorMachinesInitial && (
          <Box sx={{ mt: 3, mb: 4, pt: 4 }}>
            <SensorMap
              sensors={data}
              machineId={machineId}
              editCustomModal={editCustomModal}
              editPreDefinedModal={editPreDefSensorModal}
              editIrisModal={editIrisModal}
            />
          </Box>
        )}
        <AddSensor
          open={editModal}
          handleClose={handleClose}
          handlePreDefinedSensor={() => handlePreDefinedModal()}
          handleCustomSensor={() => handleCustomModal()}
        />
        <PreDefinedSensor
          handleClose={handleClosePreDefinedModal}
          open={preDefinedModal}
          machineId={machineId}
          sensorList={data?.sensors?.pre_def || []}
          preDefItem={preDefItem}
        />
        <CustomSensor
          handleClose={handleCloseCustomModal}
          open={customModal}
          machineId={machineId}
          customItem={customItem}
        />
        <IrisSensor handleClose={handleCloseIrisModal} open={irisModal} machineId={machineId} irisData={irisItem} />
      </Card>
    </>
  );
};

SensorDataContent.propTypes = {
  data: T.shape({
    sensors: T.shape({
      custom: T.arrayOf(T.shape({})),
      pre_def: T.arrayOf(T.shape({})),
      static: T.arrayOf(T.shape({})),
      iris: T.arrayOf(T.shape({}))
    })
  }),
  machineId: T.string,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

SensorDataContent.defaultProps = {
  data: null,
  machineId: null
};

export default withLoadingState(SensorDataContent);
