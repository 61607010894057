import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel, { tableSortLabelClasses } from '@mui/material/TableSortLabel';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TableFooter,
  Stack,
  IconButton,
  Select,
  MenuItem,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { NotifyIcon } from 'web-components';
import PropTypes from 'prop-types';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EFEFEF',
    color: '#7A7D85',
    fontSize: 14,
    fontWeight: '550',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
  [`&.${tableSortLabelClasses.root}`]: {
    backgroundColor: '#EFEFEF',
    color: '#7A7D85',
    fontSize: 14,
    fontWeight: '550'
  }
}));

export const StyledTableRow = styled(TableRow)(({ hasFooter }) => {
  if (!hasFooter) {
    return {
      '&:last-child td, &:last-child th': {
        border: 0
      }
    };
  }
  return undefined;
});

StyledTableRow.propTypes = {
  hasFooter: PropTypes.bool
};

StyledTableRow.defaultProps = {
  hasFooter: false
};

function ResponsiveTablePagination({
  itensPerPageLabel,
  itemLabel,
  currentPage,
  totalPages,
  rowsPerPage,
  handleChangePage
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleFirstPageClick = () => {
    handleChangePage(1);
  };

  const handlePrevPageClick = () => {
    handleChangePage(currentPage - 1 || 1);
  };

  const handleNextPageClick = () => {
    const nextPage = currentPage + 1;
    handleChangePage(nextPage <= totalPages ? nextPage : totalPages);
  };

  const handleLastPageClick = () => {
    handleChangePage(totalPages);
  };

  const handlePageChange = event => {
    const pageNumber = +event.target.value;
    handleChangePage(pageNumber);
  };

  const SelectRepeatTypeList = [];
  for (let page = 1; page <= totalPages; page += 1) {
    SelectRepeatTypeList.push(
      <MenuItem key={page} value={page}>
        {page}
      </MenuItem>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent={isTablet ? 'flex-start' : 'flex-end'}
        alignItems="center"
        sx={{ mr: { xs: 0, sm: 3, md: 9 }, ml: { sm: 3 } }}
      >
        <Typography sx={{ display: isMobile ? 'none' : 'block', order: { xs: -1, sm: 0 }, fontSize: 14 }}>
          {`${rowsPerPage} ${itensPerPageLabel}`}
        </Typography>

        <IconButton
          sx={{ display: isTablet ? 'none' : 'block', order: { sm: -1, md: 1 } }}
          aria-label="first-page"
          color="primary"
          disabled={currentPage === 1}
          onClick={handleFirstPageClick}
        >
          <NotifyIcon fontSize="large" iconName="doubleChevronLeft" />
        </IconButton>

        <IconButton
          sx={{ order: { xs: 2, sm: 3, md: 2 } }}
          aria-label="previous-page"
          color="primary"
          disabled={currentPage === 1}
          onClick={handlePrevPageClick}
        >
          <NotifyIcon fontSize="large" iconName="chevronLeft" />
        </IconButton>

        <Select
          variant="filled"
          size="small"
          sx={{
            order: { xs: 0, sm: 1, md: 3 },
            minWidth: 90,
            '& .MuiSelect-select': { paddingTop: '9px', paddingBottom: '9px', paddingLeft: '12px' }
          }}
          labelId="frequency_type_label"
          id="frequency_type_select"
          value={currentPage.toString()}
          onChange={handlePageChange}
        >
          {SelectRepeatTypeList}
        </Select>

        <Typography sx={{ order: { xs: 1, sm: 2, md: 4 }, fontSize: 14 }}>{`${itemLabel} ${totalPages}`}</Typography>

        <IconButton
          sx={{ order: { xs: 3, sm: 4, md: 5 } }}
          aria-label="next-page"
          color="primary"
          disabled={currentPage === totalPages}
          onClick={handleNextPageClick}
        >
          <NotifyIcon fontSize="large" iconName="chevronRight" />
        </IconButton>

        <IconButton
          sx={{ display: isTablet ? 'none' : 'block', order: { sm: -1, md: 6 } }}
          aria-label="last-page"
          color="primary"
          disabled={currentPage === totalPages}
          onClick={handleLastPageClick}
        >
          <NotifyIcon fontSize="large" iconName="doubleChevronRight" />
        </IconButton>
      </Stack>
    </Box>
  );
}

ResponsiveTablePagination.propTypes = {
  itensPerPageLabel: PropTypes.string,
  itemLabel: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired
};

ResponsiveTablePagination.defaultProps = {
  itensPerPageLabel: '',
  itemLabel: ''
};

export default function ResponsiveTable({
  rowKey,
  dataSource,
  columns,
  hasFooter = false,
  itensPerPageLabel = '',
  itemLabel = '',
  currentPage = 0,
  totalPages = 0,
  rowsPerPage = 0,
  handleChangePage = () => undefined,
  handleTableSort = () => undefined,
  rowClick,
  noDataMsg = ''
}) {
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const hasRowClick = !!rowClick;

  const handleRequestSort = newOrderBy => {
    const isAsc = order === 'asc';
    const toggledOrder = isAsc ? 'desc' : 'asc';
    setOrder(toggledOrder);
    setOrderBy(newOrderBy);
    handleTableSort(toggledOrder, newOrderBy);
  };

  const getIcon = orderIcon => {
    let tableArrow = 'unusedTableArrow';
    if (orderIcon) {
      if (orderIcon === 'asc') tableArrow = 'tableUpArrow';
      else tableArrow = 'tableDownArrow';
    }
    return tableArrow;
  };

  return (
    <TableContainer sx={{ width: '100%' }} component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map(c => (
              <StyledTableCell
                key={c.field}
                sx={{ width: c.size }}
                align={c.align}
                sortDirection={orderBy === c.field ? order : false}
              >
                {c.sort ? (
                  <StyledTableSortLabel
                    active={c.sort}
                    direction={orderBy === c.field ? order : 'asc'}
                    onClick={() => handleRequestSort(c.field)}
                    IconComponent={() => (
                      <NotifyIcon fontSize="medium" iconName={getIcon(orderBy === c.field ? order : false)} />
                    )}
                  >
                    {c.label}
                  </StyledTableSortLabel>
                ) : (
                  c.label
                )}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource.length > 0 ? (
            dataSource.map((row, idx) => (
              <StyledTableRow
                hasFooter={hasFooter}
                key={`${row[rowKey]}${idx.toString()}`}
                {...(hasRowClick && { hover: true })}
              >
                {columns.map(c => (
                  <StyledTableCell
                    {...(hasRowClick && !c.hasAction && { onClick: () => rowClick(row, idx) })}
                    sx={{ ...(hasRowClick && !c.hasAction && { cursor: 'pointer' }) }}
                    align={c.align}
                  >
                    {c.render ? c.render(row, idx) : row[c.field] || '---'}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
            <TableRow style={{ height: 53 }}>
              <TableCell sx={{ textAlign: 'center' }} colSpan={columns.length}>
                {noDataMsg}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {dataSource.length > 0 && hasFooter && currentPage && totalPages && rowsPerPage && handleChangePage ? (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length} sx={{ p: 1 }}>
                <ResponsiveTablePagination
                  itensPerPageLabel={itensPerPageLabel}
                  itemLabel={itemLabel}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
}

ResponsiveTable.propTypes = {
  rowKey: PropTypes.string.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.element).isRequired,
  columns: PropTypes.arrayOf(PropTypes.element).isRequired,
  hasFooter: PropTypes.bool.isRequired,
  itensPerPageLabel: PropTypes.string,
  itemLabel: PropTypes.string,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleTableSort: PropTypes.func,
  rowClick: PropTypes.func,
  noDataMsg: PropTypes.string
};

ResponsiveTable.defaultProps = {
  itensPerPageLabel: '',
  itemLabel: '',
  currentPage: 0,
  totalPages: 0,
  rowsPerPage: 0,
  handleChangePage: () => undefined,
  handleTableSort: () => undefined,
  rowClick: () => undefined,
  noDataMsg: ''
};
