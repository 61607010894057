import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { NotifyIcon, NotifyTypo, useResolutionCheck } from 'web-components';

import LogoNetzsch from 'attrs/logo_netzsch';
import { useTranslation } from 'react-i18next';

// On mobile resolutions:
// all routes that are on a sub navigation level need to show a back button instead logo

const generateLinkFromCrumbs = crumbs => `/${crumbs.join('/')}`;

const LogoLink = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const crumbs = pathname
    .split('/')
    .filter(item => item !== '')
    .slice(0, -1);
  const { isMobile } = useResolutionCheck();

  return (
    <>
      {isMobile && crumbs.length >= 1 ? (
        <IconButton aria-label="back" color="inherit" component={Link} to={generateLinkFromCrumbs(crumbs)}>
          <NotifyIcon iconName="backArrow" />
        </IconButton>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '200px' }}>
            <Link to="/">
              <LogoNetzsch height="25px" />
            </Link>
          </Box>
          <NotifyTypo.PageHeadingSubtitle sx={{ mb: '.5rem', letterSpacing: '6px', fontWeight: '400' }}>
            {t('auth.title_netzsch')}
          </NotifyTypo.PageHeadingSubtitle>
        </Box>
      )}
    </>
  );
};

export default LogoLink;
