import React, { useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { StyledContent, StyledHandlers, StyledNewDialog } from '../../elements';
import MachineGatewaysModalTable from './MachineGatewaysModalTable';
import { gatewayV2BindMachineAction } from '../../../../../redux/gateways_v2/actions';

const labelAmend = 'machines.machine_details.gateway_modal';

const MachineGatewayModal = ({ machineId, open, handleClose, organizationId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedGateway, setSelectedGateway] = useState({
    serial_number: ''
  });

  const handleBindGateway = () => {
    dispatch(
      gatewayV2BindMachineAction({
        gatewayId: selectedGateway.serial_number,
        machineId,
        reloadMachine: true
      })
    );
    handleClose();
  };

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={() => handleClose()}>
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        onClick={handleBindGateway}
        disabled={!(selectedGateway.serial_number !== '')}
      >
        {t(`${labelAmend}.gateway_button_action`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      open={open}
      handleClose={handleClose}
      title={t(`${labelAmend}.gateway_title`)}
      subTitle={t(`${labelAmend}.gateway_subtitle_customer`)}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="1062px"
      content={
        <StyledContent>
          <MachineGatewaysModalTable
            organizationId={organizationId}
            setSelectedGateway={setSelectedGateway}
            selectedGateway={selectedGateway}
          />
        </StyledContent>
      }
    />
  );
};

MachineGatewayModal.propTypes = {
  machineId: T.string.isRequired,
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  organizationId: T.string.isRequired
};

export default MachineGatewayModal;
