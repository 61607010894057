import styled, { css } from 'styled-components';
import { Link } from '@mui/material';
import { Dialog, NotifyIcon } from 'web-components';
import Breadcrumbs from '../../../components/elements/Breadcrumbs';

export const Wrapper = styled.div(
  ({ theme, type }) => `
  ${theme.breakpoints.down('sm')} {
    margin-top: 5.5rem;
  }

  ${theme.breakpoints.down('xs')} {
    margin-top: 8.5rem;
  }
  
  ${
    type === 'sidebar' &&
    css`
      min-width: 270px;
      margin-top: 25px;
      margin-left: 15px;
    `
  }
  
  ${
    type === 'sidebar-collapse' &&
    css`
      max-width: 50px;
      margin-top: 25px;
      margin-left: 15px;
    `
  }

  `
);

export const SidebarCollapseItem = styled.div`
  display: flex;
  justify-content: flex-start;
  color: rgb(229, 241, 240);
  align-items: center;
  padding: 20px 10px;
`;

export const SidebarCollapseItemIcon = styled(NotifyIcon)`
  margin-right: 0.5rem;
  color: #e5f1f0;
  border-radius: 50%;

  &:hover {
    background-color: #7bb8b4;
    transition: 250ms;
  }
`;

export const MachineBreadcrumb = styled(Breadcrumbs)`
  margin-bottom: 30px;
  padding-left: 24px;
  font-size: 14px;

  .MuiTypography-h4 {
    font-size: 14px;
  }
`;

export const MachineBreadcrumbLink = styled(Link)`
  font-size: 20px;
`;

export const MachinePageHeader = styled.div`
  padding-left: 24px;
  margin-bottom: 35px;
`;

export const MachinePageHeaderWithAddButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageHeaderGroupTitles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabsWrapper = styled.div`
  background-color: #367d78;
  border-radius: 0 15px 0 0;

  .MuiTab-wrapper {
    align-items: self-start;
    justify-content: flex-start;
  }
`;

export const TabsWrapperCollapse = styled.div`
  background-color: #367d78;
  border-radius: 0 15px 0 0;

  .MuiTab-wrapper {
    align-items: self-start;
    justify-content: flex-start;
    width: 50px !important;
  }
`;

export const ContainerMachine = styled.div`
  display: flex;
`;

export const CustomerBreadcrumb = styled(Breadcrumbs)`
  margin-bottom: 30px;
  padding: 10px;
  font-size: 14px;

  .MuiTypography-h4 {
    font-size: 14px;
  }
`;

export const DialogBodyContainer = styled(Dialog.Body)`
  display: flex;
`;
