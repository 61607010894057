import styled from 'styled-components';
import { Paper } from '@mui/material';
import { FormElements } from 'web-components';
import { DISABLED_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../../attrs/colors';

const StyledTabNavigationWrapper = styled(Paper)`
  position: relative;
  z-index: 1;
`;

const StyledTabNavigation = styled.div`
  margin: 1rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledSwitch = {
  Wrapper: styled.div`
    margin-top: 1.75rem;
  `,

  Row: styled.div`
    display: flex;
    margin-top: 2.5rem;

    & > :first-child {
      margin-right: 5rem;
    }

    ${({ isMobile }) =>
      isMobile &&
      `
      margin-top: 1.25rem;
    `}
  `,

  StatusTitle: styled.div`
    display: flex;
    flex-direction: column;
  `,

  ToggleWrapper: styled.div`
    margin-top: -0.5rem;
  `,

  Label: styled.span`
    font-size: 0.625rem;
    margin-top: -0.25rem;
    color: ${SECONDARY_TEXT_COLOR};
  `
};

const StyledCheckbox = styled(FormElements.Checkbox)`
  &.MuiFormControlLabel-root {
    margin: 0 2rem 0 -11px;

    & .MuiTypography-root {
      font-size: 0.875rem;
    }
  }
  margin-bottom: 8px;
`;

const StyledSambaSystemRadioGroup = styled.div(
  ({ theme, disabled }) => `
    margin: 1rem 0 0 0.75rem;

    & > .MuiTypography-subtitle2 {
      font-size: 0.75rem;
      margin-bottom: -0.375rem;
      color: ${disabled ? DISABLED_COLOR : SECONDARY_TEXT_COLOR};
    }

    & > .MuiTypography-caption {
      font-size: 1rem;
      color: ${disabled ? DISABLED_COLOR : SECONDARY_TEXT_COLOR};

      ${theme.breakpoints.down('sm')} {
        display: block;
        height: 2rem;
        margin-top: 0.25rem;
      }
    }

    & .MuiRadio-root {
      margin-top: -0.125rem;
      font-size: 0.625rem;
    }

    & > label {
      font-size: 0.875rem;
      margin-left: 1rem;
      color: ${disabled ? DISABLED_COLOR : PRIMARY_TEXT_COLOR};

      ${theme.breakpoints.down('sm')} {
        &:first-of-type {
          margin-left: -0.625rem;
        }        
      }
    }
  `
);

export { StyledTabNavigation, StyledTabNavigationWrapper, StyledSwitch, StyledCheckbox, StyledSambaSystemRadioGroup };
