import axios from './axios';

const loadGateways = () =>
  axios({
    method: 'get',
    url: '/v1/gateways'
  });

const loadGatewayMachineDetail = (
  organizationId,
  serialNumber,
  configured,
  organizationName,
  connection,
  limit,
  page,
  order,
  sort
) =>
  axios({
    method: 'get',
    url: `/v2/organization/${organizationId}/gateways/resumed`,
    params: {
      serial_number: serialNumber,
      organization_name: organizationName,
      configured,
      connection,
      limit,
      page,
      order,
      sort
    }
  });

const loadGatewaysByResume = (
  organizationId,
  serialNumber,
  organizationName,
  configured,
  connection,
  limit,
  page,
  order,
  sort
) =>
  axios({
    method: 'get',
    url: '/v1/gateways/resume',
    params: {
      organization_id: organizationId,
      serial_number: serialNumber,
      organization_name: organizationName,
      configured,
      connection,
      limit,
      page,
      order,
      sort
    }
  });

const loadGatewaysByResumeDetail = (organizationId, organizationName, configured, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v1/gateways/resume',
    params: {
      organization_id: organizationId,
      organization_name: organizationName,
      configured,
      limit,
      page,
      order,
      sort
    }
  });

const updateGateway = (id, values) =>
  axios({
    method: 'put',
    url: `/v1/gateways/${id}`,
    data: values
  });

const loadGateway = id =>
  axios({
    method: 'get',
    url: `/v1/gateways/${id}`
  });

const loadGatewaysVersions = type =>
  axios({
    method: 'get',
    url: `/v1/gateways/versioning?type=${type}`
  });

/**
 * Update a gateway firmware version using V1 endpoint
 * @param {{id: string, version: string, organization_id: string}} values
 * @returns {*}
 * @deprecated Please use updateGatewayFirmwareVersion instead
 */
const updateGatewayVersion = values =>
  axios({
    method: 'put',
    url: `/v1/gateways/versioning`,
    data: values
  });

/** Endpoints V2 */

const loadGatewaysList = (serialNumber, configured, organizationName, connection, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v2/gateways/list',
    params: {
      serial_number: serialNumber,
      configured,
      organization_name: organizationName,
      connection,
      limit,
      page,
      order,
      sort
    }
  });

const loadGatewaysListSearch = (serialNumber, configured, organizationName, connection, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v2/gateways/list',
    params: {
      serial_number: serialNumber,
      configured,
      organization_name: organizationName,
      connected: connection,
      limit,
      page,
      order,
      sort
    }
  });

const loadGatewaysByOrganization = (organizationId, serialNumber, connection, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: `/v2/organization/${organizationId}/gateways/list`,
    params: {
      serial_number: serialNumber || undefined,
      connection: connection || undefined,
      limit,
      page,
      order,
      sort
    }
  });

const loadGatewaysByOrganizationSearch = (organizationId, serialNumber, connection, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: `/v2/organization/${organizationId}/gateways/list`,
    params: {
      serial_number: serialNumber,
      connected: connection,
      limit,
      page,
      order,
      sort
    }
  });

const loadGatewaysAssignable = (serialNumber, connection, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v2/organization/gateways/assignable',
    params: {
      serial_number: serialNumber || undefined,
      connected: connection || undefined,
      limit,
      page,
      order,
      sort
    }
  });

const loadGatewaysAssignableMachine = (gatewayId, sort) =>
  axios({
    method: 'get',
    url: `/v2/gateways/${gatewayId}/machines/assignable`,
    params: {
      sort
    }
  });

const linkMachineByGateway = (gatewayId, payload) =>
  axios({
    method: 'post',
    url: `/v2/gateways/${gatewayId}/machines/bind`,
    data: payload
  });

const unLinkMachineByGateway = (gatewayId, machineId) =>
  axios({
    method: 'post',
    url: `/v2/gateways/${gatewayId}/machines/unbind`,
    data: {
      gateway_id: gatewayId,
      machine_id: machineId
    }
  });

const updateGatewayBind = (organizationId, payload) =>
  axios({
    method: 'put',
    url: `/v2/organization/${organizationId}/gateways/bind`,
    data: payload
  });

const unLinkGatewayByOrganizationId = (organizationId, payload) =>
  axios({
    method: 'put',
    url: `/v2/organization/${organizationId}/gateways/unbind`,
    data: payload
  });

const createLinkBetweenGatewayMachine = (gatewayId, payload) =>
  axios({
    method: 'post',
    url: `/v2/gateways/${gatewayId}/machines/bind`,
    data: payload
  });

const loadGatewaysLostSignal = (interval, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v2/analytics/gateways/no-signal',
    params: {
      interval,
      limit,
      page,
      order,
      sort
    }
  });

const loadMachineWithoutGateway = (limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v2/analytics/machines/unconfigured',
    params: {
      limit,
      page,
      order,
      sort
    }
  });

const loadGatewaysWithIssues = (limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v2/analytics/gateways/plc-issues',
    params: {
      limit,
      page,
      order,
      sort
    }
  });

/**
 * Update a gateway firmware version using V2 endpoint
 * @param {{gateway_id: string, version: string}} values
 * @returns {*}
 */
const updateGatewayFirmwareVersion = values =>
  axios({
    method: 'put',
    url: `/v2/gateways/${values.gateway_id}/versioning`,
    data: values
  });

export {
  createLinkBetweenGatewayMachine,
  linkMachineByGateway,
  loadGateway,
  loadGatewayMachineDetail,
  loadGateways,
  loadGatewaysAssignable,
  loadGatewaysAssignableMachine,
  loadGatewaysByOrganization,
  loadGatewaysByOrganizationSearch,
  loadGatewaysByResume,
  loadGatewaysByResumeDetail,
  loadGatewaysList,
  loadGatewaysListSearch,
  loadGatewaysLostSignal,
  loadGatewaysVersions,
  loadMachineWithoutGateway,
  loadGatewaysWithIssues,
  unLinkGatewayByOrganizationId,
  unLinkMachineByGateway,
  updateGateway,
  updateGatewayBind,
  updateGatewayVersion,
  updateGatewayFirmwareVersion
};
