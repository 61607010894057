import React from 'react';
import T, { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotifyTypo } from 'web-components';
import { Box } from '@mui/material';
import { StyledContent, StyledContentGrid, StyledGridItem, StyledGridItemSmall, StyledNewDialog } from './elements';

const labelAmend = 'licenses.transactions_history_box.modal';

const LicensesDetailsModal = ({ open, handleClose, data }) => {
  const { t } = useTranslation();

  return (
    <StyledNewDialog
      open={open}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      title={t(`${labelAmend}.title`)}
      displayActionBar="none"
      maxWidth="857px"
      content={
        <StyledContent>
          <StyledContentGrid>
            <StyledGridItem>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.transaction_id`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1 fontWeight="400">{data?.transaction_id?.props?.children || ''}</NotifyTypo.Body1>
            </StyledGridItem>
            <StyledGridItemSmall>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.operation_date`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1 fontWeight="400">{data?.created_at?.props?.children || ''}</NotifyTypo.Body1>
            </StyledGridItemSmall>
            <StyledGridItemSmall>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.type`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1 fontWeight="400">{data?.type?.props?.children || ''}</NotifyTypo.Body1>
            </StyledGridItemSmall>
            <StyledGridItem>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.activation_date`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1 fontWeight="400">{data?.activation_date?.props?.children || ''}</NotifyTypo.Body1>
            </StyledGridItem>
            <StyledGridItem>
              <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.expiration_date`)}</NotifyTypo.InfoText>
              <NotifyTypo.Body1 fontWeight="400">{data?.expiration_date?.props?.children || ''}</NotifyTypo.Body1>
            </StyledGridItem>
          </StyledContentGrid>
          <Box>
            <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.note`)}</NotifyTypo.InfoText>
            <NotifyTypo.Body1 fontWeight="400" sx={{ maxHeight: '290px', overflowX: 'clip' }}>
              {data?.note?.props?.children || ''}
            </NotifyTypo.Body1>
          </Box>
        </StyledContent>
      }
    />
  );
};

LicensesDetailsModal.propTypes = {
  data: T.shape({
    transaction_id: T.instanceOf(Object),
    created_at: T.instanceOf(Object),
    activation_date: T.instanceOf(Object),
    expiration_date: T.instanceOf(Object),
    type: T.instanceOf(Object),
    note: T.instanceOf(Object)
  }),
  open: bool.isRequired,
  handleClose: func.isRequired
};

LicensesDetailsModal.defaultProps = {
  data: null
};

export default LicensesDetailsModal;
