import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { NewPageTitle, NewSingleLineTabs } from 'web-components';
import { useTranslation } from 'react-i18next';
import { Gateway } from './Gateway';
import { ProductUsage } from './ProductUsage';

export function DashboardContainer() {
  const { t } = useTranslation();
  const { tab } = useParams();
  const [selectedTab, setSelectedTab] = useState();

  useEffect(() => {
    if (tab === 'gateway') {
      setSelectedTab({ index: 0, time: new Date().getTime() });
    } else if (tab === 'product_usage') {
      setSelectedTab({ index: 1, time: new Date().getTime() });
    }
  }, [tab]);

  const tabContent = [
    {
      title: t('dashboard.tabs.gateway.title'),
      content: <Gateway />
    },

    {
      title: t('dashboard.tabs.product_usage.title'),
      content: <ProductUsage />
    }
  ];

  return (
    <Container maxWidth={false}>
      <Box sx={{ mx: '24px', pt: '48px' }}>
        <NewPageTitle id="title_dashboard" title={t('dashboard.title')} tooltip={t('dashboard.tooltip')} />
        <NewSingleLineTabs tabContent={tabContent} selectedTab={selectedTab} sx={{ pt: 3 }} />
      </Box>
    </Container>
  );
}
