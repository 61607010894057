import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import T from 'prop-types';
import StatusContent from './StatusContent';
import { loadStatusMachine } from '../../../../redux/machines/actions';
import { getStatusMachine } from '../../../../redux/machines/selectors';

import { loadStatusMachineConditionsIrisV3, loadStatusMachineIrisV3 } from '../../../../redux/machines_v2/actions';
import { getStatusMachineConditionsIrisV3, getStatusMachineIrisV3 } from '../../../../redux/machines_v2/selectors';

import { PlcType } from '../../../../attrs/plcConfigValues';
import StatusContentIrisV3 from './StatusContentIrisV3';
import StatusContentConditionsIrisV3 from './StatusContentConditionsIrisV3';

const ConnectedStatusContent = ({ plcType }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getStatusMachine);
  const { data: dataStatus, loadingState: loadingStatusMachineIrisV3 } = useSelector(getStatusMachineIrisV3);
  const { data: dataConditions, loadingState: loadingStatusMachineConditionsIrisV3 } = useSelector(
    getStatusMachineConditionsIrisV3
  );

  const { machineId } = useParams();

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(loadStatusMachineIrisV3(machineId));
      dispatch(loadStatusMachineConditionsIrisV3(machineId));
    } else {
      dispatch(loadStatusMachine(machineId));
    }
  }, [dispatch, machineId]);

  return (
    <>
      {plcType.plc_type === PlcType.IRIS_V3 ? (
        <>
          <StatusContentIrisV3
            loadingState={loadingStatusMachineIrisV3}
            reloadAction={() => dispatch(loadStatusMachineIrisV3(machineId))}
            data={dataStatus}
            machineId={machineId}
            plcType={plcType}
          />
          <StatusContentConditionsIrisV3
            loadingState={loadingStatusMachineConditionsIrisV3}
            reloadAction={() => dispatch(loadStatusMachineConditionsIrisV3(machineId))}
            data={dataConditions}
            machineId={machineId}
            plcType={plcType}
          />
        </>
      ) : (
        <StatusContent
          loadingState={loadingState}
          reloadAction={() => dispatch(loadStatusMachine(machineId))}
          data={data}
          machineId={machineId}
          plcType={plcType}
        />
      )}
    </>
  );
};

ConnectedStatusContent.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default ConnectedStatusContent;
