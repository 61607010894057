import styled from 'styled-components';
import { Box } from '@mui/material';

const StyledContentGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  width: 100%;
`;

export { StyledContentGrid };
