import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { FormElements, NotifyTypo } from 'web-components';

import { COLOR_PRIMARY } from 'attrs/colors';

import { RadioGroupWrapper, RadioLabelsWrapper, RadioButtonsWrapper, SectionTitle } from './elements';
import { metricDataType } from '../../../../../../attrs/sensorConfig';

const TriggerWarningFields = ({ values, namespace, setFieldValue }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const namespacePrefix = namespace ? `${namespace}.` : '';

  const handleRadioChange = field => e => {
    setFieldValue(`${namespacePrefix}${field}`, e.target.value === 'true');
  };

  if (!values.is_custom) {
    return null;
  }

  return (
    <>
      <SectionTitle>{t(`machines.form.sensor.warning.title`)}</SectionTitle>
      <RadioGroupWrapper theme={theme}>
        <RadioLabelsWrapper>
          <NotifyTypo.Caption>{t('machines.form.sensor.warning.high_trigger')}</NotifyTypo.Caption>
        </RadioLabelsWrapper>
        <RadioButtonsWrapper theme={theme}>
          <label htmlFor="machines.form.sensors.high_trigger.yes">
            <FormElements.RadioButton
              margin="normal"
              name="high_trigger_true"
              activeColor={COLOR_PRIMARY}
              onChange={handleRadioChange('high_trigger')}
              value="true"
              checked={values.high_trigger}
            />
            {t('machines.form.sensor.warning.true')}
          </label>
          <label htmlFor="machines.form.sensors.high_trigger.no">
            <FormElements.RadioButton
              margin="normal"
              name="high_trigger_false"
              activeColor={COLOR_PRIMARY}
              onChange={handleRadioChange('high_trigger')}
              value="false"
              checked={!values.high_trigger}
            />
            {t('machines.form.sensor.warning.false')}
          </label>
        </RadioButtonsWrapper>
      </RadioGroupWrapper>
      <RadioGroupWrapper theme={theme}>
        <RadioLabelsWrapper>
          <NotifyTypo.Caption>{t('machines.form.sensor.warning.low_trigger')}</NotifyTypo.Caption>
        </RadioLabelsWrapper>
        <RadioButtonsWrapper theme={theme}>
          <label htmlFor="machines.form.sensors.low_trigger.yes">
            <FormElements.RadioButton
              margin="normal"
              name="low_trigger_true"
              activeColor={COLOR_PRIMARY}
              onChange={handleRadioChange('low_trigger')}
              value="true"
              checked={values.low_trigger}
            />
            {t('machines.form.sensor.warning.true')}
          </label>
          <label htmlFor="machines.form.sensors.low_trigger.no">
            <FormElements.RadioButton
              margin="normal"
              name="low_trigger_false"
              activeColor={COLOR_PRIMARY}
              onChange={handleRadioChange('low_trigger')}
              value="false"
              checked={!values.low_trigger}
            />
            {t('machines.form.sensor.warning.false')}
          </label>
        </RadioButtonsWrapper>
      </RadioGroupWrapper>
    </>
  );
};

TriggerWarningFields.propTypes = {
  values: metricDataType.isRequired,
  namespace: T.string,
  setFieldValue: T.func.isRequired
};

TriggerWarningFields.defaultProps = {
  namespace: null
};

export default TriggerWarningFields;
