import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationMachinesList } from 'redux/organizations/actions';
import T from 'prop-types';
import {
  getOrganizationMachinesListSelector,
  getSelectedOrganizationId
} from '../../../../redux/organizations/selectors';
import MachinesContent from './MachinesContent';

const ConnectedMachinesContent = ({ customer }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getOrganizationMachinesListSelector);
  const organizationId = useSelector(getSelectedOrganizationId);

  useEffect(() => {
    dispatch(getOrganizationMachinesList(organizationId));
  }, [dispatch, organizationId]);

  return (
    <MachinesContent
      loadingState={loadingState}
      reloadAction={() => dispatch(getOrganizationMachinesList(organizationId))}
      machines={data}
      organizationId={organizationId}
      customer={customer}
    />
  );
};

ConnectedMachinesContent.propTypes = {
  customer: T.instanceOf(Object).isRequired
};

export default ConnectedMachinesContent;
