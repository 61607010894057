import { pick } from 'lodash';
import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';
import { createReducer, enhanceWithResetActions, loadingStateReducer } from '../utils';

import { MACHINE_DEFAULT_LIST_ORDER, MACHINE_DEFAULT_LIST_SORT } from '../../attrs/machines';
import {
  CHANGE_MACHINES_FILTER_RESUME,
  CHANGE_MACHINES_ORDER_RESUME,
  CREATE_BATCH_RECORDS_MACHINE,
  CREATE_ERROR_CODE,
  CREATE_MACHINE,
  CREATE_NEW_MACHINE,
  CREATE_SENSOR_MACHINE,
  CREATE_STATUS_MACHINE,
  DELETE_BATCH_RECORDS_MACHINE,
  DELETE_ERROR_CODE,
  DELETE_MACHINE,
  DELETE_SENSOR_BY_ID,
  EDIT_MACHINE,
  LOAD_BATCH_RECORDS_MACHINE,
  LOAD_COMMISSION_NUMBER,
  LOAD_ERROR_CODE,
  LOAD_ERROR_CODE_BY_ID,
  LOAD_MACHINE,
  LOAD_MACHINE_PLC_TYPE,
  LOAD_MACHINE_RESUMED,
  LOAD_MACHINES,
  LOAD_MACHINES_RESUME,
  LOAD_MACHINES_RESUMED_WITH_FILTER,
  LOAD_MACHINES_WITH_GATEWAY,
  LOAD_PLC_MACHINE,
  LOAD_PLC_MACHINE_IRIS,
  LOAD_SENSOR_MACHINE,
  LOAD_STATIC_SENSOR,
  LOAD_STATUS_MACHINE,
  METRICS_MACHINE_DETAILS,
  RESET_COMMISSION_NUMBER_STATUS,
  RESET_MACHINE,
  RESET_MACHINES,
  UPDATE_BATCH_RECORDS_MACHINE,
  UPDATE_COMMISSION_NUMBER,
  UPDATE_ERROR_CODE,
  UPDATE_FLAG_REQUIRED_GATEWAY,
  UPDATE_MACHINE,
  UPDATE_PLC_MACHINE,
  UPDATE_PLC_MACHINE_IRIS,
  UPDATE_SENSOR,
  UPDATE_SENSOR_STATUS,
  UPDATE_STATIC_VALUE,
  UPDATE_STATUS_MACHINE,
  VERIFY_COMMISSION_NUMBER_ID
} from './constants';

const selectedReducer = combineReducers({
  editMachineStatus: loadingStateReducer(EDIT_MACHINE),
  createMachineStatus: loadingStateReducer(CREATE_NEW_MACHINE),
  loadingState: loadingStateReducer(LOAD_MACHINE),
  loadingStatePlcType: loadingStateReducer(LOAD_MACHINE_PLC_TYPE),
  data: typeToReducer(
    {
      [LOAD_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [CREATE_NEW_MACHINE]: {
        SUCCESS: (_data, { payload }) => payload
      },
      [EDIT_MACHINE]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    {}
  ),
  plcType: typeToReducer(
    {
      [LOAD_MACHINE_PLC_TYPE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadPlcMachine = combineReducers({
  loadingUpdateState: loadingStateReducer(UPDATE_PLC_MACHINE),
  loadingUpdateIrisState: loadingStateReducer(UPDATE_PLC_MACHINE_IRIS),
  loadingState: loadingStateReducer(LOAD_PLC_MACHINE),
  loadingIrisState: loadingStateReducer(LOAD_PLC_MACHINE_IRIS),
  data: typeToReducer(
    {
      [LOAD_PLC_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  ),
  irisData: typeToReducer(
    {
      [LOAD_PLC_MACHINE_IRIS]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadMetricsMachineDetails = combineReducers({
  // loadingState: loadingStateReducer(METRICS_MACHINE_DETAILS),
  data: typeToReducer(
    {
      [METRICS_MACHINE_DETAILS]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadStatusMachine = combineReducers({
  loadingState: loadingStateReducer(LOAD_STATUS_MACHINE),
  data: typeToReducer(
    {
      [LOAD_STATUS_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [CREATE_STATUS_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_STATUS_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadSensorMachine = combineReducers({
  loadingState: loadingStateReducer(LOAD_SENSOR_MACHINE),
  data: typeToReducer(
    {
      [LOAD_SENSOR_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_SENSOR]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_STATIC_VALUE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [DELETE_SENSOR_BY_ID]: {
        SUCCESS: (data, { sensorType }) => sensorType
      }
    },
    {}
  )
});

const loadErrorCode = combineReducers({
  loadingState: loadingStateReducer(LOAD_ERROR_CODE),
  data: typeToReducer(
    {
      [LOAD_ERROR_CODE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_ERROR_CODE_BY_ID]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_ERROR_CODE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [CREATE_ERROR_CODE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [DELETE_ERROR_CODE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadBatchRecordsMachine = combineReducers({
  loadingState: loadingStateReducer(LOAD_BATCH_RECORDS_MACHINE),
  data: typeToReducer(
    {
      [LOAD_BATCH_RECORDS_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [CREATE_BATCH_RECORDS_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_BATCH_RECORDS_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [DELETE_BATCH_RECORDS_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadStaticSensor = combineReducers({
  loadingState: loadingStateReducer(LOAD_STATIC_SENSOR),
  data: typeToReducer(
    {
      [LOAD_STATIC_SENSOR]: {
        SUCCESS: (data, { payload }) => payload
      },
      [DELETE_SENSOR_BY_ID]: {
        SUCCESS: (data, { sensorType }) => sensorType
      }
    },
    {}
  )
});

const loadMachinesByResumed = combineReducers({
  loadingState: loadingStateReducer(LOAD_MACHINE_RESUMED, LOAD_MACHINES_RESUMED_WITH_FILTER),
  data: typeToReducer(
    {
      [LOAD_MACHINE_RESUMED]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_MACHINES_RESUMED_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const updateCommissionNumberStatus = (data, { payload }) => pick(payload, ['commission_number_status']);
const updateVerifyStatus = (state, { payload }) => ({ ...state, ...payload });

const verificationReducer = combineReducers({
  loadingState: loadingStateReducer(VERIFY_COMMISSION_NUMBER_ID),
  data: typeToReducer(
    {
      [LOAD_COMMISSION_NUMBER]: { SUCCESS: updateCommissionNumberStatus },
      [UPDATE_COMMISSION_NUMBER]: { SUCCESS: updateCommissionNumberStatus },
      [VERIFY_COMMISSION_NUMBER_ID]: { START: updateVerifyStatus, SUCCESS: updateVerifyStatus },
      [RESET_COMMISSION_NUMBER_STATUS]: (state, { status }) => ({ ...state, commission_number_status: status })
    },
    {}
  )
});

export default combineReducers({
  updateFlagRequiredGateway: loadingStateReducer(UPDATE_FLAG_REQUIRED_GATEWAY),
  updateSensorStatus: loadingStateReducer(UPDATE_SENSOR_STATUS),
  loadingState: enhanceWithResetActions([RESET_MACHINES])(loadingStateReducer([LOAD_MACHINES, LOAD_MACHINES_RESUME])),
  loadingStateWithGateway: enhanceWithResetActions([RESET_MACHINES])(loadingStateReducer([LOAD_MACHINES_WITH_GATEWAY])),
  createLoadingState: enhanceWithResetActions([LOAD_MACHINES_RESUME, RESET_MACHINES])(
    loadingStateReducer([CREATE_MACHINE, CREATE_SENSOR_MACHINE, CREATE_ERROR_CODE])
  ),
  updateLoadingState: enhanceWithResetActions([
    LOAD_MACHINES,
    LOAD_PLC_MACHINE,
    LOAD_MACHINES_WITH_GATEWAY,
    LOAD_MACHINES_RESUME,
    RESET_MACHINE
  ])(loadingStateReducer(UPDATE_MACHINE)),
  data: enhanceWithResetActions([RESET_MACHINES])(
    typeToReducer(
      {
        [CREATE_MACHINE]: {
          SUCCESS: (state, { payload }) => ({ ...state, payload })
        },
        [CREATE_NEW_MACHINE]: {
          SUCCESS: (state, { payload }) => ({ ...state, payload })
        },
        [CREATE_SENSOR_MACHINE]: {
          SUCCESS: (state, { payload }) => ({ ...state, payload })
        },
        [LOAD_MACHINES_RESUME]: {
          SUCCESS: (state, { payload }) => payload
        },
        [LOAD_MACHINE]: {
          SUCCESS: (state, { payload }) => payload
        },
        [LOAD_PLC_MACHINE]: {
          SUCCESS: (state, { payload }) => payload
        },
        [LOAD_MACHINES]: {
          SUCCESS: (state, { payload }) => payload
        },
        [LOAD_MACHINES_WITH_GATEWAY]: {
          SUCCESS: (state, { payload }) => payload
        },
        [UPDATE_MACHINE]: {
          SUCCESS: (data, { payload }) => {
            if (data.map) {
              return data.map(machine => (machine.id === payload.id ? payload : machine));
            }
            return data.id === payload.id ? payload : data;
          }
        },
        [DELETE_MACHINE]: {
          SUCCESS: (data, { payload }) => payload
        }
      },
      []
    )
  ),
  selected: enhanceWithResetActions([LOAD_MACHINE])(selectedReducer),
  verification: verificationReducer,
  metricsMachine: enhanceWithResetActions([METRICS_MACHINE_DETAILS])(loadMetricsMachineDetails),
  plcMachine: enhanceWithResetActions([LOAD_PLC_MACHINE])(loadPlcMachine),
  sensorMachine: enhanceWithResetActions([LOAD_SENSOR_MACHINE])(loadSensorMachine),
  errorCode: enhanceWithResetActions([LOAD_ERROR_CODE])(loadErrorCode),
  errorCodeById: enhanceWithResetActions([LOAD_ERROR_CODE_BY_ID])(loadErrorCode),
  batchRecords: enhanceWithResetActions([LOAD_BATCH_RECORDS_MACHINE])(loadBatchRecordsMachine),
  staticSensor: enhanceWithResetActions([LOAD_STATIC_SENSOR])(loadStaticSensor),
  statusMachine: enhanceWithResetActions([LOAD_STATUS_MACHINE])(loadStatusMachine),
  machinesListResumed: enhanceWithResetActions([LOAD_MACHINE_RESUMED])(loadMachinesByResumed),
  machineSortOrder: createReducer(
    {
      [CHANGE_MACHINES_ORDER_RESUME]: (machineSortOrder, { sort, order }) => ({
        sort,
        order
      })
    },
    { sort: MACHINE_DEFAULT_LIST_SORT, order: MACHINE_DEFAULT_LIST_ORDER }
  ),
  machineFilter: createReducer(
    {
      [CHANGE_MACHINES_FILTER_RESUME]: (machineFilter, { organizationId, organizationName, commissionNumber }) => ({
        organizationId,
        organizationName,
        commissionNumber
      })
    },
    { organizationId: null, organizationName: null, commissionNumber: null }
  )
});
