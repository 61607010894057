import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Box, FormControl, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProductUsageState } from 'redux/rootSelectors';
import { loadActiveCustomers } from 'redux/productUsage/actions';
import { isSuccess } from 'helpers/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export function ActiveCustomers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadActiveCustomersStatus, activeCustomersResponse } = useSelector(getProductUsageState);
  const [interval, setInterval] = useState('30d');
  const [activeCustomers, setActiveCustomers] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [percentActiveCustomers, setPercentActiveCustomers] = useState(0);
  const [percentNotActiveCustomers, setPercentNotActiveCustomers] = useState(0);

  const colors = ['#2baca4', '#d6d6d6'];
  function getOptions() {
    let noData = false;
    if (percentActiveCustomers === 0 && percentNotActiveCustomers === 0) noData = true;
    return {
      series: [
        {
          type: 'pie',
          radius: ['57%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            fontSize: 40,
            fontWeight: 'bold',
            formatter: () => `${percentActiveCustomers}%` // To show only one label
          },
          labelLine: {
            show: false
          },
          data: [{ value: noData ? 0 : percentActiveCustomers }, { value: noData ? 100 : percentNotActiveCustomers }],
          color: colors
        }
      ]
    };
  }

  useEffect(() => {
    dispatch(loadActiveCustomers());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(loadActiveCustomersStatus.status)) {
      const active = activeCustomersResponse.active_customers_count;
      const total = activeCustomersResponse.total_customers_count;
      const activePercent = Math.trunc((100 * active) / total);
      const notActivePercent = 100 - activePercent;
      setActiveCustomers(active);
      setTotalCustomers(total);
      setPercentActiveCustomers(activePercent);
      setPercentNotActiveCustomers(notActivePercent);
    }
  }, [loadActiveCustomersStatus, activeCustomersResponse]);

  const handleIntervalChange = event => {
    const intervalParam = event.target.value;
    setInterval(intervalParam);
    dispatch(loadActiveCustomers(intervalParam));
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 1.5
      }}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack direction="column" spacing={1}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }} gutterBottom>
              {t('dashboard.tabs.product_usage.active_customers.title')}
            </Typography>
            <Typography sx={{ fontSize: 10, color: '#7A7D85' }} gutterBottom>
              {t('dashboard.tabs.product_usage.active_customers.caption')}
            </Typography>
          </Stack>
          <FormControl variant="filled" sx={{ width: '177px' }}>
            <Select
              fullWidth
              IconComponent={KeyboardArrowDownIcon}
              labelId="sensor-type-label"
              id="sensor-type-select"
              onChange={handleIntervalChange}
              value={interval}
            >
              <MenuItem key="30d" value="30d">
                {t('dashboard.tabs.product_usage.last_days', { days: 30 })}
              </MenuItem>
              <MenuItem key="60d" value="60d">
                {t('dashboard.tabs.product_usage.last_days', { days: 60 })}
              </MenuItem>
              <MenuItem key="90d" value="90d">
                {t('dashboard.tabs.product_usage.last_days', { days: 90 })}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center" sx={{ pt: '66px' }}>
              <Typography sx={{ fontSize: 40, fontWeight: 'bold' }} gutterBottom>
                {totalCustomers}
              </Typography>
              <Typography sx={{ fontSize: 16, color: '#7A7D85' }} gutterBottom>
                {t('dashboard.tabs.product_usage.active_customers.registered_customers')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center" sx={{ pt: '66px' }}>
              <Typography sx={{ fontSize: 40, fontWeight: 'bold', color: '#2baca4' }} gutterBottom>
                {activeCustomers}
              </Typography>
              <Typography sx={{ fontSize: 16, color: '#7A7D85' }} gutterBottom>
                {t('dashboard.tabs.product_usage.active_customers.active_customers')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
              <ReactEcharts option={getOptions()} style={{ width: '210px', height: '210px' }} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
