import compose from 'lodash/fp/compose';
import { createSelector } from 'reselect';
import * as features from '../../helpers/features';
import { getData, getGateways, getMachines, getOrganizations } from '../rootSelectors';

const getGatewaysWithMachinesAndOrganizationName = createSelector(
  [compose(getData, getGateways), compose(getData, getMachines), compose(getData, getOrganizations)],
  (gateways, machines, organizations) =>
    // NOTE: with mocked data on localHost we just return existing machines & some organization
    // Machines: we need to filter for machines with the relating gateway_SN
    // Organization: We just call for the name since we need to show it in the table
    gateways.map(gateway => {
      const organization = features.get('is_mocked_data')
        ? organizations[0] || null
        : organizations.find(item => item.id === gateway.organization_id) || null;

      return {
        ...gateway,
        machines: features.get('is_mocked_data')
          ? machines
          : machines.filter(machine => machine.gateway_SN === gateway.serial_number),
        organizationName: organization ? organization.name : '---'
      };
    })
);

const getGatewaysByResume = createSelector([getGateways], gateways => gateways.dataResumed);

const getGatewaysByMachine = createSelector([getGateways], machines => machines.gatewaysByMachine);

const getGatewaysAssignable = createSelector([getGateways], gateways => gateways.gatewaysAssignable);

const getGatewaysAssignableMachine = createSelector([getGateways], gateways => gateways.gatewaysAssignableMachine);

const getGatewaysList = createSelector([getGateways], gateways => gateways.gatewaysList);

const getGatewaysListByOrganization = createSelector([getGateways], gateways => gateways.gatewaysListByOrganization);

const getGatewaysSortOrder = createSelector([getGateways], gateways => gateways.gatewaySortOrder);

const getSelectedGateway = createSelector([getGateways], gateways => gateways.selected);

const getGatewayVersions = createSelector([getGateways], gateways => gateways.gatewaysVersions.data);

const getGatewayVersionsState = createSelector([getGateways], gateways => gateways.gatewaysVersions);

const getGatewayMachines = createSelector([getGateways], gateways => gateways.machines);

const getGatewayMachinesWithoutGateway = createSelector([compose(getGatewayMachines)], machines => {
  // NOTE: with mocked data on localHost we just return existing machines
  // On production we need to filter for machines without gateway SN
  if (features.get('is_mocked_data')) {
    return machines;
  }

  return machines.filter(machine => !machine.gateway_SN && machine.is_notify);
});

const getSelectedGatewayWithMachinesAndCustomer = createSelector(
  [
    compose(getData, getSelectedGateway),
    compose(getGatewayMachines),
    compose(getData, getOrganizations),
    compose(getData, getGatewayVersions)
  ],
  (gateway, machines, organizations) =>
    // NOTE: with mocked data on localHost we just return existing machines & some organization
    // On production we need to filter for machines with the relating gateway_SN
    // and resolve the right organization
    ({
      ...gateway,
      machines: features.get('is_mocked_data')
        ? machines
        : machines.filter(machine => machine.gateway_SN === gateway.serial_number),
      organization: features.get('is_mocked_data')
        ? organizations[0] || null
        : organizations.find(organization => organization.id === gateway.organization_id) || null
    })
);

const getGatewaysLostSignal = createSelector([getGateways], gateways => gateways.gatewayLostSignal);

const getMachineWithoutGateway = createSelector([getGateways], gateways => gateways.machineWithoutGateway);

const getGatewaysWithIssues = createSelector([getGateways], gateways => gateways.gatewaysWithIssues);

export {
  getGatewayMachinesWithoutGateway,
  getGatewayVersions,
  getGatewayVersionsState,
  getGatewaysAssignable,
  getGatewaysAssignableMachine,
  getGatewaysByMachine,
  getGatewaysByResume,
  getGatewaysList,
  getGatewaysListByOrganization,
  getGatewaysLostSignal,
  getGatewaysSortOrder,
  getGatewaysWithMachinesAndOrganizationName,
  getMachineWithoutGateway,
  getGatewaysWithIssues,
  getSelectedGateway,
  getSelectedGatewayWithMachinesAndCustomer
};
