import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProductUsageState } from 'redux/rootSelectors';
import { loadFreemiumMonth } from 'redux/productUsage/actions';
import { isSuccess } from 'helpers/utils';

export function FreemiumMonth() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadFreemiumMonthStatus, freemiumMonthResponse } = useSelector(getProductUsageState);
  const [freemiumData, setFreemiumData] = useState([]);
  const monthCalendar = [
    t('months.jan'),
    t('months.feb'),
    t('months.mar'),
    t('months.apr'),
    t('months.may'),
    t('months.jun'),
    t('months.jul'),
    t('months.aug'),
    t('months.sep'),
    t('months.oct'),
    t('months.nov'),
    t('months.dec')
  ];
  const [labelData, setLabelData] = useState([]);

  const colors = ['#2baca4'];
  function getOptions() {
    return {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          onZero: false
        },
        data: labelData
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: freemiumData,
          type: 'line',
          smooth: true,
          color: colors,
          name: t('dashboard.tabs.product_usage.freemium_month.customer_amount')
        }
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      }
    };
  }

  useEffect(() => {
    dispatch(loadFreemiumMonth());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(loadFreemiumMonthStatus.status)) {
      const newFreemiumData = [];
      const newLabelData = [];
      freemiumMonthResponse.forEach(value => {
        newFreemiumData.push(value.customer_count);
        const year = value.date.split('-')[0];
        newLabelData.push(`${monthCalendar[value.month - 1]}\n${year}`);
      });
      setFreemiumData(newFreemiumData);
      setLabelData(newLabelData);
    }
  }, [loadFreemiumMonthStatus, freemiumMonthResponse]);

  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 1.5
      }}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="column" spacing={1}>
          <Typography sx={{ fontSize: 16, fontWeight: 'bold' }} gutterBottom>
            {t('dashboard.tabs.product_usage.freemium_month.title')}
          </Typography>
          <Typography sx={{ fontSize: 10, color: '#7A7D85' }} gutterBottom>
            {t('dashboard.tabs.product_usage.freemium_month.caption')}
          </Typography>
        </Stack>
        <ReactEcharts option={getOptions()} style={{ width: '100%' }} />
      </Stack>
    </Box>
  );
}
