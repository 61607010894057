import React, { useEffect, useMemo } from 'react';
import T from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { loadSupportContacts } from 'redux/supportContact/actions';
import { updateOrganization } from 'redux/organizations/actions';

import { CustomerCreateSchema } from 'attrs/formValidation';

import RouteLeavingCheck from '../../elements/RouteLeavingCheck';
import CustomerForm from './CustomerForm';

const initialValues = customer => ({
  name: customer.name,
  sap_account: customer.sap_account,
  sap_status: customer.sap_status,
  phone: {
    number: customer.phone.number,
    country: customer.phone.country
  },
  email: customer['e-mail'],
  support: {
    support_id: customer?.support?.support_id,
    name: customer?.support?.name,
    country_id: customer?.support?.country_id,
    country_code: customer?.support?.country_code,
    phone: customer?.support?.phone,
    email: customer?.support?.email
  },
  support_id: customer.support_id,
  logo: customer.logo,
  enabled_config: {
    batch: customer.enabled_config.batch,
    data_exports: customer.enabled_config.data_exports
  }
});

const CustomerEdit = ({ open, handleClose, customer }) => {
  const dispatch = useDispatch();

  const handleFormSubmit = values => {
    dispatch(updateOrganization(customer.id, values));
  };

  const renderInitialValues = useMemo(() => initialValues(customer), [customer]);

  useEffect(() => {
    dispatch(loadSupportContacts());
  }, [dispatch]);

  return (
    <Formik initialValues={renderInitialValues} onSubmit={handleFormSubmit} validationSchema={CustomerCreateSchema}>
      {({ dirty }) => (
        <>
          <RouteLeavingCheck when={dirty} />
          <CustomerForm open={open} handleClose={handleClose} isEdit />
        </>
      )}
    </Formik>
  );
};

CustomerEdit.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  customer: T.shape({
    id: T.string,
    logo: T.string,
    'e-mail': T.string,
    name: T.string,
    sap_account: T.string,
    sap_status: T.string,
    phone: T.shape({ number: T.string, country: T.string }),
    support: T.shape({
      support_id: T.number,
      name: T.string,
      country_id: T.string,
      country_code: T.number,
      phone: T.string,
      email: T.string
    }),
    support_id: T.number,
    enabled_config: T.shape({
      batch: T.bool,
      data_exports: T.bool
    })
  }).isRequired
};

export default CustomerEdit;
