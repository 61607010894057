export const LOAD_SENSORS = 'SENSORS/LOAD';
export const CREATE_SENSOR = 'SENSOR/CREATE';
export const UPDATE_SENSOR = 'SENSOR/UPDATE';
export const DELETE_SENSOR = 'SENSOR/DELETE';

/**
 * IRIS Sensors
 */
export const LOAD_SENSORS_UNITS = 'SENSORS/LOAD_UNITS';
export const UPDATE_SENSORS_UNITS = 'SENSORS/UPDATE_SENSORS_UNITS';

export const LOAD_SENSORS_CONFIG_FOR_IRIS = 'SENSORS/LOAD_CONFIG_FOR_IRIS';

export const LOAD_SENSORS_IRIS = 'SENSORS/LOAD_IRIS';
export const CREATE_UPDATE_SENSOR_IRIS = 'SENSOR/CREATE_UPDATE_IRIS';
export const DELETE_SENSOR_IRIS = 'SENSOR/DELETE_IRIS';
export const FLIP_SENSOR_IRIS_STATUS = 'SENSOR/FLIP_IRIS_STATUS';
