import React from 'react';
import { Link } from 'react-router-dom';
import T from 'prop-types';
import { Avatar, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotifyTypo, useResolutionCheck } from 'web-components';

import NoUsersImg from '../../attrs/images/user_management_no_users.svg';
import NoMachinesImg from '../../attrs/images/dashboard_no_machines.svg';
import NoSensorsImg from '../../attrs/images/dashboard_no_sensors.svg';
import NoErrorCodeDashBoard from '../../attrs/images/dashboard_no_error_code.svg';
import NoStaticSensor from '../../attrs/images/dashboard_no_static_sensors.svg';
import NoLicenses from '../../attrs/images/customers_no_licenses.svg';
import NoStaticSensorData from '../../attrs/images/static_sensor_data.svg';

import { EmptyStatePageWrapper, TextEmptyStatePage } from './elements';
import { EMPTY_STATE_IMAGE_SIZE_DESKTOP, EMPTY_STATE_IMAGE_SIZE_TABLET } from '../../attrs/layout';

const { Heading1, Heading2, PageHeadingSubtitle } = NotifyTypo;

const EMPTY_STATES_IMG = {
  customers: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_customers_active',
    actionText: 'empty_state.no_customers_active_action_text'
  },
  customer: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_customer_found'
  },
  user_management_pending: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_users_pending',
    actionText: 'empty_state.no_users_pending_action_text',
    small: true
  },
  user_management_active: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_users_active',
    actionText: 'empty_state.no_users_active_action_text',
    small: true
  },
  machines: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machines',
    actionText: 'empty_state.no_machines_action_text',
    actionButtonText: 'empty_state.no_machines_button_text',
    actionLink: '/machines'
  },
  machines_created: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machines_created'
  },
  machine_details_sensor: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.no_machine_details_sensor',
    small: true,
    actionButton: true
  },
  machine_details_error_code: {
    imgSrc: NoErrorCodeDashBoard,
    mainText: 'empty_state.no_machine_details_error_code',
    small: true,
    actionButton: true
  },
  machine_details_status: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machine_details_static_sensor',
    small: true
  },
  machine_details_static_sensor: {
    imgSrc: NoStaticSensor,
    mainText: 'empty_state.no_machine_details_static_sensor',
    small: true
  },
  machine_customer_detail: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machine_customer_detail',
    actionText: 'empty_state.no_machine_customer_detail_subtitle',
    small: true
  },
  // TODO: Wave said she wants to provide a gatway image later
  gateways: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gateways'
  },
  gateways_not_configured: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gateways_not_configured'
  },
  gateways_configured: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gateways_configured'
  },
  gateway: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gateway_found'
  },
  gateway_machines: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gateway_machines_assigned'
  },
  licenses: {
    imgSrc: NoLicenses,
    mainText: 'empty_state.no_licenses_added',
    actionText: 'empty_state.no_licenses_added_subtitle',
    small: true
  },
  sensor_data: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.no_sensor_data_title',
    actionText: 'empty_state.no_sensor_data_subtitle',
    small: true
  },
  static_sensor: {
    imgSrc: NoStaticSensorData,
    mainText: 'empty_state.no_sensor_static_data_title',
    actionText: 'empty_state.no_sensor_static_data_subtitle',
    small: true
  },
  error_code: {
    imgSrc: NoErrorCodeDashBoard,
    mainText: 'empty_state.no_error_code_title',
    actionText: 'empty_state.no_error_code_subtitle',
    small: true
  }
};

const EmptyStatePage = ({ type, action, actionButton, top }) => {
  const { t } = useTranslation();
  const { isTablet } = useResolutionCheck();
  const typeState = EMPTY_STATES_IMG[type];

  let imgSize = isTablet ? EMPTY_STATE_IMAGE_SIZE_TABLET : EMPTY_STATE_IMAGE_SIZE_DESKTOP;
  let HeadingComponent = Heading1;

  if (typeState.small) {
    imgSize /= 2;
    HeadingComponent = Heading2;
  }

  return (
    <EmptyStatePageWrapper top={top} data-selector="empty-state">
      <Avatar style={{ height: imgSize, width: imgSize }} src={typeState.imgSrc} />
      <TextEmptyStatePage>
        {React.createElement(
          HeadingComponent,
          {
            gutterBottom: true,
            align: 'center',
            style: {
              marginTop: '10px'
            }
          },
          [t(typeState.mainText)]
        )}
        {!action && typeState.actionText && (
          <PageHeadingSubtitle align="center" sx={{ color: '#7A7D85', fontSize: '14px' }}>
            {t(typeState.actionText)}
          </PageHeadingSubtitle>
        )}
        {typeState.actionLink && (
          <Button
            component={Link}
            color="primary"
            variant="contained"
            to={t(typeState.actionLink)}
            style={{ marginTop: '1rem' }}
          >
            {t(typeState.actionButtonText)}
          </Button>
        )}
        {typeState.actionButton && actionButton}
      </TextEmptyStatePage>
    </EmptyStatePageWrapper>
  );
};

EmptyStatePage.propTypes = {
  type: T.string.isRequired,
  actionButton: T.node,
  action: T.shape({ permissionBase: T.string, permissionField: T.string }),
  top: T.number
};

EmptyStatePage.defaultProps = {
  actionButton: null,
  action: null,
  top: 30
};

export default EmptyStatePage;
