import React from 'react';
import { Card, Divider } from '@mui/material';
import T from 'prop-types';
import {
  IrisBatchDetailsPropTypes,
  IrisBatchLimitsPropTypes,
  IrisBatchStatusPropTypes,
  IrisBatchTargetPropTypes
} from '../../../../../helpers/propTypes';
import IrisBatchDetails from './IrisBatchBlocks/IrisBatchDetails';
import IrisBatchStatus from './IrisBatchBlocks/IrisBatchStatus';
import IrisBatchTarget from './IrisBatchBlocks/IrisBatchTarget';
import IrisBatchLimits from './IrisBatchBlocks/IrisBatchLimits';

const IrisBatch = ({ details, status, target, limits, machineId }) => (
  <Card sx={{ p: 4, my: 2 }}>
    <IrisBatchDetails details={details} status={status} target={target} limits={limits} machineId={machineId} />
    <Divider orientation="horizontal" flexItem sx={{ m: 0.5 }} />
    <IrisBatchStatus details={details} status={status} target={target} limits={limits} machineId={machineId} />
    <Divider orientation="horizontal" flexItem sx={{ m: 0.5 }} />
    <IrisBatchTarget details={details} status={status} target={target} limits={limits} machineId={machineId} />
    <Divider orientation="horizontal" flexItem sx={{ m: 0.5 }} />
    <IrisBatchLimits details={details} status={status} target={target} limits={limits} machineId={machineId} />
  </Card>
);

IrisBatch.propTypes = {
  details: IrisBatchDetailsPropTypes,
  status: IrisBatchStatusPropTypes,
  target: IrisBatchTargetPropTypes,
  limits: IrisBatchLimitsPropTypes,
  machineId: T.string.isRequired
};

IrisBatch.defaultProps = {
  details: null,
  status: null,
  target: null,
  limits: null
};

export default IrisBatch;
