import { NotifyIcon, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import { Box, Grid, createTheme } from '@mui/material';
import T from 'prop-types';
import React from 'react';

export const MachineCommissionNumber = ({ commissionNumber, status }) => {
  const theme = createTheme();
  const { isMobile } = theme.breakpoints.down('sm');
  const { t } = useTranslation();

  if (!commissionNumber) {
    return <NotifyTypo.Placeholder>{t('customers.not_assigned')}</NotifyTypo.Placeholder>;
  }

  const StatusComponent = {
    [true]: () => <NotifyIcon iconName="successFilled" style={{ marginTop: '3px' }} data-testid="success-icon" />,
    [false]: () => <NotifyIcon iconName="checkmarkFilled" style={{ marginTop: '8px' }} data-testid="warning-icon" />
  }[status];

  return (
    <Grid container alignItems="center">
      <NotifyTypo.Body1>{commissionNumber}</NotifyTypo.Body1>
      {commissionNumber && (
        <>
          <Box m={isMobile ? 0.25 : 0.5} />
          <StatusComponent data-testid="commission_number" />
        </>
      )}
    </Grid>
  );
};

MachineCommissionNumber.propTypes = {
  commissionNumber: T.string.isRequired,
  status: T.bool
};

MachineCommissionNumber.defaultProps = {
  status: false
};
