import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGatewaysListByOrganization } from 'redux/gateways/selectors';
import GatewayContent from './GatewayContent';
import { loadGatewaysByOrganization } from '../../../../redux/gateways/actions';
import { getSelectedOrganizationId } from '../../../../redux/organizations/selectors';

const ConnectedGatewayContent = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getGatewaysListByOrganization);
  const organizationId = useSelector(getSelectedOrganizationId);

  useEffect(() => {
    dispatch(loadGatewaysByOrganization(organizationId));
  }, [dispatch, organizationId]);

  return (
    <GatewayContent
      loadingState={loadingState}
      reloadAction={() => dispatch(loadGatewaysByOrganization(organizationId))}
      gateways={data}
      organizationId={organizationId}
    />
  );
};

export default ConnectedGatewayContent;
