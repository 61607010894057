import React from 'react';
import T from 'prop-types';
import MachineDetailContent from './MachineDetailContent';

const ConnectedMachineDetailContent = ({ machine, plcType }) => (
  <MachineDetailContent machine={machine} plcType={plcType} />
);

ConnectedMachineDetailContent.propTypes = {
  machine: T.shape({
    data: T.arrayOf(T.shape({}))
  }),
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

ConnectedMachineDetailContent.defaultProps = {
  machine: null
};

export default ConnectedMachineDetailContent;
