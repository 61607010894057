const CREATE_ORGANIZATION = 'ORGANIZATIONS/CREATE';
const LOAD_ORGANIZATIONS = 'ORGANIZATIONS/LOAD';
const LOAD_ORGANIZATION = 'ORGANIZATIONS/LOAD_ORGANIZATION';
const UPDATE_ORGANIZATION = 'ORGANIZATIONS/UPDATE';
const VERIFY_SAP_ID = 'ORGANIZATIONS/VERIFY';
const RESET_SAP_STATUS = 'ORGANIZATIONS/RESET_SAP_STATUS';
const RESET_ORGANIZATIONS = 'ORGANIZATIONS/RESET';
const LOAD_ORGANIZATIONS_RESUME = 'ORGANIZATIONS_RESUME/LOAD';
const CHANGE_ORGANIZATIONS_ORDER_RESUME = 'ORGANIZATIONS_RESUME/CHANGE_ORDER';
const CHANGE_ORGANIZATIONS_FILTER_RESUME = 'ORGANIZATIONS_RESUME/FILTER';
const GET_ORGANIZATION_PRODUCTS_ACCESS = 'ORGANIZATIONS/GET_PRODUCTS_ACCESS';

/** Endpoints V2 */

const ORGANIZATION_MACHINES_LIST = 'ORGANIZATION/LOAD_MACHINES_LIST';
const LOAD_MACHINES_RESUME_LIST_WITH_FILTER = 'ORGANIZATION/FILTER_MACHINE_LIST';

export {
  CHANGE_ORGANIZATIONS_FILTER_RESUME,
  CHANGE_ORGANIZATIONS_ORDER_RESUME,
  CREATE_ORGANIZATION,
  GET_ORGANIZATION_PRODUCTS_ACCESS,
  LOAD_MACHINES_RESUME_LIST_WITH_FILTER,
  LOAD_ORGANIZATION,
  LOAD_ORGANIZATIONS,
  LOAD_ORGANIZATIONS_RESUME,
  ORGANIZATION_MACHINES_LIST,
  RESET_ORGANIZATIONS,
  RESET_SAP_STATUS,
  UPDATE_ORGANIZATION,
  VERIFY_SAP_ID
};
