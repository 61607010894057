const NOTI_PRODUCT = 'NOTIFY';
const PREV_PRODUCT = 'PREVENTIVE_MAINTENANCE';
const MAINT_PRODUCT = 'MAINT_PREVENTIVE';

const NOTI_LICENSES = {
  BASIC: 'BASIC',
  STANDARD: 'STANDARD',
  ADVANCED: 'ADVANCED',
  TRIAL_BASIC: 'TRIAL_BASIC',
  TRIAL_STANDARD: 'TRIAL_STANDARD',
  TRIAL_ADVANCED: 'TRIAL_ADVANCED',
  STANDARD_200: 'STANDARD_200',
  STANDARD_500: 'STANDARD_500',
  STANDARD_1000: 'STANDARD_1000',
  COMMISSIONING: 'COMMISSIONING'
};

const NEW_NOTI_LICENSES = {
  COMMISSIONING: 'COMMISSIONING',
  TRIAL: 'TRIAL',
  FREEMIUM: 'FREEMIUM',
  STANDARD: 'STANDARD',
  ADVANCED: 'ADVANCED'
};

const LICENSES_LABEL = {
  [NOTI_LICENSES.BASIC]: 'licenses.basic',
  [NOTI_LICENSES.STANDARD]: 'licenses.standard',
  [NOTI_LICENSES.ADVANCED]: 'licenses.advanced',
  [NOTI_LICENSES.TRIAL_BASIC]: 'licenses.trial_basic',
  [NOTI_LICENSES.TRIAL_STANDARD]: 'licenses.trial_standard',
  [NOTI_LICENSES.TRIAL_ADVANCED]: 'licenses.trial_advantage',
  [NOTI_LICENSES.STANDARD_200]: 'licenses.standard_200',
  [NOTI_LICENSES.STANDARD_500]: 'licenses.standard_500',
  [NOTI_LICENSES.STANDARD_1000]: 'licenses.standard_1000',
  [NOTI_LICENSES.COMMISSIONING]: 'licenses.commissioning'
};

const PRODUCT_LABEL = {
  [NOTI_PRODUCT]: 'product.notify',
  [PREV_PRODUCT]: 'product.preventive_maintenance',
  [MAINT_PRODUCT]: 'Maintenance Tool'
};

const LICENSES_NAMES_MAP = {
  [NOTI_PRODUCT]: 'Notify',
  [PREV_PRODUCT]: 'Maintenance Tool',
  [MAINT_PRODUCT]: 'Maintenance Tool'
};

const NOTI_LICENSES_OPTIONS = [
  {
    value: NOTI_LICENSES.TRIAL_ADVANCED,
    label: 'licenses.trial_advantage'
  },
  {
    value: NOTI_LICENSES.BASIC,
    label: 'licenses.basic'
  },
  {
    value: NOTI_LICENSES.STANDARD,
    label: 'licenses.standard'
  },
  {
    value: NOTI_LICENSES.ADVANCED,
    label: 'licenses.advanced'
  },
  {
    value: NOTI_LICENSES.COMMISSIONING,
    label: 'licenses.commissioning'
  }
];

const PREV_LICENSES_OPTIONS = [
  {
    value: NOTI_LICENSES.TRIAL_STANDARD,
    label: 'licenses.trial_standard'
  },
  {
    value: NOTI_LICENSES.STANDARD_200,
    label: 'licenses.standard_200'
  },
  {
    value: NOTI_LICENSES.STANDARD_500,
    label: 'licenses.standard_500'
  },
  {
    value: NOTI_LICENSES.STANDARD_1000,
    label: 'licenses.standard_1000'
  }
];

const NEW_NOTI_LICENSES_OPTIONS = [
  {
    value: NEW_NOTI_LICENSES.COMMISSIONING,
    label: 'licenses.commissioning'
  },
  {
    value: NEW_NOTI_LICENSES.TRIAL,
    label: 'licenses.trial_advantage'
  },
  {
    value: NEW_NOTI_LICENSES.FREEMIUM,
    label: 'licenses.basic'
  },
  {
    value: NEW_NOTI_LICENSES.STANDARD,
    label: 'licenses.standard'
  },
  {
    value: NEW_NOTI_LICENSES.ADVANCED,
    label: 'licenses.advanced'
  }
];

export {
  NOTI_PRODUCT,
  PREV_PRODUCT,
  NOTI_LICENSES,
  NOTI_LICENSES_OPTIONS,
  PREV_LICENSES_OPTIONS,
  LICENSES_LABEL,
  PRODUCT_LABEL,
  LICENSES_NAMES_MAP,
  NEW_NOTI_LICENSES,
  NEW_NOTI_LICENSES_OPTIONS
};
