import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Card, Box, CircularProgress } from '@mui/material';

import Breadcrumbs from 'components/elements/Breadcrumbs';

import { PageHeaderTitle } from 'elements';

import { CustomerCreateSchema } from 'attrs/formValidation';

import { loadSupportContacts } from 'redux/supportContact/actions';
import { createOrganization } from 'redux/organizations/actions';

import {
  StyledContainer,
  CustomerPageHeader,
  CustomerContent,
  StyledHandlers,
  StyledGeneralInformationForm,
  StyleForm
} from './CustomerCreate.styled';

const initialValues = {
  name: '',
  sap_account: '',
  sap_status: '',
  phone: {
    number: null,
    country: ''
  },
  email: '',
  support: {
    support_id: '',
    name: '',
    country_id: '',
    country_code: '',
    phone: null,
    email: ''
  },
  support_id: '',
  logo: ''
};

const CustomerCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);

  const breadcrumbObj = [
    { route: '/customers', name: `${t('customers.title')}` },
    { route: `/customers/new-customer`, name: 'Add new customer' }
  ];

  useEffect(() => {
    dispatch(loadSupportContacts());
  }, [dispatch]);

  const handleFormSubmit = values => {
    setIsSaving(true);
    dispatch(
      createOrganization({
        values,
        action: () => {
          setIsSaving(false);
        }
      })
    );
  };

  return (
    <Box sx={{ py: 5 }}>
      <StyledContainer>
        <Breadcrumbs aria-label="breadcrumb" data={breadcrumbObj} />
        <CustomerPageHeader>
          <PageHeaderTitle>Add new customer</PageHeaderTitle>
        </CustomerPageHeader>
        <CustomerContent>
          <Formik
            initialValues={initialValues}
            validationSchema={CustomerCreateSchema}
            validateOnMount
            onSubmit={handleFormSubmit}
          >
            {({ isValid }) => (
              <StyleForm>
                <Card sx={{ width: '100%', minHeight: '580px', display: 'flex', flexDirection: 'column' }}>
                  <StyledGeneralInformationForm submitDisabled={false} create />
                  <StyledHandlers>
                    <Button color="secondary" onClick={() => history.push('/customers')} disabled={isSaving}>
                      {t('customers.form.cancel')}
                    </Button>
                    <Box style={{ display: 'flex', gap: '1.5rem' }}>
                      <Button
                        variant="contained"
                        style={{ padding: '1rem 2.5rem', marginLeft: '1rem' }}
                        disabled={!isValid || isSaving}
                        type="submit"
                      >
                        {t('customers.form.save')}
                        {isSaving && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
                      </Button>
                    </Box>
                  </StyledHandlers>
                </Card>
              </StyleForm>
            )}
          </Formik>
        </CustomerContent>
      </StyledContainer>
    </Box>
  );
};

export default CustomerCreate;
