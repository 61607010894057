import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadMachine, loadMachinePlcType } from '../../../redux/machines/actions';
import { getSelectedMachine } from '../../../redux/machines/selectors';
import MachineDetail from './MachineDetail';
import { getCommonLoadingState } from '../../../helpers/utils';

const ConnectedMachineDetail = () => {
  const dispatch = useDispatch();
  const { machineId } = useParams();
  const { loadingState, loadingStatePlcType, data, plcType } = useSelector(getSelectedMachine);

  useEffect(() => {
    dispatch(loadMachine(machineId));
    dispatch(loadMachinePlcType(machineId));
  }, [dispatch, machineId]);

  return (
    <MachineDetail
      loadingState={getCommonLoadingState([loadingState, loadingStatePlcType])}
      reloadAction={() => {
        dispatch(loadMachine(machineId));
        dispatch(loadMachinePlcType(machineId));
      }}
      machine={data}
      plcType={plcType}
    />
  );
};

export default ConnectedMachineDetail;
