import React from 'react';
import PropTypes from 'prop-types';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/pt';
import 'dayjs/locale/de';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import { ptBR, deDE } from '@mui/x-date-pickers';

export default function DateTimeProvider(props) {
  const { children } = props;
  const { i18n } = useTranslation();

  let language = 'en-gb';
  const pt = ptBR.components.MuiLocalizationProvider.defaultProps.localeText;
  const de = deDE.components.MuiLocalizationProvider.defaultProps.localeText;
  let localeText;
  if (i18n.language === 'pt_BR') {
    language = 'pt';
    localeText = pt;
  } else if (i18n.language === 'de_DE') {
    language = 'de';
    localeText = de;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language} localeText={localeText}>
      {children}
    </LocalizationProvider>
  );
}

DateTimeProvider.propTypes = {
  children: PropTypes.element.isRequired
};
