import React, { useState } from 'react';
import { Box, Button, Card, CardHeader } from '@mui/material';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import EditManagementUnitsModal from './EditManagementUnitsModal';
import { getValueToNullableCell } from '../../../../../helpers/stringHandler';
import withLoadingState from '../../../../../helpers/withLoadingState';

const labelAmend = 'machines.machine_details';

const ManagementUnits = ({ machineId, sensorUnits }) => {
  const { t } = useTranslation();

  const [editDatabaseModal, setEditDatabaseModal] = useState(false);

  const handleCloseDatabaseModal = () => setEditDatabaseModal(false);

  const sensorUnitsData = {
    temperature: getValueToNullableCell(sensorUnits?.temperature_variable_name),
    pressure: getValueToNullableCell(sensorUnits?.pressure_variable_name),
    product_flow: getValueToNullableCell(sensorUnits?.product_flow_variable_name),
    product_mass: getValueToNullableCell(
      sensorUnits?.product_mass_mass_flow_specific_density_and_specific_energy_variable_name
    ),
    liquid_flow: getValueToNullableCell(sensorUnits?.liquid_flow_and_volume_variable_name),
    mill_speed: getValueToNullableCell(sensorUnits?.mill_peripheral_speed_and_length_variable_name),
    current: getValueToNullableCell(sensorUnits?.current_decimal_places_variable_name),
    power: getValueToNullableCell(sensorUnits?.power_decimal_places_variable_name)
  };

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Button
            variant="text"
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
            onClick={() => setEditDatabaseModal(true)}
          >
            <NotifyIcon iconName="edit" fontSize="medium" />
            {t(`${labelAmend}.edit`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.management_units`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.management_units_subtitle`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={8} sx={{ mt: 5, mb: 4 }}>
        <Box gridColumn="span 1">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.management_units_modal.temperature`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{sensorUnitsData.temperature}</NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 1">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.management_units_modal.pressure`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{sensorUnitsData.pressure}</NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 1">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.management_units_modal.product_flow`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{sensorUnitsData.product_flow}</NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 1">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.management_units_modal.product_mass`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{sensorUnitsData.product_mass}</NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 1">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.management_units_modal.liquid_flow`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{sensorUnitsData.liquid_flow}</NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 1">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.management_units_modal.mill_speed`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{sensorUnitsData.mill_speed}</NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 1">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.management_units_modal.current`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{sensorUnitsData.current}</NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 1">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
            <Box>
              <NotifyTypo.InfoText fontWeight="400">
                {t(`${labelAmend}.management_units_modal.power`)}
              </NotifyTypo.InfoText>
              <NotifyTypo.Body1>{sensorUnitsData.power}</NotifyTypo.Body1>
            </Box>
          </Box>
        </Box>
      </Box>

      <EditManagementUnitsModal
        open={editDatabaseModal}
        handleClose={handleCloseDatabaseModal}
        data={sensorUnits && sensorUnits}
        machineId={machineId}
      />
    </Card>
  );
};

ManagementUnits.propTypes = {
  machineId: T.string,
  sensorUnits: T.shape({
    created_at: T.string,
    created_by: T.shape({}),
    updated_at: T.string,
    last_updated_by: T.shape({}),
    current_decimal_places_variable_name: T.string,
    liquid_flow_and_volume_variable_name: T.string,
    mill_peripheral_speed_and_length_variable_name: T.string,
    power_decimal_places_variable_name: T.string,
    pressure_variable_name: T.string,
    product_flow_variable_name: T.string,
    product_mass_mass_flow_specific_density_and_specific_energy_variable_name: T.string,
    temperature_variable_name: T.string
  })
};

ManagementUnits.defaultProps = {
  machineId: null,
  sensorUnits: null
};

export default withLoadingState(ManagementUnits);
