import styled from 'styled-components';

const StyledStatusSpan = styled.span`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  align-items: center;
  display: flex;
  padding: 5px 0;
  gap: 5px;
`;

const StyledStatusSpanLessMargin = styled(StyledStatusSpan)`
  margin-left: -5px;
`;

export { StyledStatusSpan, StyledStatusSpanLessMargin };
