import typeToReducer from 'type-to-reducer';
import { pick } from 'lodash';
import { combineReducers } from 'redux';

import { enhanceWithResetActions, loadingStateReducer } from '../utils';
import {
  CREATE_ORGANIZATION,
  GET_ORGANIZATION_PRODUCTS_ACCESS,
  LOAD_MACHINES_RESUME_LIST_WITH_FILTER,
  LOAD_ORGANIZATION,
  LOAD_ORGANIZATIONS,
  LOAD_ORGANIZATIONS_RESUME,
  ORGANIZATION_MACHINES_LIST,
  RESET_ORGANIZATIONS,
  RESET_SAP_STATUS,
  UPDATE_ORGANIZATION,
  VERIFY_SAP_ID
} from './constants';

const selectedReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_ORGANIZATION),
  data: typeToReducer(
    {
      [LOAD_ORGANIZATION]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_ORGANIZATION]: {
        SUCCESS: (data, { payload }) => ({ ...data, ...payload })
      }
    },
    {}
  )
});

const getOrganizationMachinesList = combineReducers({
  loadingState: loadingStateReducer(ORGANIZATION_MACHINES_LIST),
  data: typeToReducer(
    {
      [ORGANIZATION_MACHINES_LIST]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_MACHINES_RESUME_LIST_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const updateSapStatus = (data, { payload }) => pick(payload, ['sap_status']);
const updateVerifyStatus = (state, { payload }) => ({ ...state, ...payload });

const verificationReducer = combineReducers({
  loadingState: loadingStateReducer(VERIFY_SAP_ID),
  data: typeToReducer(
    {
      [LOAD_ORGANIZATION]: { SUCCESS: updateSapStatus },
      [UPDATE_ORGANIZATION]: { SUCCESS: updateSapStatus },
      [VERIFY_SAP_ID]: { START: updateVerifyStatus, SUCCESS: updateVerifyStatus },
      [RESET_SAP_STATUS]: (state, { status }) => ({ ...state, sap_status: status })
    },
    {}
  )
});

export default combineReducers({
  loadingState: enhanceWithResetActions([LOAD_ORGANIZATIONS])(
    loadingStateReducer([LOAD_ORGANIZATIONS, LOAD_ORGANIZATIONS_RESUME])
  ),
  updateLoadingState: enhanceWithResetActions([LOAD_ORGANIZATIONS, LOAD_ORGANIZATIONS_RESUME])(
    loadingStateReducer([UPDATE_ORGANIZATION])
  ),
  createLoadingState: enhanceWithResetActions([LOAD_ORGANIZATIONS])(loadingStateReducer([CREATE_ORGANIZATION])),
  data: typeToReducer(
    {
      [LOAD_ORGANIZATIONS]: {
        SUCCESS: (state, { payload }) => payload
      }
    },
    []
  ),
  dataResumed: enhanceWithResetActions([RESET_ORGANIZATIONS])(
    typeToReducer(
      {
        [LOAD_ORGANIZATIONS_RESUME]: {
          SUCCESS: (state, { payload }) => payload
        }
      },
      {}
    )
  ),
  selected: enhanceWithResetActions([RESET_ORGANIZATIONS])(selectedReducer),
  verification: verificationReducer,
  productsAccess: typeToReducer(
    {
      [GET_ORGANIZATION_PRODUCTS_ACCESS]: {
        SUCCESS: (state, { payload }) => payload
      }
    },
    []
  ),
  organizationMachineList: enhanceWithResetActions([ORGANIZATION_MACHINES_LIST])(getOrganizationMachinesList)
});
