import React, { useState } from 'react';
import T from 'prop-types';
import { Box, Card, Stack } from '@mui/material';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import DeleteSensor from '../DeleteSensorModal/DeleteSensorModal';
import ErrorCodeFormModal from '../ErrorCodeFormModal/ErrorCodeFormModal';

const labelAmmend = 'machines.error_codes';
const labelValues = 'machines.detail.error_codes';

const SensorMap = ({ sensors, machineId }) => {
  const { t } = useTranslation();
  const [deleteSensor, setDeleteSensor] = useState(false);
  const [customMode, setCustomMode] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorValues, setErrorValues] = useState(null);

  const handleCloseErrorModal = () => setErrorModal(false);

  const [idStringCustom, setIdStringCustom] = useState('');
  const [idStringPredefined, setIdStringPredefined] = useState('');

  const handleCloseDelete = () => setDeleteSensor(false);

  const handleDeleteCustom = itemId => {
    setIdStringCustom(`custom_error_id_${itemId}`);
    setIdStringPredefined(null);
    setDeleteSensor(true);
  };

  const handleDeletePreDef = itemId => {
    setIdStringPredefined(`pre_def_error_id_${itemId}`);
    setIdStringCustom(null);
    setDeleteSensor(true);
  };

  const handleEditCustom = item => {
    setErrorValues(item);
    setCustomMode(true);
    setErrorModal(true);
  };

  const handleEditPreDef = item => {
    setErrorValues(item);
    setCustomMode(false);
    setErrorModal(true);
  };

  return (
    <Box>
      {sensors?.errors?.pre_def?.map(item => (
        <Card
          sx={{ px: 4, py: 2, my: 2, display: 'flex', justifyContent: 'space-between' }}
          key={item.pre_def_error_id}
        >
          <Stack spacing={5} direction="row" sx={{ alignItems: 'center' }}>
            <NotifyTypo.Heading2
              fontWeight="500"
              sx={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {item?.type ? (
                <>
                  {item?.type}: {t(`${labelAmmend}.${item?.type}`)}
                </>
              ) : (
                'Undefined'
              )}
            </NotifyTypo.Heading2>
            <NotifyTypo.InfoText fontWeight="400">
              {t(`${labelValues}.address`)} {item.source || '---'}
            </NotifyTypo.InfoText>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', color: '#2BACA4', alignItems: 'center' }}>
            <Box
              sx={{ cursor: 'pointer', px: 2 }}
              onClick={() => {
                handleEditPreDef(item);
              }}
            >
              <NotifyIcon fontSize="large" iconName="edit" />
            </Box>
            <Box sx={{ cursor: 'pointer', px: 2 }} onClick={() => handleDeletePreDef(item.pre_def_error_id)}>
              <NotifyIcon fontSize="large" iconName="delete" />
            </Box>
          </Box>
        </Card>
      ))}
      {sensors?.errors?.custom?.map(item => (
        <Card sx={{ px: 4, py: 2, my: 2, display: 'flex', justifyContent: 'space-between' }} key={item.custom_error_id}>
          <Stack spacing={5} direction="row" sx={{ alignItems: 'center' }}>
            <NotifyTypo.Heading2
              fontWeight="500"
              sx={{ maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {item?.custom_message}
            </NotifyTypo.Heading2>
            <NotifyTypo.InfoText fontWeight="400">Address: {item.source}</NotifyTypo.InfoText>
            <NotifyTypo.Subtitle1 fontWeight="400">
              {item.custom_type === 'BOOL' ? (
                <>
                  {t(`${labelValues}.trigger_optional`)}:{' '}
                  {t(`machines.form.sensor.warning.${item.custom_bool_val ? 'true' : 'false'}`)}
                </>
              ) : (
                <>
                  {t(`${labelValues}.trigger_value`)}: {item.custom_int_val || '---'}
                </>
              )}
            </NotifyTypo.Subtitle1>
            <NotifyTypo.InfoText fontWeight="400">{item.is_custom ? 'Custom' : ''}</NotifyTypo.InfoText>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', color: '#2BACA4', alignItems: 'center' }}>
            <Box
              sx={{ cursor: 'pointer', px: 2 }}
              onClick={() => {
                handleEditCustom(item);
              }}
            >
              <NotifyIcon fontSize="large" iconName="edit" />
            </Box>
            <Box sx={{ cursor: 'pointer', px: 2 }} onClick={() => handleDeleteCustom(item.custom_error_id)}>
              <NotifyIcon fontSize="large" iconName="delete" />
            </Box>
          </Box>
        </Card>
      ))}

      <DeleteSensor
        handleClose={handleCloseDelete}
        open={deleteSensor}
        errorId={idStringCustom ?? idStringPredefined}
        machineId={machineId}
      />

      <ErrorCodeFormModal
        handleClose={handleCloseErrorModal}
        open={errorModal}
        machineId={machineId}
        isCustom={customMode}
        listedValues={sensors?.errors?.pre_def || []}
        editError={errorValues}
      />
    </Box>
  );
};

SensorMap.propTypes = {
  sensors: T.shape({
    errors: T.shape({
      custom: T.arrayOf(T.shape({})),
      pre_def: T.arrayOf(T.shape({}))
    })
  }),
  machineId: T.string
};

SensorMap.defaultProps = {
  sensors: null,
  machineId: null
};

export default SensorMap;
