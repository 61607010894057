import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { getValueToNullableCell } from '../../../../../../helpers/stringHandler';
import { IrisBatchMeasurementsPropTypes } from '../../../../../../helpers/propTypes';
import IrisBatchRecordsEditMeasurements from '../../IrisBatchRecordsEditModal/IrisBatchRecordsEditMeasurements';

const labelAmend = 'machines.machine_details.batch_iris.measurements';

const IrisBatchMeasurements = ({ measurements, machineId }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Button
            variant="text"
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
            onClick={() => setEditModal(true)}
          >
            <NotifyIcon iconName="edit" fontSize="medium" />
            {t(`machines.machine_details.edit`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.title`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.subtitle`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2} sx={{ mt: 3, mb: 2 }}>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.progress_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(measurements?.progress_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.total_grinding_time_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(measurements?.total_grinding_time_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.grinding_energy_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(measurements?.grinding_energy_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.specific_energy_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(measurements?.specific_energy_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.estimated_finish_time_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(measurements?.estimated_finish_time_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.current_cycles_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(measurements?.current_cycles_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.number_of_passes_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(measurements?.number_of_passes_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.total_grinding_energy_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(measurements?.total_grinding_energy_variable)}</NotifyTypo.Body1>
        </Box>
      </Box>

      <IrisBatchRecordsEditMeasurements
        open={editModal}
        handleClose={handleClose}
        data={measurements}
        machineId={machineId}
      />
    </Card>
  );
};

IrisBatchMeasurements.propTypes = {
  measurements: IrisBatchMeasurementsPropTypes,
  machineId: T.string.isRequired
};

IrisBatchMeasurements.defaultProps = {
  measurements: null
};

export default IrisBatchMeasurements;
