const getAuth = state => state.auth;
const getRouter = state => state.router;
const getUser = state => state.user;
const getUi = state => state.ui;
const getOrganizations = state => state.organizations;
const getUsers = state => state.users;
const getUsersV2 = state => state.usersV2;
const getMachines = state => state.machines;
const getGateways = state => state.gateways;
const getSupportContacts = state => state.supportContact;
const getLicenses = state => state.licenses;
const getCloneableMachines = state => state.cloneableMachines;
const getCloneableMachineDetail = state => state.cloneableMachines.machinesDetails;
const getTaskReducerState = state => state.task;
const getSensorReducerState = state => state.sensor;
const getBatchReducerState = state => state.batch;
const getGatewaysV2 = state => state.gatewaysV2;
const getMachinesV2 = state => state.machinesV2;
const getProductUsageState = state => state.productUsage;

/* universal */
const getLoadingState = state => state.loadingState;
const getData = state => state.data;
const getStatus = state => state.status;

export {
  getLoadingState,
  getData,
  getStatus,
  getRouter,
  getAuth,
  getUser,
  getUi,
  getOrganizations,
  getUsers,
  getMachines,
  getGateways,
  getSupportContacts,
  getLicenses,
  getCloneableMachines,
  getCloneableMachineDetail,
  getTaskReducerState,
  getSensorReducerState,
  getBatchReducerState,
  getUsersV2,
  getGatewaysV2,
  getMachinesV2,
  getProductUsageState
};
