import {
  CREATE_SENSOR,
  CREATE_UPDATE_SENSOR_IRIS,
  DELETE_SENSOR,
  DELETE_SENSOR_IRIS,
  FLIP_SENSOR_IRIS_STATUS,
  LOAD_SENSORS,
  LOAD_SENSORS_CONFIG_FOR_IRIS,
  LOAD_SENSORS_IRIS,
  LOAD_SENSORS_UNITS,
  UPDATE_SENSOR,
  UPDATE_SENSORS_UNITS
} from './constants';

export const loadSensor = id => ({
  type: LOAD_SENSORS,
  id
});

export const updateSensorUnits = (machineId, payload) => ({
  type: UPDATE_SENSORS_UNITS,
  machineId,
  payload
});

export const createSensor = (machineId, payload) => ({
  type: CREATE_SENSOR,
  machineId,
  payload
});

export const updateSensor = (machineId, payload) => ({
  type: UPDATE_SENSOR,
  machineId,
  payload
});

export const deleteSensor = (machineId, sensorType) => ({
  type: DELETE_SENSOR,
  machineId,
  sensorType
});

/**
 * IRIS Sensors
 */
export const loadSensorUnits = machineId => ({
  type: LOAD_SENSORS_UNITS,
  machineId
});

export const loadSensorConfigsForIris = () => ({
  type: LOAD_SENSORS_CONFIG_FOR_IRIS
});

export const loadSensorIris = machineId => ({
  type: LOAD_SENSORS_IRIS,
  machineId
});

export const createUpdateSensorIris = (machineId, payload) => ({
  type: CREATE_UPDATE_SENSOR_IRIS,
  machineId,
  payload
});

export const deleteIrisSensor = (machineId, sensorId) => ({
  type: DELETE_SENSOR_IRIS,
  machineId,
  sensorId
});

export const flipIrisSensorStatus = (machineId, sensorId) => ({
  type: FLIP_SENSOR_IRIS_STATUS,
  machineId,
  sensorId
});
