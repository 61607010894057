import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader, CardContent, Stack, Typography, Grid, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { NotifyIcon, NotifyTypo, Loader, DataTable, FormElements } from 'web-components';
import { COLOR_PRIMARY } from 'attrs/colors';
import { useHistory } from 'react-router-dom';
import Footer from '../../../../components/DataTable/Footer';
import { getTaskReducerState } from '../../../../redux/rootSelectors';
import { isSuccess } from '../../../../helpers/utils';
import { loadTasks } from '../../../../redux/task/actions';
import ChipViewer from './ChipViewer';
import { getSelectedMachine } from '../../../../redux/machines/selectors';
import { getSelectedOrganization } from '../../../../redux/organizations/selectors';

const TaskViewer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingTasks, paginatedTaskList } = useSelector(getTaskReducerState);
  const hasTasksLoadedSuccessfully = isSuccess(loadingTasks.status);
  const maxPageLimit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    colId: 'name',
    order: 'asc'
  });
  const [hasToLoadTbContainer, setHasToLoadTbContainer] = useState(true);

  const [name, setName] = useState();
  const categoryTypeList = [
    { title: t('enum.category_type.CIVIL'), value: 'CIVIL' },
    { title: t('enum.category_type.ELECTRICAL'), value: 'ELECTRICAL' },
    { title: t('enum.category_type.MECHANICAL'), value: 'MECHANICAL' }
  ];
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const history = useHistory();
  const { data: customer } = useSelector(getSelectedOrganization);
  const { data: machine } = useSelector(getSelectedMachine);

  useEffect(() => {
    setCategoryOptions(categoryTypeList);
    const requestBody = {
      limit: maxPageLimit,
      page: currentPage,
      order: currentSort.order,
      sort: currentSort.colId
    };
    dispatch(loadTasks({ requestBody, machineId: machine.id }));
  }, [machine]);

  useEffect(() => {
    if (hasToLoadTbContainer && hasTasksLoadedSuccessfully) setHasToLoadTbContainer(false);
  }, [hasToLoadTbContainer, hasTasksLoadedSuccessfully]);

  function hasTaskLoaded() {
    return !hasToLoadTbContainer && paginatedTaskList && paginatedTaskList.data && paginatedTaskList.data.length > 0;
  }

  const onChangeCategories = (_, categoriesParam) => {
    const newCategoriesParam = categoriesParam && categoriesParam.length > 0 ? categoriesParam : undefined;
    const newSelectedCategory = newCategoriesParam ? newCategoriesParam.map(category => category.value) : undefined;
    setSelectedCategoryOptions(newCategoriesParam);
    setSelectedCategory(newSelectedCategory);
    const requestBody = {
      name,
      categories: newSelectedCategory,
      limit: maxPageLimit,
      page: currentPage,
      order: currentSort.order,
      sort: currentSort.colId
    };
    dispatch(loadTasks({ requestBody, machineId: machine.id }));
  };

  const handleName = event => {
    const nameParam = event.target.value || undefined;
    setName(nameParam);
    const requestBody = {
      name: nameParam,
      categories: selectedCategory,
      limit: maxPageLimit,
      page: currentPage,
      order: currentSort.order,
      sort: currentSort.colId
    };
    dispatch(loadTasks({ requestBody, machineId: machine.id }));
  };

  const isOptionEqualToValue = (option, value) => option.value === value.value;

  const handleChangePage = page => {
    setCurrentPage(page);
    const requestBody = {
      name,
      categories: selectedCategory,
      limit: maxPageLimit,
      page,
      order: currentSort.order,
      sort: currentSort.colId
    };
    dispatch(loadTasks({ requestBody, machineId: machine.id }));
  };

  const handleSort = (colId, order) => {
    setCurrentSort({ colId, order });
    const requestBody = {
      name,
      categories: selectedCategory,
      limit: maxPageLimit,
      page: currentPage,
      order,
      sort: colId
    };
    dispatch(loadTasks({ requestBody, machineId: machine.id }));
  };

  const FilterOutlinedIcon = (
    <InputAdornment position="end" style={{ position: 'absolute', right: '6px', bottom: '22px' }}>
      <NotifyIcon iconName="filterOutlined" />
    </InputAdornment>
  );

  const columns = [
    {
      id: 'name',
      label: t(`task.table.task_name`),
      sortable: true,
      cellRenderer: row => row.task_general_information.name
    },
    {
      id: 'categories',
      label: t(`task.table.category`),
      sortable: false,
      cellRenderer: row => (
        <ChipViewer
          dataList={row.task_general_information.categories.map((category, index) => ({
            id: index,
            label: t(`enum.category_type.${category}`)
          }))}
          title={t('home.planning.edit.overview.notifications.by_sms')}
          direction="row"
          breakQuantity={3}
        />
      )
    }
  ];

  const tableProps = {
    dataSelectorTable: 'task-table',
    dataSelectorRows: 'task-table-row',
    columns,
    rows: paginatedTaskList.data,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={paginatedTaskList.total_pages || 1}
        totalLength={paginatedTaskList.total_length || 0}
        totalItemsPerPage={paginatedTaskList.limit || 0}
        onChange={handleChangePage}
      />
    ),
    defaultSort: currentSort,
    sortFn: handleSort
  };

  const handleAddTask = () => {
    history.push(`/customers/${customer.id}/machines/${machine.id}/tasks/create`);
  };

  return (
    <>
      <Card sx={{ p: 4, my: 2, minHeight: '490px' }}>
        <CardHeader
          sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
          action={
            <Button
              variant={hasTaskLoaded() ? 'contained' : 'outlined'}
              sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px' }}
              onClick={handleAddTask}
            >
              <NotifyIcon iconName="plus" fontSize="medium" />
              {t(`task.add_button`)}
            </Button>
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mt: '10px', fontSize: '16px' }}>{t(`task.title`)}</Box>
            </Box>
          }
          titleTypographyProps={{
            fontWeight: 'bold',
            variant: 'Body1'
          }}
        />
        {hasTaskLoaded() ? (
          <NotifyTypo.Subtitle1>
            {/* TODO: its a static value, take it off when its possible */}
            {t('task.caption', {
              used: '01',
              total: '1000'
            })}
          </NotifyTypo.Subtitle1>
        ) : (
          <NotifyTypo.Subtitle1>{t(`task.empty_caption`)}</NotifyTypo.Subtitle1>
        )}

        {hasToLoadTbContainer ? (
          <Stack sx={{ pt: '132px' }}>
            <Loader standalone />
          </Stack>
        ) : (
          <CardContent sx={{ p: 0 }}>
            {hasTaskLoaded() ? (
              <Stack sx={{ pt: '33px' }} spacing={2} alignItems="center" justifyContent="center">
                <Grid container spacing={2} columns={2}>
                  <Grid item xs={1}>
                    <FormElements.TextField
                      label={t('task.fields.task_name')}
                      id="name-filter"
                      onChange={handleName}
                      value={name}
                      size="small"
                      bgcolor="white"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ position: 'absolute', right: '6px', bottom: '22px' }}>
                            <NotifyIcon iconName="search" fontSize="default" color="primary" />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FormElements.MultiSelect
                      sx={{
                        input: { bgcolor: 'white !important' },
                        '.MuiTextField-root > .MuiInputBase-root': { bgcolor: 'white !important' }
                      }}
                      activeColor={COLOR_PRIMARY}
                      label={t('task.fields.category')}
                      id="categories"
                      name="categories"
                      fullWidth
                      value={selectedCategoryOptions}
                      options={categoryOptions}
                      setFieldValue={onChangeCategories}
                      size="small"
                      isOptionEqualToValue={isOptionEqualToValue}
                      InputProps={{
                        endAdornment: FilterOutlinedIcon
                      }}
                    />
                  </Grid>
                </Grid>
                <DataTable {...tableProps} />
              </Stack>
            ) : (
              <Stack sx={{ pt: '99px' }} spacing={2} alignItems="center" justifyContent="center">
                <Box
                  sx={{
                    bgcolor: '#EDEDF2',
                    m: 1,
                    width: '7.5rem',
                    height: '7.5rem',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center'
                  }}
                >
                  <NotifyIcon iconName="material" sx={{ color: '#C3C8D3', fontSize: '4rem', alignSelf: 'center' }} />
                </Box>
                <Typography sx={{ fontSize: { xs: 18, sm: 20 } }}>{t('task.empty_warning.title')}</Typography>
                <Typography
                  sx={{ fontSize: { xs: 12, sm: 14 }, maxWidth: 280, textAlign: 'center' }}
                  color="text.secondary"
                >
                  {t('task.empty_warning.caption')}
                </Typography>
              </Stack>
            )}
          </CardContent>
        )}
      </Card>
    </>
  );
};

export default TaskViewer;
