import { Box, Stack, Typography } from '@mui/material';
import Footer from 'components/DataTable/Footer';
import EmptyState from 'components/EmptyState';
import { getValueToNullableCell } from 'helpers/stringHandler';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'web-components';
import { useDispatch, useSelector } from 'react-redux';
import { gatewaysV2GetLogsByGtwIdAction } from 'redux/gateways_v2/actions';
import T from 'prop-types';
import { isSuccess } from 'helpers/utils';
import { getGatewaysV2 } from 'redux/rootSelectors';

export default function GatewayLogs({ gatewayId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [limit, setLimit] = useState(0);
  const [logList, setLogList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const rowsPerPage = 5;

  const loadingLogsStateEqual = (oldObj, newObj) =>
    (oldObj?.loadingLogsState?.status || false) === newObj?.loadingLogsState?.status || false;
  const { loadingLogsState } = useSelector(getGatewaysV2, loadingLogsStateEqual);

  const logListEqual = (oldObj, newObj) => {
    const sameSize = oldObj?.getGatewayLogsByGtwId?.data?.length === newObj?.getGatewayLogsByGtwId?.data?.length;
    let equalsObj = true;
    for (let i = 0; sameSize && i < oldObj?.getGatewayLogsByGtwId?.data?.length; i += 1) {
      equalsObj =
        JSON.stringify(oldObj?.getGatewayLogsByGtwId?.data[i] || '') ===
        JSON.stringify(newObj?.getGatewayLogsByGtwId?.data[i] || '');
      if (!equalsObj) break;
    }
    return (
      JSON.stringify(oldObj?.getGatewayLogsByGtwId?.data || '') ===
        JSON.stringify(newObj?.getGatewayLogsByGtwId?.data || '') &&
      oldObj?.getGatewayLogsByGtwId?.total_length === newObj?.getGatewayLogsByGtwId?.total_length &&
      sameSize &&
      equalsObj
    );
  };
  const { getGatewayLogsByGtwId: getLogsResponse } = useSelector(getGatewaysV2, logListEqual);

  useEffect(() => {
    if (gatewayId) {
      dispatch(
        gatewaysV2GetLogsByGtwIdAction(gatewayId, {
          limit: rowsPerPage,
          page: currentPage
        })
      );
    }
  }, [dispatch, gatewaysV2GetLogsByGtwIdAction, gatewayId]);

  useEffect(() => {
    if (isSuccess(loadingLogsState.status)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [loadingLogsState.status]);

  useEffect(() => {
    if (getLogsResponse && getLogsResponse.data && getLogsResponse.data.length > 0) {
      setCurrentPage(getLogsResponse.page || 0);
      setTotalPages(getLogsResponse.total_pages || 0);
      setTotalLength(getLogsResponse.total_length || 0);
      setLimit(getLogsResponse.limit || 0);
      setLogList(getLogsResponse.data);
    }
  }, [getLogsResponse]);

  const columns = [
    {
      id: 'application_name',
      label: t('gateway_details.log.table.application_name'),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.application_name)
    },
    {
      id: 'log_level',
      label: t('gateway_details.log.table.log_level'),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.log_level)
    },
    {
      id: 'message',
      label: t('gateway_details.log.table.message'),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.message)
    },
    {
      id: 'date',
      label: t('gateway_details.log.table.date'),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.date)
    }
  ];

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(
      gatewaysV2GetLogsByGtwIdAction(gatewayId, {
        limit: rowsPerPage,
        page
      })
    );
  };

  const propsLogList = {
    dataSelectorTable: 'logs-table',
    dataSelectorRows: 'logs-table-row',
    isLoading,
    columns,
    rows: logList,
    hover: undefined,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={totalPages}
        totalLength={totalLength}
        totalItemsPerPage={limit}
        onChange={handleChangePage}
      />
    )
  };
  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 3
      }}
      data-selector="gateway-logs"
    >
      <Stack direction="column" spacing={3}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('gateway_details.log.title')}</Typography>
        {(logList && logList.length > 0) || isLoading ? (
          <DataTable hover {...propsLogList} />
        ) : (
          <Box sx={{ py: 2 }}>
            <EmptyState type="gtw_logs" />
          </Box>
        )}
      </Stack>
    </Box>
  );
}

GatewayLogs.propTypes = {
  gatewayId: T.string.isRequired
};
