import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CardHeader } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { IrisBatchDetailsPropTypes } from '../../../../../../helpers/propTypes';
import { getValueToNullableCell } from '../../../../../../helpers/stringHandler';
import IrisBatchRecordsEditDetails from '../../IrisBatchRecordsEditModal/IrisBatchRecordsEditDetails';

const labelAmend = 'machines.machine_details.batch_iris.details';

const IrisBatchDetails = ({ details, machineId }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Button
            variant="text"
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
            onClick={() => setEditModal(true)}
          >
            <NotifyIcon iconName="edit" fontSize="medium" />
            {t(`machines.machine_details.edit`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.title`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.subtitle`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={2} sx={{ mt: 3, mb: 2 }}>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.batch_id_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(details?.batch_id_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.recipe_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(details?.recipe_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.batch_size_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(details?.batch_size_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.start_time_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(details?.start_time_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.end_time_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(details?.end_time_variable)}</NotifyTypo.Body1>
        </Box>
      </Box>

      <IrisBatchRecordsEditDetails open={editModal} handleClose={handleClose} data={details} machineId={machineId} />
    </>
  );
};

IrisBatchDetails.propTypes = {
  details: IrisBatchDetailsPropTypes,
  machineId: T.string.isRequired
};

IrisBatchDetails.defaultProps = {
  details: null
};

export default IrisBatchDetails;
