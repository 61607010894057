import styled from 'styled-components';
import { Paper, MenuItem, Popper, Drawer } from '@mui/material';

import { COLOR_MAIN_HIGHLIGHT, COLOR_PRIMARY, LIGHT_GREY_OPAQUE } from '../../../attrs/colors';
import { MENU_ITEM_HEIGHT, MENU_PROFILE_ITEM_HEIGHT, SUB_NAVIGATION_WIDTH } from '../../../attrs/layout';

const SubNavigationWrapper = styled(Paper)`
  &.MuiPaper-root {
    background-color: ${COLOR_MAIN_HIGHLIGHT};
    color: ${COLOR_PRIMARY};
    width: ${SUB_NAVIGATION_WIDTH}px;
    max-width: ${SUB_NAVIGATION_WIDTH}px;
    padding-left: 0;
    padding-right: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @media (max-width: 425px) {
      width: 100vw;
      max-width: 100vw;
    }

    & > .MuiList-padding {
      padding: 0;
    }
  }
`;

const StyledPopper = styled(Popper)(
  () => `
  right: 0px;
  left: unset !important;
  top: 4rem !important;
  z-index: 1100;
`
);

const StyledDrawer = styled(Drawer)(
  () => `
  & .MuiPaper-root {
    min-height: 100%;
  }
  & .MuiBackdrop-root {
    background: none;
  }
`
);

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    min-height: ${MENU_ITEM_HEIGHT}px;
    border-bottom: 1px solid ${LIGHT_GREY_OPAQUE};
    justify-content: space-between;

    :hover {
      background-color: #1c1e29;
    }
  }

  & a {
    color: inherit;
    text-decoration: none;
  }

  &.main,
  :last-child {
    border: 0;
  }

  &.lastMain {
    border-bottom: 1px solid ${LIGHT_GREY_OPAQUE};
  }

  &.loggedInUserName {
    min-height: 20px !important;
    background-color: #74799361;
    transition: 0.3s;
    font-size: 13px;
    cursor: default;
  }

  &.loggedInUserName:hover {
    background-color: #74799361;
  }
`;

const ProfileWrapper = styled(MenuItem)`
  &.MuiMenuItem-root {
    min-height: ${MENU_PROFILE_ITEM_HEIGHT}px;
    white-space: pre-line;
    overflow-wrap: anywhere;
    border-bottom: 2px solid ${LIGHT_GREY_OPAQUE};
    cursor: default;
    color: white;
    pointer-events: none;

    display: flex;
    & > h5 {
      flex-grow: 1;
    }
  }
`;

export { SubNavigationWrapper, StyledMenuItem, ProfileWrapper, StyledPopper, StyledDrawer };
