import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NotifyIcon } from 'web-components';

import { ERROR, SUCCESS, WARNING, INFO } from '../../attrs/status';
import { NOTIFICATION_COLORS } from '../../attrs/colors';
import { getNotifications } from '../../redux/ui/selectors';
import { resolveNotification } from '../../redux/ui/notifications/actions';

const variantIcon = {
  [SUCCESS]: (
    <NotifyIcon
      iconName="batchComplete"
      style={{ color: NOTIFICATION_COLORS[SUCCESS], marginRight: '.5rem', marginTop: '.4rem', fontSize: '24px' }}
    />
  ),
  [WARNING]: <NotifyIcon iconName="warning" style={{ color: NOTIFICATION_COLORS[WARNING], marginRight: '.5rem' }} />,
  [ERROR]: <NotifyIcon iconName="error" style={{ color: NOTIFICATION_COLORS[ERROR], marginRight: '.5rem' }} />,
  [INFO]: <NotifyIcon iconName="info" style={{ color: NOTIFICATION_COLORS[INFO], marginRight: '.5rem' }} />
};

let displayed = [];

/**
 * Create a new notification message
 * @param {string} message
 * @param {string: INITIAL|START|IN_PROGRESS|SUCCESS|ERROR} type
 * @param t
 * @param {Object.<string, number|string>} parameters
 * @returns {React.JSX.Element}
 */
const createMessage = (message, type, t, parameters) => (
  <span style={{ display: 'flex', alignItems: 'center' }}>
    {variantIcon[type]}
    {t(message, parameters)}
  </span>
);

const Notifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, type, options = {}, dismissed = false, parameters = {} }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      const action = () => (
        <IconButton key="close" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
          <NotifyIcon fontSize="small" iconName="close" />
        </IconButton>
      );

      // display snackbar using notistack
      enqueueSnackbar(createMessage(message, type, t, parameters), {
        key,
        action,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(resolveNotification(myKey));
          removeDisplayed(myKey);
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t]);

  return null;
};

export default Notifications;
