import { Box, Button, FormControl, Stack } from '@mui/material';
import { BuildCircleOutlined, CircleNotificationsOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { blue, orange } from '@mui/material/colors';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import T, { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormElements, NotifyTypo } from 'web-components';
import { updateMessagesMachineIrisV3 } from '../../../../../../redux/machines_v2/actions';
import { StyledContent, StyledContentGrid, StyledHandlers, StyledNewDialog } from '../elements';
import { getFormikError, getFormikHelperText, isLoading, validateBlankSpace } from '../../../../../../helpers/utils';
import { MessageStatusSchema } from '../../../../../../attrs/formValidation';
import { getStatusMachine } from '../../../../../../redux/machines/selectors';

const labelAmend = 'machines.machine_details';

const StatusTypes = [{ type: 'alarm' }, { type: 'warning' }, { type: 'maintenance' }];

const statusTypeToIconName = statusType =>
  ({
    alarm: <CircleNotificationsOutlined sx={{ color: '#59D66E', fontSize: 25, transform: 'rotate(45deg)' }} />,
    warning: <ErrorOutlineOutlined sx={{ color: orange[500], fontSize: 25 }} />,
    maintenance: <BuildCircleOutlined sx={{ color: blue[500], fontSize: 25 }} />
  }[statusType]);

const StatusCard = ({ getError, getHelperText, handleBlur, handleChange, statusType, values }) => {
  const { t } = useTranslation();
  const valueFieldName = `${statusType.type}.value`;

  const handleChangeAddress = event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {statusTypeToIconName(statusType.type)}
        <NotifyTypo.Body1 fontWeight="bold" sx={{ ml: 1 }}>
          {t(`machines.status.${statusType.type}`)}
        </NotifyTypo.Body1>
      </Box>
      <StyledContentGrid>
        <FormElements.TextField
          fullWidth
          error={getError(valueFieldName)}
          helperText={getHelperText(valueFieldName)}
          id={valueFieldName}
          label={t('machines.machine_details.messages.info_table.class_name')}
          margin="normal"
          name={valueFieldName}
          onBlur={handleBlur}
          onChange={handleChangeAddress}
          required={statusType.required}
          value={values[statusType.type].value}
        />
      </StyledContentGrid>
    </Box>
  );
};

StatusCard.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  values: T.shape({
    alarm: T.shape({}),
    warning: T.shape({}),
    maintenance: T.shape({})
  }).isRequired,
  statusType: T.shape({
    type: T.string,
    required: T.bool
  }).isRequired
};

const EditMessagesModal = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loadingState } = useSelector(getStatusMachine);

  const isSubmitting = isLoading(loadingState.status);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const submitForm = (values, formikHelpers) => {
    const payload = {
      area_name: values?.area_name,
      alarm_class_name: values?.alarm?.value.toString(),
      warning_class_name: values?.warning?.value.toString(),
      maintenance_class_name: values?.maintenance?.value.toString()
    };

    try {
      dispatch(updateMessagesMachineIrisV3(machineId, payload));
      handleClose(true);
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="status-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.handle_button`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <Formik
      initialValues={{
        area_name: {
          value: data?.area_name || ''
        },
        alarm: {
          value: data?.alarm_class_name || ''
        },
        warning: {
          value: data?.maintenance_class_name || ''
        },
        maintenance: {
          value: data?.warning_class_name || ''
        }
      }}
      validationSchema={MessageStatusSchema}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              handleClose();
            }}
            title={t(`${labelAmend}.messages.edit_modal.title`)}
            subTitle={t(`${labelAmend}.messages.edit_modal.subtitle`)}
            maxWidth="630px"
            maxHeight="672px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            shouldCloseOnOverlayClick={false}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <Stack direction="column" gap={4}>
                    <StyledContentGrid>
                      <FormElements.TextField
                        fullWidth
                        name="area_name"
                        id="area_name"
                        label={t(`${labelAmend}.messages.edit_modal.form.area_name`)}
                        margin="normal"
                        helperText={t(`${labelAmend}.messages.edit_modal.form.area_name_helper_text`)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.area_name.value}
                        InputLabelProps={{
                          sx: {
                            transform: 'translate(12px, 5px) scale(1)!important'
                          }
                        }}
                      />
                    </StyledContentGrid>
                    {StatusTypes.map(statusType => (
                      <Box>
                        <StatusCard
                          getError={getError}
                          getHelperText={getHelperText}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          key={`machine-status-card-${statusType.type}`}
                          statusType={statusType}
                          values={values}
                        />
                      </Box>
                    ))}
                  </Stack>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

EditMessagesModal.propTypes = {
  data: T.shape({
    alarm_class_name: T.string.isRequired,
    area_name: T.string.isRequired,
    created_at: T.arrayOf(T.shape({})),
    created_by: T.arrayOf(T.shape({})),
    last_updated_by: T.arrayOf(T.shape({})),
    maintenance_class_name: T.string.isRequired,
    updated_at: T.string.isRequired,
    warning_class_name: T.string.isRequired
  }),
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired
};

EditMessagesModal.defaultProps = {
  data: null,
  machineId: null
};

export default EditMessagesModal;
