import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import T, { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { NotifyTypo } from 'web-components';
import { useDispatch, useSelector } from 'react-redux';
import { getSensorReducerState } from 'redux/rootSelectors';
import { isError, isLoading, isSuccess } from 'helpers/utils';
import { StyledHandlers } from '../../elements';
import { StyledContent, StyledNewDialog } from '../elements';
import { deleteSensor, loadSensor } from '../../../../../redux/sensor/actions';
import { loadMetricsMachineDetails } from '../../../../../redux/machines/actions';

const labelAmend = 'machines.machine_details.delete_modal';

const successToastProps = {
  variant: 'success',
  autoHideDuration: 3000
};

const errorToastProps = {
  variant: 'error',
  autoHideDuration: 3000
};

const DeleteSensor = ({ open, handleClose, machineId, sensorType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { deletingSensorStatus } = useSelector(getSensorReducerState);

  const [successDeletingSensor, setSuccessDeletingSensor] = useState(false);
  useEffect(() => {
    if (successDeletingSensor && open) {
      dispatch(loadSensor(machineId));
      enqueueSnackbar(t('machines.form.sensor.message.deleting_success'), successToastProps);
      setSuccessDeletingSensor(false);
      dispatch(loadMetricsMachineDetails(machineId));
      handleClose();
    }
  }, [successDeletingSensor]);

  const [errorDeletingSensor, setErrorDeletingSensor] = useState(false);
  useEffect(() => {
    if (errorDeletingSensor) {
      enqueueSnackbar(t('machines.form.sensor.message.deleting_error'), errorToastProps);
      setErrorDeletingSensor(false);
    }
  }, [errorDeletingSensor]);

  useEffect(() => {
    if (isSuccess(deletingSensorStatus.status)) {
      setSuccessDeletingSensor(true);
    }
    if (isError(deletingSensorStatus.status)) {
      setErrorDeletingSensor(true);
    }
  }, [deletingSensorStatus.status]);

  const handleDeleteSensor = async () => {
    dispatch(deleteSensor(machineId, sensorType));
  };

  function isDeleting() {
    return isLoading(deletingSensorStatus.status);
  }

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={() => handleClose()} disabled={isDeleting()}>
        {t(`${labelAmend}.cancel`)}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        color="error"
        onClick={handleDeleteSensor}
        disabled={isDeleting()}
      >
        {t(`${labelAmend}.action`)}
        {isDeleting() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      open={open}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      title={t(`${labelAmend}.sensor_title`)}
      subTitle={t(`${labelAmend}.sensor_subtitle`)}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="680px"
      content={
        <StyledContent>
          <NotifyTypo.Body1>{t(`${labelAmend}.sensor_text`)}</NotifyTypo.Body1>
        </StyledContent>
      }
    />
  );
};

DeleteSensor.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  machineId: T.string.isRequired,
  sensorType: T.string.isRequired
};

export default DeleteSensor;
