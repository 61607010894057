import { ClickAwayListener, IconButton, MenuList } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { push } from 'connected-react-router';
import T from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NotifyIcon } from 'web-components';

import { logout } from '../../../redux/auth/actions';
import { setConfirmationRequest } from '../../../redux/ui/confirmations/actions';
import OrganizationProfile from './OrganizationProfile';
import { StyledMenuItem, StyledPopper, SubNavigationWrapper } from './elements';

const getSubNavRoutes = routes => {
  const all = routes.filter(route => route.subNav);

  return {
    main: all.filter(route => route.subNav.main),
    settings: all.filter(route => !route.subNav.main)
  };
};

const SubNavigation = ({ routes }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { main: subNavMain, settings: subNavSettings } = getSubNavRoutes(routes);

  const handleToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  };

  const prevOpen = useRef(menuOpen);

  useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  const handleLogout = () => {
    dispatch(
      setConfirmationRequest({
        message: 'dialog.confirmation.logout',
        action: () => dispatch(logout())
      })
    );
    setMenuOpen(false);
  };

  const handleNavigationClick = url => {
    dispatch(push(url));
    setMenuOpen(false);
  };

  const navigationElements = [
    <OrganizationProfile key="organization" />,
    ...subNavMain.map((route, index) => (
      <StyledMenuItem
        className={index === subNavMain.length - 1 ? 'lastMain' : 'main'}
        key={route.name}
        onClick={() => handleNavigationClick(route.path)}
      >
        {t(`routes.${route.name}`)}
      </StyledMenuItem>
    )),
    ...subNavSettings.map(route => (
      <StyledMenuItem key={route.name} onClick={() => handleNavigationClick(route.path)}>
        {t(`routes.${route.name}`)}
      </StyledMenuItem>
    )),
    <StyledMenuItem key="logout" onClick={handleLogout}>
      {t('auth.logout')}
      <NotifyIcon iconName="logout" />
    </StyledMenuItem>
  ];

  // Popper element on desktop / tablet, Drawer on mobile
  return (
    <>
      <IconButton
        ref={anchorRef}
        edge="start"
        color="primary"
        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ width: 60 }}
      >
        <NotifyIcon iconName={menuOpen ? 'close' : 'menu'} />
      </IconButton>
      <StyledPopper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        <SubNavigationWrapper theme={theme}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={menuOpen}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
              style={{ maxHeight: 'calc(100vh - 70px)', overflow: 'auto' }}
            >
              {navigationElements}
            </MenuList>
          </ClickAwayListener>
        </SubNavigationWrapper>
      </StyledPopper>
    </>
  );
};

SubNavigation.propTypes = {
  routes: T.arrayOf(T.shape({})).isRequired
};

export default SubNavigation;
