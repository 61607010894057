import { Box, Button, FormControl, Stack } from '@mui/material';
import { CheckCircleOutlineOutlined, HighlightOffOutlined, PauseCircleOutlineOutlined } from '@mui/icons-material';
import { purple, red } from '@mui/material/colors';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import T, { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormElements, NotifyTypo } from 'web-components';
import { updateStatusMachineIrisV3 } from '../../../../../redux/machines_v2/actions';
import { StyledContent, StyledHandlers, StyledNewDialog } from '../elements';
import { StyledContentGrid } from './elements';
import {
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  validateBlankSpace
} from '../../../../../helpers/utils';
import { MachineStatusIrisV3Schema } from '../../../../../attrs/formValidation';
import { getStatusMachine } from '../../../../../redux/machines/selectors';

const labelAmend = 'machines.machine_details';

const StatusTypes = [
  {
    type: 'idle',
    required: true
  },
  { type: 'running', required: true },
  { type: 'critical' }
];

const statusTypeToIconName = statusType =>
  ({
    idle: <PauseCircleOutlineOutlined sx={{ color: purple[500], fontSize: 25 }} />,
    running: <CheckCircleOutlineOutlined sx={{ color: '#59D66E', fontSize: 25 }} />,
    critical: <HighlightOffOutlined sx={{ color: red[500], fontSize: 25 }} />
  }[statusType]);

const StatusCard = ({ getError, getHelperText, handleBlur, handleChange, statusType, values }) => {
  const { t } = useTranslation();
  const valueFieldName = `${statusType.type}.value`;

  const handleChangeValue = event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
        {statusTypeToIconName(statusType.type)}
        <NotifyTypo.Body1 fontWeight="bold" sx={{ ml: 1 }}>
          {t(`machines.status.${statusType.type}`)}
        </NotifyTypo.Body1>
      </Box>
      <FormElements.TextField
        fullWidth
        error={getError(valueFieldName)}
        helperText={getHelperText(valueFieldName)}
        id={valueFieldName}
        label={t(`machines.form.machine_status_irisv3.${valueFieldName}`)}
        margin="normal"
        name={valueFieldName}
        onBlur={handleBlur}
        onChange={handleChangeValue}
        required={statusType.required}
        type="number"
        value={values[statusType.type].value}
      />
    </Box>
  );
};

StatusCard.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  values: T.shape({
    running: T.shape({}),
    critical: T.shape({}),
    idle: T.shape({})
  }).isRequired,
  statusType: T.shape({
    type: T.string,
    required: T.bool
  }).isRequired
};

export const EditStatusIrisV3 = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loadingState } = useSelector(getStatusMachine);

  const isSubmitting = isLoading(loadingState.status);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const submitForm = (values, formikHelpers) => {
    const payload = {
      variable_name: values.variable_name.toString(),
      idle_value: parseInt(values.idle.value, 10),
      running_value: parseInt(values.running.value, 10),
      error_value: parseInt(values.critical.value, 10)
    };

    try {
      dispatch(updateStatusMachineIrisV3(machineId, payload));
      handleClose(true);
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="status-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.handle_button`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <Formik
      initialValues={{
        variable_name: getDefaultFieldValue(data?.variable_name),
        idle: {
          value: getDefaultFieldValue(data?.idle_value)
        },
        running: {
          value: getDefaultFieldValue(data?.running_value)
        },
        critical: {
          value: getDefaultFieldValue(data?.error_value)
        }
      }}
      validationSchema={MachineStatusIrisV3Schema}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              handleClose();
            }}
            title={t(`${labelAmend}.status_modal_irisv3.title`)}
            subTitle={t(`${labelAmend}.status_modal_irisv3.subtitle`)}
            maxWidth="978px"
            maxHeight="508px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            shouldCloseOnOverlayClick={false}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <Stack direction="column" gap={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormElements.TextField
                        fullWidth
                        id="variable_name"
                        label={t('machines.form.machine_status_irisv3.variable_name')}
                        margin="normal"
                        name="variable_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.variable_name}
                        error={getError('variable_name')}
                        required
                      />
                    </Box>

                    <StyledContentGrid>
                      {StatusTypes.map(statusType => (
                        <Box key={`machine-status-card-${statusType.type}`}>
                          <StatusCard
                            getError={getError}
                            getHelperText={getHelperText}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            key={`machine-status-card-${statusType.type}`}
                            statusType={statusType}
                            values={values}
                          />
                        </Box>
                      ))}
                    </StyledContentGrid>
                  </Stack>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

EditStatusIrisV3.propTypes = {
  data: T.shape({
    idle_value: T.number,
    running_value: T.number,
    error_value: T.number,
    variable_name: T.string
  }),
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired
};

EditStatusIrisV3.defaultProps = {
  data: null,
  machineId: null
};
