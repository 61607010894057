const LOAD_CLONEABLE_MACHINES = 'MACHINES/LOAD_CLONEABLE_MACHINES';
const LOAD_CLONEABLE_MACHINES_DETAIL = 'MACHINES/CLONEABLE_MACHINES_DETAIL';
const LOAD_MACHINES_CLONEABLE_RESUME = 'MACHINES/LOAD_CLONEABLE_RESUME';
const CHANGE_MACHINES_CLONEABLE_ORDER_RESUME = 'MACHINES_CLONEABLE_RESUME/CHANGE_ORDER';
const LOAD_MACHINES_CLONEABLE_RESUME_WITH_FILTER = 'MACHINES/LOAD_ALL_FILTERED';
const CREATE_CLONEABLE_LICENSE = 'MACHINES/CREATE_CLONEABLE_LICENSE';

export {
  LOAD_CLONEABLE_MACHINES,
  LOAD_CLONEABLE_MACHINES_DETAIL,
  LOAD_MACHINES_CLONEABLE_RESUME,
  CHANGE_MACHINES_CLONEABLE_ORDER_RESUME,
  LOAD_MACHINES_CLONEABLE_RESUME_WITH_FILTER,
  CREATE_CLONEABLE_LICENSE
};
