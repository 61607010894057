import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMachineWithoutGateway } from 'redux/gateways/selectors';
import Footer from 'components/DataTable/Footer';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'web-components';
import { Box, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { loadMachineWithoutGateway } from '../../redux/gateways/actions';
import { isLoading, isSuccess } from '../../helpers/utils';

export function MachineWithoutGateway() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { loadingState: machineWithoutGatewayState, data: machineWithoutGatewayResponse } =
    useSelector(getMachineWithoutGateway);
  const machineWithoutGatewayIsLoading = isLoading(machineWithoutGatewayState.status);
  const machineWithoutGatewayLoaded = isSuccess(machineWithoutGatewayState.status);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [limit, setLimit] = useState(0);
  const [gatewayList, setGatewayList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    dispatch(loadMachineWithoutGateway());
  }, [dispatch]);

  useEffect(() => {
    if (machineWithoutGatewayLoaded) {
      setCurrentPage(machineWithoutGatewayResponse.page || 0);
      setTotalPages(machineWithoutGatewayResponse.total_pages || 0);
      setTotalLength(machineWithoutGatewayResponse.total_length || 0);
      setLimit(machineWithoutGatewayResponse.limit || 0);
      setGatewayList(machineWithoutGatewayResponse.data);
    }
  }, [machineWithoutGatewayLoaded]);

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(loadMachineWithoutGateway(rowsPerPage, page));
  };

  const machineWithoutGatewayTableLabel = 'dashboard.tabs.gateway.machine_without_gateway.table';
  const columnsMachineWithoutGateway = [
    {
      id: 'comission_id_number',
      label: t(`${machineWithoutGatewayTableLabel}.comission_id_number`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.commission_number || row?.id_number)
    },
    {
      id: 'name',
      label: t(`${machineWithoutGatewayTableLabel}.machine_name`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.name)
    },
    {
      id: 'organization_name',
      label: t(`${machineWithoutGatewayTableLabel}.customer_name`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.organization_name)
    },
    {
      id: 'machine_creation_date',
      label: t(`${machineWithoutGatewayTableLabel}.machine_creation_date`),
      sortable: false,
      cellRenderer: row => {
        const dt = new Date(row?.created_at);
        return (
          <>
            {t(`${machineWithoutGatewayTableLabel}.machine_creation_date_value`, {
              date: dt.toLocaleDateString(),
              time: dt.toLocaleTimeString(),
              interpolation: { escapeValue: false }
            })}
          </>
        );
      }
    }
  ];

  const handleSelectRow = row => {
    const routePath =
      row.organization_id !== null ? `/customers/${row.organization_id}/machines/${row.id}` : `/machines/${row.id}`;
    history.push(routePath);
  };

  const propsMachineWithoutGateway = {
    dataSelectorTable: 'machine-without-gateway-table',
    dataSelectorRows: 'machine-without-gateway-table-row',
    isLoading: machineWithoutGatewayIsLoading,
    columns: columnsMachineWithoutGateway,
    rows: gatewayList,
    onClick: handleSelectRow,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={totalPages}
        totalLength={totalLength}
        totalItemsPerPage={limit}
        onChange={handleChangePage}
      />
    )
  };

  const gatewayLostConnGeneralLabel = 'dashboard.tabs.gateway.machine_without_gateway';
  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 1.5
      }}
    >
      <Stack direction="column" spacing={2}>
        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {t(`${gatewayLostConnGeneralLabel}.caption`)}
        </Typography>
        <DataTable hover {...propsMachineWithoutGateway} />
      </Stack>
    </Box>
  );
}
