import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { FormElements, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChangeNumberHandler,
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  isNullUndefinedOrEmpty
} from 'helpers/utils';
import { Formik } from 'formik';
import { StyledContentGrid, StyledContentGridTrigger, StyledHandlers } from './elements';
import { IrisSensorDataSchema } from '../../../../../attrs/formValidation';
import { getIrisSensors, getSensorsConfigForIris } from '../../../../../redux/sensor/selectors';
import { createUpdateSensorIris, loadSensorConfigsForIris } from '../../../../../redux/sensor/actions';
import {
  DECIMAL_PLACES,
  ENGINEERING_DATA_TYPES_IRIS_V3,
  PERIODS_LIST_IN_SECONDS,
  SENSOR_TYPE_IRIS_V3_LIST
} from '../../../../../attrs/sensorConfig';
import { COLOR_PRIMARY } from '../../../../../attrs/colors';
import { StyledContent, StyledGridItem, StyledNewDialog } from '../elements';

const labelAmend = 'machines.form.sensor';

const IrisSensor = ({ open, handleClose, machineId, irisData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingIrisSensorsStatus: loadingState } = useSelector(getIrisSensors);
  const { loadingConfigsStatus, sensorConfigList } = useSelector(getSensorsConfigForIris);

  const [variablesFromSelectedConfig, setVariablesFromSelectedConfig] = useState([]);

  useEffect(() => {
    dispatch(loadSensorConfigsForIris());
  }, [dispatch]);

  const getEngineeringType = data => {
    const maxVal = data?.engineering_unit?.max?.value;
    const minVal = data?.engineering_unit?.min?.value;
    if (!isNullUndefinedOrEmpty(maxVal) && !isNullUndefinedOrEmpty(minVal) && !(maxVal === 0 && minVal === 0)) {
      return ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE;
    }
    return ENGINEERING_DATA_TYPES_IRIS_V3.VARIABLE_NAME;
  };

  const isLoadingConfigs = isLoading(loadingConfigsStatus.status);
  const isSubmitting = isLoading(loadingState.status);

  /**
   * Filters distinct items from an array.
   *
   * @param {Array} arr - The array to filter.
   * @returns {Array} A new array with distinct items.
   */
  const filterDistinct = arr => [...new Set(arr)];

  const handleSensorNameChange =
    (setFieldValue, setFieldTouched) =>
    /**
     * Get selected config and other fields
     *
     * @param {Event} event
     * @param {Object} newValue
     * @param {string} newValue.decimal_places
     * @param {string} newValue.description
     * @param {Object} newValue.engineering_unit
     * @param {Object} newValue.engineering_unit.max
     * @param {string} newValue.engineering_unit.max.source
     * @param {string} newValue.engineering_unit.max.value
     * @param {Object} newValue.engineering_unit.min
     * @param {string} newValue.engineering_unit.min.source
     * @param {string} newValue.engineering_unit.min.value
     * @param {boolean} newValue.high_trigger
     * @param {number} newValue.id
     * @param {number} newValue.interval
     * @param {boolean} newValue.low_trigger
     * @param {number} newValue.multiplication_factor
     * @param {string} newValue.tag
     * @param {string} newValue.type
     * @param {string} newValue.unit
     * @param {string[]} newValue.variable_names
     * @returns {void}
     */ (event, newValue) => {
      if (newValue) {
        setVariablesFromSelectedConfig(filterDistinct(newValue.variable_names));

        const setAllFields = Promise.all([
          setFieldValue('name', getDefaultFieldValue(newValue.description, '')),
          setFieldValue('decimal_places', getDefaultFieldValue(newValue.decimal_places, '')),
          setFieldValue(
            'engineering_unit.max.source',
            getDefaultFieldValue(newValue.engineering_unit?.max?.source, '')
          ),
          setFieldValue('engineering_unit.max.value', getDefaultFieldValue(newValue.engineering_unit?.max?.value, '')),
          setFieldValue(
            'engineering_unit.min.source',
            getDefaultFieldValue(newValue.engineering_unit?.min?.source, '')
          ),
          setFieldValue('engineering_unit.selected_type', getEngineeringType(newValue)),
          setFieldValue('engineering_unit.min.value', getDefaultFieldValue(newValue.engineering_unit?.min?.value, '')),
          setFieldValue('high_trigger', getDefaultFieldValue(newValue.high_trigger, true)),
          setFieldValue('low_trigger', getDefaultFieldValue(newValue.low_trigger, true)),
          setFieldValue('interval', getDefaultFieldValue(newValue.interval, '')),
          setFieldValue('multiplication_factor', getDefaultFieldValue(newValue.multiplication_factor, '')),
          setFieldValue('description', getDefaultFieldValue(newValue.description, '')),
          setFieldValue('type', getDefaultFieldValue(newValue.type, '')),
          setFieldValue('unit', getDefaultFieldValue(newValue.unit, '')),

          newValue.variable_names?.length === 1
            ? setFieldValue('variable_name', newValue.variable_names[0])
            : setFieldValue('variable_name', '')
        ]);

        setAllFields.then(res => {
          Object.keys(res[0]).forEach(key => {
            setFieldTouched(key, true);
          });
        });
      }
    };

  const handleVariableNameChange = setFieldValue => (event, newValue) => {
    if (newValue) {
      setFieldValue('variable_name', newValue);
    }
  };

  const handleRadioEngineeringUnitTypeChange = setFieldValue => event => {
    setFieldValue(event.target.name, event.target.value);
  };

  const handleRadioTriggerChange = (setFieldValue, field) => event => {
    setFieldValue(`${field}`, event.target.value === 'true');
  };

  const submitForm = (values, formikHelpers) => {
    const payload = values;
    if (payload.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE) {
      delete payload.engineering_unit.max.source;
      delete payload.engineering_unit.min.source;
    } else if (payload.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.VARIABLE_NAME) {
      delete payload.engineering_unit.max.value;
      delete payload.engineering_unit.min.value;
    }
    dispatch(createUpdateSensorIris(machineId, payload));
    handleClose(true);
    formikHelpers.resetForm();
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('default_actions.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t('default_actions.save')}
      </Button>
    </StyledHandlers>
  );

  return (
    <Formik
      initialValues={{
        decimal_places: getDefaultFieldValue(irisData?.decimal_places, ''),
        engineering_unit: {
          max: {
            source: getDefaultFieldValue(irisData?.engineering_unit?.max?.source, ''),
            value: getDefaultFieldValue(irisData?.engineering_unit?.max?.value, '')
          },
          min: {
            source: getDefaultFieldValue(irisData?.engineering_unit?.min?.source, ''),
            value: getDefaultFieldValue(irisData?.engineering_unit?.min?.value, '')
          },
          selected_type: getEngineeringType(irisData)
        },
        high_trigger: getDefaultFieldValue(irisData?.high_trigger, true),
        id: getDefaultFieldValue(irisData?.id, null),
        interval: getDefaultFieldValue(irisData?.interval, ''),
        low_trigger: getDefaultFieldValue(irisData?.low_trigger, true),
        multiplication_factor: getDefaultFieldValue(irisData?.multiplication_factor, ''),
        name: getDefaultFieldValue(irisData?.name, ''),
        type: getDefaultFieldValue(irisData?.type, ''),
        unit: getDefaultFieldValue(irisData?.unit, ''),
        variable_name: getDefaultFieldValue(irisData?.variable_name || '')
      }}
      validationSchema={IrisSensorDataSchema}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({
        setFieldValue,
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        handleSubmit,
        resetForm,
        dirty,
        setFieldTouched
      }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.iris_sensor_data.${irisData && irisData.id ? 'title_edit' : 'title'}`)}
            subTitle={t(`${labelAmend}.iris_sensor_data.subtitle`)}
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            maxWidth="978px"
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGrid>
                    <StyledGridItem>
                      <Autocomplete
                        id="sensor-name-fs"
                        freeSolo
                        inputValue={values.name}
                        options={sensorConfigList}
                        getOptionLabel={option => option?.description || ''}
                        loading={isLoadingConfigs}
                        onChange={handleSensorNameChange(setFieldValue, setFieldTouched)}
                        onInputChange={(event, newInputValue, reason) => {
                          if (reason === 'clear') {
                            setFieldValue('name', '');
                          }
                        }}
                        renderInput={params => (
                          <FormElements.TextField
                            helperText={getHelperText('name')}
                            error={getError('name')}
                            value={values.name}
                            fullWidth
                            label={t(`${labelAmend}.name`)}
                            onChange={handleChange}
                            name="name"
                            id="name"
                            size="small"
                            margin="none"
                            onBlur={handleBlur}
                            required
                            {...params}
                          />
                        )}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <Autocomplete
                        id="sensor-variable-name-fs"
                        freeSolo
                        options={variablesFromSelectedConfig}
                        value={values.variable_name}
                        onChange={handleVariableNameChange(setFieldValue)}
                        onInputChange={(event, newInputValue, reason) => {
                          if (reason === 'clear') {
                            setFieldValue('variable_name', '');
                          }
                        }}
                        renderInput={params => (
                          <FormElements.TextField
                            helperText={getHelperText('variable_name')}
                            error={getError('variable_name')}
                            value={values.variable_name}
                            fullWidth
                            label={t(`${labelAmend}.iris_sensor_data.variable_name`)}
                            onChange={handleChange}
                            name="variable_name"
                            id="variable_name"
                            size="small"
                            margin="none"
                            onBlur={handleBlur}
                            required
                            {...params}
                          />
                        )}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.Select
                        helperText={getHelperText('type')}
                        error={getError('type')}
                        value={values.type}
                        fullWidth
                        label={t(`${labelAmend}.custom_icon_type`)}
                        handleChange={handleChange}
                        name="type"
                        id="type"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required
                        withEmptyField
                      >
                        {SENSOR_TYPE_IRIS_V3_LIST.map(iconType => (
                          <option key={iconType.c} value={iconType.c}>
                            {t(`${iconType.t}`)}
                          </option>
                        ))}
                      </FormElements.Select>
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.Select
                        helperText={getHelperText('interval')}
                        error={getError('interval')}
                        value={values.interval}
                        fullWidth
                        label={t(`${labelAmend}.custom_period`)}
                        handleChange={getChangeNumberHandler(setFieldValue)('interval')}
                        name="interval"
                        id="interval"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required
                        withEmptyField
                      >
                        {PERIODS_LIST_IN_SECONDS.map(period => (
                          <option key={period.value} value={period.value}>
                            {t(`machines.form.sensor.periods.${period.key}`)}
                          </option>
                        ))}
                      </FormElements.Select>
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        helperText={getHelperText('unit')}
                        error={getError('unit')}
                        value={values.unit}
                        fullWidth
                        label={t(`${labelAmend}.custom_unit`)}
                        onChange={handleChange}
                        name="unit"
                        id="unit"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        helperText={getHelperText('multiplication_factor')}
                        error={getError('multiplication_factor')}
                        value={values.multiplication_factor}
                        fullWidth
                        label={t(`${labelAmend}.multiplication_factor`)}
                        onChange={handleChange}
                        name="multiplication_factor"
                        id="multiplication_factor"
                        size="small"
                        margin="none"
                        type="number"
                        onBlur={handleBlur}
                        required
                        InputProps={{
                          inputProps: {
                            min: '0',
                            max: '100000',
                            step: '1'
                          }
                        }}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.Select
                        helperText={getHelperText('decimal_places')}
                        error={getError('decimal_places')}
                        value={values.decimal_places}
                        fullWidth
                        label={t(`${labelAmend}.decimal_place`)}
                        handleChange={handleChange}
                        name="decimal_places"
                        id="decimal_places"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required
                        withEmptyField
                      >
                        {DECIMAL_PLACES.map(num => (
                          <option key={`${num.toString()}`} value={`${num.toString()}`}>
                            {num}
                          </option>
                        ))}
                      </FormElements.Select>
                    </StyledGridItem>
                  </StyledContentGrid>
                  <StyledContentGrid>
                    <NotifyTypo.Heading2 style={{ fontSize: 16, marginTop: '16px' }}>
                      {t('machines.form.sensor.iris_sensor_data.engineering_unit')}
                    </NotifyTypo.Heading2>
                  </StyledContentGrid>
                  <StyledContentGrid>
                    <StyledGridItem>
                      <label htmlFor={`${labelAmend}.iris_sensor_data.variable_name`} style={{ fontSize: '16px' }}>
                        <FormElements.RadioButton
                          margin="normal"
                          name="engineering_unit.selected_type"
                          activeColor={COLOR_PRIMARY}
                          onChange={handleRadioEngineeringUnitTypeChange(setFieldValue)}
                          value={ENGINEERING_DATA_TYPES_IRIS_V3.VARIABLE_NAME}
                          checked={
                            values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.VARIABLE_NAME
                          }
                        />
                        {t(`${labelAmend}.iris_sensor_data.variable_name`)}
                      </label>
                    </StyledGridItem>
                    <StyledGridItem>
                      <label htmlFor={`${labelAmend}.iris_sensor_data.fixed_value`} style={{ fontSize: '16px' }}>
                        <FormElements.RadioButton
                          margin="normal"
                          name="engineering_unit.selected_type"
                          activeColor={COLOR_PRIMARY}
                          onChange={handleRadioEngineeringUnitTypeChange(setFieldValue)}
                          value={ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE}
                          checked={values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE}
                        />
                        {t(`${labelAmend}.iris_sensor_data.fixed_value`)}
                      </label>
                    </StyledGridItem>
                  </StyledContentGrid>
                  <StyledContentGrid>
                    <StyledGridItem>
                      <FormElements.TextField
                        disabled={values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE}
                        helperText={getHelperText('engineering_unit.max.source')}
                        error={getError('engineering_unit.max.source')}
                        value={values.engineering_unit.max.source}
                        fullWidth
                        label={t(`${labelAmend}.iris_sensor_data.max_eng_unit_address`)}
                        onChange={handleChange}
                        name="engineering_unit.max.source"
                        id="engineering_unit.max.source"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required={
                          values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.VARIABLE_NAME
                        }
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        disabled={
                          values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.VARIABLE_NAME
                        }
                        helperText={getHelperText('engineering_unit.max.value')}
                        error={getError('engineering_unit.max.value')}
                        value={values.engineering_unit.max.value}
                        fullWidth
                        label={t(`${labelAmend}.iris_sensor_data.max_eng_unit_value`)}
                        onChange={handleChange}
                        name="engineering_unit.max.value"
                        id="engineering_unit.max.value"
                        size="small"
                        margin="none"
                        type="number"
                        onBlur={handleBlur}
                        required={values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        disabled={values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE}
                        helperText={getHelperText('engineering_unit.min.source')}
                        error={getError('engineering_unit.min.source')}
                        value={values.engineering_unit.min.source}
                        fullWidth
                        label={t(`${labelAmend}.iris_sensor_data.min_eng_unit_address`)}
                        onChange={handleChange}
                        name="engineering_unit.min.source"
                        id="engineering_unit.min.source"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required={
                          values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.VARIABLE_NAME
                        }
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        disabled={
                          values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.VARIABLE_NAME
                        }
                        helperText={getHelperText('engineering_unit.min.value')}
                        error={getError('engineering_unit.min.value')}
                        value={values.engineering_unit.min.value}
                        fullWidth
                        label={t(`${labelAmend}.iris_sensor_data.min_eng_unit_value`)}
                        onChange={handleChange}
                        name="engineering_unit.min.value"
                        id="engineering_unit.min.value"
                        size="small"
                        margin="none"
                        type="number"
                        onBlur={handleBlur}
                        required={values.engineering_unit.selected_type === ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE}
                      />
                    </StyledGridItem>
                  </StyledContentGrid>
                  <StyledContentGrid>
                    <NotifyTypo.Heading2 style={{ fontSize: 16, marginTop: '16px' }}>
                      {t('machines.form.sensor.iris_sensor_data.trigger_alert')}
                    </NotifyTypo.Heading2>
                  </StyledContentGrid>
                  <NotifyTypo.Heading2Regular style={{ fontSize: 14 }} color="text.secondary">
                    {t('machines.form.sensor.iris_sensor_data.trigger_alert_caption')}
                  </NotifyTypo.Heading2Regular>
                  <StyledContentGrid style={{ margin: 0, marginTop: '12px' }}>
                    <StyledContentGridTrigger>
                      <StyledGridItem>
                        <span style={{ fontSize: 16 }}>
                          {t('machines.form.sensor.iris_sensor_data.high_trigger_alert')}
                        </span>
                      </StyledGridItem>
                      <StyledGridItem>
                        <label htmlFor="default_actions.enabled" style={{ fontSize: '16px' }}>
                          <FormElements.RadioButton
                            margin="normal"
                            name="radio_high_trigger"
                            activeColor={COLOR_PRIMARY}
                            onChange={handleRadioTriggerChange(setFieldValue, 'high_trigger')}
                            value="true"
                            checked={values.high_trigger}
                          />
                          {t('default_actions.enabled')}
                        </label>
                      </StyledGridItem>
                      <StyledGridItem>
                        <label htmlFor="default_actions.disabled" style={{ fontSize: '16px' }}>
                          <FormElements.RadioButton
                            margin="normal"
                            name="radio_high_trigger"
                            activeColor={COLOR_PRIMARY}
                            onChange={handleRadioTriggerChange(setFieldValue, 'high_trigger')}
                            value="false"
                            checked={!values.high_trigger}
                          />
                          {t('default_actions.disabled')}
                        </label>
                      </StyledGridItem>
                    </StyledContentGridTrigger>
                  </StyledContentGrid>
                  <StyledContentGrid style={{ margin: 0 }}>
                    <StyledContentGridTrigger>
                      <StyledGridItem>
                        <span style={{ fontSize: 16 }}>
                          {t('machines.form.sensor.iris_sensor_data.low_trigger_alert')}
                        </span>
                      </StyledGridItem>
                      <StyledGridItem>
                        <label htmlFor="default_actions.enabled" style={{ fontSize: '16px' }}>
                          <FormElements.RadioButton
                            margin="normal"
                            name="radio_low_trigger"
                            activeColor={COLOR_PRIMARY}
                            onChange={handleRadioTriggerChange(setFieldValue, 'low_trigger')}
                            value="true"
                            checked={values.low_trigger}
                          />
                          {t('default_actions.enabled')}
                        </label>
                      </StyledGridItem>
                      <StyledGridItem>
                        <label htmlFor="default_actions.disabled" style={{ fontSize: '16px' }}>
                          <FormElements.RadioButton
                            margin="normal"
                            name="radio_low_trigger"
                            activeColor={COLOR_PRIMARY}
                            onChange={handleRadioTriggerChange(setFieldValue, 'low_trigger')}
                            value="false"
                            checked={!values.low_trigger}
                          />
                          {t('default_actions.disabled')}
                        </label>
                      </StyledGridItem>
                    </StyledContentGridTrigger>
                  </StyledContentGrid>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

IrisSensor.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  machineId: T.string.isRequired,
  irisData: T.shape({
    created_at: T.string,
    created_by: T.shape({}),
    decimal_places: T.string,
    engineering_unit: T.shape({
      max: T.shape({
        source: T.string,
        value: T.number
      }),
      min: T.shape({
        source: T.string,
        value: T.number
      })
    }),
    high_trigger: T.bool,
    icon_type: T.string,
    id: T.number,
    interval: T.number,
    is_active: T.bool,
    last_updated_by: T.shape({}),
    low_trigger: T.bool,
    multiplication_factor: T.number,
    name: T.string,
    type: T.string,
    unit: T.string,
    updated_at: T.string,
    variable_name: T.string
  })
};

IrisSensor.defaultProps = {
  irisData: null
};

export default IrisSensor;
