import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';

import { GATEWAY_DEFAULT_LIST_ORDER, GATEWAY_DEFAULT_LIST_SORT } from 'attrs/gateways';
import { enhanceWithResetActions, loadingStateReducer } from '../utils';
import {
  CHANGE_GATEWAYS_FILTER_RESUME,
  CHANGE_GATEWAYS_ORDER_RESUME,
  LOAD_GATEWAY,
  LOAD_GATEWAYS,
  LOAD_GATEWAYS_ASSIGNABLE,
  LOAD_GATEWAYS_ASSIGNABLE_MACHINE,
  LOAD_GATEWAYS_ASSIGNABLE_WITH_FILTER,
  LOAD_GATEWAYS_BY_ORGANIZATION,
  LOAD_GATEWAYS_LIST_V2,
  LOAD_GATEWAYS_LIST_WITH_FILTER,
  LOAD_GATEWAYS_LOST_SIGNAL,
  LOAD_GATEWAYS_MACHINE_DETAIL,
  LOAD_GATEWAYS_RESUME,
  LOAD_GATEWAYS_RESUME_DETAIL,
  LOAD_GATEWAYS_RESUME_WITH_FILTER,
  LOAD_GATEWAYS_VERSIONS,
  LOAD_GATEWAYS_WITH_ISSUES,
  LOAD_GATEWAY_MACHINES,
  LOAD_GATEWAY_MACHINES_BY_GATEWAY,
  LOAD_MACHINE_WITHOUT_GATEWAY,
  RESET_GATEWAY,
  RESET_GATEWAYS,
  UNLINK_GATEWAY_BY_ORGANIZATION_ID,
  UPDATE_GATEWAY,
  UPDATE_GATEWAY_BIND,
  UPDATE_GATEWAY_VERSION
} from './constants';

const selectedReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_GATEWAY),
  gatewayLoadingState: enhanceWithResetActions([UPDATE_GATEWAY_VERSION])(loadingStateReducer([LOAD_GATEWAY])),
  data: typeToReducer(
    {
      [LOAD_GATEWAY]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_GATEWAY]: {
        SUCCESS: (data, { payload }) => (data.serial_number === payload.serial_number ? { ...payload } : data)
      },
      [UPDATE_GATEWAY_VERSION]: {
        SUCCESS: (data, { payload }) => (data.serial_number === payload.serial_number ? { ...payload } : data)
      }
    },
    {}
  )
});

const gatewaysByMachine = combineReducers({
  loadingState: loadingStateReducer(LOAD_GATEWAYS_MACHINE_DETAIL),
  data: typeToReducer(
    {
      [LOAD_GATEWAYS_MACHINE_DETAIL]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const gatewaysVersions = combineReducers({
  loadingState: loadingStateReducer(LOAD_GATEWAYS_VERSIONS),
  data: typeToReducer(
    {
      [LOAD_GATEWAYS_VERSIONS]: {
        SUCCESS: (data, { payload }) => (data.serial_number === payload.serial_number ? { ...payload } : data)
      }
    },
    []
  )
});

const gatewaysList = combineReducers({
  loadingState: loadingStateReducer(LOAD_GATEWAYS_LIST_V2),
  data: typeToReducer(
    {
      [LOAD_GATEWAYS_LIST_V2]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_GATEWAYS_LIST_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

const gatewaysByOrganization = combineReducers({
  loadingState: loadingStateReducer(
    LOAD_GATEWAYS_BY_ORGANIZATION,
    UPDATE_GATEWAY_BIND,
    UNLINK_GATEWAY_BY_ORGANIZATION_ID
  ),
  data: typeToReducer(
    {
      [LOAD_GATEWAYS_BY_ORGANIZATION]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_GATEWAYS_RESUME_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

const gatewaysAssignable = combineReducers({
  data: typeToReducer(
    {
      [LOAD_GATEWAYS_ASSIGNABLE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_GATEWAYS_ASSIGNABLE_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

const gatewaysAssignableMachine = combineReducers({
  loadingState: loadingStateReducer(LOAD_GATEWAYS_ASSIGNABLE_MACHINE),
  data: typeToReducer(
    {
      [LOAD_GATEWAYS_ASSIGNABLE_MACHINE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

const unLinkGatewayGatewayByOrganization = combineReducers({
  loadingState: loadingStateReducer(UNLINK_GATEWAY_BY_ORGANIZATION_ID),
  data: typeToReducer(
    {
      [UNLINK_GATEWAY_BY_ORGANIZATION_ID]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

const gatewayLostSignal = combineReducers({
  loadingState: loadingStateReducer(LOAD_GATEWAYS_LOST_SIGNAL),
  data: typeToReducer(
    {
      [LOAD_GATEWAYS_LOST_SIGNAL]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

const machineWithoutGateway = combineReducers({
  loadingState: loadingStateReducer(LOAD_MACHINE_WITHOUT_GATEWAY),
  data: typeToReducer(
    {
      [LOAD_MACHINE_WITHOUT_GATEWAY]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

const gatewaysWithIssues = combineReducers({
  loadingState: loadingStateReducer(LOAD_GATEWAYS_WITH_ISSUES),
  data: typeToReducer(
    {
      [LOAD_GATEWAYS_WITH_ISSUES]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});

export default combineReducers({
  loadingState: enhanceWithResetActions([RESET_GATEWAYS])(
    loadingStateReducer([LOAD_GATEWAYS, LOAD_GATEWAYS_RESUME, LOAD_GATEWAYS_RESUME_DETAIL])
  ),
  updateLoadingState: enhanceWithResetActions([LOAD_GATEWAY, LOAD_GATEWAYS_RESUME, LOAD_GATEWAYS_RESUME_DETAIL])(
    loadingStateReducer([UPDATE_GATEWAY])
  ),
  data: enhanceWithResetActions([RESET_GATEWAYS])(
    typeToReducer(
      {
        [LOAD_GATEWAYS]: {
          SUCCESS: (state, { payload }) => payload
        },
        [LOAD_GATEWAYS_RESUME_DETAIL]: {
          SUCCESS: (state, { payload }) => payload
        },
        [UPDATE_GATEWAY]: {
          SUCCESS: (data, { payload }) =>
            data.map(gateway => (gateway.serial_number === payload.serial_number ? { ...payload } : gateway))
        }
      },
      []
    )
  ),
  dataResumed: enhanceWithResetActions([RESET_GATEWAYS])(
    typeToReducer(
      {
        [LOAD_GATEWAYS_RESUME]: {
          SUCCESS: (state, { payload }) => payload
        }
      },
      {}
    )
  ),
  selected: enhanceWithResetActions([RESET_GATEWAY])(selectedReducer),
  gatewaysByMachine: enhanceWithResetActions([LOAD_GATEWAYS_MACHINE_DETAIL])(gatewaysByMachine),
  gatewaysVersions: enhanceWithResetActions([LOAD_GATEWAYS_VERSIONS])(gatewaysVersions),
  gatewaysAssignable: enhanceWithResetActions([LOAD_GATEWAYS_ASSIGNABLE])(gatewaysAssignable),
  gatewaysAssignableMachine: enhanceWithResetActions([LOAD_GATEWAYS_ASSIGNABLE_MACHINE])(gatewaysAssignableMachine),
  unLinkGatewayGatewayByOrganization: enhanceWithResetActions([UNLINK_GATEWAY_BY_ORGANIZATION_ID])(
    unLinkGatewayGatewayByOrganization
  ),
  gatewaysList: enhanceWithResetActions([LOAD_GATEWAYS_LIST_V2])(gatewaysList),
  gatewaysListByOrganization: enhanceWithResetActions([LOAD_GATEWAYS_BY_ORGANIZATION])(gatewaysByOrganization),
  gatewaySortOrder: enhanceWithResetActions(
    {
      [CHANGE_GATEWAYS_ORDER_RESUME]: (gatewaySortOrder, { sort, order }) => ({
        sort,
        order
      })
    },
    { sort: GATEWAY_DEFAULT_LIST_SORT, order: GATEWAY_DEFAULT_LIST_ORDER }
  ),
  gatewayFilter: enhanceWithResetActions(
    {
      [CHANGE_GATEWAYS_FILTER_RESUME]: (gatewayFilter, { configured, organizationName }) => ({
        configured,
        organizationName
      })
    },
    { configured: null, organizationName: null }
  ),
  machines: enhanceWithResetActions([RESET_GATEWAY])(
    typeToReducer(
      {
        [LOAD_GATEWAY_MACHINES]: {
          SUCCESS: (state, { payload }) => payload
        },
        [LOAD_GATEWAY_MACHINES_BY_GATEWAY]: {
          SUCCESS: (state, { payload }) => payload
        }
      },
      []
    )
  ),
  gatewayLostSignal: enhanceWithResetActions([LOAD_GATEWAYS_LOST_SIGNAL])(gatewayLostSignal),
  machineWithoutGateway: enhanceWithResetActions([LOAD_MACHINE_WITHOUT_GATEWAY])(machineWithoutGateway),
  gatewaysWithIssues: enhanceWithResetActions([LOAD_GATEWAYS_WITH_ISSUES])(gatewaysWithIssues),
  loadingUpdateVersionState: loadingStateReducer(UPDATE_GATEWAY_VERSION)
});
