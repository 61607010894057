import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'prop-types';
import ManagementUnits from './ManagementUnits';
import { loadSensorUnits } from '../../../../../redux/sensor/actions';
import { getSensorUnits } from '../../../../../redux/sensor/selectors';
import { PlcType } from '../../../../../attrs/plcConfigValues';

const ConnectedManagementUnits = ({ machineId, plcType }) => {
  const dispatch = useDispatch();
  const { loadingSensorsUnitsStatus, sensorUnitsData } = useSelector(getSensorUnits);

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(loadSensorUnits(machineId));
    }
  }, [machineId, dispatch, plcType]);

  if (plcType.plc_type !== PlcType.IRIS_V3) {
    return null;
  }

  return (
    <ManagementUnits
      loadingState={loadingSensorsUnitsStatus}
      reloadAction={() => dispatch(loadSensorUnits(machineId))}
      sensorUnits={sensorUnitsData}
      machineId={machineId}
    />
  );
};

ConnectedManagementUnits.propTypes = {
  machineId: T.string,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

ConnectedManagementUnits.defaultProps = {
  machineId: null
};

export default ConnectedManagementUnits;
