import { put, takeLatest } from 'redux-saga/effects';
import { actionStatus, getError, statusAction } from 'redux/utils';

import { updateBatchDetails, updateBatchStatus, updateBatchTarget } from 'api/batch';
import { UPDATE_DETAILS_BATCH, UPDATE_STATUS_BATCH, UPDATE_TARGET_BATCH } from './constants';
import { LOAD_BATCH_RECORDS_MACHINE } from '../machines/constants';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';

function* handleUpdateBatchDetails({ machineId, payload }) {
  yield put(statusAction(UPDATE_DETAILS_BATCH, actionStatus.START));
  try {
    const response = yield updateBatchDetails(machineId, payload);
    yield put({ type: LOAD_BATCH_RECORDS_MACHINE, machineId });
    yield put(statusAction(UPDATE_DETAILS_BATCH, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(UPDATE_DETAILS_BATCH, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateBatchStatus({ machineId, payload }) {
  yield put(statusAction(UPDATE_STATUS_BATCH, actionStatus.START));
  try {
    const response = yield updateBatchStatus(machineId, payload);
    yield put({ type: LOAD_BATCH_RECORDS_MACHINE, machineId });
    yield put(statusAction(UPDATE_STATUS_BATCH, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(UPDATE_STATUS_BATCH, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateBatchTarget({ machineId, payload }) {
  yield put(statusAction(UPDATE_TARGET_BATCH, actionStatus.START));
  try {
    const response = yield updateBatchTarget(machineId, payload);
    yield put({ type: LOAD_BATCH_RECORDS_MACHINE, machineId });
    yield put(statusAction(UPDATE_TARGET_BATCH, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(UPDATE_TARGET_BATCH, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(UPDATE_DETAILS_BATCH, handleUpdateBatchDetails);
  yield takeLatest(UPDATE_STATUS_BATCH, handleUpdateBatchStatus);
  yield takeLatest(UPDATE_TARGET_BATCH, handleUpdateBatchTarget);
}
