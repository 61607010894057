import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadGatewaysList } from '../../redux/gateways/actions';
import Gateways from './Gateways';
import { getGatewaysList } from '../../redux/gateways/selectors';

const ConnectedGatewaysContent = () => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getGatewaysList);

  useEffect(() => {
    dispatch(loadGatewaysList());
  }, [dispatch]);

  return <Gateways loadingState={loadingState} reloadAction={() => dispatch(loadGatewaysList())} gateways={data} />;
};

export default ConnectedGatewaysContent;
