import { MachineTypes } from 'web-components';

const machineTypes = MachineTypes.filter(({ type }) => type !== 'unknown');

const initialMachineValues = {
  type: machineTypes[0].value,
  commission_number: '',
  configuration: {
    plc: {
      address: '',
      type: '',
      port: undefined,
      rack: 0,
      slot: undefined,
      batch: {
        details: {
          batch_id: undefined,
          batch_size: undefined,
          cycles: undefined,
          est_energy_consumption: undefined,
          max_allowed_temperature: undefined,
          progress: undefined,
          recipe: undefined,
          total_grinding_time: undefined
        },
        status: {
          cancelled: {
            address: '',
            value: ''
          },
          complete: {
            address: '',
            value: ''
          },
          in_operation: {
            address: '',
            value: ''
          }
        },
        target: {
          cycles: {
            target: '',
            value: undefined
          },
          grinding_energy: {
            target: '',
            value: undefined
          },
          specific_energy: {
            target: '',
            value: undefined
          },
          time: {
            hours: '',
            minutes: '',
            value: undefined
          },
          type: ''
        }
      },
      metrics: [],
      errors: [],
      statuses: {
        datatype: '',
        running: {
          address: '',
          value: ''
        },
        warning: {
          address: '',
          value: ''
        },
        critical: {
          address: '',
          value: ''
        },
        maintenance: {
          address: '',
          value: ''
        },
        idle: {
          address: '',
          value: ''
        }
      }
    }
  },
  mechanical_config: {
    bead_material: '',
    beads_filling_level: undefined,
    beads_size_1: undefined,
    beads_size_2: undefined,
    grinding_technology: '',
    inner_liner_material: '',
    samba_system: false,
    sieve_size: undefined
  },
  enabled_config: {
    batch: true
  }
};

export default initialMachineValues;
