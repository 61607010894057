import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { NewDialog } from 'web-components';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { loadGatewaysVersions, updateGatewayVersion } from 'redux/gateways/actions';
import { getGatewayVersions, getGatewayVersionsState } from 'redux/gateways/selectors';
import { isError, isSuccess } from 'helpers/utils';
import { gatewaysV2GetByIdAction } from 'redux/gateways_v2/actions';
import { getGateways } from 'redux/rootSelectors';

export default function GatewayUpdaterDialog({ open, handleClose, gatewayId, serialNumber, type, desiredVersion }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loadingVersionListEqual = (oldObj, newObj) => JSON.stringify(oldObj || '') === JSON.stringify(newObj || '');
  const versionList = useSelector(getGatewayVersions, loadingVersionListEqual);

  const loadingVersionListStateEqual = (oldObj, newObj) =>
    (oldObj?.loadingState?.status || false) === newObj?.loadingState?.status || false;
  const { loadingState: loadingVersionListState } = useSelector(getGatewayVersionsState, loadingVersionListStateEqual);

  const loadingUpdateVersionStateEqual = (oldObj, newObj) =>
    (oldObj?.loadingUpdateVersionState?.status || false) === newObj?.loadingUpdateVersionState?.status || false;
  const { loadingUpdateVersionState } = useSelector(getGateways, loadingUpdateVersionStateEqual);

  const [versionByTypeList, setVersionByTypeList] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState('');

  useEffect(() => {
    if (open) {
      setSelectedVersion(desiredVersion || '');
    }
  }, [open]);

  useEffect(() => {
    if (isSuccess(loadingVersionListState.status)) {
      const tempVersions = versionList[type.toLowerCase()];
      if (tempVersions && tempVersions.length > 0) {
        setVersionByTypeList(tempVersions);
      }
    }
  }, [dispatch, versionList, loadingVersionListState.status]);

  useEffect(() => {
    if (type) dispatch(loadGatewaysVersions({ type }));
  }, [dispatch, type]);

  useEffect(() => {
    // backend is returning error but updating version
    if (isSuccess(loadingUpdateVersionState.status) || isError(loadingUpdateVersionState.status)) {
      dispatch(
        gatewaysV2GetByIdAction({
          gatewayId
        })
      );
      handleClose();
    }
  }, [loadingUpdateVersionState.status]);

  const onChangeDesiredVersion = event => {
    const typeParam = event.target.value;
    setSelectedVersion(typeParam);
  };

  const handleUpdateVersion = () => {
    const param = {
      gateway_id: serialNumber,
      version: selectedVersion
    };
    dispatch(updateGatewayVersion(param));
  };

  const contentEditMaterialDialog = (
    <Stack spacing={4} justifyContent="center" sx={{ width: '500px' }}>
      <Box>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={6}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="desired-system-version-label">
                {t('gateways.detail.card_detail.desired_system_version')}
              </InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="desired-system-version-label"
                id="desired-system-version-select"
                onChange={onChangeDesiredVersion}
                value={selectedVersion}
              >
                <MenuItem value="" sx={{ minHeight: '36px !important' }} />
                {versionByTypeList?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );

  return (
    <NewDialog
      open={open}
      canSave={!!selectedVersion}
      handleClose={handleClose}
      handleCancel={handleClose}
      handleOk={handleUpdateVersion}
      title={t('gateways.detail.form.dialog_change_version_title')}
      subTitle={t('gateways.detail.form.dialog_change_version_sub_title')}
      content={contentEditMaterialDialog}
      cancelCaption={t('default_actions.cancel')}
      okCaption={t('default_actions.apply')}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      maxWidth="100%"
    />
  );
}

GatewayUpdaterDialog.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  gatewayId: T.string.isRequired,
  serialNumber: T.string,
  type: T.string,
  desiredVersion: T.string
};

GatewayUpdaterDialog.defaultProps = {
  serialNumber: '',
  type: '',
  desiredVersion: ''
};
