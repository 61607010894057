import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader } from '@mui/material';
import { CheckCircleOutlineOutlined, HighlightOffOutlined, PauseCircleOutlineOutlined } from '@mui/icons-material';
import { purple, red } from '@mui/material/colors';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { EditStatusIrisV3 } from './EditStatusModal';
import withLoadingState from '../../../../helpers/withLoadingState';
import { getValueToNullableCell } from '../../../../helpers/stringHandler';
import DatabaseTableName from '../DatabaseTableName';
import { IrisTableNameEnum } from '../../../../attrs/machines';

const labelAmend = 'machines.machine_details';

const StatusContentIrisV3 = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <>
      <DatabaseTableName
        plcType={plcType}
        machineId={machineId}
        selectedTableName={IrisTableNameEnum.METRICS_TABLE_NAME}
      />
      <Card sx={{ p: 4, my: 2 }}>
        <CardHeader
          sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
          action={
            <Button
              variant="text"
              sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
              onClick={() => setEditModal(true)}
            >
              <NotifyIcon iconName="edit" fontSize="medium" />
              {t(`${labelAmend}.edit`)}
            </Button>
          }
          title={
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.status`)}</Box>
            </Box>
          }
          titleTypographyProps={{
            fontWeight: '500',
            variant: 'Body1'
          }}
        />
        <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.status_info`)}</NotifyTypo.Subtitle1>

        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={8} sx={{ mt: 5, mb: 4, maxWidth: '1200px' }}>
          <Box gridColumn="span 2">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.status_info_variable_name`)} *
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.variable_name)}</NotifyTypo.Body1>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={8} sx={{ mt: 5, mb: 4, maxWidth: '1200px' }}>
          <Box gridColumn="span 1">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <PauseCircleOutlineOutlined sx={{ color: purple[500], fontSize: 25 }} />
              <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
                {t(`${labelAmend}.status_info_table.idle`)}
              </NotifyTypo.Body1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.status_info_table.value`)} *
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.idle_value)}</NotifyTypo.Body1>
              </Box>
            </Box>
          </Box>

          <Box gridColumn="span 1">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CheckCircleOutlineOutlined sx={{ color: '#59D66E', fontSize: 25 }} />
              <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
                {t(`${labelAmend}.status_info_table.running`)}
              </NotifyTypo.Body1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">
                  {t(`${labelAmend}.status_info_table.value`)} *
                </NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.running_value)}</NotifyTypo.Body1>
              </Box>
            </Box>
          </Box>

          <Box gridColumn="span 1">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <HighlightOffOutlined sx={{ color: red[500], fontSize: 25 }} />
              <NotifyTypo.Body1 fontWeight="500" sx={{ ml: 1 }}>
                {t(`${labelAmend}.status_info_table.error`)}
              </NotifyTypo.Body1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '350px' }}>
              <Box>
                <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_info_table.value`)}</NotifyTypo.InfoText>
                <NotifyTypo.Body1>{getValueToNullableCell(data?.error_value)}</NotifyTypo.Body1>
              </Box>
            </Box>
          </Box>
        </Box>
        <EditStatusIrisV3
          open={editModal}
          close={setEditModal}
          handleClose={handleClose}
          data={data}
          machineId={machineId}
        />
      </Card>
    </>
  );
};

StatusContentIrisV3.propTypes = {
  data: T.shape({
    idle_value: T.number,
    running_value: T.number,
    error_value: T.number,
    variable_name: T.string
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

StatusContentIrisV3.defaultProps = {
  data: null
};

export default withLoadingState(StatusContentIrisV3);
