import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { CheckCircleOutline, Warning } from '@mui/icons-material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { useDispatch } from 'react-redux';
import { AutoConfigStyles } from './AutoConfig.styled';
import withLoadingState from '../../../../helpers/withLoadingState';
import { updateIrisAutoConfig } from '../../../../redux/machines_v2/actions';

const AutoConfig = ({ machineId, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = AutoConfigStyles();
  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = data.filter(item => item?.has_changes).map(item => item?.type);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, item) => {
    if (!item?.has_changes) {
      return;
    }

    const selectedIndex = selected.indexOf(item?.type);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item?.type);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleSubmit = () => {
    // Convert the selected array to an object with the selected types as keys in lowercase and the value as true
    const payload = selected.reduce((acc, itemType) => ({ ...acc, [itemType.toLowerCase()]: true }), {});
    dispatch(updateIrisAutoConfig(machineId, payload));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table>
            <TableHead className={classes.header}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    className={classes.checkbox}
                    indeterminate={
                      selected.length > 0 && selected.length < data.filter(item => item?.has_changes).length
                    }
                    checked={data.length > 0 && selected.length === data.filter(item => item?.has_changes).length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell className={classes.nowrap}>{t('machines.detail.auto_config.table.select_all')}</TableCell>
                <TableCell className={classes.nowrap}>{t('machines.detail.auto_config.table.configuration')}</TableCell>
                <TableCell />
                <TableCell className={classes.nowrap}>{t('machines.detail.auto_config.table.last_update')}</TableCell>
                <TableCell>{t('machines.detail.auto_config.table.details')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.body}>
              {data.map(item => {
                const isItemSelected = isSelected(item?.type);
                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, item)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={item?.type}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox className={classes.checkbox} checked={isItemSelected} disabled={!item?.has_changes} />
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      <Typography variant="body1">
                        {t(`machines.detail.auto_config.options.${(item?.type || '').toLowerCase()}`)}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.nowrap}>
                      {item?.detected && (
                        <Box
                          className={clsx(classes.detected, {
                            [classes.detectedWarning]: item?.has_changes,
                            [classes.detectedSuccess]: !item?.has_changes
                          })}
                        >
                          <Typography variant="caption">{item?.detected} detected</Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell className={classes.nowrap}>{item?.lastUpdate}</TableCell>
                    <TableCell>
                      {item?.has_changes ? (
                        <>
                          <Warning sx={{ color: '#F19909', verticalAlign: 'middle', mr: 1 }} />
                          {t('machines.detail.auto_config.options.has_difference')}
                        </>
                      ) : (
                        <>
                          <CheckCircleOutline sx={{ color: '#59D66E', verticalAlign: 'middle', mr: 1 }} />
                          {t('machines.detail.auto_config.options.no_difference')}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Button
        className={classes.approveButton}
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        disabled={selected.length === 0}
      >
        {t('machines.detail.auto_config.button.approve_selected')}
      </Button>
    </Box>
  );
};

AutoConfig.propTypes = {
  machineId: T.string,
  data: T.arrayOf(
    T.shape({
      detected: T.number,
      has_changes: T.bool,
      lastUpdate: T.string,
      type: T.string.isRequired
    }).isRequired
  ).isRequired
};

AutoConfig.defaultProps = {
  machineId: null
};

export default withLoadingState(AutoConfig);
