import { Grid, Tooltip } from '@mui/material';
import moment from 'moment';
import T from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotifyIcon } from 'web-components';
import StyledChip from 'components/elements/StyledChip';
import GatewayTable from './GatewayTable';
import {
  GATEWAY_CONNECTED,
  GATEWAY_CONNECTION_STATUS,
  GATEWAY_CONNECTION_TIME,
  GATEWAY_NOT_CONNECTED
} from '../../attrs/gateways';
import { StyledSmall } from './elements';

const GatewayTableSelector = ({ rows }) => {
  const { t } = useTranslation();

  const columns = [
    { id: 'serial_number', label: t('gateways.overview.gateway_id'), sortable: true },
    {
      id: 'organization_name',
      label: t('gateways.overview.customer'),
      sortable: true,
      cellRenderer: row => row.organization_name || '---'
    },
    {
      id: 'machine_count',
      label: t('gateways.overview.machines_number'),
      sortable: false,
      cellRenderer: row => row.machine_count
    },
    {
      id: 'actual_version',
      label: t('gateways.overview.version'),
      sortable: false,
      cellRenderer: row => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {row.actual_version || '---'}
          {row.actual_version !== row.desired_version && (
            <Tooltip title={t('gateways.not_current_version')} placement="bottom" arrow>
              <span>
                <NotifyIcon
                  iconName="checkmarkFilled"
                  style={{
                    paddingLeft: '.5rem',
                    marginTop: '.5rem'
                  }}
                  fontSize="large"
                />
              </span>
            </Tooltip>
          )}
        </div>
      )
    },
    {
      id: 'configured',
      label: t('gateways.overview.is_configured'),
      sortable: false,
      cellRenderer: row => (
        <StyledChip
          className={row.configured ? 'configured' : 'unconfigured'}
          variant="outlined"
          style={{ border: '1px solid', cursor: 'pointer' }}
          label={row.configured ? t('gateways.configured') : t('gateways.not_configured')}
        />
      )
    },
    {
      id: 'last_seen',
      label: t('gateways.overview.status'),
      sortable: true,
      cellRenderer: row => {
        const lastSeen = moment((row || {}).last_seen || null);
        const lastSeenDiff = moment().diff(lastSeen, 'minutes');
        const status = lastSeenDiff <= GATEWAY_CONNECTION_TIME ? GATEWAY_CONNECTED : GATEWAY_NOT_CONNECTED;
        return (
          <Grid container spacing={0} style={{ display: 'block' }}>
            <Grid item xs={12}>
              {status === 'NOT_CONNECTED' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <NotifyIcon
                    iconName="gatewayDesconnected"
                    fontSize="medium"
                    htmlColor={GATEWAY_CONNECTION_STATUS[status].color}
                    style={{
                      marginRight: '.5rem'
                    }}
                  />
                  {t(GATEWAY_CONNECTION_STATUS[status].label)}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <NotifyIcon
                    iconName="gatewayConnected"
                    fontSize="medium"
                    htmlColor={GATEWAY_CONNECTION_STATUS[status].color}
                    style={{
                      marginRight: '.5rem'
                    }}
                  />
                  {t(GATEWAY_CONNECTION_STATUS[status].label)}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <StyledSmall>{`${t('gateways.overview.last_seen')}: ${lastSeen.format('LLLL')}`}</StyledSmall>
            </Grid>
          </Grid>
        );
      }
    }
  ];

  return <GatewayTable columns={columns} rows={rows} />;
};

GatewayTableSelector.propTypes = {
  rows: T.instanceOf(Object).isRequired
};

export default GatewayTableSelector;
