import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CardHeader } from '@mui/material';
import T from 'prop-types';
import { BATCH_STATUS_ENUM, BatchStatusIcon, NotifyIcon, NotifyTypo } from 'web-components';
import { IrisBatchStatusPropTypes } from '../../../../../../helpers/propTypes';
import { getValueToNullableCell } from '../../../../../../helpers/stringHandler';
import { StyledStatusSpanLessMargin } from '../../elements';
import IrisBatchRecordsEditStatus from '../../IrisBatchRecordsEditModal/IrisBatchRecordsEditStatus';

const labelAmend = 'machines.machine_details.batch_iris.status';

const IrisBatchStatus = ({ status, machineId }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Button
            variant="text"
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
            onClick={() => setEditModal(true)}
          >
            <NotifyIcon iconName="edit" fontSize="medium" />
            {t(`machines.machine_details.edit`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.title`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.subtitle`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2} sx={{ mt: 3, mb: 2 }}>
        <Box gridColumn="span 3">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_variable`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(status?.status_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <StyledStatusSpanLessMargin>
            {BatchStatusIcon(BATCH_STATUS_ENUM.STOPPED)}
            {t(`${labelAmend}.status_stopped`)}
          </StyledStatusSpanLessMargin>
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_stopped_value`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(status?.status_stopped_value)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <StyledStatusSpanLessMargin>
            {BatchStatusIcon(BATCH_STATUS_ENUM.LOADING)}
            {t(`${labelAmend}.status_loading`)}
          </StyledStatusSpanLessMargin>
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_loading_value`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(status?.status_loading_value)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <StyledStatusSpanLessMargin>
            {BatchStatusIcon(BATCH_STATUS_ENUM.IN_OPERATION)}
            {t(`${labelAmend}.status_in_operation`)}
          </StyledStatusSpanLessMargin>
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_in_operation_value`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(status?.status_in_operation_value)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <StyledStatusSpanLessMargin>
            {BatchStatusIcon(BATCH_STATUS_ENUM.COMPLETED)}
            {t(`${labelAmend}.status_completed`)}
          </StyledStatusSpanLessMargin>
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_completed_value`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(status?.status_completed_value)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <StyledStatusSpanLessMargin>
            {BatchStatusIcon(BATCH_STATUS_ENUM.COMPLETED_BEFORE_TARGET)}
            {t(`${labelAmend}.status_completed_before_target`)}
          </StyledStatusSpanLessMargin>
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.status_completed_before_target_value`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(status?.status_completed_before_target_value)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <StyledStatusSpanLessMargin>
            {BatchStatusIcon(BATCH_STATUS_ENUM.COMPLETED_WITH_TARGET_UPDATES)}
            {t(`${labelAmend}.status_completed_with_target_updates`)}
          </StyledStatusSpanLessMargin>
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.status_completed_with_target_updates_value`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {getValueToNullableCell(status?.status_completed_with_target_updates_value)}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <StyledStatusSpanLessMargin>
            {BatchStatusIcon(BATCH_STATUS_ENUM.CANCELLED)}
            {t(`${labelAmend}.status_cancelled`)}
          </StyledStatusSpanLessMargin>
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.status_cancelled_value`)}</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(status?.status_cancelled_value)}</NotifyTypo.Body1>
        </Box>
      </Box>

      <IrisBatchRecordsEditStatus open={editModal} handleClose={handleClose} data={status} machineId={machineId} />
    </>
  );
};

IrisBatchStatus.propTypes = {
  status: IrisBatchStatusPropTypes,
  machineId: T.string.isRequired
};

IrisBatchStatus.defaultProps = {
  status: null
};

export default IrisBatchStatus;
