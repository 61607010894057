import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';

import { SUCCESS, WARNING } from 'attrs/status';

import {
  createOrganization,
  getOrganization,
  getOrganizationMachinesList,
  getOrganizationMachinesListSearch,
  getOrganizationProductsAccess,
  getOrganizations,
  getOrganizationsByResume,
  updateOrganization,
  verifySapId
} from '../../api/organizations';
import { STATUS_INVALID, STATUS_NOT_VERIFIED, STATUS_VALID } from '../../attrs/salesforce';

import { generateId } from '../../helpers/utils';

import { actionStatus, getError, statusAction } from '../utils';
import { addNotification } from '../ui/notifications/actions';

import { loadOrganizations, resetSapStatus, setOrganizationFilter, setOrganizationSortOrder } from './actions';
import * as constants from './constants';

import { getVerificationStatus } from './selectors';

function* handleLoadOrganizations() {
  yield put(statusAction(constants.LOAD_ORGANIZATIONS, actionStatus.START));

  try {
    const { data } = yield getOrganizations();

    yield put(statusAction(constants.LOAD_ORGANIZATIONS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_ORGANIZATIONS, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.organizations.${error}`
      })
    );
  }
}

function* handleLoadOrganizationsByResume({ sapId, customerName, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_ORGANIZATIONS_RESUME, actionStatus.START));
  const sapIdFilter = !_.isEmpty(sapId) ? sapId : null;
  const custumerNameFilter = !_.isEmpty(customerName) ? customerName : null;

  const search = sapIdFilter || custumerNameFilter;

  try {
    const { data } = yield getOrganizationsByResume(search, limit, page, order, sort);

    yield put(setOrganizationSortOrder(sort, order));
    yield put(setOrganizationFilter(sapId, customerName));

    yield put(statusAction(constants.LOAD_ORGANIZATIONS_RESUME, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_ORGANIZATIONS_RESUME, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleVerifySapId({ sapAccount }) {
  yield put(
    statusAction(constants.VERIFY_SAP_ID, actionStatus.START, {
      payload: { sap_status: STATUS_NOT_VERIFIED }
    })
  );

  try {
    const { data } = yield call(verifySapId, sapAccount);

    yield put(
      statusAction(constants.VERIFY_SAP_ID, actionStatus.SUCCESS, {
        payload: { sap_status: data.is_exist ? STATUS_VALID : STATUS_INVALID }
      })
    );

    const message = data.is_exist
      ? 'customers.form.sap_verification_success'
      : 'customers.form.sap_verification_failed_short';
    const type = data.is_exist ? SUCCESS : WARNING;

    yield put(
      addNotification({
        key: generateId(),
        type,
        message
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.VERIFY_SAP_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.organizations.${error}`
      })
    );
  }
}

function* verifySapIdAndPrepareModifiedValues(values) {
  let verificationStatus = yield select(getVerificationStatus);
  const isSapIdPopulated = Boolean(values.sap_account.length);
  const shouldVerifySapId = verificationStatus === STATUS_NOT_VERIFIED && isSapIdPopulated;
  if (shouldVerifySapId) {
    yield call(handleVerifySapId, { sapAccount: values.sap_account });
    verificationStatus = yield select(getVerificationStatus);
  }

  const { email, ...filteredValues } = values;
  const sapStatus = verificationStatus && isSapIdPopulated ? verificationStatus : STATUS_NOT_VERIFIED;

  // BE is expecting e-mail key
  return {
    ...filteredValues,
    sap_status: sapStatus,
    'e-mail': email
  };
}

function* handleCreateOrganization({ values, action }) {
  yield put(statusAction(constants.CREATE_ORGANIZATION, actionStatus.START));

  try {
    const modifiedValues = yield call(verifySapIdAndPrepareModifiedValues, values);
    const sapStatus = modifiedValues.sap_status;
    if (sapStatus !== STATUS_NOT_VERIFIED) {
      // currently phone is not correct in specification
      // logo does not expect empty string
      const { data } = yield createOrganization(modifiedValues);

      yield put(statusAction(constants.CREATE_ORGANIZATION, actionStatus.SUCCESS, { payload: data }));
      yield put(loadOrganizations());
      yield put(push(`/customers/${data.id}`));

      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.SUCCESS,
          message: 'form.customers.success.create'
        })
      );
    }
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_ORGANIZATION, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.organizations.${error}`
      })
    );
  } finally {
    action();
    yield put(resetSapStatus(''));
  }
}

function* handleLoadOrganization({ id }) {
  yield put(statusAction(constants.LOAD_ORGANIZATION, actionStatus.START));

  try {
    const { data } = yield getOrganization(id);

    yield put(statusAction(constants.LOAD_ORGANIZATION, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_ORGANIZATION, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.organizations.${error}`
      })
    );
  }
}

function* handleUpdateOrganization({ id, values }) {
  yield put(statusAction(constants.UPDATE_ORGANIZATION, actionStatus.START));

  try {
    const modifiedValues = yield call(verifySapIdAndPrepareModifiedValues, values);

    const { data } = yield updateOrganization(id, modifiedValues);

    yield put(statusAction(constants.UPDATE_ORGANIZATION, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.customers.success.update'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_ORGANIZATION, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.organizations.${error}`
      })
    );
  }
}

/**
 * Get all organization products access
 * @param organizationId
 * @returns {Generator<*, void, *>}
 */
function* handleGetOrganizationProductsAccess({ organizationId }) {
  yield put(statusAction(constants.GET_ORGANIZATION_PRODUCTS_ACCESS, actionStatus.START));

  try {
    const { data } = yield getOrganizationProductsAccess(organizationId);
    yield put(statusAction(constants.GET_ORGANIZATION_PRODUCTS_ACCESS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.GET_ORGANIZATION_PRODUCTS_ACCESS, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.organizations.${error}`
      })
    );
  }
}

/** Endpoints V2 */

function* handleGetOrganizationMachinesList({ organizationId, limit, page, sort, order }) {
  yield put(statusAction(constants.ORGANIZATION_MACHINES_LIST, actionStatus.START));
  try {
    const { data } = yield getOrganizationMachinesList(organizationId, limit, page, sort, order);

    yield put(statusAction(constants.ORGANIZATION_MACHINES_LIST, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.ORGANIZATION_MACHINES_LIST, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleFetchFiltered({ organizationId, serialNumber, commissionNumber, limit, page, sort, order }) {
  try {
    const params = {
      limit,
      page,
      sort,
      order
    };

    if (serialNumber !== '') {
      params.serial_number = serialNumber;
    }

    if (commissionNumber !== '') {
      params.commission_number = commissionNumber;
    }

    const { data } = yield getOrganizationMachinesListSearch(organizationId, params);

    yield put(
      statusAction(constants.ORGANIZATION_MACHINES_LIST, actionStatus.SUCCESS, {
        payload: data
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.ORGANIZATION_MACHINES_LIST, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.alerts.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_ORGANIZATIONS, handleLoadOrganizations);
  yield takeLatest(constants.CREATE_ORGANIZATION, handleCreateOrganization);
  yield takeLatest(constants.LOAD_ORGANIZATION, handleLoadOrganization);
  yield takeLatest(constants.UPDATE_ORGANIZATION, handleUpdateOrganization);
  yield takeLatest(constants.VERIFY_SAP_ID, handleVerifySapId);
  yield takeLatest(constants.LOAD_ORGANIZATIONS_RESUME, handleLoadOrganizationsByResume);
  yield takeLatest(constants.GET_ORGANIZATION_PRODUCTS_ACCESS, handleGetOrganizationProductsAccess);
  yield takeLatest(constants.ORGANIZATION_MACHINES_LIST, handleGetOrganizationMachinesList);
  yield takeLatest(constants.LOAD_MACHINES_RESUME_LIST_WITH_FILTER, handleFetchFiltered);
}
