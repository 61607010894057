import { combineReducers } from 'redux';
import { loadingStateReducer } from 'redux/utils';
import typeToReducer from 'type-to-reducer';
import {
  CREATE_CLONEABLE_LICENSE,
  LOAD_CLONEABLE_MACHINES,
  LOAD_CLONEABLE_MACHINES_DETAIL,
  LOAD_MACHINES_CLONEABLE_RESUME
} from './constants';

export default combineReducers({
  loadingState: loadingStateReducer(LOAD_CLONEABLE_MACHINES, LOAD_MACHINES_CLONEABLE_RESUME),
  updateLoadingState: loadingStateReducer(LOAD_CLONEABLE_MACHINES, LOAD_MACHINES_CLONEABLE_RESUME),
  machinesDetails: typeToReducer(
    {
      [LOAD_CLONEABLE_MACHINES_DETAIL]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  ),
  data: typeToReducer(
    {
      [LOAD_CLONEABLE_MACHINES]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_MACHINES_CLONEABLE_RESUME]: {
        SUCCESS: (data, { payload }) => payload
      },
      [CREATE_CLONEABLE_LICENSE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  )
});
