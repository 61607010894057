import T from 'prop-types';

export const PERIODS_LIST = ['5s', '30s', '1m0s', '5m0s', '30m0s', '1h0m0s'];
export const PERIODS_LIST_IN_SECONDS = [
  { key: '5s', value: 5 },
  { key: '30s', value: 30 },
  { key: '1m0s', value: 60 },
  { key: '5m0s', value: 300 },
  { key: '30m0s', value: 1800 },
  { key: '1h0m0s', value: 3600 }
];
export const getPeriodsListInSecondsKeyByValue = value => {
  const period = PERIODS_LIST_IN_SECONDS.find(item => item.value === value);
  return period ? period.key : undefined;
};

export const DEFAULT_PERIOD = '30s';

export const CUSTOM_ICON_TYPE_LIST = [
  { t: 'custom_icon_types.angle', c: 'ANGLE' },
  { t: 'custom_icon_types.concentration', c: 'CONCENTRATION' },
  { t: 'custom_icon_types.current', c: 'CURRENT' },
  { t: 'custom_icon_types.density', c: 'DENSITY' },
  { t: 'custom_icon_types.distance_mm_cm_m', c: 'DISTANCE_MM_CM_M' },
  { t: 'custom_icon_types.energy', c: 'ENERGY' },
  { t: 'custom_icon_types.force', c: 'FORCE' },
  { t: 'custom_icon_types.flow', c: 'FLOW' },
  { t: 'custom_icon_types.hourmeter', c: 'HOURMETER' },
  { t: 'custom_icon_types.level', c: 'LEVEL' },
  { t: 'custom_icon_types.position', c: 'POSITION' },
  { t: 'custom_icon_types.power', c: 'POWER' },
  { t: 'custom_icon_types.pressure', c: 'PRESSURE' },
  { t: 'custom_icon_types.progress', c: 'PROGRESS' },
  { t: 'custom_icon_types.quantity_number', c: 'QUANTITY_NUMBER' },
  { t: 'custom_icon_types.speed', c: 'SPEED' },
  { t: 'custom_icon_types.temperature', c: 'TEMPERATURE' },
  { t: 'custom_icon_types.time', c: 'TIME' },
  { t: 'custom_icon_types.torque', c: 'TORQUE' },
  { t: 'custom_icon_types.vibration', c: 'VIBRATION' },
  { t: 'custom_icon_types.viscosity', c: 'VISCOSITY' },
  { t: 'custom_icon_types.volume', c: 'VOLUME' },
  { t: 'custom_icon_types.weight', c: 'WEIGHT' }
];

export const initialStaticValues = {
  is_custom: false,
  is_static: true,
  name: '',
  period: '',
  custom_period: '',
  custom_icon_type: '',
  custom_unit: '',
  decimal_place: '',
  multiplication_factor: null,
  source: '',
  type: '',
  units: '',
  value_type: ''
};

export const initialSensorValues = {
  is_custom: false,
  is_static: false,
  name: '',
  period: '',
  custom_period: DEFAULT_PERIOD,
  custom_icon_type: '',
  custom_unit: '',
  decimal_place: '',
  multiplication_factor: null,
  low_trigger: true,
  high_trigger: true,
  y_axis: {
    min: {
      specified: false,
      value: null
    },
    max: {
      specified: false,
      value: null
    }
  },
  range: {
    max: {
      value: undefined,
      source: undefined
    },
    min: {
      value: undefined,
      source: undefined
    }
  },
  source: '',
  type: '',
  units: ''
};

export const ENGINEERING_DATA_TYPES = [
  { id: 0, label: 'source' },
  { id: 1, label: 'value' }
];

export const ENGINEERING_UNITS = ['max', 'min'];

export const DECIMAL_PLACES = [0, 0.1, 0.01, 0.001, 0.0001];

export const metricDataType = T.shape({
  is_custom: T.bool,
  is_static: T.bool,
  name: T.string,
  type: T.string,
  source: T.string,
  units: T.string,
  custom_icon_type: T.string,
  custom_unit: T.string,
  period: T.string,
  custom_period: T.string,
  decimal_place: T.oneOfType([T.string, T.number]),
  multiplication_factor: T.oneOfType([T.string, T.number]),
  low_trigger: T.bool,
  high_trigger: T.bool,
  range: T.shape({
    min: T.shape({
      value: T.oneOfType([T.string, T.number])
    }),
    max: T.shape({
      value: T.oneOfType([T.string, T.number])
    })
  }),
  y_axis: T.shape({
    min: T.shape({
      specified: T.bool,
      value: T.oneOfType([T.string, T.number])
    }),
    max: T.shape({
      specified: T.bool,
      value: T.oneOfType([T.string, T.number])
    })
  })
});

export const SENSOR_TYPE_IRIS_V3_LIST = [
  { t: 'sensor_meas_type.current', c: 'SENSOR_MEAS_TYPE_CURRENT' },
  { t: 'sensor_meas_type.cooling_flow', c: 'SENSOR_MEAS_TYPE_COOLING_FLOW' },
  { t: 'sensor_meas_type.density', c: 'SENSOR_MEAS_TYPE_DENSITY' },
  { t: 'sensor_meas_type.energy', c: 'SENSOR_MEAS_TYPE_ENERGY' },
  { t: 'sensor_meas_type.frequency', c: 'SENSOR_MEAS_TYPE_FREQUENCY' },
  { t: 'sensor_meas_type.level', c: 'SENSOR_MEAS_TYPE_LEVEL' },
  { t: 'sensor_meas_type.percentage', c: 'SENSOR_MEAS_TYPE_PERCENTAGE' },
  { t: 'sensor_meas_type.product_mass_flow', c: 'SENSOR_MEAS_TYPE_PRODUCT_MASS_FLOW' },
  { t: 'sensor_meas_type.power', c: 'SENSOR_MEAS_TYPE_POWER' },
  { t: 'sensor_meas_type.pressure', c: 'SENSOR_MEAS_TYPE_PRESSURE' },
  { t: 'sensor_meas_type.quantity_number', c: 'SENSOR_MEAS_TYPE_QUANTITY' },
  { t: 'sensor_meas_type.specific_energy', c: 'SENSOR_MEAS_TYPE_SPECIFIC_ENERGY' },
  { t: 'sensor_meas_type.speed', c: 'SENSOR_MEAS_TYPE_SPEED' },
  { t: 'sensor_meas_type.speed_percentage', c: 'SENSOR_MEAS_TYPE_SPEED_PERCENTAGE' },
  { t: 'sensor_meas_type.temperature', c: 'SENSOR_MEAS_TYPE_TEMPERATURE' },
  { t: 'sensor_meas_type.time', c: 'SENSOR_MEAS_TYPE_TIME' },
  { t: 'sensor_meas_type.torque', c: 'SENSOR_MEAS_TYPE_TORQUE' },
  { t: 'sensor_meas_type.viscosity', c: 'SENSOR_MEAS_TYPE_VISCOSITY' },
  { t: 'sensor_meas_type.volume', c: 'SENSOR_MEAS_TYPE_PRODUCT_VOLUME_FLOW' }
];

export const ENGINEERING_DATA_TYPES_IRIS_V3 = {
  VARIABLE_NAME: 'VARIABLE_NAME',
  FIXED_VALUE: 'FIXED_VALUE'
};
