import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BeadMaterialType, FormElements, GrindingTechnology, InnerLinerMaterial } from 'web-components';
import {
  getDefaultFieldValue,
  getListWithoutDefaultValue,
  preventTypeNumberDefaultCharacters
} from '../../../../helpers/utils';

const MachineDetailsBatchRecordFields = ({
  isMasterRefiner,
  getError,
  getHelperText,
  handleChangeNumber,
  handleChangeFloat,
  handleBlur,
  setFieldValue,
  values
}) => {
  const { t } = useTranslation();

  const handleSelectChange = name => e => setFieldValue(name, e.target.value);

  const optionValueMapper = arr =>
    arr.map(option => (
      <option key={option.value} value={option.value}>
        {option.name}
      </option>
    ));

  return (
    <>
      <FormElements.Select
        fullWidth
        helperText={t('form.helpers.select_option')}
        handleChange={handleSelectChange('mechanical_config.grinding_technology')}
        id="grinding_technology"
        label={t('machines.form.grinding_technology')}
        name="grinding_technology"
        onBlur={handleBlur}
        value={values.mechanical_config.grinding_technology}
        placeholder={t('form.helpers.select_option')}
        withEmptyField
      >
        {optionValueMapper(getListWithoutDefaultValue(GrindingTechnology))}
      </FormElements.Select>
      <FormElements.Select
        fullWidth
        helperText={t('form.helpers.select_option')}
        handleChange={handleSelectChange('mechanical_config.inner_liner_material')}
        id="inner_liner_material"
        label={t('machines.form.inner_liner_material')}
        name="inner_liner_material"
        onBlur={handleBlur}
        value={values.mechanical_config.inner_liner_material}
        withEmptyField
      >
        {optionValueMapper(getListWithoutDefaultValue(InnerLinerMaterial))}
      </FormElements.Select>
      <FormElements.TextField
        fullWidth
        error={getError('mechanical_config.sieve_size')}
        helperText={getHelperText('mechanical_config.sieve_size')}
        placeholder={t('form.helpers.input_example_numeric')}
        id="sieve_size"
        label={t('machines.form.sieve_size')}
        margin="normal"
        name="mechanical_config.sieve_size"
        type="number"
        min="0"
        max="100000"
        onBlur={handleBlur}
        onChange={handleChangeFloat('mechanical_config.sieve_size')}
        value={getDefaultFieldValue(values.mechanical_config.sieve_size)}
        InputProps={{ inputProps: { min: 0 } }}
        onKeyDown={preventTypeNumberDefaultCharacters}
      />

      <FormElements.Select
        fullWidth
        helperText={t('form.helpers.select_option')}
        handleChange={handleSelectChange('mechanical_config.bead_material')}
        id="bead_material"
        label={t('machines.form.bead_material')}
        name="bead_material"
        onBlur={handleBlur}
        value={values.mechanical_config.bead_material}
        withEmptyField
      >
        {optionValueMapper(getListWithoutDefaultValue(BeadMaterialType))}
      </FormElements.Select>
      <FormElements.TextField
        fullWidth
        error={getError('mechanical_config.beads_size_1')}
        helperText={getHelperText('mechanical_config.beads_size_1')}
        InputLabelProps={{ shrink: true }}
        placeholder={t('form.helpers.input_example_numeric')}
        id="bead_size_1"
        label={t('machines.form.bead_size_1')}
        margin="normal"
        name="mechanical_config.beads_size_1"
        type="number"
        min="0"
        max="100000"
        onBlur={handleBlur}
        onChange={handleChangeFloat('mechanical_config.beads_size_1')}
        value={getDefaultFieldValue(values.mechanical_config.beads_size_1)}
        InputProps={{ inputProps: { min: 0 } }}
        onKeyDown={preventTypeNumberDefaultCharacters}
      />
      <FormElements.TextField
        disabled={!isMasterRefiner}
        fullWidth
        error={getError('mechanical_config.beads_size_2')}
        helperText={getHelperText('mechanical_config.beads_size_2')}
        InputLabelProps={{ shrink: true }}
        placeholder={t('form.helpers.input_example_numeric')}
        id="bead_size_2"
        label={t('machines.form.bead_size_2')}
        margin="normal"
        name="mechanical_config.beads_size_2"
        type="number"
        min="0"
        max="100000"
        onBlur={handleBlur}
        onChange={handleChangeFloat('mechanical_config.beads_size_2')}
        value={getDefaultFieldValue(values.mechanical_config.beads_size_2)}
        InputProps={{ inputProps: { min: 0 } }}
        onKeyDown={preventTypeNumberDefaultCharacters}
      />
      <FormElements.TextField
        fullWidth
        InputLabelProps={{ shrink: true }}
        placeholder={t('form.helpers.input_example_percentage')}
        id="bead_filling_level"
        label={t('machines.form.bead_filling_level')}
        margin="normal"
        name="bead_filling_level"
        type="number"
        min="0"
        max="100"
        onBlur={handleBlur}
        onChange={handleChangeNumber('mechanical_config.beads_filling_level')}
        value={getDefaultFieldValue(values.mechanical_config.beads_filling_level)}
        InputProps={{ inputProps: { min: 0 } }}
        onKeyDown={preventTypeNumberDefaultCharacters}
      />
    </>
  );
};

MachineDetailsBatchRecordFields.propTypes = {
  isMasterRefiner: T.bool.isRequired,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChangeNumber: T.func.isRequired,
  handleChangeFloat: T.func.isRequired,
  setFieldValue: T.func.isRequired,
  values: T.shape({
    type: T.string,
    commission_number: T.string,
    mechanical_config: T.shape({
      bead_material: T.string,
      beads_filling_level: T.number,
      beads_size_1: T.number,
      beads_size_2: T.number,
      grinding_technology: T.string,
      inner_liner_material: T.string,
      samba_system: T.bool,
      sieve_size: T.number
    })
  }).isRequired
};

export default MachineDetailsBatchRecordFields;
