import { combineReducers } from 'redux';
import { enhanceWithResetActions, loadingStateReducer } from 'redux/utils';
import typeToReducer from 'type-to-reducer';
import { CREATE_LICENSE, EDIT_LICENSE, LOAD_LICENSES, LOAD_LICENSES_RESUME, RENEW_LICENSE } from './constants';

const loadLicenseByResume = combineReducers({
  loadingState: loadingStateReducer(LOAD_LICENSES_RESUME),
  dataByResume: typeToReducer(
    {
      [LOAD_LICENSES_RESUME]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

export default combineReducers({
  creatingState: loadingStateReducer(CREATE_LICENSE),
  editingState: loadingStateReducer(EDIT_LICENSE),
  loadingState: loadingStateReducer(LOAD_LICENSES),
  updateLoadingState: loadingStateReducer(LOAD_LICENSES),
  data: typeToReducer(
    {
      [CREATE_LICENSE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [EDIT_LICENSE]: {
        SUCCESS: (data, { payload }) => payload
      },
      [LOAD_LICENSES]: {
        SUCCESS: (data, { payload }) => payload
      },
      [RENEW_LICENSE]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    []
  ),
  licenseResume: enhanceWithResetActions([LOAD_LICENSES_RESUME])(loadLicenseByResume)
});
