import React from 'react';
import T from 'prop-types';

import { useResolutionCheck } from 'web-components';

import { useTheme } from '@mui/material/styles';
import withLoadingState from '../../../helpers/withLoadingState';
import EmptyState from '../../../components/EmptyState';
import { ContentWrapper } from '../../../elements';
import { ContainerCustomerDetails, Wrapper } from './CustomerDetail.styled';
import Licenses from './Licenses';
import Machines from './Machines';
import Gateway from './Gateways';
import CustomerMain from './CustomerMain';
import UserManagementContainer from './UserManagement';

const CustomerDetail = ({ customer }) => {
  const { isMobile } = useResolutionCheck();
  const theme = useTheme();

  return (
    <>
      {customer && customer.name ? (
        <ContainerCustomerDetails>
          <Wrapper type="content-wrapper" style={{ width: '100%' }} theme={theme}>
            <ContentWrapper
              style={{ marginBottom: !isMobile && '4rem' }}
              maxWidth={false}
              data-selector="customer-details"
            >
              <CustomerMain customer={customer} />
              <Licenses customer={customer} />
              <Gateway customer={customer} />
              <Machines customer={customer} />
              <UserManagementContainer customer={customer} />
            </ContentWrapper>
          </Wrapper>
        </ContainerCustomerDetails>
      ) : (
        <EmptyState top={100} type="customer" />
      )}
    </>
  );
};

CustomerDetail.propTypes = {
  customer: T.shape({ name: T.string })
};

CustomerDetail.defaultProps = {
  customer: null
};

export default withLoadingState(CustomerDetail);
