import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageHeader, useResolutionCheck } from 'web-components';

import GatewayTable from '../../components/GatewayTable';
import { ContainerHeader, ContentWrapper } from '../../elements';

const Gateways = ({ gateways }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useResolutionCheck();

  return (
    <>
      <ContainerHeader className="withoutShadow">
        <PageHeader.Title>
          <PageHeader.Title.Main>{t('gateways.title')}</PageHeader.Title.Main>
          <PageHeader.Title.Sub>{t('gateways.subtitle')}</PageHeader.Title.Sub>
        </PageHeader.Title>
      </ContainerHeader>

      <ContentWrapper
        style={isDesktop ? { paddingLeft: '5rem', paddingRight: '5rem', paddingTop: 0 } : {}}
        className={!isMobile && 'withPageHeader'}
        maxWidth={false}
      >
        <GatewayTable rows={gateways} />
      </ContentWrapper>
    </>
  );
};

Gateways.propTypes = {
  gateways: T.instanceOf(Object).isRequired
};

export default Gateways;
