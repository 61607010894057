import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader, Tooltip } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import EditBatchRecordsGrinding from '../EditBatchRecordsModal/EditBatchRecordsGrinding';
import { PlcType } from '../../../../../attrs/plcConfigValues';

const labelAmend = 'machines.machine_details';

const BatchGrinding = ({ data, machineId, plcType }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Tooltip
            title={t(`machines.detail.plc_not_filled`)}
            disableHoverListener={plcType.plc_type !== PlcType.UNKNOWN}
            arrow
          >
            <span>
              <Button
                variant="text"
                disabled={plcType.plc_type === PlcType.UNKNOWN}
                sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
                onClick={() => setEditModal(true)}
              >
                <NotifyIcon iconName="edit" fontSize="medium" />
                {t(`${labelAmend}.edit`)}
              </Button>
            </span>
          </Tooltip>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.grinding_target`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.grinding_target_subtitle`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2} sx={{ mt: 3, mb: 4 }}>
        <Box gridColumn="span 10">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.target_type_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.type ? '---' : data.batch_source?.target.type}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.time_value`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.time.value ? '---' : data.batch_source?.target.time.value}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.time_address_h`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.time.hours ? '---' : data.batch_source?.target.time.hours}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 6">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.time_address_m`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.time.minutes ? '---' : data.batch_source?.target.time.minutes}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.grinding_energy_value`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.specific_energy.value ? '---' : data.batch_source?.target.specific_energy.value}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.energy_add`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.specific_energy.target
              ? '---'
              : data.batch_source?.target.specific_energy.target}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.energy_value`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.grinding_energy.value ? '---' : data.batch_source?.target.grinding_energy.value}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 3">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.energy_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.grinding_energy.target
              ? '---'
              : data.batch_source?.target.grinding_energy.target}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.number_cycles_value`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.cycles.value ? '---' : data.batch_source?.target.cycles.value}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 2">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.grinding_target_label.cycles_address`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {!data.batch_source?.target.cycles.target ? '---' : data.batch_source?.target.cycles.target}
          </NotifyTypo.Body1>
        </Box>
      </Box>

      <EditBatchRecordsGrinding open={editModal} handleClose={handleClose} data={data} machineId={machineId} />
    </Card>
  );
};

BatchGrinding.propTypes = {
  data: T.shape({
    batch_source: T.shape({
      target: T.shape({
        type: T.string.isRequired,
        time: T.shape({
          hours: T.string,
          minutes: T.string,
          value: T.number
        }),
        grinding_energy: T.shape({
          target: T.string,
          value: T.number
        }),
        specific_energy: T.shape({
          target: T.string,
          value: T.number
        }),
        cycles: T.shape({
          target: T.string,
          value: T.number
        })
      })
    })
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

BatchGrinding.defaultProps = {
  data: null
};

export default BatchGrinding;
