import React from 'react';
import { Stack } from '@mui/material';
import T from 'prop-types';
import withLoadingState from '../../../../helpers/withLoadingState';
import BatchDetails from './BatchRecordsCards/BatchDetails';
import BatchStatus from './BatchRecordsCards/BatchStatus';
import BatchGrinding from './BatchRecordsCards/BatchGriding';

const BatchRecordsContent = ({ data, machineId, plcType }) => (
  <Stack direction="column">
    <BatchDetails data={data} machineId={machineId} plcType={plcType} />
    <BatchStatus data={data} machineId={machineId} plcType={plcType} />
    <BatchGrinding data={data} machineId={machineId} plcType={plcType} />
  </Stack>
);

BatchRecordsContent.propTypes = {
  data: T.shape({
    data: T.arrayOf(T.shape({}))
  }),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

BatchRecordsContent.defaultProps = {
  data: null
};

export default withLoadingState(BatchRecordsContent);
