/* eslint-disable import/prefer-default-export */
import { all, put, select, takeLatest } from 'redux-saga/effects';
import compose from 'lodash/fp/compose';

import * as constants from './constants';
import { deleteUser, inviteUser, loadUserList, loadUserListPaginated, updateUser } from '../../api/user';
import { actionStatus, getError, statusAction } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import * as actions from './actions';
import { getSelectedOrganizationId } from '../organizations/selectors';
import { ROLE_OPERATOR } from '../../attrs/roles';

function* enrichUserWithRole(data) {
  const organizationId = yield select(compose(getSelectedOrganizationId));

  const enrichedData = {
    ...data,
    role: data.organizations[organizationId] || ROLE_OPERATOR
  };

  return enrichedData;
}

function* handleLoadUsers() {
  const organizationId = yield select(compose(getSelectedOrganizationId));

  yield put(statusAction(constants.LOAD_USERS, actionStatus.START));
  try {
    const { data } = yield loadUserList(organizationId);

    // add role key-value pair with the role the user has in the selected organization
    const enrichedData = yield all(data.map(item => enrichUserWithRole(item)));

    yield put(statusAction(constants.LOAD_USERS, actionStatus.SUCCESS, { payload: enrichedData }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_USERS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

function* handleLoadUserByResume({ organizationId, search, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_USERS_RESUME, actionStatus.START));
  try {
    const { data } = yield loadUserListPaginated(organizationId, search, limit, page, order, sort);

    yield put(statusAction(constants.LOAD_USERS_RESUME, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(statusAction(constants.LOAD_USERS_RESUME, actionStatus.ERROR, { message: error }));
  }
}

function* handleUpdateUserRole({ userId, values }) {
  const organizationId = yield select(compose(getSelectedOrganizationId));

  const progressedValues = {
    ...values,
    organizations: {
      ...values.organizations,
      [organizationId]: values.role
    }
  };

  yield put(actions.updateUserById(userId, progressedValues));
}

function* handleUpdateUserById({ userId, values }) {
  yield put(statusAction(constants.UPDATE_USER_BY_ID, actionStatus.START));

  try {
    const { id, ...filteredValues } = values;
    const { data } = yield updateUser(userId, filteredValues);
    const enrichedData = yield enrichUserWithRole(data);

    yield put(
      statusAction(constants.UPDATE_USER_BY_ID, actionStatus.SUCCESS, {
        payload: enrichedData
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_USER_BY_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

function* handleDeleteUserById({ userId }) {
  if (userId) {
    yield put(statusAction(constants.DELETE_USER_BY_ID, actionStatus.START));

    const organizationId = yield select(compose(getSelectedOrganizationId));

    try {
      yield deleteUser(userId, organizationId);

      yield put(statusAction(constants.DELETE_USER_BY_ID, actionStatus.SUCCESS, { userId }));

      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.SUCCESS,
          message: 'form.users.success.delete'
        })
      );
    } catch (err) {
      const error = getError(err);
      yield put(
        statusAction(constants.DELETE_USER_BY_ID, actionStatus.ERROR, {
          message: error
        })
      );
      yield put(
        addNotification({
          key: generateId(),
          type: actionStatus.ERROR,
          message: `errors.users.${error}`
        })
      );
    }
  }
}

function* handleInviteUser({ values }) {
  const organizationId = yield select(compose(getSelectedOrganizationId));
  yield put(statusAction(constants.INVITE_USER, actionStatus.START));

  try {
    const enrichedValues = {
      ...values,
      'e-mail': values.email,
      organization_id: organizationId
    };

    const { data } = yield inviteUser(enrichedValues);

    yield put(statusAction(constants.INVITE_USER, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.users.success.invite'
      })
    );
    yield put(actions.loadUsers());
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_USER_BY_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_USERS, handleLoadUsers);
  yield takeLatest(constants.LOAD_USERS_RESUME, handleLoadUserByResume);
  yield takeLatest(constants.UPDATE_USER_ROLE, handleUpdateUserRole);
  yield takeLatest(constants.UPDATE_USER_BY_ID, handleUpdateUserById);
  yield takeLatest(constants.DELETE_USER_BY_ID, handleDeleteUserById);
  yield takeLatest(constants.INVITE_USER, handleInviteUser);
}
