import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { objectToMap } from 'helpers/utils';
import { getValueToNullableCell } from 'helpers/stringHandler';
import EmptyState from 'components/EmptyState';

export default function GatewayVersions({ gatewayDetails }) {
  const { t } = useTranslation();
  const [versionMap, setVersionMap] = useState(new Map());

  useEffect(() => {
    if (gatewayDetails) {
      setVersionMap(objectToMap(gatewayDetails?.info?.versions));
    }
  }, [gatewayDetails]);
  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 3
      }}
      data-selector="gateway-versions"
    >
      <Stack direction="column" spacing={3}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('gateway_details.versions.title')}</Typography>
        {versionMap.size > 0 ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            rowSpacing={3}
            columnSpacing={2}
            columns={5}
          >
            {Array.from(versionMap).map(([key, value], idx) => (
              <Grid item xs={1} sx={{ pl: idx % 5 === 0 ? '0px !important' : undefined }}>
                <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                  <Typography
                    sx={{ fontSize: 12, color: '#7A7D85' }}
                    data-selector={`gateway-versions-key-${key}`}
                    gutterBottom
                  >
                    {key}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} data-selector={`gateway-versions-value-${key}`} gutterBottom>
                    {getValueToNullableCell(value)}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={{ py: 2 }}>
            <EmptyState type="gtw_versions" />
          </Box>
        )}
      </Stack>
    </Box>
  );
}

GatewayVersions.propTypes = {
  gatewayDetails: T.shape({ info: T.shape({ versions: T.shape() }) }).isRequired
};
