import { UPDATE_DETAILS_BATCH, UPDATE_STATUS_BATCH, UPDATE_TARGET_BATCH } from './constants';
import { convertEmptyToNull } from '../../helpers/stringHandler';

export const updateBatchDetails = (machineId, payload) => ({
  type: UPDATE_DETAILS_BATCH,
  machineId,
  payload: convertEmptyToNull(payload)
});

export const updateBatchStatus = (machineId, payload) => ({
  type: UPDATE_STATUS_BATCH,
  machineId,
  payload
});

export const updateBatchTarget = (machineId, payload) => ({
  type: UPDATE_TARGET_BATCH,
  machineId,
  payload: convertEmptyToNull(payload)
});
