import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import T from 'prop-types';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { setConfirmationRequest } from '../../redux/ui/confirmations/actions';

const RouteLeavingCheck = ({ when }) => {
  const [confirmed, setConfirmation] = useState(false);
  const [modal, showModal] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmNavigationClick = nextLocation => {
    setConfirmation(true);
    dispatch(push(nextLocation.pathname));
  };

  const handleBlockedNavigation = nextLocation => {
    if (!confirmed && !modal) {
      showModal(true);
      dispatch(
        setConfirmationRequest({
          action: () => handleConfirmNavigationClick(nextLocation),
          disagreeAction: () => showModal(false),
          message: 'dialog.confirmation.form_unsaved_changes'
        })
      );

      return false;
    }

    return true;
  };

  return <Prompt message={handleBlockedNavigation} when={when} />;
};

RouteLeavingCheck.propTypes = {
  when: T.bool.isRequired
};

export default RouteLeavingCheck;
