import { AppBar, Card, Container } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';
import { PageHeader as Header, NotifyTypo, Styles } from 'web-components';

import { COLOR_MAIN_HIGHLIGHT, SNACKBAR_BACKGROUND } from './attrs/colors';
import { FOOTER_NAVIGATION_BAR_HEIGHT } from './attrs/layout';

const { GlobalStyle, AppBarContentWrapper, MainContainer, ContentWrapper } = Styles;

// Demo page
const ElementsContainer = styled.div`
  & button {
    margin: 0 0.5rem 0.5rem 0;
  }
  & > div {
    margin: 0 0.5rem 0.5rem 0;
  }
  & svg {
    margin: 0.5rem;
  }
  margin: 1rem 0;
`;

// Demo page
const StyledCard = styled(Card)`
  margin: 0.5rem 0;
`;

const PageHeader = styled(Header)`
  padding: 4rem 5rem 20px 5rem;

  &.MuiPaper-root {
    @media (max-width: 425px) {
      flex-wrap: wrap;
      padding: 5px 10px;
    }
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }

  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
  @media (max-width: 768px) {
    padding: 4rem 3rem 20px 3rem;
  }
`;

const PageHeaderTitle = styled(NotifyTypo.Heading1)`
  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 16px;
    }
  }
`;

const PageHeaderSubTitle = styled(NotifyTypo.PageHeadingSubtitle)`
  &.MuiTypography-body2 {
    margin-top: 10px;
    font-size: 13px;
    color: #7a7d85;
    @media (max-width: 425px) {
      font-size: 12px;
    }
  }
`;

const StyledSnackbarProvider = styled(SnackbarProvider)`
  & .MuiSnackbarContent-root {
    background-color: ${SNACKBAR_BACKGROUND};
  }
  & .MuiSnackbarContent-message {
    font-weight: 400;
    display: flex;
    flex: 1;
  }
`;

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-colorPrimary {
    background-color: ${COLOR_MAIN_HIGHLIGHT};
  }
`;

const StyledFooterBar = styled(AppBar)`
  &.MuiAppBar-positionFixed {
    top: auto;
    bottom: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.97)), ${COLOR_MAIN_HIGHLIGHT};
  }
  &.MuiAppBar-positionSticky {
    margin-top: 3rem;
    background: none;
    box-shadow: none;
    & > .MuiToolbar-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
  & > .MuiToolbar-root {
    min-height: ${FOOTER_NAVIGATION_BAR_HEIGHT}px;
  }
`;

const StyledFooterBarContainer = styled(Container)(
  props => `
  &.MuiContainer-root {
    display: flex;
    justify-content: space-between;
    ${props.theme.breakpoints.down('xs')} {
      flex-direction: column;
      padding: 1rem 0;
      & button {
        width: 100%;
        margin-bottom: .5rem;
      }
    }
  }
`
);

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4rem 6.5rem 0px 6.5rem;

  &.MuiPaper-root {
    @media (max-width: 425px) {
      flex-wrap: wrap;
      padding: 5px 10px;
    }
  }

  &.MuiPaper-elevation1 {
    box-shadow: none;
  }

  &.MuiTypography-h3 {
    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
  @media (max-width: 768px) {
    padding: 4rem 3rem 20px 3rem;
  }
`;

const ContainerMachine = styled.div`
  display: flex;
`;

export {
  AppBarContentWrapper,
  ContainerHeader,
  ContainerMachine,
  ContentWrapper,
  ElementsContainer,
  GlobalStyle,
  MainContainer,
  PageHeader,
  PageHeaderSubTitle,
  PageHeaderTitle,
  StyledAppBar,
  StyledCard,
  StyledFooterBar,
  StyledFooterBarContainer,
  StyledSnackbarProvider
};
