import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { HOUR } from '../../TaskRepeatTypes';

export function TaskFrequencyByHourmeterCard(props) {
  const { color, firstUpdate, repeatEveryTask, isBacklog, frequency, setFrequency } = props;
  const { t } = useTranslation();
  const [repeatByHour, setRepeatByHour] = useState(0);
  const [repeatHourMaxValue, setRepeatHourMaxValue] = useState(0);

  useEffect(() => {
    if (frequency) {
      let every = 0;
      let type = HOUR;
      if (!isBacklog) {
        if (frequency.upcoming) {
          every = frequency.upcoming.amount;
        } else {
          setFrequency({ ...frequency, upcoming: { type, amount: every } });
        }
        let everyMax = -1;
        if (frequency.backlog) {
          everyMax = frequency.backlog.amount || -1;
        }
        if (everyMax >= 0) setRepeatHourMaxValue(everyMax - 1);
        else setRepeatHourMaxValue(-1);
      } else {
        if (frequency.backlog) {
          every = frequency.backlog.amount;
          type = frequency.backlog.type;
        } else {
          setFrequency({ ...frequency, backlog: { type, amount: every } });
        }
        const everyMax = repeatEveryTask || -1;
        if (everyMax >= 0) setRepeatHourMaxValue(everyMax - 1);
        else setRepeatHourMaxValue(-1);
      }
      if (repeatByHour !== every) {
        setRepeatByHour(every);
      }
    }
  }, [frequency]);

  useEffect(() => {
    if (firstUpdate) {
      const every = 0;
      const type = HOUR;
      setFrequency({ ...frequency, upcoming: { type, amount: every }, backlog: { type, amount: every } });
    }
  }, [repeatEveryTask]);

  const handleRepeatByHourChange = event => {
    const repeatEvery = +event.target.value;
    if (repeatEvery <= repeatHourMaxValue && repeatEvery >= 0) {
      setRepeatByHour(repeatEvery);
      if (frequency && setFrequency) {
        if (!isBacklog) {
          setFrequency({ ...frequency, upcoming: { ...frequency.upcoming, amount: repeatEvery } });
        } else {
          setFrequency({ ...frequency, backlog: { ...frequency.backlog, amount: repeatEvery } });
        }
      }
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 2,
        borderLeft: 4,
        borderColor: color,
        borderRadius: '8px',
        px: 2,
        py: 2
      }}
    >
      <Grid container spacing={2} columns={12} alignItems="center">
        <Grid item xs={2}>
          <Typography sx={{ fontSize: { xs: 12, sm: 14 } }} gutterBottom>
            {t('task.general_information.frequency.show_task')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="show_before"
            variant="filled"
            type="number"
            onChange={handleRepeatByHourChange}
            value={repeatByHour}
            InputProps={{
              inputProps: {
                min: 0,
                max: repeatHourMaxValue
              }
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ fontSize: { xs: 12, sm: 14 } }} gutterBottom>
            {t('task.general_information.frequency.it_runs')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

TaskFrequencyByHourmeterCard.propTypes = {
  color: PropTypes.string.isRequired,
  firstUpdate: PropTypes.bool.isRequired,
  repeatEveryTask: PropTypes.number.isRequired,
  isBacklog: PropTypes.bool,
  frequency: PropTypes.shape.isRequired,
  setFrequency: PropTypes.func.isRequired
};

TaskFrequencyByHourmeterCard.defaultProps = {
  isBacklog: false
};
