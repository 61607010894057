/* eslint-disable import/prefer-default-export */
import * as constants from './constants';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';
import { MACHINE_DEFAULT_LIST_ORDER, MACHINE_DEFAULT_LIST_SORT } from '../../attrs/machines';

const loadUsers = () => ({
  type: constants.LOAD_USERS
});

const loadUserByResume = (
  organizationId = null,
  search = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = MACHINE_DEFAULT_LIST_ORDER,
  sort = MACHINE_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_USERS_RESUME,
  organizationId,
  search,
  limit,
  page,
  order,
  sort
});

const getUsersFiltered = (limit, page, order, sort) => ({
  type: constants.LOAD_USERS_RESUME_WITH_FILTER,
  limit,
  page,
  order,
  sort
});

const updateUserById = (userId, values) => ({
  type: constants.UPDATE_USER_BY_ID,
  userId,
  values
});

const deleteUserById = userId => ({
  type: constants.DELETE_USER_BY_ID,
  userId
});

const updateUserRole = (userId, values) => ({
  type: constants.UPDATE_USER_ROLE,
  userId,
  values
});

const inviteUser = values => ({
  type: constants.INVITE_USER,
  values
});

export { loadUsers, loadUserByResume, getUsersFiltered, updateUserById, updateUserRole, deleteUserById, inviteUser };
