import React from 'react';
import T from 'prop-types';
import { StyledContentWrapper } from '../../../../components/forms/Machines/MachineDetail/elements';
import ConnectedBatchRecordsContent from './ConnectedBatchRecordsContent';

const BatchRecords = ({ plcType }) => (
  <>
    <StyledContentWrapper elevation={3}>
      <ConnectedBatchRecordsContent plcType={plcType} />
    </StyledContentWrapper>
  </>
);

BatchRecords.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default BatchRecords;
