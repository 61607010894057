import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'prop-types';
import { loadIrisTableNames } from '../../../../redux/machines_v2/actions';
import DatabaseTableName from './DatabaseTableName';
import { getSelectedIrisTableNames } from '../../../../redux/machines_v2/selectors';
import { PlcType } from '../../../../attrs/plcConfigValues';

const ConnectedDatabaseTableName = ({ machineId, plcType, selectedTableName }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getSelectedIrisTableNames);

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(loadIrisTableNames(machineId));
    }
  }, [machineId, dispatch, plcType]);

  if (plcType.plc_type !== PlcType.IRIS_V3) {
    return null;
  }

  return (
    <DatabaseTableName
      loadingState={loadingState}
      reloadAction={() => dispatch(loadIrisTableNames(machineId))}
      irisTableName={data}
      machineId={machineId}
      selectedTableName={selectedTableName}
    />
  );
};

ConnectedDatabaseTableName.propTypes = {
  machineId: T.string,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired,
  selectedTableName: T.string.isRequired
};

ConnectedDatabaseTableName.defaultProps = {
  machineId: null
};

export default ConnectedDatabaseTableName;
