import styled, { css } from 'styled-components';
import { Box } from '@mui/material';
import { NewDialog } from 'web-components';

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
`;

// >>>>>>>>>>> User Edit Modal Elements

export const Wrapper = styled.div(
  ({ theme, type }) => `
  ${theme.breakpoints.down('sm')} {
    margin-top: 5.5rem;
  }

  ${theme.breakpoints.down('xs')} {
    margin-top: 8.5rem;
  }
  
  ${
    type === 'sidebar' &&
    css`
      min-width: 250px;
      margin-top: 25px;
      margin-left: 15px;
    `
  }
  
  ${
    type === 'sidebar-collapse' &&
    css`
      max-width: 50px;
      margin-top: 25px;
      margin-left: 15px;
    `
  }
  `
);

export const UserEditStyledHandlers = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const UserEditOverviewContent = styled(Box)`
  display: inline-flex;
  flex-wrap: inherit;
  padding: 3.25rem 0 0;
  gap: 1.25rem;
`;

export const UserEditOverviewColumn = styled(Box)`
  display: flex;
  flex: 1;
  gap: 1.25rem;
  flex-direction: row;
`;

export const UserEditOverviewRow = styled(Box)`
  display: flex;
  flex: 1;
  gap: 3px;
  flex-direction: column;
`;

export const StyledNewDialog = styled(NewDialog)`
  .MuiDialog-container {
    align-items: normal;
  }

  .MuiBox-root {
    width: 100%;
  }

  .MuiDialog-paper {
    border-radius: 0.625rem;
    min-width: 1062px;
    width: 100%;

    form {
      width: 100%;
    }
  }
  h2:first-of-type {
    padding-bottom: unset;
  }
`;
