import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Loader } from 'web-components';

import customTheme from './attrs/theme';
import { StyledSnackbarProvider } from './elements';
import LoggedIn from './LoggedIn';
import LoggedOut from './LoggedOut';
import Notifications from './components/Notifications';
import Confirmations from './components/Confirmations';
import { checkAuthentication } from './redux/auth/actions';
import { getIsAuthenticated } from './redux/auth/selectors';
import FeatureToggles from './components/FeatureToggles';
import { CacheBuster } from './CacheBuster';

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    dispatch(checkAuthentication());
    setIsLoading(false);
  }, [dispatch, setIsLoading]);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <Suspense fallback={<Loader standalone />}>
            <ThemeProvider theme={customTheme}>
              <FeatureToggles />
              <CssBaseline />
              {isLoading ? (
                <Loader standalone />
              ) : (
                <StyledSnackbarProvider dense maxSnack={3}>
                  {isAuthenticated ? <LoggedIn /> : <LoggedOut />}
                  <Notifications />
                  <Confirmations />
                </StyledSnackbarProvider>
              )}
            </ThemeProvider>
          </Suspense>
        );
      }}
    </CacheBuster>
  );
}

export default App;
