import { call, put, takeLatest } from 'redux-saga/effects';
import { actionStatus, getError, statusAction } from 'redux/utils';

import { push } from 'connected-react-router';
import * as api from '../../api/machines';
import { LOAD_CLONEABLE_MACHINES, LOAD_CLONEABLE_MACHINES_DETAIL, LOAD_MACHINES_CLONEABLE_RESUME } from './constants';
import { CLONE_MACHINE } from '../machines/constants';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';
import { setMachineCloneableSortOrder } from './actions';

function* loadCloneableMachines(payload) {
  yield put(statusAction(LOAD_CLONEABLE_MACHINES, actionStatus.START));

  try {
    const { data } = yield call(api.loadCloneableMachines, payload.organizationId);
    yield put(statusAction(LOAD_CLONEABLE_MACHINES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_CLONEABLE_MACHINES, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleFetchFilteredMachine({
  organizationId,
  commissionNumber,
  machineType,
  plcType,
  customerName,
  limit,
  page,
  order,
  sort
}) {
  try {
    const { data } = yield api.loadCloneableMachinesPaginated(
      organizationId,
      commissionNumber,
      machineType,
      plcType,
      customerName,
      limit,
      page,
      order,
      sort
    );

    yield put(setMachineCloneableSortOrder(sort, order));
    yield put(
      statusAction(LOAD_MACHINES_CLONEABLE_RESUME, actionStatus.SUCCESS, {
        payload: data
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(LOAD_MACHINES_CLONEABLE_RESUME, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.alerts.${error}`
      })
    );
  }
}

function* loadCloneableMachinesDetails({ machineId, organizationId }) {
  yield put(statusAction(LOAD_CLONEABLE_MACHINES_DETAIL, actionStatus.START));

  try {
    const validMachineObject = {
      machineId,
      organizationId
    };

    const { data } = yield call(api.loadCloneableMachinesDetails, validMachineObject);
    yield put(statusAction(LOAD_CLONEABLE_MACHINES_DETAIL, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_CLONEABLE_MACHINES_DETAIL, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleCreateCloneMachine({ payload, history }) {
  yield put(statusAction(CLONE_MACHINE, actionStatus.START));

  try {
    const response = yield call(api.createCloneableMachine, payload);

    yield put(statusAction(CLONE_MACHINE, actionStatus.SUCCESS, response));
    if (history && response.data && response.data.machine_id) {
      yield put(push(`/customers/${payload.organization_id}/machines/${response.data.machine_id}`));
    }
  } catch (err) {
    yield put(
      statusAction(CLONE_MACHINE, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

export function* watchCreateLicense() {
  yield takeLatest(LOAD_CLONEABLE_MACHINES, loadCloneableMachines);
  yield takeLatest(LOAD_MACHINES_CLONEABLE_RESUME, handleFetchFilteredMachine);
  yield takeLatest(LOAD_CLONEABLE_MACHINES_DETAIL, loadCloneableMachinesDetails);
  yield takeLatest(CLONE_MACHINE, handleCreateCloneMachine);
}
