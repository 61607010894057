import { put, takeLatest } from 'redux-saga/effects';
import { actionStatus, statusAction } from 'redux/utils';
import { loadActiveCustomers, loadActiveUsers, loadCustomersMonth, loadFreemiumMonth } from '../../api/productUsage';
import { LOAD_ACTIVE_CUSTOMERS, LOAD_ACTIVE_USERS, LOAD_CUSTOMERS_MONTH, LOAD_FREEMIUM_MONTH } from './constants';

function* handleLoadActiveCustomers({ interval }) {
  yield put(statusAction(LOAD_ACTIVE_CUSTOMERS, actionStatus.START));
  try {
    const { data } = yield loadActiveCustomers(interval);
    yield put(statusAction(LOAD_ACTIVE_CUSTOMERS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_ACTIVE_CUSTOMERS, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadActiveUsers({ interval }) {
  yield put(statusAction(LOAD_ACTIVE_USERS, actionStatus.START));
  try {
    const { data } = yield loadActiveUsers(interval);
    yield put(statusAction(LOAD_ACTIVE_USERS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_ACTIVE_USERS, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadCustomersMonth({ interval }) {
  yield put(statusAction(LOAD_CUSTOMERS_MONTH, actionStatus.START));
  try {
    const { data } = yield loadCustomersMonth(interval);
    yield put(statusAction(LOAD_CUSTOMERS_MONTH, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_CUSTOMERS_MONTH, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleLoadFreemiumMonth({ interval }) {
  yield put(statusAction(LOAD_FREEMIUM_MONTH, actionStatus.START));
  try {
    const { data } = yield loadFreemiumMonth(interval);
    yield put(statusAction(LOAD_FREEMIUM_MONTH, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_FREEMIUM_MONTH, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(LOAD_ACTIVE_CUSTOMERS, handleLoadActiveCustomers);
  yield takeLatest(LOAD_ACTIVE_USERS, handleLoadActiveUsers);
  yield takeLatest(LOAD_CUSTOMERS_MONTH, handleLoadCustomersMonth);
  yield takeLatest(LOAD_FREEMIUM_MONTH, handleLoadFreemiumMonth);
}
