import axios from './axios';

const loadActiveCustomers = interval =>
  axios({
    method: 'get',
    url: '/v2/analytics/organizations/active-count',
    params: {
      interval
    }
  });

const loadActiveUsers = interval =>
  axios({
    method: 'get',
    url: '/v2/analytics/users/active-count',
    params: {
      interval
    }
  });

const loadCustomersMonth = year =>
  axios({
    method: 'get',
    url: '/v2/analytics/users/notify-accesses',
    params: {
      year
    }
  });

const loadFreemiumMonth = year =>
  axios({
    method: 'get',
    url: '/v2/analytics/organizations/notify-freemium-customers',
    params: {
      year
    }
  });

export { loadActiveCustomers, loadActiveUsers, loadCustomersMonth, loadFreemiumMonth };
