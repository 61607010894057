import React from 'react';
import { Stack } from '@mui/material';
import T from 'prop-types';
import withLoadingState from '../../../../helpers/withLoadingState';
import DatabaseTableName from '../DatabaseTableName/ConnectedDatabaseTableName';
import { IrisTableNameEnum } from '../../../../attrs/machines';
import { IrisBatch, IrisBatchMeasurements } from './IrisBatchRecordsCards';

const IrisBatchRecordsContent = ({ details, status, target, limits, measurements, machineId, plcType }) => (
  <Stack direction="column">
    <DatabaseTableName
      plcType={plcType}
      machineId={machineId}
      selectedTableName={IrisTableNameEnum.BATCH_DETAILS_TABLE_NAME}
    />
    <IrisBatch machineId={machineId} details={details} status={status} target={target} limits={limits} />
    <DatabaseTableName
      plcType={plcType}
      machineId={machineId}
      selectedTableName={IrisTableNameEnum.BATCH_MEASUREMENTS_TABLE_NAME}
    />
    <IrisBatchMeasurements machineId={machineId} measurements={measurements} />
  </Stack>
);

IrisBatchRecordsContent.propTypes = {
  details: T.shape({}),
  status: T.shape({}),
  target: T.shape({}),
  limits: T.shape({}),
  measurements: T.shape({}),
  machineId: T.string.isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

IrisBatchRecordsContent.defaultProps = {
  details: null,
  status: null,
  target: null,
  limits: null,
  measurements: null
};

export default withLoadingState(IrisBatchRecordsContent);
