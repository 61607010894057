import { SET_CONFIRMATION_REQUEST, RESOLVE_CONFIRMATION_REQUEST } from './constants';

/**
 * Open a confirmation dialog
 * @param {
 *   message: string,
 *   title: string|undefined,
 *   extraValue: string|object|element|undefined,
 *   yesVariant: 'secondary'|'contained'|undefined,
 *   yesColor: 'secondary'|'primary'|undefined
 * } confirmation
 * @returns {{confirmation, type: string}}
 */
const setConfirmationRequest = confirmation => ({
  type: SET_CONFIRMATION_REQUEST,
  confirmation
});

const resolveConfirmationRequest = () => ({
  type: RESOLVE_CONFIRMATION_REQUEST
});

export { setConfirmationRequest, resolveConfirmationRequest };
