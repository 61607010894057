import T from 'prop-types';

export const userObjectType = T.shape({
  email: T.string.isRequired,
  first_name: T.string.isRequired,
  last_name: T.string.isRequired,
  organization_id: T.string.isRequired, // FIXME - This will become multiple organization at some point.
  created_at: T.string.isRequired,
  updated_at: T.string.isRequired,
  last_login: T.string,
  photo: T.string,
  phone: T.shape({
    country: T.string,
    phone: T.string
  }),
  roles: T.arrayOf(
    T.shape({
      product: T.string.isRequired,
      role: T.string.isRequired
    }).isRequired
  )
});

export const getUsersPaginatedReturn = T.shape({
  total_pages: T.number.isRequired,
  limit: T.number.isRequired,
  total_length: T.number.isRequired,
  data: T.arrayOf(userObjectType).isRequired
});
