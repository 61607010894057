import { useEffect, useState } from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

const { caches } = window;

const CacheBuster = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [isLatestVersion, setIsLatestVersion] = useState(false);
  const refreshCacheAndReload = async () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      const names = await caches.keys();
      await Promise.all(
        // eslint-disable-next-line array-callback-return
        names.map(name => {
          caches.delete(name);
          // delete browser cache and hard reload
          window.location.reload();
        })
      );
    }
  };

  const semverGreatThen = (versionA, versionB) => {
    const versionsA = versionA.split(/\.|-/g);
    const versionsB = versionB.split(/\.|-/g);

    while (versionsA.length || versionsB.length) {
      const a = versionsA.shift();
      const b = versionsB.shift();

      // eslint-disable-next-line no-continue
      if (a === b) continue;

      return a !== b;
    }
    return false;
  };

  useEffect(() => {
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        const latestVerson = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreatThen(latestVerson, currentVersion);

        if (shouldForceRefresh) {
          setLoading(false);
          setIsLatestVersion(false);
        } else {
          setLoading(false);
          setIsLatestVersion(true);
        }
      });
  }, []);

  return children({ loading, isLatestVersion, refreshCacheAndReload });
};

export { CacheBuster };
