import axios from './axios';

/**
 * Invite a new user - V2
 * Documentation: https://service-api-staging.get-notify.com/api/v2/docs#tag/Users/operation/PostUsersInvite
 * @param {{
 *    organization_id: string,
 *    user: {
 *      email: string, roles: Array.<{product: 'preventive'|'notify',
 *      role: 'coordinator'|'technician'|'manager'|'operator'|'no_access'}>
 *    },
 *  }} inviteUser
 * @returns {*}
 */
const apiInviteUserV2 = inviteUser =>
  axios({
    method: 'post',
    url: '/v2/users/invite',
    skipAuthRefresh: true,
    data: inviteUser
  });

/**
 * Get Users Paginated - This will return an array of users at paginated default return
 * Remember, this endpoint will return the new type of uses interface. Take a look at the documentation in V2
 * Documentation: https://service-api-staging.get-notify.com/api/v2/docs#tag/Users/operation/GetUsersOrganizationId
 * @param {{
 *   organization_id: string,
 *   limit: number,
 *   page: number,
 *   is_active: boolean
 * }} request
 * @returns {*}
 */
const apiGetUsersPaginatedV2 = request =>
  axios({
    method: 'get',
    url: `/v2/users/${request.organization_id}`,
    skipAuthRefresh: true,
    params: request
  });

/**
 * Update user roles V2
 * @param {{
 *   user_id: string,
 *   updated_by_user_id: string,
 *   roles: Array.<{
 *     product: 'preventive'|'notify',
 *     role: 'coordinator'|'technician'|'manager'|'operator'|'no_access',
 *   }>
 * }} updateUserRolesV2Request
 * @returns {*}
 */
const apiUpdateUserRolesV2 = updateUserRolesV2Request =>
  axios({
    method: 'put',
    url: `/v2/users/${updateUserRolesV2Request.user_id}/roles`,
    skipAuthRefresh: true,
    data: updateUserRolesV2Request
  });

export { apiInviteUserV2, apiGetUsersPaginatedV2, apiUpdateUserRolesV2 };
