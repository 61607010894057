import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { Box, Card, Divider, IconButton, Stack, Typography } from '@mui/material';
import { FormElements, MachineIcon, NotifyIcon, NotifyTypo } from 'web-components';
import { getSensorPropsFromType, isSuccess } from 'helpers/utils';
import { getMillSensor } from 'attrs/sensorType';
import { useTranslation } from 'react-i18next';
import { getSensorProps } from 'helpers/machine';
import { useDispatch, useSelector } from 'react-redux';
import { loadMetricsMachineDetails, updateSensorStatus } from 'redux/machines/actions';
import DeleteSensor from '../DeleteSensorModal/DeleteSensorModal';
import { getMachines } from '../../../../../redux/rootSelectors';
import { getPeriodsListInSecondsKeyByValue } from '../../../../../attrs/sensorConfig';
import DeleteIrisSensor from '../DeleteIrisSensor';
import { flipIrisSensorStatus } from '../../../../../redux/sensor/actions';
import { StyledToggleContainer } from './elements';

const SensorMap = ({ sensors, machineId, editCustomModal, editPreDefinedModal, editIrisModal }) => {
  const [deleteSensor, setDeleteSensor] = useState(false);
  const [deleteSensorIris, setDeleteSensorIris] = useState(false);
  const [idStringCustom, setIdStringCustom] = useState('');
  const [idStringIris, setIdStringIris] = useState('');
  const [idStringDefined, setIdStringDefined] = useState('');
  const [listCustomSensorIsActive, setListCustomSensorIsActive] = useState(
    sensors?.sensors.custom?.map(item => item.is_active)
  );
  const [listIrisSensorIsActive, setListIrisSensorIsActive] = useState(
    sensors?.sensors.iris?.map(item => item.is_active)
  );
  const [listPreDefSensorIsActive, setListPreDefSensorIsActive] = useState(
    sensors?.sensors.pre_def?.map(item => item.is_active)
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateSensorStatus: updateSensorStatusSelector } = useSelector(getMachines);

  useEffect(() => {
    if (isSuccess(updateSensorStatusSelector.status)) {
      dispatch(loadMetricsMachineDetails(machineId));
    }
  }, [updateSensorStatusSelector]);

  const handleCloseDelete = () => setDeleteSensor(false);
  const handleCloseDeleteIris = () => {
    setDeleteSensorIris(false);
    setIdStringIris(null);
  };

  const handleDeleteCustom = itemId => {
    setIdStringCustom(`custom_sensor_id_${itemId}`);
    setIdStringDefined(null);
    setDeleteSensor(true);
  };

  const handleDeleteIris = itemId => {
    setIdStringIris(`${itemId}`);
    setDeleteSensorIris(true);
  };

  const handleDeletePreDef = itemId => {
    setIdStringDefined(`pre_def_sensor_id_${itemId}`);
    setIdStringCustom(null);
    setDeleteSensor(true);
  };

  const handleActiveCustomSensor = (itemParam, idxParam) => {
    setListCustomSensorIsActive(
      listCustomSensorIsActive.map((value, idx) => {
        if (idxParam === idx) return !value;
        return value;
      })
    );
    const payload = {
      machine_id: machineId,
      custom_sensor_id: itemParam.custom_sensor_id
    };
    dispatch(updateSensorStatus(machineId, payload));
  };

  const handleActiveIrisSensor = (itemParam, idxParam) => {
    setListIrisSensorIsActive(
      listIrisSensorIsActive.map((value, idx) => {
        if (idxParam === idx) return !value;
        return value;
      })
    );
    dispatch(flipIrisSensorStatus(machineId, itemParam.id));
  };

  const handleActivePreDefSensor = (itemParam, idxParam) => {
    setListPreDefSensorIsActive(
      listPreDefSensorIsActive.map((value, idx) => {
        if (idxParam === idx) return !value;
        return value;
      })
    );
    const payload = {
      machine_id: machineId,
      pre_def_sensor_id: itemParam.pre_def_sensor_id
    };
    dispatch(updateSensorStatus(machineId, payload));
  };

  function isCustomSensorActive(idx) {
    if (listCustomSensorIsActive.length > 0) return listCustomSensorIsActive[idx];
    return false;
  }

  function isIrisSensorActive(idx) {
    if (listIrisSensorIsActive.length > 0) return listIrisSensorIsActive[idx];
    return false;
  }

  function isPreDefSensorActive(idx) {
    if (listPreDefSensorIsActive.length > 0) return listPreDefSensorIsActive[idx];
    return false;
  }

  return (
    <Box>
      {sensors?.sensors.custom?.map((item, idx) => (
        <Card
          sx={{ px: 3, py: 2, my: 2, display: 'flex', justifyContent: 'space-between' }}
          key={item.custom_sensor_id}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MachineIcon
              iconName={getSensorProps(item.custom_icon_type).icon}
              style={{
                fontSize: '2rem'
              }}
              viewBox="0 0 32 32"
              status="OK"
            />
            <NotifyTypo.Heading1 fontWeight="500" sx={{ mx: 1 }}>
              {item.name}
            </NotifyTypo.Heading1>
            <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '58px' }}>
              {t('machines.form.sensor.source')}: {item.source}
            </NotifyTypo.Subtitle1>
            <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '30px' }}>
              {t('machines.form.sensor.units')}: {item.units}
            </NotifyTypo.Subtitle1>
            <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '46px' }}>
              {t('machines.form.sensor.period')}: {item.custom_period}
            </NotifyTypo.Subtitle1>
            <Typography sx={{ fontSize: 14, marginLeft: 1, textWrap: 'nowrap' }} color="text.secondary">
              {t('machines.form.sensor.config_custom')}
            </Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => editCustomModal(item)}>
              <NotifyIcon color="primary" fontSize="large" iconName="edit" />
            </IconButton>
            <IconButton onClick={() => handleDeleteCustom(item.custom_sensor_id)}>
              <NotifyIcon color="primary" fontSize="large" iconName="delete" />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <StyledToggleContainer>
              <FormElements.ToggleButton
                label={
                  isCustomSensorActive(idx) ? t('machines.form.sensor.active') : t('machines.form.sensor.inactive')
                }
                name={`activeCustomSensor_${idx}`}
                id={`activeCustomSensor_${idx}`}
                size="large"
                margin="none"
                checked={isCustomSensorActive(idx)}
                onChange={() => handleActiveCustomSensor(item, idx)}
                disabled={false}
              />
            </StyledToggleContainer>
          </Stack>
        </Card>
      ))}
      {sensors?.sensors.pre_def?.map((item, idx) => {
        const type = getSensorPropsFromType(item.type).mapValue;
        const mill = getMillSensor(item.type);
        const { isSubItem } = mill;
        return (
          <Card
            sx={{ px: 3, py: 2, my: 2, display: 'flex', justifyContent: 'space-between' }}
            key={item.pre_def_sensor_id}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <MachineIcon
                iconName={getSensorProps(type).icon}
                style={{
                  fontSize: '2rem'
                }}
                status="OK"
                viewBox="0 0 32 32"
              />
              <NotifyTypo.Heading1 fontWeight="500" sx={{ mx: 1 }}>
                {`${t(`${mill.name}`)}`} {isSubItem ? `[${item.units}]` : ''}
              </NotifyTypo.Heading1>
              <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '58px' }}>
                {t('machines.form.sensor.source')}: {item.source}
              </NotifyTypo.Subtitle1>
              <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '30px' }}>
                {t('machines.form.sensor.units')}: {item.units}
              </NotifyTypo.Subtitle1>
              <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '46px' }}>
                {t('machines.form.sensor.period')}: {item.period}
              </NotifyTypo.Subtitle1>
            </Box>
            <Stack direction="row" spacing={1}>
              <IconButton onClick={() => editPreDefinedModal(item)}>
                <NotifyIcon color="primary" fontSize="large" iconName="edit" />
              </IconButton>
              <IconButton onClick={() => handleDeletePreDef(item.pre_def_sensor_id)}>
                <NotifyIcon color="primary" fontSize="large" iconName="delete" />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <StyledToggleContainer>
                <FormElements.ToggleButton
                  label={
                    isPreDefSensorActive(idx) ? t('machines.form.sensor.active') : t('machines.form.sensor.inactive')
                  }
                  name={`activePreDefSensor_${idx}`}
                  id={`activePreDefSensor_${idx}`}
                  size="large"
                  margin="none"
                  checked={isPreDefSensorActive(idx)}
                  onChange={() => handleActivePreDefSensor(item, idx)}
                  disabled={false}
                />
              </StyledToggleContainer>
            </Stack>
          </Card>
        );
      })}
      {sensors?.sensors.iris?.map((item, idx) => (
        <Card sx={{ px: 3, py: 2, my: 2, display: 'flex', justifyContent: 'space-between' }} key={item.id}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MachineIcon
              iconName={getSensorProps(item.icon_type).icon}
              style={{
                fontSize: '2rem'
              }}
              viewBox="0 0 32 32"
              status="OK"
            />
            <NotifyTypo.Heading1 fontWeight="500" sx={{ mx: 1 }}>
              {item.name}
            </NotifyTypo.Heading1>
            <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '58px' }}>
              {`${t('machines.form.sensor.source')}: ${item.variable_name}`}
            </NotifyTypo.Subtitle1>
            <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '30px' }}>
              {`${t('machines.form.sensor.units')}: ${item.unit}`}
            </NotifyTypo.Subtitle1>
            <NotifyTypo.Subtitle1 fontWeight="400" sx={{ mx: 1, minWidth: '46px' }}>
              {`${t('machines.form.sensor.period')}: ${t(
                `machines.form.sensor.periods.${getPeriodsListInSecondsKeyByValue(item.interval)}`
              )}`}
            </NotifyTypo.Subtitle1>
          </Box>
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => editIrisModal(item)}>
              <NotifyIcon color="primary" fontSize="large" iconName="edit" />
            </IconButton>
            <IconButton onClick={() => handleDeleteIris(item.id)}>
              <NotifyIcon color="primary" fontSize="large" iconName="delete" />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <StyledToggleContainer>
              <FormElements.ToggleButton
                label={isIrisSensorActive(idx) ? t('machines.form.sensor.active') : t('machines.form.sensor.inactive')}
                name={`activeCustomSensor_${idx}`}
                id={`activeCustomSensor_${idx}`}
                size="large"
                margin="none"
                checked={isIrisSensorActive(idx)}
                onChange={() => handleActiveIrisSensor(item, idx)}
                disabled={false}
              />
            </StyledToggleContainer>
          </Stack>
        </Card>
      ))}

      <DeleteSensor
        handleClose={handleCloseDelete}
        open={deleteSensor}
        sensorType={idStringCustom ?? idStringDefined}
        machineId={machineId}
      />

      <DeleteIrisSensor
        handleClose={handleCloseDeleteIris}
        open={deleteSensorIris}
        sensorId={idStringIris}
        machineId={machineId}
      />
    </Box>
  );
};

SensorMap.propTypes = {
  sensors: T.shape({
    sensors: T.shape({
      pre_def: T.arrayOf(T.shape({})),
      custom: T.arrayOf(T.shape({})),
      static: T.arrayOf(T.shape({})),
      iris: T.arrayOf(T.shape({}))
    })
  }),
  machineId: T.string,
  editCustomModal: T.func.isRequired,
  editPreDefinedModal: T.func.isRequired,
  editIrisModal: T.func.isRequired
};

SensorMap.defaultProps = {
  sensors: null,
  machineId: null
};

export default SensorMap;
