import { isValidNumber } from '../../helpers/utils';

const getValue = value => {
  if (isValidNumber(value)) {
    return value;
  }
  return null;
};

const convertToValidMachineBackendObject = values => {
  const originalStatuses = values.configuration.plc.statuses;
  const originalMetrics = (values.configuration.plc || {}).metrics || [];
  const statuses = {
    datatype: originalStatuses.datatype
  };
  const originalMechanicalConfig = values.mechanical_config;
  const mechanicalConfig = {};

  // Note: For status codes that are not filled out,
  // BE is expecting null value instead of the object, e.g.
  // "maintenance": null
  Object.keys(originalStatuses).forEach(status => {
    if (status !== 'datatype') {
      if (!originalStatuses[status] || !originalStatuses[status].address || originalStatuses[status].address === '') {
        statuses[status] = null;
      } else {
        statuses[status] = originalStatuses[status];
      }
    }
  });

  let metrics = originalMetrics
    .filter(metric => !metric.is_static)
    .map(metric => ({
      ...metric,
      range: {
        max: {
          value: getValue((((metric || {}).range || {}).max || {}).value),
          source: (((metric || {}).range || {}).max || {}).source || null
        },
        min: {
          value: getValue((((metric || {}).range || {}).min || {}).value),
          source: (((metric || {}).range || {}).min || {}).source || null
        }
      },
      y_axis: {
        max: {
          specified: (((metric || {}).y_axis || {}).max || {}).specified || false,
          value: getValue((((metric || {}).y_axis || {}).max || {}).value)
        },
        min: {
          specified: (((metric || {}).y_axis || {}).min || {}).specified || false,
          value: getValue((((metric || {}).y_axis || {}).min || {}).value)
        }
      },
      multiplication_factor: metric.multiplication_factor || null
    }));

  metrics = [...metrics, ...originalMetrics.filter(metric => metric.is_static)];

  Object.keys(originalMechanicalConfig).forEach(config => {
    mechanicalConfig[config] = originalMechanicalConfig[config];
    if (originalMechanicalConfig[config] === '' || originalMechanicalConfig[config] === undefined) {
      mechanicalConfig[config] = null;
    }
  });

  return {
    ...values,
    configuration: {
      ...values.configuration,
      plc: {
        ...values.configuration.plc,
        statuses,
        metrics
      }
    },
    mechanical_config: mechanicalConfig,
    // TODO: In the future the machine will have the option to choose if will be Preventive machine too, change here
    is_preventive: false,
    is_notify: true
  };
};

export { convertToValidMachineBackendObject };
