import React from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NotifyTypo } from 'web-components';
import { LogoContainer, PageWrapper } from './elements';
import LogoNetzsch from '../../../attrs/logo_netzsch';
import LoginForm from '../../../components/forms/Auth/LoginForm';

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Container maxWidth="sm">
        <LogoContainer>
          <LogoNetzsch height="65px" />
          <NotifyTypo.Heading1 sx={{ mb: '2rem' }}>{t('auth.title_netzsch')}</NotifyTypo.Heading1>
        </LogoContainer>
        <LoginForm />
      </Container>
    </PageWrapper>
  );
};

export default SignIn;
