import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from 'attrs/pagination';
import { MACHINE_DEFAULT_LIST_ORDER } from 'attrs/machines';
import {
  CREATE_LICENSE,
  EDIT_LICENSE,
  LOAD_LICENSES,
  LOAD_LICENSES_RESUME,
  RENEW_LICENSE,
  SET_LICENSES
} from './constants';

export const createLicense = values => ({
  type: CREATE_LICENSE,
  values
});

export const updateLicense = (values, organizationId) => ({
  type: EDIT_LICENSE,
  values,
  organizationId
});

export const loadLicenses = organizationId => ({
  type: LOAD_LICENSES,
  organizationId
});

export const loadLicensesByResume = (
  licenseId = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = MACHINE_DEFAULT_LIST_ORDER
) => ({
  type: LOAD_LICENSES_RESUME,
  licenseId,
  limit,
  page,
  order
});

export const setLicenses = () => ({
  type: SET_LICENSES,
  licenses: []
});

export const renewLicense = (license, organizationId) => ({
  type: RENEW_LICENSE,
  license,
  organizationId
});
