import styled from 'styled-components';
import { Avatar, TableCell, TableContainer } from '@mui/material';
import { NotifyIcon } from 'web-components';
import { COLOR_PRIMARY, COLOR_PRIMARY_CONTRAST, SECONDARY_TEXT_COLOR } from '../../attrs/colors';

const StyledMobileTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: 0;
    padding: 0.5rem;
  }
`;

const StyledTableContainer = styled(TableContainer)`
  &.MuiTableContainer-root {
    margin: 1rem 0;
    padding: 0.5rem;
  }
`;

export const FilterContainer = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

const StyledNotifyIcon = styled(NotifyIcon)`
  &.MuiSvgIcon-root {
    color: ${COLOR_PRIMARY_CONTRAST};
    margin-left: 0.5rem;
    height: 3.4rem;
    width: 3.4rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: ${COLOR_PRIMARY};
  }
`;

const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-colorDefault {
    color: ${COLOR_PRIMARY};
    background-color: ${COLOR_PRIMARY}1A;
  }
`;

const StyledAvatarNoUser = styled(Avatar)`
  &.MuiAvatar-colorDefault {
    color: ${COLOR_PRIMARY};
    background-color: ${COLOR_PRIMARY}1A;
  }

  & .MuiAvatar-img {
    width: 200%;
    height: 200%;
  }
`;

const StyledSmall = styled.small`
  color: ${SECONDARY_TEXT_COLOR};
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

export {
  StyledMobileTableCell,
  StyledTableContainer,
  StyledNotifyIcon,
  StyledAvatar,
  StyledAvatarNoUser,
  StyledSmall,
  MenuProps
};
