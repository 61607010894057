import React from 'react';
import { Button } from '@mui/material';
import T, { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { NotifyTypo } from 'web-components';
import { useDispatch } from 'react-redux';
import { StyledContent, StyledHandlers, StyledNewDialog } from 'components/CustomerDetails/GatewayTable/elements';
import { useParams } from 'react-router-dom';
import { gatewayV2UnbindMachineAction } from '../../../../redux/gateways_v2/actions';

const labelAmmend = 'gateways.detail.unlink_modal';

const UnLinkGateway = ({ open, handleClose, gatewayId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { machineId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const handleUnLinkGateway = async () => {
    try {
      dispatch(gatewayV2UnbindMachineAction({ gatewayId, machineId, reloadMachines: true, reloadGtwMachines: false }));
      handleClose(true);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.error_create_clone'), errorToastProps);
      }
    }
  };

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={() => handleClose()}>
        {t(`${labelAmmend}.cancel`)}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        color="error"
        onClick={handleUnLinkGateway}
      >
        {t(`${labelAmmend}.action`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      open={open}
      handleClose={handleClose}
      title={t(`${labelAmmend}.title`)}
      subTitle={t(`${labelAmmend}.subtitle`)}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="680px"
      content={
        <StyledContent>
          <NotifyTypo.Heading2>{t(`${labelAmmend}.text`)}</NotifyTypo.Heading2>
        </StyledContent>
      }
    />
  );
};

UnLinkGateway.defaultProps = {
  gatewayId: null
};

UnLinkGateway.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  gatewayId: T.string
};

export default UnLinkGateway;
