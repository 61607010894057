import styled from 'styled-components';
import { Container, Box } from '@mui/material';

import { GeneralInformationForm } from 'components/forms/Customers/FormElements/GeneralInformationForm';
import { COLOR_BORDER, LIGHT_GREY } from 'attrs/colors';
import { Form } from 'formik';

export const CustomerPageHeader = styled.div`
  margin-bottom: 2.188rem;
`;

export const CustomerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
`;

export const StyledContainer = styled(Container)`
  padding-top: 0.063rem;
  @media (min-width: 1440px) {
    max-width: 1440px !important;
    padding-left: unset !important;
    padding-right: unset !important;
  }
`;

export const StyledHandlers = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${LIGHT_GREY};
  padding: 1.25rem 2rem 1.313rem 2.5rem;
  border-top: 1px solid ${COLOR_BORDER};
`;

export const StyledGeneralInformationForm = styled(GeneralInformationForm)`
  padding: 2.5rem 2.5rem 6rem;
  flex: 1;
`;

export const StyleForm = styled(Form)`
  width: 100%;
  height: 100%;
`;
