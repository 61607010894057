import React, { useLayoutEffect, useState } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useResolutionCheck } from 'web-components';

import withLoadingState from '../../../helpers/withLoadingState';
import { isLoading } from '../../../helpers/utils';
import { getMachines } from '../../../redux/rootSelectors';
import { resetMachineState, updateMachine } from '../../../redux/machines/actions';
import { createTabDefinitions, tabCreator } from '../../../components/forms/Machines/MachineDetail/tabs';
import { MachineDetailTablet } from './MachineDetailTablet';

const MachineDetail = ({ machine, plcType }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isMobile, isSmallDevice } = useResolutionCheck();
  const { updateLoadingState } = useSelector(getMachines);
  const [selected, setSelected] = useState(0);
  const [showBatch, setShowBatch] = useState(machine.enabled_config?.batch);
  const [collapseMenu, setCollapseMenu] = useState(false);

  let tabDefinitions = createTabDefinitions(showBatch);
  const selectedTab = tabDefinitions[selected];

  const isSubmitting = isLoading(updateLoadingState.status);

  const handleFormSubmit = values => {
    dispatch(updateMachine(machine.id, values));
  };

  const handleFeatureChange = (name, value) => {
    if (name === 'enabled_config.batch') {
      setShowBatch(value);
      tabDefinitions = createTabDefinitions(value);
    }
  };

  const handleCollapseSidebar = () => {
    if (collapseMenu) {
      setCollapseMenu(false);
    } else {
      setCollapseMenu(true);
    }
  };

  useLayoutEffect(
    () => () => {
      dispatch(resetMachineState());
    },
    [dispatch]
  );
  return (
    <>
      <MachineDetailTablet
        handleFeatureChange={handleFeatureChange}
        handleCollapseSidebar={handleCollapseSidebar}
        setSelected={setSelected}
        tabDefinitions={tabDefinitions}
        isSmallDevice={isSmallDevice}
        updateLoadingState={updateLoadingState}
        t={t}
        machine={machine}
        collapseMenu={collapseMenu}
        tabCreator={tabCreator}
        handleFormSubmit={handleFormSubmit}
        theme={theme}
        isMobile={isMobile}
        selected={selected}
        selectedTab={selectedTab}
        isSubmitting={isSubmitting}
        plcType={plcType}
      />
    </>
  );
};

MachineDetail.propTypes = {
  machine: T.shape({
    id: T.string.isRequired,
    type: T.string.isRequired,
    enabled_config: T.shape({
      batch: T.bool
    })
  }).isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default withLoadingState(MachineDetail);
