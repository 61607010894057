import styled, { css } from 'styled-components';
import { Card, Avatar } from '@mui/material';
import { COLOR_PRIMARY } from '../../attrs/colors';

const StyledCard = styled(Card)`
  &.withPointer {
    cursor: pointer;
  }
  &.MuiPaper-root {
    padding: 1.25rem 1.25rem;
    margin-bottom: 1rem;
    ${props =>
      props.selected &&
      css`
        background: linear-gradient(0deg, rgba(45, 179, 165, 0.05), rgba(45, 179, 165, 0.05)), #ffffff;
        border: 1px solid #2db3a5;
      `}
  }
`;

const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-colorDefault {
    color: ${COLOR_PRIMARY};
    background-color: ${COLOR_PRIMARY}1A;
  }
`;

export { StyledCard, StyledAvatar };
