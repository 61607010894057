import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGatewaysLostSignal } from 'redux/gateways/selectors';
import Footer from 'components/DataTable/Footer';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'web-components';
import { Box, Chip, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { getValueToNullableCell } from 'helpers/stringHandler';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { loadGatewaysLostSignal } from '../../redux/gateways/actions';
import { isLoading, isSuccess } from '../../helpers/utils';

export function GatewayLostConnection() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loadingState: gatewayLostConnectionState, data: gatewayLostConnectionResponse } =
    useSelector(getGatewaysLostSignal);
  const gatewayLostConnectionIsLoading = isLoading(gatewayLostConnectionState.status);
  const gatewayLostConnectionLoaded = isSuccess(gatewayLostConnectionState.status);
  const [interval, setInterval] = useState('30d');
  const [totalPages, setTotalPages] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [limit, setLimit] = useState(0);
  const [gatewayList, setGatewayList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    dispatch(loadGatewaysLostSignal());
  }, [dispatch]);

  useEffect(() => {
    if (gatewayLostConnectionLoaded) {
      setCurrentPage(gatewayLostConnectionResponse.page || 0);
      setTotalPages(gatewayLostConnectionResponse.total_pages || 0);
      setTotalLength(gatewayLostConnectionResponse.total_length || 0);
      setLimit(gatewayLostConnectionResponse.limit || 0);
      setGatewayList(gatewayLostConnectionResponse.data);
    }
  }, [gatewayLostConnectionLoaded]);

  const handleIntervalChange = event => {
    const intervalParam = event.target.value;
    setInterval(intervalParam);
    dispatch(loadGatewaysLostSignal(intervalParam, rowsPerPage, currentPage));
  };

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(loadGatewaysLostSignal(interval, rowsPerPage, page));
  };

  const gatewayLostConnTableLabel = 'dashboard.tabs.gateway.lost_connection.table';
  const columnsGatewayLostConnection = [
    {
      id: 'serial_number',
      label: t(`${gatewayLostConnTableLabel}.gateway_id`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.serial_number)
    },
    {
      id: 'organization_name',
      label: t(`${gatewayLostConnTableLabel}.customer`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.organization_name)
    },
    {
      id: 'actual_version',
      label: t(`${gatewayLostConnTableLabel}.system_version`),
      sortable: false,
      cellRenderer: row => getValueToNullableCell(row?.actual_version)
    },
    {
      id: 'last_seen',
      label: t(`${gatewayLostConnTableLabel}.last_signal`),
      sortable: false,
      cellRenderer: row => {
        const dt = new Date(row?.last_seen);
        return (
          <>
            {t(`${gatewayLostConnTableLabel}.last_signal_value`, {
              date: dt.toLocaleDateString(),
              time: dt.toLocaleTimeString(),
              interpolation: { escapeValue: false }
            })}
          </>
        );
      }
    }
  ];

  const propsGatewayLostConn = {
    dataSelectorTable: 'lost-connection-table',
    dataSelectorRows: 'lost-connection-table-row',
    isLoading: gatewayLostConnectionIsLoading,
    columns: columnsGatewayLostConnection,
    rows: gatewayList,
    hover: undefined,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={totalPages}
        totalLength={totalLength}
        totalItemsPerPage={limit}
        onChange={handleChangePage}
      />
    )
  };

  const gatewayLostConnGeneralLabel = 'dashboard.tabs.gateway.lost_connection';
  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 1.5
      }}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Typography sx={{ fontSize: 16 }} gutterBottom>
              {t(`${gatewayLostConnGeneralLabel}.caption`)}
            </Typography>
            <Chip
              label={t(`${gatewayLostConnGeneralLabel}.total`, { total: totalLength })}
              size="small"
              sx={{ backgroundColor: '#DEF1EE' }}
            />
          </Stack>
          <FormControl variant="filled">
            <Select
              fullWidth
              IconComponent={KeyboardArrowDownIcon}
              labelId="sensor-type-label"
              id="sensor-type-select"
              onChange={handleIntervalChange}
              value={interval}
            >
              <MenuItem key="30d" value="30d">
                {t(`${gatewayLostConnGeneralLabel}.last_days`, { days: 30 })}
              </MenuItem>
              <MenuItem key="60d" value="60d">
                {t(`${gatewayLostConnGeneralLabel}.last_days`, { days: 60 })}
              </MenuItem>
              <MenuItem key="90d" value="90d">
                {t(`${gatewayLostConnGeneralLabel}.last_days`, { days: 90 })}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <DataTable hover {...propsGatewayLostConn} />
      </Stack>
    </Box>
  );
}
