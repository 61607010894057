import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import T from 'prop-types';
import BatchRecordsContent from './BatchRecordsContent';
import { loadBatchRecordsMachine } from '../../../../redux/machines/actions';
import { getBatchRecordsMachine } from '../../../../redux/machines/selectors';
import { PlcType } from '../../../../attrs/plcConfigValues';
import {
  getSelectedIrisBatchDetails,
  getSelectedIrisBatchLimits,
  getSelectedIrisBatchMeasurements,
  getSelectedIrisBatchStatus,
  getSelectedIrisBatchTarget
} from '../../../../redux/machines_v2/selectors';
import { getCommonLoadingState } from '../../../../helpers/utils';
import {
  loadIrisBatchDetails,
  loadIrisBatchLimits,
  loadIrisBatchMeasurements,
  loadIrisBatchStatus,
  loadIrisBatchTarget
} from '../../../../redux/machines_v2/actions';
import IrisBatchRecordsContent from './IrisBatchRecordsContent';

const ConnectedBatchRecordsContent = ({ plcType }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getBatchRecordsMachine);
  const { loadingState: loadingIrisBatchDetailsState, data: batchDetails } = useSelector(getSelectedIrisBatchDetails);
  const { loadingState: loadingIrisBatchStatusState, data: batchStatus } = useSelector(getSelectedIrisBatchStatus);
  const { loadingState: loadingIrisBatchTargetState, data: batchTarget } = useSelector(getSelectedIrisBatchTarget);
  const { loadingState: loadingIrisBatchLimitsState, data: batchLimits } = useSelector(getSelectedIrisBatchLimits);
  const { loadingState: loadingIrisBatchMeasurementsState, data: batchMeasurements } = useSelector(
    getSelectedIrisBatchMeasurements
  );
  const { machineId } = useParams();

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(loadIrisBatchDetails(machineId));
      dispatch(loadIrisBatchStatus(machineId));
      dispatch(loadIrisBatchTarget(machineId));
      dispatch(loadIrisBatchLimits(machineId));
      dispatch(loadIrisBatchMeasurements(machineId));
    } else {
      dispatch(loadBatchRecordsMachine(machineId));
    }
  }, [dispatch, machineId, plcType.plc_type]);

  if (plcType.plc_type === PlcType.IRIS_V3) {
    return (
      <IrisBatchRecordsContent
        loadingState={getCommonLoadingState([
          loadingIrisBatchDetailsState,
          loadingIrisBatchStatusState,
          loadingIrisBatchTargetState,
          loadingIrisBatchLimitsState,
          loadingIrisBatchMeasurementsState
        ])}
        reloadAction={() => {
          dispatch(loadIrisBatchDetails(machineId));
          dispatch(loadIrisBatchStatus(machineId));
          dispatch(loadIrisBatchTarget(machineId));
          dispatch(loadIrisBatchLimits(machineId));
          dispatch(loadIrisBatchMeasurements(machineId));
        }}
        details={batchDetails}
        status={batchStatus}
        target={batchTarget}
        limits={batchLimits}
        measurements={batchMeasurements}
        machineId={machineId}
        plcType={plcType}
      />
    );
  }

  return (
    <BatchRecordsContent
      loadingState={loadingState}
      reloadAction={() => dispatch(loadBatchRecordsMachine(machineId))}
      data={data}
      machineId={machineId}
      plcType={plcType}
    />
  );
};

ConnectedBatchRecordsContent.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default ConnectedBatchRecordsContent;
