import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import T, { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotifyTypo } from 'web-components';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading } from 'helpers/utils';
import { StyledHandlers } from '../../elements';
import { StyledContent, StyledNewDialog } from '../elements';
import { deleteIrisSensor } from '../../../../../redux/sensor/actions';
import { getIrisSensors } from '../../../../../redux/sensor/selectors';

const labelAmend = 'machines.machine_details.delete_modal';

const DeleteIrisSensor = ({ open, handleClose, machineId, sensorId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deletingIrisSensorStatus } = useSelector(getIrisSensors);

  const handleDeleteIrisSensor = async () => {
    dispatch(deleteIrisSensor(machineId, sensorId));
    handleClose();
  };

  function isDeleting() {
    return isLoading(deletingIrisSensorStatus.status);
  }

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={() => handleClose()} disabled={isDeleting()}>
        {t(`${labelAmend}.cancel`)}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        color="error"
        onClick={handleDeleteIrisSensor}
        disabled={isDeleting()}
      >
        {t(`${labelAmend}.action`)}
        {isDeleting() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      open={open}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      title={t(`${labelAmend}.sensor_title`)}
      subTitle={t(`${labelAmend}.sensor_subtitle`)}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="680px"
      content={
        <StyledContent>
          <NotifyTypo.Body1>{t(`${labelAmend}.sensor_text`)}</NotifyTypo.Body1>
        </StyledContent>
      }
    />
  );
};

DeleteIrisSensor.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  machineId: T.string.isRequired,
  sensorId: T.string.isRequired
};

export default DeleteIrisSensor;
