import React, { memo, useEffect, useState } from 'react';
import { DataTable, NotifyIcon } from 'web-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, getUsersV2 } from 'redux/rootSelectors';
import Footer from 'components/DataTable/Footer';
import { getUsersPaginatedV2Action } from 'redux/users_v2/actions';
import T from 'prop-types';
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import { getInitials, getUserRoleByProduct, isRoleDiffNoAccess, isSuccess } from 'helpers/utils';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { getUserInfo } from 'redux/user/selectors';
import { setConfirmationRequest } from 'redux/ui/confirmations/actions';
import { deleteUserById } from 'redux/users/actions';
import EmptyState from 'components/EmptyState';
import UserEditModal from './UserEditModal';

const ActiveUsers = memo(({ organizationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listStatusEqual = (oldObj, newObj) =>
    oldObj?.usersPaginatedStatus?.status === newObj?.usersPaginatedStatus?.status;
  const { usersPaginatedStatus } = useSelector(getUsersV2, listStatusEqual);
  const [isLoading, setIsLoading] = useState(false);

  const userListEqual = (oldObj, newObj) => {
    const sameSize = oldObj?.usersPaginated?.data?.length === newObj?.usersPaginated?.data?.length;
    let equalsObj = true;
    for (let i = 0; sameSize && i < oldObj?.usersPaginated?.data?.length; i += 1) {
      equalsObj =
        JSON.stringify(oldObj?.usersPaginated?.data[i] || '') === JSON.stringify(newObj?.usersPaginated?.data[i] || '');
      if (!equalsObj) break;
    }
    return (
      JSON.stringify(oldObj?.usersPaginated?.data || '') === JSON.stringify(newObj?.usersPaginated?.data || '') &&
      oldObj?.usersPaginated?.total_length === newObj?.usersPaginated?.total_length &&
      sameSize &&
      equalsObj
    );
  };
  const { usersPaginated: getUsersResponse } = useSelector(getUsersV2, userListEqual);

  const updatingUserStatusEqual = (oldObj, newObj) =>
    oldObj?.updatingUserStatus?.status === newObj?.updatingUserStatus?.status;
  const { updatingUserStatus } = useSelector(getUsersV2, updatingUserStatusEqual);

  const deletingUserStatusEqual = (oldObj, newObj) =>
    oldObj?.deletingUserStatus?.status === newObj?.deletingUserStatus?.status;
  const { deletingUserStatus } = useSelector(getUsers, deletingUserStatusEqual);

  const userEqual = (oldObj, newObj) => oldObj?.user?.data?.id === newObj?.user?.data?.id;
  const { user } = useSelector(getUserInfo, userEqual);

  const labelAmend = 'customers.user_management.list';
  const rowsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [limit, setLimit] = useState(0);
  const [activeUsers, setActiveUsers] = useState([]);
  const [userId, setUserId] = useState();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState();
  const [successUpdatingUser, setSuccessUpdatingUser] = useState(false);
  const [successDeletingUser, setSuccessDeletingUser] = useState(false);

  useEffect(() => {
    dispatch(
      getUsersPaginatedV2Action({
        organization_id: organizationId,
        limit: rowsPerPage,
        page: currentPage,
        is_active: true
      })
    );
  }, [dispatch, getUsersPaginatedV2Action, organizationId]);

  useEffect(() => {
    if (isSuccess(usersPaginatedStatus.status)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [usersPaginatedStatus.status]);

  useEffect(() => {
    if (isSuccess(updatingUserStatus.status)) {
      setSuccessUpdatingUser(true);
    }
  }, [updatingUserStatus.status]);

  useEffect(() => {
    if (isSuccess(deletingUserStatus.status)) {
      setSuccessDeletingUser(true);
    }
  }, [deletingUserStatus.status]);

  useEffect(() => {
    if (successUpdatingUser || successDeletingUser) {
      setSuccessUpdatingUser(false);
      setSuccessDeletingUser(false);
      dispatch(
        getUsersPaginatedV2Action({
          organization_id: organizationId,
          limit: rowsPerPage,
          page: currentPage,
          is_active: true
        })
      );
    }
  }, [successUpdatingUser, successDeletingUser]);

  useEffect(() => {
    const loginUser = user.data;
    if (loginUser && loginUser.id) {
      setUserId(loginUser.id);
    }
  }, [user]);

  useEffect(() => {
    if (getUsersResponse && getUsersResponse.data && getUsersResponse.data.length > 0) {
      setCurrentPage(getUsersResponse.page || 0);
      setTotalPages(getUsersResponse.total_pages || 0);
      setTotalLength(getUsersResponse.total_length || 0);
      setLimit(getUsersResponse.limit || 0);
      setActiveUsers(getUsersResponse.data);
    }
  }, [getUsersResponse]);

  const onClickOpenEditDialog = row => {
    setEditingUser(row);
    setOpenEditModal(true);
  };

  const onClickOpenDeleteDialog = row => {
    dispatch(
      setConfirmationRequest({
        message: 'dialog.confirmation.delete_user',
        extraValue: row?.email,
        action: () => dispatch(deleteUserById(row?.id))
      })
    );
  };

  const columns = [
    {
      id: 'photo',
      label: t(`${labelAmend}.photo`),
      sortable: false,
      cellRenderer: row => (
        <Avatar
          key={`item-avatar-${row?.id}`}
          alt={`${row?.first_name} ${row?.last_name}`}
          src={row?.photo}
          sx={{
            fontSize: 12,
            width: 44,
            height: 44,
            boxShadow: 3,
            ...(!row?.photo && {
              color: '#58BAAA',
              bgcolor: '#CEEAE5',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#DDDDDD80'
            })
          }}
        >
          <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: '550' }}>
            {getInitials(`${row.first_name} ${row.last_name}`)}
          </Typography>
        </Avatar>
      )
    },
    {
      id: 'first_name',
      label: t(`${labelAmend}.first_name`),
      sortable: false
    },
    { id: 'last_name', label: t(`${labelAmend}.last_name`), sortable: false },
    {
      id: 'role_notify',
      label: t(`${labelAmend}.role_notify`),
      cellRenderer: row => {
        const roleNotify = getUserRoleByProduct(row.roles, 'notify');
        const hasAccess = isRoleDiffNoAccess(roleNotify);
        const colorIcon = hasAccess ? '#2BACA4' : '#BCBDC1';
        const colorText = hasAccess ? undefined : '#BCBDC1';
        return (
          <Stack spacing={1} direction="row" alignItems="center">
            <NotifyIcon fontSize="medium" iconName="notifyIcon" sx={{ color: colorIcon }} />
            <Typography sx={{ fontSize: 14, color: colorText }}>{t(`products.roles.${roleNotify}`)}</Typography>
          </Stack>
        );
      }
    },
    {
      id: 'role_preventive',
      label: t(`${labelAmend}.role_prev`),
      cellRenderer: row => {
        const rolePreventive = getUserRoleByProduct(row.roles, 'preventive');
        const hasAccess = isRoleDiffNoAccess(rolePreventive);
        const colorIcon = hasAccess ? '#2BACA4' : '#BCBDC1';
        const colorText = hasAccess ? undefined : '#BCBDC1';
        return (
          <Stack spacing={1} direction="row" alignItems="center">
            <NotifyIcon fontSize="medium" iconName="preventiveIcon" sx={{ color: colorIcon }} />
            <Typography sx={{ fontSize: 14, color: colorText }}>{t(`products.roles.${rolePreventive}`)}</Typography>
          </Stack>
        );
      }
    },
    {
      id: 'email',
      label: t(`${labelAmend}.email`)
    },
    {
      id: 'action',
      cellRenderer: row => (
        <>
          {userId === row.id ? null : (
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <IconButton aria-label="edit" color="inherit" size="medium" onClick={() => onClickOpenEditDialog(row)}>
                <ModeEditOutlineOutlinedIcon color="primary" fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="inherit"
                size="medium"
                onClick={() => onClickOpenDeleteDialog(row)}
              >
                <DeleteForeverOutlinedIcon color="primary" fontSize="inherit" />
              </IconButton>
            </Stack>
          )}
        </>
      )
    }
  ];

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(
      getUsersPaginatedV2Action({
        organization_id: organizationId,
        limit: rowsPerPage,
        page,
        is_active: true
      })
    );
  };

  const propsActiveUsers = {
    dataSelectorTable: 'active-users-table',
    dataSelectorRows: 'active-users-table-row',
    isLoading,
    columns,
    rows: activeUsers,
    hover: undefined,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={totalPages}
        totalLength={totalLength}
        totalItemsPerPage={limit}
        onChange={handleChangePage}
      />
    )
  };
  return (
    <>
      {(activeUsers && activeUsers.length > 0) || isLoading ? (
        <DataTable hover {...propsActiveUsers} />
      ) : (
        <Box sx={{ pb: 2, pt: 4 }}>
          <EmptyState type="user_management_active" />
        </Box>
      )}
      <UserEditModal open={openEditModal} setOpen={setOpenEditModal} user={editingUser} />
    </>
  );
});

ActiveUsers.propTypes = {
  organizationId: T.string.isRequired
};

export default ActiveUsers;
