import React, { useState } from 'react';
import { Box, Divider, FormControl, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateTimeProvider from '../../../../../../components/DateTime/DateTimeProvider';
import { TaskFrequencyByHourmeterCard } from './TaskFrequencyByHourmeterCard';
import { TaskFrequencyByTimeCard } from './TaskFrequencyByTimeCard';
import { DAY, MONTH, WEEK, YEAR } from '../../TaskRepeatTypes';
import { BY_HOUR_METER } from '../../TaskFrequencyTypes';

export function TaskFrequency(props) {
  const { frequency, setFrequency, frequencyType } = props;
  const { t } = useTranslation();
  const [firstUpdate, setFirstUpdate] = useState(false);

  const handleRepeatEveryChange = event => {
    const repeatEveryParam = +event.target.value;
    setFrequency({ ...frequency, repeat_amount: repeatEveryParam });
    if (!firstUpdate) setFirstUpdate(true);
  };

  const handleRepeatEveryHourChange = event => {
    const repeatEveryHourParam = +event.target.value;
    setFrequency({ ...frequency, repeat_hours: repeatEveryHourParam });
    if (!firstUpdate) setFirstUpdate(true);
  };

  const handleRepeatTypeChange = event => {
    const repeatTypeParam = event.target.value;
    setFrequency({ ...frequency, repeat_type: repeatTypeParam });
    if (!firstUpdate) setFirstUpdate(true);
  };

  const handleStartDateChange = startDateParam => {
    setFrequency({ ...frequency, start_date: startDateParam });
    if (!firstUpdate) setFirstUpdate(true);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        p: 2
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Grid container spacing={2} columns={12} alignItems="center">
            <Grid item xs={2}>
              <Typography sx={{ fontSize: { xs: 12, sm: 14 } }} gutterBottom>
                {t('task.general_information.frequency.repeat_every')}
              </Typography>
            </Grid>
            {frequencyType === BY_HOUR_METER ? (
              <>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="repeat_hours"
                    variant="filled"
                    type="number"
                    onChange={handleRepeatEveryHourChange}
                    value={frequency.repeat_hours}
                    InputProps={{
                      inputProps: {
                        min: 1
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: { xs: 12, sm: 14 } }} gutterBottom>
                    {t('task.general_information.frequency.hours_machine_runtime')}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="repeat_every"
                    variant="filled"
                    type="number"
                    onChange={handleRepeatEveryChange}
                    value={frequency.repeat_amount}
                    InputProps={{
                      inputProps: {
                        min: 1
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="filled" fullWidth>
                    <Select
                      fullWidth
                      IconComponent={KeyboardArrowDownIcon}
                      labelId="period_type_label"
                      id="period_type_select"
                      onChange={handleRepeatTypeChange}
                      value={frequency.repeat_type}
                    >
                      <MenuItem key={DAY} value={DAY}>
                        {t('enum.repeat_type.DAY')}
                      </MenuItem>
                      <MenuItem key={WEEK} value={WEEK}>
                        {t('enum.repeat_type.WEEK')}
                      </MenuItem>
                      <MenuItem key={MONTH} value={MONTH}>
                        {t('enum.repeat_type.MONTH')}
                      </MenuItem>
                      <MenuItem key={YEAR} value={YEAR}>
                        {t('enum.repeat_type.YEAR')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <DateTimeProvider>
                    <DatePicker
                      label={t('task.general_information.frequency.start_date')}
                      value={frequency.start_date}
                      onChange={date => {
                        if (!Number.isNaN(new Date(date).getTime())) {
                          const strDate = new Date(date).toISOString();
                          handleStartDateChange(strDate);
                        }
                      }}
                      renderInput={params => <TextField {...params} variant="filled" />}
                    />
                  </DateTimeProvider>
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
        <Divider variant="middle" sx={{ pt: 1 }} />
        <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 'bold' }} gutterBottom>
          {t('task.general_information.frequency.planning')}
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, sm: 16 } }} gutterBottom>
          {t('task.general_information.frequency.upcoming')}
        </Typography>
        {frequencyType === BY_HOUR_METER ? (
          <TaskFrequencyByHourmeterCard
            color="#F19909"
            firstUpdate={firstUpdate}
            repeatEveryTask={frequency.repeat_hours}
            frequency={frequency}
            setFrequency={setFrequency}
          />
        ) : (
          <TaskFrequencyByTimeCard
            color="#F19909"
            firstUpdate={firstUpdate}
            repeatEveryTask={frequency.repeat_amount}
            repeatTypeTask={frequency.repeat_type}
            frequency={frequency}
            setFrequency={setFrequency}
          />
        )}
        <Typography sx={{ fontSize: { xs: 14, sm: 16 } }} gutterBottom>
          {t('task.general_information.frequency.backlog')}
        </Typography>
        {frequencyType === BY_HOUR_METER ? (
          <TaskFrequencyByHourmeterCard
            color="#397CCA"
            firstUpdate={firstUpdate}
            repeatEveryTask={frequency.repeat_hours}
            isBacklog
            frequency={frequency}
            setFrequency={setFrequency}
          />
        ) : (
          <TaskFrequencyByTimeCard
            color="#397CCA"
            firstUpdate={firstUpdate}
            repeatEveryTask={frequency.repeat_amount}
            repeatTypeTask={frequency.repeat_type}
            isBacklog
            frequency={frequency}
            setFrequency={setFrequency}
          />
        )}
      </Stack>
    </Box>
  );
}

TaskFrequency.propTypes = {
  frequency: PropTypes.shape.isRequired,
  setFrequency: PropTypes.func.isRequired,
  frequencyType: PropTypes.shape.isRequired
};
