import React, { useState, useEffect } from 'react';
import { Avatar, Toolbar, Typography } from '@mui/material';
import T from 'prop-types';

import { getInitials } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SubNavigation from './SubNavigation';
import { ButtonWrapper } from './elements';
import { StyledAppBar, AppBarContentWrapper } from '../../elements';
import ConnectedNavigationItems from './NavigationItems';
import LogoLink from './LogoLink';
import { getUserInfo } from '../../redux/user/selectors';

const Navbar = ({ routes }) => {
  const [userFullName, setUserFullName] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [userInitials, setUserInitials] = useState('');
  const { user } = useSelector(getUserInfo);
  const { t } = useTranslation();

  useEffect(() => {
    const loginUser = user.data;
    if (loginUser && loginUser.id) {
      const fullName = `${loginUser.first_name} ${loginUser.last_name}`;
      setUserFullName(fullName);
      setUserPhoto(loginUser.photo);
      setUserInitials(getInitials(fullName));
    }
  }, [user]);

  return (
    <StyledAppBar position="fixed">
      <Toolbar variant="dense">
        <LogoLink />
        <AppBarContentWrapper>
          <ConnectedNavigationItems routes={routes} />
        </AppBarContentWrapper>
        <Typography
          variant="h6"
          noWrap
          sx={{
            display: { xs: 'none', md: 'flex' },
            fontSize: 14,
            fontWeight: 550,
            color: 'inherit',
            textDecoration: 'none',
            mr: 1
          }}
        >
          {t('auth.hello')}
        </Typography>
        <Avatar
          key="userPhoto"
          alt={userFullName}
          src={userPhoto}
          sx={{
            display: { xs: 'none', md: 'flex' },
            width: 24,
            height: 24,
            bgcolor: '#2BACA4',
            color: '#FFFFFF',
            mr: '70px'
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: '550' }}>{userInitials}</Typography>
        </Avatar>
        <ButtonWrapper>
          <SubNavigation routes={routes} />
        </ButtonWrapper>
      </Toolbar>
    </StyledAppBar>
  );
};

Navbar.propTypes = {
  routes: T.arrayOf(T.shape({})).isRequired
};

export default Navbar;
