import styled, { css } from 'styled-components';
import { TableCell, TableContainer, TableRow } from '@mui/material';

const StyledMobileTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: 0;
    padding: 0.5rem;
  }
`;

const StyledTableContainer = styled(TableContainer)`
  &.MuiTableContainer-root {
    margin: 1rem 0;
    padding: 0.5rem;
    ${props =>
      props.selected &&
      css`
        background: linear-gradient(0deg, rgba(45, 179, 165, 0.05), rgba(45, 179, 165, 0.05)), #ffffff;
        border: 1px solid #2db3a5;
      `}
  }
`;

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root.Mui-selected {
    background: linear-gradient(0deg, rgba(45, 179, 165, 0.1), rgba(45, 179, 165, 0.1)), #ffffff;
  }
`;

export { StyledMobileTableCell, StyledTableContainer, StyledTableRow };
