import { LOAD_ACTIVE_CUSTOMERS, LOAD_ACTIVE_USERS, LOAD_CUSTOMERS_MONTH, LOAD_FREEMIUM_MONTH } from './constants';

export const loadActiveCustomers = (interval = '30d') => ({
  type: LOAD_ACTIVE_CUSTOMERS,
  interval
});

export const loadActiveUsers = (interval = '30d') => ({
  type: LOAD_ACTIVE_USERS,
  interval
});

export const loadCustomersMonth = year => ({
  type: LOAD_CUSTOMERS_MONTH,
  year
});

export const loadFreemiumMonth = year => ({
  type: LOAD_FREEMIUM_MONTH,
  year
});
