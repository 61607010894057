import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSensorReducerState } from 'redux/rootSelectors';
import T from 'prop-types';
import SensorDataContent from './SensorDataContent';
import { loadSensor, loadSensorIris } from '../../../../redux/sensor/actions';
import { PlcType } from '../../../../attrs/plcConfigValues';
import { getIrisSensors } from '../../../../redux/sensor/selectors';

const ConnectedSensorDataContent = ({ plcType }) => {
  const dispatch = useDispatch();
  const { loadingSensorsStatus, paginatedSensorList } = useSelector(getSensorReducerState);
  const { loadingIrisSensorsStatus, irisSensorList } = useSelector(getIrisSensors);
  const { machineId } = useParams();

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(loadSensorIris(machineId));
    } else {
      dispatch(loadSensor(machineId));
    }
  }, [dispatch, machineId, plcType.plc_type]);

  const sensorList = paginatedSensorList.sensors || {};
  sensorList.iris = irisSensorList || [];

  return (
    <SensorDataContent
      loadingState={plcType.plc_type === PlcType.IRIS_V3 ? loadingIrisSensorsStatus : loadingSensorsStatus}
      reloadAction={() => {
        if (plcType.plc_type === PlcType.IRIS_V3) {
          dispatch(loadSensorIris(machineId));
        } else {
          dispatch(loadSensor(machineId));
        }
      }}
      data={{ ...paginatedSensorList, sensors: sensorList }}
      machineId={machineId}
      plcType={plcType}
    />
  );
};

ConnectedSensorDataContent.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default ConnectedSensorDataContent;
