import * as constants from './constants';
import { removeEmptyValuesRecursively, removeEmptyValuesRecursivelyPaginated } from '../utils';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';

/**
 * Machines V2 - List Machines Resumed
 * @param {
 *   {
 *     gateway_id: string|undefined,
 *     organization_id: string|undefined,
 *     organization_name: string|undefined,
 *     commission_number: string|undefined,
 *     serial_number: string|undefined,
 *     limit: number|10,
 *     page: number|1,
 *     order: "desc"|"asc"|undefined,
 *     sort: string|undefined
 *   }
 * } params
 * @returns {{type: string, params}}
 */
const machinesV2ListResumed = (
  params = {
    limit: PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
    page: PAGINATION_DEFAULT_START_PAGE
  }
) => ({
  type: constants.MACHINES_V2_LIST_RESUMED,
  params: removeEmptyValuesRecursivelyPaginated(params)
});

const loadIrisTableNames = machineId => ({
  type: constants.LOAD_IRIS_TABLE_NAMES,
  machineId
});

/**
 * Update Iris Table Names
 *
 * @param {string} machineId
 * @param {{
 *   table_name: "BATCH_MEASUREMENTS_TABLE_NAME"|"BATCH_DETAILS_TABLE_NAME"|"MESSAGES_TABLE_NAME"|"METRICS_TABLE_NAME",
 *   target: string
 * }} payload - The payload to update the table name
 * @return {{machineId, payload, type}}
 */
const updateIrisTableNames = (machineId, payload) => ({
  type: constants.UPDATE_IRIS_TABLE_NAMES,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

const loadIrisAutoConfig = machineId => ({
  type: constants.LOAD_IRIS_AUTO_CONFIG,
  machineId
});

/**
 * Request update for Iris Auto Config
 *
 * @param {string} machineId
 * @param {{
 *   plc: boolean | undefined | null,
 *   sensor_data: boolean | undefined | null
 *   static_values: boolean | undefined | null
 *   status: boolean | undefined | null
 *   messages: boolean | undefined | null
 *   batch_records: boolean | undefined | null
 * }} payload - The payload to update the table name
 * @return {{machineId, payload, type}}
 */
const updateIrisAutoConfig = (machineId, payload) => ({
  type: constants.UPDATE_IRIS_AUTO_CONFIG,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

const createStaticValueIrisV3 = (machineId, payload) => ({
  type: constants.CREATE_STATIC_VALUE_IRIS_V3,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

const loadStaticValuesIrisV3 = machineId => ({
  type: constants.LOAD_STATIC_VALUE_IRIS_V3,
  machineId
});

const updateStaticValueIrisV3 = (machineId, payload) => ({
  type: constants.UPDATE_STATIC_VALUE_IRIS_V3,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

const deleteSensorIrisV3 = (machineId, selectedItemId) => ({
  type: constants.DELETE_SENSOR_IRIS_V3,
  machineId,
  selectedItemId
});

const updateSensorStatusIrisV3 = (machineId, staticSensor) => ({
  type: constants.UPDATE_SENSOR_STATUS_IRIS_V3,
  machineId,
  staticSensor
});

const loadMessagesIrisV3 = machineId => ({
  type: constants.LOAD_MESSAGES_IRIS_V3,
  machineId
});

const updateMessagesMachineIrisV3 = (machineId, payload) => ({
  type: constants.UPDATE_MESSAGES_MACHINE_IRIS_V3,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

const loadStatusMachineIrisV3 = machineId => ({
  type: constants.LOAD_STATUS_MACHINE_IRIS_V3,
  machineId
});

const updateStatusMachineIrisV3 = (machineId, payload) => ({
  type: constants.UPDATE_STATUS_MACHINE_IRIS_V3,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

const loadStatusMachineConditionsIrisV3 = machineId => ({
  type: constants.LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3,
  machineId
});

const updateStatusMachineConditionsIrisV3 = (machineId, payload) => ({
  type: constants.UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

/**
 * Load Iris Batch Details
 * @param machineId
 * @return {{machineId, type: string}}
 */
const loadIrisBatchDetails = machineId => ({
  type: constants.LOAD_IRIS_BATCH_DETAILS,
  machineId
});

/**
 * Request update for Iris Batch Details
 * @param {string} machineId
 * @param {{
 *   batch_id_variable: string,
 *   batch_size_variable: string,
 *   end_time_variable: string,
 *   recipe_variable: string,
 *   start_time_variable: string
 * }} payload - The payload to update the batch details
 * @return {{machineId, payload, type}}
 */
const updateIrisBatchDetails = (machineId, payload) => ({
  type: constants.UPDATE_IRIS_BATCH_DETAILS,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

/**
 * Load Iris Batch Status
 * @param machineId
 * @return {{machineId, type: string}}
 */
const loadIrisBatchStatus = machineId => ({
  type: constants.LOAD_IRIS_BATCH_STATUS,
  machineId
});

/**
 * Request update for Iris Batch Status
 * @param {string} machineId
 * @param {{
 *   status_cancelled_value: number,
 *   status_completed_before_target_value: number,
 *   status_completed_value: number,
 *   status_completed_with_target_updates_value: number,
 *   status_in_operation_value: number,
 *   status_loading_value: number,
 *   status_stopped_value: number,
 *   status_variable: string
 * }} payload - The payload to update the batch status
 * @return {{machineId, payload, type}}
 */
const updateIrisBatchStatus = (machineId, payload) => ({
  type: constants.UPDATE_IRIS_BATCH_STATUS,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

/**
 * Load Iris Batch Target
 * @param machineId
 * @return {{machineId, type: string}}
 */
const loadIrisBatchTarget = machineId => ({
  type: constants.LOAD_IRIS_BATCH_TARGET,
  machineId
});

/**
 * Request update for Iris Batch Target
 * @param {string} machineId
 * @param {{
 *   target_comment_1: string,
 *   target_comment_2: string,
 *   target_comment_3: string,
 *   target_comment_4: string,
 *   target_type_variable: string,
 *   target_value_variable: string
 * }} payload - The payload to update the batch target
 * @return {{machineId, payload, type}}
 */
const updateIrisBatchTarget = (machineId, payload) => ({
  type: constants.UPDATE_IRIS_BATCH_TARGET,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

/**
 * Load Iris Batch Limits
 * @param machineId
 * @return {{machineId, type: string}}
 */
const loadIrisBatchLimits = machineId => ({
  type: constants.LOAD_IRIS_BATCH_LIMITS,
  machineId
});

/**
 * Request update for Iris Batch Limits
 * @param {string} machineId
 * @param {{
 *   dry_running_flow_protection_variable: string,
 *   dry_running_pressure_protection_variable: string,
 *   high_critical_pressure_inlet_variable: string,
 *   high_critical_product_outlet_temperature_variable: string,
 *   high_warning_pressure_inlet_variable: string,
 *   high_warning_product_outlet_temperature_variable: string,
 *   sieve_cleaning_pressure_variable: string
 * }} payload - The payload to update the batch limits
 * @return {{machineId, payload, type}}
 */
const updateIrisBatchLimits = (machineId, payload) => ({
  type: constants.UPDATE_IRIS_BATCH_LIMITS,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

/**
 * Load Iris Batch Measurements
 * @param machineId
 * @return {{machineId, type: string}}
 */
const loadIrisBatchMeasurements = machineId => ({
  type: constants.LOAD_IRIS_BATCH_MEASUREMENTS,
  machineId
});

/**
 * Request update for Iris Batch Measurements
 * @param {string} machineId
 * @param {{
 *   current_cycles_variable: string,
 *   estimated_finish_time_variable: string,
 *   grinding_energy_variable: string,
 *   number_of_passes_variable: string,
 *   progress_variable: string,
 *   specific_energy_variable: string,
 *   total_grinding_energy_variable: string,
 *   total_grinding_time_variable: string
 * }} payload - The payload to update the batch measurements
 * @return {{machineId, payload, type}}
 */
const updateIrisBatchMeasurements = (machineId, payload) => ({
  type: constants.UPDATE_IRIS_BATCH_MEASUREMENTS,
  machineId,
  payload: removeEmptyValuesRecursively(payload)
});

export {
  machinesV2ListResumed,
  loadIrisTableNames,
  updateIrisTableNames,
  loadIrisAutoConfig,
  updateIrisAutoConfig,
  createStaticValueIrisV3,
  loadStaticValuesIrisV3,
  updateStaticValueIrisV3,
  deleteSensorIrisV3,
  updateSensorStatusIrisV3,
  loadMessagesIrisV3,
  updateMessagesMachineIrisV3,
  loadStatusMachineIrisV3,
  updateStatusMachineIrisV3,
  loadStatusMachineConditionsIrisV3,
  updateStatusMachineConditionsIrisV3,
  loadIrisBatchDetails,
  updateIrisBatchDetails,
  loadIrisBatchStatus,
  updateIrisBatchStatus,
  loadIrisBatchTarget,
  updateIrisBatchTarget,
  loadIrisBatchLimits,
  updateIrisBatchLimits,
  loadIrisBatchMeasurements,
  updateIrisBatchMeasurements
};
