import React from 'react';
import T from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormElements, NotifyIcon, NotifyTypo } from 'web-components';
import { Avatar, Box, Button } from '@mui/material';

import { getContactsSupport } from 'redux/supportContact/selectors';
import { fileToBase64, getInitials } from 'helpers/utils';

import SapFormElement from './SapFormElement';
import { GeneralContainer, GeneralGroup, GeneralContent, ImageWrapper } from './elements';

const emptyStateSupport = {
  support_id: '',
  email: '',
  phone: {
    number: '',
    country: ''
  },
  support: {
    support_id: '',
    phone: '',
    email: '',
    name: '',
    country_id: '',
    country_code: ''
  },
  logo: ''
};

const labelAmend = 'customers.form';

export const GeneralInformationForm = ({ create, className }) => {
  const { values, errors, touched, handleChange, handleBlur, setValues, setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const supportContacts = useSelector(getContactsSupport);

  const onChangeSupport = ({ target: { value } }) => {
    if (!value) {
      setValues(...values, ...emptyStateSupport);
      return;
    }
    const supportValue = supportContacts.find(item => item.support_id === parseInt(value, 10));

    setValues({
      ...values,
      support_id: supportValue.support_id,
      email: supportValue.email,
      phone: {
        number: supportValue.phone,
        country: supportValue.country_id
      },
      support: {
        support_id: supportValue.support_id,
        phone: supportValue.phone,
        email: supportValue.email,
        name: supportValue.name,
        country_id: supportValue.country_id,
        country_code: supportValue.country_code
      }
    });
  };

  const handleDeleteImage = () => {
    setFieldValue('logo', '');

    const fileInput = document.querySelector('[data-selector="upload-photo"]');
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleFileChange = async ({ target: { files } }) => {
    const file = files[0];

    const base64 = await fileToBase64(file);

    setFieldValue('logo', base64);
  };

  return (
    <GeneralContainer className={className}>
      <ImageWrapper>
        <Avatar
          sx={{ background: 'linear-gradient(0deg, #ffffffb3, #ffffffb3), #58BAAA', color: '#58BAAA' }}
          style={{ height: '167px', width: '167px', border: '1px solid #DDDDDD', marginRight: 20 }}
          src={values?.logo}
          data-selector="customer-logo"
        >
          {!values?.logo && getInitials(values?.name)}
        </Avatar>

        <Button variant="outlined" component="label" sx={{ p: 2, display: 'flex', gap: 1.5 }}>
          <NotifyIcon iconName="backArrow" fontSize="medium" style={{ transform: 'rotate(90deg)' }} />
          {t(`${labelAmend}.upload_photo`)}
          <input
            hidden
            data-selector="upload-photo"
            key={values?.logo ? new Date().getTime() : 'no-logo'}
            accept="image/*"
            type="file"
            onChange={handleFileChange}
          />
        </Button>
        <Button onClick={handleDeleteImage}>{t(`${labelAmend}.remove_photo`)}</Button>
      </ImageWrapper>
      <GeneralContent>
        <Box>
          {create && (
            <NotifyTypo.Heading2 style={{ marginBottom: 21 }}>
              {t(`${labelAmend}.general_information`)}
            </NotifyTypo.Heading2>
          )}

          <GeneralGroup>
            <FormElements.TextField
              label={t('customers.form.name')}
              fullWidth
              id="name"
              margin="none"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.name || ''}
              required
              error={errors.name && touched.name}
              helperText={errors.name && touched.name && t(`form.validate.${errors.name}`)}
              style={{ maxWidth: 344 }}
            />

            <SapFormElement />
          </GeneralGroup>
        </Box>
        <Box>
          <NotifyTypo.Heading2 style={{ marginBottom: 21 }}>{t(`${labelAmend}.country_code`)}</NotifyTypo.Heading2>
          <GeneralGroup>
            <FormElements.Select
              value={values?.support?.support_id || ''}
              fullWidth
              handleChange={onChangeSupport}
              label={t('customers.form.country_code')}
              name="support.support_id"
              id="phoneCountry"
              className="support-select"
              margin="none"
            >
              <option aria-label="None" value="" />
              {supportContacts.map(option => (
                <option key={option.support_id} value={option.support_id}>
                  {`${option.name}`}
                </option>
              ))}
            </FormElements.Select>
            <FormElements.TextField
              label={t('customers.form.phone')}
              style={{ opacity: '0.5', maxWidth: 344 }}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              InputLabelProps={{ shrink: !!values?.support?.phone }}
              id="phoneNumber"
              name="support.phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.support?.phone || ''}
              required
              margin="none"
              error={((errors || {}).phone || {}).number && ((touched || {}).phone || {}).number}
              helperText={
                ((errors || {}).phone || {}).number &&
                ((touched || {}).phone || {}).number &&
                t(`form.validate.${errors.phone.number}`)
              }
            />
            <FormElements.TextField
              label={t('customers.form.email')}
              style={{ opacity: '0.5', maxWidth: 344 }}
              fullWidth
              InputProps={{
                readOnly: true
              }}
              InputLabelProps={{
                shrink: !!values?.support?.email
              }}
              id="email"
              margin="none"
              name="support.email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.support?.email || ''}
              required
              error={errors.email && touched.email}
              helperText={errors.email && touched.email && t(`form.validate.${errors.email}`)}
            />
          </GeneralGroup>
        </Box>
      </GeneralContent>
    </GeneralContainer>
  );
};

GeneralInformationForm.propTypes = {
  create: T.bool,
  className: T.string
};

GeneralInformationForm.defaultProps = {
  create: false,
  className: ''
};
