import axios from './axios';
import { convertEmptyToNull } from '../helpers/stringHandler';

const loadSensor = id => {
  const params = new URLSearchParams();
  params.append('selection', 'custom');
  params.append('selection', 'pre_def');
  params.append('selection', 'static');

  return axios({
    method: 'get',
    url: `/v2/machines/${id}/sensors`,
    params
  });
};

const createSensor = (machineId, payload) =>
  axios({
    method: 'post',
    url: `/v2/machines/${machineId}/sensor`,
    data: {
      sensor: payload
    }
  });

const updateSensor = (id, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${id}/sensor`,
    data: {
      sensor: payload
    }
  });

const deleteSensor = (id, sensorType) =>
  axios({
    method: 'delete',
    url: `/v2/machines/${id}/sensor`,
    params: {
      sensor_type: sensorType
    }
  });

const loadSensorUnits = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-units-config`
  });

const updateSensorUnits = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-units-config`,
    data: convertEmptyToNull(payload)
  });

const loadSensorConfigsForIris = () =>
  axios({
    method: 'get',
    url: `/v2/machines/iris-sensor-data-config/configs`
  });

const loadSensorIris = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-sensor-data-config`
  });

const createUpdateSensorIris = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-sensor-data-config`,
    data: {
      sensor_data: convertEmptyToNull(payload)
    }
  });

const deleteSensorIris = (machineId, sensorId) =>
  axios({
    method: 'delete',
    url: `/v2/machines/${machineId}/iris-sensor-data-config/${sensorId}`
  });

const flipSensorIrisStatus = (machineId, sensorId) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-sensor-data-config/${sensorId}/flip-state`
  });

export {
  loadSensor,
  createSensor,
  updateSensor,
  deleteSensor,
  loadSensorUnits,
  updateSensorUnits,
  loadSensorConfigsForIris,
  loadSensorIris,
  createUpdateSensorIris,
  deleteSensorIris,
  flipSensorIrisStatus
};
