import { makeStyles } from '@mui/styles';

export const AutoConfigStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#EBEBEB',
    height: 60,
    '& th': {
      color: '#7A7D85',
      fontWeight: '500'
    }
  },
  body: {
    '& td': {
      fontWeight: '400'
    }
  },
  checkbox: {
    color: '#2BACA4'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  detected: {
    padding: '2px 8px'
  },
  detectedWarning: {
    display: 'inline',
    border: '1px solid #F19909',
    backgroundColor: '#FDEFD9',
    color: '#F19909',
    borderRadius: 100
  },
  detectedSuccess: {
    display: 'inline',
    border: '1px solid #2BACA4',
    backgroundColor: '#E5F1F0',
    color: '#2BACA4',
    borderRadius: 100
  },
  approveButton: {
    display: 'inline-flex',
    height: '56px',
    minWidth: '159px',
    padding: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    flexShrink: '0',
    float: 'right'
  }
}));
