import axios from './axios';

const updateBatchDetails = (id, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${id}/batch/details`,
    data: payload
  });

const updateBatchStatus = (id, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${id}/batch/statuses`,
    data: payload
  });

const updateBatchTarget = (id, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${id}/batch/target`,
    data: payload
  });

export { updateBatchDetails, updateBatchStatus, updateBatchTarget };
