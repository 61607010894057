import React from 'react';
import { Box } from '@mui/material';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { StyledButton, StyledContent, StyledNewDialog } from './elements';

const labelAmend = 'machines.machine_details.sensor_data_modal';

const AddSensor = ({ open, handleClose, handleCustomSensor, handlePreDefinedSensor }) => {
  const { t } = useTranslation();

  return (
    <StyledNewDialog
      open={open}
      handleClose={handleClose}
      title={t(`${labelAmend}.title`)}
      subTitle={t(`${labelAmend}.subtitle`)}
      displayActionBar="none"
      maxWidth="680px"
      content={
        <StyledContent>
          <StyledButton onClick={() => handlePreDefinedSensor()}>
            <NotifyIcon iconName="preDefIcon" fontSize="medium" sx={{ mx: 2 }} />
            <Box>
              <NotifyTypo.Body1 color="text.primary">
                <strong>{t(`${labelAmend}.predefined_title`)}</strong>
              </NotifyTypo.Body1>
              <NotifyTypo.Subtitle1 color="text.primary" fontWeight="normal">
                {t(`${labelAmend}.predefined_subtitle`)}
              </NotifyTypo.Subtitle1>
            </Box>
          </StyledButton>

          <StyledButton onClick={() => handleCustomSensor()}>
            <NotifyIcon iconName="customIcon" fontSize="medium" sx={{ mx: 2 }} />
            <Box>
              <NotifyTypo.Body1 color="text.primary">
                <strong>{t(`${labelAmend}.custom_title`)}</strong>
              </NotifyTypo.Body1>
              <NotifyTypo.Subtitle1 color="text.primary" fontWeight="normal">
                {t(`${labelAmend}.custom_subtitle`)}
              </NotifyTypo.Subtitle1>
            </Box>
          </StyledButton>
        </StyledContent>
      }
    />
  );
};

AddSensor.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleCustomSensor: func.isRequired,
  handlePreDefinedSensor: func.isRequired
};

export default AddSensor;
