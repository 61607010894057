import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, NotifyIcon } from 'web-components';
import { Box, Button, Tooltip } from '@mui/material';
import { formatMachineType } from 'helpers/utils';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../../attrs/pagination';
import { MachineFilter } from '../MachineFilter';
import { FilterContainer } from '../MachineTable.styled';
import { getGatewaysSortOrder } from '../../../redux/gateways/selectors';
import { getGatewayFiltered, loadGatewaysByResumeDetail } from '../../../redux/gateways/actions';
import { GATEWAY_DEFAULT_LIST_ORDER, GATEWAY_DEFAULT_LIST_SORT } from '../../../attrs/gateways';
import UnLinkGateway from './UnLinkGateway/UnLinkGatewayModal';

const labelAmmend = 'gateways.overview.connection_status_gateway';

const GatewayTable = ({ columns, rows, organizationId }) => {
  const dispatch = useDispatch();
  const gatewaysSortOrder = useSelector(getGatewaysSortOrder);
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [currentSort, setCurrentSort] = useState({
    colId: gatewaysSortOrder.sort || GATEWAY_DEFAULT_LIST_SORT,
    order: gatewaysSortOrder.name || GATEWAY_DEFAULT_LIST_ORDER
  });
  const [filterOrganizationName, setFilterOrganizationName] = useState('');
  const [filterConnectionStatus, setFilterConnectionStatus] = useState('');
  const [clear, setClear] = useState(false);
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;
  const object = [rows];

  const handleCloseUnlinkGateway = () => setAddModal(false);
  const handleRowClick = serial => history.push(`/gateways/${serial}`);

  useEffect(() => {
    setIsLoading(false);
    if (rows && object && object.length) {
      setCurrentView(
        DataTable.sortFn(
          object.map(row => {
            const machineTypeFormat = formatMachineType(row.type);

            return {
              ...row,
              gateway_id: row.serial_number ? (
                <Box onClick={() => handleRowClick(row.serial_number)}> {row.serial_number} </Box>
              ) : (
                '---'
              ),
              linked_machines: row.machine_count ? row.machine_count : '---',
              system_version: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {row.desired_version !== row.actual_version && (
                    <Tooltip title={t('gateways.not_current_version')} placement="bottom" arrow>
                      <span>
                        <NotifyIcon iconName="warning" fontSize="medium" sx={{ mt: 1 }} />
                      </span>
                    </Tooltip>
                  )}
                  {getValueToNullableCell(row.actual_version)}
                </Box>
              ),
              operating_system: row.type ? machineTypeFormat : t('gateways.unassigned'),
              connection_status: row.connected ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <NotifyIcon iconName="gatewayConnected" fontSize="small" sx={{ mr: 1 }} />
                  {t(`${labelAmmend}.network_connected`)}
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <NotifyIcon iconName="gatewayDesconnected" fontSize="small" sx={{ mr: 1 }} />
                  {t(`${labelAmmend}.not_network_connected`)}
                </Box>
              ),
              link_gateway: (
                <Box onClick={() => setAddModal(true)}>
                  <NotifyIcon iconName="unLinkGateway" fontSize="large" sx={{ mt: 1.5 }} />
                </Box>
              )
            };
          })
        )
      );
    } else {
      setCurrentView([]);
    }
  }, [organizationId, rows, t, currentSort.colId, currentSort.order]);

  const handleChangeFilter = (page, configured, organizationName) => {
    setCurrentPage(page);
    dispatch(
      getGatewayFiltered(
        organizationId,
        organizationName,
        configured,
        rowsPerPage,
        page,
        currentSort.order,
        currentSort.colId
      )
    );
  };

  const handleSelectRow = row => setSelectedRow(row);

  const handleClearFilter = () => {
    setClear(true);
    dispatch(loadGatewaysByResumeDetail());
  };

  const handleSort = (colId, order) => {
    setIsLoading(true);
    dispatch(
      getGatewayFiltered(
        organizationId,
        filterOrganizationName,
        filterConnectionStatus,
        rowsPerPage,
        currentPage,
        order.toUpperCase(),
        colId
      )
    );
    setCurrentSort({ colId, order });
  };

  const setInitStates = () => {
    setCurrentPage(PAGINATION_DEFAULT_START_PAGE);
    setCurrentSort({
      colId: gatewaysSortOrder.sort || GATEWAY_DEFAULT_LIST_SORT,
      order: gatewaysSortOrder.name || GATEWAY_DEFAULT_LIST_ORDER
    });
  };
  const props = {
    dataSelectorTable: 'machines-table',
    dataSelectorRows: 'machines-table-rows',
    isLoading,
    columns,
    rows: currentView,
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  const handleFilterConnectionStatus = configured => {
    if (configured !== filterConnectionStatus) {
      setIsLoading(true);
      setFilterConnectionStatus(configured);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, configured, filterOrganizationName);
    }
  };

  const handleFilterOrganizationName = organizationName => {
    if (organizationName !== filterOrganizationName) {
      setIsLoading(true);
      setFilterOrganizationName(organizationName);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, filterConnectionStatus, organizationName);
    }
  };

  return (
    <>
      <Box sx={{ display: 'none' }}>
        <FilterContainer>
          <MachineFilter
            isCheckbox={false}
            clear={clear}
            optionText={filterOrganizationName}
            setInitStates={setInitStates}
            handleFilter={handleFilterOrganizationName}
            labelFilter={t('gateways.filter.gateway_id')}
          />
          <MachineFilter
            isCheckbox={false}
            clear={clear}
            optionText={filterConnectionStatus}
            setInitStates={setInitStates}
            handleFilter={handleFilterConnectionStatus}
            labelFilter={t('gateways.filter.connection_status')}
          />
          <Button
            color="primary"
            onClick={() => {
              handleClearFilter();
            }}
          >
            {t('gateways.filter.clear')}
          </Button>
        </FilterContainer>
      </Box>
      <DataTable hover {...props} />

      <UnLinkGateway
        open={addModal}
        handleClose={handleCloseUnlinkGateway}
        gatewayId={selectedRow.serial_number}
        organizationId={selectedRow.organization_id}
      />
    </>
  );
};

GatewayTable.propTypes = {
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired,
  rows: T.shape({
    data: T.instanceOf(Array),
    limit: T.number,
    order: T.string,
    page: T.number,
    search: T.string,
    sort: T.instanceOf(Array),
    total_length: T.number,
    total_pages: T.number
  }).isRequired,
  organizationId: T.string
};

GatewayTable.defaultProps = {
  organizationId: null
};

export default GatewayTable;
