import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'web-components';
import { Box, Stack } from '@mui/material';
import { getMachinesFiltered } from '../../redux/organizations/actions';

import Footer from '../DataTable/Footer';
import { MACHINE_DEFAULT_LIST_SORT, ROUTE_MACHINES } from '../../attrs/machines';
import { getMachineSortOrder } from '../../redux/machines/selectors';
import { MachineFilter } from '../MachineTable/MachineFilter';
import {
  PAGINATION_ASC,
  PAGINATION_DEFAULT_ROWS_PER_PAGE,
  PAGINATION_DEFAULT_START_PAGE
} from '../../attrs/pagination';

const MachineTable = ({ columns, rows, organizationId, customer }) => {
  const dispatch = useDispatch();
  const machineSortOrder = useSelector(getMachineSortOrder);
  const { t } = useTranslation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState({
    colId: machineSortOrder.sort || MACHINE_DEFAULT_LIST_SORT,
    order: machineSortOrder.name || PAGINATION_ASC
  });
  const [filterCommissionNumber, setFilterCommissionNumber] = useState('');
  const [filterGatewayId, setFilterGatewayId] = useState('');
  const [clear, setClear] = useState(false);
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;

  useEffect(() => {
    setIsLoading(false);
    if (rows && rows.data && rows.data.length) {
      setCurrentView(rows.data);
    } else {
      setCurrentView([]);
    }
  }, [organizationId, rows, t, currentSort.colId, currentSort.order]);

  const handleChangePage = page => {
    setIsLoading(true);
    setCurrentPage(page);
    dispatch(
      getMachinesFiltered(
        organizationId,
        filterGatewayId,
        filterCommissionNumber,
        rowsPerPage,
        page,
        currentSort.colId,
        currentSort.order
      )
    );
  };

  const handleChangeFilter = (page, commissionNumber, gatewayId) => {
    setCurrentPage(page);
    dispatch(
      getMachinesFiltered(
        organizationId,
        gatewayId,
        commissionNumber,
        rowsPerPage,
        page,
        currentSort.colId,
        currentSort.order
      )
    );
  };

  const handleSelectRow = row => {
    localStorage.setItem('organizationName', customer.name);
    localStorage.setItem('organizationId', customer.id);
    const routePath =
      row.organization_id !== null
        ? `/customers/${row.organization_id}/${ROUTE_MACHINES}/${row.id}`
        : `/${ROUTE_MACHINES}/${row.id}`;

    history.push(routePath);
  };

  const handleSort = (colId, order) => {
    setIsLoading(true);
    dispatch(
      getMachinesFiltered(
        organizationId,
        filterGatewayId,
        filterCommissionNumber,
        rowsPerPage,
        currentPage,
        colId,
        order.toUpperCase()
      )
    );
    setCurrentSort({ colId, order });
  };

  const setInitStates = () => {
    setCurrentPage(PAGINATION_DEFAULT_START_PAGE);
    setCurrentSort({
      colId: machineSortOrder.sort || MACHINE_DEFAULT_LIST_SORT,
      order: machineSortOrder.name || PAGINATION_ASC
    });
  };

  const props = {
    dataSelectorTable: 'machines-table',
    dataSelectorRows: 'machines-table-rows',
    isLoading,
    columns,
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={rows.total_pages || 1}
        totalLength={rows.total_length || 0}
        totalItemsPerPage={rows.limit || 0}
        onChange={handleChangePage}
      />
    ),
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  const handleFilterCommissionNumber = commissionNumber => {
    if (commissionNumber !== filterCommissionNumber) {
      setIsLoading(true);
      setFilterCommissionNumber(commissionNumber);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, commissionNumber, filterGatewayId);
    }
  };

  const handleFilterGatewayId = gatewayId => {
    if (gatewayId !== filterGatewayId) {
      setIsLoading(true);
      setFilterGatewayId(gatewayId);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, filterCommissionNumber, gatewayId);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={4} sx={{ py: 4 }}>
        <MachineFilter
          isCheckbox={false}
          clear={clear}
          optionText={filterCommissionNumber}
          setInitStates={setInitStates}
          handleFilter={handleFilterCommissionNumber}
          labelFilter={t('machines.filter.commission_number')}
          setClear={setClear}
        />
        <MachineFilter
          isCheckbox={false}
          clear={clear}
          optionText={filterGatewayId}
          setInitStates={setInitStates}
          handleFilter={handleFilterGatewayId}
          labelFilter={t('machines.filter.gateway_serial_number')}
          setClear={setClear}
        />
      </Stack>
      <DataTable hover {...props} />
    </Box>
  );
};

MachineTable.propTypes = {
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired,
  rows: T.shape({
    data: T.instanceOf(Array),
    limit: T.number,
    order: T.string,
    page: T.number,
    search: T.string,
    sort: T.instanceOf(Array),
    total_length: T.number,
    total_pages: T.number
  }).isRequired,
  organizationId: T.string,
  customer: T.instanceOf(Object)
};

MachineTable.defaultProps = {
  organizationId: null,
  customer: null
};

export default MachineTable;
