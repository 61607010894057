import * as constants from './constants';

/**
 * Invite User V2 Action for Sagas
 * @param {{
 *    organization_id: string,
 *    user: {
 *      email: string, roles: Array.<{product: 'preventive'|'notify',
 *      role: 'coordinator'|'technician'|'manager'|'operator'|'no_access'}>
 *    },
 *  }} inviteUser
 * @returns {{type: string, inviteUser}}
 */
const inviteUserV2Action = inviteUser => ({
  type: constants.USERS_V2_INVITE_USER,
  inviteUser
});

/**
 * Get Users Paginated - This will return a array of users at paginated default return
 * @param {{
 *   organization_id: string,
 *   limit: number,
 *   page: number,
 *   is_active: boolean
 * }} request
 * @returns {{request, type: string}}
 */
const getUsersPaginatedV2Action = request => ({
  type: constants.USERS_V2_GET_USERS_PAGINATED,
  request
});

/**
 * Get Users Invitations Paginated
 * @param {{
 *   organization_id: string,
 *   limit: number,
 *   page: number,
 *   is_active: boolean
 * }} request
 * @returns {{request, type: string}}
 */
const getUsersInvitationsPaginatedV2Action = request => ({
  type: constants.USERS_V2_GET_USERS_INVITATIONS_PAGINATED,
  request
});

/**
 * Update the user roles (v2)
 * @param {{
 *   user_id: string,
 *   updated_by_user_id: string,
 *   roles: Array.<{
 *     product: 'preventive'|'notify',
 *     role: 'coordinator'|'technician'|'manager'|'operator'|'no_access',
 *   }>
 * }} updateUserRolesV2Request
 * @returns {{updateUserRolesV2Request, type: string}}
 */
const updateUserRolesV2Action = updateUserRolesV2Request => ({
  type: constants.USERS_V2_UPDATE_USER_ROLES,
  updateUserRolesV2Request
});

export { inviteUserV2Action, getUsersPaginatedV2Action, getUsersInvitationsPaginatedV2Action, updateUserRolesV2Action };
