import styled from 'styled-components';

export const StyledList = styled('ul')`
  position: fixed;
  z-index: 2000;
  bottom: 1rem;
  right: 1rem;
  background: deeppink;
  margin: 0;
  list-style: none;
  color: white;
  opacity: 0.2;

  font-size: 0rem;
  padding: 0.5rem;
  &:hover {
    font-size: 1rem;
    padding: 1rem;
  }
`;

export default {};
