import React from 'react';
import T from 'prop-types';
import ConnectedGatewayContent from './ConnectedGatewayContent';
import { StyledContentWrapper } from '../CustomerDetail.styled';

const Gateway = () => (
  <>
    <StyledContentWrapper elevation={3}>
      <ConnectedGatewayContent />
    </StyledContentWrapper>
  </>
);

Gateway.propTypes = {
  customer: T.shape({ id: T.string, name: T.string }).isRequired
};

export default Gateway;
