import React from 'react';
import { Stack, Typography } from '@mui/material';
import Editor from 'components/Editor';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function Instructions({ data, setData }) {
  const { t } = useTranslation();

  const onChangeInstructions = content => {
    setData({ ...data, instructions: content });
  };

  return (
    <Stack spacing={2} justifyContent="center">
      <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 'bold' }} gutterBottom>
        {t('task.instructions.title')}
      </Typography>
      <Editor onChange={onChangeInstructions} />
    </Stack>
  );
}

Instructions.propTypes = {
  data: PropTypes.shape.isRequired,
  setData: PropTypes.func.isRequired
};
