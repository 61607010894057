import axios from './axios';

const createLicense = values =>
  axios({
    method: 'post',
    url: '/v1/licenses',
    data: values
  });

const editLicense = values =>
  axios({
    method: 'put',
    url: `/v1/licenses/${values.license.subscription_uuid}`,
    data: {
      organization_id: values.organization_id,
      license: {
        monitoring_points: values.license.monitoring_points,
        plan: values.license.plan,
        note: values.license.note,
        expiration_date: values.license.expiration_date,
        activation_date: values.license.activation_date
      }
    }
  });

const loadLicenses = organizationId =>
  axios({
    method: 'get',
    url: `/v1/licenses`,
    params: {
      organization_id: organizationId
    }
  });

const loadLicensesByResume = (licenseId, limit, page, order) =>
  axios({
    method: 'get',
    url: `/v2/organization/license/${licenseId}/transaction/history`,
    params: {
      limit,
      page,
      order
    }
  });

const renewLicense = license => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);

  return axios({
    method: 'put',
    url: `/v1/licenses/renew/${license.subscription_uuid}`,
    data: {
      license_id: license.subscription_uuid,
      organization_id: license.organization_id,
      expiration_date: date
    }
  });
};

export { createLicense, editLicense, loadLicenses, loadLicensesByResume, renewLicense };
