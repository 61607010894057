import React from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { FormElements } from 'web-components';
import ReCAPTCHA from 'react-google-recaptcha';

import { LoginSchema } from '../../../attrs/formValidation';
import { login } from '../../../redux/auth/actions';
import { getLogin } from '../../../redux/auth/selectors';
import { isError, isLoading } from '../../../helpers/utils';
import { COLOR_MAIN_HIGHLIGHT, COLOR_PRIMARY } from '../../../attrs/colors';

const LoginForm = ({ inverted }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status } = useSelector(getLogin);

  const handleSignIn = values => {
    dispatch(login(values.login, values.password, values.recaptchaValue));
  };

  const StyledLoginButton = withStyles({
    root: {
      background: inverted ? COLOR_MAIN_HIGHLIGHT : COLOR_PRIMARY,
      marginTop: '2rem',
      height: '45px'
    }
  })(Button);

  return (
    <Formik
      initialValues={{
        login: '',
        password: '',
        recaptchaValue: ''
      }}
      validationSchema={LoginSchema}
      onSubmit={values => handleSignIn(values)}
    >
      {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
        <form>
          <FormElements.TextField
            label={t('auth.email')}
            fullWidth
            id="login"
            margin="normal"
            name="login"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.login}
            required
            error={(errors.login && touched.login) || isError(status)}
            helperText={errors.login && touched.login && t(`form.validate.${errors.login}`)}
          />
          <FormElements.TextField
            autoComplete="current-password"
            error={(errors.password && touched.password) || isError(status)}
            fullWidth
            label={t('auth.password')}
            id="password"
            margin="normal"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            required
            type="password"
            helperText={errors.password && touched.password && t(`form.validate.${errors.password}`)}
          />
          <ReCAPTCHA
            sitekey="6Ldy1gMmAAAAANTSxV9caNAhISVMmlyn7vbBEz0K"
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: '24px'
            }}
            onChange={value => handleChange({ target: { name: 'recaptchaValue', value } })}
          />
          <StyledLoginButton
            color="primary"
            endIcon={isLoading(status) && <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />}
            fullWidth
            onClick={handleSubmit}
            disabled={!values.recaptchaValue}
            variant="contained"
            type="submit"
          >
            {t('auth.login')}
          </StyledLoginButton>
        </form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  inverted: T.bool
};

LoginForm.defaultProps = {
  inverted: false
};

export default LoginForm;
