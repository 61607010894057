import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { NewDialog } from 'web-components';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { getMillSensor, getMillSensorMap } from 'attrs/sensorType';
import { useDispatch, useSelector } from 'react-redux';
import { generateId, isError, isLoading, isSuccess, validateBlankSpace } from 'helpers/utils';
import { getSensorReducerState } from 'redux/rootSelectors';
import { addNotification } from 'redux/ui/notifications/actions';
import { ERROR, SUCCESS } from 'attrs/status';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createSensor, loadSensor, updateSensor } from '../../../../../redux/sensor/actions';
import { loadMetricsMachineDetails } from '../../../../../redux/machines/actions';

const PreDefinedSensor = ({ open, handleClose, machineId, sensorList, preDefItem }) => {
  const { creatingSensorStatus, updatingSensorStatus } = useSelector(getSensorReducerState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectMillList, setSelectMillList] = useState([]);

  useEffect(() => {
    if (open) {
      const millSensorTypes = new Map(getMillSensorMap());
      if (millSensorTypes && millSensorTypes.size > 0) {
        const tempSelectMillList = [];
        if (sensorList) {
          sensorList.forEach(s => {
            if (preDefItem && preDefItem.pre_def_sensor_id) {
              if (preDefItem.type !== s.type) {
                millSensorTypes.delete(s.type);
              }
            } else {
              millSensorTypes.delete(s.type);
            }
          });
        }
        millSensorTypes.forEach((millSensor, millSensorKey) => {
          const suffix = millSensor.isSubItem ? `[${millSensor.unit}]` : '';
          tempSelectMillList.push(
            <MenuItem key={`${millSensor.uniqueKey}`} value={millSensorKey}>
              {`${t(`${millSensor.name}`)} ${suffix}`}
            </MenuItem>
          );
        });
        setSelectMillList(tempSelectMillList);
      }
    }
  }, [open, sensorList, preDefItem]);

  const [sensorType, setSensorType] = useState('');
  const [isSensorTypeTouched, setSensorTypeTouched] = useState(false);
  const [unit, setUnit] = useState('');
  const [period, setPeriod] = useState('');
  const handleSensorTypeChange = event => {
    const sensorTypeParam = event.target.value;
    setSensorType(sensorTypeParam);
    setSensorTypeTouched(true);
    const sensorTypeFound = getMillSensor(sensorTypeParam);
    setUnit(sensorTypeFound?.unit);
    setPeriod(sensorTypeFound?.period);
  };

  const [source, setSource] = useState('');
  const [isSourceTouched, setSourceTouched] = useState(false);
  const onChangeSource = event => {
    let sourceParam = event.target.value;
    sourceParam = validateBlankSpace(sourceParam);
    setSource(sourceParam);
    setSourceTouched(true);
  };

  const [maxEngUnitAddress, setMaxEngUnitAddress] = useState('');
  const onChangeMaxEngUnitAddress = event => {
    const maxEngUnitAddressParam = event.target.value;
    setMaxEngUnitAddress(maxEngUnitAddressParam);
  };

  const [minEngUnitAddress, setMinEngUnitAddress] = useState('');
  const onChangeMinEngUnitAddress = event => {
    const minEngUnitAddressParam = event.target.value;
    setMinEngUnitAddress(minEngUnitAddressParam);
  };

  const [maxEngUnitValue, setMaxEngUnitValue] = useState();
  const [isMaxEngUnitValueTouched, setMaxEngUnitValueTouched] = useState(false);
  const onChangeMaxEngUnitValue = event => {
    const maxEngUnitValueParam = event.target.value;
    setMaxEngUnitValue(maxEngUnitValueParam);
    setMaxEngUnitValueTouched(true);
  };

  const [minEngUnitValue, setMinEngUnitValue] = useState();
  const onChangeMinEngUnitValue = event => {
    const minEngUnitValueParam = event.target.value;
    setMinEngUnitValue(minEngUnitValueParam);
  };

  const [engineeringUnit, setEngineeringUnit] = useState('address');
  const handleRadioEngineeringUnitChange = event => {
    const engineeringUnitParam = event.target.value;
    setEngineeringUnit(engineeringUnitParam);
    if (engineeringUnitParam === 'address') {
      setMaxEngUnitValue();
      setMinEngUnitValue();
    } else {
      setMaxEngUnitAddress('');
      setMinEngUnitAddress('');
    }
  };

  const [preDefSensorId, setPreDefSensorId] = useState();
  useEffect(() => {
    if (preDefItem && preDefItem.pre_def_sensor_id) {
      setPreDefSensorId(preDefItem.pre_def_sensor_id);
      setSensorType(preDefItem.type);
      setUnit(preDefItem.units);
      setPeriod(preDefItem.period);
      setEngineeringUnit(preDefItem.range?.max?.value ? 'fixed_value' : 'address');
      setMaxEngUnitAddress(preDefItem.range?.max?.source || '');
      setMaxEngUnitValue(preDefItem.range?.max?.value);
      setMinEngUnitAddress(preDefItem.range?.min?.source || '');
      setMinEngUnitValue(preDefItem.range?.min?.value);
      setSource(preDefItem.source);
    } else {
      setPreDefSensorId(undefined);
      setSensorType('');
      setSensorTypeTouched(false);
      setUnit('');
      setPeriod('');
      setEngineeringUnit('address');
      setMaxEngUnitAddress('');
      setMaxEngUnitValue();
      setMaxEngUnitValueTouched(false);
      setMinEngUnitAddress('');
      setMinEngUnitValue();
      setSource('');
      setSourceTouched(false);
    }
  }, [preDefItem]);

  const [successCreatingSensor, setSuccessCreatingSensor] = useState(false);
  useEffect(() => {
    if (successCreatingSensor && open) {
      dispatch(loadSensor(machineId));
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines.form.sensor.message.creating_success'
        })
      );
      setSuccessCreatingSensor(false);
      dispatch(loadMetricsMachineDetails(machineId));
      handleClose();
    }
  }, [successCreatingSensor]);

  const [errorCreatingSensor, setErrorCreatingSensor] = useState(false);
  useEffect(() => {
    if (errorCreatingSensor) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines.form.sensor.message.creating_error'
        })
      );
      setErrorCreatingSensor(false);
    }
  }, [errorCreatingSensor]);

  useEffect(() => {
    if (open) {
      if (isSuccess(creatingSensorStatus.status)) {
        setSuccessCreatingSensor(true);
      }
      if (isError(creatingSensorStatus.status)) {
        setErrorCreatingSensor(true);
      }
    }
  }, [creatingSensorStatus.status]);

  const [successUpdatingSensor, setSuccessUpdatingSensor] = useState(false);
  useEffect(() => {
    if (successUpdatingSensor && open) {
      dispatch(loadSensor(machineId));
      dispatch(
        addNotification({
          key: generateId(),
          type: SUCCESS,
          message: 'machines.form.sensor.message.updating_success'
        })
      );
      setSuccessUpdatingSensor(false);
      handleClose();
    }
  }, [successUpdatingSensor]);

  const [errorUpdatingSensor, setErrorUpdatingSensor] = useState(false);
  useEffect(() => {
    if (errorUpdatingSensor) {
      dispatch(
        addNotification({
          key: generateId(),
          type: ERROR,
          message: 'machines.form.sensor.message.updating_error'
        })
      );
      setErrorUpdatingSensor(false);
    }
  }, [errorUpdatingSensor]);

  useEffect(() => {
    if (open) {
      if (isSuccess(updatingSensorStatus.status)) {
        setSuccessUpdatingSensor(true);
      }
      if (isError(updatingSensorStatus.status)) {
        setErrorUpdatingSensor(true);
      }
    }
  }, [updatingSensorStatus.status]);

  function isSaving() {
    return isLoading(creatingSensorStatus.status) || isLoading(updatingSensorStatus.status);
  }

  const handleOk = () => {
    const isEditing = !!preDefItem || false;
    const payload = {
      is_custom: false,
      is_static: false,
      period,
      range: {
        max: {
          value: engineeringUnit === 'fixed_value' ? +maxEngUnitValue : null,
          source: engineeringUnit === 'address' ? maxEngUnitAddress || null : null
        },
        min: {
          value: engineeringUnit === 'fixed_value' ? +minEngUnitValue : null,
          source: engineeringUnit === 'address' ? minEngUnitAddress || null : null
        }
      },
      source,
      type: sensorType,
      units: unit,
      y_axis: {
        max: {
          specified: false,
          value: null
        },
        min: {
          specified: false,
          value: null
        }
      },
      multiplication_factor: null
    };

    if (isEditing) {
      payload.pre_def_sensor_id = preDefSensorId;
      dispatch(updateSensor(machineId, payload));
    } else {
      dispatch(createSensor(machineId, payload));
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  function canSave() {
    return !!sensorType && (minEngUnitValue ? maxEngUnitValue > minEngUnitValue : true) && !!source;
  }

  const content = (
    <Stack spacing={5} justifyContent="center" sx={{ width: '1062px' }}>
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
          {t('machines.machine_details.sensor_data_modal.predefined_title')}
        </Typography>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <FormControl variant="filled" required fullWidth error={!sensorType && isSensorTypeTouched}>
              <InputLabel id="sensor-type">{t('machines.form.sensor.type')}</InputLabel>
              <Select
                fullWidth
                IconComponent={KeyboardArrowDownIcon}
                labelId="sensor-type-label"
                id="sensor-type-select"
                onChange={handleSensorTypeChange}
                value={sensorType}
              >
                {selectMillList}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="source"
              label={t('machines.form.sensor.source')}
              variant="filled"
              value={source}
              onChange={onChangeSource}
              error={!source && isSourceTouched}
            />
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
            <TextField
              required
              fullWidth
              id="unit"
              label={t('machines.form.sensor.units')}
              variant="filled"
              value={unit}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="period"
              label={t('machines.form.sensor.period')}
              variant="filled"
              value={period}
              disabled
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
          {t('machines.form.sensor.custom_sensor_data.engineering_unit')}
        </Typography>
        <RadioGroup
          row
          aria-labelledby="radio-engineering-unit-label"
          name="radio-engineering-unit"
          sx={{ paddingLeft: 2 }}
          value={engineeringUnit}
          onChange={handleRadioEngineeringUnitChange}
        >
          <Grid container spacing={2} columns={12}>
            <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
              <FormControlLabel value="address" control={<Radio />} label={t('machines.form.sensor.source')} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel value="fixed_value" control={<Radio />} label={t('machines.form.sensor.value')} />
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}>
              <TextField
                fullWidth
                id="max-eng-unit-address"
                label={t('machines.form.sensor.custom_sensor_data.max_eng_unit_address')}
                variant="filled"
                value={maxEngUnitAddress}
                onChange={onChangeMaxEngUnitAddress}
                disabled={engineeringUnit === 'fixed_value'}
              />
            </Grid>
            <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
              <TextField
                fullWidth
                type="number"
                id="max-eng-unit-value"
                label={t('machines.form.sensor.custom_sensor_data.max_eng_unit_value')}
                variant="filled"
                value={maxEngUnitValue}
                error={maxEngUnitValue <= minEngUnitValue && isMaxEngUnitValueTouched}
                helperText={
                  maxEngUnitValue <= minEngUnitValue && isMaxEngUnitValueTouched
                    ? t('machines.form.sensor.custom_sensor_data.eng_unit_value_validation')
                    : undefined
                }
                onChange={onChangeMaxEngUnitValue}
                disabled={engineeringUnit === 'address'}
              />
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: '0px !important' }}>
              <TextField
                fullWidth
                id="min-eng-unit-address"
                label={t('machines.form.sensor.custom_sensor_data.min_eng_unit_address')}
                variant="filled"
                value={minEngUnitAddress}
                onChange={onChangeMinEngUnitAddress}
                disabled={engineeringUnit === 'fixed_value'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                id="min-eng-unit-value"
                label={t('machines.form.sensor.custom_sensor_data.min_eng_unit_value')}
                variant="filled"
                value={minEngUnitValue}
                onChange={onChangeMinEngUnitValue}
                disabled={engineeringUnit === 'address'}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Stack>
    </Stack>
  );

  return (
    <NewDialog
      open={open}
      canSave={canSave()}
      isSaving={isSaving()}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      handleCancel={handleCancel}
      handleOk={handleOk}
      title={
        preDefItem
          ? t('machines.machine_details.sensor_data_modal.title_edit')
          : t('machines.machine_details.sensor_data_modal.title')
      }
      subTitle={t('machines.machine_details.sensor_data_modal.subtitle')}
      content={content}
      cancelCaption={t('default_actions.cancel')}
      okCaption={t('default_actions.save')}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      maxWidth="98%"
      maxHeight="98%"
    />
  );
};

PreDefinedSensor.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  machineId: T.string.isRequired,
  sensorList: T.arrayOf(
    T.shape({
      type: T.string.isRequired,
      uniqueKey: T.string.isRequired,
      name: T.string.isRequired,
      unit: T.string,
      isSubItem: T.bool
    })
  ).isRequired,
  preDefItem: T.shape({
    pre_def_sensor_id: T.string,
    type: T.string.isRequired,
    units: T.string.isRequired,
    period: T.string.isRequired,
    range: T.shape({
      max: T.shape({
        value: T.number,
        source: T.string
      }),
      min: T.shape({
        value: T.number,
        source: T.string
      })
    }),
    source: T.string.isRequired
  })
};

PreDefinedSensor.defaultProps = {
  preDefItem: null
};

export default PreDefinedSensor;
