/* eslint-disable import/prefer-default-export */
import { put, takeLatest } from 'redux-saga/effects';

import * as constants from './constants';
import { actionStatus, statusAction, getError } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { loadSupportContacts } from '../../api/supportContact';

function* handleSupportContacts() {
  yield put(statusAction(constants.LOAD_SUPPORT_CONTACTS, actionStatus.START));
  try {
    const { data } = yield loadSupportContacts();

    yield put(statusAction(constants.LOAD_SUPPORT_CONTACTS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_SUPPORT_CONTACTS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.supportContacts.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_SUPPORT_CONTACTS, handleSupportContacts);
}
