import { useTranslation } from 'react-i18next';
import { FormElements } from 'web-components';
import React from 'react';
import T from 'prop-types';
import { getChangeNumberHandler, getDefaultFieldValue } from '../../../../../helpers/utils';

const CustomBoolValues = ['true', 'false'];

const CustomErrorFields = ({ error, setFieldValue, namespace, handleBlur }) => {
  const { t } = useTranslation();
  const namespacePrefix = namespace ? `${namespace}.` : '';

  const setValue = field => e =>
    setFieldValue(`${namespacePrefix}${field}`, e.target.value ? e.target.value === 'true' : null);

  if (!error.is_custom) {
    return null;
  }

  return (
    <>
      <FormElements.Select
        fullWidth
        handleChange={setValue('custom_bool_val')}
        id={`${namespacePrefix}custom_bool_val`}
        label={t('machines.form.error.bool_value_select')}
        name={`${namespacePrefix}custom_bool_val`}
        value={`${getDefaultFieldValue(error.custom_bool_val)}`}
        disabled={error.custom_type === 'INT'}
        withEmptyField
      >
        {CustomBoolValues.map(value => (
          <option key={value} value={value}>
            {t(`machines.form.sensor.warning.${value}`)}
          </option>
        ))}
      </FormElements.Select>
      <FormElements.TextField
        fullWidth
        id={`${namespacePrefix}custom_int_val`}
        label={t('machines.form.error.int_value_select')}
        margin="normal"
        name={`${namespacePrefix}custom_int_val`}
        disabled={error.custom_type === 'BOOL'}
        onBlur={handleBlur}
        onChange={getChangeNumberHandler(setFieldValue)(`${namespacePrefix}custom_int_val`)}
        type="number"
        value={getDefaultFieldValue(error.custom_int_val)}
      />
    </>
  );
};

CustomErrorFields.propTypes = {
  error: T.shape({
    is_custom: T.bool,
    custom_bool_val: T.bool,
    custom_type: T.string,
    custom_int_val: T.number
  }).isRequired,
  setFieldValue: T.func.isRequired,
  namespace: T.string,
  handleBlur: T.func.isRequired
};

CustomErrorFields.defaultProps = {
  namespace: ''
};

export default CustomErrorFields;
