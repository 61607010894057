import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, NotifyTypo } from 'web-components';

import { getConfirmations } from '../../redux/ui/selectors';
import { resolveConfirmationRequest } from '../../redux/ui/confirmations/actions';
import { COLOR_PRIMARY } from '../../attrs/colors';

const { Body1, Body2 } = NotifyTypo;

const ConfirmationDialog = () => {
  const dispatch = useDispatch();
  const confirmation = useSelector(getConfirmations);

  const handleClose = () => {
    if (confirmation.disagreeAction) {
      confirmation.disagreeAction();
    }
    dispatch(resolveConfirmationRequest());
  };

  const handleAgree = () => {
    dispatch(resolveConfirmationRequest());
    confirmation.action();
  };

  const { t } = useTranslation();
  const open = typeof confirmation === 'object';

  return (
    confirmation && (
      <>
        <Dialog open={open}>
          <Dialog.Header highlightColor={COLOR_PRIMARY} onClose={handleClose}>
            {confirmation.title ? confirmation.title : t('dialog.confirmation.title')}
          </Dialog.Header>
          <Dialog.Body>
            <Body1>{t(confirmation.message)}</Body1>
            {confirmation.extraValue && <Body2 style={{ margin: '1rem 0' }}>{confirmation.extraValue}</Body2>}
          </Dialog.Body>
          <Dialog.Actions>
            <Button onClick={handleClose}>{t('dialog.confirmation.disagree')}</Button>
            <Button
              variant={confirmation.yesVariant ? confirmation.yesVariant : 'contained'}
              color={confirmation.yesColor ? confirmation.yesColor : 'secondary'}
              onClick={handleAgree}
            >
              {t('dialog.confirmation.agree')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </>
    )
  );
};

export default ConfirmationDialog;
