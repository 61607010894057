import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardHeader } from '@mui/material';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import EditDatabaseTableNameModal from './EditDatabaseTableNameModal';
import { getValueToNullableCell } from '../../../../helpers/stringHandler';
import withLoadingState from '../../../../helpers/withLoadingState';
import { getIrisTableNameDetailsByType } from '../../../../attrs/machines';

const labelAmend = 'machines.machine_details';

const DatabaseTableName = ({ machineId, irisTableName, selectedTableName }) => {
  const { t } = useTranslation();

  const [editDatabaseModal, setEditDatabaseModal] = useState(false);
  const [currentTableName, setCurrentTableName] = useState(
    getIrisTableNameDetailsByType(selectedTableName, irisTableName)
  );

  const handleCloseDatabaseModal = () => setEditDatabaseModal(false);

  useEffect(() => {
    setCurrentTableName(getIrisTableNameDetailsByType(selectedTableName, irisTableName));
  }, [irisTableName, selectedTableName]);

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Button
            variant="text"
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
            onClick={() => setEditDatabaseModal(true)}
          >
            <NotifyIcon iconName="edit" fontSize="medium" />
            {t(`${labelAmend}.edit`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.${currentTableName.title}`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.${currentTableName.subtitle}`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" gap={8} sx={{ mt: 3, mb: 1 }}>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">{t(`${labelAmend}.database_table_name`)} *</NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(currentTableName?.database)}</NotifyTypo.Body1>
        </Box>
      </Box>

      <EditDatabaseTableNameModal
        open={editDatabaseModal}
        close={setEditDatabaseModal}
        handleClose={handleCloseDatabaseModal}
        machineId={machineId}
        currentTableName={currentTableName}
      />
    </Card>
  );
};

DatabaseTableName.propTypes = {
  machineId: T.string,
  irisTableName: T.shape({
    batch_details_table_name: T.string,
    batch_measurements_table_name: T.string,
    messages_table_name: T.string,
    metrics_table_name: T.string
  }),
  selectedTableName: T.string.isRequired
};

DatabaseTableName.defaultProps = {
  machineId: null,
  irisTableName: null
};

export default withLoadingState(DatabaseTableName);
