import styled from 'styled-components';

import { FormElements } from 'web-components';

import { COLOR_PRIMARY, SECONDARY_TEXT_COLOR } from 'attrs/colors';

export const RadioGroupWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.down('xs')} {
    flex-direction: column;
    align-items: flex-start;
  }
`
);

export const RadioLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > .MuiTypography-subtitle2 {
    font-size: 0.75rem;
    color: ${COLOR_PRIMARY};
  }

  & > .MuiTypography-caption {
    font-size: 1rem;
    color: ${SECONDARY_TEXT_COLOR};
  }
`;

export const RadioButtonsWrapper = styled.div(
  ({ theme, alignBottom }) => `
  display: flex;
  font-size: 0.875rem;
  min-width: 220px;

  ${alignBottom && `margin-top: 0.875rem;`}

  ${theme.breakpoints.down('xs')} {
    margin-top: 0;
  }

  & > *:first-child {
    margin: 0 1.5rem 0 -0.75rem;
  }
`
);

export const TextField = styled(FormElements.TextField)`
  &.MuiFormControl-marginNormal {
    margin-top: 0.5rem;
  }
`;
