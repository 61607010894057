import styled from 'styled-components';
import { Box, CircularProgress } from '@mui/material';

import { COLOR_WARNING, COLOR_PROGRESS } from '../../../../attrs/colors';

export const StatusChip = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  border: 1px solid ${({ isProgress }) => (isProgress ? COLOR_PROGRESS : COLOR_WARNING)}20;
  border-left: 2px solid ${({ isProgress }) => (isProgress ? COLOR_PROGRESS : COLOR_WARNING)};
  padding: 0.563rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 400;

  & > :first-child {
    margin-right: 0.75rem;
  }
`;

export const StyledProgressIcon = styled(CircularProgress)`
  margin-top: 1.563rem;
`;

export const GeneralContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2.5rem;
`;

export const GeneralGroup = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25rem 2.5rem;
  flex-wrap: wrap;

  .support-select {
    width: 100%;
    max-width: 344px;
  }
`;

export const GeneralContent = styled.div`
  width: 100%;
  display: flex;
  gap: 3.125rem;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
`;
