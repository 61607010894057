import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CardHeader } from '@mui/material';
import T from 'prop-types';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { IrisBatchLimitsPropTypes } from '../../../../../../helpers/propTypes';
import { getValueToNullableCell } from '../../../../../../helpers/stringHandler';
import IrisBatchRecordsEditLimits from '../../IrisBatchRecordsEditModal/IrisBatchRecordsEditLimits';

const labelAmend = 'machines.machine_details.batch_iris.limits';

const IrisBatchLimits = ({ limits, machineId }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const handleClose = () => setEditModal(false);

  return (
    <>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 0 }}
        action={
          <Button
            variant="text"
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
            onClick={() => setEditModal(true)}
          >
            <NotifyIcon iconName="edit" fontSize="medium" />
            {t(`machines.machine_details.edit`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.title`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />
      <NotifyTypo.Subtitle1 fontWeight="400">{t(`${labelAmend}.subtitle`)}</NotifyTypo.Subtitle1>
      <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={2} sx={{ mt: 3, mb: 2 }}>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.high_critical_product_outlet_temperature_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {getValueToNullableCell(limits?.high_critical_product_outlet_temperature_variable)}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 4">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.high_warning_product_outlet_temperature_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {getValueToNullableCell(limits?.high_warning_product_outlet_temperature_variable)}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.high_critical_pressure_inlet_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(limits?.high_critical_pressure_inlet_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.sieve_cleaning_pressure_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(limits?.sieve_cleaning_pressure_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.high_warning_pressure_inlet_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(limits?.high_warning_pressure_inlet_variable)}</NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.dry_running_pressure_protection_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>
            {getValueToNullableCell(limits?.dry_running_pressure_protection_variable)}
          </NotifyTypo.Body1>
        </Box>
        <Box gridColumn="span 1">
          <NotifyTypo.InfoText fontWeight="400">
            {t(`${labelAmend}.dry_running_flow_protection_variable`)}
          </NotifyTypo.InfoText>
          <NotifyTypo.Body1>{getValueToNullableCell(limits?.dry_running_flow_protection_variable)}</NotifyTypo.Body1>
        </Box>
      </Box>

      <IrisBatchRecordsEditLimits open={editModal} handleClose={handleClose} data={limits} machineId={machineId} />
    </>
  );
};

IrisBatchLimits.propTypes = {
  limits: IrisBatchLimitsPropTypes,
  machineId: T.string.isRequired
};

IrisBatchLimits.defaultProps = {
  limits: null
};

export default IrisBatchLimits;
