import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { FormElements, NotifyIcon } from 'web-components';

import { resetSapStatus, verifySapId } from 'redux/organizations/actions';
import { getVerification, getVerificationStatus } from 'redux/organizations/selectors';

import { STATUS_VALID, STATUS_INVALID, STATUS_NOT_VERIFIED } from 'attrs/salesforce';
import { COLOR_WARNING, COLOR_PROGRESS } from 'attrs/colors';

import { isLoading } from 'helpers/utils';

import { StatusChip, StyledProgressIcon } from './elements';

const SapFormElement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const verificationStatus = useSelector(getVerificationStatus);
  const { loadingState } = useSelector(getVerification);
  const { handleChange, values, touched, errors } = useFormikContext();

  const { sap_account: sapAccount } = values;

  const handleTextFieldChange = value => {
    dispatch(resetSapStatus(STATUS_NOT_VERIFIED));
    handleChange(value);
  };

  const handleTextFieldBlur = () => {
    if (values.sap_account) dispatch(verifySapId(values.sap_account));
  };

  const isLoadingState = isLoading(loadingState.status);
  const shouldShowStatusChip = verificationStatus === STATUS_INVALID || isLoadingState;

  const endAdornment = {
    true: <></>,
    [verificationStatus === STATUS_VALID]: (
      <NotifyIcon
        iconName="successFilled"
        fontSize="medium"
        style={{ margin: '1.625rem -0.313rem 0 0' }}
        data-testid="success-icon"
      />
    ),
    [isLoadingState]: <StyledProgressIcon size={14} />
  }.true;

  const verificationMessage = {
    true: '',
    [verificationStatus === STATUS_INVALID]: t('customers.form.sap_verification_failed'),
    [verificationStatus === STATUS_NOT_VERIFIED && isLoadingState]: t('customers.form.sap_verification_progress')
  }.true;

  return (
    <>
      <Grid container wrap="nowrap" width="100%" maxWidth={344}>
        <FormElements.TextField
          label={t('customers.form.sap_account')}
          fullWidth
          disabled={isLoadingState}
          id="sap_account"
          margin="none"
          name="sap_account"
          onChange={handleTextFieldChange}
          onBlur={handleTextFieldBlur}
          value={values.sap_account}
          error={errors.sap_account && touched.sap_account}
          InputProps={{ endAdornment }}
          style={{ maxWidth: 344 }}
        />
      </Grid>
      {sapAccount && shouldShowStatusChip && (
        <StatusChip isProgress={isLoadingState} data-testid="status-chip">
          {isLoadingState ? (
            <NotifyIcon iconName="clock" style={{ color: COLOR_PROGRESS, fontSize: '1.5rem' }} />
          ) : (
            <NotifyIcon iconName="warning" style={{ color: COLOR_WARNING, fontSize: '1.625rem' }} />
          )}
          <span>{verificationMessage}</span>
        </StatusChip>
      )}
    </>
  );
};

export default SapFormElement;
