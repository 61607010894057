import * as constants from './constants';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';
import { removeEmptyValuesRecursivelyPaginated } from '../utils';

/**
 * Gateways V2 - Get Gateway By ID
 * @param {{
 *    gatewayId: string,
 *  }} params
 * @returns {{type: string, inviteUser}}
 */
const gatewaysV2GetByIdAction = ({ gatewayId }) => ({
  type: constants.GATEWAYS_V2_GET_BY_ID,
  gatewayId
});

/**
 * Gateways V2 - Get machines that belongs to the gateway
 * @param {string} gatewayId
 * @param {
 *  {
 *    commission_number: string|undefined,
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: ''|undefined
 *  }
 * } params
 * @returns {{type: string, params}}
 */
const gatewaysV2GetMachinesByGtwIdAction = (gatewayId, params) => ({
  type: constants.GATEWAYS_V2_MACHINE_BY_GTW_ID,
  gatewayId,
  params: removeEmptyValuesRecursivelyPaginated(params)
});

/**
 * Gateways V2 - Bind organization to a gateway
 * @param {{
 *    gatewayId: string,
 *    organizationId: string
 *  }} params
 * @returns {{organizationId, type: string, gatewayId}}
 */
const gatewayV2BindOrganizationAction = ({ gatewayId, organizationId }) => ({
  type: constants.GATEWAYS_V2_BIND_ORGANIZATION,
  gatewayId,
  organizationId
});

/**
 * Gateways V2 - Unbind organization from a gateway
 * @param {{
 *    gatewayId: string,
 *    organizationId: string
 *  }} params
 * @returns {{organizationId, type: string, gatewayId}}
 */
const gatewayV2UnbindOrganizationAction = ({ gatewayId, organizationId }) => ({
  type: constants.GATEWAYS_V2_UNBIND_ORGANIZATION,
  gatewayId,
  organizationId
});

/**
 * Gateways V2 - List Organization Gateways
 * @param {string} organizationId
 * @param {
 *  {
 *    serial_number: string|undefined,
 *    configured: string|undefined,
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: 'serial_number'|'last_seen'|undefined
 *  }
 * } params
 * @returns {{organizationId, type: string, params}}
 */
const gatewaysV2OrganizationGatewaysAction = (
  organizationId,
  params = {
    limit: PAGINATION_DEFAULT_ROWS_PER_PAGE,
    page: PAGINATION_DEFAULT_START_PAGE
  }
) => ({
  type: constants.GATEWAYS_V2_ORGANIZATION_GATEWAYS,
  organizationId,
  params: removeEmptyValuesRecursivelyPaginated(params)
});

/**
 * Gateways V2 - Bind machine to a gateway
 * @param {{
 *    machineId: string,
 *    gatewayId: string,
 *    reloadMachine: boolean,
 *    reloadResumed: boolean
 *  }} params
 * @returns {{machineId, type: string, gatewayId, reloadMachine}}
 */
const gatewayV2BindMachineAction = ({ machineId, gatewayId, reloadMachine, reloadResumed }) => ({
  type: constants.GATEWAYS_V2_BIND_MACHINE,
  machineId,
  gatewayId,
  reloadMachine,
  reloadResumed // Used at gateway details page
});

/**
 * Gateways V2 - Unbind machine from a gateway
 * @param {{
 *    machineId: string,
 *    gatewayId: string,
 *    reloadMachines: boolean,
 *    reloadGtwMachines: boolean,
 *  }} params
 * @returns {{machineId, type: string, gatewayId, reloadMachine}}
 */
const gatewayV2UnbindMachineAction = (
  { machineId, gatewayId, reloadMachines, reloadGtwMachines } = {
    machineId: '',
    gatewayId: '',
    reloadMachines: false,
    reloadGtwMachines: false
  }
) => ({
  type: constants.GATEWAYS_V2_UNBIND_MACHINE,
  machineId,
  gatewayId,
  reloadMachines,
  reloadGtwMachines
});

/**
 * Gateways V2 - List Machines assigned to an organization without a gateway
 * @param {string} gatewayId
 * @param {
 *  {
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: 'commission_number'|'time'|undefined
 *  }
 * } params
 */
const gatewaysV2ListMachAssignedToOrgWithoutGtwAction = (
  gatewayId,
  params = {
    limit: PAGINATION_DEFAULT_ROWS_PER_PAGE,
    page: PAGINATION_DEFAULT_START_PAGE
  }
) => ({
  type: constants.GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW,
  gatewayId,
  params: removeEmptyValuesRecursivelyPaginated(params)
});

/**
 * Gateways V2 - Get logs that belongs to the gateway
 * @param {string} gatewayId
 * @param {
 *  {
 *    limit: number|5,
 *    page: number|1,
 *    order: 'desc'|'asc'|undefined,
 *    sort: ''|undefined
 *  }
 * } params
 * @returns {{type: string, params}}
 */
const gatewaysV2GetLogsByGtwIdAction = (gatewayId, params) => ({
  type: constants.GATEWAYS_V2_LOGS,
  gatewayId,
  params: removeEmptyValuesRecursivelyPaginated(params)
});

export {
  gatewaysV2GetByIdAction,
  gatewaysV2GetMachinesByGtwIdAction,
  gatewayV2BindOrganizationAction,
  gatewayV2UnbindOrganizationAction,
  gatewaysV2OrganizationGatewaysAction,
  gatewayV2BindMachineAction,
  gatewayV2UnbindMachineAction,
  gatewaysV2ListMachAssignedToOrgWithoutGtwAction,
  gatewaysV2GetLogsByGtwIdAction
};
