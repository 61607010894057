import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { metricDataType } from '../../../../../../attrs/sensorConfig';
import { SectionTitle, TextField } from './elements';
import { getDefaultFieldValue } from '../../../../../../helpers/utils';

const YAxisFields = ({ values, namespace, getError, getHelperText, onBlur, onChange }) => {
  const { t } = useTranslation();
  const namespacePrefix = namespace ? `${namespace}.` : '';

  if (!values.is_custom) {
    return null;
  }

  return (
    <>
      <SectionTitle>{t(`machines.form.sensor.y_axis.title`)}</SectionTitle>
      <TextField
        fullWidth
        disabled={!values.y_axis.max.specified}
        error={getError(`${namespacePrefix}y_axis.max.value`)}
        helperText={getHelperText(`${namespacePrefix}y_axis.max.value`)}
        id={`${namespacePrefix}y_axis.max.value`}
        label={t('machines.form.sensor.y_axis.enter_max_val')}
        margin="normal"
        name={`${namespacePrefix}y_axis.max.value`}
        type="number"
        min="-100000"
        max="100000"
        onBlur={onBlur}
        onChange={onChange}
        required
        value={getDefaultFieldValue(values.y_axis.max.value)}
      />
      <TextField
        fullWidth
        disabled={!values.y_axis.min.specified}
        error={getError(`${namespacePrefix}y_axis.min.value`)}
        helperText={getHelperText(`${namespacePrefix}y_axis.min.value`)}
        id={`${namespacePrefix}y_axis.min.value`}
        label={t('machines.form.sensor.y_axis.enter_min_val')}
        margin="normal"
        name={`${namespacePrefix}y_axis.min.value`}
        type="number"
        min="-100000"
        max="100000"
        onBlur={onBlur}
        onChange={onChange}
        required
        value={getDefaultFieldValue(values.y_axis.min.value)}
      />
    </>
  );
};

YAxisFields.propTypes = {
  values: metricDataType.isRequired,
  namespace: T.string,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  onBlur: T.func.isRequired,
  onChange: T.func.isRequired
};

YAxisFields.defaultProps = {
  namespace: null
};

export default YAxisFields;
