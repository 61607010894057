import { Tab } from '@mui/material';
import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { NotifyIcon } from 'web-components';
import ContentErrorCodes from './ErrorCodes';
import ContentMachineDetails from './MachineDetails';
import ContentMachineStatus from './MachineStatus';
import ContentPlcInformation from './PlcInformation';
import ContentSensorData from './SensorData';
import ContentBatchRecords from './BatchRecords';
import { StaticValues } from './StaticValues/StaticValues';

const createTabDefinitions = showBatchTab => [
  {
    value: 0,
    iconName: 'details',
    title: 'details',
    content: ContentMachineDetails
  },
  {
    value: 1,
    iconName: 'plc',
    title: 'plc',
    content: ContentPlcInformation
  },
  {
    value: 2,
    iconName: 'sensor',
    title: 'sensor_data',
    content: ContentSensorData
  },
  {
    value: 3,
    iconName: 'staticSensor',
    title: 'static_values',
    content: StaticValues
  },
  {
    value: 4,
    iconName: 'status',
    title: 'status',
    content: ContentMachineStatus
  },
  {
    value: 5,
    iconName: 'error',
    title: 'error_codes',
    content: ContentErrorCodes
  },
  {
    value: 6,
    iconName: 'batchRecords',
    title: 'batch_records',
    content: ContentBatchRecords,
    viewBox: '-9 -4 34 34',
    disabled: !showBatchTab
  }
];

const tabCreator = (isMobile, t) => tab => {
  const [hover, setHover] = useState(false);

  const { value, title, disabled, iconName } = tab;

  const getIcon = () => {
    if (iconName === 'details') {
      return (
        <NotifyIcon
          iconName={iconName}
          style={{
            marginRight: '.5rem',
            marginLeft: '.3rem',
            marginTop: '.5rem',
            color: '#E5F1F0',
            width: '35px',
            height: '35px'
          }}
          fontSize="large"
          color="#E5F1F0"
          viewBox="0 0 30 30"
        />
      );
    }

    if (iconName === 'staticSensor') {
      return (
        <NotifyIcon
          iconName={iconName}
          style={{
            marginRight: '.5rem',
            marginLeft: '.3rem',
            marginTop: '.5rem',
            color: '#E5F1F0',
            width: '35px',
            height: '35px'
          }}
          fontSize="large"
          color="#E5F1F0"
          viewBox="0 0 30 30"
        />
      );
    }

    if (iconName === 'batchRecords') {
      return (
        <NotifyIcon
          iconName={iconName}
          style={{
            marginRight: '.5rem',
            marginLeft: '.3rem',
            marginTop: '.5rem',
            color: '#E5F1F0',
            width: '35px',
            height: '35px'
          }}
          fontSize="large"
          color="#E5F1F0"
          viewBox="0 0 30 30"
        />
      );
    }

    return (
      <NotifyIcon
        iconName={iconName}
        style={{ marginRight: '.5rem', color: '#E5F1F0', width: '35px', height: '35px' }}
        fontSize="large"
        color="#E5F1F0"
      />
    );
  };

  return (
    <Tab
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      data-selector={`machine-details-tab-${title}`}
      key={value}
      value={value}
      tabIndex={0}
      disabled={disabled}
      style={{
        display: disabled ? 'none' : 'flex',
        padding: '32px 8px',
        borderTop: '1px solid #E5F1F0',
        alignItems: 'baseline',
        ...(hover
          ? {
              backgroundColor: '#005A52'
            }
          : null)
      }}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={t(`machines.detail.${title}.tab`)} placement="right">
            <div style={{ display: 'flex', alignItems: 'center' }}>{getIcon()}</div>
          </Tooltip>
          <div
            style={{
              color: '#E5F1F0',
              textAlign: 'left',
              display: 'flex',
              alignItems: 'center',
              height: '35px',
              paddingTop: '3px'
            }}
          >
            {!isMobile && t(`machines.detail.${title}.tab`)}
          </div>
        </div>
      }
    />
  );
};

export { createTabDefinitions, tabCreator };
