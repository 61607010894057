import { FormElements, NotifyTypo } from 'web-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import T from 'prop-types';
import { COLOR_PRIMARY } from '../../../../attrs/colors';
import { RadioButtonsWrapper, RadioGroupWrapper, RadioLabelsWrapper } from './SensorData/fields/elements';

const ErrorConfigurationRadioGroup = ({ values, namespace, setFieldValue, isNewError }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const namespacePrefix = namespace ? `${namespace}.` : '';

  const handleRadioChange = e => {
    if (isNewError) {
      setFieldValue(`${namespacePrefix}is_custom`, e.target.value === 'true');
      return true;
    }
    return setFieldValue(`${namespacePrefix}is_custom`, e.target.value === 'true');
  };

  return (
    <RadioGroupWrapper theme={theme} topMargin="1rem">
      <RadioLabelsWrapper>
        <NotifyTypo.InfoText>{t('machines.form.error.error_config')}</NotifyTypo.InfoText>
        <NotifyTypo.Caption>{t('machines.form.error.select_config_type')}</NotifyTypo.Caption>
      </RadioLabelsWrapper>
      <RadioButtonsWrapper theme={theme} alignBottom>
        <label htmlFor="machines.form.machine.is_custom.yes">
          <FormElements.RadioButton
            margin="normal"
            name="is_custom"
            activeColor={COLOR_PRIMARY}
            onChange={handleRadioChange}
            value="false"
            checked={!values.is_custom}
            disabled={!isNewError}
            id="is_custom"
          />
          {t('machines.form.sensor.config_predefined')}
        </label>
        <label htmlFor="machines.form.sensors.is_custom.no">
          <FormElements.RadioButton
            margin="normal"
            name="is_custom"
            activeColor={COLOR_PRIMARY}
            onChange={handleRadioChange}
            value="true"
            checked={values.is_custom}
            disabled={!isNewError}
            id="is_custom"
          />
          {t('machines.form.sensor.config_custom')}
        </label>
      </RadioButtonsWrapper>
    </RadioGroupWrapper>
  );
};

// values, namespace, setFieldValue, isNewError
ErrorConfigurationRadioGroup.propTypes = {
  values: T.shape({
    is_custom: T.bool,
    custom_bool_val: T.bool,
    custom_type: T.string,
    custom_int_val: T.number,
    type: T.string,
    custom_message: T.string
  }).isRequired,
  namespace: T.string,
  setFieldValue: T.func.isRequired,
  isNewError: T.bool
};

ErrorConfigurationRadioGroup.defaultProps = {
  isNewError: false,
  namespace: ''
};

export default ErrorConfigurationRadioGroup;
