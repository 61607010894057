import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataTable, FormElements, NotifyIcon } from 'web-components';

import { GATEWAY_CONNECTED, GATEWAY_NOT_CONNECTED } from 'attrs/gateways';
import { getGatewaysAssignableFiltered } from 'redux/gateways/actions';
import { COLOR_PRIMARY } from 'attrs/colors';
import { FilledInput, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Footer from '../../DataTable/Footer';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../../attrs/pagination';
import { SelectDataItem } from '../../MachineTable/MachineTable.styled';
import { getGateways } from '../../../redux/rootSelectors';

const FilterOutlinedIcon = (
  <InputAdornment position="end" style={{ position: 'absolute', right: '6px', bottom: '22px' }}>
    <NotifyIcon iconName="filterOutlined" />
  </InputAdornment>
);

const GatewayTableAssignable = ({ columns, rows, selectedGateway, setSelectedGateway }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loadingState } = useSelector(getGateways);

  const [currentPage, setCurrentPage] = useState(PAGINATION_DEFAULT_START_PAGE);
  const [currentView, setCurrentView] = useState([]);
  const [currentSort, setCurrentSort] = useState({
    colId: 'connection',
    order: 'desc'
  });
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;

  const [filterGatewayId, setFilterGatewayId] = useState('');
  const [filterConnection, setFilterConnection] = useState('');

  const handleSelectRow = useCallback(row => setSelectedGateway(row), [setSelectedGateway]);

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(
      getGatewaysAssignableFiltered(
        filterGatewayId,
        filterConnection,
        rowsPerPage,
        page,
        currentSort.order,
        currentSort.colId
      )
    );
  };

  const handleSort = (colId, order) => {
    dispatch(
      getGatewaysAssignableFiltered(
        filterGatewayId,
        filterConnection,
        rowsPerPage,
        PAGINATION_DEFAULT_START_PAGE,
        order.toUpperCase(),
        colId
      )
    );
    setCurrentSort({ colId, order });
  };

  const props = {
    dataSelectorTable: 'gateway-table',
    dataSelectorRows: 'gateway-table-row',
    isLoading: loadingState.status === 'IN_PROGRESS',
    columns,
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={rows.total_pages || 1}
        totalLength={rows.total_length || 0}
        totalItemsPerPage={rows.limit || 0}
        onChange={handleChangePage}
      />
    ),
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  useEffect(() => {
    if (rows && rows.data && rows.data.length) {
      setCurrentView(
        DataTable.sortFn(
          rows.data.map(row => ({
            ...row,
            serial_number_radio: (
              <SelectDataItem>
                <FormElements.RadioButton
                  margin="normal"
                  name="link_gateway"
                  activeColor={COLOR_PRIMARY}
                  onChange={() => handleSelectRow(row)}
                  checked={selectedGateway?.serial_number === row?.serial_number}
                  disabled={false}
                  id="link_gateway"
                />
                {row.serial_number ? row.serial_number : '---'}
              </SelectDataItem>
            )
          })),
          currentSort.colId,
          currentSort.order.toUpperCase()
        )
      );
    } else {
      setCurrentView([]);
    }
  }, [rows, currentSort.colId, currentSort.order, selectedGateway, handleSelectRow]);

  const handleChangeFilter = (page, gatewayId, connection) => {
    setCurrentPage(page);
    dispatch(
      getGatewaysAssignableFiltered(gatewayId, connection, rowsPerPage, page, currentSort.order, currentSort.colId)
    );
  };

  const handleFilterConnection = event => {
    const connection = event.target.value;
    if (connection !== filterConnection) {
      setFilterConnection(connection);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, filterGatewayId, connection);
    }
  };

  const handleFilterGatewayId = event => {
    const gatewayId = event.target.value;
    if (gatewayId !== filterGatewayId) {
      setFilterGatewayId(gatewayId);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, gatewayId, filterConnection);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <FormControl variant="filled">
          <InputLabel htmlFor="gateway_id">{t('gateways.filter.gateway_id')}</InputLabel>
          <FilledInput
            id="gateway_id"
            value={filterGatewayId}
            size="small"
            onChange={handleFilterGatewayId}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon sx={{ color: '#2BACA4' }} />
              </InputAdornment>
            }
            sx={{
              width: '300px',
              backgroundColor: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#FFFFFF',
                '@media (hover: none)': {
                  backgroundColor: '#FFFFFF'
                }
              },
              '&.Mui-focused': {
                backgroundColor: '#FFFFFF'
              },
              '&.Mui-focused fieldset': {
                backgroundColor: '#FFFFFF'
              }
            }}
          />
        </FormControl>
        <FormControl variant="filled">
          <InputLabel htmlFor="connection_status">{t('gateways.filter.connection_status')}</InputLabel>
          <Select
            labelId="connection_status"
            id="connection_status_select"
            onChange={handleFilterConnection}
            value={filterConnection}
            sx={{
              width: '300px',
              bgcolor: 'white !important',
              '& .MuiFilledInput-input:focus': {
                backgroundColor: 'white !important'
              }
            }}
            IconComponent={() => FilterOutlinedIcon}
            size="small"
          >
            <MenuItem key="all" value="">
              {t('gateways.all')}
            </MenuItem>
            <MenuItem key={GATEWAY_CONNECTED} value={GATEWAY_CONNECTED}>
              {t('gateways.connected')}
            </MenuItem>
            <MenuItem key={GATEWAY_NOT_CONNECTED} value={GATEWAY_NOT_CONNECTED}>
              {t('gateways.not_connected')}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <DataTable hover {...props} />
    </>
  );
};

GatewayTableAssignable.propTypes = {
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired,
  rows: T.shape({
    data: T.instanceOf(Array),
    limit: T.number,
    order: T.string,
    page: T.number,
    search: T.string,
    sort: T.instanceOf(Array),
    total_length: T.number,
    total_pages: T.number
  }).isRequired,
  selectedGateway: T.instanceOf(Object).isRequired,
  setSelectedGateway: T.func.isRequired
};

export default GatewayTableAssignable;
