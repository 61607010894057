import React from 'react';
import Grid from '@mui/material/Grid';
import { ActiveUsers } from './ActiveUsers';
import { ActiveCustomers } from './ActiveCustomers';
import { CustomersMonth } from './CustomersMonth';
import { FreemiumMonth } from './FreemiumMonth';

export function ProductUsage() {
  window.history.replaceState(null, '', '/dashboard/product_usage');
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ActiveCustomers />
      </Grid>
      <Grid item xs={6}>
        <ActiveUsers />
      </Grid>
      <Grid item xs={6}>
        <CustomersMonth />
      </Grid>
      <Grid item xs={6}>
        <FreemiumMonth />
      </Grid>
    </Grid>
  );
}
