import * as constants from './constants';

const setUserInformation = payload => ({
  type: constants.SET_USER_INFORMATION,
  payload
});

const loadUserInformation = () => ({
  type: constants.LOAD_USER_INFORMATION
});

const loadUser = (id = null) => ({
  type: constants.LOAD_USER,
  id
});

const updateUser = (userId, values) => ({
  type: constants.UPDATE_USER,
  userId,
  values
});

const resetPassword = values => ({
  type: constants.RESET_PASSWORD,
  values
});

export { setUserInformation, loadUserInformation, loadUser, updateUser, resetPassword };
