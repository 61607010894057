import * as constants from './constants';
import {
  CHANGE_MACHINES_CLONEABLE_ORDER_RESUME,
  LOAD_CLONEABLE_MACHINES,
  LOAD_CLONEABLE_MACHINES_DETAIL,
  LOAD_MACHINES_CLONEABLE_RESUME_WITH_FILTER
} from './constants';
import { CLONE_MACHINE } from '../machines/constants';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';
import { MACHINE_DEFAULT_LIST_ORDER, MACHINE_DEFAULT_LIST_SORT } from '../../attrs/machines';

export const loadCloneableMachines = organizationId => ({
  type: LOAD_CLONEABLE_MACHINES,
  organizationId
});

export const loadCloneableMachinesDetails = (machineId, organizationId) => ({
  type: LOAD_CLONEABLE_MACHINES_DETAIL,
  machineId,
  organizationId
});

export const setMachineCloneableSortOrder = (sort, order) => ({
  type: CHANGE_MACHINES_CLONEABLE_ORDER_RESUME,
  sort,
  order
});

export const loadMachinesCloneableByResume = (
  organizationId = null,
  commissionNumber = null,
  machineType = null,
  plcType = null,
  customerName = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = MACHINE_DEFAULT_LIST_ORDER,
  sort = MACHINE_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_MACHINES_CLONEABLE_RESUME,
  organizationId,
  commissionNumber,
  machineType,
  plcType,
  customerName,
  limit,
  page,
  order,
  sort
});

export const getMachinesCloneableFiltered = (
  page,
  limit,
  order,
  sort,
  organizationId,
  organizationName,
  commissionNumber,
  gatewayId
) => ({
  type: LOAD_MACHINES_CLONEABLE_RESUME_WITH_FILTER,
  organizationId,
  organizationName,
  commissionNumber,
  gatewayId,
  limit,
  page,
  order,
  sort
});

export const createCloneMachine = ({ payload, history }) => ({
  type: CLONE_MACHINE,
  payload,
  history
});
