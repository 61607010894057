import axios from './axios';

const login = (userLogin, password, recaptchaValue) =>
  axios({
    method: 'post',
    url: '/v1/users/login',
    skipAuthRefresh: true,
    data: {
      login: userLogin,
      password,
      recaptchaValue
    }
  });

const logout = () =>
  axios({
    method: 'post',
    url: '/v1/users/logout'
  });

const loadUser = userId =>
  axios({
    method: 'get',
    url: `/v1/users/${userId}`
  });

const updateUser = (userId, values) =>
  axios({
    method: 'put',
    url: `/v1/users/${userId}`,
    data: values
  });

const resetPassword = values =>
  axios({
    method: 'post',
    url: '/v1/users/set-password',
    data: values
  });

const requestResetPassword = values =>
  axios({
    method: 'post',
    url: '/v1/users/reset-password',
    data: values
  });

const loadUserList = organizationId =>
  axios({
    method: 'get',
    url: '/v1/users',
    params: {
      organization_id: organizationId
    }
  });

const loadUserListPaginated = (organizationId, search, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v1/users/paginated',
    params: {
      organization_id: organizationId,
      search,
      limit,
      page,
      order,
      sort
    }
  });

const deleteUser = (userId, organizationId) =>
  axios({
    method: 'delete',
    url: `/v1/users/${userId}`,
    params: {
      organization_id: organizationId
    }
  });

const inviteUser = values =>
  axios({
    method: 'post',
    url: '/v1/users/invite',
    data: values
  });

export {
  login,
  logout,
  loadUser,
  updateUser,
  resetPassword,
  requestResetPassword,
  loadUserList,
  loadUserListPaginated,
  deleteUser,
  inviteUser
};
