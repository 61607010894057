import { COLOR_ERROR, COLOR_SUCCESS } from './colors';

export const GATEWAY_DEFAULT_LIST_SORT = 'connected';
export const GATEWAY_DEFAULT_LIST_ORDER = 'desc';

export const GATEWAY_CONNECTION_TIME = 10;

export const GATEWAY_CONNECTED = 'CONNECTED';
export const GATEWAY_NOT_CONNECTED = 'NOT_CONNECTED';

export const GATEWAY_CONNECTION_STATUS = {
  [GATEWAY_CONNECTED]: { label: 'gateways.connected', color: COLOR_SUCCESS },
  [GATEWAY_NOT_CONNECTED]: { label: 'gateways.not_connected', color: COLOR_ERROR }
};
