import React from 'react';
import { Button } from '@mui/material';
import T, { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { NotifyTypo } from 'web-components';
import { useDispatch } from 'react-redux';
import { renewLicense } from 'redux/licenses/actions';
import { LICENSES_LABEL, LICENSES_NAMES_MAP } from 'attrs/licenses';
import { StyledContent, StyledNewDialog, StyledHandlersCustom } from './elements';

const labelAmmend = 'licenses.renew';

const RenewLicence = ({ open, handleClose, licenseData, organizationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const successToastProps = {
    variant: 'success',
    autoHideDuration: 3000
  };

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const handleDeleteMachine = async () => {
    try {
      dispatch(renewLicense(licenseData, organizationId));
      handleClose(true);
      enqueueSnackbar(t('licenses.renew.success'), successToastProps);
    } catch (err) {
      enqueueSnackbar(t('licenses.renew.error'), errorToastProps);
    }
  };

  const actionBar = (
    <StyledHandlersCustom>
      <Button variant="outlined" style={{ padding: '1rem 2.5rem', marginRight: 25 }} onClick={() => handleClose()}>
        {t(`${labelAmmend}.cancel`)}
      </Button>
      <Button
        variant="outlined"
        data-selector="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        onClick={handleDeleteMachine}
      >
        {t(`${labelAmmend}.action`)}
      </Button>
    </StyledHandlersCustom>
  );

  return (
    <StyledNewDialog
      open={open}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      title={t(`${labelAmmend}.title`)}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="688px"
      content={
        <StyledContent>
          <NotifyTypo.Body1>
            {`${t(`${labelAmmend}.text`)}`}{' '}
            <strong>
              {t(`${t(LICENSES_NAMES_MAP[licenseData.product])} ${t(LICENSES_LABEL[licenseData.plan])}?`)}
            </strong>
          </NotifyTypo.Body1>
        </StyledContent>
      }
    />
  );
};

RenewLicence.defaultProps = {
  organizationId: null
};

RenewLicence.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  licenseData: T.instanceOf(Object).isRequired,
  organizationId: T.string
};

export default RenewLicence;
