import compose from 'lodash/fp/compose';
import { createSelector } from 'reselect';
import * as features from '../../helpers/features';
import { getData, getGateways, getMachines, getOrganizations } from '../rootSelectors';

const getMachinesWithoutGateway = createSelector([compose(getData, getMachines)], machines => {
  // NOTE: with mocked data on localHost we just return existing machines
  // On production we need to filter for machines without gateway SN
  if (features.get('is_mocked_data')) {
    return machines;
  }
  return machines.filter(machine => !machine.gateway_SN && machine.is_notify);
});

const getMachinesByResume = createSelector([compose(getData, getMachines)], (machines, gateways, organizations) => {
  machines.data.map(machine => {
    const assignedGateway = (gateways || []).find(gateway => gateway.serial_number === machine.gateway_SN);
    const assignedOrganization = assignedGateway
      ? (organizations || []).find(org => org.id === assignedGateway.organization_id)
      : null;

    return {
      ...machine,
      gatewaySN: assignedGateway ? assignedGateway.serial_number : null,
      organizationName: assignedOrganization ? assignedOrganization.name : null
    };
  });
});

const getMachinesWithGatewayAndOrganizationName = createSelector(
  [compose(getData, getMachines), compose(getData, getGateways), compose(getData, getOrganizations)],
  (machines, gateways, organizations) => {
    machines.data.map(machine => {
      const assignedGateway = gateways.find(gateway => gateway.serial_number === machine.gateway_SN);
      const assignedOrganization = assignedGateway
        ? organizations.find(org => org.id === assignedGateway.organization_id)
        : null;

      return {
        ...machine,
        gatewaySN: assignedGateway ? assignedGateway.serial_number : null,
        organizationName: assignedOrganization ? assignedOrganization.name : null
      };
    });
  }
);

const getMachinesListResumed = createSelector([getMachines], machines => machines.machinesListResumed);

const getMachineSortOrder = createSelector([getMachines], machine => machine.machineSortOrder);

const getSelectedMachine = createSelector([getMachines], machines => machines.selected);

const getSelectedMetricMachine = createSelector([getMachines], machines => machines.metricsMachine);

const getSelectedPlcMachine = createSelector([getMachines], machines => machines.plcMachine);

const getSensorMachine = createSelector([getMachines], machines => machines.sensorMachine);

const getErrorCode = createSelector([getMachines], machines => machines.errorCode);

const getErrorCodeById = createSelector([getMachines], machines => machines.errorCodeById);

const getBatchRecordsMachine = createSelector([getMachines], machines => machines.batchRecords);

const getStaticSensor = createSelector([getMachines], machines => machines.staticSensor);

const getStatusMachine = createSelector([getMachines], machines => machines.statusMachine);

const getVerification = createSelector([getMachines], machines => machines.verification);

const getVerificationCommissionNumber = createSelector(
  [getVerification],
  verification => verification?.data.commission_number_status
);

const getSelectedStaticValuesIrisV3 = createSelector([getMachines], machines => machines.staticValuesIrisV3);

const getMessagesIrisV3 = createSelector([getMachines], machines => machines.messageIrisV3);

const getStatusMachineIrisV3 = createSelector([getMachines], machines => machines.statusMachineIrisV3);

const getStatusMachineConditionsIrisV3 = createSelector(
  [getMachines],
  machines => machines.statusMachineConditionsIrisV3
);

export {
  getBatchRecordsMachine,
  getErrorCode,
  getErrorCodeById,
  getMachineSortOrder,
  getMachinesByResume,
  getMachinesListResumed,
  getMachinesWithGatewayAndOrganizationName,
  getMachinesWithoutGateway,
  getSelectedMachine,
  getSelectedMetricMachine,
  getSelectedPlcMachine,
  getSensorMachine,
  getStaticSensor,
  getStatusMachine,
  getVerification,
  getVerificationCommissionNumber,
  getSelectedStaticValuesIrisV3,
  getMessagesIrisV3,
  getStatusMachineIrisV3,
  getStatusMachineConditionsIrisV3
};
