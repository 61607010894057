import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik } from 'formik';
import { ErrorCodeTypes, FormElements, NotifyIcon, NotifyTypo, ResponsiveIconButton } from 'web-components';
import { Button, CardContent, Container, IconButton } from '@mui/material';
import EmptyState from '../../../EmptyState';
import { ErrorCodesSchema } from '../../../../attrs/formValidation';
import { setConfirmationRequest } from '../../../../redux/ui/confirmations/actions';
import { getFormikError, getFormikHelperText } from '../../../../helpers/utils';
import {
  StyledCard,
  StyledCardContent,
  StyledCardFormAction,
  StyledCardHeader,
  StyledContentHeaderColumn,
  StyledContentWrapper
} from './elements';
import ErrorConfigurationRadioGroup from './ErrorConfigurationRadioGroup';
import SelectErrorType from './CustomErrorForms/SelectErrorType';
import CustomErrorFields from './CustomErrorForms/CustomErrorFields';
import ErrorTypeList from './CustomErrorForms/ErrorTypeList';
import {
  MachineBreadcrumb,
  MachinePageHeader,
  MachinePageHeaderWithAddButton
} from '../../../../containers/Machines/MachineDetail/MachineDetail.styled';
import { PageHeaderSubTitle, PageHeaderTitle } from '../../../../elements';

const { Heading2 } = NotifyTypo;

const formatErrorCode = (errorCode, t) => {
  if (!errorCode.is_custom) {
    const currentErrorCode =
      ErrorCodeTypes.find(item => item.value === (errorCode.value ? errorCode.value : errorCode.type)) || {};
    return `${currentErrorCode.value}: ${t(`machines.${currentErrorCode.name}`)}`;
  }
  return (
    <>
      <b>{t('machines.form.error.custom_error')}:</b> {errorCode.custom_message}
    </>
  );
};

const NewErrorCodeForm = ({ onDelete, onSubmit, listedValues, isNewError, getHelperText, getError }) => {
  const { t } = useTranslation();

  const initialValues = {
    source: '',
    type: '',
    custom_type: 'BOOL',
    custom_message: '',
    is_custom: false
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      validationSchema={ErrorCodesSchema}
      enableReinitialize
    >
      {({ dirty, setFieldValue, errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <StyledCard>
          <StyledCardContent>
            <StyledCardHeader>
              <Heading2>{t('machines.form.error_code.title')}</Heading2>
            </StyledCardHeader>
            <Container maxWidth="sm">
              <ErrorConfigurationRadioGroup values={values} isNewError={isNewError} setFieldValue={setFieldValue} />
              <ErrorTypeList
                handleBlur={handleBlur}
                handleChange={handleChange}
                error={values}
                namespace={null}
                listedValues={listedValues}
                getHelperText={getHelperText}
                getError={getError}
                formatErrorCode={formatErrorCode}
              />
              <FormElements.TextField
                error={getFormikError({ errors, touched })('source')}
                helperText={getFormikHelperText({ errors, t })('source')}
                fullWidth
                id="source"
                label={t('machines.form.error_code.source')}
                margin="normal"
                name="source"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.source}
              />
              <SelectErrorType isNewError={isNewError} error={values} setFieldValue={setFieldValue} namespace={null} />
              <CustomErrorFields handleBlur={handleBlur} error={values} setFieldValue={setFieldValue} />
              <StyledCardFormAction>
                <Button color="secondary" onClick={onDelete} variant="text" type="button">
                  {t('form.delete')}
                </Button>
                <Button
                  data-selector="machine-details--add-error-code-button"
                  color="primary"
                  disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                  onClick={handleSubmit}
                  variant="contained"
                  type="button"
                >
                  {t('form.add')}
                </Button>
              </StyledCardFormAction>
            </Container>
          </StyledCardContent>
        </StyledCard>
      )}
    </Formik>
  );
};

NewErrorCodeForm.propTypes = {
  listedValues: T.arrayOf(T.shape({})),
  onDelete: T.func.isRequired,
  onSubmit: T.func.isRequired,
  isNewError: T.bool,
  getHelperText: T.func.isRequired,
  getError: T.func.isRequired
};

NewErrorCodeForm.defaultProps = {
  isNewError: false,
  listedValues: []
};

const ErrorCodeCard = ({
  errorCode,
  getError,
  getHelperText,
  handleBlur,
  handleChange,
  namespace,
  onDelete,
  isNewError,
  setFieldValue
}) => {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(true);

  return (
    <StyledCard>
      <StyledCardContent>
        <StyledCardHeader>
          <Heading2>{formatErrorCode(errorCode, t)}</Heading2>
          <IconButton onClick={() => setIsHidden(state => !state)}>
            <NotifyIcon iconName="edit" />
          </IconButton>
        </StyledCardHeader>
        {!isHidden && (
          <Container maxWidth="sm">
            <ErrorConfigurationRadioGroup setFieldValue={setFieldValue} values={errorCode} isNewError={isNewError} />
            <ErrorTypeList
              handleBlur={handleBlur}
              handleChange={handleChange}
              error={errorCode}
              namespace={namespace}
              getHelperText={getHelperText}
              getError={getError}
              formatErrorCode={formatErrorCode}
            />
            <FormElements.TextField
              error={getError(`${namespace}.source`)}
              helperText={getHelperText(`${namespace}.source`)}
              fullWidth
              id={`${namespace}.source`}
              label={t('machines.form.sensor.source')}
              margin="normal"
              name={`${namespace}.source`}
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={errorCode.source}
            />
            <SelectErrorType error={errorCode} setFieldValue={setFieldValue} namespace={namespace} />
            <CustomErrorFields
              handleBlur={handleBlur}
              error={errorCode}
              setFieldValue={setFieldValue}
              namespace={namespace}
            />
            <StyledCardFormAction>
              <Button color="secondary" onClick={onDelete} variant="text" type="button">
                {t('form.delete')}
              </Button>
            </StyledCardFormAction>
          </Container>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

ErrorCodeCard.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  onDelete: T.func.isRequired,
  setFieldValue: T.func.isRequired,
  isNewError: T.bool,
  errorCode: T.shape({
    type: T.string,
    source: T.string,
    // Custom error props
    custom_bool_val: T.bool,
    custom_int_val: T.number,
    custom_error_id: T.number,
    custom_message: T.string,
    custom_type: T.string,
    is_custom: T.bool.isRequired
  }).isRequired,
  namespace: T.string.isRequired
};

ErrorCodeCard.defaultProps = {
  isNewError: false
};

const ErrorCodes = ({ getError, getHelperText, handleBlur, handleChange, values, setFieldValue }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [shouldShowNewErrorCodeForm, toggleNewErrorCodeForm] = useState(false);

  const handleAddNewErrorCode = (arrayHelpers, newErrorCodeValues) => {
    arrayHelpers.push({ ...newErrorCodeValues });
    toggleNewErrorCodeForm(false);
  };

  const hasNoCodes = ((((values || {}).configuration || {}).plc || {}).errors || []).length === 0;
  const shouldShowEmptyState = hasNoCodes && !shouldShowNewErrorCodeForm;

  const confirmBeforeDeleting =
    onDelete =>
    (...props) => {
      dispatch(
        setConfirmationRequest({
          message: 'dialog.confirmation.delete_error_code',
          action: () => onDelete(...props)
        })
      );
    };

  const breadcrumbObj = [
    { route: '/machines', name: `${t('machines.title')}` },
    { route: `/machines/${values.id || ''}`, name: values.commission_number || t('machines.detail.new_machine') },
    { route: null, name: `${t('machines.detail.error_codes.title')}` }
  ];

  return (
    <FieldArray name="configuration.plc.errors">
      {arrayHelpers =>
        shouldShowEmptyState ? (
          <StyledContentWrapper elevation={3}>
            <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

            <MachinePageHeader>
              <PageHeaderTitle>{t('machines.detail.error_codes.title')}</PageHeaderTitle>
              <PageHeaderSubTitle>{t('machines.detail.subtitle')}</PageHeaderSubTitle>
            </MachinePageHeader>

            <StyledCard>
              <CardContent>
                <EmptyState
                  type="machine_details_error_code"
                  actionButton={
                    <ResponsiveIconButton
                      data-selector="machine-details--new-error-code-button"
                      buttonText={t('machines.form.error_code.add_new_error_code')}
                      color="primary"
                      iconName="plus"
                      variant="outlined"
                      onClick={() => toggleNewErrorCodeForm(true)}
                    />
                  }
                />
              </CardContent>
            </StyledCard>
          </StyledContentWrapper>
        ) : (
          <StyledContentWrapper elevation={3}>
            <StyledContentHeaderColumn>
              <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

              <MachinePageHeaderWithAddButton>
                <MachinePageHeader>
                  <PageHeaderTitle>{t('machines.detail.error_codes.title')}</PageHeaderTitle>
                  <PageHeaderSubTitle>{t('machines.detail.subtitle')}</PageHeaderSubTitle>
                </MachinePageHeader>

                <ResponsiveIconButton
                  buttonText={t('machines.form.error_code.add_new_error_code')}
                  color="primary"
                  iconName="plus"
                  variant="outlined"
                  onClick={() => toggleNewErrorCodeForm(true)}
                />
              </MachinePageHeaderWithAddButton>
            </StyledContentHeaderColumn>

            <Container maxWidth="md" style={{ marginTop: '2rem' }}>
              {shouldShowNewErrorCodeForm && (
                <NewErrorCodeForm
                  listedValues={hasNoCodes ? [] : values.configuration.plc.errors}
                  onDelete={confirmBeforeDeleting(() => toggleNewErrorCodeForm(false))}
                  onSubmit={newValues => handleAddNewErrorCode(arrayHelpers, newValues)}
                  isNewError
                  getHelperText={getHelperText}
                  getError={getError}
                />
              )}
              {!hasNoCodes &&
                values.configuration.plc.errors.map((errorCode, idx) => (
                  <ErrorCodeCard
                    errorCode={errorCode}
                    getError={getError}
                    getHelperText={getHelperText}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    key={`error-code-card-${idx}`} // eslint-disable-line react/no-array-index-key
                    namespace={`configuration.plc.errors.${idx}`}
                    onDelete={confirmBeforeDeleting(() => arrayHelpers.remove(idx))}
                    values={values}
                    isNewError={false}
                    setFieldValue={setFieldValue}
                    idx={idx}
                  />
                ))}
            </Container>
          </StyledContentWrapper>
        )
      }
    </FieldArray>
  );
};

ErrorCodes.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  setFieldValue: T.func.isRequired,
  values: T.shape({
    id: T.string,
    commission_number: T.string,
    configuration: T.shape({
      plc: T.shape({
        errors: T.arrayOf(T.shape({}))
      }).isRequired
    }).isRequired
  }).isRequired
};

export default ErrorCodes;
