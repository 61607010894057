import { ERROR, WARNING, SUCCESS, INFO, IN_PROGRESS } from './status';

// CUSTOMIZABLE COLORS
export const COLOR_MAIN_HIGHLIGHT = ((window || {}).colors || {}).MAIN_HIGHLIGHT || '#2B2F42';
export const COLOR_MAIN_HIGHLIGHT_TEXT = ((window || {}).colors || {}).MAIN_HIGHLIGHT_TEXT || '#FFFFFF';
export const COLOR_MAIN_HIGHLIGHT_TEXT_SECONDARY =
  ((window || {}).colors || {}).MAIN_HIGHLIGHT_TEXT_SECONDARY || '#B1B7C5';
export const COLOR_PRIMARY = ((window || {}).colors || {}).PRIMARY || '#58BAAA';
export const COLOR_PRIMARY_CONTRAST = ((window || {}).colors || {}).PRIMARY_CONTRAST || '#FFFFFF';
export const COLOR_SECONDARY = ((window || {}).colors || {}).SECONDARY || '#F13309';
export const COLOR_SECONDARY_CONTRAST = ((window || {}).colors || {}).SECONDARY_CONTRAST || '#FFFFFF';
export const COLOR_WARNING = ((window || {}).colors || {}).WARNING || '#F19909';
export const COLOR_WARNING_CONTRAST = ((window || {}).colors || {}).WARNING_CONTRAST || '#FFFFFF';
export const COLOR_ERROR = ((window || {}).colors || {}).ERROR || '#F13309';
export const COLOR_ERROR_CONTRAST = ((window || {}).colors || {}).ERROR_CONTRAST || '#FFFFFF';
export const COLOR_SUCCESS = ((window || {}).colors || {}).SUCCESS || '#59D66E';
export const COLOR_SUCCESS_CONTRAST = ((window || {}).colors || {}).SUCCESS_CONTRAST || '#00000026';
export const COLOR_PROGRESS = ((window || {}).colors || {}).PROGRESS || '#419BF7';
export const COLOR_PROGRESS_CONTRAST = ((window || {}).colors || {}).PROGRESS_CONTRAST || '#FFFFFF';
export const COLOR_WIZARD = ((window || {}).colors || {}).WIZARD || ' #2baca3';
export const COLOR_BORDER = ((window || {}).colors || {}).BORDER || '#b9b9bc';
export const COLOR_PRIMARY_BUTTON = ((window || {}).colors || {}).PRIMARY_BUTTON || '#1B6B65';

// GLOBALS
export const LIGHT_GREY = '#F7F7FA';
export const LIGHT_GREY_OPAQUE = '#F7F7FA80';
export const PRIMARY_TEXT_COLOR = '#23252F';
export const SECONDARY_TEXT_COLOR = '#7A7D85';
export const TEXTAREA_BACKGROUND = LIGHT_GREY;
export const DISABLED_COLOR = '#00000026';
export const CHECKBOX_SELECTED = '#2BACA4';

// NOTIFICATION COLORS
export const NOTIFICATION_COLORS = {
  [ERROR]: COLOR_ERROR,
  [WARNING]: COLOR_WARNING,
  [SUCCESS]: COLOR_SUCCESS,
  [INFO]: COLOR_PRIMARY,
  [IN_PROGRESS]: COLOR_PROGRESS
};

// GATEWAY CONFIGURATION COLORS
export const GATEWAY_CONFIGURATION_COLORS = {
  CONFIGURED: '#2FB259',
  UNCONFIGURED: '#F13309'
};

export const SNACKBAR_BACKGROUND = '#333333';
