const PRODUCT_NOTIFY = 'notify';
const PRODUCT_PREVENTIVE = `preventive`;

const ROLE_COORDINATOR = 'coordinator';
const ROLE_TECHNICIAN = 'technician';
const ROLE_MANAGER = 'manager';
const ROLE_OPERATOR = 'operator';
const ROLE_NO_ACCESS = 'no_access';

/**
 * License name for Notify product constant
 * @type {string}
 */
const LICENSE_NOTIFY_PRODUCT = 'NOTIFY';
/**
 * License name for Maintenance Tools product constant
 * @type {string}
 */
const LICENSE_NOTIFY_PREVENTIVE = 'MAINT_PREVENTIVE';

/**
 * Get the product icon from product name constant
 * @param {'notify'|'preventive'} product
 * @returns {string}
 */
function getProductNotifyIconConstant(product) {
  switch (product) {
    case PRODUCT_NOTIFY:
      return `notifyIcon`;
    case PRODUCT_PREVENTIVE:
      return `preventiveIcon`;
    default:
      return ``;
  }
}

export {
  PRODUCT_NOTIFY,
  PRODUCT_PREVENTIVE,
  ROLE_COORDINATOR,
  ROLE_TECHNICIAN,
  ROLE_MANAGER,
  ROLE_OPERATOR,
  ROLE_NO_ACCESS,
  getProductNotifyIconConstant,
  LICENSE_NOTIFY_PRODUCT,
  LICENSE_NOTIFY_PREVENTIVE
};
