import styled from 'styled-components';
import { Button } from '@mui/material';
import { COLOR_PROGRESS, COLOR_WARNING } from '../../../../attrs/colors';

export const VerifyButton = styled(Button)`
  &.MuiButton-root {
    min-width: 120px;
    height: 56px;
    margin: 16px 0 0 10px;
  }

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.16);
  }
`;

export const StatusChip = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  border: 1px solid ${({ isProgress }) => (isProgress ? COLOR_PROGRESS : COLOR_WARNING)}20;
  border-left: 2px solid ${({ isProgress }) => (isProgress ? COLOR_PROGRESS : COLOR_WARNING)};
  padding: 9px 10px;
  font-size: 0.75rem;
  font-weight: 400;

  & > :first-child {
    margin-right: 12px;
  }
`;
