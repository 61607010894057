import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FormElements, NotifyIcon } from 'web-components';
import { Button, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFormikError, getFormikHelperText } from 'helpers/utils';
import { getOrganizationProductsAccessAction } from '../../../../redux/organizations/actions';
import { StyledBox } from './elements';
import { StyledHandlers, StyledNewDialog, StyledTableRow } from '../../../../components/forms/Customers/elements';
import { getOrganizationProductAccess } from '../../../../redux/organizations/selectors';
import { RadioLabel } from '../Licenses/elements';
import { COLOR_PRIMARY } from '../../../../attrs/colors';
import {
  getProductNotifyIconConstant,
  PRODUCT_NOTIFY,
  PRODUCT_PREVENTIVE,
  ROLE_NO_ACCESS
} from '../../../../attrs/products';

const UsersInviteModal = ({ open, handleClose, organizationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    values, // setFieldValue,
    setValues,
    handleChange,
    resetForm,
    errors,
    touched,
    handleBlur, // isValid,
    submitForm,
    dirty
  } = useFormikContext();

  const onClose = () => {
    resetForm();
    handleClose();
  };

  const getError = getFormikError({ errors, touched });
  const getHelperText = getFormikHelperText({ errors, t });

  let { products_access: productsAccess } = useSelector(getOrganizationProductAccess);
  if (!Array.isArray(productsAccess)) {
    productsAccess = [];
  }

  const handleRadioChange = ({ target: { name, value } }) => {
    setValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRadioDisabled = (role, product) => {
    if (role === ROLE_NO_ACCESS) {
      return true;
    }
    if (values.role_notify === ROLE_NO_ACCESS && product === PRODUCT_PREVENTIVE && role === ROLE_NO_ACCESS) {
      return true;
    }
    return values.role_preventive === ROLE_NO_ACCESS && product === PRODUCT_NOTIFY && role === ROLE_NO_ACCESS;
  };

  useEffect(() => {
    if (open) {
      dispatch(getOrganizationProductsAccessAction(organizationId));
    }
  }, [dispatch, open, organizationId]);

  const userModalActionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={() => onClose()}>
        {t('customers.form.cancel')}
      </Button>
      <Button
        disabled={!dirty || Object.keys(errors).length !== 0}
        variant="contained"
        style={{ padding: '1rem 2.5rem' }}
        color="primary"
        onClick={async () => {
          await submitForm();
          onClose();
        }}
        type="submit"
      >
        {t('form.save')}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      open={open}
      handleClose={onClose}
      title={t('customers.user_management.invite_user_title')}
      subTitle={t('customers.user_management.invite_user_subtitle')}
      displayActionBar="true"
      actionBar={userModalActionBar}
      maxWidth="736px"
      content={
        <StyledBox>
          <FormElements.TextField
            label={t('customers.user_management.list.email')}
            fullWidth
            id="email"
            margin="normal"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            required
            error={getError('email')}
            helperText={getHelperText('email')}
          />
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <StyledTableRow>
                <TableCell sx={{ color: 'rgba(122, 125, 133, 1)' }}>{t('products.products')}</TableCell>
                <TableCell sx={{ color: 'rgba(122, 125, 133, 1)' }}>{t('products.products_roles')}</TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {productsAccess.map(productAccess => (
                <TableRow
                  id={`row_product_access_${productAccess.product}`}
                  key={`row_product_access_${productAccess.product}`}
                >
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', alignContent: 'center' }}>
                      <span style={{ display: 'flex', gap: '0.5rem' }}>
                        <NotifyIcon
                          fontSize="large"
                          iconName={getProductNotifyIconConstant(productAccess.product)}
                          style={{ color: '#2BACA4', fontSize: '24px' }}
                        />
                        {t(`products.${productAccess.product}`)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      row
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      name={`role_${productAccess.product}`}
                      id={`role_${productAccess.product}`}
                    >
                      {Array.isArray(productAccess.roles) ? (
                        productAccess.roles.map(role => (
                          <RadioLabel key={role}>
                            <FormElements.RadioButton
                              margin="normal"
                              activeColor={COLOR_PRIMARY}
                              value={role}
                              id={`role_${productAccess.product}`}
                              name={`role_${productAccess.product}`}
                              onChange={handleRadioChange}
                              disabled={handleRadioDisabled(role, productAccess.product)}
                            />
                            <span>{t(`products.roles.${role}`)}</span>
                          </RadioLabel>
                        ))
                      ) : (
                        <RadioLabel key="no_access">
                          <FormElements.RadioButton
                            margin="normal"
                            name="product"
                            activeColor={COLOR_PRIMARY}
                            value="no_access"
                            id="no_access"
                            disabled
                          />
                          <span>{t(`products.roles.no_access`)}</span>
                        </RadioLabel>
                      )}
                    </RadioGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledBox>
      }
    />
  );
};

UsersInviteModal.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  organizationId: T.string.isRequired
};

export default UsersInviteModal;
