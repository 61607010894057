/**
 * Convert file to a string of base64
 * @param {File | Blob} file
 * @returns {Promise<string>} resultString
 */
export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });
