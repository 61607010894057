import { put, takeLatest } from 'redux-saga/effects';
import * as constants from './constants';
import { actionStatus, getError, statusAction } from '../utils';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';
import { apiGetUsersPaginatedV2, apiInviteUserV2, apiUpdateUserRolesV2 } from '../../api/user_v2';

/**
 *  Invite User - Sagas function
 *
 * @param {{
 *    organization_id: string,
 *    user: {
 *      email: string, roles: Array.<{product: "preventive"|"notify",
 *      role: "coordinator"|"technician"|"manager"|"operator"|"no_access"}>
 *    },
 *  }} inviteUser
 * @returns {Generator<*, void, *>}
 */
function* handlerUsersV2InviteUser({ inviteUser }) {
  yield put(statusAction(constants.USERS_V2_INVITE_USER, actionStatus.START));

  try {
    const inviteUserFiltered = {
      ...inviteUser,
      user: {
        ...inviteUser.user,
        roles: inviteUser.user.roles.filter(role => !(role.role === ''))
      }
    };

    const { data: response } = yield apiInviteUserV2(inviteUserFiltered);

    yield put(statusAction(constants.USERS_V2_INVITE_USER, actionStatus.SUCCESS, { response }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.users.invite.invite_success',
        parameters: {
          email: inviteUser.user.email
        }
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.USERS_V2_INVITE_USER, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

/**
 * Get Users Paginated - This will return an array of users at paginated default return
 * @param {{
 *   organization_id: string,
 *   limit: number,
 *   page: number,
 *   is_active: boolean
 * }} request
 * @returns {Generator<*, void, *>}
 */
function* handlerUsersV2GetUsersPaginated({ request }) {
  yield put(statusAction(constants.USERS_V2_GET_USERS_PAGINATED, actionStatus.START));

  try {
    const { data: response } = yield apiGetUsersPaginatedV2(request);

    yield put(statusAction(constants.USERS_V2_GET_USERS_PAGINATED, actionStatus.SUCCESS, { response }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.USERS_V2_GET_USERS_PAGINATED, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

/**
 * Get Users Invitations Paginated
 * @param {{
 *   organization_id: string,
 *   limit: number,
 *   page: number,
 *   is_active: boolean
 * }} request
 * @returns {Generator<*, void, *>}
 */
function* handlerUsersInvitationsV2GetUsersPaginated({ request }) {
  yield put(statusAction(constants.USERS_V2_GET_USERS_INVITATIONS_PAGINATED, actionStatus.START));

  try {
    const { data: response } = yield apiGetUsersPaginatedV2(request);

    yield put(
      statusAction(constants.USERS_V2_GET_USERS_INVITATIONS_PAGINATED, actionStatus.SUCCESS, {
        response
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.USERS_V2_GET_USERS_INVITATIONS_PAGINATED, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

/**
 * Update the user roles
 * @param {{
 *   user_id: string,
 *   updated_by_user_id: string,
 *   roles: Array.<{
 *     product: "preventive"|"notify",
 *     role: "coordinator"|"technician"|"manager"|"operator"|"no_access",
 *   }>
 * }} updateUserRolesV2Request
 * @returns {Generator<{updateUserRolesV2Request, type: string}|*, void, *>}
 */
function* handlerUpdateUserRolesV2({ updateUserRolesV2Request }) {
  yield put(statusAction(constants.USERS_V2_UPDATE_USER_ROLES, actionStatus.START));
  try {
    const { data: response } = yield apiUpdateUserRolesV2(updateUserRolesV2Request);
    yield put(statusAction(constants.USERS_V2_UPDATE_USER_ROLES, actionStatus.SUCCESS, { response }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.users.edit_roles.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.USERS_V2_UPDATE_USER_ROLES, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.users.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.USERS_V2_INVITE_USER, handlerUsersV2InviteUser);
  yield takeLatest(constants.USERS_V2_GET_USERS_PAGINATED, handlerUsersV2GetUsersPaginated);
  yield takeLatest(constants.USERS_V2_GET_USERS_INVITATIONS_PAGINATED, handlerUsersInvitationsV2GetUsersPaginated);
  yield takeLatest(constants.USERS_V2_UPDATE_USER_ROLES, handlerUpdateUserRolesV2);
}
