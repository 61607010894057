import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorCodeTypes, FormElements } from 'web-components';
import T from 'prop-types';

const ErrorTypeList = ({
  error,
  handleChange,
  namespace,
  handleBlur,
  getHelperText,
  getError,
  formatErrorCode,
  listedValues,
  isNewError
}) => {
  const { t } = useTranslation();

  const namespacePrefix = namespace ? `${namespace}.` : '';

  const errorCodesFiltered = ErrorCodeTypes.filter(
    ect => listedValues.filter(lv => lv.type === ect.value).length === 0 || (!isNewError && ect.value === error.type)
  );

  return (
    <>
      {!error.is_custom ? (
        <FormElements.Select
          fullWidth
          handleChange={handleChange}
          id={`${namespacePrefix}type`}
          label={t('machines.form.error_code.type')}
          name={`${namespacePrefix}type`}
          required
          value={error.type}
          error={getError(`${namespacePrefix}type`)}
          helperText={getHelperText(`${namespacePrefix}type`)}
          withEmptyField
        >
          {errorCodesFiltered.map(errorCodeType => (
            <option key={errorCodeType.value} value={errorCodeType.value}>
              {formatErrorCode(errorCodeType, t)}
            </option>
          ))}
        </FormElements.Select>
      ) : (
        <FormElements.TextField
          error={getError(`${namespacePrefix}custom_message`)}
          helperText={getHelperText(`${namespacePrefix}custom_message`)}
          fullWidth
          id={`${namespacePrefix}custom_message`}
          label={t('machines.form.error.custom_message')}
          margin="normal"
          name={`${namespacePrefix}custom_message`}
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={error.custom_message}
        />
      )}
    </>
  );
};

ErrorTypeList.propTypes = {
  error: T.shape({
    is_custom: T.bool,
    custom_bool_val: T.bool,
    custom_type: T.string,
    custom_int_val: T.number,
    type: T.string,
    custom_message: T.string
  }).isRequired,
  namespace: T.string,
  handleBlur: T.func.isRequired,
  getHelperText: T.func.isRequired,
  getError: T.func.isRequired,
  formatErrorCode: T.func.isRequired,
  handleChange: T.func.isRequired,
  listedValues: T.arrayOf(T.shape({})),
  isNewError: T.bool
};

ErrorTypeList.defaultProps = {
  isNewError: false,
  namespace: '',
  listedValues: []
};

export default ErrorTypeList;
