import T, { bool, func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import EmptyState from 'components/EmptyState';
import CloneMachineInformationStep from 'components/MachineTable/CloneMachineTable/CloneMachineInformationStep';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { createCloneMachine, loadMachinesCloneableByResume } from 'redux/cloneableMachines/actions';
import { getSelectedOrganizationId } from 'redux/organizations/selectors';
import CloneMachineTableSelector from '../../../../../components/MachineTable/CloneMachineTable/CloneMachine';
import { getCloneableMachines } from '../../../../../redux/rootSelectors';
import { StyledHandlers } from '../../Licenses/elements';
import { StyledContent, StyledNewDialog } from '../elements';

const labelAmmend = 'machines.overview.clone_modal';

const CloneMachine = ({ open, handleClose, organizationById, customer }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const organizationId = useSelector(getSelectedOrganizationId);
  const { data } = useSelector(getCloneableMachines);
  const [step, setStep] = React.useState(0);
  const [selectedMachineToClone, setSelectedMachineToClone] = useState({});
  const [checkboxValues, setCheckboxValues] = useState({
    machine_detail: {
      type: '',
      checked: false
    },
    base_information: {
      type: '',
      checked: false
    },
    technical_information: {
      type: '',
      checked: false
    },
    feature_access: {
      type: '',
      checked: false
    },
    notify: {
      type: '',
      checked: false
    },
    plc: {
      type: '',
      checked: false
    },
    monitoring_points: {
      type: '',
      checked: false
    },
    sensor_data: {
      type: '',
      checked: false
    },
    static_sensor_data: {
      type: '',
      checked: false
    },
    statuses: {
      type: '',
      checked: false
    },
    error_codes: {
      type: '',
      checked: false
    },
    batch_data_points: {
      type: '',
      checked: false
    },
    preventive_maintenance_trial: {
      type: '',
      checked: false
    },
    preventive_license_tasks: {
      type: '',
      checked: false
    },
    select_all: {
      type: '',
      checked: false
    }
  });

  const handleCloseAndResetStep = () => {
    setSelectedMachineToClone({});
    setStep(0);
    handleClose();
  };

  const handleNextStep = () => {
    setStep(prevStep => prevStep + 1);
  };

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const handleClone = async () => {
    const payload = {
      machine_id: selectedMachineToClone.id,
      organization_id: organizationId,
      to_clone_info: Object.entries(checkboxValues)
        .filter(([, value]) => value.checked && value.type !== '')
        .map(([, { type }]) => type)
    };
    try {
      localStorage.setItem('organizationName', customer.name);
      localStorage.setItem('organizationId', customer.id);
      dispatch(
        createCloneMachine({
          payload,
          history: 'history'
        })
      );
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.error_create_clone'), errorToastProps);
      }
    }
  };

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={handleCloseAndResetStep}>
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!selectedMachineToClone}
        onClick={step === 0 ? handleNextStep : handleClone}
      >
        {step === 0 ? t('machines.overview.clone_modal.form.continue') : t('machines.overview.clone_modal.form.clone')}
      </Button>
    </StyledHandlers>
  );

  useEffect(() => {
    if (open) {
      dispatch(loadMachinesCloneableByResume(organizationId));
    }
  }, [dispatch, organizationId, open]);

  return (
    <StyledNewDialog
      open={open}
      handleClose={handleCloseAndResetStep}
      title={t(`${labelAmmend}.title`)}
      subTitle={t(`${labelAmmend}.subtitle`)}
      displayActionBar="true"
      actionBar={actionBar}
      content={
        <StyledContent>
          {!data && <EmptyState type="machine_customer_detail" />}
          {data && step === 0 && (
            <CloneMachineTableSelector
              rows={data}
              handleNextStep={handleNextStep}
              selectedMachineToClone={selectedMachineToClone}
              setSelectedMachineToClone={setSelectedMachineToClone}
            />
          )}
          {data && step === 1 && (
            <CloneMachineInformationStep
              handleNextStep={handleNextStep}
              machineId={selectedMachineToClone.id ? selectedMachineToClone.id : ''}
              organizationId={organizationById}
              checkboxValues={checkboxValues}
              setCheckboxValues={setCheckboxValues}
            />
          )}
        </StyledContent>
      }
    />
  );
};

CloneMachine.propTypes = {
  open: bool.isRequired,
  customer: T.instanceOf(Object).isRequired,
  handleClose: func.isRequired,
  organizationById: T.string.isRequired
};

export default CloneMachine;
