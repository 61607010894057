import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DAY, MONTH, WEEK, YEAR } from '../../TaskRepeatTypes';

export function TaskFrequencyByTimeCard(props) {
  const { color, firstUpdate, repeatEveryTask, repeatTypeTask, isBacklog, frequency, setFrequency } = props;
  const { t } = useTranslation();
  const repeatTypeListInitialValue = [
    {
      title: t('enum.repeat_type_before.DAY'),
      value: DAY,
      disabled: false
    },
    {
      title: t('enum.repeat_type_before.WEEK'),
      value: WEEK,
      disabled: false
    },
    {
      title: t('enum.repeat_type_before.MONTH'),
      value: MONTH,
      disabled: false
    },
    {
      title: t('enum.repeat_type_before.YEAR'),
      value: YEAR,
      disabled: false
    }
  ];
  const [repeatEveryByStatus, setRepeatEveryByStatus] = useState(0);
  const [repeatTypeByStatus, setRepeatTypeByStatus] = useState(DAY);
  const [repeatEveryMaxValue, setRepeatEveryMaxValue] = useState(0);
  const [repeatTypeList, setRepeatTypeList] = useState(repeatTypeListInitialValue);

  const calcRepeatEveryMaxValue = (repeatTypeParam, repeatTypeTaskParam, repeatEveryTaskParam) => {
    switch (repeatTypeTaskParam) {
      case YEAR: {
        switch (repeatTypeParam) {
          case YEAR: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam - 1);
            break;
          }
          case MONTH: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam * 12 - 1);
            break;
          }
          case WEEK: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam * 52 - 1);
            break;
          }
          case DAY: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam * 365 - 1);
            break;
          }
          default: {
            break;
          }
        }
        break;
      }
      case MONTH: {
        switch (repeatTypeParam) {
          case YEAR: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(-1);
            break;
          }
          case MONTH: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam - 1);
            break;
          }
          case WEEK: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam * 4 - 1);
            break;
          }
          case DAY: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam * 30 - 1);
            break;
          }
          default: {
            break;
          }
        }
        break;
      }
      case WEEK: {
        switch (repeatTypeParam) {
          case YEAR: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(-1);
            break;
          }
          case MONTH: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(-1);
            break;
          }
          case WEEK: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam - 1);
            break;
          }
          case DAY: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam * 7 - 1);
            break;
          }
          default: {
            break;
          }
        }
        break;
      }
      case DAY: {
        switch (repeatTypeParam) {
          case YEAR: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(-1);
            break;
          }
          case MONTH: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(-1);
            break;
          }
          case WEEK: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(-1);
            break;
          }
          case DAY: {
            if (repeatEveryTaskParam) setRepeatEveryMaxValue(repeatEveryTaskParam - 1);
            break;
          }
          default: {
            break;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const calcEnabledRepeatType = repeatTypeParam => {
    switch (repeatTypeParam) {
      case YEAR: {
        repeatTypeList[0].disabled = false;
        repeatTypeList[1].disabled = false;
        repeatTypeList[2].disabled = false;
        repeatTypeList[3].disabled = false;
        break;
      }
      case MONTH: {
        repeatTypeList[0].disabled = false;
        repeatTypeList[1].disabled = false;
        repeatTypeList[2].disabled = false;
        repeatTypeList[3].disabled = true;
        break;
      }
      case WEEK: {
        repeatTypeList[0].disabled = false;
        repeatTypeList[1].disabled = false;
        repeatTypeList[2].disabled = true;
        repeatTypeList[3].disabled = true;
        break;
      }
      case DAY: {
        repeatTypeList[0].disabled = false;
        repeatTypeList[1].disabled = true;
        repeatTypeList[2].disabled = true;
        repeatTypeList[3].disabled = true;
        break;
      }
      default:
        break;
    }
    setRepeatTypeList(repeatTypeList);
  };

  useEffect(() => {
    if (frequency) {
      let every = 0;
      let type = DAY;
      if (!isBacklog) {
        let everyMax = -1;
        let typeMax = DAY;
        if (frequency.upcoming) {
          every = frequency.upcoming.amount;
          type = frequency.upcoming.type;
        } else {
          setFrequency({ ...frequency, upcoming: { type, amount: every } });
        }
        if (frequency.backlog) {
          everyMax = frequency.backlog.amount || -1;
          typeMax = frequency.backlog.type || DAY;
        }
        calcRepeatEveryMaxValue(type, typeMax, everyMax);
        calcEnabledRepeatType(typeMax);
      } else {
        if (frequency.backlog) {
          every = frequency.backlog.amount;
          type = frequency.backlog.type;
        } else {
          setFrequency({ ...frequency, backlog: { type, amount: every } });
        }
        const everyMax = repeatEveryTask || -1;
        const typeMax = repeatTypeTask || DAY;
        calcEnabledRepeatType(typeMax);
        calcRepeatEveryMaxValue(type, typeMax, everyMax);
      }
      if (repeatEveryByStatus !== every) {
        setRepeatEveryByStatus(every);
      }
      if (repeatTypeByStatus !== type) {
        setRepeatTypeByStatus(type);
      }
    }
  }, [frequency]);

  useEffect(() => {
    if (firstUpdate) {
      const every = 0;
      const type = DAY;
      setFrequency({ ...frequency, upcoming: { type, amount: every }, backlog: { type, amount: every } });
    }
  }, [repeatEveryTask, repeatTypeTask]);

  const handleRepeatTypeChange = event => {
    const repeatType = event.target.value;
    setRepeatTypeByStatus(repeatType);
    setRepeatEveryByStatus(0);

    if (frequency && setFrequency) {
      if (!isBacklog) {
        setFrequency({ ...frequency, upcoming: { type: repeatType, amount: 0 } });
      } else {
        setFrequency({ ...frequency, backlog: { type: repeatType, amount: 0 } });
      }
    }
  };

  const handleRepeatEveryByStatusChange = event => {
    const repeatEvery = +event.target.value;
    if (repeatEvery <= repeatEveryMaxValue && repeatEvery >= 0) {
      setRepeatEveryByStatus(repeatEvery);
      if (frequency && setFrequency) {
        if (!isBacklog) {
          setFrequency({ ...frequency, upcoming: { ...frequency.upcoming, amount: repeatEvery } });
        } else {
          setFrequency({ ...frequency, backlog: { ...frequency.backlog, amount: repeatEvery } });
        }
      }
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 2,
        borderLeft: 4,
        borderColor: color,
        borderRadius: '8px',
        px: 2,
        py: 2
      }}
    >
      <Grid container spacing={2} columns={12} alignItems="center">
        <Grid item xs={2}>
          <Typography sx={{ fontSize: { xs: 12, sm: 14 } }} gutterBottom>
            {t('task.general_information.frequency.show_task')}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="show_before"
            variant="filled"
            type="number"
            onChange={handleRepeatEveryByStatusChange}
            value={repeatEveryByStatus}
            InputProps={{
              inputProps: {
                min: 0,
                max: repeatEveryMaxValue
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="filled" fullWidth>
            <Select
              fullWidth
              IconComponent={KeyboardArrowDownIcon}
              labelId="period_type_label"
              id="period_type_select"
              onChange={handleRepeatTypeChange}
              value={repeatTypeByStatus}
            >
              <MenuItem key={DAY} value={DAY} disabled={repeatTypeList[0].disabled}>
                {t('enum.repeat_type.DAY')}
              </MenuItem>
              <MenuItem key={WEEK} value={WEEK} disabled={repeatTypeList[1].disabled}>
                {t('enum.repeat_type.WEEK')}
              </MenuItem>
              <MenuItem key={MONTH} value={MONTH} disabled={repeatTypeList[2].disabled}>
                {t('enum.repeat_type.MONTH')}
              </MenuItem>
              <MenuItem key={YEAR} value={YEAR} disabled={repeatTypeList[3].disabled}>
                {t('enum.repeat_type.YEAR')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ fontSize: { xs: 12, sm: 14 } }} gutterBottom>
            {t('task.general_information.frequency.it_runs')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

TaskFrequencyByTimeCard.propTypes = {
  color: PropTypes.string.isRequired,
  firstUpdate: PropTypes.bool.isRequired,
  repeatEveryTask: PropTypes.number.isRequired,
  repeatTypeTask: PropTypes.shape.isRequired,
  isBacklog: PropTypes.bool,
  frequency: PropTypes.shape.isRequired,
  setFrequency: PropTypes.func.isRequired
};

TaskFrequencyByTimeCard.defaultProps = {
  isBacklog: false
};
