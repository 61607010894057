import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Divider } from '@mui/material';

import Details from './Details';
import Status from './Status';
import GrindingTarget from './GrindingTarget';
import { StyledContentWrapper } from '../elements';
import {
  MachineBreadcrumb,
  MachinePageHeader
} from '../../../../../containers/Machines/MachineDetail/MachineDetail.styled';
import { PageHeaderSubTitle, PageHeaderTitle } from '../../../../../elements';

const BatchRecords = ({ getError, getHelperText, handleBlur, handleChange, handleChangeNumber, values }) => {
  const { t } = useTranslation();

  const breadcrumbObj = [
    { route: '/machines', name: `${t('machines.title')}` },
    { route: `/machines/${values.id || ''}`, name: values.commission_number || t('machines.detail.new_machine') },
    { route: null, name: `${t('machines.detail.batch_records.title')}` }
  ];

  return (
    <StyledContentWrapper elevation={3}>
      <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />
      <MachinePageHeader>
        <PageHeaderTitle>{t('machines.detail.batch_records.title')}</PageHeaderTitle>
        <PageHeaderSubTitle>{t('machines.detail.subtitle')}</PageHeaderSubTitle>
      </MachinePageHeader>

      <Divider />
      <Container maxWidth="md">
        <Details
          getError={getError}
          getHelperText={getHelperText}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
        />
        <Status
          getError={getError}
          getHelperText={getHelperText}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
        />
        <GrindingTarget
          getError={getError}
          getHelperText={getHelperText}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleChangeNumber={handleChangeNumber}
          values={values}
        />
      </Container>
    </StyledContentWrapper>
  );
};

BatchRecords.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  handleChangeNumber: T.func.isRequired,
  values: T.shape({
    id: T.string,
    type: T.string,
    commission_number: T.string,
    gateway_SN: T.string,
    mechanical_config: T.shape({
      samba_system: T.bool
    }),
    enabled_config: T.shape({
      batch: T.bool
    })
  }).isRequired
};

export default BatchRecords;
