import { SET_CONFIRMATION_REQUEST, RESOLVE_CONFIRMATION_REQUEST } from './constants';
import { createReducer } from '../../utils';

const initialState = null;

export default createReducer(
  {
    [SET_CONFIRMATION_REQUEST]: (state, { confirmation }) => confirmation,
    [RESOLVE_CONFIRMATION_REQUEST]: () => null
  },
  initialState
);
