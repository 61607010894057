import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import T from 'prop-types';
import ErrorCodeTypeSelection from './ErrorCodeTypeSelection';
import { loadErrorCode } from '../../../../redux/machines/actions';
import { getErrorCode } from '../../../../redux/machines/selectors';
import { loadMessagesIrisV3 } from '../../../../redux/machines_v2/actions';
import { getMessagesIrisV3 } from '../../../../redux/machines_v2/selectors';

import { PlcType } from '../../../../attrs/plcConfigValues';
import ErrorCodeTypeSelectionIrisV3 from './IrisV3/ErrorCodeTypeSelectionIrisV3';

const ConnectedErrorCodeContent = ({ plcType }) => {
  const dispatch = useDispatch();
  const { loadingState, data } = useSelector(getErrorCode);
  const { data: dataMessages, loadingState: loadingStateMessageIrisV3 } = useSelector(getMessagesIrisV3);

  const { machineId } = useParams();

  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      dispatch(loadMessagesIrisV3(machineId));
    } else {
      dispatch(loadErrorCode(machineId));
    }
  }, [dispatch, machineId]);

  return (
    <>
      {plcType.plc_type === PlcType.IRIS_V3 ? (
        <ErrorCodeTypeSelectionIrisV3
          loadingState={loadingStateMessageIrisV3}
          reloadAction={() => dispatch(loadMessagesIrisV3(machineId))}
          data={dataMessages}
          machineId={machineId}
          plcType={plcType}
        />
      ) : (
        <ErrorCodeTypeSelection
          loadingState={loadingState}
          reloadAction={() => dispatch(loadErrorCode(machineId))}
          data={data}
          machineId={machineId}
          plcType={plcType}
        />
      )}
    </>
  );
};

ConnectedErrorCodeContent.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default ConnectedErrorCodeContent;
