import { Layout } from 'web-components';

const NAVIGATION_BAR_HEIGHT = Layout.navbarHeight || 65;
const NAVIGATION_BAR_LOGO_HEIGHT = Layout.navbarLogoHeight || 25;
const PAGE_HEADER_BAR_HEIGHT = Layout.pageHeaderBarHeight || 110;
const FOOTER_NAVIGATION_BAR_HEIGHT = Layout.footerNavbarHeight || 90;
const SUB_NAVIGATION_WIDTH = Layout.subNavWidth || 370;
const MENU_ITEM_HEIGHT = Layout.menuItemHeight || 60;
const MENU_PROFILE_ITEM_HEIGHT = Layout.menuProfileItemHeight || 100;
const EMPTY_STATE_IMAGE_SIZE_DESKTOP = Layout.emptyStateImageSizeDesktop || 69;
const EMPTY_STATE_IMAGE_SIZE_TABLET = Layout.emptyStateImageSizeTablet || 69;

const NOTIFICATION_LIST_DEFAULT_AMOUNT = 5;
const NOTIFICATION_LIST_STEP_AMOUNT = 5;

export {
  NAVIGATION_BAR_HEIGHT,
  NAVIGATION_BAR_LOGO_HEIGHT,
  MENU_ITEM_HEIGHT,
  SUB_NAVIGATION_WIDTH,
  MENU_PROFILE_ITEM_HEIGHT,
  FOOTER_NAVIGATION_BAR_HEIGHT,
  PAGE_HEADER_BAR_HEIGHT,
  EMPTY_STATE_IMAGE_SIZE_DESKTOP,
  EMPTY_STATE_IMAGE_SIZE_TABLET,
  NOTIFICATION_LIST_DEFAULT_AMOUNT,
  NOTIFICATION_LIST_STEP_AMOUNT
};
