const LOAD_MACHINES_RESUME = 'MACHINES_RESUME/LOAD';
const RESET_MACHINES_RESUME = 'MACHINES_RESUME/RESET';
const CHANGE_MACHINES_ORDER_RESUME = 'MACHINES_RESUME/CHANGE_ORDER';
const CHANGE_MACHINES_FILTER_RESUME = 'MACHINE_RESUME/FILTER';
const LOAD_MACHINES = 'MACHINES/LOAD';
const LOAD_SENSOR_MACHINE = 'MACHINES/LOAD_SENSOR';
const LOAD_STATIC_SENSOR = 'MACHINES/LOAD_STATIC_SENSOR';
const DELETE_SENSOR_BY_ID = 'MACHINES/DELETE_SENSOR';
const LOAD_MACHINES_WITH_GATEWAY = 'MACHINES/LOAD_WITH_GATEWAY';
const LOAD_MACHINE = 'MACHINES/LOAD_MACHINE';
const LOAD_MACHINE_PLC_TYPE = 'MACHINES/LOAD_MACHINE_PLC_TYPE';
const LOAD_PLC_MACHINE = 'MACHINES/LOAD_PLC_MACHINE';
const LOAD_PLC_MACHINE_IRIS = 'MACHINES/LOAD_PLC_MACHINE_IRIS';
const UPDATE_MACHINES_GATEWAY = 'MACHINES/UPDATE_GATEWAY';
const UPDATE_SENSOR = 'MACHINES/UPDATE_SENSOR';
const UPDATE_MACHINE = 'MACHINE/UPDATE';
const UPDATE_PLC_MACHINE = 'MACHINE/PLC_UPDATE';
const UPDATE_PLC_MACHINE_IRIS = 'MACHINE/PLC_UPDATE_IRIS';
const CREATE_MACHINE = 'MACHINE/CREATE';
const CREATE_SENSOR_MACHINE = 'MACHINE/CREATE_SENSOR';
const CREATE_NEW_MACHINE = 'MACHINE/CREATE_NEW';
const CLONE_MACHINE = 'MACHINE/CLONE';
const RESET_MACHINE = 'MACHINE/RESET';
const RESET_MACHINES = 'MACHINES/RESET';
const VERIFY_COMMISSION_NUMBER_ID = 'COMMISSION_NUMBER/VERIFY';
const RESET_COMMISSION_NUMBER_STATUS = 'COMMISSION_NUMBER/RESET';
const LOAD_COMMISSION_NUMBER = 'COMMISSION_NUMBER/LOAD';
const UPDATE_COMMISSION_NUMBER = 'COMMISSION_NUMBER/UPDATE';
const LOAD_BATCH_RECORDS_MACHINE = 'MACHINE/LOAD_BATCH_RECORDS';
const CREATE_BATCH_RECORDS_MACHINE = 'MACHINE/CREATE_BATCH_RECORDS';
const UPDATE_BATCH_RECORDS_MACHINE = 'MACHINE/UPDATE_BATCH_RECORDS';
const DELETE_BATCH_RECORDS_MACHINE = 'MACHINE/DELETE_BATCH_RECORDS';
const DELETE_MACHINE = 'MACHINE/DELETE_MACHINE';
const EDIT_MACHINE = 'MACHINE/EDIT_MACHINE';
const LOAD_ERROR_CODE_BY_ID = 'MACHINE/LOAD_ERROR_CODE_BY_ID';
const LOAD_ERROR_CODE = 'MACHINE/LOAD_ERROR_CODE';
const UPDATE_ERROR_CODE = 'MACHINE/UPDATE_ERROR_CODE';
const CREATE_ERROR_CODE = 'MACHINE/CREATE_ERROR_CODE';
const DELETE_ERROR_CODE = 'MACHINE/DELETE_ERROR_CODE';
const LOAD_STATUS_MACHINE = 'MACHINE/LOAD_STATUS';
const CREATE_STATUS_MACHINE = 'MACHINE/CREATE_STATUS';
const UPDATE_STATUS_MACHINE = 'MACHINE/UPDATE_STATUS';
const METRICS_MACHINE_DETAILS = 'MACHINE/MACHINE_METRICS';
const UPDATE_FLAG_REQUIRED_GATEWAY = 'MACHINE/UPDATE_FLAG_REQUIRED_GATEWAY';
const UPDATE_SENSOR_STATUS = 'MACHINE/SENSOR_STATUS';

/** Endpoints V2 */

const LOAD_MACHINE_RESUMED = 'MACHINES/LOAD_LIST_MACHINES';
const LOAD_MACHINES_RESUMED_WITH_FILTER = 'MACHINES/LOAD_ALL_FILTERED';
const UPDATE_STATIC_VALUE = 'MACHINE/UPDATE_STATIC_VALUE';

export {
  CHANGE_MACHINES_FILTER_RESUME,
  CHANGE_MACHINES_ORDER_RESUME,
  CLONE_MACHINE,
  CREATE_BATCH_RECORDS_MACHINE,
  CREATE_ERROR_CODE,
  CREATE_MACHINE,
  CREATE_NEW_MACHINE,
  CREATE_SENSOR_MACHINE,
  CREATE_STATUS_MACHINE,
  DELETE_BATCH_RECORDS_MACHINE,
  DELETE_ERROR_CODE,
  DELETE_MACHINE,
  DELETE_SENSOR_BY_ID,
  EDIT_MACHINE,
  LOAD_BATCH_RECORDS_MACHINE,
  LOAD_COMMISSION_NUMBER,
  LOAD_ERROR_CODE,
  LOAD_ERROR_CODE_BY_ID,
  LOAD_MACHINE,
  LOAD_MACHINE_PLC_TYPE,
  LOAD_MACHINES,
  LOAD_MACHINES_RESUME,
  LOAD_MACHINES_RESUMED_WITH_FILTER,
  LOAD_MACHINES_WITH_GATEWAY,
  LOAD_MACHINE_RESUMED,
  LOAD_PLC_MACHINE,
  LOAD_PLC_MACHINE_IRIS,
  LOAD_SENSOR_MACHINE,
  LOAD_STATIC_SENSOR,
  LOAD_STATUS_MACHINE,
  METRICS_MACHINE_DETAILS,
  RESET_COMMISSION_NUMBER_STATUS,
  RESET_MACHINE,
  RESET_MACHINES,
  RESET_MACHINES_RESUME,
  UPDATE_BATCH_RECORDS_MACHINE,
  UPDATE_COMMISSION_NUMBER,
  UPDATE_ERROR_CODE,
  UPDATE_MACHINE,
  UPDATE_MACHINES_GATEWAY,
  UPDATE_PLC_MACHINE,
  UPDATE_PLC_MACHINE_IRIS,
  UPDATE_SENSOR,
  UPDATE_STATIC_VALUE,
  UPDATE_STATUS_MACHINE,
  VERIFY_COMMISSION_NUMBER_ID,
  UPDATE_FLAG_REQUIRED_GATEWAY,
  UPDATE_SENSOR_STATUS
};
