const GATEWAYS_V2_GET_BY_ID = 'GATEWAYS_V2/GET_BY_ID';
const GATEWAYS_V2_MACHINE_BY_GTW_ID = 'GATEWAYS_V2/MACHINES_BY_GTW_ID';
const GATEWAYS_V2_BIND_ORGANIZATION = 'GATEWAYS_V2/BIND_ORGANIZATION';
const GATEWAYS_V2_UNBIND_ORGANIZATION = 'GATEWAYS_V2/UNBIND_ORGANIZATION';
const GATEWAYS_V2_ORGANIZATION_GATEWAYS = 'GATEWAYS_V2/ORGANIZATION_GATEWAYS';
const GATEWAYS_V2_BIND_MACHINE = 'GATEWAYS_V2/BIND_MACHINE';
const GATEWAYS_V2_UNBIND_MACHINE = 'GATEWAYS_V2/UNBIND_MACHINE';
const GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW =
  'GATEWAYS_V2/GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW';
const GATEWAYS_V2_LOGS = 'GATEWAYS_V2/LOGS';

export {
  GATEWAYS_V2_GET_BY_ID,
  GATEWAYS_V2_MACHINE_BY_GTW_ID,
  GATEWAYS_V2_BIND_ORGANIZATION,
  GATEWAYS_V2_UNBIND_ORGANIZATION,
  GATEWAYS_V2_ORGANIZATION_GATEWAYS,
  GATEWAYS_V2_BIND_MACHINE,
  GATEWAYS_V2_UNBIND_MACHINE,
  GATEWAYS_V2_LIST_MACH_ASSIGNED_TO_ORG_WITHOUT_GTW,
  GATEWAYS_V2_LOGS
};
