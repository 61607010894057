import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE } from './attrs/languages';

import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/pt-br';

// const debug = window.configuration.nodeEnv === 'development';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  debug: false
});

i18n.on('languageChanged', lng => {
  moment.locale(lng);
});

i18n.setDefaultNamespace('generics');

export default i18n;
