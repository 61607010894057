import { createSelector } from 'reselect';
import { getMachinesV2 } from '../rootSelectors';

const getSelectedIrisTableNames = createSelector([getMachinesV2], machinesV2 => machinesV2.irisTableNames);

const getSelectedIrisAutoConfig = createSelector([getMachinesV2], machinesV2 => machinesV2.irisAutoConfig);

const getSelectedStaticValuesIrisV3 = createSelector([getMachinesV2], machines => machines.staticValuesIrisV3);

const getMessagesIrisV3 = createSelector([getMachinesV2], machines => machines.messageIrisV3);

const getStatusMachineIrisV3 = createSelector([getMachinesV2], machines => machines.statusMachineIrisV3);

const getStatusMachineConditionsIrisV3 = createSelector(
  [getMachinesV2],
  machines => machines.statusMachineConditionsIrisV3
);

const getSelectedIrisBatchDetails = createSelector([getMachinesV2], machinesV2 => machinesV2.irisBatch.details);

const getSelectedIrisBatchStatus = createSelector([getMachinesV2], machinesV2 => machinesV2.irisBatch.status);

const getSelectedIrisBatchTarget = createSelector([getMachinesV2], machinesV2 => machinesV2.irisBatch.target);

const getSelectedIrisBatchLimits = createSelector([getMachinesV2], machinesV2 => machinesV2.irisBatch.limits);

const getSelectedIrisBatchMeasurements = createSelector(
  [getMachinesV2],
  machinesV2 => machinesV2.irisBatch.measurements
);

export {
  getSelectedIrisTableNames,
  getSelectedIrisAutoConfig,
  getSelectedStaticValuesIrisV3,
  getMessagesIrisV3,
  getStatusMachineIrisV3,
  getStatusMachineConditionsIrisV3,
  getSelectedIrisBatchDetails,
  getSelectedIrisBatchStatus,
  getSelectedIrisBatchTarget,
  getSelectedIrisBatchLimits,
  getSelectedIrisBatchMeasurements
};
