import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, FilledInput, FormControl, InputAdornment, InputLabel, Stack } from '@mui/material';
import { DataTable } from 'web-components';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import Footer from '../DataTable/Footer';
import { PAGINATION_DEFAULT_START_PAGE, PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN } from '../../attrs/pagination';
import { CUSTOMER_DEFAULT_LIST_ORDER, CUSTOMER_DEFAULT_LIST_SORT } from '../../attrs/customer';
import { loadOrganizationsByResume } from '../../redux/organizations/actions';
import { getOrganizations } from '../../redux/rootSelectors';

const CustomerTable = ({ columns, rows }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { loadingState } = useSelector(getOrganizations);

  const [currentPage, setCurrentPage] = useState(PAGINATION_DEFAULT_START_PAGE);
  const [currentView, setCurrentView] = useState([]);
  const [currentSort, setCurrentSort] = useState({
    colId: CUSTOMER_DEFAULT_LIST_SORT,
    order: CUSTOMER_DEFAULT_LIST_ORDER
  });
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN;

  const [filterCustomerName, setFilterCustomerName] = useState('');

  useEffect(() => {
    if (rows && rows.data && rows.data.length) {
      setCurrentView(
        rows.data.map(row => ({
          ...row,
          logo: row.logo,
          customer_name: row.name,
          sap_id: row.sap_account,
          users: row.user_count,
          machines: row.machine_count
        }))
      );
    } else {
      setCurrentView([]);
    }
  }, [rows]);

  const handleSelectRow = row => history.push(`/customers/${row.id}`);

  const handleSort = (colId, order) => {
    dispatch(
      loadOrganizationsByResume(
        '',
        filterCustomerName,
        rowsPerPage,
        PAGINATION_DEFAULT_START_PAGE,
        order.toUpperCase(),
        colId
      )
    );
    setCurrentSort({ colId, order });
  };

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(
      loadOrganizationsByResume('', filterCustomerName, rowsPerPage, page, currentSort.order, currentSort.colId)
    );
  };

  const handleChangeFilter = (page, customerName, sapId) => {
    setCurrentPage(page);

    dispatch(loadOrganizationsByResume(sapId, customerName, rowsPerPage, page, currentSort.order, currentSort.colId));
  };

  const handleFilterCustomerName = event => {
    const customerName = event.target.value;
    setFilterCustomerName(customerName);
    handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, customerName, '');
  };

  const props = {
    dataSelectorTable: 'customer-table',
    dataSelectorRows: 'customer-table-row',
    isLoading: loadingState.status === 'IN_PROGRESS',
    columns,
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={rows.total_pages || 1}
        totalLength={rows.total_length || 0}
        totalItemsPerPage={rows.limit || 0}
        onChange={handleChangePage}
      />
    ),
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Stack direction="row" spacing={2}>
          <FormControl variant="filled">
            <InputLabel htmlFor="sapId">{t('customers.filter.name')}</InputLabel>
            <FilledInput
              id="sapId"
              value={filterCustomerName}
              onChange={handleFilterCustomerName}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              sx={{
                width: '300px',
                backgroundColor: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                  '@media (hover: none)': {
                    backgroundColor: '#FFFFFF'
                  }
                },
                '&.Mui-focused': {
                  backgroundColor: '#FFFFFF'
                },
                '&.Mui-focused fieldset': {
                  backgroundColor: '#FFFFFF'
                }
              }}
            />
          </FormControl>
        </Stack>
      </Box>
      <DataTable hover {...props} />
    </>
  );
};

CustomerTable.propTypes = {
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired,
  rows: T.shape({
    data: T.instanceOf(Array),
    limit: T.number,
    order: T.string,
    page: T.number,
    search: T.string,
    sort: T.instanceOf(Array),
    total_length: T.number,
    total_pages: T.number
  }).isRequired
};

export { CustomerTable };
