import { MACHINE_DEFAULT_LIST_ORDER, MACHINE_DEFAULT_LIST_SORT } from '../../attrs/machines';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';
import * as constants from './constants';
import { CHANGE_MACHINES_FILTER_RESUME, CHANGE_MACHINES_ORDER_RESUME } from './constants';
import { convertEmptyToNull } from '../../helpers/stringHandler';

const loadMachines = (organizationId = null) => ({
  type: constants.LOAD_MACHINES,
  organizationId
});

const loadMetricsMachineDetails = (machineId = null) => ({
  type: constants.METRICS_MACHINE_DETAILS,
  machineId
});

const loadSensorMachine = (id = null) => ({
  type: constants.LOAD_SENSOR_MACHINE,
  id
});

const deleteSensor = (machineId = null, sensorType = null) => ({
  type: constants.DELETE_SENSOR_BY_ID,
  machineId,
  sensorType
});

const loadMachinesByGateway = (gatewayId = null) => ({
  type: constants.LOAD_MACHINES_WITH_GATEWAY,
  gatewayId
});

const loadMachinesByResume = (
  organizationId = null,
  organizationName = null,
  commissionNumber = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = MACHINE_DEFAULT_LIST_ORDER,
  sort = MACHINE_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_MACHINES_RESUME,
  organizationId,
  organizationName,
  commissionNumber,
  limit,
  page,
  order,
  sort
});

const setMachineSortOrder = (sort, order) => ({
  type: CHANGE_MACHINES_ORDER_RESUME,
  sort,
  order
});

const getMachinesFiltered = (page, limit, order, sort, organizationName, commissionNumber, serialNumber) => ({
  type: constants.LOAD_MACHINES_RESUMED_WITH_FILTER,
  organizationName,
  commissionNumber,
  serialNumber,
  limit,
  page,
  order,
  sort
});

const setMachineFilter = (page, limit, order, sort, organizationId, organizationName, commissionNumber, gatewayId) => ({
  type: CHANGE_MACHINES_FILTER_RESUME,
  organizationId,
  organizationName,
  commissionNumber,
  gatewayId,
  limit,
  page,
  order,
  sort
});

const loadMachine = id => ({
  type: constants.LOAD_MACHINE,
  id
});

const loadMachinePlcType = id => ({
  type: constants.LOAD_MACHINE_PLC_TYPE,
  id
});

const loadPlcMachine = id => ({
  type: constants.LOAD_PLC_MACHINE,
  id
});

const loadPlcMachineIris = machineId => ({
  type: constants.LOAD_PLC_MACHINE_IRIS,
  machineId
});

const updatePlcMachine = (machineId, payload) => ({
  type: constants.UPDATE_PLC_MACHINE,
  machineId,
  payload
});

const updatePlcMachineIris = (machineId, payload) => ({
  type: constants.UPDATE_PLC_MACHINE_IRIS,
  machineId,
  payload
});

const updateSensor = (machineId, payload) => ({
  type: constants.UPDATE_SENSOR,
  machineId,
  payload
});

const createSensor = (machineId, payload) => ({
  type: constants.CREATE_SENSOR_MACHINE,
  machineId,
  payload
});

const loadErrorCode = machineId => ({
  type: constants.LOAD_ERROR_CODE,
  machineId
});

const loadErrorCodeById = (machineId, errorId) => ({
  type: constants.LOAD_ERROR_CODE_BY_ID,
  machineId,
  errorId
});

const updateErrorCode = (machineId, payload) => ({
  type: constants.UPDATE_ERROR_CODE,
  machineId,
  payload
});

const createErrorCode = (machineId, payload) => ({
  type: constants.CREATE_ERROR_CODE,
  machineId,
  payload
});

const deleteErrorCode = (machineId, errorId) => ({
  type: constants.DELETE_ERROR_CODE,
  machineId,
  errorId
});

const createMachine = values => ({
  type: constants.CREATE_MACHINE,
  values
});

const createNewMachine = (payload, history) => ({
  type: constants.CREATE_NEW_MACHINE,
  payload: convertEmptyToNull(payload),
  history
});

const editMachineDetails = payload => ({
  type: constants.EDIT_MACHINE,
  payload: convertEmptyToNull(payload)
});

const deleteMachine = (machineId, organizationId = null) => ({
  type: constants.DELETE_MACHINE,
  machineId,
  organizationId
});

const updateMachine = (id, values) => ({
  type: constants.UPDATE_MACHINE,
  id,
  values
});

const updateMachinesGateway = (gatewaySN, machineIds) => ({
  type: constants.UPDATE_MACHINES_GATEWAY,
  gatewaySN,
  machineIds
});

const resetMachineState = () => ({
  type: constants.RESET_MACHINE
});

const verifyCommissionNumber = commissionNumber => ({
  type: constants.VERIFY_COMMISSION_NUMBER_ID,
  commissionNumber
});

const resetCommissionNumberStatus = status => ({
  type: constants.RESET_COMMISSION_NUMBER_STATUS,
  status
});

const loadBatchRecordsMachine = machineId => ({
  type: constants.LOAD_BATCH_RECORDS_MACHINE,
  machineId
});

const createBatchRecordsMachine = (machineId, payload) => ({
  type: constants.CREATE_BATCH_RECORDS_MACHINE,
  machineId,
  payload
});

const loadStatusMachine = machineId => ({
  type: constants.LOAD_STATUS_MACHINE,
  machineId
});

const createStatusMachine = (machineId, payload) => ({
  type: constants.CREATE_STATUS_MACHINE,
  machineId,
  payload
});

const updateBatchRecordsMachine = (machineId, payload) => ({
  type: constants.UPDATE_BATCH_RECORDS_MACHINE,
  machineId,
  payload
});

const updateStatusMachine = (machineId, payload) => ({
  type: constants.UPDATE_STATUS_MACHINE,
  machineId,
  payload
});

const updateStaticValue = (machineId, payload) => ({
  type: constants.UPDATE_STATIC_VALUE,
  machineId,
  payload
});

const deleteBatchRecordsMachine = machineId => ({
  type: constants.DELETE_BATCH_RECORDS_MACHINE,
  machineId
});

const updateFlagRequiredGateway = (machineId, payload) => ({
  type: constants.UPDATE_FLAG_REQUIRED_GATEWAY,
  machineId,
  payload
});

const updateSensorStatus = (machineId, payload) => ({
  type: constants.UPDATE_SENSOR_STATUS,
  machineId,
  payload
});

const loadMachinesResumed = (
  organizationName = null,
  commissionNumber = null,
  serialNumber = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = MACHINE_DEFAULT_LIST_ORDER,
  sort = MACHINE_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_MACHINE_RESUMED,
  organizationName,
  commissionNumber,
  serialNumber,
  limit,
  page,
  order,
  sort
});

export {
  createBatchRecordsMachine,
  createErrorCode,
  createMachine,
  createNewMachine,
  createSensor,
  createStatusMachine,
  deleteBatchRecordsMachine,
  deleteErrorCode,
  deleteMachine,
  deleteSensor,
  editMachineDetails,
  getMachinesFiltered,
  loadBatchRecordsMachine,
  loadErrorCode,
  loadErrorCodeById,
  loadMachine,
  loadMachinePlcType,
  loadMachines,
  loadMachinesByGateway,
  loadMachinesByResume,
  loadMachinesResumed,
  loadMetricsMachineDetails,
  loadPlcMachine,
  loadPlcMachineIris,
  loadSensorMachine,
  loadStatusMachine,
  resetCommissionNumberStatus,
  resetMachineState,
  setMachineFilter,
  setMachineSortOrder,
  updateBatchRecordsMachine,
  updateErrorCode,
  updateMachine,
  updateMachinesGateway,
  updatePlcMachine,
  updatePlcMachineIris,
  updateSensor,
  updateStaticValue,
  updateStatusMachine,
  verifyCommissionNumber,
  updateFlagRequiredGateway,
  updateSensorStatus
};
