import React from 'react';
import './index.css';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { createRoot } from 'react-dom/client';
import App from './App';
import * as features from './helpers/features';
import configureStore from './redux/store';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { GlobalStyle } from './elements';

features.configure();

const { history, store } = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

const app = (
  <Provider context={ReactReduxContext} store={store}>
    <ConnectedRouter context={ReactReduxContext} history={history}>
      <GlobalStyle />
      <App />
    </ConnectedRouter>
  </Provider>
);

root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
