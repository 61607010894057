import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { FormElements, NotifyTypo } from 'web-components';

import { COLOR_PRIMARY } from 'attrs/colors';

import { RadioButtonsWrapper, RadioGroupWrapper, RadioLabelsWrapper } from './elements';
import { metricDataType } from '../../../../../../attrs/sensorConfig';

/**
 * Return the radio button for chose sensor register type: predefined or custom sensor
 * @param values
 * @param namespace
 * @param setFieldValue
 * @param isNewSensor
 * @returns {JSX.Element}
 * @constructor
 */
const SensorConfigurationRadioGroup = ({ values, namespace, setFieldValue, isNewSensor }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const namespacePrefix = namespace ? `${namespace}.` : '';

  const handleRadioChange = e => {
    setFieldValue(`${namespacePrefix}is_custom`, e.target.value === 'true');
    setFieldValue(`${namespacePrefix}y_axis.max.specified`, e.target.value === 'true');
    setFieldValue(`${namespacePrefix}y_axis.min.specified`, e.target.value === 'true');
  };
  return (
    <RadioGroupWrapper theme={theme} topMargin="1rem">
      <RadioLabelsWrapper>
        <NotifyTypo.InfoText>{t('machines.form.sensor.sensor_config')}</NotifyTypo.InfoText>
        <NotifyTypo.Caption>{t('machines.form.sensor.select_config_type')}</NotifyTypo.Caption>
      </RadioLabelsWrapper>
      <RadioButtonsWrapper theme={theme} alignBottom>
        <label htmlFor="machines.form.sensors.is_custom.yes">
          <FormElements.RadioButton
            margin="normal"
            name="predefined"
            activeColor={COLOR_PRIMARY}
            onChange={handleRadioChange}
            value="false"
            checked={!values.is_custom}
            disabled={!isNewSensor}
          />
          {t('machines.form.sensor.config_predefined')}
        </label>
        <label htmlFor="machines.form.sensors.is_custom.no">
          <FormElements.RadioButton
            margin="normal"
            name="custom"
            activeColor={COLOR_PRIMARY}
            onChange={handleRadioChange}
            value="true"
            checked={values.is_custom}
            disabled={!isNewSensor}
          />
          {t('machines.form.sensor.config_custom')}
        </label>
      </RadioButtonsWrapper>
    </RadioGroupWrapper>
  );
};

SensorConfigurationRadioGroup.propTypes = {
  values: metricDataType.isRequired,
  namespace: T.string,
  setFieldValue: T.func.isRequired,
  isNewSensor: T.bool.isRequired
};

SensorConfigurationRadioGroup.defaultProps = {
  namespace: null
};

export default SensorConfigurationRadioGroup;
