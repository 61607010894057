import React from 'react';
import T from 'prop-types';
import { StyledContentWrapper } from '../../../../components/forms/Machines/MachineDetail/elements';
import ConnectedStaticValueContent from './ConnectedStaticValueContent';

const StaticValue = ({ plcType }) => (
  <>
    <StyledContentWrapper elevation={3}>
      <ConnectedStaticValueContent plcType={plcType} />
    </StyledContentWrapper>
  </>
);

StaticValue.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default StaticValue;
