import axios from './axios';

export const loadTasks = ({ params }) =>
  axios({
    method: 'get',
    url: `/preventive/v1/tasks/machine/${params.machineId}`,
    params: params.requestBody
  });

// TODO: mimic data, take it off as soon as possible
export const loadFakeTasks = ({ params }) => {
  const { name, categories, limit, page, order, sort } = params.requestBody;
  return new Promise(resolve => {
    const response = {
      data: [
        {
          id: 'string',
          machine: {
            machine: '2345678BiscoitoMachine',
            machine_is_notify: true,
            machine_is_preventive: true,
            machine_name: 'Machine Name',
            organization_id: 'string',
            production_line: 'Fake Production Line',
            tag_id: 'STR001'
          },
          task_general_information: {
            assignees: [
              {
                first_name: 'FirstName',
                id: '2345678BiscoitoAssignee',
                is_active: true,
                last_name: 'LastName',
                photo: ''
              }
            ],
            categories: categories || ['CIVIL', 'ELECTRICAL'],
            name: name || 'Task Name, Fist Load'
          }
        }
      ],
      limit,
      order,
      page,
      search: 'string',
      sort,
      total_length: 0,
      total_pages: 1
    };
    setTimeout(() => resolve(response), 200);
  });
};
