import axios from './axios';
import { removeEmptyValuesRecursivelyPaginated } from '../redux/utils';

/**
 * @typedef {import("axios").AxiosPromise} AxiosPromise
 */

/**
 * Machines V2 - List Machines Resumed
 * @param {
 *   {
 *     gateway_id: string|undefined,
 *     organization_id: string|undefined,
 *     organization_name: string|undefined,
 *     commission_number: string|undefined,
 *     serial_number: string|undefined,
 *     limit: number|10,
 *     page: number|1,
 *     order: "DESC"|"ASC"|undefined,
 *     sort: string|undefined
 *   }
 * } params
 * @returns {AxiosPromise}
 */
const apiMachinesV2ListMachinesResumed = params =>
  axios({
    method: 'get',
    url: `/v2/machines/list/resumed`,
    params: removeEmptyValuesRecursivelyPaginated(params)
  });

const loadIrisTableNames = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-table-names`
  });

const updateIrisTableNames = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-table-names`,
    data: payload
  });

const loadIrisAutoConfig = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-auto-config`
  });

const updateIrisAutoConfig = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-auto-config`,
    data: payload
  });

const createStaticValueIrisV3 = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-static-data-config`,
    data: {
      sensor_data: payload
    }
  });

const loadStaticValuesIrisV3 = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-static-data-config`
  });

const updateStaticValueIrisV3 = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-static-data-config`,
    data: {
      sensor_data: payload
    }
  });

const deleteSensorIrisV3 = (machineId, selectedItemId) =>
  axios({
    method: 'delete',
    url: `/v2/machines/${machineId}/iris-static-data-config/${selectedItemId}`
  });

const updateSensorStatusIrisV3 = (machineId, staticSensor) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-static-data-config/${staticSensor}/flip-state`
  });

const loadMessagesIrisV3 = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-messages-config`
  });

const updateMessagesMachineIrisV3 = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-messages-config`,
    data: {
      messages: payload
    }
  });

const loadStatusMachineIrisV3 = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-statuses-config`
  });

const updateStatusMachineIrisV3 = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-statuses-config`,
    data: {
      statuses: payload
    }
  });

const loadStatusMachineConditionsIrisV3 = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-conditions-config`
  });

const updateStatusMachineConditionsIrisV3 = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-conditions-config`,
    data: {
      conditions: payload
    }
  });

/**
 * Load Iris Batch Details
 * @param machineId
 * @return {AxiosPromise}
 */
const loadIrisBatchDetails = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-batch-config/details`
  });

/**
 * Update Iris Batch Details
 * @param {string} machineId
 * @param {{
 *   batch_id_variable: string,
 *   batch_size_variable: string,
 *   end_time_variable: string,
 *   recipe_variable: string,
 *   start_time_variable: string
 * }} payload - The payload to update the batch details
 * @return {AxiosPromise}
 */
const updateIrisBatchDetails = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-batch-config/details`,
    data: {
      details: payload
    }
  });

/**
 * Load Iris Batch Status
 * @param machineId
 * @return {AxiosPromise}
 */
const loadIrisBatchStatus = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-batch-config/status`
  });

/**
 * Update Iris Batch Status
 * @param {string} machineId
 * @param {{
 *   status_cancelled_value: number,
 *   status_completed_before_target_value: number,
 *   status_completed_value: number,
 *   status_completed_with_target_updates_value: number,
 *   status_in_operation_value: number,
 *   status_loading_value: number,
 *   status_stopped_value: number,
 *   status_variable: string
 * }} payload - The payload to update the batch status
 * @return {AxiosPromise}
 */
const updateIrisBatchStatus = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-batch-config/status`,
    data: {
      status: payload
    }
  });

/**
 * Load Iris Batch Target
 * @param machineId
 * @return {AxiosPromise}
 */
const loadIrisBatchTarget = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-batch-config/target`
  });

/**
 * Update Iris Batch Target
 * @param {string} machineId
 * @param {{
 *   target_comment_1: string,
 *   target_comment_2: string,
 *   target_comment_3: string,
 *   target_comment_4: string,
 *   target_type_variable: string,
 *   target_value_variable: string
 * }} payload - The payload to update the batch target
 * @return {AxiosPromise}
 */
const updateIrisBatchTarget = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-batch-config/target`,
    data: {
      target: payload
    }
  });

/**
 * Load Iris Batch Limits
 * @param machineId
 * @return {AxiosPromise}
 */
const loadIrisBatchLimits = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-batch-config/limits`
  });

/**
 * Update Iris Batch Limits
 * @param {string} machineId
 * @param {{
 *   dry_running_flow_protection_variable: string,
 *   dry_running_pressure_protection_variable: string,
 *   high_critical_pressure_inlet_variable: string,
 *   high_critical_product_outlet_temperature_variable: string,
 *   high_warning_pressure_inlet_variable: string,
 *   high_warning_product_outlet_temperature_variable: string,
 *   sieve_cleaning_pressure_variable: string
 * }} payload - The payload to update the batch limits
 * @return {AxiosPromise}
 */
const updateIrisBatchLimits = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-batch-config/limits`,
    data: {
      limits: payload
    }
  });

/**
 * Load Iris Batch Measurements
 * @param machineId
 * @return {AxiosPromise}
 */
const loadIrisBatchMeasurements = machineId =>
  axios({
    method: 'get',
    url: `/v2/machines/${machineId}/iris-batch-config/measurements`
  });

/**
 * Update Iris Batch Measurements
 * @param {string} machineId
 * @param {{
 *   current_cycles_variable: string,
 *   estimated_finish_time_variable: string,
 *   grinding_energy_variable: string,
 *   number_of_passes_variable: string,
 *   progress_variable: string,
 *   specific_energy_variable: string,
 *   total_grinding_energy_variable: string,
 *   total_grinding_time_variable: string
 * }} payload - The payload to update the batch measurements
 * @return {AxiosPromise}
 */
const updateIrisBatchMeasurements = (machineId, payload) =>
  axios({
    method: 'put',
    url: `/v2/machines/${machineId}/iris-batch-config/measurements`,
    data: {
      measurements: payload
    }
  });

export {
  apiMachinesV2ListMachinesResumed,
  loadIrisTableNames,
  updateIrisTableNames,
  loadIrisAutoConfig,
  updateIrisAutoConfig,
  createStaticValueIrisV3,
  loadStaticValuesIrisV3,
  updateStaticValueIrisV3,
  deleteSensorIrisV3,
  updateSensorStatusIrisV3,
  loadMessagesIrisV3,
  updateMessagesMachineIrisV3,
  loadStatusMachineIrisV3,
  updateStatusMachineIrisV3,
  loadStatusMachineConditionsIrisV3,
  updateStatusMachineConditionsIrisV3,
  loadIrisBatchDetails,
  updateIrisBatchDetails,
  loadIrisBatchStatus,
  updateIrisBatchStatus,
  loadIrisBatchTarget,
  updateIrisBatchTarget,
  loadIrisBatchLimits,
  updateIrisBatchLimits,
  loadIrisBatchMeasurements,
  updateIrisBatchMeasurements
};
