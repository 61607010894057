import { Chip } from '@mui/material';
import styled from 'styled-components';
import { GATEWAY_CONFIGURATION_COLORS } from '../../attrs/colors';

const StyledChip = styled(Chip)`
  min-width: 70px;
  max-width: 100%;

  &.configured {
    background-color: ${GATEWAY_CONFIGURATION_COLORS.CONFIGURED}26;
    color: ${GATEWAY_CONFIGURATION_COLORS.CONFIGURED};
  }

  &.unconfigured {
    background-color: ${GATEWAY_CONFIGURATION_COLORS.UNCONFIGURED}26;
    color: ${GATEWAY_CONFIGURATION_COLORS.UNCONFIGURED};
  }
`;

export default StyledChip;
