import styled from 'styled-components';
import { Box, Button } from '@mui/material';
import { NewDialog, NotifyIcon } from 'web-components';

const StyledButton = styled(Button).attrs(() => ({
  variant: 'outlined',
  sx: { p: 1.2 }
}))`
  box-shadow: 0 0.25rem 0.25rem rgba(54, 125, 120, 0.25);

  &.MuiButton-root {
    justify-content: flex-start;
    text-transform: none;
    border-width: 0.125rem;
    text-align: start;

    &:hover {
      border-width: 0.125rem;
    }
  }
`;

const StyledIcon = styled(NotifyIcon).attrs(() => ({
  sx: { m: 2, fontSize: '1.75rem' }
}))``;

const StyledNewDialog = styled(NewDialog)`
  .MuiDialog-paper {
    border-radius: 0.625rem;
    width: 80%;
    max-height: 90%;

    form {
      width: 100%;
    }
  }
  h2:first-of-type {
    padding-bottom: unset;
  }
`;

const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

const StyledContentGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;
`;

const StyledGridItem = styled(Box)`
  width: 100%;
`;

export { StyledButton, StyledIcon, StyledNewDialog, StyledContent, StyledContentGrid, StyledGridItem };
