import { GATEWAY_DEFAULT_LIST_ORDER, GATEWAY_DEFAULT_LIST_SORT } from '../../attrs/gateways';
import {
  PAGINATION_DEFAULT_ROWS_PER_PAGE,
  PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
  PAGINATION_DEFAULT_START_PAGE
} from '../../attrs/pagination';
import * as constants from './constants';
import { CHANGE_GATEWAYS_FILTER_RESUME, CHANGE_GATEWAYS_ORDER_RESUME } from './constants';

const loadGateways = () => ({
  type: constants.LOAD_GATEWAYS
});

const loadGatewaysByResume = (
  organizationId = null,
  organizationName = null,
  configured = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = GATEWAY_DEFAULT_LIST_ORDER,
  sort = GATEWAY_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_GATEWAYS_RESUME,
  organizationId,
  organizationName,
  configured,
  limit,
  page,
  order,
  sort
});

const loadGatewayMachineDetail = (
  organizationId = null,
  serialNumber = null,
  configured = null,
  organizationName = null,
  connection = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = GATEWAY_DEFAULT_LIST_ORDER,
  sort = GATEWAY_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_GATEWAYS_MACHINE_DETAIL,
  organizationId,
  serialNumber,
  configured,
  organizationName,
  connection,
  limit,
  page,
  order,
  sort
});

const loadGatewaysByResumeDetail = (
  organizationId = null,
  organizationName = null,
  configured = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = GATEWAY_DEFAULT_LIST_ORDER,
  sort = GATEWAY_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_GATEWAYS_RESUME_DETAIL,
  organizationId,
  organizationName,
  configured,
  limit,
  page,
  order,
  sort
});

const setGatewaySortOrder = (order, sort) => ({
  type: CHANGE_GATEWAYS_ORDER_RESUME,
  order,
  sort
});

const setGatewayFilter = (page, limit, order, sort, organizationId, organizationName, configured) => ({
  type: CHANGE_GATEWAYS_FILTER_RESUME,
  organizationId,
  organizationName,
  configured,
  limit,
  page,
  order,
  sort
});

const loadGateway = id => ({
  type: constants.LOAD_GATEWAY,
  id
});

const updateGateway = (id, values) => ({
  type: constants.UPDATE_GATEWAY,
  id,
  values
});

const resetGatewayState = () => ({
  type: constants.RESET_GATEWAY
});

const resetGatewaysState = () => ({
  type: constants.RESET_GATEWAYS
});

const loadGatewaysVersions = values => ({
  type: constants.LOAD_GATEWAYS_VERSIONS,
  values
});

const updateGatewayVersion = values => ({
  type: constants.UPDATE_GATEWAY_VERSION,
  values
});

const loadGatewayMachines = (organizationId = null) => ({
  type: constants.LOAD_GATEWAY_MACHINES,
  organizationId
});

const loadGatewayMachinesByGateway = (gatewayId = null) => ({
  type: constants.LOAD_GATEWAY_MACHINES_BY_GATEWAY,
  gatewayId
});

/** Endpoints V2 */

const loadGatewaysList = (
  serialNumber = null,
  configured = null,
  organizationName = null,
  connection = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = GATEWAY_DEFAULT_LIST_ORDER,
  sort = GATEWAY_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_GATEWAYS_LIST_V2,
  serialNumber,
  configured,
  organizationName,
  connection,
  limit,
  page,
  order,
  sort
});

const getGatewayFiltered = (
  organizationId = null,
  serialNumber = null,
  connection = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order,
  sort
) => ({
  type: constants.LOAD_GATEWAYS_RESUME_WITH_FILTER,
  organizationId,
  serialNumber,
  connection,
  limit,
  page,
  order,
  sort
});

const getGatewayFilteredResume = (
  serialNumber = null,
  configured = null,
  organizationName = null,
  connection = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE_MAIN,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = GATEWAY_DEFAULT_LIST_ORDER,
  sort = GATEWAY_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_GATEWAYS_LIST_WITH_FILTER,
  serialNumber,
  configured,
  organizationName,
  connection,
  limit,
  page,
  order,
  sort
});

const loadGatewaysByOrganization = (
  organizationId = null,
  serialNumber = null,
  connection = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order,
  sort
) => ({
  type: constants.LOAD_GATEWAYS_BY_ORGANIZATION,
  organizationId,
  serialNumber,
  connection,
  limit,
  page,
  order,
  sort
});

const loadGatewaysByOrganizationPagination = (
  organizationId = null,
  serialNumber = null,
  configured = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = GATEWAY_DEFAULT_LIST_ORDER,
  sort = GATEWAY_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_GATEWAYS_BY_ORGANIZATION,
  organizationId,
  serialNumber,
  configured,
  limit,
  page,
  order,
  sort
});

const loadGatewaysAssignable = (
  serialNumber = null,
  connection = null,
  limit = PAGINATION_DEFAULT_ROWS_PER_PAGE,
  page = PAGINATION_DEFAULT_START_PAGE,
  order = GATEWAY_DEFAULT_LIST_ORDER,
  sort = GATEWAY_DEFAULT_LIST_SORT
) => ({
  type: constants.LOAD_GATEWAYS_ASSIGNABLE,
  serialNumber,
  connection,
  limit,
  page,
  order,
  sort
});

const getGatewaysAssignableFiltered = (serialNumber = null, connection = null, limit, page, order, sort) => ({
  type: constants.LOAD_GATEWAYS_ASSIGNABLE_WITH_FILTER,
  serialNumber,
  connection,
  limit,
  page,
  sort,
  order
});

const loadGatewaysAssignableMachine = (gatewayId, sort) => ({
  type: constants.LOAD_GATEWAYS_ASSIGNABLE_MACHINE,
  gatewayId,
  sort
});

const updateGatewayBind = (organizationId, payload) => ({
  type: constants.UPDATE_GATEWAY_BIND,
  organizationId,
  payload
});

const unLinkGatewayByOrganizationId = (organizationId, payload) => ({
  type: constants.UNLINK_GATEWAY_BY_ORGANIZATION_ID,
  organizationId,
  payload
});

const createLinkBetweenGatewayMachine = (gatewayId, payload) => ({
  type: constants.CREATE_LINK_BETWEEN_GATEWAY_MACHINE,
  gatewayId,
  payload
});

const linkMachineByGateway = (gatewayId, payload) => ({
  type: constants.LINK_MACHINES_BY_GATEWAY,
  gatewayId,
  payload
});

const unLinkMachineByGateway = (gatewayId, machineId) => ({
  type: constants.UNLINK_MACHINES_BY_GATEWAY,
  gatewayId,
  machineId
});

const loadGatewaysLostSignal = (interval = '30d', limit = 10, page = 1, order, sort) => ({
  type: constants.LOAD_GATEWAYS_LOST_SIGNAL,
  interval,
  limit,
  page,
  order,
  sort
});

const loadMachineWithoutGateway = (limit = 10, page = 1, order, sort) => ({
  type: constants.LOAD_MACHINE_WITHOUT_GATEWAY,
  limit,
  page,
  order,
  sort
});

const loadGatewaysWithIssues = (limit = 10, page = 1, order, sort) => ({
  type: constants.LOAD_GATEWAYS_WITH_ISSUES,
  limit,
  page,
  order,
  sort
});

export {
  createLinkBetweenGatewayMachine,
  getGatewayFiltered,
  getGatewayFilteredResume,
  getGatewaysAssignableFiltered,
  linkMachineByGateway,
  loadGateway,
  loadGatewayMachineDetail,
  loadGatewayMachines,
  loadGatewayMachinesByGateway,
  loadGateways,
  loadGatewaysAssignable,
  loadGatewaysAssignableMachine,
  loadGatewaysByOrganization,
  loadGatewaysByOrganizationPagination,
  loadGatewaysByResume,
  loadGatewaysByResumeDetail,
  loadGatewaysList,
  loadGatewaysLostSignal,
  loadGatewaysVersions,
  loadMachineWithoutGateway,
  loadGatewaysWithIssues,
  resetGatewayState,
  resetGatewaysState,
  setGatewayFilter,
  setGatewaySortOrder,
  unLinkGatewayByOrganizationId,
  unLinkMachineByGateway,
  updateGateway,
  updateGatewayBind,
  updateGatewayVersion
};
