const DEFAULT_LANGUAGE = 'en_US';

const LANGUAGES = [
  {
    value: 'de_DE',
    label: 'profile.languages.german'
  },
  {
    value: 'en_US',
    label: 'profile.languages.english'
  },
  {
    value: 'pt_BR',
    label: 'profile.languages.portuguese'
  }
];

const DATA_PRIVACY_LINKS = {
  de_DE: 'https://www.get-notify.io/de/datenschutz.html',
  en_US: 'https://www.get-notify.io/en/privacy-policy.html',
  pt_BR: 'https://www.get-notify.io/pt/politica-de-privacidade.html'
};

export { DEFAULT_LANGUAGE, LANGUAGES, DATA_PRIVACY_LINKS };
