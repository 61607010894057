import React, { useEffect, useMemo } from 'react';
import T from 'prop-types';
import { FormElements, NotifyIcon, NotifyTypo } from 'web-components';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, RadioGroup, Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { COLOR_PRIMARY, COLOR_PRIMARY_BUTTON } from 'attrs/colors';
import { useFormikContext } from 'formik';
import { getFormikError, getFormikHelperText } from 'helpers/utils';
import {
  NOTI_LICENSES,
  NOTI_PRODUCT,
  PREV_LICENSES_OPTIONS,
  PREV_PRODUCT,
  PRODUCT_LABEL,
  NEW_NOTI_LICENSES_OPTIONS
} from 'attrs/licenses';
import DateTimeProvider from 'components/DateTime/DateTimeProvider';
import { RadioLabel, StyledBox, StyledHandlers, StyledNewDialog, StyledStack } from './elements';

const labelAmend = 'customers.form.add_licenses_modal';

const radioGroup = [
  {
    value: NOTI_PRODUCT,
    id: 1,
    label: `${labelAmend}.notify`,
    iconName: 'notifyIcon'
  },
  { value: PREV_PRODUCT, id: 2, label: `${labelAmend}.preventive_maintenance`, iconName: 'preventiveIcon' }
];

const numberTasks = [
  {
    value: '200',
    label: `${labelAmend}.number_tasks.up_to`
  },
  {
    value: '500',
    label: `${labelAmend}.number_tasks.up_to`
  },
  {
    value: '1000',
    label: `${labelAmend}.number_tasks.up_to`
  },
  {
    value: 'OTHER',
    label: `${labelAmend}.number_tasks.other`
  }
];

const LicenseModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const {
    values,
    setFieldValue,
    setValues,
    handleChange,
    resetForm,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    submitForm,
    initialValues,
    dirty
  } = useFormikContext();

  const formikInitialValues = useFormikContext();

  const hasProduct = !!values.product;
  const NumberTasksIsOther = values.number_of_tasks === 'OTHER';
  const isPrevProduct = values.product === PREV_PRODUCT;
  const isTrialStandard = values.plan === NOTI_LICENSES.TRIAL_STANDARD;
  const isEditing = !!values.subscription_uuid;

  useEffect(() => {
    if (!isEditing) {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);

      const updatedInitialValues = {
        ...formikInitialValues.initialValues,
        expiration_date: date
      };

      formikInitialValues.setValues(updatedInitialValues);
    }
  }, []);

  const handleRadioChange = ({ target: { name, value } }) => {
    setValues(prev => ({
      ...prev,
      [name]: value,
      plan: value === NOTI_PRODUCT ? NOTI_LICENSES.TRIAL_ADVANCED : NOTI_LICENSES.TRIAL_STANDARD,
      ...(value === PREV_PRODUCT
        ? {
            monitoring_points: 5,
            number_of_tasks: 50
          }
        : { number_of_tasks: 50 })
    }));
  };

  const onClose = () => {
    resetForm();
    handleClose();
  };

  const getError = getFormikError({ errors, touched });
  const getHelperText = getFormikHelperText({ errors, t });

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={onClose}>
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={async () => {
          await submitForm();
          onClose();
        }}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t('customers.form.save')}
      </Button>
    </StyledHandlers>
  );

  const planOptions = useMemo(
    () => (values.product === NOTI_PRODUCT ? NEW_NOTI_LICENSES_OPTIONS : PREV_LICENSES_OPTIONS),
    [values.product]
  );

  useEffect(() => {
    if (values.plan === 'COMMISSIONING') {
      setFieldValue('monitoring_points', 1);
    } else if (values.plan === 'BASIC') {
      setFieldValue('monitoring_points', 2);
    } else if (values.plan === 'FREEMIUM') {
      setFieldValue('monitoring_points', 2);
    } else if (values.plan === 'STANDARD') {
      setFieldValue('monitoring_points', 20);
    } else if (values.plan === 'ADVANCED') {
      setFieldValue('monitoring_points', 40);
    } else if (values.plan === 'TRIAL_ADVANCED') {
      setFieldValue('monitoring_points', 40);
    }
  }, [values.plan, setFieldValue]);

  useEffect(() => {
    if (values.plan === 'TRIAL_STANDARD') {
      setFieldValue('number_of_tasks', '50');
    } else if (values.plan === 'STANDARD_200') {
      setFieldValue('number_of_tasks', '200');
    } else if (values.plan === 'STANDARD_500') {
      setFieldValue('number_of_tasks', '500');
    } else if (values.plan === 'STANDARD_1000') {
      setFieldValue('number_of_tasks', '1000');
    }
  }, [values.plan, setFieldValue]);

  return (
    <StyledNewDialog
      open={open}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
      title={isEditing ? `${t(`${labelAmend}.title_edit`)}` : t(`${labelAmend}.title`)}
      subTitle={isEditing ? t(`${labelAmend}.subtitle_edit`) : t(`${labelAmend}.subtitle`)}
      maxWidth="736px"
      actionBar={actionBar}
      data-selector="license-modal-add"
      content={
        <StyledBox>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {!isEditing && (
              <FormControl>
                <NotifyTypo.Body1 sx={{ fontWeight: 500, mb: 2.5 }}>{t(`${labelAmend}.product`)}</NotifyTypo.Body1>
                <RadioGroup row sx={{ gap: 5 }}>
                  {radioGroup.map(radio => (
                    <RadioLabel key={radio.id}>
                      <FormElements.RadioButton
                        margin="normal"
                        name="product"
                        activeColor={COLOR_PRIMARY}
                        onChange={handleRadioChange}
                        value={radio.value}
                        checked={values.product === radio.value}
                        id={radio.value}
                      />
                      <span>
                        <NotifyIcon
                          iconName={radio.iconName}
                          style={{ color: COLOR_PRIMARY_BUTTON, fontSize: '24px' }}
                        />
                        {t(radio.label)}
                      </span>
                    </RadioLabel>
                  ))}
                </RadioGroup>
              </FormControl>
            )}
            {hasProduct && (
              <Stack gap={0}>
                <StyledStack
                  direction="row"
                  sx={{ justifyContent: 'space-between', alignItems: 'baseline', pr: 4, mb: 4 }}
                >
                  <NotifyTypo.Body1 sx={{ fontWeight: 500 }}>
                    {isEditing ? t(PRODUCT_LABEL[values.product]) : t(`${labelAmend}.configuration`)}
                  </NotifyTypo.Body1>
                </StyledStack>
                <FormControl sx={{ gap: 5 }}>
                  <StyledStack direction="row" gap={5} flexWrap="wrap">
                    <FormElements.Select
                      value={values.plan}
                      fullWidth
                      handleChange={ev => {
                        handleChange(ev);
                      }}
                      label={t(`${labelAmend}.license_plan`)}
                      name="plan"
                      id="plan"
                      size="small"
                      margin="none"
                    >
                      {planOptions?.map(option => (
                        <option
                          key={option.value}
                          value={
                            option.value === 'TRIAL' || option.value === 'TRIAL_ADVANCED'
                              ? 'TRIAL_ADVANCED'
                              : option.value
                          }
                        >
                          {t(option.label)}
                        </option>
                      ))}
                    </FormElements.Select>

                    {isPrevProduct && (
                      <FormElements.Select
                        value={values.number_of_tasks}
                        fullWidth
                        handleChange={ev => {
                          handleChange(ev);
                          setFieldValue('number_of_tasks_other', initialValues.number_of_tasks_other);
                        }}
                        label={t(`${labelAmend}.number_tasks.title`)}
                        name="number_of_tasks"
                        id="number_of_tasks"
                        size="small"
                        margin="none"
                        disabled
                      >
                        {isTrialStandard && (
                          <option value="50">{t(`${labelAmend}.number_tasks.up_to`, { number: 50 })}</option>
                        )}
                        {numberTasks?.map(option => (
                          <option key={option.value} value={option.value}>
                            {t(option.label, { number: option.value })}
                          </option>
                        ))}
                      </FormElements.Select>
                    )}

                    {!isPrevProduct && (
                      <FormElements.TextField
                        fullWidth
                        label={t(`${labelAmend}.monitoring_points`)}
                        name="monitoring_points"
                        id="monitoring_points"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={handleChange}
                        disabled
                        error={getError('monitoring_points')}
                        value={`0/${values.monitoring_points}`}
                        helperText={touched.monitoring_points && getHelperText('monitoring_points')}
                      />
                    )}

                    {NumberTasksIsOther && (
                      <FormElements.TextField
                        fullWidth
                        placeholder="Enter the number of tasks"
                        size="small"
                        onChange={handleChange}
                        id="number_of_tasks_other"
                        type="number"
                        name="number_of_tasks_other"
                        error={getError('number_of_tasks_other')}
                        helperText={touched.number_of_tasks_other && getHelperText('number_of_tasks_other')}
                        onBlur={handleBlur}
                        value={values?.number_of_tasks_other}
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    )}
                  </StyledStack>
                  <StyledStack direction="row" spacing={5}>
                    <DateTimeProvider>
                      <DatePicker
                        label={t(`${labelAmend}.activation_date`)}
                        value={values.activation_date ? new Date(values.activation_date) : new Date()}
                        renderInput={params => <FormElements.TextField fullWidth readOnly={isEditing} {...params} />}
                        onChange={newValue => {
                          if (!Number.isNaN(new Date(newValue).getTime())) {
                            const newActivationDate = newValue;
                            const newExpirationDate = new Date(newActivationDate);
                            newExpirationDate.setFullYear(newExpirationDate.getFullYear() + 1);

                            setValues(prev => ({
                              ...prev,
                              activation_date: newActivationDate.toISOString(), // Formato ISO 8601
                              expiration_date: newExpirationDate.toISOString() // Formato ISO 8601
                            }));
                          }
                        }}
                        width="100%"
                        disabled={values.monitoring_points === 0 && isPrevProduct !== true}
                      />

                      <DatePicker
                        label={t(`${labelAmend}.expiration_date`)}
                        value={values.expiration_date ? new Date(values.expiration_date) : new Date()}
                        renderInput={params => <FormElements.TextField fullWidth readOnly={isEditing} {...params} />}
                        minDate={values.activation_date}
                        onChange={newValue => {
                          if (!Number.isNaN(new Date(newValue).getTime())) {
                            setFieldValue('expiration_date', newValue.toISOString()); // Formato ISO 8601
                          }
                        }}
                        disabled={values.monitoring_points === 0 && isPrevProduct !== true}
                      />
                    </DateTimeProvider>
                  </StyledStack>
                  <StyledStack direction="row" spacing={5} gap={5}>
                    <FormElements.TextField
                      sx={{ width: '100%', pr: 4 }}
                      label="Note"
                      placeholder="Type the note here"
                      size="large"
                      onChange={handleChange}
                      id="note"
                      type="text"
                      name="note"
                      error={getError('note')}
                      helperText={touched.note && getHelperText('note')}
                      onBlur={handleBlur}
                      value={values.note}
                    />
                  </StyledStack>
                </FormControl>
              </Stack>
            )}
          </LocalizationProvider>
        </StyledBox>
      }
    />
  );
};

LicenseModal.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired
};

export default LicenseModal;
