import React from 'react';
import { Button, CircularProgress, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import T, { bool, func } from 'prop-types';
import { FormElements } from 'web-components';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  validateBlankSpace
} from 'helpers/utils';
import { StyledContent, StyledContentGrid, StyledGridItem, StyledHandlers, StyledNewDialog } from '../../elements';
import { IrisBatchLimitsSchema } from '../../../../../attrs/formValidation';
import { IrisBatchLimitsPropTypes } from '../../../../../helpers/propTypes';
import { getSelectedIrisBatchLimits } from '../../../../../redux/machines_v2/selectors';
import { updateIrisBatchLimits } from '../../../../../redux/machines_v2/actions';

const labelAmend = 'machines.machine_details.batch_iris.limits';

const IrisBatchRecordsEditLimits = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingState } = useSelector(getSelectedIrisBatchLimits);

  function isSaving() {
    return isLoading(loadingState.status);
  }

  const submitForm = (values, formikHelpers) => {
    dispatch(updateIrisBatchLimits(machineId, values));
    handleClose(true);
    formikHelpers.resetForm();
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSaving()}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`default_actions.save`)}
        {isSaving() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  const handleChangeValue = handleChange => event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Formik
      validationSchema={IrisBatchLimitsSchema}
      initialValues={{
        high_critical_product_outlet_temperature_variable: getDefaultFieldValue(
          data.high_critical_product_outlet_temperature_variable
        ),
        high_warning_product_outlet_temperature_variable: getDefaultFieldValue(
          data.high_warning_product_outlet_temperature_variable
        ),
        high_critical_pressure_inlet_variable: getDefaultFieldValue(data.high_critical_pressure_inlet_variable),
        sieve_cleaning_pressure_variable: getDefaultFieldValue(data.sieve_cleaning_pressure_variable),
        high_warning_pressure_inlet_variable: getDefaultFieldValue(data.high_warning_pressure_inlet_variable),
        dry_running_pressure_protection_variable: getDefaultFieldValue(data.dry_running_pressure_protection_variable),
        dry_running_flow_protection_variable: getDefaultFieldValue(data.dry_running_flow_protection_variable)
      }}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.modal_title`)}
            subTitle={t(`${labelAmend}.modal_subtitle`)}
            maxWidth="978px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGrid>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('high_critical_product_outlet_temperature_variable')}
                        helperText={getHelperText('high_critical_product_outlet_temperature_variable')}
                        fullWidth
                        id="high_critical_product_outlet_temperature_variable"
                        label={t(`${labelAmend}.high_critical_product_outlet_temperature_variable`)}
                        margin="none"
                        size="small"
                        name="high_critical_product_outlet_temperature_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.high_critical_product_outlet_temperature_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('high_warning_product_outlet_temperature_variable')}
                        helperText={getHelperText('high_warning_product_outlet_temperature_variable')}
                        fullWidth
                        id="high_warning_product_outlet_temperature_variable"
                        label={t(`${labelAmend}.high_warning_product_outlet_temperature_variable`)}
                        margin="none"
                        size="small"
                        name="high_warning_product_outlet_temperature_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.high_warning_product_outlet_temperature_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('high_critical_pressure_inlet_variable')}
                        helperText={getHelperText('high_critical_pressure_inlet_variable')}
                        fullWidth
                        id="high_critical_pressure_inlet_variable"
                        label={t(`${labelAmend}.high_critical_pressure_inlet_variable`)}
                        margin="none"
                        size="small"
                        name="high_critical_pressure_inlet_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.high_critical_pressure_inlet_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('sieve_cleaning_pressure_variable')}
                        helperText={getHelperText('sieve_cleaning_pressure_variable')}
                        fullWidth
                        id="sieve_cleaning_pressure_variable"
                        label={t(`${labelAmend}.sieve_cleaning_pressure_variable`)}
                        margin="none"
                        size="small"
                        name="sieve_cleaning_pressure_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.sieve_cleaning_pressure_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('high_warning_pressure_inlet_variable')}
                        helperText={getHelperText('high_warning_pressure_inlet_variable')}
                        fullWidth
                        id="high_warning_pressure_inlet_variable"
                        label={t(`${labelAmend}.high_warning_pressure_inlet_variable`)}
                        margin="none"
                        size="small"
                        name="high_warning_pressure_inlet_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.high_warning_pressure_inlet_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('dry_running_pressure_protection_variable')}
                        helperText={getHelperText('dry_running_pressure_protection_variable')}
                        fullWidth
                        id="dry_running_pressure_protection_variable"
                        label={t(`${labelAmend}.dry_running_pressure_protection_variable`)}
                        margin="none"
                        size="small"
                        name="dry_running_pressure_protection_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.dry_running_pressure_protection_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('dry_running_flow_protection_variable')}
                        helperText={getHelperText('dry_running_flow_protection_variable')}
                        fullWidth
                        id="dry_running_flow_protection_variable"
                        label={t(`${labelAmend}.dry_running_flow_protection_variable`)}
                        margin="none"
                        size="small"
                        name="dry_running_flow_protection_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.dry_running_flow_protection_variable)}
                      />
                    </StyledGridItem>
                  </StyledContentGrid>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

IrisBatchRecordsEditLimits.propTypes = {
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired,
  data: IrisBatchLimitsPropTypes
};

IrisBatchRecordsEditLimits.defaultProps = {
  machineId: null,
  data: null
};

export default IrisBatchRecordsEditLimits;
