import { useTranslation } from 'react-i18next';
import React from 'react';
import T from 'prop-types';
import { NotifyIcon } from 'web-components';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { StyledAvatar } from '../../components/CustomerCard/elements';
import { StyledAvatarNoUser } from '../../components/GatewayTable/elements';
import NoUsersImg from '../../attrs/images/user_management_no_users.svg';
import { CustomerTable } from '../../components/CustomerTable';
import { STATUS_VALID } from '../../attrs/salesforce';

const CustomerTableSelector = ({ rows }) => {
  const { t } = useTranslation();
  const labelAmend = 'customers.overview';

  const columns = [
    {
      id: 'logo',
      label: t(`${labelAmend}.logo`),
      sortable: false,
      cellRenderer: row =>
        row.logo ? (
          <StyledAvatar alt={row.name} src={row.logo} />
        ) : (
          <StyledAvatarNoUser alt={t('gateways.unassigned')} src={NoUsersImg} />
        )
    },
    { id: 'name', label: t(`${labelAmend}.customer_name`), sortable: true },
    {
      id: 'sap_id',
      label: t(`${labelAmend}.sap_id`),
      sortable: false,
      cellRenderer: row => (
        <Grid container alignItems="center">
          {row.sap_id && row.sap_status && row.sap_status !== STATUS_VALID && (
            <Tooltip key={row.id} title={t(`${labelAmend}.invalid_sap_id`)} placement="top" arrow>
              <Grid item>
                <NotifyIcon
                  fontSize="medium"
                  iconName="checkmarkFilled"
                  viewBox="0 0 30 30"
                  style={{ marginTop: '17px' }}
                  data-testid="warning-icon"
                />
              </Grid>
            </Tooltip>
          )}
          <Grid item>{row.sap_id ? row.sap_id : '---'}</Grid>
        </Grid>
      )
    },
    { id: 'users', label: t(`${labelAmend}.users`), sortable: false },
    { id: 'machines', label: t(`${labelAmend}.machines`), sortable: false }
  ];

  return <CustomerTable columns={columns} rows={rows} />;
};

CustomerTableSelector.propTypes = {
  rows: T.instanceOf(Object).isRequired
};

export { CustomerTableSelector };
