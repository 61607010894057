import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, CardContent, Box } from '@mui/material';

import { NotifyTypo, FormElements } from 'web-components';

import { StyledCard, StyledCardHeader } from '../elements';
import { batchConfiguration } from '../../../../../helpers/propTypes';
import { getDefaultFieldValue } from '../../../../../helpers/utils';

const Details = ({ handleBlur, handleChange, getError, getHelperText, values }) => {
  const { t } = useTranslation();

  return (
    <StyledCard className="withMargins">
      <CardContent>
        <StyledCardHeader>
          <NotifyTypo.Heading2>{t('machines.form.batch_records.details')}</NotifyTypo.Heading2>
        </StyledCardHeader>
        <Container maxWidth="sm">
          <Box m={6} />
          <FormElements.TextField
            fullWidth
            id="batch_id"
            label={t('machines.form.batch_records.batch_id')}
            margin="normal"
            name="configuration.plc.batch.details.batch_id"
            onBlur={handleBlur}
            onChange={handleChange}
            value={getDefaultFieldValue(values.configuration.plc.batch.details.batch_id)}
          />
          <FormElements.TextField
            fullWidth
            id="batch_recipe"
            label={t('machines.form.batch_records.recipe')}
            margin="normal"
            name="configuration.plc.batch.details.recipe"
            onBlur={handleBlur}
            onChange={handleChange}
            value={getDefaultFieldValue(values.configuration.plc.batch.details.recipe)}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.details.max_allowed_temperature')}
            helperText={getHelperText('configuration.plc.batch.details.max_allowed_temperature')}
            fullWidth
            id="configuration.plc.batch.details.max_allowed_temperature"
            label={t('machines.form.batch_records.max_temp')}
            margin="normal"
            name="configuration.plc.batch.details.max_allowed_temperature"
            onBlur={handleBlur}
            onChange={handleChange}
            value={getDefaultFieldValue(values.configuration.plc.batch.details.max_allowed_temperature)}
            required
          />
          <FormElements.TextField
            fullWidth
            id="batch_size"
            label={t('machines.form.batch_records.batch_size')}
            margin="normal"
            name="configuration.plc.batch.details.batch_size"
            onBlur={handleBlur}
            onChange={handleChange}
            value={getDefaultFieldValue(values.configuration.plc.batch.details.batch_size)}
          />
          <FormElements.TextField
            fullWidth
            id="progress"
            label={t('machines.form.batch_records.progress')}
            margin="normal"
            name="configuration.plc.batch.details.progress"
            onBlur={handleBlur}
            onChange={handleChange}
            value={getDefaultFieldValue(values.configuration.plc.batch.details.progress)}
          />
          <FormElements.TextField
            fullWidth
            id="energy_consumption"
            label={t('machines.form.batch_records.energy_consumption')}
            margin="normal"
            name="configuration.plc.batch.details.est_energy_consumption"
            onBlur={handleBlur}
            onChange={handleChange}
            value={getDefaultFieldValue(values.configuration.plc.batch.details.est_energy_consumption)}
          />
          <FormElements.TextField
            fullWidth
            id="current_cycles"
            label={t('machines.form.batch_records.current_cycles')}
            margin="normal"
            name="configuration.plc.batch.details.cycles"
            onBlur={handleBlur}
            onChange={handleChange}
            value={getDefaultFieldValue(values.configuration.plc.batch.details.cycles)}
          />
          <FormElements.TextField
            error={getError('configuration.plc.batch.details.total_grinding_time')}
            helperText={getHelperText('configuration.plc.batch.details.total_grinding_time')}
            fullWidth
            id="configuration.plc.batch.details.total_grinding_time"
            label={t('machines.form.batch_records.total_grinding_time')}
            margin="normal"
            name="configuration.plc.batch.details.total_grinding_time"
            onBlur={handleBlur}
            onChange={handleChange}
            value={getDefaultFieldValue(values.configuration.plc.batch.details.total_grinding_time)}
            required
          />
        </Container>
      </CardContent>
    </StyledCard>
  );
};

Details.propTypes = {
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  values: T.shape({
    configuration: T.shape({
      plc: T.shape({
        batch: batchConfiguration
      })
    })
  }).isRequired
};

export default Details;
