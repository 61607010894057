import styled from 'styled-components';
import { Link } from '@mui/material';
import { COLOR_PRIMARY, COLOR_MAIN_HIGHLIGHT, COLOR_MAIN_HIGHLIGHT_TEXT } from '../../../attrs/colors';

const PageWrapper = styled.div`
  min-height: 100vh;
  padding: 1rem 0;
  background: ${props => (props.inverted ? COLOR_PRIMARY : COLOR_MAIN_HIGHLIGHT)};
  color: ${COLOR_MAIN_HIGHLIGHT_TEXT};
  display: flex;
  align-items: center;
  & div {
    text-align: center;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  flex: 1;
  width: 40%;
`;

const FormContainer = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
`;

const LogoContainer = styled.div`
  magin-bottom: 1.5rem;
  color: ${COLOR_MAIN_HIGHLIGHT_TEXT};
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  margin: 1rem;
`;

export { PageWrapper, LoginContainer, StyledLink, LogoContainer, FormContainer };
