import React from 'react';
import T from 'prop-types';
import { StyledContentWrapper } from '../../../../components/forms/Machines/MachineDetail/elements';
import ConnectedPlcContent from './ConnectedPlcContent';

const Plc = ({ plcType }) => (
  <>
    <StyledContentWrapper elevation={3}>
      <ConnectedPlcContent plcType={plcType} />
    </StyledContentWrapper>
  </>
);

Plc.propTypes = {
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default Plc;
