import { call, put, takeLatest } from 'redux-saga/effects';
import { actionStatus, getError, statusAction } from 'redux/utils';
import {
  createSensor,
  createUpdateSensorIris,
  deleteSensor,
  deleteSensorIris,
  flipSensorIrisStatus,
  loadSensor,
  loadSensorConfigsForIris,
  loadSensorIris,
  loadSensorUnits,
  updateSensor,
  updateSensorUnits
} from '../../api/sensor';
import {
  CREATE_SENSOR,
  CREATE_UPDATE_SENSOR_IRIS,
  DELETE_SENSOR,
  DELETE_SENSOR_IRIS,
  FLIP_SENSOR_IRIS_STATUS,
  LOAD_SENSORS,
  LOAD_SENSORS_CONFIG_FOR_IRIS,
  LOAD_SENSORS_IRIS,
  LOAD_SENSORS_UNITS,
  UPDATE_SENSOR,
  UPDATE_SENSORS_UNITS
} from './constants';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';

function* handleLoadSensor({ id }) {
  yield put(statusAction(LOAD_SENSORS, actionStatus.START));
  try {
    const { data } = yield loadSensor(id);
    yield put(statusAction(LOAD_SENSORS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    yield put(
      statusAction(LOAD_SENSORS, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleCreateSensor({ machineId, payload }) {
  yield put(statusAction(CREATE_SENSOR, actionStatus.START));

  try {
    const response = yield createSensor(machineId, payload);
    yield put(statusAction(CREATE_SENSOR, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    yield put(
      statusAction(CREATE_SENSOR, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleUpdateSensor({ machineId, payload }) {
  yield put(statusAction(UPDATE_SENSOR, actionStatus.START));

  try {
    const response = yield updateSensor(machineId, payload);
    yield put(statusAction(UPDATE_SENSOR, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    yield put(
      statusAction(UPDATE_SENSOR, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

function* handleDeleteSensor({ machineId, sensorType }) {
  yield put(statusAction(DELETE_SENSOR, actionStatus.START));
  try {
    const response = yield deleteSensor(machineId, sensorType);
    yield put(statusAction(DELETE_SENSOR, actionStatus.SUCCESS, { payload: response }));
  } catch (err) {
    yield put(
      statusAction(DELETE_SENSOR, actionStatus.ERROR, {
        message: err
      })
    );
  }
}

/**
 * IRIS Sensors
 */

/**
 * IRIS sensor units
 * @param machineId
 * @return {Generator<*, void, *>}
 */
function* handleLoadSensorUnits({ machineId }) {
  yield put(statusAction(LOAD_SENSORS_UNITS, actionStatus.START));
  try {
    const { data } = yield loadSensorUnits(machineId);
    yield put(statusAction(LOAD_SENSORS_UNITS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(LOAD_SENSORS_UNITS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

/**
 * IRIS sensor predefined configs
 * @return {Generator<*, void, *>}
 */
function* handleLoadSensorConfigsForIris() {
  yield put(statusAction(LOAD_SENSORS_CONFIG_FOR_IRIS, actionStatus.START));
  try {
    const { data } = yield loadSensorConfigsForIris();
    yield put(statusAction(LOAD_SENSORS_CONFIG_FOR_IRIS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(LOAD_SENSORS_CONFIG_FOR_IRIS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleLoadSensorIris({ machineId }) {
  yield put(statusAction(LOAD_SENSORS_IRIS, actionStatus.START));
  try {
    const { data } = yield loadSensorIris(machineId);
    yield put(statusAction(LOAD_SENSORS_IRIS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(LOAD_SENSORS_IRIS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleCreateUpdateSensorIris({ machineId, payload }) {
  yield put(statusAction(CREATE_UPDATE_SENSOR_IRIS, actionStatus.START));
  try {
    const response = yield createUpdateSensorIris(machineId, payload);
    yield put(statusAction(CREATE_UPDATE_SENSOR_IRIS, actionStatus.SUCCESS, { payload: response }));
    yield call(handleLoadSensorIris, { machineId });
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(CREATE_UPDATE_SENSOR_IRIS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleUpdateSensorUnits({ machineId, payload }) {
  yield put(statusAction(UPDATE_SENSORS_UNITS, actionStatus.START));
  try {
    const { data } = yield updateSensorUnits(machineId, payload);
    yield call(handleLoadSensorUnits, { machineId });

    yield put(statusAction(UPDATE_SENSORS_UNITS, actionStatus.SUCCESS, { payload: data }));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'machines.machine_details.management_units_modal.notifications.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(UPDATE_SENSORS_UNITS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: 'machines.machine_details.management_units_modal.notifications.error'
      })
    );
  }
}

function* handleDeleteIrisSensor({ machineId, sensorId }) {
  yield put(statusAction(DELETE_SENSOR_IRIS, actionStatus.START));
  try {
    const response = yield deleteSensorIris(machineId, sensorId);
    yield put(statusAction(DELETE_SENSOR_IRIS, actionStatus.SUCCESS, { payload: response }));
    yield call(handleLoadSensorIris, { machineId });
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(DELETE_SENSOR_IRIS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

function* handleFlipIrisSensorStatus({ machineId, sensorId }) {
  yield put(statusAction(FLIP_SENSOR_IRIS_STATUS, actionStatus.START));
  try {
    const response = yield flipSensorIrisStatus(machineId, sensorId);
    yield put(statusAction(FLIP_SENSOR_IRIS_STATUS, actionStatus.SUCCESS, { payload: response }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(FLIP_SENSOR_IRIS_STATUS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.machines.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(LOAD_SENSORS, handleLoadSensor);
  yield takeLatest(CREATE_SENSOR, handleCreateSensor);
  yield takeLatest(UPDATE_SENSOR, handleUpdateSensor);
  yield takeLatest(DELETE_SENSOR, handleDeleteSensor);

  /**
   * IRIS Sensors
   */
  yield takeLatest(LOAD_SENSORS_UNITS, handleLoadSensorUnits);
  yield takeLatest(UPDATE_SENSORS_UNITS, handleUpdateSensorUnits);
  yield takeLatest(LOAD_SENSORS_CONFIG_FOR_IRIS, handleLoadSensorConfigsForIris);
  yield takeLatest(LOAD_SENSORS_IRIS, handleLoadSensorIris);
  yield takeLatest(CREATE_UPDATE_SENSOR_IRIS, handleCreateUpdateSensorIris);
  yield takeLatest(DELETE_SENSOR_IRIS, handleDeleteIrisSensor);
  yield takeLatest(FLIP_SENSOR_IRIS_STATUS, handleFlipIrisSensorStatus);
}
