import styled from 'styled-components';
import { Box } from '@mui/material';
import { NewDialog } from 'web-components';

const StyledNewDialog = styled(NewDialog)`
  .MuiDialog-paper {
    border-radius: 0.625rem;
    width: 80%;
    max-height: 90%;

    form {
      width: 100%;
    }
  }
  h2:first-of-type {
    padding-bottom: unset;
  }
`;

const StyledHandlers = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

const StyledContentGridColumn = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  width: 100%;
`;

const StyledContentGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  width: 100%;
`;

const StyledGridItem = styled(Box)`
  width: 100%;
`;

export { StyledNewDialog, StyledHandlers, StyledContent, StyledContentGrid, StyledContentGridColumn, StyledGridItem };
