import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';
import { loadingStateReducer } from 'redux/utils';
import {
  USERS_V2_GET_USERS_PAGINATED,
  USERS_V2_GET_USERS_INVITATIONS_PAGINATED,
  USERS_V2_INVITE_USER,
  USERS_V2_UPDATE_USER_ROLES
} from './constants';

export default combineReducers({
  inviteUser: typeToReducer(
    {
      [USERS_V2_INVITE_USER]: {
        SUCCESS: (_data, { response }) => response
      }
    },
    []
  ),
  usersPaginated: typeToReducer(
    {
      [USERS_V2_GET_USERS_PAGINATED]: {
        SUCCESS: (_data, { response }) => response
      }
    },
    {
      data: [],
      limit: 5,
      total_length: 0,
      total_pages: 0,
      page: 1
    }
  ),
  usersInvitationsPaginated: typeToReducer(
    {
      [USERS_V2_GET_USERS_INVITATIONS_PAGINATED]: {
        SUCCESS: (_data, { response }) => response
      }
    },
    {
      data: [],
      limit: 5,
      total_length: 0,
      total_pages: 0,
      page: 1
    }
  ),
  updateUserRole: typeToReducer(
    {
      [USERS_V2_UPDATE_USER_ROLES]: {
        SUCCESS: (_data, { response }) => response
      }
    },
    {
      user_id: '',
      updated_by_user_id: '',
      roles: []
    }
  ),
  invitingUserStatus: loadingStateReducer(USERS_V2_INVITE_USER),
  updatingUserStatus: loadingStateReducer(USERS_V2_UPDATE_USER_ROLES),
  usersPaginatedStatus: loadingStateReducer(USERS_V2_GET_USERS_PAGINATED),
  usersInvitationsPaginatedStatus: loadingStateReducer(USERS_V2_GET_USERS_INVITATIONS_PAGINATED)
});
