import { Box } from '@mui/material';
import { COLOR_PRIMARY } from 'attrs/colors';
import T from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, FormElements } from 'web-components';

import { MACHINE_DEFAULT_LIST_ORDER, MACHINE_DEFAULT_LIST_SORT } from '../../../attrs/machines';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../../attrs/pagination';
import { getMachineType } from '../../../helpers/utils';
import { getMachinesCloneableFiltered, loadMachinesCloneableByResume } from '../../../redux/cloneableMachines/actions';
import { getMachineSortOrder } from '../../../redux/machines/selectors';
import Footer from '../../DataTable/Footer';
import { SelectDataItem } from '../MachineTable.styled';
import { MachineFilter } from '../MachineFilter';

const CloneMachineTable = ({ columns, rows, organizationId, selectedMachineToClone, setSelectedMachineToClone }) => {
  const dispatch = useDispatch();
  const machineSortOrder = useSelector(getMachineSortOrder);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(PAGINATION_DEFAULT_START_PAGE);
  const [currentView, setCurrentView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState({
    colId: machineSortOrder.sort || MACHINE_DEFAULT_LIST_SORT,
    order: machineSortOrder.name || MACHINE_DEFAULT_LIST_ORDER
  });
  const [clear, setClear] = useState(false);

  const [filterCommissionNumber, setFilterCommissionNumber] = useState('');
  const [filterMachineType, setFilterMachineType] = useState('');
  const [filterPlcType, setFilterPlcType] = useState('');
  const [filterCustomerName, setFilterCustomerName] = useState('');

  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;

  const handleSelectRow = useCallback(
    row => {
      setSelectedMachineToClone(row);
    },
    [setSelectedMachineToClone]
  );

  const handleSort = (colId, order) => {
    setIsLoading(true);
    dispatch(getMachinesCloneableFiltered(currentPage, rowsPerPage, order.toUpperCase(), colId, organizationId));
    setCurrentSort({ colId, order });
  };

  const handleChangePage = page => {
    setCurrentPage(page);
    dispatch(
      loadMachinesCloneableByResume(
        organizationId,
        filterCommissionNumber,
        filterMachineType,
        filterPlcType,
        filterCustomerName,
        rowsPerPage,
        page,
        currentSort.order,
        currentSort.colId
      )
    );
  };

  const setInitStates = () => {
    setCurrentPage(PAGINATION_DEFAULT_START_PAGE);
    setCurrentSort({
      colId: machineSortOrder.sort || MACHINE_DEFAULT_LIST_SORT,
      order: machineSortOrder.name || MACHINE_DEFAULT_LIST_ORDER
    });
  };

  const props = {
    dataSelectorTable: 'machines-table',
    dataSelectorRows: 'machines-table-rows',
    isLoading,
    columns,
    rows: currentView,
    typeBorder: 'none',
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={rows.total_pages || 1}
        totalLength={rows.total_length || 0}
        totalItemsPerPage={rows.limit || 0}
        onChange={handleChangePage}
      />
    ),
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  useEffect(() => {
    setIsLoading(false);
    if (rows && rows.machines) {
      setCurrentView(
        DataTable.sortFn(
          rows.machines.map(row => {
            const machineType = getMachineType(row.type);

            return {
              ...row,
              brand: machineType.brand,
              type: row.machine_type ? row.machine_type : t(''),
              commission_number: (
                <SelectDataItem>
                  <FormElements.RadioButton
                    margin="normal"
                    name="is_machine_to_clone"
                    activeColor={COLOR_PRIMARY}
                    onChange={() => handleSelectRow(row)}
                    checked={selectedMachineToClone?.id === row.id}
                    disabled={false}
                    id="is_machine_to_clone"
                  />
                  {row.commission_number ? row.commission_number : '---'}
                </SelectDataItem>
              ),
              organization_name: row.customer_name ? row.customer_name : t('machines.overview.not_assigned'),
              gateway_id: row.gateway_id ? row.gateway_id : t('machines.overview.not_assigned'),
              name: row.name ? row.name : t('machines.overview.machine_name_not_assigned'),
              production_line: row.production_line
                ? row.production_line
                : t('machines.overview.production_line_name_not_assigned')
            };
          }),
          currentSort.colId,
          currentSort.order.toUpperCase(),
          organizationId
        )
      );
    } else {
      setCurrentView([]);
    }
  }, [organizationId, rows, selectedMachineToClone, t, currentSort.colId, currentSort.order, handleSelectRow]);

  const handleChangeFilter = (page, commissionNumber, machineType, plcType, customerName) => {
    setCurrentPage(page);
    dispatch(
      loadMachinesCloneableByResume(
        organizationId,
        commissionNumber,
        machineType,
        plcType,
        customerName,
        rowsPerPage,
        page,
        currentSort.order,
        currentSort.colId
      )
    );
  };

  const handleFilterCommissionNumber = commissionNumber => {
    if (commissionNumber !== filterCommissionNumber) {
      setIsLoading(true);
      setFilterCommissionNumber(commissionNumber);
      handleChangeFilter(
        PAGINATION_DEFAULT_START_PAGE,
        commissionNumber,
        filterMachineType,
        filterPlcType,
        filterCustomerName
      );
    }
  };

  const handleFilterMachineType = machineType => {
    if (machineType !== filterMachineType) {
      setIsLoading(true);
      setFilterMachineType(machineType);
      handleChangeFilter(
        PAGINATION_DEFAULT_START_PAGE,
        filterCommissionNumber,
        machineType,
        filterPlcType,
        filterCustomerName
      );
    }
  };

  const handleFilterPlcType = plcType => {
    if (plcType !== filterPlcType) {
      setIsLoading(true);
      setFilterPlcType(plcType);
      handleChangeFilter(
        PAGINATION_DEFAULT_START_PAGE,
        filterCommissionNumber,
        filterMachineType,
        plcType,
        filterCustomerName
      );
    }
  };

  const handleFilterCustomerName = customerName => {
    if (customerName !== filterCustomerName) {
      setIsLoading(true);
      setFilterCustomerName(customerName);
      handleChangeFilter(
        PAGINATION_DEFAULT_START_PAGE,
        filterCommissionNumber,
        filterMachineType,
        filterPlcType,
        customerName
      );
    }
  };

  // TODO: if someone pass here in the future, replace this by PlcProtocols
  const configuredObject = [
    { label: 'general.all', value: '' },
    { label: 'S7 Connection', value: 'SIEMENS_S7' },
    { label: 'Rockwell', value: 'ROCKWELL' }
  ];

  return (
    <Box>
      <Box sx={{ flexWrap: 'wrap', py: 4 }}>
        <MachineFilter
          isCheckbox={false}
          isClonable
          clear={clear}
          optionText={filterCommissionNumber}
          setInitStates={setInitStates}
          handleFilter={handleFilterCommissionNumber}
          labelFilter={t('machines.overview.clone_modal.filter.commission_number')}
          setClear={setClear}
        />
        <MachineFilter
          isCheckbox={false}
          isClonable
          clear={clear}
          optionText={filterMachineType}
          setInitStates={setInitStates}
          handleFilter={handleFilterMachineType}
          labelFilter={t('machines.overview.clone_modal.filter.machine_type')}
          setClear={setClear}
        />
        <MachineFilter
          isCheckbox={false}
          isSearchIcon
          clear={clear}
          optionText={filterPlcType}
          setInitStates={setInitStates}
          handleFilter={handleFilterPlcType}
          labelFilter={t('machines.overview.clone_modal.filter.plc_type')}
          optionsObj={configuredObject}
          setClear={setClear}
        />
        <MachineFilter
          isCheckbox={false}
          isClonable
          clear={clear}
          optionText={filterCustomerName}
          setInitStates={setInitStates}
          handleFilter={handleFilterCustomerName}
          labelFilter={t('machines.overview.clone_modal.filter.costumer')}
          setClear={setClear}
        />
      </Box>
      <DataTable hover {...props} />
    </Box>
  );
};

CloneMachineTable.propTypes = {
  rows: T.instanceOf(Object).isRequired,
  organizationId: T.string,
  columns: T.instanceOf(Array).isRequired,
  selectedMachineToClone: T.shape({
    id: T.string
  }).isRequired,
  setSelectedMachineToClone: T.func.isRequired
};

CloneMachineTable.defaultProps = {
  organizationId: null
};

export default CloneMachineTable;
