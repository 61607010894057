import { Box, Container, InputAdornment, TableRow } from '@mui/material';
import styled, { css } from 'styled-components';
import { NewDialog, NotifyIcon } from 'web-components';
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
  TEXTAREA_BACKGROUND
} from '../../../attrs/colors';

const StyledContainer = styled(Container)`
  .MuiFormHelperText-root {
    font-size: 0.75rem;
  }
`;

const StyledContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

const ImageWrapper = styled.div`
  margin: 0.5rem 0 2.25rem 0;
  background: ${TEXTAREA_BACKGROUND};
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: space-between;
  & > div {
    display: flex;
    overflow: hidden;
    word-break: break-word;
    & > :first-child {
      margin-right: 0.8rem;
    }
  }
`;

const EmptyImageWrapper = styled.div`
  margin: 0.5rem 0 2rem 0;
  padding: 0.5rem;
  width: 100px;
  height: 0;
`;

const StyledInputAdornment = styled(InputAdornment)`
  color: ${COLOR_PRIMARY};
  ${props =>
    props.error &&
    css`
      color: ${COLOR_SECONDARY};
      cursor: pointer;
      &:hover {
        color: ${PRIMARY_TEXT_COLOR};
      }
    `}
`;

const MandatoryLabel = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${SECONDARY_TEXT_COLOR};
  padding: 2rem 0 2.5rem;

  ${({ isEdit }) =>
    isEdit &&
    `
      visibility: hidden;
  `}

  ${({ isMobile }) =>
    isMobile &&
    `
      padding: 3rem 0 0;
  `}
`;

const StyledSwitch = {
  Wrapper: styled.div`
    margin-top: 1.75rem;
  `,

  Row: styled.div`
    display: flex;
    margin-top: 2.5rem;

    & > :first-child {
      margin-right: 5rem;
    }

    ${({ isMobile }) =>
      isMobile &&
      `
      margin-top: 1.25rem;
    `}
  `,

  StatusTitle: styled.div`
    display: flex;
    min-width: 120px !important;
    flex-direction: column;
  `,

  ToggleWrapper: styled.div`
    margin-top: -0.5rem;
  `,

  Label: styled.span`
    font-size: 0.625rem;
    margin-top: -0.25rem;
    color: ${SECONDARY_TEXT_COLOR};
  `
};

const IconStyled = styled(NotifyIcon)`
  .MuiSvgIcon-fontSizeLarge {
    width: 35px;
    height: 35px;
  }
`;

const CustomerOverviewContent = styled(Box)`
  display: flex;
  padding: 3.25rem 0 0;
  flex-wrap: wrap;
  gap: 1.25rem;
`;

const OverviewColumn = styled(Box)`
  display: flex;
  flex: 1;
  gap: 1.25rem;
  flex-direction: column;
`;

const OverviewRow = styled(Box)`
  display: flex;
  flex: 1;
  gap: 3px;
  flex-direction: column;
  min-width: 140px;
`;

const SapId = styled(Box)`
  display: flex;
  gap: 0.5rem;
`;

const StyledHandlers = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledNewDialog = styled(NewDialog)`
  .MuiDialog-paper {
    border-radius: 0.625rem;
    width: 100%;

    form {
      width: 100%;
    }
  }
  h2:first-of-type {
    padding-bottom: unset;
  }
`;

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(239, 239, 239, 1)'
  },
  '&:last-child td, &:last-child th': {
    border: 0,
    padding: '20px 15px'
  }
}));

const StyledContentGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  width: 100%;
`;

const StyledGridItem = styled(Box)`
  width: 100%;
`;

export {
  CustomerOverviewContent,
  EmptyImageWrapper,
  IconStyled,
  ImageWrapper,
  MandatoryLabel,
  OverviewColumn,
  OverviewRow,
  SapId,
  StyledContainer,
  StyledContent,
  StyledContentGrid,
  StyledGridItem,
  StyledHandlers,
  StyledInputAdornment,
  StyledNewDialog,
  StyledSwitch,
  StyledTableRow
};
