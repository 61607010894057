import { ADD_NOTIFICATION, RESOLVE_NOTIFICATION } from './constants';

/**
 * Create and add a notification for resolveNotification at Notification.js in App.js
 * @param {{
 *   key: string,
 *   type: 'INITIAL'|'START'|'IN_PROGRESS'|'SUCCESS'|'ERROR',
 *   message: string,
 *   parameters: Object.<string, number|string>|undefined
 * }} notification
 * @returns {{notification, type: string}}
 */
const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  notification
});

const resolveNotification = key => ({
  type: RESOLVE_NOTIFICATION,
  key
});

export { addNotification, resolveNotification };
