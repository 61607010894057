import axios from './axios';

const getOrganizations = () =>
  axios({
    method: 'get',
    url: '/v1/organizations'
  });

const getOrganization = id =>
  axios({
    method: 'get',
    url: `/v1/organizations/${id}`
  });

const getOrganizationsByResume = (search, limit, page, order, sort) =>
  axios({
    method: 'get',
    url: '/v1/organizations/resume',
    params: {
      search,
      limit,
      page,
      order,
      sort
    }
  });

const updateOrganization = (id, values) =>
  axios({
    method: 'put',
    url: `/v1/organizations/${id}`,
    data: values
  });

const createOrganization = values =>
  axios({
    method: 'post',
    url: '/v1/organizations',
    data: values
  });

const verifySapId = sapAccount =>
  axios({
    method: 'post',
    url: '/v1/salesforce/verify',
    data: { sap_account: sapAccount }
  });

const getOrganizationProductsAccess = organizationId =>
  axios({
    method: 'get',
    url: `/v1/organizations/${organizationId}/products-access`
  });

/** Endpoints V2 */

const getOrganizationMachinesList = (organizationId, limit, page, sort, order) =>
  axios({
    method: 'get',
    url: `/v2/organization/${organizationId}/machines/list`,
    params: {
      limit,
      page,
      sort,
      order
    }
  });

const getOrganizationMachinesListSearch = (organizationId, params) =>
  axios({
    method: 'get',
    url: `/v2/organization/${organizationId}/machines/list`,
    params
  });

export {
  createOrganization,
  getOrganization,
  getOrganizationMachinesList,
  getOrganizationMachinesListSearch,
  getOrganizationProductsAccess,
  getOrganizations,
  getOrganizationsByResume,
  updateOrganization,
  verifySapId
};
