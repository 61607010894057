import * as Yup from 'yup';
import { isNullUndefinedOrEmpty } from '../helpers/utils';
import { LICENSE_NOTIFY_PREVENTIVE, LICENSE_NOTIFY_PRODUCT } from './products';
import { PlcType } from './plcConfigValues';
import { ENGINEERING_DATA_TYPES_IRIS_V3 } from './sensorConfig';

export const passwordMinLength = 8;
export const passwordMaxLength = 50;
export const passwordNumberAndSymbol = /(?=.*?[#?!@$%^&*-])(?=.*?[0-9])/;
export const passwordUpperAndLowerCase = /(?=.*?[A-Z])(?=.*?[a-z])/;

const testForImage = (url, timeoutVal) =>
  new Promise(res => {
    const timeout = timeoutVal || 5000;
    let timedOut = false;
    let timer;
    const img = new Image();
    if (url) {
      img.onerror = () => {
        if (!timedOut) {
          clearTimeout(timer);
          res('error');
        }
      };

      img.onabort = () => {
        if (!timedOut) {
          clearTimeout(timer);
          res('error');
        }
      };

      img.onload = () => {
        if (!timedOut) {
          clearTimeout(timer);
          res('success');
        }
      };

      img.src = url;

      timer = setTimeout(() => {
        timedOut = true;
        // reset .src to invalid URL so it stops previous
        // loading, but doesn't trigger new load
        img.src = '//!!!!/test.jpg';
        res('timeout');
      }, timeout);
    } else {
      // return valid if url is empty
      res('success');
    }
  });

function ipv4Validator(message = 'invalid_ipv4') {
  return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
    message,
    excludeEmptyString: true
  }).test('ip', message, value => {
    const isEmptyValue = value === undefined || value.trim() === '';
    return isEmptyValue ? true : value.split('.').find(i => parseInt(i, 10) > 255) === undefined;
  });
}

// not used
function plcRegisterAddressValidator(message) {
  return this.matches(/(^DB[0-9]{3}\.DB[XWD]{1}[0-9]{4}(.9)?$)/, {
    message,
    excludeEmptyString: true
  }).test('plcRegisterAddress', message, value => {
    const isEmptyValue = value === undefined || value.trim() === '';
    return isEmptyValue ? true : value;
  });
}

function dataTypeValueValidator(message) {
  return this.matches(/^-?[0-9]{1,4}$/, {
    message,
    excludeEmptyString: true
  }).test('dataTypeValue', message, value => {
    const isEmptyValue = value === undefined || value.trim() === '';
    return isEmptyValue ? true : value;
  });
}

function stringBooleanValidator(message) {
  const validValues = ['true', 'false'];
  return this.test('stringBoolean', message, value => {
    const isEmptyValue = value === undefined || value.trim() === '';

    return isEmptyValue ? true : validValues.includes(value);
  });
}

Yup.addMethod(Yup.string, 'ipv4', ipv4Validator);
Yup.addMethod(Yup.string, 'plcRegisterAddress', plcRegisterAddressValidator);
Yup.addMethod(Yup.string, 'dataTypeValue', dataTypeValueValidator);
Yup.addMethod(Yup.string, 'stringBoolean', stringBooleanValidator);

const RequestPasswordResetSchema = Yup.object().shape({
  login: Yup.string().email('invalid_email').required('required')
});

const AccountUpdateSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('required'),
  last_name: Yup.string().trim().required('required'),
  phone: Yup.object().shape({
    number: Yup.number().typeError('number_required').required('required')
  })
});

const AccountRegisterSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('required'),
  last_name: Yup.string().trim().required('required'),
  phone: Yup.object().shape({
    number: Yup.number().typeError('number_required').required('required')
  }),
  password: Yup.string()
    .defined()
    .min(passwordMinLength, 'too_short')
    .max(passwordMaxLength, 'too_long')
    .matches(passwordUpperAndLowerCase, 'password_symbol_required')
    .matches(passwordNumberAndSymbol, 'password_number_required')
    .required('required'),
  confirm_password: Yup.string()
    .defined()
    .oneOf([Yup.ref('password'), null], 'passwords_match')
    .required('required'),
  terms_accept: Yup.bool().oneOf([true], 'terms_need_to_be_accepted')
});

// Password requirements: Min 6 letters, 1 number
const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .defined()
    .min(passwordMinLength, 'too_short')
    .max(passwordMaxLength, 'too_long')
    .matches(passwordUpperAndLowerCase, 'password_symbol_required')
    .matches(passwordNumberAndSymbol, 'password_number_required')
    .required('required'),
  confirm_password: Yup.string()
    .defined()
    .oneOf([Yup.ref('password'), null], 'passwords_match')
    .required('required'),
  prev_password: Yup.string().required('required')
});

const ResetPasswordNonAuthSchema = Yup.object().shape({
  password: Yup.string()
    .defined()
    .min(passwordMinLength, 'too_short')
    .max(passwordMaxLength, 'too_long')
    .matches(passwordUpperAndLowerCase, 'password_symbol_required')
    .matches(passwordNumberAndSymbol, 'password_number_required')
    .required('required'),
  confirm_password: Yup.string()
    .defined()
    .oneOf([Yup.ref('password'), null], 'passwords_match')
    .required('required')
});

const LoginSchema = Yup.object().shape({
  login: Yup.string().email('invalid_email').required('required'),
  password: Yup.string().required('required')
});

const UserInviteSchema = licenses =>
  Yup.object().shape({
    email: Yup.string().email('invalid_email').required('required'),
    role_notify: Yup.string().when([], {
      is: () =>
        (licenses ?? []).filter(
          license => license.product === LICENSE_NOTIFY_PRODUCT && new Date(license.expiration_date) > Date.now()
        ).length > 0,
      then: Yup.string().oneOf(['manager', 'operator', 'no_access']).required('required'),
      otherwise: Yup.string().nullable()
    }),
    role_preventive: Yup.string().when([], {
      is: () =>
        (licenses ?? []).filter(
          license => license.product === LICENSE_NOTIFY_PREVENTIVE && new Date(license.expiration_date) > Date.now()
        ).length > 0,
      then: Yup.string().oneOf(['technician', 'coordinator', 'no_access']).required('required'),
      otherwise: Yup.string().nullable()
    })
  });

const CustomerCreateSchema = Yup.object().shape({
  name: Yup.string().trim().required('required'),
  sap_account: Yup.string().max(15, 'too_long_15'),
  email: Yup.string().email('invalid_email').required('required'),
  phone: Yup.object().shape({
    number: Yup.string().typeError('number_required').required('required')
  }),
  logo: Yup.string().test('valid-image', 'must_be_valid_image', value =>
    testForImage(value, 1000).then(status => status === 'success')
  )
});

const integerRangeRequired = (min, max, errorKey) =>
  Yup.number().integer(errorKey).min(min, errorKey).max(max, errorKey).required('required').nullable();

const StaticSensorDataSchema = Yup.object().shape({
  is_static: Yup.bool(),
  is_custom: Yup.bool(),
  name: Yup.string().required('required'),
  custom_period: Yup.string().required('required'),
  custom_unit: Yup.string().required('required'),
  custom_icon_type: Yup.string().required('required'),
  multiplication_factor: Yup.number().when('is_number', {
    is: type => type === 'NUMERIC',
    then: Yup.number()
      .min(0.000001, 'between_0000001_100000')
      .max(100000, 'between_0000001_100000')
      .test('maxDigitsAfterDecimal', 'between_0000001_100000', number => /^\d+(\.\d{0,6})?$/.test(`${number}`))
      .required('required')
      .nullable(),
    otherwise: Yup.number().notRequired().nullable()
  }),
  decimal_place: Yup.number().when('value_type', {
    is: type => type === 'NUMERIC',
    then: Yup.number().required('required'),
    otherwise: Yup.number().notRequired()
  }),
  low_trigger: Yup.bool(),
  high_trigger: Yup.bool(),
  value_type: Yup.string().trim().required('required'),
  units: Yup.string().trim(),
  period: Yup.string().trim(),
  source: Yup.string().trim().required('required'),
  static_sensor_id: Yup.number(),
  type: Yup.string()
});

const SensorDataSchema = Yup.object().shape({
  is_custom: Yup.bool(),
  is_static: Yup.bool(),
  type: Yup.string().when(['is_custom', 'is_static'], {
    is: (isCustom, isStatic) => !isCustom && !isStatic,
    then: Yup.string().required('required'),
    otherwise: Yup.string().notRequired()
  }),
  name: Yup.string().when('is_custom', {
    is: true,
    then: Yup.string().required('required'),
    otherwise: Yup.string().notRequired()
  }),
  custom_period: Yup.string().when('is_custom', {
    is: true,
    then: Yup.string().required('required'),
    otherwise: Yup.string().notRequired()
  }),
  custom_unit: Yup.string().when('is_custom', {
    is: true,
    then: Yup.string().required('required'),
    otherwise: Yup.string().notRequired()
  }),
  custom_icon_type: Yup.string().when('is_custom', {
    is: true,
    then: Yup.string().required('required'),
    otherwise: Yup.string().notRequired()
  }),
  multiplication_factor: Yup.number().when('is_custom', {
    is: true,
    then: Yup.number()
      .min(0.000001, 'between_0000001_100000')
      .max(100000, 'between_0000001_100000')
      .test('maxDigitsAfterDecimal', 'between_0000001_100000', number => /^\d+(\.\d{0,6})?$/.test(`${number}`))
      .required('required')
      .nullable(),
    otherwise: Yup.number().notRequired().nullable()
  }),
  decimal_place: Yup.number().when('is_custom', {
    is: true,
    then: Yup.number().required('required'),
    otherwise: Yup.number().notRequired()
  }),
  low_trigger: Yup.bool(),
  high_trigger: Yup.bool(),
  y_axis: Yup.object().when('is_custom', {
    is: true,
    then: Yup.object().shape(
      {
        min: Yup.object().when('max', max => {
          const maxValue = (max || {}).value;
          const maxSpecified = (max || {}).specified;

          return Yup.object().shape({
            specified: Yup.bool(),
            value: Yup.number().when('specified', {
              is: true,
              then:
                !isNullUndefinedOrEmpty(maxSpecified) && !isNullUndefinedOrEmpty(maxValue)
                  ? integerRangeRequired(-100000, 100000, 'between_-100000_100000').lessThan(
                      maxValue,
                      'invalid_min_y_axis'
                    )
                  : integerRangeRequired(-100000, 100000, 'between_-100000_100000'),
              otherwise: Yup.number().notRequired().nullable()
            })
          });
        }),
        max: Yup.object().when('min', min => {
          const minValue = (min || {}).value;
          const minSpecified = (min || {}).specified;

          return Yup.object().shape({
            specified: Yup.bool(),
            value: Yup.number().when('specified', {
              is: true,
              then:
                !isNullUndefinedOrEmpty(minSpecified) && !isNullUndefinedOrEmpty(minValue)
                  ? integerRangeRequired(-100000, 100000, 'between_-100000_100000').moreThan(
                      minValue,
                      'invalid_max_y_axis'
                    )
                  : integerRangeRequired(-100000, 100000, 'between_-100000_100000'),
              otherwise: Yup.number().notRequired().nullable()
            })
          });
        })
      },
      [['min', 'max']]
    ),
    otherwise: Yup.object().notRequired()
  }),
  source: Yup.string().trim().required('required'),
  range: Yup.object().shape(
    {
      min: Yup.object().when('max', max => {
        const maxValue = (max || {}).value;

        return Yup.object().shape({
          value: !isNullUndefinedOrEmpty(maxValue)
            ? Yup.number().lessThan(maxValue, 'invalid_min_engineering_unit')
            : Yup.number()
        });
      }),
      max: Yup.object().when('min', min => {
        const minValue = (min || {}).value;

        return Yup.object().shape({
          value: !isNullUndefinedOrEmpty(minValue)
            ? Yup.number().moreThan(minValue, 'invalid_max_engineering_unit')
            : Yup.number()
        });
      })
    },
    [['min', 'max']]
  ),
  units: Yup.string().trim(),
  period: Yup.string().trim()
});

const ErrorCodesSchema = Yup.object().shape({
  source: Yup.string().trim().required('required'),
  is_custom: Yup.bool().required('required'),
  type: Yup.string().when('is_custom', {
    is: isCustom => isCustom,
    then: Yup.string().trim().notRequired(),
    otherwise: Yup.string().required('required')
  }),
  custom_type: Yup.string().when(['is_custom'], {
    is: isCustom => isCustom,
    then: Yup.string().required('required'),
    otherwise: Yup.string().trim().notRequired()
  }),
  custom_message: Yup.string().when('is_custom', {
    is: isCustom => isCustom,
    then: Yup.string().required('required'),
    otherwise: Yup.string().trim().notRequired()
  }),
  custom_int_val: Yup.number().when(['is_custom', 'custom_type'], {
    is: (isCustom, customType) => isCustom && customType === 'INT',
    then: Yup.number().required('required'),
    otherwise: Yup.number().notRequired().nullable()
  }),
  custom_bool_val: Yup.bool().when(['is_custom', 'custom_type'], {
    is: (isCustom, customType) => isCustom && customType === 'BOOL',
    then: Yup.bool().required('required'),
    otherwise: Yup.bool().notRequired().nullable()
  })
});

const StatusTypeSchemaReq = Yup.object().when('datatype', {
  is: 'INTEGER',
  then: Yup.object().shape({
    address: Yup.string().trim().required('required'),
    value: Yup.string().dataTypeValue('invalid_data_type_value').required('required')
  }),
  otherwise: Yup.object().shape({
    address: Yup.string().trim().required('required'),
    value: Yup.string().stringBoolean('invalid_boolean_value').required('required')
  })
});

const StatusTypeSchemaNonReq = Yup.object().when('datatype', {
  is: 'INTEGER',
  then: Yup.object().shape(
    {
      address: Yup.string()
        .notRequired()
        .when('value', {
          is: val => val,
          then: Yup.string().trim().required('required'),
          otherwise: Yup.string().trim().notRequired()
        }),
      value: Yup.string()
        .notRequired()
        .when('address', {
          is: val => val,
          then: Yup.string().dataTypeValue('invalid_data_type_value').required('required'),
          otherwise: Yup.string().dataTypeValue('invalid_data_type_value').notRequired()
        })
    },
    ['value', 'address']
  ),
  otherwise: Yup.object().shape(
    {
      address: Yup.string()
        .notRequired()
        .when('value', {
          is: val => val,
          then: Yup.string().trim().required('required'),
          otherwise: Yup.string().trim().notRequired()
        }),
      value: Yup.string()
        .notRequired()
        .when('address', {
          is: val => val,
          then: Yup.string().stringBoolean('invalid_boolean_value').required('required'),
          otherwise: Yup.string().stringBoolean('invalid_boolean_value').notRequired()
        })
    },
    ['value', 'address']
  )
});

const MachineStatusSchema = Yup.object().shape({
  datatype: Yup.string().required('required'),
  running: StatusTypeSchemaReq,
  warning: StatusTypeSchemaNonReq,
  critical: StatusTypeSchemaNonReq,
  maintenance: StatusTypeSchemaNonReq,
  idle: StatusTypeSchemaReq
});

const BatchDetailsSchema = Yup.object().shape({
  batch_id: Yup.string().notRequired(),
  batch_size: Yup.string().notRequired(),
  cycles: Yup.string().notRequired(),
  est_energy_consumption: Yup.string().notRequired(),
  max_allowed_temperature: Yup.string().required('required'),
  progress: Yup.string().notRequired(),
  recipe: Yup.string().notRequired(),
  total_grinding_time: Yup.string().required('required')
});

const BatchStatusDataSchemaReq = Yup.object().shape({
  address: Yup.string().required('required'),
  value: Yup.string().required('required')
});

const BatchStatusDataSchemaNonReq = Yup.object().shape(
  {
    address: Yup.string()
      .notRequired()
      .when('value', {
        is: val => val,
        then: Yup.string().trim().required('required'),
        otherwise: Yup.string().trim().notRequired()
      }),
    value: Yup.string()
      .notRequired()
      .when('address', {
        is: val => val,
        then: Yup.string().trim().required('required'),
        otherwise: Yup.string().trim().notRequired()
      })
  },
  ['value', 'address']
);

const BatchStatusSchema = Yup.object().shape({
  cancelled: BatchStatusDataSchemaNonReq,
  complete: BatchStatusDataSchemaNonReq,
  in_operation: BatchStatusDataSchemaReq
});

const BatchTargetDataSchema = Yup.object().shape(
  {
    target: Yup.string()
      .notRequired()
      .when('value', {
        is: val => val,
        then: Yup.string().trim().required('required'),
        otherwise: Yup.string().trim().notRequired()
      }),
    value: Yup.number()
      .notRequired()
      .when('target', {
        is: val => val,
        then: Yup.number().required('required'),
        otherwise: Yup.number().notRequired()
      })
  },
  ['value', 'target']
);

const BatchTargetTimeSchema = Yup.object().shape(
  {
    value: Yup.number()
      .notRequired()
      .when(['hours', 'minutes'], {
        is: (hours, minutes) => !isNullUndefinedOrEmpty(hours) || !isNullUndefinedOrEmpty(minutes),
        then: Yup.number().required('required'),
        otherwise: Yup.number().notRequired()
      }),
    hours: Yup.string()
      .notRequired()
      .when(['minutes', 'value'], {
        is: (target, minutes) => !isNullUndefinedOrEmpty(target) || !isNullUndefinedOrEmpty(minutes),
        then: Yup.string().trim().required('required'),
        otherwise: Yup.string().trim().notRequired()
      }),
    minutes: Yup.string()
      .notRequired()
      .when(['hours', 'value'], {
        is: (hours, target) => !isNullUndefinedOrEmpty(hours) || !isNullUndefinedOrEmpty(target),
        then: Yup.string().trim().required('required'),
        otherwise: Yup.string().trim().notRequired()
      })
  },
  [
    ['hours', 'minutes'],
    ['minutes', 'value'],
    ['hours', 'value']
  ]
);

const BatchTargetSchema = Yup.object().shape({
  cycles: BatchTargetDataSchema,
  grinding_energy: BatchTargetDataSchema,
  specific_energy: BatchTargetDataSchema,
  time: BatchTargetTimeSchema,
  type: Yup.string()
    .notRequired()
    .when(['cycles', 'grinding_energy', 'specific_energy', 'time'], {
      is: (cycles, grindingEnergy, specificEnergy, time) =>
        !isNullUndefinedOrEmpty(cycles.value) ||
        !isNullUndefinedOrEmpty(cycles.target) ||
        !isNullUndefinedOrEmpty(grindingEnergy.value) ||
        !isNullUndefinedOrEmpty(grindingEnergy.target) ||
        !isNullUndefinedOrEmpty(specificEnergy.value) ||
        !isNullUndefinedOrEmpty(specificEnergy.target) ||
        !isNullUndefinedOrEmpty(time.value) ||
        !isNullUndefinedOrEmpty(time.hours) ||
        !isNullUndefinedOrEmpty(time.minutes),
      then: Yup.string().trim().required('required'),
      otherwise: Yup.string().trim().notRequired()
    })
});

const BatchSchema = Yup.object().shape({
  details: BatchDetailsSchema,
  status: BatchStatusSchema,
  target: BatchTargetSchema
});

const PlcConfigurationSchema = Yup.object().shape({
  type: Yup.string().required('required'),
  address: Yup.string().trim().ipv4().required('required'),
  port: Yup.number()
    .integer()
    .min(0, 'between_0_65535')
    .max(65535, 'between_0_65535')
    .when('type', {
      is: type => type === PlcType.ROCKWELL,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required('required')
    }),
  rack: Yup.string().when('type', {
    is: type => type === PlcType.ROCKWELL,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('required')
  }),
  slot: Yup.string().required('required'),
  errors: Yup.array().of(ErrorCodesSchema),
  metrics: Yup.array().of(SensorDataSchema),
  statuses: MachineStatusSchema
});

const PlcConfigurationWithBatchSchema = PlcConfigurationSchema.shape({
  batch: BatchSchema
});

const GatewayDesiredVersion = Yup.object().shape({
  desired_version: Yup.string().required('required')
});

const MachineSchema = Yup.object().shape({
  type: Yup.string().required('required'),
  commission_number: Yup.string().trim().max(15, 'too_long_15').required('required'),
  configuration: Yup.object().when('enabled_config', {
    is: enabledConfig => enabledConfig.batch,
    then: Yup.object().shape({
      plc: PlcConfigurationWithBatchSchema
    }),
    otherwise: Yup.object().shape({
      plc: PlcConfigurationSchema
    })
  }),
  mechanical_config: Yup.object().shape({
    bead_material: Yup.string(),
    beads_filling_level: Yup.number(),
    beads_size_1: Yup.number().test(
      'maxDigitsAfterDecimal',
      'only_two_decimal',
      number => isNullUndefinedOrEmpty(number) || /^\d+(\.\d{0,2})?$/.test(`${number}`)
    ),
    beads_size_2: Yup.number().test(
      'maxDigitsAfterDecimal',
      'only_two_decimal',
      number => isNullUndefinedOrEmpty(number) || /^\d+(\.\d{0,2})?$/.test(`${number}`)
    ),
    grinding_technology: Yup.string(),
    inner_liner_material: Yup.string(),
    samba_system: Yup.bool(),
    sieve_size: Yup.number().test(
      'maxDigitsAfterDecimal',
      'only_two_decimal',
      number => isNullUndefinedOrEmpty(number) || /^\d+(\.\d{0,2})?$/.test(`${number}`)
    )
  }),
  enabled_config: Yup.object().shape({
    batch: Yup.bool().required('required')
  })
});

const AddLicensesValidation = Yup.object().shape({
  number_of_tasks_other: Yup.number()
    .when('number_of_tasks', {
      is: field => field === 'OTHER',
      then: Yup.number().required('required')
    })
    .test(
      'numberOfTasks',
      'only_number_greater_than',
      number => isNullUndefinedOrEmpty(number) || Number(number) > 1000
    )
});

const MinCommissionNumber = Yup.object().shape({
  commission_number: Yup.string().min(3, 'commission_number').max(15, 'too_long_15').required('required')
});

const ValidationAddressInPLC = Yup.object().shape({
  type: Yup.string().required('required'),
  address: Yup.string()
    .trim()
    .ipv4()
    .when('type', {
      is: type => type === PlcType.IRIS_V3,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required('required')
    }),
  port: Yup.number()
    .integer()
    .min(0, 'between_0_65535')
    .max(65535, 'between_0_65535')
    .when('type', {
      is: type => type !== PlcType.SIEMENS_S7,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required('required')
    }),
  rack: Yup.string().when('type', {
    is: type => type !== PlcType.SIEMENS_S7,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('required')
  }),
  slot: Yup.string().when('type', {
    is: type => type === PlcType.IRIS_V3,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('required')
  }),
  database_name: Yup.string().when('type', {
    is: type => type !== PlcType.IRIS_V3,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('required')
  }),
  variables_table_name: Yup.string().when('type', {
    is: type => type !== PlcType.IRIS_V3,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('required')
  })
});

const ValidateStaticValue = Yup.object().shape({
  name: Yup.string().required('required'),
  source: Yup.string().ipv4('address').required('required'),
  type: Yup.string().required('required'),
  custom_period: Yup.string().required('required'),
  units: Yup.string().trim().required('required'),
  value_type: Yup.string().trim().required('required')
});

const IrisSensorDataSchema = Yup.object().shape({
  decimal_places: Yup.number().required('required'),
  engineering_unit: Yup.object().shape(
    {
      min: Yup.object().when(['max', 'selected_type'], (max, selectedType) => {
        if (selectedType === ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE) {
          const maxValue = (max || {}).value;
          return Yup.object().shape({
            value: Yup.number().lessThan(maxValue, 'invalid_min_engineering_unit').required('required')
          });
        }
        return Yup.object().shape({
          source: Yup.string().required('required')
        });
      }),
      max: Yup.object().when(['min', 'selected_type'], (min, selectedType) => {
        if (selectedType === ENGINEERING_DATA_TYPES_IRIS_V3.FIXED_VALUE) {
          const minValue = (min || {}).value;
          return Yup.object().shape({
            value: Yup.number().moreThan(minValue, 'invalid_max_engineering_unit').required('required')
          });
        }
        return Yup.object().shape({
          source: Yup.string().required('required')
        });
      }),
      selected_type: Yup.string().required('required')
    },
    [['min', 'max', 'engineering_type']]
  ),
  high_trigger: Yup.bool(),
  interval: Yup.number().required('required'),
  low_trigger: Yup.bool(),
  multiplication_factor: Yup.number()
    .min(0.000001, 'between_0000001_100000')
    .max(100000, 'between_0000001_100000')
    .test('maxDigitsAfterDecimal', 'between_0000001_100000', number => /^\d+(\.\d{0,6})?$/.test(`${number}`))
    .required('required')
    .nullable(),
  name: Yup.string().required('required'),
  type: Yup.string().required('required'),
  unit: Yup.string().required('required'),
  variable_name: Yup.string().trim().required('required')
});

const DataBaseTableNameSchema = Yup.object().shape({
  table_name: Yup.string().required('required')
});

const ManagementUnitsSchema = Yup.object().shape({
  temperature: Yup.string().trim(),
  pressure: Yup.string().trim(),
  product_flow: Yup.string().trim(),
  product_mass: Yup.string().trim(),
  liquid_flow: Yup.string().trim(),
  mill_speed: Yup.string().trim(),
  current: Yup.string().trim(),
  power: Yup.string().trim()
});

const MessageStatusSchema = Yup.object().shape({
  area_name: Yup.string().notRequired(),
  alarm: Yup.string().trim(),
  warning: Yup.string().trim().required('required'),
  maintenance: Yup.string().trim().required('required')
});

const MachineStatusIrisV3Schema = Yup.object().shape({
  variable_name: Yup.string().required('required'),
  idle: Yup.string().required('required'),
  running: Yup.string().required('required'),
  critical: Yup.string().required('required')
});

const MachineStatusConditionsIrisV3Schema = Yup.object().shape({
  warning_variable_name: Yup.string().notRequired(),
  warning_value: Yup.string().notRequired(),
  maintenance_variable_name: Yup.string().notRequired(),
  maintenance_value: Yup.string().notRequired()
});

const IrisBatchDetailsSchema = Yup.object().shape({
  batch_id_variable: Yup.string().trim().required('required'),
  batch_size_variable: Yup.string().trim(),
  end_time_variable: Yup.string().trim().required('required'),
  recipe_variable: Yup.string().trim(),
  start_time_variable: Yup.string().trim().required('required')
});

const IrisBatchStatusSchema = Yup.object().shape({
  status_cancelled_value: Yup.number(),
  status_completed_before_target_value: Yup.number(),
  status_completed_value: Yup.number(),
  status_completed_with_target_updates_value: Yup.number(),
  status_in_operation_value: Yup.number(),
  status_loading_value: Yup.number(),
  status_stopped_value: Yup.number(),
  status_variable: Yup.string().trim().required('required')
});

const IrisBatchTargetSchema = Yup.object().shape({
  target_comment_1: Yup.string().trim(),
  target_comment_2: Yup.string().trim(),
  target_comment_3: Yup.string().trim(),
  target_comment_4: Yup.string().trim(),
  target_type_variable: Yup.string().trim(),
  target_value_variable: Yup.string().trim()
});

const IrisBatchLimitsSchema = Yup.object().shape({
  dry_running_flow_protection_variable: Yup.string().trim(),
  dry_running_pressure_protection_variable: Yup.string().trim(),
  high_critical_pressure_inlet_variable: Yup.string().trim(),
  high_critical_product_outlet_temperature_variable: Yup.string().trim(),
  high_warning_pressure_inlet_variable: Yup.string().trim(),
  high_warning_product_outlet_temperature_variable: Yup.string().trim(),
  sieve_cleaning_pressure_variable: Yup.string().trim()
});

const IrisBatchMeasurementsSchema = Yup.object().shape({
  current_cycles_variable: Yup.string().trim(),
  estimated_finish_time_variable: Yup.string().trim(),
  grinding_energy_variable: Yup.string().trim(),
  number_of_passes_variable: Yup.string().trim(),
  progress_variable: Yup.string().trim(),
  specific_energy_variable: Yup.string().trim(),
  total_grinding_energy_variable: Yup.string().trim(),
  total_grinding_time_variable: Yup.string().trim()
});

export {
  AccountRegisterSchema,
  AccountUpdateSchema,
  AddLicensesValidation,
  CustomerCreateSchema,
  ErrorCodesSchema,
  GatewayDesiredVersion,
  LoginSchema,
  MachineSchema,
  MachineStatusSchema,
  MinCommissionNumber,
  RequestPasswordResetSchema,
  ResetPasswordNonAuthSchema,
  ResetPasswordSchema,
  SensorDataSchema,
  StaticSensorDataSchema,
  UserInviteSchema,
  ValidateStaticValue,
  ValidationAddressInPLC,
  BatchDetailsSchema,
  BatchStatusSchema,
  BatchTargetSchema,
  IrisSensorDataSchema,
  DataBaseTableNameSchema,
  ManagementUnitsSchema,
  MessageStatusSchema,
  MachineStatusIrisV3Schema,
  MachineStatusConditionsIrisV3Schema,
  IrisBatchDetailsSchema,
  IrisBatchStatusSchema,
  IrisBatchTargetSchema,
  IrisBatchLimitsSchema,
  IrisBatchMeasurementsSchema
};
