import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader, Stack } from '@mui/material';
import T from 'prop-types';
import { useDispatch } from 'react-redux';

import { FormElements } from 'web-components';
import { useParams } from 'react-router-dom';
import GatewayTableSelector from 'components/MachineTable/GatewayNoFilter/GatewayTableSelector';
import { updateFlagRequiredGateway } from 'redux/machines/actions';
import LinkIcon from '@mui/icons-material/Link';
import EmptyState from '../../../../components/EmptyState';
import MachineGatewayModal from './MachineGatewaysModal';

const labelAmend = 'gateways.overview';

const GatewayContent = ({ gateways }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isGatewayRequired, setGatewayRequired] = useState(!!gateways?.is_gateway_required);
  const [openModal, setOpenModal] = useState(false);
  const { customerId, machineId } = useParams();

  const handleCloseModal = () => setOpenModal(false);

  const columns = [
    { id: 'gateway_id', label: t(`${labelAmend}.gateway_id`), sortable: false },
    { id: 'linked_machines', label: t(`${labelAmend}.linked_machines`), sortable: false },
    { id: 'system_version', label: t(`${labelAmend}.system_version`), sortable: false },
    { id: 'operating_system', label: t(`${labelAmend}.operating_system`), sortable: false },
    { id: 'connection_status', label: t(`${labelAmend}.connection_status`), sortable: false },
    { id: 'link_gateway', label: '', sortable: false }
  ];

  const handleRequiredFlag = () => {
    const required = !isGatewayRequired;
    setGatewayRequired(required);
    const payload = {
      required
    };
    dispatch(updateFlagRequiredGateway(machineId, payload));
  };

  return (
    <Card sx={{ p: 4, my: 2 }}>
      <CardHeader
        sx={{ p: 0, alignItems: 'flex-start', mb: 3 }}
        action={
          <Button
            variant={!gateways?.gateway ? 'contained' : 'outlined'}
            disabled={!isGatewayRequired}
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '55px', minW: '156px' }}
            onClick={() => setOpenModal(true)}
          >
            <LinkIcon fontSize="medium" />
            {t(`${labelAmend}.link`)}
          </Button>
        }
        title={
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '15px', fontSize: '16px' }}>{t(`${labelAmend}.gateway`)}</Box>
          </Box>
        }
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />

      <Stack spacing={4}>
        <FormElements.ToggleButton
          label="Required"
          name="requiredType"
          id="requiredType"
          size="large"
          margin="none"
          checked={isGatewayRequired}
          onChange={handleRequiredFlag}
          disabled={false}
          labelPlacement="right"
        />
        {!gateways?.gateway ? (
          <EmptyState type="gateway_customer_detail" />
        ) : (
          <GatewayTableSelector organizationId={customerId} columns={columns} rows={gateways.gateway} />
        )}
      </Stack>

      <MachineGatewayModal
        handleClose={handleCloseModal}
        open={openModal}
        organizationId={customerId}
        machineId={machineId}
      />
    </Card>
  );
};

GatewayContent.propTypes = {
  gateways: T.instanceOf(Object)
};

GatewayContent.defaultProps = {
  gateways: null
};

export default GatewayContent;
