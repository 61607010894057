/* eslint-disable import/prefer-default-export */
import { put, takeLatest } from 'redux-saga/effects';
import { GATEWAY_DEFAULT_LIST_ORDER, GATEWAY_DEFAULT_LIST_SORT } from 'attrs/gateways';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from 'attrs/pagination';
import {
  createLinkBetweenGatewayMachine,
  linkMachineByGateway,
  loadGateway,
  loadGatewayMachineDetail,
  loadGateways,
  loadGatewaysAssignable,
  loadGatewaysAssignableMachine,
  loadGatewaysByOrganization,
  loadGatewaysByOrganizationSearch,
  loadGatewaysByResume,
  loadGatewaysByResumeDetail,
  loadGatewaysList,
  loadGatewaysListSearch,
  loadGatewaysLostSignal,
  loadGatewaysVersions,
  loadGatewaysWithIssues,
  loadMachineWithoutGateway,
  unLinkGatewayByOrganizationId,
  unLinkMachineByGateway,
  updateGateway,
  updateGatewayBind,
  updateGatewayFirmwareVersion
} from '../../api/gateways';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { actionStatus, getError, statusAction } from '../utils';
import { setGatewayFilter, setGatewaySortOrder } from './actions';
import * as constants from './constants';

function* handleUpdateGateway({ id, values }) {
  yield put(statusAction(constants.UPDATE_GATEWAY, actionStatus.START));

  try {
    const { data } = yield updateGateway(id, values);
    yield put(statusAction(constants.UPDATE_GATEWAY, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_GATEWAY, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleLoadGateways() {
  yield put(statusAction(constants.LOAD_GATEWAYS, actionStatus.START));
  try {
    const { data } = yield loadGateways();
    yield put(statusAction(constants.LOAD_GATEWAYS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleLoadGateway({ id }) {
  yield put(statusAction(constants.LOAD_GATEWAY, actionStatus.START));
  try {
    const { data } = yield loadGateway(id);
    yield put(statusAction(constants.LOAD_GATEWAY, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAY, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleLoadGatewaysVersion({ values }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_VERSIONS, actionStatus.START));

  const { type } = values;
  try {
    const { data } = yield loadGatewaysVersions(type);

    yield put(statusAction(constants.LOAD_GATEWAYS_VERSIONS, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_VERSIONS, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleUpdateGatewayVersion({ values }) {
  yield put(statusAction(constants.UPDATE_GATEWAY_VERSION, actionStatus.START));

  try {
    const { data } = yield updateGatewayFirmwareVersion(values);
    yield put(
      statusAction(constants.UPDATE_GATEWAY_VERSION, actionStatus.SUCCESS, {
        payload: data
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.gateways.success.update'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_GATEWAY_VERSION, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleLoadGatewaysMachineDetail({
  organizationId,
  serialNumber,
  configured,
  organizationName,
  connection,
  limit,
  page,
  order,
  sort
}) {
  yield put(statusAction(constants.LOAD_GATEWAYS_MACHINE_DETAIL, actionStatus.START));
  try {
    const { data } = yield loadGatewayMachineDetail(
      organizationId,
      serialNumber,
      configured,
      organizationName,
      connection,
      limit,
      page,
      order,
      sort
    );

    yield put(setGatewaySortOrder(sort, order));
    yield put(setGatewayFilter(organizationName, connection));

    yield put(statusAction(constants.LOAD_GATEWAYS_MACHINE_DETAIL, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_MACHINE_DETAIL, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleLoadGatewaysByResume({ organizationId, organizationName, connection, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_RESUME, actionStatus.START));
  try {
    const { data } = yield loadGatewaysByResume(organizationId, organizationName, connection, limit, page, order, sort);

    yield put(setGatewaySortOrder(sort, order));
    yield put(setGatewayFilter(organizationName, connection));

    yield put(statusAction(constants.LOAD_GATEWAYS_RESUME, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_RESUME, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleLoadGatewaysByResumeDetail({ organizationId, organizationName, configured, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_BY_ORGANIZATION, actionStatus.START));
  try {
    const { data } = yield loadGatewaysByResumeDetail(
      organizationId,
      organizationName,
      configured,
      limit,
      page,
      order,
      sort
    );

    yield put(setGatewaySortOrder(order, sort));
    yield put(setGatewayFilter(organizationName, configured));

    yield put(statusAction(constants.LOAD_GATEWAYS_RESUME_DETAIL, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_RESUME_DETAIL, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

/** Endpoints V2 */

function* handleLoadGatewaysList({ serialNumber, configured, organizationName, connection, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_LIST_V2, actionStatus.START));
  try {
    const { data } = yield loadGatewaysList(
      serialNumber,
      configured,
      organizationName,
      connection,
      limit,
      page,
      order,
      sort
    );

    yield put(statusAction(constants.LOAD_GATEWAYS_LIST_V2, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_LIST_V2, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleFetchFilteredGatewayList({
  serialNumber,
  configured,
  organizationName,
  connection,
  page,
  limit,
  order,
  sort
}) {
  try {
    const { data } = yield loadGatewaysListSearch(
      serialNumber,
      configured,
      organizationName,
      connection,
      limit,
      page,
      order,
      sort
    );

    yield put(setGatewaySortOrder(sort, order));
    yield put(
      statusAction(constants.LOAD_GATEWAYS_LIST_V2, actionStatus.SUCCESS, {
        payload: data
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_LIST_V2, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.alerts.${error}`
      })
    );
  }
}

function* handleLoadGatewaysByOrganization({ organizationId, serialNumber, connection, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_BY_ORGANIZATION, actionStatus.START));

  try {
    const { data } = yield loadGatewaysByOrganization(
      organizationId,
      serialNumber,
      connection,
      limit,
      page,
      order,
      sort
    );

    yield put(statusAction(constants.LOAD_GATEWAYS_BY_ORGANIZATION, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_BY_ORGANIZATION, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleFetchFiltered({ organizationId, serialNumber, connection, limit, page, order, sort }) {
  try {
    const { data } = yield loadGatewaysByOrganizationSearch(
      organizationId,
      serialNumber,
      connection,
      limit,
      page,
      order,
      sort
    );

    yield put(setGatewaySortOrder(order, sort));
    yield put(statusAction(constants.LOAD_GATEWAYS_BY_ORGANIZATION, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_BY_ORGANIZATION, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.alerts.${error}`
      })
    );
  }
}

function* handleLoadGatewaysAssignable({ serialNumber, connection, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE, actionStatus.START));

  try {
    const { data } = yield loadGatewaysAssignable(serialNumber, connection, limit, page, order, sort);

    yield put(statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleGetGatewaysAssignableFiltered({ serialNumber, connection, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE, actionStatus.START));

  try {
    const { data } = yield loadGatewaysAssignable(serialNumber, connection, limit, page, order, sort);

    yield put(statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleLoadGatewaysAssignableMachine({ gatewayId, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE_MACHINE, actionStatus.START));

  try {
    const { data } = yield loadGatewaysAssignableMachine(gatewayId, sort);

    yield put(statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE_MACHINE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_ASSIGNABLE_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleUpdateGatewayBind({ organizationId, payload }) {
  yield put(statusAction(constants.UPDATE_GATEWAY_BIND, actionStatus.START));

  try {
    const { data } = yield updateGatewayBind(organizationId, payload);
    yield put(statusAction(constants.UPDATE_GATEWAY_BIND, actionStatus.SUCCESS, { payload: data }));
    yield put({ type: constants.LOAD_GATEWAYS_BY_ORGANIZATION, organizationId, limit: 5, page: 1 });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UPDATE_GATEWAY_BIND, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleUnlinkGatewayByOrganizationId({ organizationId, payload }) {
  yield put(statusAction(constants.UNLINK_GATEWAY_BY_ORGANIZATION_ID, actionStatus.START));

  try {
    const { data } = yield unLinkGatewayByOrganizationId(organizationId, payload);
    yield put(statusAction(constants.UNLINK_GATEWAY_BY_ORGANIZATION_ID, actionStatus.SUCCESS, { payload: data }));
    yield put({ type: constants.LOAD_GATEWAYS_BY_ORGANIZATION, organizationId, limit: 5, page: 1 });

    yield put({
      type: constants.LOAD_GATEWAYS_BY_ORGANIZATION,
      organizationId,
      limit: PAGINATION_DEFAULT_ROWS_PER_PAGE,
      page: PAGINATION_DEFAULT_START_PAGE,
      order: GATEWAY_DEFAULT_LIST_ORDER,
      sort: GATEWAY_DEFAULT_LIST_SORT
    });

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UNLINK_GATEWAY_BY_ORGANIZATION_ID, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleLinkMachineByGateway({ gatewayId, payload }) {
  yield put(statusAction(constants.LINK_MACHINES_BY_GATEWAY, actionStatus.START));

  try {
    const { data } = yield linkMachineByGateway(gatewayId, payload);
    yield put(statusAction(constants.LINK_MACHINES_BY_GATEWAY, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );

    window.location.reload(true);
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LINK_MACHINES_BY_GATEWAY, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleUnlinkMachineByGateway({ gatewayId, machineId }) {
  yield put(statusAction(constants.UNLINK_MACHINES_BY_GATEWAY, actionStatus.START));

  try {
    const { data } = yield unLinkMachineByGateway(gatewayId, machineId);
    yield put(statusAction(constants.UNLINK_MACHINES_BY_GATEWAY, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );

    window.location.reload(true);
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.UNLINK_MACHINES_BY_GATEWAY, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleCreateLinkBetweenGatewayMachine({ gatewayId, payload }) {
  yield put(statusAction(constants.CREATE_LINK_BETWEEN_GATEWAY_MACHINE, actionStatus.START));

  try {
    const { data } = yield createLinkBetweenGatewayMachine(gatewayId, payload);
    yield put(statusAction(constants.CREATE_LINK_BETWEEN_GATEWAY_MACHINE, actionStatus.SUCCESS, { payload: data }));

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CREATE_LINK_BETWEEN_GATEWAY_MACHINE, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.gateways.${error}`
      })
    );
  }
}

function* handleLoadGatewaysLostSignal({ interval, limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_LOST_SIGNAL, actionStatus.START));
  try {
    const { data } = yield loadGatewaysLostSignal(interval, limit, page, order, sort);
    yield put(statusAction(constants.LOAD_GATEWAYS_LOST_SIGNAL, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_LOST_SIGNAL, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleLoadMachineWithoutGateway({ limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_MACHINE_WITHOUT_GATEWAY, actionStatus.START));
  try {
    const { data } = yield loadMachineWithoutGateway(limit, page, order, sort);
    yield put(statusAction(constants.LOAD_MACHINE_WITHOUT_GATEWAY, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_MACHINE_WITHOUT_GATEWAY, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

function* handleLoadGatewaysWithIssues({ limit, page, order, sort }) {
  yield put(statusAction(constants.LOAD_GATEWAYS_WITH_ISSUES, actionStatus.START));
  try {
    const { data } = yield loadGatewaysWithIssues(limit, page, order, sort);
    yield put(statusAction(constants.LOAD_GATEWAYS_WITH_ISSUES, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_GATEWAYS_WITH_ISSUES, actionStatus.ERROR, {
        message: error
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_GATEWAYS, handleLoadGateways);
  yield takeLatest(constants.LOAD_GATEWAY, handleLoadGateway);
  yield takeLatest(constants.UPDATE_GATEWAY, handleUpdateGateway);
  yield takeLatest(constants.UPDATE_GATEWAY_VERSION, handleUpdateGatewayVersion);
  yield takeLatest(constants.LOAD_GATEWAYS_MACHINE_DETAIL, handleLoadGatewaysMachineDetail);
  yield takeLatest(constants.LOAD_GATEWAYS_VERSIONS, handleLoadGatewaysVersion);
  yield takeLatest(constants.LOAD_GATEWAYS_ASSIGNABLE, handleLoadGatewaysAssignable);
  yield takeLatest(constants.LOAD_GATEWAYS_BY_ORGANIZATION, handleLoadGatewaysByOrganization);
  yield takeLatest(constants.LOAD_GATEWAYS_RESUME, handleLoadGatewaysByResume);
  yield takeLatest(constants.LOAD_GATEWAYS_RESUME_DETAIL, handleLoadGatewaysByResumeDetail);
  yield takeLatest(constants.LOAD_GATEWAYS_RESUME_WITH_FILTER, handleFetchFiltered);

  yield takeLatest(constants.LOAD_GATEWAYS_LIST_V2, handleLoadGatewaysList);
  yield takeLatest(constants.LOAD_GATEWAYS_LIST_WITH_FILTER, handleFetchFilteredGatewayList);
  yield takeLatest(constants.UPDATE_GATEWAY_BIND, handleUpdateGatewayBind);
  yield takeLatest(constants.UNLINK_GATEWAY_BY_ORGANIZATION_ID, handleUnlinkGatewayByOrganizationId);
  yield takeLatest(constants.LOAD_GATEWAYS_ASSIGNABLE_MACHINE, handleLoadGatewaysAssignableMachine);
  yield takeLatest(constants.CREATE_LINK_BETWEEN_GATEWAY_MACHINE, handleCreateLinkBetweenGatewayMachine);
  yield takeLatest(constants.LINK_MACHINES_BY_GATEWAY, handleLinkMachineByGateway);
  yield takeLatest(constants.UNLINK_MACHINES_BY_GATEWAY, handleUnlinkMachineByGateway);
  yield takeLatest(constants.LOAD_GATEWAYS_LOST_SIGNAL, handleLoadGatewaysLostSignal);
  yield takeLatest(constants.LOAD_MACHINE_WITHOUT_GATEWAY, handleLoadMachineWithoutGateway);
  yield takeLatest(constants.LOAD_GATEWAYS_WITH_ISSUES, handleLoadGatewaysWithIssues);
  yield takeLatest(constants.LOAD_GATEWAYS_ASSIGNABLE_WITH_FILTER, handleGetGatewaysAssignableFiltered);
}
