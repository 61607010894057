import { Box, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { NewSingleLineTabs } from 'web-components';
import UserInvite from 'components/forms/Customers/UserManagement/UserInviteForm';
import { StyledContentWrapper } from '../CustomerDetail.styled';
import UserInvitations from './UserInvitations';
import ActiveUsers from './ActiveUsers';

const UserManagementContainer = ({ customer }) => {
  const { t } = useTranslation();

  const tabContent = [
    {
      title: t('customers.user_management.active'),
      content: <ActiveUsers organizationId={customer.id} />
    },

    {
      title: t('customers.user_management.pending'),
      content: <UserInvitations organizationId={customer.id} />
    }
  ];

  return (
    <StyledContentWrapper elevation={3}>
      <Card>
        <Box sx={{ mx: '24px', my: '24px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }} gutterBottom>
              {t('User Management')}
            </Typography>
            <UserInvite organizationId={customer.id} />
          </Stack>
          <NewSingleLineTabs tabContent={tabContent} sx={{ pt: 3 }} />
        </Box>
      </Card>
    </StyledContentWrapper>
  );
};

UserManagementContainer.propTypes = {
  customer: T.shape({ id: T.string, name: T.string }).isRequired
};

export default UserManagementContainer;
