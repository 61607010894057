import React, { useState } from 'react';
import { Box, FormControl, Grid, InputLabel, Select, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormElements } from 'web-components';
import PropTypes from 'prop-types';
import { TaskFrequency } from './frequency/TaskFrequency';
import { BY_HOUR_METER, BY_TIME } from '../TaskFrequencyTypes';
import { HOUR, YEAR } from '../TaskRepeatTypes';

export default function GeneralInformation({ data, setData }) {
  const { t } = useTranslation();
  const [frequency1, setFrequency1] = useState();
  const [frequency2, setFrequency2] = useState();
  const [hasCheckedByTime, setHasCheckedByTime] = useState(false);
  const [hasCheckedByHour, setHasCheckedByHour] = useState(false);

  // TODO: do the same to others field when we start to comunicate to backend
  const onChangeName = event => {
    setData({ ...data, name: event.target.value });
  };

  //  TODO: when we have info to change this
  const hasHourMeter = true;

  const onChangeCheckByTime = () => {
    if (!hasCheckedByTime)
      setFrequency1({
        frequency_type: BY_TIME,
        repeat_amount: 1,
        repeat_type: YEAR,
        start_date: new Date().toISOString()
      });
    else setFrequency1(null);
    setHasCheckedByTime(!hasCheckedByTime);
  };

  const onChangeCheckByHour = () => {
    if (!hasCheckedByHour)
      setFrequency2({
        frequency_type: BY_HOUR_METER,
        repeat_hours: 24,
        repeat_type: HOUR,
        start_date: new Date().toISOString()
      });
    else setFrequency2(null);
    setHasCheckedByHour(!hasCheckedByHour);
  };
  return (
    <Stack spacing={4} justifyContent="center">
      <Box>
        <Grid container spacing={2} columns={10} sx={{ alignItems: 'flex-end' }}>
          <Grid item xs={3}>
            <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 'bold' }} gutterBottom>
              {t('task.general_information.title')}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              inputProps={{ maxLength: 30 }}
              id="task_name"
              label={t('task.general_information.task_name')}
              variant="filled"
              onChange={onChangeName}
              value={data.name}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              inputProps={{ maxLength: 30 }}
              id="category"
              label={t('task.general_information.category')}
              variant="filled"
            />
          </Grid>
          <Grid item xs={2} sx={{ alignItems: 'center' }}>
            <Typography sx={{ fontSize: { xs: 14, sm: 16 } }} gutterBottom>
              {t('task.general_information.estimated_downtime_caption')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <TextField
              fullWidth
              inputProps={{ maxLength: 30 }}
              id="estimated_downtime_number"
              label={t('task.general_information.estimated_downtime_number')}
              variant="filled"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="period_time">{t('task.general_information.period_time')}</InputLabel>
              <Select fullWidth labelId="period_time_label" id="period_time_select" />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Typography component="div">
          <Stack spacing={1}>
            <Box sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 'bold' }}>
              {t('task.general_information.frequency.title')}
            </Box>
            <Box sx={{ color: 'text.secondary', fontSize: { xs: 12, sm: 14 } }}>
              {t('task.general_information.frequency.caption')}
            </Box>
          </Stack>
        </Typography>
      </Stack>
      <FormElements.ToggleButton
        checked={hasCheckedByTime}
        onChange={onChangeCheckByTime}
        label={t('task.general_information.frequency.frequency_by_time')}
        labelPlacement="right"
      />
      {frequency1 && <TaskFrequency frequency={frequency1} setFrequency={setFrequency1} frequencyType={BY_TIME} />}
      <FormElements.ToggleButton
        disabled={!hasHourMeter}
        checked={hasCheckedByHour}
        onChange={onChangeCheckByHour}
        label={t('task.general_information.frequency.frequency_by_hour_meter')}
        labelPlacement="right"
      />
      {frequency2 && (
        <TaskFrequency frequency={frequency2} setFrequency={setFrequency2} frequencyType={BY_HOUR_METER} />
      )}
    </Stack>
  );
}

GeneralInformation.propTypes = {
  data: PropTypes.shape.isRequired,
  setData: PropTypes.func.isRequired
};
