const LOAD_GATEWAYS = 'GATEWAYS/LOAD';
const LOAD_GATEWAY = 'GATEWAYS/LOAD_GATEWAY';
const UPDATE_GATEWAY = 'GATEWAY/UPDATE';
const RESET_GATEWAY = 'GATEWAY/RESET';
const RESET_GATEWAYS = 'GATEWAYS/RESET';
const UPDATE_GATEWAY_VERSION = 'GATEWAYS/UPDATE_VERSION';
const LOAD_GATEWAYS_VERSIONS = 'GATEWAYS/LOAD_VERSIONS';
const LOAD_GATEWAYS_RESUME = 'GATEWAYS_RESUME/LOAD';
const LOAD_GATEWAYS_MACHINE_DETAIL = 'GATEWAYS_RESUME/GATEWAY_MACHINE_DETAIL';
const LOAD_GATEWAYS_RESUME_WITH_FILTER = 'GATEWAYS_RESUME_DETAIL/LOAD_ALL_FILTERED';
const LOAD_GATEWAYS_RESUME_DETAIL = 'GATEWAYS_RESUME_DETAIL/LOAD';
const CHANGE_GATEWAYS_ORDER_RESUME = 'GATEWAYS_RESUME_DETAIL/CHANGE_ORDER';
const CHANGE_GATEWAYS_FILTER_RESUME = 'GATEWAYS_RESUME_DETAIL/FILTER';
const LOAD_GATEWAY_MACHINES = 'MACHINES/LOAD';
const LOAD_GATEWAY_MACHINES_BY_GATEWAY = 'MACHINES/LOAD_WITH_GATEWAY';

/** Endpoints V2 */

const LOAD_GATEWAYS_LIST_V2 = 'GATEWAYS/LOAD_LIST';
const LOAD_GATEWAYS_LIST_WITH_FILTER = 'GATEWAYS/LOAD_LIST_WITH_FILTER';
const LOAD_GATEWAYS_ASSIGNABLE_WITH_FILTER = 'GATEWAYS/ASSIGNABLE_WITH_FILTER';
const LOAD_GATEWAYS_BY_ORGANIZATION = 'GATEWAYS/LOAD_BY_ORGANIZATION';
const LOAD_GATEWAYS_ASSIGNABLE = 'GATEWAYS/LOAD_GATEWAYS_ASSIGNABLE';
const LOAD_GATEWAYS_ASSIGNABLE_MACHINE = 'GATEWAYS/LOAD_GATEWAYS_ASSIGNABLE_MACHINE';
const UPDATE_GATEWAY_BIND = 'GATEWAYS/UPDATE_GATEWAY_BIND';
const UNLINK_GATEWAY_BY_ORGANIZATION_ID = 'GATEWAYS/UNLINK_GATEWAY_BY_ORGANIZATION_ID';
const CREATE_LINK_BETWEEN_GATEWAY_MACHINE = 'GATEWAYS/LINK_GATEWAY_MACHINE';
const LINK_MACHINES_BY_GATEWAY = 'GATEWAY/LINK_MACHINE';
const UNLINK_MACHINES_BY_GATEWAY = 'GATEWAY/UNLINK_MACHINE';
const LOAD_GATEWAYS_LOST_SIGNAL = 'GATEWAYS/LOAD_GATEWAYS_LOST_SIGNAL';
const LOAD_MACHINE_WITHOUT_GATEWAY = 'MACHINE/LOAD_MACHINE_WITHOUT_GATEWAY';
const LOAD_GATEWAYS_WITH_ISSUES = 'GATEWAYS/LOAD_GATEWAY_WITH_ISSUE';

export {
  CHANGE_GATEWAYS_FILTER_RESUME,
  CHANGE_GATEWAYS_ORDER_RESUME,
  CREATE_LINK_BETWEEN_GATEWAY_MACHINE,
  LINK_MACHINES_BY_GATEWAY,
  LOAD_GATEWAY,
  LOAD_GATEWAYS,
  LOAD_GATEWAYS_ASSIGNABLE,
  LOAD_GATEWAYS_ASSIGNABLE_MACHINE,
  LOAD_GATEWAYS_ASSIGNABLE_WITH_FILTER,
  LOAD_GATEWAYS_BY_ORGANIZATION,
  LOAD_GATEWAYS_LIST_V2,
  LOAD_GATEWAYS_LIST_WITH_FILTER,
  LOAD_GATEWAYS_LOST_SIGNAL,
  LOAD_GATEWAYS_MACHINE_DETAIL,
  LOAD_GATEWAYS_RESUME,
  LOAD_GATEWAYS_RESUME_DETAIL,
  LOAD_GATEWAYS_RESUME_WITH_FILTER,
  LOAD_GATEWAYS_VERSIONS,
  LOAD_GATEWAY_MACHINES,
  LOAD_GATEWAY_MACHINES_BY_GATEWAY,
  LOAD_MACHINE_WITHOUT_GATEWAY,
  LOAD_GATEWAYS_WITH_ISSUES,
  RESET_GATEWAY,
  RESET_GATEWAYS,
  UNLINK_GATEWAY_BY_ORGANIZATION_ID,
  UNLINK_MACHINES_BY_GATEWAY,
  UPDATE_GATEWAY,
  UPDATE_GATEWAY_BIND,
  UPDATE_GATEWAY_VERSION
};
