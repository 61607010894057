import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DataTable, NotifyIcon } from 'web-components';
import { Box, IconButton } from '@mui/material';
import { formatMachineType } from 'helpers/utils';
import { useHistory } from 'react-router-dom';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../../attrs/pagination';
import Footer from '../../DataTable/Footer';
import { MachineFilter } from '../../MachineFilter';
import { FilterContainer } from './elements';
import { getGatewayFiltered } from '../../../redux/gateways/actions';
import { GATEWAY_CONNECTED, GATEWAY_NOT_CONNECTED } from '../../../attrs/gateways';
import UnLinkGateway from './UnLinkGateway/UnLinkGatewayModal';

const GatewayTable = ({ columns, rows, organizationId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [addModal, setAddModal] = useState({
    open: false,
    row: {
      gatewayId: '',
      organizationId: ''
    }
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSort, setCurrentSort] = useState({});
  const [filterConnectionStatus, setFilterConnectionStatus] = useState('');
  const [filterSerialNumber, setFilterSerialNumber] = useState('');
  const [clear, setClear] = useState(false);
  const rowsPerPage = PAGINATION_DEFAULT_ROWS_PER_PAGE;

  const handleCloseUnlinkGateway = () =>
    setAddModal({
      open: false,
      row: {
        gatewayId: '',
        organizationId: ''
      }
    });
  const handleRowClick = serial => history.push(`/gateways/${serial}`);

  const unLink = (e, row) => {
    e.stopPropagation();
    e.preventDefault();
    setAddModal({
      open: true,
      row: {
        gatewayId: row.serial_number,
        organizationId: row.organization_id
      }
    });
  };

  useEffect(() => {
    setIsLoading(false);
    if (rows && rows.data && rows.data.length) {
      setCurrentView(
        DataTable.sortFn(
          rows.data.map(row => {
            const machineTypeFormat = formatMachineType(row.type);
            return {
              ...row,
              gateway_id: row.serial_number ? <Box> {row.serial_number} </Box> : '---',
              linked_machines: row.machine_count ? row.machine_count : '---',
              system_version: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {row.desired_version !== row.actual_version && (
                    <NotifyIcon iconName="warning" fontSize="medium" sx={{ mt: 1 }} />
                  )}
                  {row.actual_version || '---'}
                </Box>
              ),
              operating_system: row.type ? machineTypeFormat : t('gateways.unassigned'),
              connection_status: row.connection_status,
              link_gateway: (
                <Box sx={{ textAlign: 'right' }}>
                  <IconButton color="inherit" onClick={e => unLink(e, row)}>
                    <LinkOffIcon color="primary" />
                  </IconButton>
                </Box>
              )
            };
          })
        )
      );
    } else {
      setCurrentView([]);
    }
  }, [organizationId, rows, t, currentSort.colId, currentSort.order]);

  const handleChangePage = page => {
    setIsLoading(true);
    setCurrentPage(page);
    dispatch(
      getGatewayFiltered(
        organizationId,
        filterSerialNumber,
        filterConnectionStatus,
        rowsPerPage,
        page,
        currentSort.order,
        currentSort.colId
      )
    );
  };

  const handleChangeFilter = (page, serialNumber, connection) => {
    setCurrentPage(page);
    dispatch(
      getGatewayFiltered(
        organizationId,
        serialNumber,
        connection,
        rowsPerPage,
        page,
        currentSort.order,
        currentSort.colId
      )
    );
  };

  const handleSelectRow = row => {
    setSelectedRow(row);
    handleRowClick(row.serial_number);
  };

  const handleSort = (colId, order) => {
    setIsLoading(true);
    dispatch(
      getGatewayFiltered(
        organizationId,
        filterSerialNumber,
        filterConnectionStatus,
        rowsPerPage,
        currentPage,
        order.toUpperCase(),
        colId
      )
    );
    setCurrentSort({ colId, order });
  };

  const setInitStates = () => {
    setCurrentPage(PAGINATION_DEFAULT_START_PAGE);
  };
  const props = {
    dataSelectorTable: 'gateways-table',
    dataSelectorRows: 'gateways-table-rows',
    isLoading,
    columns,
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={rows.total_pages || 1}
        totalLength={rows.total_length || 0}
        totalItemsPerPage={rows.limit || 0}
        onChange={handleChangePage}
      />
    ),
    onClick: handleSelectRow,
    defaultSort: currentSort,
    sortFn: handleSort
  };

  const handleFilterConnectionStatus = configured => {
    if (configured !== filterConnectionStatus) {
      setIsLoading(true);
      setFilterConnectionStatus(configured);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, filterSerialNumber, configured);
    }
  };

  const handleFilterSerialNumber = serialNumber => {
    if (serialNumber !== filterSerialNumber) {
      setIsLoading(true);
      setFilterSerialNumber(serialNumber);
      handleChangeFilter(PAGINATION_DEFAULT_START_PAGE, serialNumber, filterConnectionStatus);
    }
  };

  const connectionObject = [
    { label: 'general.all', value: '' },
    { label: 'gateways.connected', value: GATEWAY_CONNECTED },
    { label: 'gateways.not_connected', value: GATEWAY_NOT_CONNECTED }
  ];

  return (
    <Box>
      <FilterContainer>
        <MachineFilter
          isCheckbox={false}
          clear={clear}
          optionText={filterSerialNumber}
          setInitStates={setInitStates}
          handleFilter={handleFilterSerialNumber}
          labelFilter={t('gateways.filter.gateway_id')}
          setClear={setClear}
        />
        <MachineFilter
          isCheckbox={false}
          clear={clear}
          optionText={filterConnectionStatus}
          setInitStates={setInitStates}
          handleFilter={handleFilterConnectionStatus}
          optionsObj={connectionObject}
          labelFilter={t('gateways.filter.connection_status')}
          setClear={setClear}
        />
      </FilterContainer>
      <DataTable hover {...props} />

      <UnLinkGateway
        handleModalParams={addModal}
        handleClose={handleCloseUnlinkGateway}
        gatewayId={selectedRow.serial_number}
        organizationId={selectedRow.organization_id}
      />
    </Box>
  );
};

GatewayTable.propTypes = {
  columns: T.arrayOf(
    T.shape({
      id: T.string.isRequired
    })
  ).isRequired,
  rows: T.shape({
    data: T.instanceOf(Array),
    limit: T.number,
    order: T.string,
    page: T.number,
    search: T.string,
    sort: T.instanceOf(Array),
    total_length: T.number,
    total_pages: T.number
  }).isRequired,
  organizationId: T.string
};

GatewayTable.defaultProps = {
  organizationId: null
};

export default GatewayTable;
