import React, { useEffect, useRef, useState } from 'react';
import T from 'prop-types';
import { Box, Chip, IconButton, List, ListItem, ListItemText, Paper, Popper, Stack, Typography } from '@mui/material';
import { NotifyIcon } from 'web-components';

export default function ChipViewer(props) {
  const { dataList, title, direction, breakQuantity } = props;
  const popperComponentRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopper = Boolean(anchorEl);

  const handleLeave = () => {
    setAnchorEl(null);
  };

  function assertIsNode(e) {
    if (!e || !('nodeType' in e)) {
      throw new Error(`Node expected`);
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = ({ target }) => {
      assertIsNode(target);
      if (openPopper && popperComponentRef.current && !popperComponentRef.current.contains(target)) {
        handleLeave();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openPopper]);

  return (
    <Stack
      direction={direction}
      justifyContent="flex-start"
      alignItems={direction === 'row' ? 'center' : 'flex-start'}
      spacing={0.5}
    >
      {dataList.map((a, i) => {
        if (i < breakQuantity)
          return (
            <Chip
              label={
                <Typography sx={{ fontSize: 12 }} noWrap>
                  {a.label}
                </Typography>
              }
              size="small"
              sx={{
                bgcolor: '#7A7D85',
                color: '#FFFFFF',
                maxWidth: '160px'
              }}
            />
          );
        return null;
      })}
      {dataList.length > breakQuantity && (
        <Chip
          label={`+${dataList.length - breakQuantity}`}
          size="small"
          sx={{
            bgcolor: '#7A7D85',
            color: '#FFFFFF'
          }}
          onClick={event => {
            setAnchorEl(event.currentTarget);
          }}
        />
      )}
      <Popper style={{ zIndex: 1500, maxWidth: '100%' }} open={openPopper} anchorEl={anchorEl} ref={popperComponentRef}>
        <Paper sx={{ p: 3, pr: 0, pt: 1, m: 1 }}>
          <Box>
            <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
              <Typography sx={{ fontSize: { xs: 18, sm: 20 }, fontWeight: '500' }} noWrap>
                {title}
              </Typography>
              <IconButton onClick={handleLeave}>
                <NotifyIcon iconName="close" color="primary" fontSize="small" />
              </IconButton>
            </Stack>
          </Box>
          <List
            sx={{
              width: 360,
              maxWidth: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 300,
              p: 0,
              pr: 2
            }}
          >
            {dataList.map((a, index) => {
              if (index >= breakQuantity)
                return (
                  <ListItem
                    key={`item-${a.id}`}
                    sx={{
                      p: 1,
                      borderBottom: '1px solid #B1B7C5'
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          <Chip
                            label={
                              <Typography sx={{ fontSize: 12 }} noWrap>
                                {a.label}
                              </Typography>
                            }
                            size="small"
                            sx={{
                              bgcolor: '#7A7D85',
                              color: '#FFFFFF',
                              maxWidth: '160px'
                            }}
                          />
                        </>
                      }
                    />
                  </ListItem>
                );
              return null;
            })}
          </List>
        </Paper>
      </Popper>
    </Stack>
  );
}

ChipViewer.propTypes = {
  dataList: T.arrayOf(T.element),
  title: T.string,
  direction: T.string,
  breakQuantity: T.number
};

ChipViewer.defaultProps = {
  dataList: [],
  title: '',
  direction: 'row',
  breakQuantity: 2
};
