import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Navbar from './components/Navbar';
import { MainContainer } from './elements';
import ROUTES from './attrs/routes';

const LoggedIn = () => (
  <>
    <Navbar routes={ROUTES} />
    <MainContainer maxWidth={false}>
      <Switch>
        {ROUTES.map(route => (
          <Route key={route.name} exact component={route.component} path={route.path} />
        ))}
        <Redirect to="/dashboard" />
      </Switch>
    </MainContainer>
  </>
);

export default LoggedIn;
