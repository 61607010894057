import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormElements, MachineIcon, NotifyTypo } from 'web-components';
import { CardContent, Container } from '@mui/material';
import EmptyState from '../../../EmptyState';
import {
  StyledCard,
  StyledContentWrapper,
  StyledMachineStatusCardsWrapper,
  StyledMachineStatusDataTypeWrapper,
  StyledMachineStatusHeader
} from './elements';
import {
  MachineBreadcrumb,
  MachinePageHeader
} from '../../../../containers/Machines/MachineDetail/MachineDetail.styled';
import { PageHeaderSubTitle, PageHeaderTitle } from '../../../../elements';

const { Heading2, InfoText } = NotifyTypo;

const StatusDataTypes = ['INTEGER', 'BOOL'];

const StatusTypes = [
  { type: 'running', required: true },
  { type: 'idle', required: true },
  { type: 'warning' },
  { type: 'critical' },
  { type: 'maintenance' }
];

const StateValueTypes = ['true', 'false'];

const statusTypeToIconName = statusType =>
  ({
    running: 'running',
    warning: 'warning',
    critical: 'error',
    maintenance: 'maintenance',
    idle: 'idle'
  }[statusType]);

const MachineStatusCard = ({ getError, getHelperText, handleBlur, handleChange, statusType, values }) => {
  const { t } = useTranslation();
  const addressFieldName = `configuration.plc.statuses.${statusType.type}.address`;
  const valueFieldName = `configuration.plc.statuses.${statusType.type}.value`;
  const isStatusDataTypeBoolean = values.configuration.plc.statuses.datatype === StatusDataTypes[1];

  return (
    <StyledCard>
      <CardContent>
        <Container maxWidth="sm">
          <StyledMachineStatusHeader>
            <MachineIcon
              iconName={statusTypeToIconName(statusType.type)}
              style={{ marginRight: '.5rem', fontSize: '2rem' }}
              viewBox="0 0 32 32"
            />
            <Heading2>{t(`machines.status.${statusType.type}`)}</Heading2>
          </StyledMachineStatusHeader>
          <FormElements.TextField
            fullWidth
            error={getError(addressFieldName)}
            helperText={getHelperText(addressFieldName)}
            id={addressFieldName}
            label={t('machines.form.machine_status.address')}
            margin="normal"
            name={addressFieldName}
            onBlur={handleBlur}
            onChange={handleChange}
            required={statusType.required}
            value={values.configuration.plc.statuses[statusType.type].address}
          />
          {isStatusDataTypeBoolean ? (
            <FormElements.Select
              error={getError(valueFieldName)}
              helperText={getHelperText(valueFieldName)}
              fullWidth
              id={valueFieldName}
              label={t('machines.form.machine_status.value')}
              name={valueFieldName}
              handleChange={handleChange}
              onBlur={handleBlur}
              required={statusType.required}
              value={values.configuration.plc.statuses[statusType.type].value}
              withEmptyField
            >
              {StateValueTypes.map(val => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </FormElements.Select>
          ) : (
            <FormElements.TextField
              error={getError(valueFieldName)}
              helperText={getHelperText(valueFieldName)}
              fullWidth
              id={valueFieldName}
              label={t('machines.form.machine_status.value')}
              margin="normal"
              name={valueFieldName}
              onBlur={handleBlur}
              onChange={handleChange}
              required={statusType.required}
              type="text"
              value={values.configuration.plc.statuses[statusType.type].value}
            />
          )}
        </Container>
      </CardContent>
    </StyledCard>
  );
};

MachineStatusCard.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  values: T.shape({
    configuration: T.shape({
      plc: T.shape({
        statuses: T.shape({
          datatype: T.oneOf([...StatusDataTypes, '']),
          running: T.shape({}),
          warning: T.shape({}),
          critical: T.shape({}),
          maintenance: T.shape({}),
          idle: T.shape({})
        })
      }).isRequired
    }).isRequired
  }).isRequired,
  statusType: T.shape({
    type: T.string,
    required: T.bool
  }).isRequired
};

const MachineStatus = ({ getError, getHelperText, handleBlur, handleChange, values }) => {
  const { t } = useTranslation();

  const breadcrumbObj = [
    { route: '/machines', name: `${t('machines.title')}` },
    { route: `/machines/${values.id || ''}`, name: values.commission_number || t('machines.detail.new_machine') },
    { route: null, name: `${t('machines.detail.status.title')}` }
  ];

  return (
    <StyledContentWrapper elevation={3}>
      <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

      <MachinePageHeader>
        <PageHeaderTitle>{t('machines.detail.status.title')}</PageHeaderTitle>
        <PageHeaderSubTitle>{t('machines.detail.subtitle')}</PageHeaderSubTitle>
      </MachinePageHeader>

      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <StyledMachineStatusDataTypeWrapper>
          <Heading2>{t('machines.form.machine_status.data_type')}</Heading2>
          <InfoText>{t('machines.form.machine_status.data_type_help')}</InfoText>
          <label htmlFor="configuration.plc.statuses.datatype.integer">
            <FormElements.RadioButton
              margin="normal"
              id="configuration.plc.statuses.datatype.integer"
              name="configuration.plc.statuses.datatype"
              onBlur={handleBlur}
              onChange={handleChange}
              value={StatusDataTypes[0]}
              checked={values.configuration.plc.statuses.datatype === StatusDataTypes[0]}
            />
            {t('machines.form.machine_status.integer')}
          </label>
          <label htmlFor="configuration.plc.statuses.datatype.bool">
            <FormElements.RadioButton
              margin="normal"
              id="configuration.plc.statuses.datatype.bool"
              name="configuration.plc.statuses.datatype"
              onBlur={handleBlur}
              onChange={handleChange}
              value={StatusDataTypes[1]}
              checked={values.configuration.plc.statuses.datatype === StatusDataTypes[1]}
            />
            {t('machines.form.machine_status.boolean')}
          </label>
        </StyledMachineStatusDataTypeWrapper>
        {values.configuration.plc.statuses.datatype === '' ? (
          <StyledCard style={{ margin: '2rem 0 0' }}>
            <CardContent>
              <EmptyState type="machine_details_status" />
            </CardContent>
          </StyledCard>
        ) : (
          <StyledMachineStatusCardsWrapper>
            {StatusTypes.map(statusType => (
              <MachineStatusCard
                getError={getError}
                getHelperText={getHelperText}
                handleBlur={handleBlur}
                handleChange={handleChange}
                key={`machine-status-card-${statusType.type}`}
                statusType={statusType}
                values={values}
              />
            ))}
          </StyledMachineStatusCardsWrapper>
        )}
      </Container>
    </StyledContentWrapper>
  );
};

MachineStatus.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  values: T.shape({
    id: T.string,
    commission_number: T.string,
    configuration: T.shape({
      plc: T.shape({
        statuses: T.shape({
          datatype: T.oneOf([...StatusDataTypes, '']),
          running: T.shape({}),
          warning: T.shape({}),
          critical: T.shape({}),
          maintenance: T.shape({}),
          idle: T.shape({})
        })
      }).isRequired
    }).isRequired
  }).isRequired
};

export default MachineStatus;
