/* eslint-disable max-len */
import React from 'react';
import T from 'prop-types';

import { COLOR_MAIN_HIGHLIGHT, COLOR_MAIN_HIGHLIGHT_TEXT } from './colors';

const LogoNetzsch = ({ height, inverted }) => {
  const textColor = inverted ? COLOR_MAIN_HIGHLIGHT : COLOR_MAIN_HIGHLIGHT_TEXT;

  return (
    <svg width="231" height={height} viewBox="0 0 231 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M197.319 47.9826V0.00295086H210.918V18.4266H217.311V0.00295086H231V47.9826H217.311V29.1128H210.918V47.9826H197.319ZM100.854 10.4708L100.878 0.00295086H129.325V10.491L112.483 37.7234H128.525L128.502 47.9517H97.701L97.6326 37.7234L114.451 10.4751L100.854 10.4708ZM78.4404 47.9826V10.5145H71.596V0.01147H98.3323V10.5145H91.4879V47.9826H78.4404ZM42.5066 47.9826V0.00295086H68.9845L69.008 10.4857H56.1296L56.1082 18.4266H68.9866V29.1128H56.1082L56.0758 37.7714H68.9542L68.9866 47.9826H42.5066ZM0 47.9826V0.00295086H13.6893L21.7938 20.1166V0.00295086H35.4826V47.9826H21.7938L13.5995 27.5144L13.6914 47.9826H0ZM173.814 0.00295086H193.176V10.5059H180.477V37.7671H193.176V47.9783H173.902C173.027 48.0124 172.153 47.8675 171.332 47.5534C170.512 47.2394 169.762 46.762 169.125 46.1488C168.487 45.5356 167.976 44.7997 167.621 43.9829C167.266 43.166 167.075 42.2852 167.057 41.392L166.97 6.59142C166.994 5.70051 167.191 4.82347 167.549 4.01013C167.906 3.19679 168.417 2.46332 169.053 1.8516C169.688 1.23988 170.436 0.76168 171.253 0.44488C172.07 0.12808 172.941 -0.0234903 173.814 0.00295086ZM154.902 47.9847H131.939V37.7501H148.507V29.1149H139.951C138.182 29.1325 136.476 28.4448 135.198 27.1981C133.919 25.9513 133.169 24.2451 133.107 22.4423L133.199 6.59781C133.244 4.81343 133.979 3.11954 135.244 1.88567C136.509 0.65181 138.201 -0.0219368 139.951 0.01147H160.033V10.4793H146.434V18.4426H154.9C155.771 18.4099 156.639 18.555 157.454 18.8707C158.268 19.1863 159.013 19.6654 159.643 20.2795C160.272 20.8936 160.775 21.6309 161.12 22.4465C161.466 23.2622 161.648 24.1404 161.655 25.0289V41.4069C161.655 42.2976 161.479 43.1796 161.136 43.9988C160.793 44.818 160.29 45.5577 159.659 46.1733C159.027 46.789 158.28 47.2672 157.462 47.5801C156.645 47.8929 155.773 48.0336 154.9 47.9932"
        fill={textColor}
      />
    </svg>
  );
};

LogoNetzsch.propTypes = {
  inverted: T.bool,
  height: T.string.isRequired
};
LogoNetzsch.defaultProps = {
  inverted: false
};

export default LogoNetzsch;
