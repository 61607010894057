import { all } from 'redux-saga/effects';

import * as authSagas from './auth/sagas';
import * as userSagas from './user/sagas';
import * as organizationsSagas from './organizations/sagas';
import * as usersSagas from './users/sagas';
import * as usersV2Sagas from './users_v2/sagas';
import * as machinesSagas from './machines/sagas';
import * as gatewaysSagas from './gateways/sagas';
import * as supportContactSagas from './supportContact/sagas';
import * as licensesSagas from './licenses/sagas';
import * as cloneableMachinesSagas from './cloneableMachines/sagas';
import * as taskSagas from './task/sagas';
import * as sensorSagas from './sensor/sagas';
import * as batchSagas from './batch/sagas';
import * as gatewaysV2Sagas from './gateways_v2/sagas';
import * as machinesV2Sagas from './machines_v2/sagas';
import * as productUsageSagas from './productUsage/sagas';

const allSagas = [
  ...Object.values(authSagas),
  ...Object.values(userSagas),
  ...Object.values(organizationsSagas),
  ...Object.values(usersSagas),
  ...Object.values(usersV2Sagas),
  ...Object.values(machinesSagas),
  ...Object.values(gatewaysSagas),
  ...Object.values(supportContactSagas),
  ...Object.values(licensesSagas),
  ...Object.values(cloneableMachinesSagas),
  ...Object.values(taskSagas),
  ...Object.values(sensorSagas),
  ...Object.values(batchSagas),
  ...Object.values(gatewaysV2Sagas),
  ...Object.values(machinesV2Sagas),
  ...Object.values(productUsageSagas)
];

export default function* rootSaga() {
  yield all(allSagas.map(saga => saga()));
}
