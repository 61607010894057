import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer, enhanceWithResetActions } from '../utils';

import { SET_USER_INFORMATION, LOAD_USER, UPDATE_USER, RESET_PASSWORD } from './constants';
import { LOGIN, LOGOUT } from '../auth/constants';

const userReducer = combineReducers({
  loadingState: loadingStateReducer(LOAD_USER),
  updateLoadingState: enhanceWithResetActions([LOAD_USER])(loadingStateReducer([UPDATE_USER, RESET_PASSWORD])),
  data: typeToReducer(
    {
      [LOGIN]: {
        START: () => null,
        ERROR: () => null
      },
      [LOGOUT]: {
        SUCCESS: () => null
      },
      [SET_USER_INFORMATION]: (state, { payload }) => ({ ...state, ...payload }),
      [LOAD_USER]: {
        SUCCESS: (state, { payload }) => ({ ...state, ...payload })
      },
      [UPDATE_USER]: {
        SUCCESS: (state, { payload }) => ({ ...state, ...payload })
      }
    },
    {}
  )
});

export default combineReducers({
  user: userReducer
});
