import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { NotifyTypo } from 'web-components';

const ContainerLinkBackPage = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  margin: 10px;
`;

const StyledBox = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
`;

const HeadingStyled = styled(NotifyTypo.Heading2)`
  &.MuiTypography-h4 {
    line-height: 0.75;
    font-size: 14px;
    @media (max-width: 425px) {
      font-size: 10px;
    }
  }
`;

function Breadcrumbs({ data }) {
  return (
    <ContainerLinkBackPage>
      {data.map((item, index) => {
        if (item.divider) {
          return (
            <HeadingStyled
              key={`divider-${item.name + index}`}
              color="#BCBDC1"
              style={{ paddingLeft: 6, marginRight: 5 }}
            >
              {' | '}
            </HeadingStyled>
          );
        }
        if (index !== data.length - 1) {
          return (
            <Link style={{ textDecoration: 'none' }} to={item.route} key={item.name}>
              <StyledBox sx={{ display: 'flex' }}>
                {item.icon && (
                  <HeadingStyled
                    color="primary"
                    style={{
                      marginRight: item.icon ? '0' : '5px'
                    }}
                  >
                    {item.icon}
                  </HeadingStyled>
                )}
                {data.length > 2 && index === data.length - 2 && (
                  <HeadingStyled
                    color="#BCBDC1"
                    style={{
                      marginRight: item.icon ? '0' : '5px'
                    }}
                  >
                    {' / '}
                  </HeadingStyled>
                )}
                <HeadingStyled
                  color="primary"
                  style={{
                    marginRight: item.icon ? '0' : '5px'
                  }}
                >
                  {item.name}
                </HeadingStyled>
              </StyledBox>
            </Link>
          );
        }
        return (
          <StyledBox sx={{ display: 'flex' }} key={item.name}>
            {item.icon && (
              <HeadingStyled
                color="primary"
                style={{
                  marginRight: item.icon ? '0' : '5px'
                }}
              >
                {item.icon}
              </HeadingStyled>
            )}
            <HeadingStyled
              key={`${item.name}-slash`}
              color="#BCBDC1"
              style={{
                marginRight: item.icon ? '0' : '5px'
              }}
            >{` / `}</HeadingStyled>
            <HeadingStyled
              key={item.name}
              style={{
                marginRight: item.icon ? '0' : '5px'
              }}
            >
              {item.name}
            </HeadingStyled>
          </StyledBox>
        );
      })}
    </ContainerLinkBackPage>
  );
}

Breadcrumbs.propTypes = {
  data: T.arrayOf(Object).isRequired
};

export default Breadcrumbs;
