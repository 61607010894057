import { Box } from '@mui/material';
import styled from 'styled-components';

export { default } from './CloneMachineTableSelector';

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const StyledWrapperCheck = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledBoxHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 66px;
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05), 0 8px 32px rgba(17, 17, 26, 0.03);
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 0;
`;

export const InformationStepHeaderItem = styled.div`
  margin: 0 25px;
`;
export const InformationStepHeaderCommissionNumber = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #2b2f42;
`;
export const InformationStepHeaderNameSpan = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #7a7d85;
`;
export const InformationStepHeaderName = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #191919;
`;

export const StyledBoxContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 20px;
`;

export const StyledBoxInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 36px;
  background: #f19909;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px;
  margin: 0 0 20px;
`;

export const StyledBoxContentContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex: 1 1 30%;
  ul {
    display: flex;
    flex-direction: column;
  }
`;

export const ListBox = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 0px;
`;

export const ListBoxItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
