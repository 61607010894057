import styled from 'styled-components';
import { Card, Tab, Tabs } from '@mui/material';
import { DISABLED_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from 'attrs/colors';

const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3rem;
`;

const StyledMachineStatusHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;

const StyledMachineStatusDataTypeWrapper = styled.div`
  .MuiTypography-root.MuiTypography-subtitle2 {
    margin: 0.5rem 0;
  }
  & > label {
    margin-left: -9px;
    padding-right: 3rem;
  }
`;

const StyledMachineStatusCardsWrapper = styled.div`
  margin: 2rem 0 0;
`;

const StyledContentWrapper = styled.div`
  padding: 0rem 0rem 0rem;
`;

const StyledContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 0.5rem;
`;

const StyledContentHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCardFormAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 2rem 0 0;
`;

const StyledCard = styled(Card)`
  &.MuiPaper-elevation1 {
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    box-shadow: none;
  }

  margin-bottom: 1rem;

  &.withMargins {
    margin: 2rem 0 0;
  }
`;

const StyledCardContent = styled.div`
  padding: 1rem;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 1rem;
  &.MuiTabs-root {
    min-height: auto;
    min-width: auto;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 1rem;
    min-height: auto;
    min-width: auto;
    padding: 0.2rem 1rem;
  }
`;

const StyledSambaSystemRadioGroup = styled.div(
  ({ theme, disabled }) => `
    margin: 1rem 0 0 0.75rem;

    & > .MuiTypography-subtitle2 {
      font-size: 0.75rem;
      margin-bottom: -0.375rem;
      color: ${disabled ? DISABLED_COLOR : SECONDARY_TEXT_COLOR};
    }

    & > .MuiTypography-caption {
      font-size: 1rem;
      color: ${disabled ? DISABLED_COLOR : SECONDARY_TEXT_COLOR};

      ${theme.breakpoints.down('sm')} {
        display: block;
        height: 2rem;
        margin-top: 0.25rem;
      }
    }

    & .MuiRadio-root {
      margin-top: -0.125rem;
      font-size: 0.625rem;
    }

    & > label {
      font-size: 0.875rem;
      margin-left: 1rem;
      color: ${disabled ? DISABLED_COLOR : PRIMARY_TEXT_COLOR};

      ${theme.breakpoints.down('sm')} {
        &:first-of-type {
          margin-left: -0.625rem;
        }        
      }
    }
  `
);

export {
  StyledCard,
  StyledCardContent,
  StyledCardFormAction,
  StyledCardHeader,
  StyledContentHeader,
  StyledContentWrapper,
  StyledMachineStatusCardsWrapper,
  StyledMachineStatusDataTypeWrapper,
  StyledMachineStatusHeader,
  StyledTab,
  StyledTabs,
  StyledSambaSystemRadioGroup,
  StyledContentHeaderColumn
};
