import React from 'react';
import { Button } from '@mui/material';
import T, { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { NotifyTypo } from 'web-components';
import { useDispatch } from 'react-redux';
import { deleteMachine } from 'redux/machines/actions';
import { StyledContent, StyledNewDialog, StyledHandlers } from './elements';

const labelAmend = 'machines.overview.delete_machine';

const DeleteMachine = ({ open, handleClose, machineId, organizationId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const handleDeleteMachine = async () => {
    try {
      dispatch(deleteMachine(machineId, organizationId));
      handleClose(true);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={() => handleClose()}>
        {t(`${labelAmend}.cancel`)}
      </Button>
      <Button
        variant="contained"
        data-selector="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        color="error"
        onClick={handleDeleteMachine}
      >
        {t(`${labelAmend}.action`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      open={open}
      handleClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      title={t(`${labelAmend}.title`)}
      subTitle={t(`${labelAmend}.subtitle`)}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="680px"
      content={
        <StyledContent>
          <NotifyTypo.Heading2>{t(`${labelAmend}.text`)}</NotifyTypo.Heading2>
        </StyledContent>
      }
    />
  );
};

DeleteMachine.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  machineId: T.string.isRequired,
  organizationId: T.string.isRequired
};

export default DeleteMachine;
