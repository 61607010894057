import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormElements } from 'web-components';
import { Container } from '@mui/material';
import { StyledContentWrapper } from './elements';
import {
  MachineBreadcrumb,
  MachinePageHeader
} from '../../../../containers/Machines/MachineDetail/MachineDetail.styled';
import { PageHeaderSubTitle, PageHeaderTitle } from '../../../../elements';
import { PlcProtocols, PlcRacks, PlcSlots, PlcType } from '../../../../attrs/plcConfigValues';

const numberOrEmpty = value => {
  if (typeof value === 'number') {
    return value;
  }

  return '';
};

const PlcInformation = ({ getError, getHelperText, handleBlur, handleChange, handleChangeNumber, values }) => {
  const { t } = useTranslation();

  const breadcrumbObj = [
    {
      route: '/machines',
      name: `${t('machines.title')}`
    },
    {
      route: `/machines/${values.id || ''}`,
      name: values.commission_number || t('machines.detail.new_machine')
    },
    { route: null, name: `${t('machines.detail.plc.title')}` }
  ];

  return (
    <StyledContentWrapper elevation={3}>
      <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

      <MachinePageHeader>
        <PageHeaderTitle>{t('machines.detail.plc.title')}</PageHeaderTitle>
        <PageHeaderSubTitle>{t('machines.detail.subtitle')}</PageHeaderSubTitle>
      </MachinePageHeader>

      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <FormElements.Select
          error={getError('configuration.plc.type')}
          helperText={getHelperText('configuration.plc.type')}
          fullWidth
          handleChange={handleChange}
          id="configuration.plc.type"
          label={t('machines.form.protocol')}
          name="configuration.plc.type"
          onBlur={handleBlur}
          required
          value={values.configuration.plc.type}
          withEmptyField
        >
          {PlcProtocols.map(protocol => (
            <option key={protocol.value} value={protocol.value}>
              {protocol.type}
            </option>
          ))}
        </FormElements.Select>
        <FormElements.TextField
          error={getError('configuration.plc.address')}
          helperText={getHelperText('configuration.plc.address')}
          fullWidth
          id="address"
          label={t('machines.form.ip_address')}
          margin="normal"
          name="configuration.plc.address"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.configuration.plc.address}
        />
        <FormElements.TextField
          disabled={values.configuration.plc.type === PlcType.ROCKWELL}
          error={getError('configuration.plc.port')}
          helperText={getHelperText('configuration.plc.port')}
          fullWidth
          id="port"
          label={t('machines.form.port')}
          margin="normal"
          name="configuration.plc.port"
          onBlur={handleBlur}
          onChange={handleChangeNumber('configuration.plc.port')}
          type="number"
          required={values.configuration.plc.type !== PlcType.ROCKWELL}
          value={numberOrEmpty(values.configuration.plc.port)}
        />
        <FormElements.Select
          disabled={values.configuration.plc.type === PlcType.ROCKWELL}
          error={getError('configuration.plc.rack')}
          helperText={getHelperText('configuration.plc.rack')}
          fullWidth
          handleChange={handleChangeNumber('configuration.plc.rack')}
          id="configuration.plc.rack"
          label={t('machines.form.rack')}
          name="configuration.plc.rack"
          onBlur={handleBlur}
          required={values.configuration.plc.type !== PlcType.ROCKWELL}
          value={values.configuration.plc.rack}
        >
          {PlcRacks.map(rack => (
            <option key={rack.value} value={rack.value}>
              {rack.value}
            </option>
          ))}
        </FormElements.Select>
        <FormElements.Select
          error={getError('configuration.plc.slot')}
          helperText={getHelperText('configuration.plc.slot')}
          fullWidth
          handleChange={handleChangeNumber('configuration.plc.slot')}
          id="configuration.plc.slot"
          label={t('machines.form.slot')}
          name="configuration.plc.slot"
          onBlur={handleBlur}
          required
          value={values.configuration.plc.slot}
          withEmptyField
        >
          {PlcSlots.map(slot => (
            <option key={slot.value} value={slot.value}>
              {slot.value}
            </option>
          ))}
        </FormElements.Select>
      </Container>
    </StyledContentWrapper>
  );
};

PlcInformation.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  handleChangeNumber: T.func.isRequired,
  values: T.shape({
    id: T.string,
    commission_number: T.string,
    configuration: T.shape({
      plc: T.shape({
        type: T.string,
        address: T.string,
        port: T.number,
        rack: T.number,
        slot: T.number
      }).isRequired
    }).isRequired
  }).isRequired
};

export default PlcInformation;
