const LOAD_USERS = 'USERS/LOAD';
const LOAD_USERS_RESUME = 'USERS_RESUME/LOAD';
const LOAD_USERS_RESUME_WITH_FILTER = 'USERS_RESUME/LOAD_ALL_FILTERED';
const UPDATE_USER_BY_ID = 'USERS/UPDATE_BY_ID';
const DELETE_USER_BY_ID = 'USERS/DELETE_BY_ID';
const UPDATE_USER_ROLE = 'USERS/UPDATE_USER_ROLE';
const INVITE_USER = 'USERS/INVITE';

export {
  LOAD_USERS,
  LOAD_USERS_RESUME,
  LOAD_USERS_RESUME_WITH_FILTER,
  UPDATE_USER_BY_ID,
  UPDATE_USER_ROLE,
  DELETE_USER_BY_ID,
  INVITE_USER
};
