const MACHINES_V2_LIST_RESUMED = 'MACHINES_V2/LIST_RESUMED';
const UPDATE_IRIS_TABLE_NAMES = 'MACHINES/UPDATE_IRIS_TABLE_NAMES';
const LOAD_IRIS_TABLE_NAMES = 'MACHINES/LOAD_IRIS_TABLE_NAMES';
const LOAD_IRIS_AUTO_CONFIG = 'MACHINES/LOAD_IRIS_AUTO_CONFIG';
const UPDATE_IRIS_AUTO_CONFIG = 'MACHINES/UPDATE_IRIS_AUTO_CONFIG';

const CREATE_STATIC_VALUE_IRIS_V3 = 'MACHINES/CREATE_STATIC_VALUE_IRIS_V3';
const LOAD_STATIC_VALUE_IRIS_V3 = 'MACHINES/LOAD_STATIC_VALUE_IRIS_V3';
const UPDATE_STATIC_VALUE_IRIS_V3 = 'MACHINES/UPDATE_STATIC_VALUE_IRIS_V3';
const DELETE_SENSOR_IRIS_V3 = 'MACHINES/DELETE_SENSOR_IRIS_V3';
const UPDATE_SENSOR_STATUS_IRIS_V3 = 'MACHINES/UPDATE_SENSOR_STATUS_IRIS_V3';
const LOAD_MESSAGES_IRIS_V3 = 'MACHINES/LOAD_MESSAGES_IRIS_V3';
const UPDATE_MESSAGES_MACHINE_IRIS_V3 = 'MACHINES/UPDATE_MESSAGES_MACHINE_IRIS_V3';
const LOAD_STATUS_MACHINE_IRIS_V3 = 'MACHINES/LOAD_STATUS_MACHINE_IRIS_V3';
const UPDATE_STATUS_MACHINE_IRIS_V3 = 'MACHINES/UPDATE_STATUS_MACHINE_IRIS_V3';
const LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3 = 'MACHINES/LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3';
const UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3 = 'MACHINES/UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3';

// Batch IRIS v3
const LOAD_IRIS_BATCH_DETAILS = 'MACHINES/LOAD_IRIS_BATCH_DETAILS';
const UPDATE_IRIS_BATCH_DETAILS = 'MACHINES/UPDATE_IRIS_BATCH_DETAILS';
const LOAD_IRIS_BATCH_STATUS = 'MACHINES/LOAD_IRIS_BATCH_STATUS';
const UPDATE_IRIS_BATCH_STATUS = 'MACHINES/UPDATE_IRIS_BATCH_STATUS';
const LOAD_IRIS_BATCH_TARGET = 'MACHINES/LOAD_IRIS_BATCH_TARGET';
const UPDATE_IRIS_BATCH_TARGET = 'MACHINES/UPDATE_IRIS_BATCH_TARGET';
const LOAD_IRIS_BATCH_LIMITS = 'MACHINES/LOAD_IRIS_BATCH_LIMITS';
const UPDATE_IRIS_BATCH_LIMITS = 'MACHINES/UPDATE_IRIS_BATCH_LIMITS';
const LOAD_IRIS_BATCH_MEASUREMENTS = 'MACHINES/LOAD_IRIS_BATCH_MEASUREMENTS';
const UPDATE_IRIS_BATCH_MEASUREMENTS = 'MACHINES/UPDATE_IRIS_BATCH_MEASUREMENTS';

export {
  MACHINES_V2_LIST_RESUMED,
  LOAD_IRIS_TABLE_NAMES,
  UPDATE_IRIS_TABLE_NAMES,
  LOAD_IRIS_AUTO_CONFIG,
  UPDATE_IRIS_AUTO_CONFIG,
  CREATE_STATIC_VALUE_IRIS_V3,
  LOAD_STATIC_VALUE_IRIS_V3,
  UPDATE_STATIC_VALUE_IRIS_V3,
  DELETE_SENSOR_IRIS_V3,
  UPDATE_SENSOR_STATUS_IRIS_V3,
  LOAD_MESSAGES_IRIS_V3,
  UPDATE_MESSAGES_MACHINE_IRIS_V3,
  LOAD_STATUS_MACHINE_IRIS_V3,
  UPDATE_STATUS_MACHINE_IRIS_V3,
  LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3,
  UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3,
  LOAD_IRIS_BATCH_DETAILS,
  UPDATE_IRIS_BATCH_DETAILS,
  LOAD_IRIS_BATCH_STATUS,
  UPDATE_IRIS_BATCH_STATUS,
  LOAD_IRIS_BATCH_TARGET,
  UPDATE_IRIS_BATCH_TARGET,
  LOAD_IRIS_BATCH_LIMITS,
  UPDATE_IRIS_BATCH_LIMITS,
  LOAD_IRIS_BATCH_MEASUREMENTS,
  UPDATE_IRIS_BATCH_MEASUREMENTS
};
