import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Breadcrumbs, Container, Link, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import GeneralInformation from './GeneralInformation';
import Instructions from './Instructions';
import Materials from './Materials';
import Notification from './Notification';
import { getSelectedMachine } from '../../../../../redux/machines/selectors';
import { getSelectedOrganization } from '../../../../../redux/organizations/selectors';
import { loadOrganization } from '../../../../../redux/organizations/actions';
import { loadMachine } from '../../../../../redux/machines/actions';
import { isLoading } from '../../../../../helpers/utils';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#2BACA3'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#2BACA3'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#B1B7C5',
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#000000',
  width: 32,
  height: 32,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#91D1CF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundColor: '#91D1CF'
  })
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.node
};

ColorlibStepIcon.defaultProps = {
  active: false,
  completed: false,
  className: 'row',
  icon: <></>
};

export default function TaskStepper() {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const { loadingState: loadingStateMachine, data: machine } = useSelector(getSelectedMachine);
  const { loadingState: loadingStateCustomer, data: customer } = useSelector(getSelectedOrganization);
  const isLoadingCustomer = isLoading(loadingStateCustomer.status);
  const isLoadingMachine = isLoading(loadingStateMachine.status);

  const { customerId, machineId } = useParams();
  const dispatch = useDispatch();

  const [generalInformation, setGeneralInformation] = useState({});
  const [instructions, setInstructions] = useState({});
  const [materialList, setMaterialList] = useState({});
  const [notification, setNotification] = useState({});

  useEffect(() => {
    if (!customer.id && customerId !== 'undefined' && !isLoadingCustomer) {
      dispatch(loadOrganization(customerId));
    }
  }, [dispatch, customer, customerId, isLoadingCustomer]);

  useEffect(() => {
    if (!machine.id && machineId !== 'undefined' && !isLoadingMachine) {
      dispatch(loadMachine(machineId));
    }
  }, [dispatch, machine, machineId, isLoadingMachine]);

  const steps = [
    {
      title: t('task.general_information.title'),
      content: <GeneralInformation data={generalInformation} setData={setGeneralInformation} />
    },
    {
      title: t('task.instructions.title'),
      content: <Instructions data={instructions} setData={setInstructions} />
    },
    {
      title: t('task.materials.title'),
      content: <Materials data={materialList} setData={setMaterialList} />
    },
    {
      title: t('task.notification.title'),
      content: <Notification data={notification} setData={setNotification} />
    }
  ];

  // TODO: implement for mandatory fields
  const isStepOptional = step => step === 1;

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleFinish = () => {
    // TODO: do something
  };

  return (
    <Container maxWidth={false} className="default-inner-container-with-breadcrumb">
      <Stack spacing={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="primary.main"
            sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
            href="/customers"
          >
            {t('task.breadcrumb.customers')}
          </Link>
          <Link
            underline="hover"
            color="primary.main"
            sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
            href={`/customers/${customerId}`}
          >
            {customer.name}
          </Link>
          <Link
            underline="hover"
            color="primary.main"
            sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
            href={`/machines/${machineId}`}
          >
            {machine.name}
          </Link>
          <Link
            underline="hover"
            color="primary.main"
            sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
            href={`/machines/${machineId}/tasks`}
          >
            {t('task.breadcrumb.tasks')}
          </Link>
          <Typography color="text.primary" sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}>
            {t('task.breadcrumb.add_maintenance')}
          </Typography>
        </Breadcrumbs>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('task.stepper.title')}</Typography>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Stepper sx={{ flex: 1, maxWidth: '852px' }} activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((step, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              return (
                <Step key={step.title} {...stepProps}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{step.title}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Stack>
      <Stack sx={{ mt: 4 }} spacing={2}>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            width: '100%',
            backgroundColor: 'white',
            boxShadow: 2,
            borderRadius: '8px',
            p: 2
          }}
        >
          {activeStep > 0 && (
            <Typography color="text.primary" sx={{ fontSize: { xs: 18, sm: 20 }, fontWeight: 550 }}>
              {generalInformation.name}
            </Typography>
          )}
          <Stack direction="row" spacing={2}>
            <Typography color="text.primary" sx={{ fontSize: { xs: 10, sm: 12 } }}>
              {machine.name}
            </Typography>
            <Typography color="text.secondary" sx={{ fontSize: { xs: 10, sm: 12 } }}>
              {machine.production_line}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            width: '100%',
            backgroundColor: 'white',
            boxShadow: 2,
            borderRadius: '8px'
          }}
        >
          <Box sx={{ p: 2 }}>{steps[activeStep].content}</Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              p: 2,
              bgcolor: '#F7F7FA',
              borderTop: '2px solid #11111A1A',
              boxShadow: '0px 0px 0px #888, 0px -1px 5px #11111A1A'
            }}
          >
            <Button variant="outlined" color="error" sx={{ minHeight: 56, minWidth: 127 }} onClick={handleBack}>
              {t('default_actions.cancel')}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                sx={{ minHeight: 56, minWidth: 127 }}
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                {t('default_actions.back')}
              </Button>
              <Button
                variant="outlined"
                sx={{ minHeight: 56, minWidth: 127 }}
                disabled={activeStep === steps.length - 1}
                onClick={handleNext}
              >
                {t('default_actions.next')}
              </Button>
              <Button
                variant="outlined"
                sx={{ minHeight: 56, minWidth: 127 }}
                disabled={activeStep < steps.length - 1}
                onClick={handleFinish}
              >
                {t('default_actions.save')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
}
