const USERS_V2_INVITE_USER = 'USERS_V2/INVITE_USER';
const USERS_V2_GET_USERS_PAGINATED = 'USERS_V2/GET_USERS_PAGINATED';
const USERS_V2_GET_USERS_INVITATIONS_PAGINATED = 'USERS_V2/GET_USERS_INVITATIONS_PAGINATED';
const USERS_V2_UPDATE_USER_ROLES = 'USERS_V2/UPDATE_USER_ROLES';

export {
  USERS_V2_INVITE_USER,
  USERS_V2_GET_USERS_PAGINATED,
  USERS_V2_GET_USERS_INVITATIONS_PAGINATED,
  USERS_V2_UPDATE_USER_ROLES
};
