import { combineReducers } from 'redux';
import typeToReducer from 'type-to-reducer';
import {
  LOAD_IRIS_AUTO_CONFIG,
  LOAD_IRIS_BATCH_DETAILS,
  LOAD_IRIS_BATCH_LIMITS,
  LOAD_IRIS_BATCH_MEASUREMENTS,
  LOAD_IRIS_BATCH_STATUS,
  LOAD_IRIS_BATCH_TARGET,
  LOAD_IRIS_TABLE_NAMES,
  LOAD_MESSAGES_IRIS_V3,
  LOAD_STATIC_VALUE_IRIS_V3,
  LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3,
  LOAD_STATUS_MACHINE_IRIS_V3,
  MACHINES_V2_LIST_RESUMED,
  UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3,
  UPDATE_STATUS_MACHINE_IRIS_V3
} from './constants';
import { enhanceWithResetActions, loadingStateReducer } from '../utils';

const irisTableNames = combineReducers({
  loadingState: loadingStateReducer(LOAD_IRIS_TABLE_NAMES),
  data: typeToReducer(
    {
      [LOAD_IRIS_TABLE_NAMES]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const irisAutoConfig = combineReducers({
  loadingState: loadingStateReducer(LOAD_IRIS_AUTO_CONFIG),
  data: typeToReducer(
    {
      [LOAD_IRIS_AUTO_CONFIG]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadStaticValuesIrisV3 = combineReducers({
  loadingUpdateState: loadingStateReducer(LOAD_STATIC_VALUE_IRIS_V3),
  loadingState: loadingStateReducer(LOAD_STATIC_VALUE_IRIS_V3),
  data: typeToReducer(
    {
      [LOAD_STATIC_VALUE_IRIS_V3]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadStatusMachineIrisV3 = combineReducers({
  loadingState: loadingStateReducer(LOAD_STATUS_MACHINE_IRIS_V3),
  data: typeToReducer(
    {
      [LOAD_STATUS_MACHINE_IRIS_V3]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_STATUS_MACHINE_IRIS_V3]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadStatusMachineConditionsIrisV3 = combineReducers({
  loadingState: loadingStateReducer(LOAD_STATUS_MACHINE_IRIS_V3),
  data: typeToReducer(
    {
      [LOAD_STATUS_MACHINE_CONDITIONS_IRIS_V3]: {
        SUCCESS: (data, { payload }) => payload
      },
      [UPDATE_STATUS_MACHINE_CONDITIONS_IRIS_V3]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const loadMessagesIrisV3 = combineReducers({
  loadingState: loadingStateReducer(LOAD_MESSAGES_IRIS_V3),
  data: typeToReducer(
    {
      [LOAD_MESSAGES_IRIS_V3]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const irisBatchDetails = combineReducers({
  loadingState: loadingStateReducer(LOAD_IRIS_BATCH_DETAILS),
  data: typeToReducer(
    {
      [LOAD_IRIS_BATCH_DETAILS]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});
const irisBatchStatus = combineReducers({
  loadingState: loadingStateReducer(LOAD_IRIS_BATCH_STATUS),
  data: typeToReducer(
    {
      [LOAD_IRIS_BATCH_STATUS]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const irisBatchTarget = combineReducers({
  loadingState: loadingStateReducer(LOAD_IRIS_BATCH_TARGET),
  data: typeToReducer(
    {
      [LOAD_IRIS_BATCH_TARGET]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const irisBatchLimits = combineReducers({
  loadingState: loadingStateReducer(LOAD_IRIS_BATCH_LIMITS),
  data: typeToReducer(
    {
      [LOAD_IRIS_BATCH_LIMITS]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const irisBatchMeasurements = combineReducers({
  loadingState: loadingStateReducer(LOAD_IRIS_BATCH_MEASUREMENTS),
  data: typeToReducer(
    {
      [LOAD_IRIS_BATCH_MEASUREMENTS]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});

const irisBatch = combineReducers({
  details: enhanceWithResetActions([LOAD_IRIS_BATCH_DETAILS])(irisBatchDetails),
  status: enhanceWithResetActions([LOAD_IRIS_BATCH_STATUS])(irisBatchStatus),
  target: enhanceWithResetActions([LOAD_IRIS_BATCH_TARGET])(irisBatchTarget),
  limits: enhanceWithResetActions([LOAD_IRIS_BATCH_LIMITS])(irisBatchLimits),
  measurements: enhanceWithResetActions([LOAD_IRIS_BATCH_MEASUREMENTS])(irisBatchMeasurements)
});

export default combineReducers({
  loadingState: loadingStateReducer(MACHINES_V2_LIST_RESUMED),
  listResumed: typeToReducer(
    {
      [MACHINES_V2_LIST_RESUMED]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {
      data: [],
      limit: 0,
      page: 0,
      search: '',
      sort: [],
      total_length: 0,
      total_pages: 0
    }
  ),
  irisTableNames: enhanceWithResetActions([LOAD_IRIS_TABLE_NAMES])(irisTableNames),
  irisAutoConfig: enhanceWithResetActions([LOAD_IRIS_AUTO_CONFIG])(irisAutoConfig),
  staticValuesIrisV3: enhanceWithResetActions([LOAD_STATIC_VALUE_IRIS_V3])(loadStaticValuesIrisV3),
  messageIrisV3: enhanceWithResetActions([LOAD_MESSAGES_IRIS_V3])(loadMessagesIrisV3),
  statusMachineIrisV3: enhanceWithResetActions([LOAD_STATUS_MACHINE_IRIS_V3])(loadStatusMachineIrisV3),
  statusMachineConditionsIrisV3: enhanceWithResetActions([LOAD_STATUS_MACHINE_IRIS_V3])(
    loadStatusMachineConditionsIrisV3
  ),
  irisBatch
});
