import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';

import { LOAD_TASKS } from './constants';

export default combineReducers({
  loadingTasks: loadingStateReducer(LOAD_TASKS),
  paginatedTaskList: typeToReducer(
    {
      [LOAD_TASKS]: {
        SUCCESS: (_data, { payload }) => payload
      }
    },
    []
  )
});
