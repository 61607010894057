import React, { useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotifyIcon, NotifyTypo } from 'web-components';
import { Button, Container, IconButton } from '@mui/material';
import { isValidNumber } from 'helpers/utils';
import { StyledCard, StyledCardContent, StyledCardFormAction, StyledCardHeader } from '../elements';
import EngineeringUnitsFields from './fields/EngineeringUnitsFields';
import BaseConfigurationFields from './fields/BaseConfigurationFields';
import SensorConfigurationRadioGroup from './fields/SensorConfigurationRadioGroup';
import TriggerWarningFields from './fields/TriggerWarningFields';
import YAxisFields from './fields/YAxisFields';
import { metricDataType } from '../../../../../attrs/sensorConfig';

const getPreselectedType = range => {
  if (isValidNumber(((range || {}).max || {}).value) || isValidNumber(((range || {}).min || {}).value)) {
    return 1;
  }
  return 0;
};

const SensorDataCard = ({
  getError,
  getHelperText,
  availableSensorTypes,
  handleBlur,
  handleChange,
  handleChangeSensorType,
  handleChangeEngineeringDataType,
  metric,
  namespace,
  millSensorTypes,
  onDelete,
  setFieldValue,
  isNewSensor
}) => {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(true);
  const preSelectedEngineeringDataType = getPreselectedType(metric.range);
  const [engineeringDataType, setEngineeringDataType] = useState(preSelectedEngineeringDataType);

  const sensorType = millSensorTypes.find(({ value }) => value === metric.type) || {};

  const setChangeEngineeringDataType = (e, value) => {
    handleChangeEngineeringDataType(namespace, value);
    setEngineeringDataType(value);
  };

  return (
    <StyledCard style={{ marginBottom: '1rem' }}>
      <StyledCardContent>
        <StyledCardHeader>
          <NotifyTypo.Heading2
            data-selector={`machine-details-sensor-card--${metric.is_custom ? metric.name : sensorType.value}`}
          >
            {metric.is_custom ? metric.name : t(sensorType.name)}
            {sensorType.postfix && ` [${sensorType.postfix}]`}
          </NotifyTypo.Heading2>
          <IconButton onClick={() => setIsHidden(state => !state)}>
            <NotifyIcon iconName="edit" />
          </IconButton>
        </StyledCardHeader>
        {!isHidden && (
          <Container maxWidth="sm">
            <SensorConfigurationRadioGroup
              isNewSensor={isNewSensor}
              values={metric}
              namespace={namespace}
              setFieldValue={setFieldValue}
            />
            <BaseConfigurationFields
              values={metric}
              namespace={namespace}
              availableSensorTypes={availableSensorTypes}
              handleChangeSensorType={handleChangeSensorType(namespace)}
              getError={getError}
              getHelperText={getHelperText}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <EngineeringUnitsFields
              values={metric}
              namespace={namespace}
              engineeringDataType={engineeringDataType}
              getError={getError}
              getHelperText={getHelperText}
              onBlur={handleBlur}
              onChange={handleChange}
              onChangeTab={setChangeEngineeringDataType}
            />
            <TriggerWarningFields
              values={metric}
              namespace={namespace}
              setFieldValue={setFieldValue}
              getError={getError}
              getHelperText={getHelperText}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <YAxisFields
              values={metric}
              namespace={namespace}
              getError={getError}
              getHelperText={getHelperText}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <StyledCardFormAction>
              <Button color="secondary" onClick={onDelete} variant="text" type="button">
                {t('form.delete')}
              </Button>
            </StyledCardFormAction>
          </Container>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

SensorDataCard.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  handleChangeSensorType: T.func.isRequired,
  handleChangeEngineeringDataType: T.func.isRequired,
  metric: metricDataType.isRequired,
  namespace: T.string.isRequired,
  millSensorTypes: T.arrayOf(T.shape({})).isRequired,
  onDelete: T.func.isRequired,
  availableSensorTypes: T.arrayOf(T.shape({})).isRequired,
  setFieldValue: T.func.isRequired,
  isNewSensor: T.bool.isRequired
};

export default SensorDataCard;
