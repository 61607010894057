import { Box, Button, FormControl, Stack } from '@mui/material';
import { BuildCircleOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { blue, orange } from '@mui/material/colors';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import T, { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormElements, NotifyTypo } from 'web-components';
import { updateStatusMachineConditionsIrisV3 } from '../../../../../redux/machines_v2/actions';
import { StyledContent, StyledContentGrid, StyledHandlers, StyledNewDialog } from '../elements';
import {
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  validateBlankSpace
} from '../../../../../helpers/utils';
import { MachineStatusConditionsIrisV3Schema } from '../../../../../attrs/formValidation';
import { getStatusMachine } from '../../../../../redux/machines/selectors';

const labelAmend = 'machines.machine_details';

const StatusDataTypes = ['INTEGER', 'BOOL'];

const StatusTypes = [{ type: 'warning' }, { type: 'maintenance' }];

const statusTypeToIconName = statusType =>
  ({
    warning: <ErrorOutlineOutlined sx={{ color: orange[500], fontSize: 25 }} />,
    maintenance: <BuildCircleOutlined sx={{ color: blue[500], fontSize: 25 }} />
  }[statusType]);

const StatusCard = ({ getError, getHelperText, handleBlur, handleChange, statusType, values }) => {
  const { t } = useTranslation();
  const addressFieldName = `${statusType.type}.variable_name`;
  const valueFieldName = `${statusType.type}.value`;

  const handleChangeAddress = event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {statusTypeToIconName(statusType.type)}
        <NotifyTypo.Body1 fontWeight="bold" sx={{ ml: 1 }}>
          {t(`machines.status.${statusType.type}`)}
        </NotifyTypo.Body1>
      </Box>
      <StyledContentGrid>
        <FormElements.TextField
          fullWidth
          error={getError(addressFieldName)}
          helperText={getHelperText(addressFieldName)}
          id={addressFieldName}
          label={t('machines.form.machine_status_conditions.variable_name')}
          margin="normal"
          name={addressFieldName}
          onBlur={handleBlur}
          onChange={handleChangeAddress}
          required={statusType.required}
          value={values[statusType.type].variable_name}
        />
        <FormElements.TextField
          error={getError(valueFieldName)}
          helperText={getHelperText(valueFieldName)}
          fullWidth
          id={valueFieldName}
          label={t('machines.form.machine_status_conditions.value')}
          margin="normal"
          name={valueFieldName}
          onBlur={handleBlur}
          onChange={handleChange}
          required={statusType.required}
          type="number"
          value={values[statusType.type].value}
        />
      </StyledContentGrid>
    </Box>
  );
};

StatusCard.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  values: T.shape({
    datatype: T.oneOf([...StatusDataTypes, '']),
    running: T.shape({}),
    warning: T.shape({}),
    critical: T.shape({}),
    maintenance: T.shape({}),
    idle: T.shape({})
  }).isRequired,
  statusType: T.shape({
    type: T.string,
    required: T.bool
  }).isRequired
};

export const EditStatusConditionsIrisV3 = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loadingState } = useSelector(getStatusMachine);

  const isSubmitting = isLoading(loadingState.status);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const submitForm = (values, formikHelpers) => {
    const payload = {
      warning_variable_name: values.warning?.variable_name.toString(),
      warning_value: values.warning.value,
      maintenance_variable_name: values.maintenance?.variable_name.toString(),
      maintenance_value: values.warning.value
    };

    try {
      dispatch(updateStatusMachineConditionsIrisV3(machineId, payload));
      handleClose(true);
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-selector="status-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.handle_button`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <Formik
      initialValues={{
        warning: {
          variable_name: getDefaultFieldValue(data.warning_variable_name),
          value: getDefaultFieldValue(data.warning_value)
        },
        maintenance: {
          variable_name: getDefaultFieldValue(data.maintenance_variable_name),
          value: getDefaultFieldValue(data.maintenance_value)
        }
      }}
      validationSchema={MachineStatusConditionsIrisV3Schema}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              handleClose();
            }}
            title={t(`${labelAmend}.status_modal_conditions.title`)}
            subTitle={t(`${labelAmend}.status_modal_conditions.subtitle`)}
            maxWidth="1062px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            shouldCloseOnOverlayClick={false}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <Stack direction="column" gap={4}>
                    {StatusTypes.map(statusType => (
                      <Box>
                        <StatusCard
                          getError={getError}
                          getHelperText={getHelperText}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          key={`machine-status-card-${statusType.type}`}
                          statusType={statusType}
                          values={values}
                        />
                      </Box>
                    ))}
                  </Stack>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

EditStatusConditionsIrisV3.propTypes = {
  data: T.shape({
    warning_variable_name: T.string,
    warning_value: T.number,
    maintenance_variable_name: T.string,
    maintenance_value: T.number
  }),
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired
};

EditStatusConditionsIrisV3.defaultProps = {
  data: null,
  machineId: null
};
