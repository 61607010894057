import { createSelector } from 'reselect';
import { getOrganizations } from '../rootSelectors';

const getSelectedOrganization = createSelector([getOrganizations], organizations => organizations.selected);

const getSelectedOrganizationId = createSelector([getSelectedOrganization], organization => organization.data.id);

const getVerification = createSelector([getOrganizations], organizations => organizations.verification);

const getVerificationStatus = createSelector([getVerification], verification => verification.data.sap_status);

const getOrganizationByResume = createSelector([getOrganizations], organizations => organizations.dataResumed);

const getOrganizationMachinesListSelector = createSelector(
  [getOrganizations],
  organizations => organizations.organizationMachineList
);

const getOrganizationProductAccess = createSelector([getOrganizations], organizations => organizations.productsAccess);

const getOrganizationsSelector = createSelector([getOrganizations], organizations => organizations.data);

export {
  getOrganizationByResume,
  getOrganizationMachinesListSelector,
  getOrganizationProductAccess,
  getSelectedOrganization,
  getSelectedOrganizationId,
  getVerification,
  getVerificationStatus,
  getOrganizationsSelector
};
