import React from 'react';
import { useTranslation } from 'react-i18next';

import { NotifyTypo, FormElements, useResolutionCheck } from 'web-components';

import T from 'prop-types';
import { StyledSwitch } from '../elements';

const FeatureAccessSwitch = ({ setFieldValue, handleFeatureChange, values }) => {
  const { t } = useTranslation();
  const { isMobile } = useResolutionCheck();

  const batchStatusLabel = t(`machines.form.batch_records_${values.enabled_config.batch ? 'enabled' : 'disabled'}`);

  const handleChangeToggle = name => value => {
    setFieldValue(name, value);
    handleFeatureChange(name, value);
  };

  return (
    <StyledSwitch.Wrapper>
      <NotifyTypo.Heading2 gutterBottom style={{ marginTop: '2rem' }}>
        {t('machines.form.feature_access_title')}
      </NotifyTypo.Heading2>
      <NotifyTypo.Body1>{t('machines.form.feature_access_description')}</NotifyTypo.Body1>
      <StyledSwitch.Row isMobile={isMobile}>
        <StyledSwitch.StatusTitle>
          <NotifyTypo.Body1>{t('machines.form.batch_records_feature')}</NotifyTypo.Body1>
          <StyledSwitch.Label>{batchStatusLabel}</StyledSwitch.Label>
        </StyledSwitch.StatusTitle>
        <StyledSwitch.ToggleWrapper>
          <FormElements.ToggleButton
            name="enabled_config.batch"
            checked={values.enabled_config.batch}
            onChange={handleChangeToggle('enabled_config.batch')}
          />
        </StyledSwitch.ToggleWrapper>
      </StyledSwitch.Row>
    </StyledSwitch.Wrapper>
  );
};

FeatureAccessSwitch.propTypes = {
  setFieldValue: T.func.isRequired,
  handleFeatureChange: T.func.isRequired,
  values: T.shape({
    enabled_config: T.shape({
      batch: T.bool
    })
  }).isRequired
};

export default FeatureAccessSwitch;
